import React from "react";
import {FaGraduationCap, FaPlaneDeparture, FaWikipediaW} from "react-icons/fa";
import {LiaBrushSolid, LiaGlobeAmericasSolid} from "react-icons/lia";
import {LuChefHat, LuCloudSun, LuFileSignature} from "react-icons/lu";
import {RiUserReceived2Line} from "react-icons/ri";
import {TbMail, TbMap2, TbShoppingBag} from "react-icons/tb";
import {FaCode, FaCodepen, FaSquareRootVariable} from "react-icons/fa6";
import {PiBookBookmark, PiBookOpenTextFill, PiFlaskBold, PiListMagnifyingGlass, PiMathOperations, PiMusicNotes, PiNewspaperClipping} from "react-icons/pi";
import {BiSolidLeaf} from "react-icons/bi";
import {IoNewspaperOutline} from "react-icons/io5";
import {IoIosCalculator, IoMdDoneAll} from "react-icons/io";
import {HiOutlineBanknotes} from "react-icons/hi2";
import {BsYinYang} from "react-icons/bs";
import {GiBrain, GiDna1, GiMaterialsScience} from "react-icons/gi";
import clsx from "clsx";
import {MdOutlineHealthAndSafety, MdOutlineImageSearch, MdSportsFootball} from "react-icons/md";
import {VscLaw} from "react-icons/vsc";


export function getUppercaseLetters(name) {
    let uppercaseLetters = '';
    for (let i = 0; i < name?.length; i++) {
        if (name[i] === name[i].toUpperCase() && name[i] !== name[i].toLowerCase()) {
            uppercaseLetters += name[i];
        }
    }
    return uppercaseLetters.slice(0, 2);
}

export const glideDefaultColor = {bg: 'bg-slate-800 dark:bg-slate-400', stroke: 'stroke-slate-800 dark:stroke-slate-400', fill: 'fill-slate-800 dark:fill-slate-400', text: 'text-slate-800 dark:text-slate-400', ring: 'ring-slate-800 dark:ring-slate-400'};

export const glideColorClasses = {
    lime: {bg: 'bg-lime-600 dark:bg-lime-400', stroke: 'stroke-lime-600 dark:stroke-lime-400', fill: 'fill-lime-600 dark:fill-lime-400', text: 'text-lime-600 dark:text-lime-400', ring: 'ring-lime-600 dark:ring-lime-400'},
    indigo: {bg: 'bg-indigo-600 dark:bg-indigo-400', stroke: 'stroke-indigo-600 dark:stroke-indigo-400', fill: 'fill-indigo-600 dark:fill-indigo-400', text: 'text-indigo-600 dark:text-indigo-400', ring: 'ring-indigo-600 dark:ring-indigo-400'},
    teal: {bg: 'bg-teal-600 dark:bg-teal-400', stroke: 'stroke-teal-600 dark:stroke-teal-400', fill: 'fill-teal-600 dark:fill-teal-400', text: 'text-teal-600 dark:text-teal-400', ring: 'ring-teal-600 dark:ring-teal-400'},
    blue: {bg: 'bg-blue-600 dark:bg-blue-400', stroke: 'stroke-blue-600 dark:stroke-blue-400', fill: 'fill-blue-600 dark:fill-blue-400', text: 'text-blue-600 dark:text-blue-400', ring: 'ring-blue-600 dark:ring-blue-400'},
    green: {bg: 'bg-green-600 dark:bg-green-400', stroke: 'stroke-green-600 dark:stroke-green-400', fill: 'fill-green-600 dark:fill-green-400', text: 'text-green-600 dark:text-green-400', ring: 'ring-green-600 dark:ring-green-400'},
    cyan: {bg: 'bg-cyan-600 dark:bg-cyan-400', stroke: 'stroke-cyan-600 dark:stroke-cyan-400', fill: 'fill-cyan-600 dark:fill-cyan-400', text: 'text-cyan-600 dark:text-cyan-400', ring: 'ring-cyan-600 dark:ring-cyan-400'},
    pink: {bg: 'bg-pink-600 dark:bg-pink-400', stroke: 'stroke-pink-600 dark:stroke-pink-400', fill: 'fill-pink-600 dark:fill-pink-400', text: 'text-pink-600 dark:text-pink-400', ring: 'ring-pink-600 dark:ring-pink-400'},
    orange: {bg: 'bg-orange-600 dark:bg-orange-400', stroke: 'stroke-orange-600 dark:stroke-orange-400', fill: 'fill-orange-600 dark:fill-orange-400', text: 'text-orange-600 dark:text-orange-400', ring: 'ring-orange-600 dark:ring-orange-400'},
    red: {bg: 'bg-red-600 dark:bg-red-400', stroke: 'stroke-red-600 dark:stroke-red-400', fill: 'fill-red-600 dark:fill-red-400', text: 'text-red-600 dark:text-red-400', ring: 'ring-red-600 dark:ring-red-400'},
    yellow: {bg: 'bg-yellow-600 dark:bg-yellow-400', stroke: 'stroke-yellow-600 dark:stroke-yellow-400', fill: 'fill-yellow-600 dark:fill-yellow-400', text: 'text-yellow-600 dark:text-yellow-400', ring: 'ring-yellow-600 dark:ring-yellow-400'},
    purple: {bg: 'bg-purple-600 dark:bg-purple-400', stroke: 'stroke-purple-600 dark:stroke-purple-400', fill: 'fill-purple-600 dark:fill-purple-400', text: 'text-purple-600 dark:text-purple-400', ring: 'ring-purple-600 dark:ring-purple-400'},
    slate: {bg: 'bg-slate-600 dark:bg-slate-400', stroke: 'stroke-slate-600 dark:stroke-slate-400', fill: 'fill-slate-600 dark:fill-slate-400', text: 'text-slate-600 dark:text-slate-400', ring: 'ring-slate-600 dark:ring-slate-400'},
    emerald: {bg: 'bg-emerald-600 dark:bg-emerald-400', stroke: 'stroke-emerald-600 dark:stroke-emerald-400', fill: 'fill-emerald-600 dark:fill-emerald-400', text: 'text-emerald-600 dark:text-emerald-400', ring: 'ring-emerald-600 dark:ring-emerald-400'},
    gray: {bg: 'bg-gray-600 dark:bg-gray-400', stroke: 'stroke-gray-600 dark:stroke-gray-400', fill: 'fill-gray-600 dark:fill-gray-400', text: 'text-gray-600 dark:text-gray-400', ring: 'ring-gray-600 dark:ring-gray-400'},
    rose: {bg: 'bg-rose-600 dark:bg-rose-400', stroke: 'stroke-rose-600 dark:stroke-rose-400', fill: 'fill-rose-600 dark:fill-rose-400', text: 'text-rose-600 dark:text-rose-400', ring: 'ring-rose-600 dark:ring-rose-400'},
    amber: {bg: 'bg-amber-600 dark:bg-amber-400', stroke: 'stroke-amber-600 dark:stroke-amber-400', fill: 'fill-amber-600 dark:fill-amber-400', text: 'text-amber-600 dark:text-amber-400', ring: 'ring-amber-600 dark:ring-amber-400'},
    fuchsia: {bg: 'bg-fuchsia-600 dark:bg-fuchsia-400', stroke: 'stroke-fuchsia-600 dark:stroke-fuchsia-400', fill: 'fill-fuchsia-600 dark:fill-fuchsia-400', text: 'text-fuchsia-600 dark:text-fuchsia-400', ring: 'ring-fuchsia-600 dark:ring-fuchsia-400'},
    sky: {bg: 'bg-sky-600 dark:bg-sky-400', stroke: 'stroke-sky-600 dark:stroke-sky-400', fill: 'fill-sky-600 dark:fill-sky-400', text: 'text-sky-600 dark:text-sky-400', ring: 'ring-sky-600 dark:ring-sky-400'},
    violet: {bg: 'bg-violet-600 dark:bg-violet-400', stroke: 'stroke-violet-600 dark:stroke-violet-400', fill: 'fill-violet-600 dark:fill-violet-400', text: 'text-violet-600 dark:text-violet-400', ring: 'ring-violet-600 dark:ring-violet-400'},
    stone: {bg: 'bg-stone-600 dark:bg-stone-400', stroke: 'stroke-stone-600 dark:stroke-stone-400', fill: 'fill-stone-600 dark:fill-stone-400', text: 'text-stone-600 dark:text-stone-400', ring: 'ring-stone-600 dark:ring-stone-400'},
    black: {bg: 'bg-black dark:bg-white', stroke: 'stroke-black dark:stroke-white', fill: 'fill-black dark:fill-white', text: 'text-black dark:text-white', ring: 'ring-black dark:ring-white'},
};


export const availableColorsList = Object.keys(glideColorClasses).map((colorName, index) => ({
    id: index,
    name: colorName
}));

export const toolNameTagOfName = {
    'wiki_browser': 'Wiki Browser',
    'web_browser': 'Web Browser',
    'document_retriever': 'Document Retriever',
    'calculator': 'Calculator',
}

export const getToolIcon = (toolName, className="w-4 h-4 sm:w-3.5 sm:h-3.5") => {
    switch (toolNameTagOfName[toolName]) {
        case 'Wiki Browser':
            return <FaWikipediaW className={className}/>;
        case 'Web Browser':
            return <LiaGlobeAmericasSolid className={className}/>;
        case 'Document Retriever':
            return <PiListMagnifyingGlass className={className}/>;
        case 'Weather Reader':
            return <LuCloudSun className={className}/>;
        case 'User Profile':
            return <RiUserReceived2Line className={className}/>;
        case 'Calculator':
            return <IoIosCalculator className={className}/>;
        case 'Gmail Manager':
            return <TbMail className={className}/>;
        case 'Image Capturer':
            return <MdOutlineImageSearch className={className}/>;
    }
}


export const getGlideIcon = (glideName, size="w-4 h-4 sm:w-3.5 sm:h-3.5") => {
    switch (glideName) {
        case 'TaskGlide':
            return <IoMdDoneAll className={size}/>;
        case 'HistoGlide':
            return <PiBookOpenTextFill className={size}/>;
        case 'NewsGlide':
            return <IoNewspaperOutline className={size}/>;
        case 'MathGlide':
            return <FaSquareRootVariable className={size}/>;
        case 'ChemiGlide':
            return <PiFlaskBold className={size}/>;
        case 'PhysiGlide':
            return <GiMaterialsScience className={size}/>;
        case 'ShopGlide':
            return <TbShoppingBag className={size}/>;
        case 'FinGlide':
            return <HiOutlineBanknotes className={size}/>;
        case 'PhiloGlide':
            return <BsYinYang className={size}/>;
        case 'ArtGlide':
            return <LiaBrushSolid className={size}/>;
        case 'MusicGlide':
            return <PiMusicNotes className={size}/>;
        case 'EcoGlide':
            return <BiSolidLeaf className={size}/>;
        case 'TravelGlide':
            return <FaPlaneDeparture className={size}/>;
        case 'DocGlide':
            return <LuFileSignature className={size}/>;
        case 'BioGlide':
            return <GiDna1 className={size}/>;
        case 'TechGlide':
            return <FaCodepen className={size}/>;
        case 'CodeGlide':
            return <FaCode className={size}/>;
        case 'PsychGlide':
            return <GiBrain className={size}/>;
        case 'LitGlide':
            return <PiBookBookmark className={size}/>;
        case 'CookGlide':
            return <LuChefHat className={size}/>;
        case 'HealthGlide':
            return <MdOutlineHealthAndSafety className={size}/>;
        case 'LegalGlide':
            return <VscLaw className={size}/>;
        case 'SportGlide':
            return <MdSportsFootball className={size}/>;
        case 'EduGlide':
            return <FaGraduationCap className={size}/>;
        case 'GeoGlide':
            return <TbMap2 className={size}/>;
        default:
            return <p className={clsx(size, "flex items-center text-center justify-center")}>{getUppercaseLetters(glideName)}</p>;
    }
}
