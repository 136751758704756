import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import {GoStarFill} from "react-icons/go";

import MessageActions from "../../../Pages/Auth/Products/TaskGlide/Chat/ChatBox/Messages/Actions/MessageActions";
import UserMessageImageInput from "./UserMessageImageInput";
import Markdown from "../../Markdown/Markdown";
import UserAvatar from "../../UserAvatar/UserAvatar";
import TaskGlideDocuments from "../TaskGlideDocuments";
import {useGetTgInteractionStream} from "../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetTgInteractionStream";
import {useStreamTaskGlide} from "../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";


const UserMessage = (
    { message, isLastMessage, onMessageDelete, userInfo, animationVariants, selectedFile, chatBoxRef, pinningIsEnabled }
) => {
    const { taskGlideStreamVF } = useStreamTaskGlide();
    const { tgStreamUserMessage, tgStreamGlideResponse, tgStreamSchema } = useGetTgInteractionStream();

    const userMessageInstance = (taskGlideStreamVF && isLastMessage && tgStreamUserMessage) ?
        tgStreamUserMessage
        : message?.content;
    const glideResponseInstance = (taskGlideStreamVF && isLastMessage && tgStreamGlideResponse)
        ? tgStreamGlideResponse
        : message?.content;
    const schemaInstance = (taskGlideStreamVF && isLastMessage && tgStreamSchema)
        ? tgStreamSchema
        : message?.schema;

    const messageIsStarred = userMessageInstance?.is_pinned;
    const timestamp = glideResponseInstance?.timestamp;
    let time = "Just now";

    if (timestamp) {
        const date = new Date(timestamp);
        if (!isNaN(date.getTime())) {
            time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        }
    }

    const containsImages = userMessageInstance?.images?.length > 0;
    const containsDocuments = userMessageInstance?.documents?.length > 0;

    return (
        <div className="flex flex-row space-x-2 group/user-message" >
            <div className="h-full" >
                <UserAvatar userInfo={userInfo} />
            </div>
            <div className="w-full" >
                <div className="flex items-center justify-between mb-0.5" >
                    <div className="flex flex-row items-end gap-x-2 w-fit max-w-full py-0.5" >
                        <span className="leading-none font-medium text-sm" >{userInfo?.first_name}</span>
                        <span className="text-2xs sm:text-3xs text-base-content/70 whitespace-nowrap" >{time}</span>
                        {messageIsStarred && <GoStarFill className="size-3.5 shrink-0 text-yellow-400 dark:text-yellow-500" />}
                    </div>
                    <MessageActions scope="user" message={message} onDelete={onMessageDelete} chatBoxRef={chatBoxRef} pinningIsEnabled={pinningIsEnabled} />
                </div>
                <div className="grid grid-cols-1 grid-flow-row gap-y-0.5"  >
                    {(containsImages || containsDocuments) && <div className="w-full flex flex-wrap gap-4 mt-3 mb-2">
                        {containsImages &&
                            userMessageInstance?.images?.map((image, index) => <UserMessageImageInput key={index} imageUrl={image?.url}/>)}
                        {containsDocuments &&
                            userMessageInstance?.documents.map((document, index) => <TaskGlideDocuments key={index} document={document} selectedFile={selectedFile}/>)}
                    </div>}
                    <div className="w-full" >
                        <Markdown
                            text={userMessageInstance?.text_input}
                            className="w-full min-w-full text-base-content"
                            codeIsCustomized={false}
                        />
                    </div>
                    {(schemaInstance?.risk === 'high' || schemaInstance?.risk === 'medium') &&
                    <motion.div
                        variants={animationVariants}
                        initial="initial"
                        animate="enter"
                        className={clsx(
                            "rounded-md w-full text-sm px-4 py-3 font-light my-1 bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 col-span-full",
                            (schemaInstance?.risk === 'high' ? "text-error-content" : "text-warning-content")
                        )} >
                        Your request was flagged as risky. Please review your request and make sure it is safe to proceed. To learn more, please visit our <a href={`${process.env.frontendUrl}/terms-of-use`} target="_blank" rel="noreferrer" className="underline hover:text-base-content" >Terms of Use</a>.
                    </motion.div>}
                </div>
            </div>
        </div>
    )
};

UserMessage.propTypes = {
    message: PropTypes.object,
    isLastMessage: PropTypes.bool,
    onMessageDelete: PropTypes.func,
    userInfo: PropTypes.object,
    animationVariants: PropTypes.object,
    selectedFile: PropTypes.object,
    chatBoxRef: PropTypes.object,
    pinningIsEnabled: PropTypes.bool,
};

export default UserMessage;
