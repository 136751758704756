import React from "react";

const UserInfoRegFormSkeleton = () => {

    return (
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-2 md:py-0 lg:grid-cols-9 w-full px-px overflow-hidden" >
            <div className="col-span-full md:col-span-4 md:hidden animate-pulse duration-700" >
                <div className="h-4 w-36 bg-base-300/30 rounded" />
                <div className="mt-4 space-y-3" >
                    <div className="bg-base-300/30 w-full h-4 skeleton rounded" />
                    <div className="bg-base-300/30 w-2/3 h-4 skeleton rounded" />
                </div>
            </div>
            <div className="col-span-full py-px">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8">
                    <div className="bg-base-300/30 rounded-md h-8 w-full col-span-full skeleton" />
                    <div className="bg-base-300/30 rounded-md h-8 w-full col-span-full skeleton"/>
                    <div className="bg-base-300/30 rounded-md h-8 w-full col-span-full skeleton"/>
                    <div className="bg-base-300/30 rounded-md h-8 w-full col-span-full skeleton"/>
                    <div className="bg-base-300/30 rounded-md h-8 w-full col-span-full skeleton"/>
                    <div className="bg-base-300/30 rounded-md h-8 w-full col-span-full skeleton"/>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <div className="h-8 w-14 bg-base-300/30 skeleton rounded-md"/>
                </div>
            </div>
        </div>
    )
};

export default UserInfoRegFormSkeleton;


