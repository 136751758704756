import React from "react";
import {CgArrowDown, CgArrowLongRight} from "react-icons/cg";

import researchImg from "./ResearchPaperScreenShot.png";


const ResearchMockFrame = () => {

    return (
        <div className="flex flex-col lg:flex-row items-center justify-center mx-auto w-full max-w-7xl space-y-4 lg:space-y-0 lg:space-x-4" >
            <img src={researchImg} alt="Researching" className="w-[400px] h-auto lg:w-auto lg:h-[450px] shadow-[0px_0px_10px_rgba(0,0,0,0.10)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.3)] ring-1 ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 rounded-xl"/>
            <CgArrowLongRight className="size-6 text-base-content/70 shrink-0 hidden lg:block"/>
            <CgArrowDown className="size-6 text-base-content/70 shrink-0 lg:hidden"/>
            <div className="space-y-6 text-xs">
                <div className="flex items-start space-x-4">
                    <p className="text-4xl font-semibold text-base-content/70">Q:</p>
                    <div className="rounded-xl bg-white text-black outline-0 focus:ring-0 flex ring-1 ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 shadow-[0px_0px_10px_rgba(0,0,0,0.10)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.3)] w-full max-w-[350px] px-4 py-2">
                        In the context of &quot;Quantum work statistics with initial coherence,&quot; how does initial quantum coherence affect the thermodynamic work and entropy production in non-equilibrium processes?
                    </div>
                </div>
                <div className="flex items-start space-x-4">
                    <p className="text-4xl font-semibold text-base-content/70">A:</p>
                    <div className="rounded-xl bg-white text-black outline-0 flex ring-1 ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 shadow-[0px_0px_10px_rgba(0,0,0,0.10)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.3)] w-full max-w-[350px] px-4 py-2">
                        Initial quantum coherence, as quantified by the l1-coherence measure, significantly influences the thermodynamic work and entropy production in non-equilibrium quantum processes. The study demonstrates that while the Two Point Measurement (TPM) scheme does not account for initial coherence, thereby potentially oversimplifying work statistics, the Margenau-Hill (MH) scheme allows for initial coherence to impact the thermodynamics. This can lead to a measurable difference in the first moments and variances of work, indicating that the presence of initial coherence can contribute to negative entropy production in the MH scheme, challenging classical interpretations of the second law of thermodynamics. This insight is crucial for the development of quantum thermodynamic devices and highlights the nuanced role quantum coherences play in non-equilibrium thermodynamics.
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ResearchMockFrame;
