import React, {Fragment} from "react";
import {EllipsisHorizontalIcon} from "@heroicons/react/20/solid";


const LinkedCardsSkeleton = () => {

    return (
        <>
            <div className="py-5 bg-base-100 sticky top-28 right-0 left-0 lg:left-16 border-b border-neutral-focus/40 dark:border-neutral-focus-dark/40 z-[2]">
                <div className="sm:flex sm:items-center sm:justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" >
                    <div className="min-w-0 flex-1 sm:flex-auto">
                        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:tracking-tight text-base-content">Linked Cards</h1>
                    </div>
                </div>
            </div>
            <div className="flow-root pt-4 pb-20" >
                <div className="px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" >
                    <ul className="grid md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-4 hide-scrollbar">

                        {/*Card Skeletons*/}
                        <div className="bg-base-300/30 skeleton ring-1 ring-neutral rounded-sm shadow" >
                            <div className="px-6 py-5 flex items-start justify-between w-full">
                                <div className="sm:flex sm:items-start">
                                    <div className="w-10 h-6 bg-base-100" />
                                    <div className="mt-3 sm:ml-4 sm:mt-0">
                                        <div className="bg-base-100 rounded h-4 w-24"/>
                                        <div className="mt-3.5 text-sm text-base-300 sm:flex sm:items-center">
                                            <div className="bg-base-100 rounded h-3 w-28" />
                                        </div>
                                    </div>
                                </div>
                                <div className="relative ml-auto">
                                    <EllipsisHorizontalIcon className="h-5 w-5 text-base-300" aria-hidden="true" />
                                </div>
                            </div>
                        </div>

                        <div className="bg-base-300/30 skeleton ring-1 ring-neutral rounded-sm shadow">
                            <div className="px-6 py-5 flex items-start justify-between w-full">
                                <div className="sm:flex sm:items-start">
                                    <div className="w-10 h-6 bg-base-100" />
                                    <div className="mt-3 sm:ml-4 sm:mt-0">
                                        <div className="bg-base-100 h-4 w-24"/>
                                        <div className="mt-4 bg-base-100 rounded h-3 w-28" />
                                    </div>
                                </div>
                                <div className="relative ml-auto">
                                    <EllipsisHorizontalIcon className="h-5 w-5 text-base-300" aria-hidden="true" />
                                </div>
                            </div>
                        </div>

                        <div className="bg-base-300/30 skeleton ring-1 ring-neutral rounded-sm shadow" >
                            <div className="px-6 py-5 flex items-start justify-between w-full">
                                <div className="sm:flex sm:items-start">
                                    <div className="w-10 h-6 bg-base-100" />
                                    <div className="mt-3 sm:ml-4 sm:mt-0">
                                        <div className="bg-base-100 rounded h-4 w-24"/>
                                        <div className="mt-4 bg-base-100 rounded h-3 w-28" />
                                    </div>
                                </div>
                                <div className="relative ml-auto">
                                    <EllipsisHorizontalIcon className="h-5 w-5 text-base-300" aria-hidden="true" />
                                </div>
                            </div>
                        </div>

                        <div className="bg-base-300/30 skeleton ring-1 ring-neutral rounded-sm shadow" >
                            <div className="px-6 py-5 flex items-start justify-between w-full">
                                <div className="sm:flex sm:items-start">
                                    <div className="w-10 h-6 bg-base-100" />
                                    <div className="mt-3 sm:ml-4 sm:mt-0">
                                        <div className="bg-base-100 rounded h-4 w-24"/>
                                        <div className="mt-4 bg-base-100 rounded h-3 w-28" />
                                    </div>
                                </div>
                                <div className="relative ml-auto">
                                    <EllipsisHorizontalIcon className="h-5 w-5 text-base-300" aria-hidden="true" />
                                </div>
                            </div>
                        </div>

                        {/*Card Addition Block*/}
                        <div className="group flex justify-center items-center min-h-[120px] sm:min-h-0 bg-base-300/30 skeleton border border-neutral-focus/50 dark:border-neutral-focus-dark/50 border-dashed rounded-sm shadow" >
                            <div className="px-6 py-4 flex items-center justify-center w-full">
                                <div className="flex flex-col items-center justify-center space-y-2 focus:outline-none w-full" >
                                    <div className="h-7 w-7 rounded-full bg-base-100" aria-hidden="true" />
                                    <div className="bg-base-100 h-3 w-1/2 rounded" />
                                </div>
                            </div>
                        </div>

                    </ul>
                </div>
                <nav
                    className="border-t border-neutral-focus dark:border-neutral-focus-dark border-opacity-40 bg-base-100 py-3 fixed bottom-0 left-0 right-0 z-[2]"
                    aria-label="Pagination"
                >
                    <div className={"flex items-center justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl"}>
                        <div className="hidden sm:block w-full">
                            <p className="bg-base-300/30 h-4 w-52 rounded" />
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end space-x-3">
                            <button className="relative inline-flex items-center rounded bg-base-100 px-3 py-2 text-sm font-medium text-base-content/90 hover:text-base-content ring-1 ring-inset ring-neutral-focus/70 dark:ring-neutral-focus-dark/70 shadow hover:shadow-md outline-none disabled:opacity-50 disabled:pointer-events-none disabled:hover:cursor-not-allowed" disabled >
                                Previous
                            </button>
                            <button className="relative inline-flex items-center rounded bg-base-100 px-3 py-2 text-sm font-medium text-base-content/90 hover:text-base-content ring-1 ring-inset ring-neutral-focus/70 dark:ring-neutral-focus-dark/70 shadow hover:shadow-md outline-none disabled:opacity-50 disabled:pointer-events-none disabled:hover:cursor-not-allowed" disabled >
                                Next
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
};

export default LinkedCardsSkeleton;
