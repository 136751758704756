import React, {forwardRef, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";
import {IoTrash} from "react-icons/io5";
import clsx from "clsx";
import {TbTrashX} from "react-icons/tb";

import {useAddOrRemoveGlideOfUserGlides} from "../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useAddOrRemoveGlideOfUserGlides";


const DeleteMyGlideModal = forwardRef(({ onDelete, disabledState, isUserCreatedGlide, agent }, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        addOrRemoveGlideOfUserGlidesVF,
        addGlideToUserGlidesSuccessVF,
        addGlideToUserGlidesFailureVF,
        addOrRemoveGlideOfUserGlidesApiState
    } = useAddOrRemoveGlideOfUserGlides({ vfExists: true, autoUpdate: true });
    const modalId = `glide-deletion-${agent?.uuid}`;

    useEffect(() => {
        const dialogElement = document.getElementById(modalId);

        if (addGlideToUserGlidesSuccessVF || addGlideToUserGlidesFailureVF) {
            dialogElement?.close();
            setIsModalOpen(false);
        }
    }, [addOrRemoveGlideOfUserGlidesApiState, addOrRemoveGlideOfUserGlidesVF, modalId]);

    return (
        <>
            <button
                type="button"
                disabled={disabledState}
                className="dropdown-item px-3"
                ref={ref}
                onClick={(e) => {
                    if (!disabledState) {
                        e.preventDefault();
                        const dialogElement = document.getElementById(modalId);
                        dialogElement.showModal();
                        setIsModalOpen(true);
                    }
                }}
            >
                {!addOrRemoveGlideOfUserGlidesVF ?
                    <TbTrashX className="w-4 h-4 shrink-0" /> :
                    <CgSpinner className="animate-spin w-4 h-4 shrink-0" />}
                <p>{isUserCreatedGlide(agent) ? "Delete" : "Remove"}</p>
            </button>
            <dialog
                id={modalId}
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={() => setIsModalOpen(false)}
            >
                <form
                    method="dialog"
                    className="modal-wrapper"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-error-content sm:mx-0 sm:h-10 sm:w-10">
                            <IoTrash className="h-6 w-6 text-error" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mx-4 sm:mt-2 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-base-content">
                                {isUserCreatedGlide(agent) ? "Delete" : "Remove"} {agent?.name}
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-base-content/70">
                                    Are you sure you want to {isUserCreatedGlide(agent) ? "delete" : "remove"} {agent?.name} which you {isUserCreatedGlide(agent) ? "added" : "created"} {isUserCreatedGlide(agent) && "from Glide Store"}? This action cannot be undone.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-end gap-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                const dialogElement = document.getElementById(modalId);
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                            className="standard-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            disabled={disabledState}
                            className="error-button"
                            onClick={onDelete}
                        >
                            {disabledState ?
                                <CgSpinner className="animate-spin h-5 w-5 inline-block"/> :
                                isUserCreatedGlide(agent) ? "Delete" : "Remove"}
                        </button>
                    </div>
                </form>
                <form method="dialog" className="modal-backdrop">
                    <button
                        tabIndex={-1}
                        className="cursor-default focus-visible:outline-0 focus-visible:ring-0"
                    >
                        close
                    </button>
                </form>
            </dialog>
        </>
    );
});

DeleteMyGlideModal.displayName = 'DeleteMyGlideModal';

DeleteMyGlideModal.propTypes = {
    onDelete: PropTypes.func,
    disabledState: PropTypes.bool,
    isUserCreatedGlide: PropTypes.func,
    agent: PropTypes.object,
};

export default DeleteMyGlideModal;
