import React from "react";
import {HiOutlineExternalLink} from "react-icons/hi";
import clsx from "clsx";
import {CgClose, CgMathPlus} from "react-icons/cg";
import {LuCheck} from "react-icons/lu";
import PropTypes from "prop-types";

import {getToolIcon} from "../../../Chat/ChatBox/Messages/glideInfo";


const GlideToolsStackList = (
    { glideTools, values, onCheckboxChange, showAllGlideTools, setShowAllGlideTools }
) => {
    const handleKeyDown = (event, uuid, isChecked) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onCheckboxChange(uuid, isChecked);
        }
    };

    return (
        <ul className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 py-6 text-sm">
            {glideTools?.map((tool) => (
                <li key={tool?.uuid} className="col-span-1 rounded-lg bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 grid grid-rows-3 py-3 px-4 space-y-2">
                    <div className="flex w-full items-center justify-between space-x-5 row-span-2" >
                        <div className="flex-1 w-fit overflow-hidden space-y-1" >
                            <div className="flex items-center space-x-3" >
                                <h3 className="ine-clamp-1 text-sm font-medium text-base-content">{tool?.name_tag}</h3>
                            </div>
                            <p className="ine-clamp-2 text-base-content/70">{tool?.description}</p>
                        </div>
                        <div className="rounded-full overflow-hidden" >
                            {getToolIcon(tool?.name_tag, 'text-base-content bg-base-100 w-10 h-10 p-2.5 flex-shrink-0')}
                        </div>
                    </div>
                    <div className="flex items-center justify-end row-span-1 gap-x-3">
                        <a
                            href={'/'}
                            className="empty-button"
                        >
                            Learn More
                        </a>
                        <div
                            className={clsx(
                                "space-x-2",
                                values.added_tools?.includes(tool?.uuid) ? "error-button" : "standard-button")}
                            onClick={() => onCheckboxChange(tool?.uuid, !values.added_tools?.includes(tool?.uuid))}
                            onKeyDown={(e) => handleKeyDown(e, tool?.uuid, !values.added_tools?.includes(tool?.uuid))}
                            role="button"
                            tabIndex={0}
                            aria-pressed={values.added_tools?.includes(tool?.uuid)}
                        >
                            {values.added_tools?.includes(tool?.uuid) ?
                                <CgClose className="h-4 w-4 shrink-0" aria-hidden="true"/>
                                : <LuCheck className="h-4 w-4 shrink-0" aria-hidden="true"/>}
                            <p>{values.added_tools?.includes(tool?.uuid) ? "Deselect" : "Select"}</p>
                        </div>
                    </div>
                </li>
            ))}
            {(!showAllGlideTools && glideTools?.length > 4) && (
                <div className="flex items-center justify-center" >
                    <button
                        className="rounded-full py-0.5 px-2 shadow shadow-gray-400/50 dark:shadow-black/50 ring-[0.5px] ring-base-content/30 dark:ring-base-content/40 border-0 flex items-center space-x-2 text-sm dark:bg-base-200 focus-visible:ring focus-visible:ring-info/40 focus-visible:outline-none dark:focus-visible:ring-info/40 focus-visible:rounded-full"
                        onClick={() => setShowAllGlideTools(true)}
                    >
                        <CgMathPlus className="h-4 w-4 text-base-content" aria-hidden="true"/>
                        <p className="text-sm pr-1">Show All</p>
                    </button>
                </div>)}
        </ul>
    )
};

GlideToolsStackList.propTypes = {
    glideTools: PropTypes.array,
    values: PropTypes.object,
    onCheckboxChange: PropTypes.func,
    showAllGlideTools: PropTypes.bool,
    setShowAllGlideTools: PropTypes.func,
};

export default GlideToolsStackList;
