import React, {useEffect, useState} from "react";
import {HiOutlineMagnifyingGlass} from "react-icons/hi2";

import {useListPublicGlides} from "../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useListPublicGlides";
import Nav from "../../../../Shell/Nav/Nav";
import GlideItem from "./GlideItem";
import GlideStoreSkeleton from "./GlideStoreSkeleton";


const GlideStore = () => {
    const {
        getPublicGlides,
        publicGlidesListingResponse,
        publicGlidesListingVF
    } = useListPublicGlides({ vfExists: true });

    // Local state to store sorted Glides
    const [trendingGlides, setTrendingGlides] = useState([]);
    const [featuredGlides, setFeaturedGlides] = useState([]);

    useEffect(() => {
        if (!publicGlidesListingResponse) {
            getPublicGlides();
        }
    }, []);

    useEffect(() => {
        if (publicGlidesListingResponse) {
            const sortedTrendingGlides = [...publicGlidesListingResponse]
                .filter(glide => glide.usage_count > 0)
                .sort((a, b) => b.usage_count - a.usage_count);

            const sortedFeaturedGlides = [...publicGlidesListingResponse]
                .filter(glide => glide.user_count > 0)
                .sort((a, b) => b.user_count - a.user_count);

            setTrendingGlides(sortedTrendingGlides);
            setFeaturedGlides(sortedFeaturedGlides);
        }
    }, [publicGlidesListingResponse]);

    const loadingState = publicGlidesListingVF;

    return (
        <>
            <Nav/>
            <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-base-100 to-base-200 border-b border-neutral-focus/70 dark:border-neutral-focus-dark/70">
                <div className="flex flex-col justify-center space-y-4 w-full max-w-lg mx-auto py-4">
                    <div className="space-y-3">
                        <h1 className="text-3xl font-bold leading-7 sm:truncate sm:tracking-tight text-base-content text-center">
                            Glide Store
                        </h1>
                        <p className="text-sm text-base-content/70 text-center font-light max-w-xl mx-auto" >
                            Find and use public Glides created by other users. You can also create your own Glides and publish them to the Glide Store.
                        </p>
                    </div>
                    <div className="relative w-full mx-auto group">
                        {loadingState ?
                        <div className="flex items-center px-2 bg-base-300/30 skeleton h-8 rounded-md w-full">
                            <div className="rounded-full bg-base-100 h-4 w-4" />
                        </div> :
                        <>
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                <HiOutlineMagnifyingGlass className="h-4 w-4 text-base-300" aria-hidden="true"/>
                            </div>
                            <input
                                id="search"
                                name="search"
                                // value={searchGlides}
                                // onChange={handleSearchGlidesChange}
                                className="pl-9 input-field"
                                type="text"
                                autoComplete="off"
                                placeholder="Search Glides"
                                disabled={publicGlidesListingVF}
                            />
                        </>}
                    </div>
                </div>
            </div>
            {loadingState ? <GlideStoreSkeleton/> :
                <div className="w-full h-[calc(100dvh-233px)] sm:h-[calc(100dvh-227px)] scrollbar overflow-y-auto pb-10 pt-8 bg-base-200">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-sm space-y-8">
                        {featuredGlides?.length > 0 &&
                            <div className="space-y-4">
                                <h1 className="font-semibold text-2xl">Featured</h1>
                                <ul className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-8">
                                    {featuredGlides?.slice(0, 3)?.map((glide, i) => {
                                        return <GlideItem key={glide?.uuid} glide={glide} index={i}/>;
                                    })}
                                </ul>
                            </div>}
                        {trendingGlides?.length > 0 &&
                            <div className="space-y-4">
                                <h1 className="font-semibold text-2xl">Trending</h1>
                                <ul className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-8">
                                    {trendingGlides?.slice(0, 3)?.map((glide, i) => {
                                        return <GlideItem key={glide?.uuid} glide={glide} index={i}/>;
                                    })}
                                </ul>
                            </div>}
                        <div className="space-y-4">
                            <h1 className="font-semibold text-2xl">Everything</h1>
                            <ul className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-8">
                                {publicGlidesListingResponse?.map((glide, i) => {
                                    return <GlideItem key={glide?.uuid} glide={glide} index={i}/>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default GlideStore;
