import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearSubscriptionPlanRetrievalVF, subscriptionPlanGET} from "../../../api/features/payments/getSubscriptionPlanSlice";


export const useGetSubscriptionPlan = (
    { vfExists=false }
) => {
    const dispatch = useDispatch();

    // Payment methods
    const {
        subscriptionPlanRetrievalVF,
        subscriptionPlanRetrievalApiState,
        subscriptionPlanRetrievalResponse,
        subscriptionPlanRetrievalError,
    } = useSelector(state => state.getSubscriptionPlanReducer);

    // API states with visual feedback
    const subscriptionPlanRetrievalSuccessVF = subscriptionPlanRetrievalApiState === 'succeeded' && subscriptionPlanRetrievalVF;

    const getSubscriptionPlan = (product_name) => dispatch(subscriptionPlanGET(product_name));

    useEffect(() => {
        if (vfExists) {
            if (subscriptionPlanRetrievalSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearSubscriptionPlanRetrievalVF());
                }, 500);
                return () => clearTimeout(timer);
            }
        }
    }, [subscriptionPlanRetrievalApiState, subscriptionPlanRetrievalVF]);

    return {
        getSubscriptionPlan,
        subscriptionPlanRetrievalVF,
        subscriptionPlanRetrievalApiState,
        subscriptionPlanRetrievalResponse,
        subscriptionPlanRetrievalError,
        subscriptionPlanRetrievalSuccessVF
    };
};
