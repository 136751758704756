import React from "react";
import {Link} from "react-router-dom";
import {BsFillEnvelopeAtFill} from "react-icons/bs";


const Contact = () => {

    return (
            <main className="relative isolate pt-14">
                <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                    <div className="static px-6 py-20 sm:pt-24 lg:px-8 border-b border-b-neutral/30 lg:border-b-0 md:shadow-light-right md:dark:shadow-dark-right z-20">
                        <div className="static mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                            <div className="absolute inset-y-0 left-0 -z-50 w-full overflow-hidden border-r border-r-neutral/30 lg:w-1/2">
                                <svg
                                    className="absolute inset-0 h-full w-full stroke-primary/10 [mask-image:radial-gradient(64rem_64rem_at_top_right,white,transparent)]"
                                    aria-hidden="true"
                                >
                                    <defs>
                                        <pattern
                                            id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2"
                                            width={200}
                                            height={200}
                                            x="100%"
                                            y={-1}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <path d="M130 200V.5M.5 .5H200" fill="none" />
                                        </pattern>
                                    </defs>
                                    <svg x="100%" y={-1} className="overflow-visible fill-primary/5">
                                        <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                                    </svg>
                                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)" />
                                </svg>
                                <div
                                    className="absolute right-24 bottom-96 transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                                    aria-hidden="true"
                                >
                                    <div
                                        className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-primary to-primary-focus dark:to-primary-focus-dark opacity-20"
                                        style={{
                                            clipPath:
                                                'polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)',
                                        }}
                                    />
                                </div>
                            </div>
                            <h2 className="text-3xl font-bold tracking-tight text-base-content mb-8">
                                Get in touch
                            </h2>
                            <p className="text-base leading-8 text-base-content/70 space-y-6">
                                <p>
                                    We believe in the power of communication and collaboration. We are always ready to hear from you, whether you&apos;re looking for support, interested in our products, or keen to join our journey to revolutionize productivity.
                                </p>
                                <p>
                                    Your insights, inquiries, and ideas matter to us. They help us shape our services to fit your needs and make our Intelligence-powered solutions better every day.
                                </p>
                                <p>
                                    So, don&apos;t hesitate. Use the contact form to send us a message. Alternatively, you can also reach us through our email address, provided below. We look forward to hearing from you and will respond as soon as possible.
                                </p>
                            </p>
                            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                                <div className="flex items-center gap-x-4">
                                    <dt className="flex-none">
                                        <span className="sr-only">Email</span>
                                        <BsFillEnvelopeAtFill className="size-6 text-base-300" aria-hidden="true" />
                                    </dt>
                                    <dd>
                                        <Link className="text-base-content/70 hover:text-base-content" to="mailto:contact@dovise.com">
                                            contact@dovise.com
                                        </Link>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <form action="#" method="POST" className="px-6 py-20 sm:py-24 lg:px-8 bg-base-100 z-20">
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                <div className="space-y-2" >
                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-base-content">
                                        First name
                                    </label>
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="input-field"
                                    />
                                </div>
                                <div className="space-y-2">
                                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-base-content">
                                        Last name
                                    </label>
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="input-field"
                                    />
                                </div>
                                <div className="sm:col-span-2 space-y-2">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-base-content">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        className="input-field"
                                    />
                                </div>
                                <div className="sm:col-span-2 space-y-2">
                                    <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-base-content">
                                        Phone number
                                    </label>
                                    <input
                                        type="tel"
                                        name="phone-number"
                                        id="phone-number"
                                        autoComplete="tel"
                                        className="input-field"
                                    />
                                </div>
                                <div className="sm:col-span-2 space-y-2">
                                    <label htmlFor="message" className="block text-sm font-medium leading-6 text-base-content">
                                        Message
                                    </label>
                                    <textarea
                                        name="message"
                                        id="message"
                                        rows={4}
                                        className="input-field"
                                        defaultValue={''}
                                    />
                                </div>
                            </div>
                            <div className="mt-8 flex justify-end">
                                <button
                                    type="submit"
                                    className="primary-button"
                                >
                                    Send message
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
    )
};

export default Contact;
