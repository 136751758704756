import PropTypes from "prop-types";
import {motion} from 'framer-motion';
import {GoStarFill} from "react-icons/go";
import React, {useCallback, useMemo, useState} from "react";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";

import Markdown from "../../Markdown/Markdown";
import RadialProgress from "../../RadialProgress/RadialProgress";
import GlideCaption from "../../../Pages/Auth/Products/TaskGlide/Chat/ChatBox/Indicators/GlideCaption";
import TaskGlideAddOns from "../../../Pages/Auth/Products/TaskGlide/Chat/ChatBox/AddOns/TaskGlideAddOns";
import MessageActions from "../../../Pages/Auth/Products/TaskGlide/Chat/ChatBox/Messages/Actions/MessageActions";
import {useStreamTaskGlide} from "../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";
import ToolMessage from "./ToolMessage";


const GlideResponse = ({ interaction, message, isLastMessage, onResponseDelete, chatBoxRef, pinningIsEnabled }) => {
    const { taskGlideStreamVF } = useStreamTaskGlide();

    const glideResponseInstance = message?.content;
    const tgResponseIsLoading = !glideResponseInstance?.main_response && isLastMessage && taskGlideStreamVF;
    const tgResponseIsStreaming = !!glideResponseInstance?.main_response && isLastMessage && taskGlideStreamVF;

    const messageIsStarred = glideResponseInstance?.is_pinned;

    const [isReviewExpanded, setIsReviewExpanded] = useState(true);
    const toggleReviewExpand = () => {
        setIsReviewExpanded((prev) => !prev);
    };

    const [isToolListExpanded, setIsToolListExpanded] = useState(false);
    const toggleToolListExpand = () => {
        setIsToolListExpanded((prev) => !prev);
    };

    const mainResponseText = useMemo(() => (
        tgResponseIsStreaming
            ? glideResponseInstance?.main_response + "  ▶"
            : glideResponseInstance?.main_response
    ), [taskGlideStreamVF, isLastMessage, glideResponseInstance?.main_response]);

    const reviewResponseText = useMemo(() => (
        tgResponseIsStreaming
            ? glideResponseInstance?.review_response + "  ▶"
            : glideResponseInstance?.review_response
    ), [taskGlideStreamVF, isLastMessage, glideResponseInstance?.review_response]);

    const toolsUsed = !taskGlideStreamVF && interaction?.messages?.filter(message => message?.type === 'tool_used');

    const formatTime = useCallback((timestamp) => {
        const date = new Date(timestamp);
        return isNaN(date.getTime()) ? "Just now" :
            date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    }, []);

    const time = useMemo(() => {
        const timestamp = glideResponseInstance?.timestamp;
        return timestamp ? formatTime(timestamp) : "Just now";
    }, [glideResponseInstance?.timestamp, formatTime]);

    return (
        <div className="flex flex-row space-x-2 group/task-glide-message" >
            <div className="h-full" >
                <RadialProgress
                    key={message?.id}
                    interaction={interaction}
                    message={message}
                />
            </div>

            <div className="col-span-full w-full">
                <div className="flex items-center justify-between mb-0.5">
                    <div className="flex flex-row items-center gap-x-2 w-fit max-w-full py-0.5">
                        <GlideCaption
                            glideState={interaction?.status?.action}
                            glideName={glideResponseInstance?.responding_glide?.name}
                        />
                        <span className="text-2xs sm:text-3xs text-base-content/70 whitespace-nowrap leading-none">{time}</span>
                        {messageIsStarred && <GoStarFill className="size-3.5 shrink-0 text-yellow-400 dark:text-yellow-500"/>}
                    </div>
                    <MessageActions scope="task-glide" message={message?.content} onDelete={onResponseDelete} chatBoxRef={chatBoxRef} pinningIsEnabled={pinningIsEnabled}/>
                </div>

                <div className="w-full">
                    {tgResponseIsLoading &&
                        <motion.div
                            className="w-fit h-fit"
                            animate={{opacity: [1, 0]}}
                            transition={{duration: 1, repeat: Infinity, times: [0, 0.5, 1]}}
                        >
                            ▶
                        </motion.div>}
                    <Markdown
                        text={mainResponseText}
                        className="bg-transparent min-w-full text-base-content"
                        codeIsCustomized={true}
                    />
                </div>
                {reviewResponseText && (
                    <div className="space-y-2 mt-2">
                        <button
                            type="button"
                            className="flex items-center space-x-1 cursor-pointer text-primary button-ring-focus"
                            onClick={toggleReviewExpand}
                        >
                            <p className="font-semibold text-xs" >Review</p>
                            {isReviewExpanded ? (
                                <MdKeyboardArrowUp className="size-4" />
                            ) : (
                                <MdKeyboardArrowDown className="size-4" />
                            )}
                        </button>

                        {isReviewExpanded && (
                            <div className="col-span-full w-full border border-dashed border-neutral-focus dark:border-neutral-focus-dark bg-neutral/50 rounded-md px-3 py-2">
                                {tgResponseIsLoading && (
                                    <motion.div
                                        className="w-fit h-fit"
                                        animate={{opacity: [1, 0]}}
                                        transition={{duration: 1, repeat: Infinity, times: [0, 0.5, 1]}}
                                    >
                                        ▶
                                    </motion.div>
                                )}
                                <Markdown
                                    text={reviewResponseText}
                                    className="bg-transparent min-w-full text-base-content"
                                    codeIsCustomized={true}
                                />
                            </div>
                        )}
                    </div>
                )}
                {toolsUsed?.length > 0 &&
                    <div className="space-y-2 mt-2">
                        <button
                            type="button"
                            className="flex items-center space-x-1 cursor-pointer text-primary button-ring-focus"
                            onClick={toggleToolListExpand}
                        >
                            <p className="font-semibold text-xs">Tools used</p>
                            {isToolListExpanded ?
                                <MdKeyboardArrowUp className="size-4"/> :
                                <MdKeyboardArrowDown className="size-4"/>}
                        </button>
                        {isToolListExpanded &&
                            <div className="grid grid-flow-col gap-4" >
                                {toolsUsed?.map(toolData => <ToolMessage
                                        key={toolData?.content?.tool_call_id}
                                        toolData={toolData}
                                    />)}
                            </div>}
                    </div>}
                <div>
                    <TaskGlideAddOns message={message}/>
                </div>
            </div>
        </div>
    )
};

GlideResponse.propTypes = {
    interaction: PropTypes.object,
    message: PropTypes.object,
    lastTool: PropTypes.object,
    isLastMessage: PropTypes.bool,
    onResponseDelete: PropTypes.func,
    animationVariants: PropTypes.object,
    chatBoxRef: PropTypes.object,
    pinningIsEnabled: PropTypes.bool,
};

export default GlideResponse;
