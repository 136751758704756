import React from "react";
import {VscClose} from "react-icons/vsc";
import {SiTask} from "react-icons/si";

import Markdown from "../../../../Components/Markdown/Markdown";


const userQuery = "Add a view for authenticating users using Django's built-in authentication system.";

const generatedCode = `\`\`\`python\nfrom django.contrib.auth import authenticate, login
from django.http import JsonResponse
from django.views.decorators.http import require_http_methods

@require_http_methods(["POST"])
def my_login_view(request):
    # Extract username and password from request.POST
    username = request.POST.get('username')
    password = request.POST.get('password')
    
    # Authenticate the user
    user = authenticate(request, username=username, password=password)
    if user is not None:
        # The credentials are valid
        login(request, user)
        return JsonResponse({"message": "Login successful"}, status=200)
    else:
        # Authentication failed
        return JsonResponse({"message": "Invalid username or password"}, status=400)
\n\`\`\``;


const ProgrammingMockFrame = () => {

    return (
        <div className="bg-base-100 w-[48rem] sm:w-[57rem] max-w-none rounded-md ring-1 ring-neutral shadow-[0px_0px_50px_rgba(0,0,0,0.20)] dark:shadow-[0px_0px_50px_rgba(0,0,0,0.75)] mx-auto lg:-ml-0 text-sm overflow-hidden">
            <div className="flex items-center border-b border-neutral-focus/70 dark:border-neutral-focus-dark/70">
                <div className="flex items-center text-center px-3 py-1.5 text-primary-focus dark:text-primary-focus-dark border-r border-neutral-focus/70 dark:border-neutral-focus-dark/70 bg-base-200 space-x-1.5">
                    <p>authenticate_user.py</p>
                    <VscClose className="text-base-content/50 shrink-0"/>
                </div>
                <div className="flex items-center text-center px-3 py-1.5">generate_response.py</div>
            </div>
            <div className="px-4 pt-4 space-y-4">
                <div className="rounded-md bg-base-100 text-base-content outline-0 focus:ring-0 flex ring-1 ring-neutral-focus/70 dark:ring-neutral-focus-dark/70 shadow-[0px_0px_10px_rgba(0,0,0,0.10)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.3)] w-full px-3 py-2 space-x-3">
                    <SiTask className="size-5 text-base-content shrink-0"/>
                    <p>{userQuery}</p>
                </div>
                <div>
                    <Markdown
                        text={generatedCode}
                        className="bg-transparent min-w-full text-base-content"
                        codeIsCustomized={true}
                        codeClassName="bg-base-100"
                        includeCodeHeader={false}
                    />
                </div>
            </div>
        </div>
    )
};

export default ProgrammingMockFrame;
