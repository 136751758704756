import React from "react";
import {Link} from "react-router-dom";

import DocumentationMockFrame from "./DocumentationMockFrame";
import BgCheckers from "../../../../Components/Bg Checkers/BgCheckers";
import routes from "../../../../Routes/routes";


const DocumentationSolution = () => {

    return (
        <div className="relative isolate py-16 sm:py-24">
            <BgCheckers lines="stroke-accent/10" boxes="fill-accent/10"/>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 gap-x-6 gap-y-10 content-center mx-auto max-w-7xl px-6 lg:px-8 py-16">
                <div className="flex flex-col justify-center mx-auto max-w-2xl text-start">
                    <h2 className="text-base font-medium leading-7 text-accent-focus dark:text-accent-focus-dark">Documentation Solutions</h2>
                    <h1 className="mt-2 font-bold tracking-tight text-base-content text-[min(7.5vw,40px)] leading-tight">Intelligence-Driven Documentation Excellence</h1>
                    <p className="leading-8 mx-auto mt-6 max-w-2xl text-[min(5vw,18px)] text-base-content/70">Elevate your documentation with our Super Intelligent solutions, designed to streamline the creation, management, and optimization of your technical and business documents. Experience efficiency like never before.</p>
                </div>
                <div className="relative scale-90 h-[550px]" >
                    <DocumentationMockFrame/>
                </div>
            </div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-8">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div className="space-y-1">
                        <h3 className="text-lg font-medium">Effortless Document Creation</h3>
                        <p className="text-base-content/70">Begin with ease. Our Super Intelligent agents help you start your documents on the right foot, suggesting outlines and formats that cater to your specific needs. Whether it’s technical specifications, business plans, or research reports, get a head start with templates that are as smart as they are simple to use.</p>
                    </div>
                    <div className="space-y-1">
                        <h3 className="text-lg font-medium">Intelligent Writing Assistant</h3>
                        <p className="text-base-content/70">Say goodbye to writer’s block. Our dynamic writing assistant supports you at every step, providing real-time grammar corrections, style improvements, and vocabulary suggestions. It adapts to your tone and context, ensuring your document is not just error-free, but also eloquently articulated.</p>
                    </div>
                </div>
                <div className="space-y-1">
                    <h3 className="text-lg font-medium">Real-Time Collaboration</h3>
                    <p className="text-base-content/70">Collaborate without constraints. Our platform’s real-time collaboration tools allow multiple users to edit, comment, and suggest changes simultaneously. With an intuitive interface, you can track revisions, merge edits, and manage suggestions with ease. The Super Intelligent agent ensures that document integrity is maintained by intelligently resolving conflicts and merging changes from various contributors. This collaborative environment is designed to promote synergy and enhance the quality of your collective output.</p>
                </div>
                <div className="space-y-1">
                    <h3 className="text-lg font-medium">Automated Content Optimization</h3>
                    <p className="text-base-content/70">Leverage the power of Super Intelligence to ensure your content is engaging and effective. Our Automated Content Optimization scans your document, identifying areas for enhancement—be it simplifying complex sentences, improving passive constructions, or ensuring keyword density for SEO. The system provides actionable insights, like highlighting overused words and suggesting alternatives for clarity and impact. This proactive tool keeps your content aligned with the best practices of documentation standards.</p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                    <div className="space-y-1">
                        <h3 className="text-lg font-medium">Multilingual Support</h3>
                        <p className="text-base-content/70">Cross language barriers with our multilingual documentation support. Create, edit, and share documents in multiple languages, all with the same level of Super Intelligent assistance.</p>
                    </div>
                    <div className="space-y-1">
                        <h3 className="text-lg font-medium">Document Analytics</h3>
                        <p className="text-base-content/70">Gain insights into your documentation process with analytics that track changes, monitor engagement, and suggest content improvements. Make data-driven decisions to refine your documents continuously.</p>
                    </div>
                    <div className="space-y-1">
                        <h3 className="text-lg font-medium">Versatile Exporting Options</h3>
                        <p className="text-base-content/70">Easily convert your documents into various formats, including PDF, DOCX, YAML and many more for any end-use. Our platform ensures a smooth transition with preserved formatting, enhanced by ProseMaster&apos;s forthcoming capabilities.</p>
                    </div>
                </div>
                <div className="space-y-1">
                    <h3 className="text-lg font-medium">Secure & Compliant</h3>
                    <p className="text-base-content/70">Protect your content with our robust security measures. End-to-end encryption ensures that your documents are secure at every stage of the writing process. The platform is designed to meet the highest compliance standards, regularly updated to keep up with global data protection regulations. We also offer advanced permission settings, so you can control who has access to sensitive information, maintaining the integrity and confidentiality of your documents.</p>
                </div>
                <div className="space-y-1">
                    <h3 className="text-lg font-medium">Customizable Experience</h3>
                    <p className="text-base-content/70">Craft a documentation experience that is distinctly yours with our customization options. From the Super Intelligent’s involvement level to the aesthetic elements of your document templates, everything can be tailored. We understand that your branding and workflow are unique, and our platform is designed to be flexible enough to accommodate that. Create a documentation process that not only delivers in terms of quality but also aligns with your brand’s identity.</p>
                </div>
                <div className="space-y-2 pt-8">
                    <h3 className="text-xl font-medium">Begin with Our Documentation Solutions</h3>
                    <p className="text-base-content/70 text-lg">Leverage our intelligent documentation platform today, and anticipate the enhanced capabilities that <Link
                        className="text-secondary hover:text-secondary-focus dark:hover:text-secondary-focus-dark"
                        to={routes.unAuth.products.find(
                            product => product.name === "ProseMaster"
                        ).href}>ProseMaster</Link> will bring. Transform how you create, collaborate, and deliver documentation. Embrace a future where every document is a testament to efficiency and quality.</p>
                </div>
            </div>
        </div>
    );
};

export default DocumentationSolution;
