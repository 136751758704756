import React from 'react'
import {Bars3Icon} from '@heroicons/react/24/outline'
import {Link, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {RiArrowRightLine, RiArrowRightSLine} from "react-icons/ri";

import routes from "../../Routes/routes";
import {companyLogo} from "../../Design/companyLogo";
import {useAuthentication} from "../../Hooks/apiHooks/users/useAuthentication";
import HeaderSideBar from "./HeaderSideBar";
import HeaderPopOvers from "./HeaderPopOvers";
import ThemeButtons from "../ThemeButtons/ThemeButtons";


const Header = () => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();

    const [searchParams, setSearchParams] = useSearchParams();
    const openParam = searchParams.get('sidebar');
    const isSidebarOpen = openParam === 'open';

    const setOpen = (newOpen) => {
        setSearchParams({ 'sidebar': newOpen ? 'open' : 'close' });
    };
    const handleMenuClick = () => setOpen(!isSidebarOpen);

    return (
        <header className="absolute inset-x-0 top-0 z-50 bg-gradient-to-b from-base-100 via-base-100/75 to-transparent" >
            <nav className="mx-auto flex max-w-7xl items-center justify-between md:px-8 px-6 py-6" aria-label="Global">
                <div className="flex lg:flex-1">
                    <Link to={routes.unAuth.home.href} >
                        <span className="sr-only">Dovise</span>
                        <img className="h-8 w-auto transition-transform hover:sm:scale-110 duration-500" src={companyLogo} alt="" />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md text-base-content"
                        onClick={handleMenuClick}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-7 w-7" aria-hidden="true" />
                    </button>
                </div>
                <HeaderPopOvers />
                <div className="hidden space-x-4 lg:flex lg:flex-1 lg:justify-end items-center">
                    <ThemeButtons />
                    <Link
                        to={userIsAuthenticated ? routes.auth.taskGlide.home.href : routes.unAuth.login.href}
                        onClick={() => !userIsAuthenticated && dispatch({type: "RESET_USER_MANAGEMENT"})}
                        className="rounded-primary-button group"
                    >
                        <p>{userIsAuthenticated ? "Dashboard" : "Sign in"}</p>
                        <div>
                            <RiArrowRightSLine className="flex group-hover:sm:hidden h-4 w-4 shrink-0" aria-hidden="true" />
                            <RiArrowRightLine className="group-hover:sm:flex hidden h-4 w-4 shrink-0" aria-hidden="true" />
                        </div>
                    </Link>
                </div>
            </nav>
            <HeaderSideBar handleMenuClick={handleMenuClick} isSidebarOpen={isSidebarOpen} />
        </header>
    )
};

export default Header;
