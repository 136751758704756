import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {allTgConversationsLIST, clearAllTgConversationsListingVF} from "../../../../../api/features/superIntelligence/taskGlide/conversations/listAllTgConversationsSlice";
import {useAuthentication} from "../../../users/useAuthentication";


export const useListAllTgConversations = (
    {vfExists=false} = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Task Glide
    const {
        tgConversationsListingVF,
        tgConversationsListingApiState,
        tgConversationsListingResponse,
        tgConversationsListingError,
    } = useSelector(state => state.listAllTGConversationsReducer);

    // API states with visual feedback
    const tgConversationsListingSuccessVF = tgConversationsListingApiState === 'succeeded' && tgConversationsListingVF;
    const tgConversationsListingFailureVF = tgConversationsListingApiState === 'failed' && tgConversationsListingVF;

    const listAllTgConversations = (page = 1, pageSize = 20) => {
        const params = `?page=${page}&page_size=${pageSize}`;
        userIsAuthenticated && dispatch(allTgConversationsLIST(params));
    };

    useEffect(() => {
        if (vfExists && tgConversationsListingSuccessVF) {
            const timer = setTimeout(() => {
                dispatch(clearAllTgConversationsListingVF());
            }, 250);

            return () => clearTimeout(timer);
        }
    }, [tgConversationsListingApiState, tgConversationsListingVF]);

    return {
        listAllTgConversations,
        tgConversationsListingVF,
        tgConversationsListingApiState,
        tgConversationsListingResponse,
        tgConversationsListingError,
        tgConversationsListingSuccessVF,
        tgConversationsListingFailureVF
    };
};

