import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const publicGlidesGET = createAsyncThunk(
    'taskGlide/glides/public-glides/list',
    async (values, thunkAPI) => {
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_LIST_PUBLIC_GLIDES_PATH}`,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    publicGlidesListingVF: false,
    publicGlidesListingApiState: 'idle',
    publicGlidesListingResponse: null,
    publicGlidesListingError: null,
};

export const listPublicGlidesSlice = createSlice({
    name: 'publicGlidesListing',
    initialState: INITIAL_STATE,
    reducers: {
        clearPublicGlidesListingVF: (state) => {
            state.publicGlidesListingVF = false;
        },
        resetPublicGlidesListing: (state) => {
            state.publicGlidesListingVF = false;
            state.publicGlidesListingApiState = 'idle';
            state.publicGlidesListingResponse = null;
            state.publicGlidesListingError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(publicGlidesGET.pending, (state) => {
                state.publicGlidesListingApiState = 'loading';
                state.publicGlidesListingVF = true;
            })
            .addCase(publicGlidesGET.fulfilled, (state, action) => {
                state.publicGlidesListingApiState = 'succeeded';
                state.publicGlidesListingResponse = action?.payload;
                state.publicGlidesListingVF = true;
                state.publicGlidesListingError = null;
            })
            .addCase(publicGlidesGET.rejected, (state, action) => {
                state.publicGlidesListingApiState = 'failed';
                state.publicGlidesListingError = action?.payload;
                state.publicGlidesListingVF = true;
                state.publicGlidesListingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearPublicGlidesListingVF,
    resetPublicGlidesListing
} = listPublicGlidesSlice.actions;

export default listPublicGlidesSlice.reducer;
