import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearGlideToolsListingVF, glideToolsGET, resetGlideToolsListing} from "../../../../../api/features/superIntelligence/taskGlide/glides/listGlideToolsSlice";


export const useListGlideTools = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // MyGlides
    const {
        glideToolsListingVF,
        glideToolsListingApiState,
        glideToolsListingResponse,
        glideToolsListingError,
    } = useSelector(state => state.listGlideToolsReducer);

    // API states with visual feedback
    const glideToolsListingSuccessVF = glideToolsListingApiState === 'succeeded' && glideToolsListingVF;
    const glideToolsListingFailureVF = glideToolsListingApiState === 'failed' && glideToolsListingVF;

    const listGlideTools = () => userIsAuthenticated && dispatch(glideToolsGET());

    useEffect(() => {
        if (vfExists) {
            if (glideToolsListingSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearGlideToolsListingVF());
                }, 500);

                return () => clearTimeout(timer);
            } else if (glideToolsListingFailureVF) {
                dispatch(setAlert({
                    message: glideToolsListingResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetGlideToolsListing());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [glideToolsListingApiState, glideToolsListingVF]);

    return {
        listGlideTools,
        glideToolsListingVF,
        glideToolsListingApiState,
        glideToolsListingResponse,
        glideToolsListingError,
        glideToolsListingSuccessVF,
        glideToolsListingFailureVF
    };
};
