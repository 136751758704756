import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {clearUserPreferencesUpdateVF, resetUserPreferencesUpdate, userPreferencesUPDATE} from "../../../api/features/users/updateUserPreferencesSlice";
import {useGetUserPreferences} from "./useGetUserPreferences";
import {useAuthentication} from "./useAuthentication";


export const useUpdateUserPreferences = ({vfExists=false, refreshAndReset=true} = {}) => {
    const dispatch = useDispatch();

    // User preferences
    const {
        userPreferencesUpdateVF,
        userPreferencesUpdateApiState,
        userPreferencesUpdateResponse,
        userPreferencesUpdateError,
    } = useSelector(state => state.updateUserPreferencesReducer);
    const { getUserPreferences } = useGetUserPreferences();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // API states with visual feedback
    const userPreferencesUpdateSuccessVF = userPreferencesUpdateApiState === 'succeeded' && userPreferencesUpdateVF;
    const userPreferencesUpdateFailureVF = userPreferencesUpdateApiState === 'failed' && userPreferencesUpdateVF;

    // Function to be called on form submission
    const updateUserPreferences = (values) => {
        const mappedValues = {
            updates: values?.updates,
            offers: values?.offers,
            alerts: values?.alerts,
            preferred_language: values?.language?.name,
        };
        userIsAuthenticated && dispatch(userPreferencesUPDATE(mappedValues));
    };

    useEffect(() => {
        if (vfExists && (userPreferencesUpdateSuccessVF || userPreferencesUpdateFailureVF)) {
            dispatch(setAlert({
                message: userPreferencesUpdateApiState === 'succeeded' ? userPreferencesUpdateResponse?.message : userPreferencesUpdateError?.message || "Please try again.",
                type: userPreferencesUpdateApiState === 'succeeded' ? "success" : "error"
            }));
            dispatch(incrementAlertKey());

            const timer = setTimeout(() => {
                dispatch(clearUserPreferencesUpdateVF());
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [userPreferencesUpdateApiState]);

    useEffect(() => {
        if (refreshAndReset && userPreferencesUpdateApiState === 'succeeded') {
            if (!userPreferencesUpdateVF) {
                getUserPreferences();
                dispatch(resetUserPreferencesUpdate());
            }
        }
    }, [userPreferencesUpdateApiState, userPreferencesUpdateVF]);

    return { 
        updateUserPreferences,
        userPreferencesUpdateApiState,
        userPreferencesUpdateSuccessVF,
        userPreferencesUpdateFailureVF,
        userPreferencesUpdateVF
    };
};