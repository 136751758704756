import React, {useEffect, useState} from "react";
import clsx from "clsx";
import * as yup from "yup";
import {CgSpinner} from "react-icons/cg";
import InputMask from "react-input-mask";
import {Field, Form, Formik} from "formik";
import {RiCheckboxCircleLine, RiCloseCircleLine} from "react-icons/ri";

import Nav from "../../Shell/Nav/Nav";
import SettingsNav from "../SettingsNav";
import AccountDeletionModal from "../Modals/AccountDeletionModal";
import {useGetUserInfo} from "../../../../Hooks/apiHooks/users/useGetUserInfo";
import {useUpdateUserInfo} from "../../../../Hooks/apiHooks/users/useUpdateUserInfo";
import AddressAutocompletion from "../../../../Components/AddressAutocompletion/AddressAutocompletion";
import AccountSettingsSkeleton from "./AccountSettingsSkeleton";
import {useChangePassword} from "../../../../Hooks/apiHooks/users/useChangePassword";
import Alerts from "../../../../Components/Alerts/Alerts";
import {useAlert} from "../../../../Hooks/frontendHooks/useAlert";


export const userAvatar = ""
const personalInfoValidationSchema = yup.object().shape({
    firstName: yup.string()
        .required('First name is required')
        .min(2, 'Must be at least 2 characters')
        .matches(/^[a-zA-Z\s]*$/, 'Can only contain letters and spaces'),
    lastName: yup.string()
        .required('Last name is required')
        .min(2, 'Must be at least 2 characters')
        .matches(/^[a-zA-Z\s]*$/, 'Can only contain letters and spaces'),
    occupation: yup.string()
        .required('Occupation is required')
        .min(2, 'Must be at least 2 characters')
        .matches(/^[a-zA-Z\s]*$/, 'Can only contain letters and spaces'),
    dateOfBirth: yup.string()
        .required('Date of birth is required')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Must be in format of YYYY-MM-DD')
        .test('valid-date', 'Date is not valid', value => {
            if (!value) {return true;} // Let required handle this case
            const [year, month, day] = value.split('-');
            const date = new Date(year, month - 1, day);
            return date.getFullYear() === Number(year)
                && date.getMonth() === Number(month) - 1
                && date.getDate() === Number(day);
        })
        .test('not-future-date', 'Date of birth cannot be in the future', value => {
            if (!value) {return true;}
            const [year, month, day] = value.split('-');
            const date = new Date(year, month - 1, day);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date <= today;
        }),
    phoneNumber: yup.string()
        .required('Phone number is required')
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Must be in format of (123) 456-7890'),
    address1: yup.string().required('Street address is required'),
    address2: yup.string(),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    zipCode: yup.string().required('Zip code is required'),
});
const passwordChangeValidationSchema = yup.object().shape({
    old_password: yup.string().required('Password is required'),
    new_password: yup.string().required('Password is required'),
    new_password2: yup.string().required('Change passwords is required'),
});

export const getUserInfoInitialValues = (userInfo) => ({
    firstName: userInfo?.first_name || '',
    lastName: userInfo?.last_name || '',
    occupation: userInfo?.occupation || '',
    dateOfBirth: userInfo?.date_of_birth || '',
    phoneNumber: userInfo?.phone_number || '',
    address1: userInfo?.address1 || '',
    address2: userInfo?.address2 || '',
    city: userInfo?.city || '',
    state: userInfo?.state || '',
    country: userInfo?.country || '',
    zipCode: userInfo?.zip_code || '',
});

const passwordChangeInitialValues = {
    old_password: '',
    new_password: '',
    new_password2: '',
}


const AccountSettings = () => {
    const [editingPersonalInfo, setEditingPersonalInfo] = useState(false);
    const {
        userInfoRetrievalResponse,
        userInfoRetrievalVF,
    } = useGetUserInfo({ vfExists: true });
    const {
        updateUserInfo,
        userInfoUpdateApiState,
        userInfoUpdateVF,
        userInfoUpdateFailureVF,
    } = useUpdateUserInfo({ vfExists: true, refreshAndReset: false });
    const {
        changePassword,
        passwordChangeVF,
        passwordChangeSuccessVF,
        passwordChangeFailureVF,
        passwordChangeLoadingVF
    } = useChangePassword({ vfExists: true });
    const { alert, alertKey} = useAlert();

    const userInfoInitialValues = getUserInfoInitialValues(userInfoRetrievalResponse)
    const [personalInfoPrevValues, setPersonalInfoPrevValues] = useState({});

    const onPersonalInfoUpdate = (values, { setSubmitting }) => {
        updateUserInfo(values);
        setSubmitting(false);
    };

    useEffect(() => {
        if(userInfoUpdateApiState === 'succeeded' && !userInfoUpdateVF) {
            setEditingPersonalInfo(false);
        }
    }, [userInfoUpdateApiState, userInfoUpdateVF]);

    const onChangePassword = (values, { setSubmitting }) => {
        changePassword(values);
        setSubmitting(false);
    };

    return (
        <>
            <Nav/>
            <SettingsNav/>

            {userInfoRetrievalVF ? <AccountSettingsSkeleton /> : <div className="bg-base-100 overflow-y-auto h-[calc(100dvh-112px)] scrollbar">
                <div className="border-b border-neutral">
                    <div className="grid grid-cols-1 gap-x-20 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                        <div className="col-span-full md:col-span-4">
                            <h2 className="text-base font-semibold leading-7 text-base-content">Personal information</h2>
                            <p className="mt-1 text-sm leading-6 text-base-content/70">
                                Your personal information will be used to give you a personalized experience in your interactions with the assistants.
                            </p>
                        </div>

                        <Formik
                            initialValues={getUserInfoInitialValues(userInfoRetrievalResponse)}
                            onSubmit={onPersonalInfoUpdate}
                            validationSchema={personalInfoValidationSchema}
                        >
                            {({errors, touched, isSubmitting, isValid, values, setFieldValue, handleBlur, validateForm, resetForm}) => {
                                const areAddressFieldsEmpty = values.address1 === "" || values.city === "" || values.state === "" || values.country === "" || values.zipCode === "";

                                const handlePersonalInfoEditToggle = () => {
                                    setPersonalInfoPrevValues(values);
                                    setEditingPersonalInfo(!editingPersonalInfo);
                                };

                                const handlePersonalInfoCancel = () => {
                                    Object.keys(personalInfoPrevValues).forEach(key => {
                                        setFieldValue(key, personalInfoPrevValues[key]);
                                    });
                                    resetForm({});
                                    setEditingPersonalInfo(false);
                                };

                                return (
                                    <Form className="relative md:col-start-5 md:col-span-5 lg:col-start-6">
                                        <div className="grid grid-cols-1 gap-y-5 sm:max-w-none sm:grid-cols-4">
                                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                                <label htmlFor="first-name" className="col-span-3 text-sm" >
                                                    First name
                                                </label>
                                                <Field
                                                    name="firstName"
                                                    type="text"
                                                    placeholder="John"
                                                    disabled={!editingPersonalInfo}
                                                    className="input-field col-span-4"
                                                />
                                            </div>

                                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                                <label htmlFor="last-name" className="col-span-3 text-sm">
                                                    Last name
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="lastName"
                                                    autoComplete="family-name"
                                                    placeholder="Doe"
                                                    disabled={!editingPersonalInfo}
                                                    className="input-field col-span-4"
                                                />
                                            </div>

                                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                                <label htmlFor="occupation" className="col-span-3 text-sm">
                                                    Occupation
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="occupation"
                                                    autoComplete="organization"
                                                    placeholder="Software Engineer"
                                                    disabled={!editingPersonalInfo}
                                                    className="input-field col-span-4"
                                                />
                                            </div>

                                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                                <label htmlFor="date-of-birth" className="col-span-3 text-sm">
                                                    Date of birth
                                                </label>
                                                <InputMask
                                                    mask="9999-99-99"
                                                    value={values.dateOfBirth}
                                                    disabled={!editingPersonalInfo}
                                                    onChange={e => {
                                                        setFieldValue('dateOfBirth', e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                >
                                                    {() => (
                                                        <Field
                                                            type="text"
                                                            name="dateOfBirth"
                                                            placeholder="1999-12-31"
                                                            autoComplete="bday"
                                                            disabled={!editingPersonalInfo}
                                                            className="input-field col-span-4"
                                                        />
                                                    )}
                                                </InputMask>
                                            </div>

                                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                                <label htmlFor="phone-number" className="col-span-3 text-sm">
                                                    Phone number
                                                </label>
                                                <InputMask
                                                    mask="(999) 999-9999"
                                                    value={values.phoneNumber}
                                                    disabled={!editingPersonalInfo}
                                                    onChange={e => {
                                                        setFieldValue('phoneNumber', e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                >
                                                    {() => (
                                                        <Field
                                                            type="text"
                                                            name="phoneNumber"
                                                            placeholder="(123) 456-7890"
                                                            autoComplete="tel"
                                                            disabled={!editingPersonalInfo}
                                                            className="input-field col-span-4"
                                                        />
                                                    )}
                                                </InputMask>
                                            </div>

                                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                                <label htmlFor="phone-number" className="col-span-3 text-sm">Address</label>
                                                <AddressAutocompletion
                                                    key={editingPersonalInfo ? "editing" : "not-editing"}
                                                    relativeToScreen={true}
                                                    disabled={!editingPersonalInfo}
                                                    wrapperClass="col-span-4"
                                                    className="input-field"
                                                    defaultValue={[userInfoInitialValues?.address1, userInfoInitialValues?.city, userInfoInitialValues?.state, userInfoInitialValues?.country, userInfoInitialValues?.zipCode].filter(Boolean).join(", ")}
                                                    placeholder={"123 Main St, New York, NY, USA, 10001"}
                                                    onSelect={addressComponents => {
                                                        setFieldValue("address1", addressComponents.address1);
                                                        setFieldValue("address2", addressComponents.address2);
                                                        setFieldValue("city", addressComponents.city);
                                                        setFieldValue("state", addressComponents.state);
                                                        setFieldValue("country", addressComponents.countryCode);
                                                        setFieldValue("zipCode", addressComponents.zip_code);

                                                        // Trigger Formik validation
                                                        setTimeout(() => {
                                                            validateForm();
                                                        }, 0);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-6 flex items-center justify-end gap-x-3">
                                            <button
                                                type="button"
                                                onClick={editingPersonalInfo ? handlePersonalInfoCancel : handlePersonalInfoEditToggle}
                                                className="empty-button"
                                            >
                                                {editingPersonalInfo ? 'Cancel' : 'Edit'}
                                            </button>
                                            <button
                                                type="submit"
                                                disabled={isSubmitting || !isValid || values.firstName === "" || values.lastName === "" || values.occupation === "" || values.dateOfBirth === "" || values.phoneNumber === "" || userInfoUpdateVF || areAddressFieldsEmpty}
                                                className="primary-button"
                                            >
                                                {userInfoUpdateVF ? "Saving..." : "Save"}
                                            </button>
                                        </div>

                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>

                <div className="border-b border-neutral">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" >
                        <div className="col-span-full md:col-span-4">
                            <h2 className="text-base font-semibold leading-7 text-base-content">Change password</h2>
                            <p className="mt-1 text-sm leading-6 text-base-content/70">
                                Update your password associated with your account.
                            </p>
                        </div>

                        <Formik
                            initialValues={passwordChangeInitialValues}
                            onSubmit={onChangePassword}
                            validationSchema={passwordChangeValidationSchema}
                        >
                            {({ errors, touched, isSubmitting, isValid }) => (
                                <Form className="relative md:col-start-5 md:col-span-5 lg:col-start-6">
                                    <div className="grid grid-cols-1 gap-y-5 sm:max-w-none sm:grid-cols-4">
                                        <div className="group relative col-span-full grid grid-cols-7 items-center">
                                            <label htmlFor="old_password" className="col-span-3 text-sm">Current password</label>
                                            <Field
                                                type="password"
                                                name="old_password"
                                                placeholder="************"
                                                autoComplete="current_password"
                                                className="input-field col-span-4"
                                            />
                                        </div>

                                        <div className="group relative col-span-full grid grid-cols-7 items-center">
                                            <label htmlFor="new_password" className="col-span-3 text-sm">New password</label>
                                            <Field
                                                type="password"
                                                name="new_password"
                                                autoComplete="new_password"
                                                placeholder="************"
                                                className="input-field col-span-4"
                                            />
                                        </div>

                                        <div className="group relative col-span-full grid grid-cols-7 items-center">
                                            <label htmlFor="confirm_password" className="col-span-3 text-sm">Confirm password</label>
                                            <Field
                                                type="password"
                                                name="new_password2"
                                                autoComplete="new_password2"
                                                placeholder="************"
                                                className="input-field col-span-4"
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-3">
                                        <button
                                            type="submit"
                                            className={clsx(
                                                passwordChangeFailureVF ? "error-button" :
                                                    passwordChangeSuccessVF ? "success-button" :
                                                        "primary-button")}
                                            disabled={isSubmitting || !isValid || passwordChangeFailureVF || passwordChangeSuccessVF}
                                        >
                                            <CgSpinner
                                                className={clsx(
                                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                                    passwordChangeLoadingVF ? "" : "hidden"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <RiCloseCircleLine
                                                className={clsx(
                                                    "absolute inset-0 m-auto h-5 w-5",
                                                    passwordChangeFailureVF ? "" : "hidden"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <RiCheckboxCircleLine
                                                className={clsx(
                                                    "absolute inset-0 m-auto h-5 w-5",
                                                    passwordChangeSuccessVF ? "" : "hidden"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <p className={passwordChangeVF ? "invisible" : ""}>
                                                Save
                                            </p>
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>

                <div>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" >
                        <div className="col-span-full md:col-span-4">
                            <h2 className="text-base font-semibold leading-7 text-base-content">Delete account</h2>
                            <div className="mt-1 text-sm leading-6 text-base-content/70 space-x-1" >
                                <p className="inline" >
                                    No longer want to use our services? You can delete your account here. All information related to this account will be deleted permanently after your confirmation.
                                </p>
                                <p className="inline italic" >
                                    Be advised your subscription will be canceled once your account is deleted and you will lose access to all your products.
                                </p>
                            </div>
                        </div>

                        <form className="relative md:col-start-6 lg:col-start-7 md:col-span-4">
                            <div className="flex items-center justify-end gap-x-3">
                                <AccountDeletionModal />
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {passwordChangeFailureVF &&
            <Alerts type={alert?.type} keyValue={alertKey} message={alert?.message} />}
        </>
    );
};

export default AccountSettings;
