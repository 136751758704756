import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "./useAuthentication";
import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {clearEmailSubscriptionVF, emailSubscriptionPOST, resetEmailSubscription} from "../../../api/features/users/emailSubscriptionSlice";


export const useEmailSubscription = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // MyGlides
    const {
        emailSubscriptionVF,
        emailSubscriptionApiState,
        emailSubscriptionResponse,
        emailSubscriptionError,
    } = useSelector(state => state.emailSubscriptionReducer);

    // API states with visual feedback
    const emailSubscriptionSuccessVF = emailSubscriptionApiState === 'succeeded' && emailSubscriptionVF;
    const emailSubscriptionFailureVF = emailSubscriptionApiState === 'failed' && emailSubscriptionVF;

    const subscribeToEmail = (values) => dispatch(emailSubscriptionPOST(values));

    useEffect(() => {
        if (vfExists) {
            if (emailSubscriptionSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearEmailSubscriptionVF());
                }, 250);

                return () => clearTimeout(timer);
            } else if (emailSubscriptionFailureVF) {
                dispatch(setAlert({
                    message: emailSubscriptionResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetEmailSubscription());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [emailSubscriptionApiState, emailSubscriptionVF]);

    return {
        subscribeToEmail,
        emailSubscriptionVF,
        emailSubscriptionApiState,
        emailSubscriptionResponse,
        emailSubscriptionError,
        emailSubscriptionSuccessVF,
        emailSubscriptionFailureVF
    };
};
