import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {HiMiniArrowDownCircle, HiMiniArrowUpCircle, HiMiniCheckCircle, HiMiniClock, HiMiniXCircle} from "react-icons/hi2";
import {ImTable} from "react-icons/im";
import PropTypes from "prop-types";
import {motion} from "framer-motion";

import BillingNav from "../BillingNav";
import Nav from "../../Shell/Nav/Nav";
import {useListTransactions} from "../../../../Hooks/apiHooks/payments/useListTransactions";
import InvoicesSkeleton from "./InvoicesSkeleton";


export const statusBadges = {
    succeeded: 'success-badge',
    pending: 'warning-badge',
    failed: 'error-badge',
    refunded: 'neutral-badge',
    submitted: 'success-badge',
};

const icons = {
    submitted: <HiMiniArrowUpCircle className={"h-5 w-5"}/>,
    succeeded: <HiMiniCheckCircle className={"h-5 w-5"}/>,
    pending: <HiMiniClock className={"h-5 w-5"}/>,
    failed: <HiMiniXCircle className={"h-5 w-5"}/>,
    refunded: <HiMiniArrowDownCircle className={"h-5 w-5"}/>,
};

export const PaginationButton = ({ onClick, disabled, children }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className="relative standard-button"
    >
        {children}
    </button>
);

PaginationButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
};


const formatDate = (unixTimestamp) => {
    return new Date(unixTimestamp * 1000).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};


const Invoices = () => {
    const {
        listTransactions,
        transactionListingResponse,
        transactionListingVF
    } = useListTransactions({ vfExists: true });

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(transactionListingResponse?.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentInvoices = transactionListingResponse?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        if (!transactionListingResponse) {
            listTransactions();
        }
    }, []);

    return (
        <>
            <Nav/>
            <BillingNav />

            <div className={clsx(
                "bg-base-100 sticky top-28 right-0 left-0 lg:left-16 border-b border-neutral z-10"
                , transactionListingResponse?.length > 0 ? 'py-4' : 'py-5'
            )}>
                <div className="sm:flex sm:items-center sm:justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" >
                    <div className="min-w-0 flex-1 sm:flex-auto">
                        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:tracking-tight text-base-content">Billing History</h1>
                    </div>
                    {transactionListingResponse?.length > 0 && (
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                                type="button"
                                className="primary-button"
                            >
                                Print
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="scrollbar overflow-y-auto h-[calc(100dvh-274px)] sm:h-[calc(100dvh-230px)]">
                <div className="mx-auto px-6 lg:px-9 max-w-5xl" >
                    {transactionListingVF ? <InvoicesSkeleton /> :
                        transactionListingResponse?.length > 0 ? (
                        <table className="table-fixed w-full px-px">
                            <thead className="border-b border-neutral text-base-content uppercase tracking-wide text-xs">
                                <tr>
                                    <th scope="col" className="py-3.5 text-left px-2" >
                                        Invoice
                                    </th>
                                    <th scope="col" className="hidden py-3.5 lg:table-cell text-left px-2" >
                                        Date
                                    </th>
                                    <th scope="col" className="hidden py-3.5 md:table-cell text-left px-2" >
                                        Payment Method
                                    </th>
                                    <th scope="col" className="py-3.5 text-left px-2" >
                                        Status
                                    </th>
                                    <th scope="col" className="py-3.5 text-right px-2" >
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-neutral/5" >
                                {currentInvoices.map((transaction, index) => (
                                    <motion.tr
                                        key={index}
                                        initial={{ opacity: 0, y: 10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: index * 0.1 }}
                                        className="hover:bg-neutral/5"
                                    >
                                        <td className="whitespace-nowrap py-2 text-sm font-medium text-base-content text-left px-2" >
                                            <div className="flex gap-x-3" >
                                                <div
                                                    className="hidden flex-none text-base-300 sm:block"
                                                    aria-hidden="true"
                                                >{icons[transaction?.status]}</div>
                                                {transaction?.amount} USD
                                            </div>
                                            <div className="flex flex-col font-normal">
                                                <p className="mt-1 truncate text-base-content/70 lg:hidden">{formatDate(transaction?.created)}</p>
                                                <p className="mt-1 truncate text-base-content/70 md:hidden">****  {transaction?.last4}</p>
                                            </div>
                                        </td>
                                        <td className='whitespace-nowrap hidden py-2 text-sm text-base-content/70 lg:table-cell px-2'>
                                            {formatDate(transaction?.created)}
                                        </td>
                                        <td className='whitespace-nowrap hidden py-2 text-sm text-base-content/70 md:table-cell px-2'>
                                            **** **** **** {transaction?.last4}
                                        </td>
                                        <td className="px-2" >
                                            <span className={statusBadges[transaction.status]}>{transaction.status}</span>
                                        </td>
                                        <td className="relative whitespace-nowrap py-2 text-right text-sm font-medium px-2" >
                                            <div className="flex justify-end">
                                                <Link
                                                    to={`/billing/invoices/transaction/${transaction.id}`}
                                                    className="text-sm font-medium leading-6 text-primary hover:text-primary-focus dark:hover:text-primary-focus-dark"
                                                >
                                                    View<span className="hidden sm:inline"> transaction</span>
                                                </Link>
                                            </div>
                                            <div className="mt-1 text-xs leading-5 text-base-content/70 hidden md:block">
                                                #{transaction?.invoice_number}
                                            </div>
                                        </td>
                                    </motion.tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center pt-6">
                            <ImTable className="mx-auto h-8 w-8 text-base-content/30" aria-hidden="true" />
                            <h3 className="mt-2 text-sm font-semibold text-base-content">No invoices found</h3>
                            <p className="mt-1 text-sm text-base-content/70">There are no invoices available at this time.</p>
                        </div>
                    )}
                </div>
            </div>
            <nav
                className="border-t border-neutral-focus/40 dark:border-neutral-focus-dark/40 bg-base-100 py-3 z-[2]"
                aria-label="Pagination"
            >
                <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" >
                    <div className="hidden sm:block">
                        {transactionListingVF ? <div className="h-3.5 w-48 bg-base-300/30 animate-pulse duration-700 rounded-md" /> : <p className="text-sm text-base-content/70">
                            Showing{" "}
                            <span className="font-medium text-base-content">{indexOfFirstItem + 1}</span> to{" "}
                            <span className="font-medium text-base-content">{Math.min(indexOfLastItem, transactionListingResponse?.length)}</span> of{" "}
                            <span className="font-medium text-base-content">{transactionListingResponse?.length}</span> results
                        </p>}
                    </div>
                    {/*TODO: UPDATE THE ONCLICK TO GET BACKEND METADATA FOR PAGINATION*/}
                    <div className="flex flex-1 justify-between sm:justify-end space-x-3">
                        <PaginationButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </PaginationButton>
                        <PaginationButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            Next
                        </PaginationButton>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Invoices;
