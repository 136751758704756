import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const changePasswordPOST = createAsyncThunk(
    'users/account/change-password',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: process.env.REACT_APP_CHANGE_PASSWORD_PATH,
                    args: values,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    passwordChangeVF: false,
    passwordChangeApiState: 'idle',
    passwordChangeResponse: null,
    passwordChangeError: null,
};

export const passwordChangeSlice = createSlice({
    name: 'changePassword',
    initialState: INITIAL_STATE,
    reducers: {
        clearPasswordChangeVF: (state) => {
            state.passwordChangeVF = false;
        },
        resetPasswordChange: (state) => {
            state.passwordChangeVF = false;
            state.passwordChangeApiState = 'idle';
            state.passwordChangeResponse = null;
            state.passwordChangeError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(changePasswordPOST.pending, (state) => {
                state.passwordChangeApiState = 'loading';
                state.passwordChangeVF = true;
            })
            .addCase(changePasswordPOST.fulfilled, (state, action) => {
                state.passwordChangeApiState = 'succeeded';
                state.passwordChangeResponse = action.payload;
                state.passwordChangeVF = true;
                state.passwordChangeError = null;
            })
            .addCase(changePasswordPOST.rejected, (state, action) => {
                state.passwordChangeApiState = 'failed';
                state.passwordChangeError = action?.payload;
                state.passwordChangeVF = true;
                state.passwordChangeResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET' || action.type === 'RESET_USER_MANAGEMENT') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    resetPasswordChange,
    clearPasswordChangeVF
} = passwordChangeSlice.actions;

export default passwordChangeSlice.reducer;
