import React from "react";
import PropTypes from "prop-types";


const MdLinks = ({ href, children }) => {

    const handleClick = (e) => {
        if (href.startsWith("#")) {
            e.preventDefault();
        }
    };

    return (
        <a
            href={href}
            target={href.startsWith("#") ? "_self" : "_blank"}
            rel={href.startsWith("#") ? "" : "noopener noreferrer"}
            className="no-underline text-sm font-light text-secondary hover:text-secondary-focus dark:hover:text-secondary-focus-dark pr-0.5 group relative cursor-pointer"
            onClick={handleClick}
        >
            {children}
        </a>
    );
};

MdLinks.propTypes = {
    href: PropTypes.string,
    alt: PropTypes.string,
    children: PropTypes.node,
};

export default MdLinks;
