import jwt_decode from "jwt-decode";

import {fetchAccess} from "./fetchAccess";
import {unAuthenticate} from "../features/users/authenticationSlice";
import {updateLocalStorage} from "./localStorageUtils";
import config from "../../config";


export async function prepareHeaders(accessCode, customHeaders, thunkAPI) {
    const defaultHeaders = {
        'Content-Type': 'application/json',
        ...customHeaders
    };

    if (!accessCode) {return defaultHeaders;}

    const decodedToken = jwt_decode(accessCode);
    if (decodedToken.exp < Date.now() / 1000) {
        try {
            const { access, refresh } = await fetchAccess(config.backendUrl);
            updateLocalStorage(access, refresh);
            defaultHeaders['Authorization'] = `Bearer ${access}`;
        } catch (error) {
            thunkAPI.dispatch(unAuthenticate());
            thunkAPI.rejectWithValue('Session expired. Please log in again.');
            return null;
        }
    } else {
        defaultHeaders['Authorization'] = `Bearer ${accessCode}`;
    }

    return defaultHeaders;
}
