import React from "react";
import PropTypes from "prop-types";
import {BsChatTextFill, BsFillBoxFill} from "react-icons/bs";
import {PiCodesandboxLogoFill} from "react-icons/pi";
import {GiMicroscope} from "react-icons/gi";
import {RiGraduationCapFill, RiPencilFill} from "react-icons/ri";

import routes from "../../Routes/routes";
import clsx from "clsx";
import {IoMdDoneAll} from "react-icons/io";

export const products = [
    {
        name: 'Scholar',
        description: 'Accelerating research with instant data processing.',
        active: false,
        href: routes.unAuth.products.find(product => product.name === 'Scholar').href,
        icon: (className) => <ProductIcon className={className} >Sc</ProductIcon>
    },
    {
        name: 'CodeCraft',
        description: 'Transforming programming into a streamlined process.',
        active: false,
        href: routes.unAuth.products.find(product => product.name === 'CodeCraft').href,
        icon: (className) => <ProductIcon className={className} >CC</ProductIcon>
    },
    {
        name: 'FiscalFlow',
        description: 'Transforming financial analysis with instant data processing.',
        active: false,
        href: routes.unAuth.products.find(product => product.name === 'FiscalFlow').href,
        icon: (className) => <ProductIcon className={className} >FF</ProductIcon>
    },
    {
        name: 'ProseMaster',
        description: 'Streamlining documentation with versatile, cost-effective writing.',
        active: false,
        href: routes.unAuth.products.find(product => product.name === 'ProseMaster').href,
        icon: (className) => <ProductIcon className={className} >PM</ProductIcon>
    },
    // {
    //     name: 'VitalHealth',
    //     description: 'Harness the essentials of well-being with in-depth nutrition and fitness analytics.',
    //     href: routes.unAuth.products.find(product => product.name === 'VitalHealth').href,
    //     icon: () => <ProductIcon>VH</ProductIcon>
    // }
];

export const solutions = [
    {
        name: 'Programming',
        description: 'Transforming programming into a streamlined process.',
        href: routes.unAuth.solutions.find(solution => solution.name === 'Programming').href,
        icon: PiCodesandboxLogoFill
    },
    {
        name: 'Research',
        description: 'Accelerating research with instant data processing.',
        href: routes.unAuth.solutions.find(solution => solution.name === 'Research').href,
        icon: GiMicroscope
    },
    {
        name: 'Documentation',
        description: 'Streamlining documentation with versatile, cost-effective writing.',
        href: routes.unAuth.solutions.find(solution => solution.name === 'Documentation').href,
        icon: RiPencilFill
    },
    {
        name: 'Education',
        description: 'Transforming education with instant interactive and high-quality material.',
        href: routes.unAuth.solutions.find(solution => solution.name === 'Education').href,
        icon: RiGraduationCapFill
    },
];

export const productBottomSection = [
    {
        name: 'TaskGlide',
        href: routes.unAuth.products.find(product => product.name === 'TaskGlide').href,
        active: true,
        icon: IoMdDoneAll
    },
    {
        name: 'View all products',
        href: routes.unAuth.products.find(product => product.name === 'Store').href,
        active: true,
        icon: BsFillBoxFill
    },
];

const ProductIcon = ({children, className}) => {
    return (
        <p className={clsx('', className)}>{children}</p>
    )
};

ProductIcon.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
