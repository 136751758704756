import React, {useEffect, useState} from "react";
import {HiExclamation} from "react-icons/hi";
import {CgSpinner} from "react-icons/cg";
import clsx from "clsx";

import {useAuthentication} from "../../../../Hooks/apiHooks/users/useAuthentication";
import {useDeleteUserAccount} from "../../../../Hooks/apiHooks/users/useDeleteUserAccount";


const AccountDeletionModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        deleteUserAccount,
        userAccountDeletionVF,
        userAccountDeletionApiState
    } = useDeleteUserAccount({ vfExists: true });
    const { logout, authenticationVF } = useAuthentication({ vfExists: false });

    const loadingState = userAccountDeletionVF || authenticationVF;

    useEffect(() => {
        const dialogElement = document.getElementById('accountDeletion');
        if (userAccountDeletionApiState === 'succeeded') {
            dialogElement?.close();
            setIsModalOpen(false);
            logout();
        }
    }, [userAccountDeletionApiState]);

    return (
        <>
            <button
                className="error-button"
                onClick={() => {
                    const dialogElement = document.getElementById('accountDeletion');
                    dialogElement.showModal();
                    setIsModalOpen(true);
                }}
                type="button"
            >
                Delete my account
            </button>
            <dialog
                id="accountDeletion"
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={() => setIsModalOpen(false)}
            >
                <form method="dialog" className="modal-wrapper">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-error-content sm:mx-0 sm:h-10 sm:w-10">
                            <HiExclamation className="h-6 w-6 text-error" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mx-4 sm:mt-2 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-base-content">
                                Delete your account
                            </h3>
                            <div className="mt-2 text-sm">
                                <p className="inline text-base-content/70" >
                                    Are you sure you want to delete your account? All of your data including your payment methods, invoices, and subscriptions will be permanently deleted. This action cannot be undone.
                                </p>
                                <p className="inline italic text-base-content" >You will lose access to all the products you are currently subscribed to while getting no refunds.</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-end gap-x-3">
                        <button
                            className="standard-button"
                            onClick={() => {
                                const dialogElement = document.getElementById('accountDeletion');
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="error-button"
                            disabled={loadingState}
                            onClick={deleteUserAccount}
                        >
                            <CgSpinner
                                className={clsx(
                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                    loadingState ? "" : "hidden"
                                )}
                                aria-hidden="true"
                            />
                            <p className={loadingState ? "invisible" : ""}>
                                Delete
                            </p>
                        </button>
                    </div>
                </form>
                <form method="dialog" className="modal-backdrop">
                    <button
                        tabIndex={-1}
                        className="cursor-default"
                        onClick={() => {
                            const dialogElement = document.getElementById("accountDeletion");
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >
                        close
                    </button>
                </form>
            </dialog>
        </>
    )
};

AccountDeletionModal.propTypes = {}

export default AccountDeletionModal;
