import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {CalendarDaysIcon, UserCircleIcon} from "@heroicons/react/20/solid";
import clsx from "clsx";
import {statusBadges} from "../Invoices/Invoices";
import {cardLogos} from "../LinkedCards/LinkedCards";
import {RiArrowRightLine, RiArrowRightSLine} from "react-icons/ri";
import {useGetTransactionReceipt} from "../../../../Hooks/apiHooks/payments/useGetTransactionReceipt";
import {resetTransactionReceiptRetrieval} from "../../../../api/features/payments/getTransactionReceiptSlice";
import {useDispatch} from "react-redux";


export const formatTransactionDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
};

const TransactionCard = ({ transaction }) => {
    const dispatch = useDispatch();
    const {
        getTransactionReceipt,
        transactionReceiptRetrievalSuccessVF,
        transactionReceiptRetrievalResponse,
        transactionReceiptRetrievalApiState
    } = useGetTransactionReceipt({ vfExists: true });

    const effectiveDate = transaction?.status === 'refunded' ? transaction?.refund_date : transaction?.created;

    useEffect(() => {
        if (transactionReceiptRetrievalSuccessVF) {
            window.open(transactionReceiptRetrievalResponse?.receipt_url);
            dispatch(resetTransactionReceiptRetrieval());
        }
    }, [transactionReceiptRetrievalApiState]);

    return (
        <div className="w-full text-base-content">
            <h2 className="sr-only">Summary</h2>
            <div className="rounded-lg bg-base-200 shadow-sm ring-1 ring-neutral p-4">
                <dl className="w-full divide-y divide-base-100">
                    <div className="flex justify-between items-center pb-4">
                        <div className="text-sm font-semibold leading-6 space-y-1">
                            <p>Amount</p>
                            <p className="text-base">${transaction?.amount?.toFixed(2)}</p>
                        </div>
                        <div>
                            <p className="sr-only">Status</p>
                            <p className={clsx("rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset capitalize justify-self-end", transaction?.status && statusBadges[transaction?.status])}>
                                {transaction?.status}
                            </p>
                        </div>
                    </div>
                    <div className="space-y-3 py-4">
                        <div className="flex items-center w-full flex-none gap-x-4">
                            <div className="">
                                <span className="sr-only">Name</span>
                                <UserCircleIcon className="size-5" aria-hidden="true"/>
                            </div>
                            <dd className="text-sm leading-6 text-base-content/70">{transaction?.payer_info?.name}</dd>
                        </div>
                        <div className="flex items-center w-full flex-none gap-x-4">
                            <dt className="flex-none">
                                <span className="sr-only">Due date</span>
                                <CalendarDaysIcon className="h-6 w-5" aria-hidden="true"/>
                            </dt>
                            <dd className="text-sm leading-6 text-base-content/70">
                                <time dateTime={effectiveDate}>{formatTransactionDate(effectiveDate)}</time>
                            </dd>
                        </div>
                        <div className="flex items-center w-full flex-none gap-x-4">
                            <dt className="flex-none">
                                <span className="sr-only">Status</span>
                                <img
                                    src={transaction?.card_brand !== "unknown" ? cardLogos[transaction?.card_brand] : cardLogos["cardGeneric"]}
                                    alt={transaction?.card_brand.toUpperCase()}
                                    className="w-auto h-4 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-sm shrink-0"
                                />
                            </dt>
                            <dd className="text-sm leading-6 text-base-content/70">Paid with {transaction?.card_brand} ending {transaction?.last4}</dd>
                        </div>
                    </div>

                    <div className="pt-4">
                        <button
                            className="rounded-empty-button group"
                            onClick={() => getTransactionReceipt(transaction?.id)}
                        >
                            <p>Download receipt</p>
                            <RiArrowRightSLine className='flex group-hover:sm:hidden size-4 shrink-0' aria-hidden="true"/>
                            <RiArrowRightLine className='group-hover:sm:flex hidden size-4 shrink-0' aria-hidden="true"/>
                        </button>
                    </div>
                </dl>
            </div>
        </div>
    )
};

TransactionCard.propTypes = {
    transaction: PropTypes.object,
}

export default TransactionCard;
