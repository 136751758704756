import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {useListUserGlides} from "./useListUserGlides";
import {glideOfUserGlidesPOSTDELETE, clearAddOrRemoveGlideOfUserGlidesVF, resetGlideOfUserGlideAdditionOrRemoval} from "../../../../../api/features/superIntelligence/taskGlide/glides/addOrRemoveGlideOfUserGlidesSlice";


export const useAddOrRemoveGlideOfUserGlides = (
    { vfExists=false, autoUpdate=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // MyGlides
    const {
        addOrRemoveGlideOfUserGlidesVF,
        addOrRemoveGlideOfUserGlidesApiState,
        addOrRemoveGlideOfUserGlidesResponse,
        addOrRemoveGlideOfUserGlidesError,
    } = useSelector(state => state.addOrRemoveGlideOfUserGlidesReducer);
    const { getGlides } = useListUserGlides();

    // API states with visual feedback
    const addGlideToUserGlidesSuccessVF = addOrRemoveGlideOfUserGlidesApiState === 'succeeded' && addOrRemoveGlideOfUserGlidesVF;
    const addGlideToUserGlidesFailureVF = addOrRemoveGlideOfUserGlidesApiState === 'failed' && addOrRemoveGlideOfUserGlidesVF;

    const addOrRemoveGlideOfUserGlides = (glide_uuid, action) => userIsAuthenticated && dispatch(glideOfUserGlidesPOSTDELETE({ glide_uuid, action }));

    useEffect(() => {
        if (vfExists) {
            if (addGlideToUserGlidesSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearAddOrRemoveGlideOfUserGlidesVF());
                }, 250);

                return () => clearTimeout(timer);
            } else if (addGlideToUserGlidesFailureVF) {
                dispatch(setAlert({
                    message: addOrRemoveGlideOfUserGlidesResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetGlideOfUserGlideAdditionOrRemoval());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [addOrRemoveGlideOfUserGlidesApiState, addOrRemoveGlideOfUserGlidesVF]);

    useEffect(() => {
        if (autoUpdate && addOrRemoveGlideOfUserGlidesApiState === 'succeeded' && !addOrRemoveGlideOfUserGlidesVF) {
            getGlides();
            dispatch(resetGlideOfUserGlideAdditionOrRemoval());
        }
    }, [addOrRemoveGlideOfUserGlidesApiState, addOrRemoveGlideOfUserGlidesVF]);

    return {
        addOrRemoveGlideOfUserGlides,
        addOrRemoveGlideOfUserGlidesVF,
        addOrRemoveGlideOfUserGlidesApiState,
        addOrRemoveGlideOfUserGlidesResponse,
        addOrRemoveGlideOfUserGlidesError,
        addGlideToUserGlidesSuccessVF,
        addGlideToUserGlidesFailureVF
    };
};
