import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {HiExclamation} from "react-icons/hi";
import {CgSpinner} from "react-icons/cg";
import clsx from "clsx";

import {useDeletePaymentMethod} from "../../../../Hooks/apiHooks/payments/useDeletePaymentMethod";


const CardDeletionModal = ({ card, onDelete }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalId = `cardDeletion-${card?.id}`;
    const {
        paymentMethodDeletionApiState,
        paymentMethodDeletionVF,
        paymentMethodDeletionSuccessVF,
        paymentMethodDeletionFailureVF
    } = useDeletePaymentMethod({vfExists: true, refreshAndReset: false});

    useEffect(() => {
        const dialogElement = document.getElementById(modalId);

        if (paymentMethodDeletionSuccessVF || paymentMethodDeletionFailureVF) {
            dialogElement?.close();
            setIsModalOpen(false);
        }
    }, [paymentMethodDeletionApiState, paymentMethodDeletionVF]);

    return (
        <>
            <button
                disabled={paymentMethodDeletionVF}
                className="flex flex-row w-full items-center space-x-2 px-3 py-1 text-sm leading-6 text-base-content hover:sm:bg-base-200 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:sm:bg-base-100"
                onClick={(e) => {
                    if (!paymentMethodDeletionVF) {
                        e.preventDefault();
                        const dialogElement = document.getElementById(modalId);
                        dialogElement.showModal();
                        setIsModalOpen(true);
                    }
                }}
            >
                Delete
            </button>
            <dialog
                id={modalId}
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={() => setIsModalOpen(false)}
            >
                <form method="dialog" className="modal-wrapper" onSubmit={(e) => e.preventDefault()} >
                    <div className="sm:flex sm:items-start" >
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-error-content sm:mx-0 sm:h-10 sm:w-10">
                            <HiExclamation className="h-6 w-6 text-error shrink-0" aria-hidden="true"/>
                        </div>
                        <div className="mt-3 text-center sm:mx-4 sm:mt-2 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-base-content">
                                Delete payment method
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-base-content/70">
                                    Are you sure you want to delete your payment method? This will remove your payment method from all your active subscriptions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-end gap-x-4">
                        <button
                            type="button"
                            onClick={() => {
                                const dialogElement = document.getElementById(modalId);
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                            className="w-auto text-sm font-medium text-base-content/90 hover:text-base-content outline-none"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            disabled={paymentMethodDeletionVF}
                            className="error-button"
                            onClick={onDelete}
                        >
                            <CgSpinner
                                className={clsx(
                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                    paymentMethodDeletionVF ? "" : "hidden"
                                )}
                                aria-hidden="true"
                            />
                            <p className={paymentMethodDeletionVF ? "invisible" : ""}>
                                Delete
                            </p>
                        </button>
                    </div>
                </form>
                <form method="dialog" className="modal-backdrop">
                    <button
                        className="cursor-default"
                        onClick={() => {
                            const dialogElement = document.getElementById(modalId);
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >close</button>
                </form>
            </dialog>
        </>
    )
};

CardDeletionModal.propTypes = {
    card: PropTypes.object,
    onDelete: PropTypes.func,
}

export default CardDeletionModal;
