import React from "react";
import {Link} from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MdOutlineAccessTimeFilled} from "react-icons/md";
import {AiFillBug} from "react-icons/ai";
import {HiCodeBracketSquare} from "react-icons/hi2";
import {RiArrowRightLine, RiArrowRightSLine} from "react-icons/ri";

import {feature1Theme} from "./feature1Theme";
import routes from "../../../../../Routes/routes";

const features = [
    {
        name: 'Streamlined Understanding.',
        description:
            'Simplify your coding process and understand the purpose of the code at a glance.',
        icon: MdOutlineAccessTimeFilled,
    },
    {
        name: 'Advanced Debugging and Optimization.',
        description: 'Easily detect and rectify errors, and optimize your code with minimal effort. Let our Intelligence work its magic.',
        icon: AiFillBug,
    },
    {
        name: 'Effortless Coding.',
        description: 'AWrite robust and efficient code without breaking a sweat. Our Intelligence has your back, every step of the way.',
        icon: HiCodeBracketSquare,
    },
];

const jsCode = `const validateUser = user => {
    if (!user || typeof user !== 'object') {
        throw new Error('Invalid user information');
    }

    let { firstName, lastName, email, password } = user;

    let emailRegEx = /^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/;
    if (!emailRegEx.test(email)) {
        throw new Error('Email invalid');
    }

    if (password.length < 5) {
        throw new Error('Password short');
    }

    return { [email]: { firstName, lastName, password } };
};`;

const Feature1 = () => {

    return (
        <div className="overflow-hidden" >
            <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg px-6 lg:px-8">
                        <h2 className="text-base font-medium leading-7 text-primary">Accelerate Innovation</h2>
                        <div className="space-y-4 mt-4">
                            <p className="text-3xl font-bold tracking-tight text-base-content sm:text-4xl">Transforming Programming Experience</p>
                            <p className="text-lg leading-8 text-base-content/70">
                                Reinvent the way you code with our intelligent solutions designed to streamline programming.
                            </p>
                            <dl className="pt-4 pl-1 max-w-xl space-y-4 text-base leading-7 lg:max-w-none">
                                {features.map((feature) => (
                                <div key={feature.name} className="relative space-x-2">
                                    <feature.icon className="inline h-5 w-5 text-base-300 -mt-1" aria-hidden="true"/>
                                    <dt className="inline font-semibold text-base-content">
                                        {feature.name}
                                    </dt>
                                    <dd className="inline text-base-content/70">{feature.description}</dd>
                                </div>))}
                            </dl>
                        </div>
                        <Link
                            to={routes.unAuth.products.find(product => product.name === 'CodeCraft').href}
                            className="rounded-empty-button group mt-8"
                        >
                            <p>Learn More</p>
                            <div>
                                <RiArrowRightSLine className='flex group-hover:hidden size-4 shrink-0' aria-hidden="true"/>
                                <RiArrowRightLine className='group-hover:flex hidden size-4 shrink-0' aria-hidden="true"/>
                            </div>
                        </Link>
                    </div>
                    <div className="sm:px-6 lg:px-0">
                    <div className="relative isolate overflow-hidden bg-primary px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-lg sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                        <div className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-white opacity-20 ring-1 ring-inset ring-base-100 mx-auto max-w-2xl sm:mx-0 sm:max-w-none cursor-default" aria-hidden="true" />
                            <div className="w-screen overflow-hidden rounded-tl-lg bg-[#1A1E28]">
                                <div className="flex bg-[#282A36]/40 ring-1 ring-gray-700">
                                    <div className="flex text-sm font-medium leading-6 text-base-content/70">
                                        <div className="border-r border-r-gray-700 bg-gray-800/70 px-4 py-2 text-white">
                                            validateUser.js
                                        </div>
                                    </div>
                                </div>
                                <div className="p-6">
                                    <SyntaxHighlighter
                                        language="javascript"
                                        style={feature1Theme()}
                                        className="hide-scrollbar"
                                    >
                                        {jsCode}
                                    </SyntaxHighlighter>
                                </div>
                            </div>
                            <div className="pointer-events-none absolute inset-0 sm:rounded-3xl" aria-hidden="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Feature1;
