import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const userInfoUPDATE = createAsyncThunk(
    'users/userInfo/update',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'PUT',
                    path: `${process.env.REACT_APP_USER_INFO_PATH}`,
                    accessCode: access,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userInfoUpdateVF: false,
    userInfoUpdateApiState: 'idle',
    userInfoUpdateResponse: null,
    userInfoUpdateError: null,
};

export const updateUserInfoSlice = createSlice({
    name: 'userInfoUpdate',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserInfoUpdateVF: (state) => {
            state.userInfoUpdateVF = false;
        },
        resetUserInfoUpdate: (state) => {
            state.userInfoUpdateVF = false;
            state.userInfoUpdateApiState = 'idle';
            state.userInfoUpdateResponse = null;
            state.userInfoUpdateError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userInfoUPDATE.pending, (state) => {
                state.userInfoUpdateApiState = 'loading';
                state.userInfoUpdateVF = true;
            })
            .addCase(userInfoUPDATE.fulfilled, (state, action) => {
                state.userInfoUpdateApiState = 'succeeded';
                state.userInfoUpdateResponse = action?.payload;
                state.userInfoUpdateVF = true;
                state.userInfoUpdateError = null;
            })
            .addCase(userInfoUPDATE.rejected, (state, action) => {
                state.userInfoUpdateApiState = 'failed';
                state.userInfoUpdateError = action?.payload;
                state.userInfoUpdateVF = true;
                state.userInfoUpdateResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUserInfoUpdateVF, resetUserInfoUpdate
} = updateUserInfoSlice.actions;

export default updateUserInfoSlice.reducer;
