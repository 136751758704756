import React from "react";
import {CgSpinner} from "react-icons/cg";
import clsx from "clsx";
import {ImPriceTags} from "react-icons/im";
import {useDispatch} from "react-redux";
import {RxCross2} from "react-icons/rx";

import {resetPromoCodeValidation} from "../../../../../api/features/payments/validatePromoCodeSlice";
import {useValidatePromoCode} from "../../../../../Hooks/apiHooks/payments/useValidatePromoCode";


const SubscriptionPromoSelection = () => {
    const dispatch = useDispatch();
    const {
        validatePromoCode,
        promoCodeValidationVF,
        promoCodeValidationResponse,
    } = useValidatePromoCode({ vfExists: true });

    return (
        <div className="col-span-full space-y-4 py-6">
            <div className="flex items-center w-full space-x-6">
                <div className="relative w-full">
                    <p className="absolute -top-2 left-2.5 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-light text-base-content/70 z-10 leading-none">
                        Promo Code
                    </p>
                    <input
                        type="text"
                        id="promotion_code"
                        name="promotion_code"
                        className="input-field"
                        placeholder="DOVISE2024"
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                validatePromoCode({promotion_code: event.target.value});
                                event.target.value = '';
                            }
                        }}
                    />
                </div>
                <button
                    type="button"
                    onClick={() => {
                        const promoCode = document.getElementById('promotion_code').value;
                        validatePromoCode({promotion_code: promoCode});
                        document.getElementById('promotion_code').value = '';
                    }}
                    className="neutral-button"
                >
                    <CgSpinner
                        className={clsx(
                            "absolute inset-0 m-auto animate-spin h-5 w-5",
                            promoCodeValidationVF ? "" : "hidden"
                        )}
                        aria-hidden="true"
                    />
                    <p className={promoCodeValidationVF ? "invisible" : ""}>
                        Apply
                    </p>
                </button>
            </div>
            {promoCodeValidationResponse &&
                <div className="uppercase flex items-center space-x-1.5 bg-base-200 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-md shadow px-2 py-1 w-fit text-sm">
                    <ImPriceTags className="w-4 h-4 text-base-content"/>
                    <p>{promoCodeValidationResponse?.promotion_code}</p>
                    <button
                        type="button"
                        onClick={() => dispatch(resetPromoCodeValidation())}
                        className="flex items-center justify-center text-base-content relative group/remove-promo"
                    >
                        <RxCross2 className="w-4 h-4"/>
                        <div className="right-tooltips left-8 group-hover/remove-promo:sm:block">Remove</div>
                    </button>
                </div>}
        </div>
    )
};

export default SubscriptionPromoSelection;
