import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    tgStreamConversationUuid: null,
    // tgStreamSelectedGlides: null,
    tgStreamInteractionId: null,
    tgStreamInteractionStatus: null,
    tgStreamInteractionMessages: null,
    // tgStreamUserMessage: null,
    // tgStreamGlideResponse: null,
    tgStreamSchema: null,
};

export const getTgInteractionStreamSlice = createSlice({
    name: 'tgInteractionStreamRetrieval',
    initialState: initialState,
    reducers: {
        setTgStreamConversationUuid: (state, action) => {
            state.tgStreamConversationUuid = action.payload;
        },
        // setTgStreamSelectedGlides: (state, action) => {
        //     state.tgStreamSelectedGlides = action.payload;
        // },
        setTgStreamInteractionId: (state, action) => {
            state.tgStreamInteractionId = action.payload;
        },
        setTgStreamInteractionStatus: (state, action) => {
            state.tgStreamInteractionStatus = action.payload;
        },
        setTgStreamInteractionMessages: (state, action) => {
            state.tgStreamInteractionMessages = action.payload;
        },
        // setTgStreamUserMessage: (state, action) => {
        //     state.tgStreamUserMessage = action.payload;
        // },
        // setTgStreamGlideResponse: (state, action) => {
        //     state.tgStreamGlideResponse = action.payload;
        // },
        setTgStreamSchema: (state, action) => {
            state.tgStreamSchema = action.payload;
        },
    },
});

export const {
    setTgStreamConversationUuid,
    // setTgStreamSelectedGlides,
    setTgStreamInteractionId,
    setTgStreamInteractionStatus,
    setTgStreamInteractionMessages,
    // setTgStreamUserMessage,
    // setTgStreamGlideResponse,
    setTgStreamSchema,
} = getTgInteractionStreamSlice.actions;

export default getTgInteractionStreamSlice.reducer;
