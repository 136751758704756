import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearStripeCheckoutVF, stripeCheckoutPOST} from "../../api/features/payments/stripeCheckoutSlice";
import {incrementAlertKey, setAlert} from "../../api/features/frontend/alertSlice";
import {useCreatePaymentMethod} from "../apiHooks/payments/useCreatePaymentMethod";
import {useAuthentication} from "../apiHooks/users/useAuthentication";


export const useStripeCheckout = () => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Stripe Frontend Elements
    const {
        stripeCheckoutVF,
        stripeCheckoutFxnState,
        stripeCheckoutResponse,
        stripeCheckoutError,
    } = useSelector(state => state.getStripeCheckoutReducer);
    const { createPaymentMethod } = useCreatePaymentMethod({vfExists: false, refreshAndReset: false});

    // API State with visual feedback
    const stripeCheckoutSuccessVF = stripeCheckoutFxnState === 'succeeded' && stripeCheckoutVF;
    const stripeCheckoutFailureVF = stripeCheckoutFxnState === 'failed' && stripeCheckoutVF;
    const stripeCheckoutLoadingVF = stripeCheckoutFxnState === 'loading' && stripeCheckoutVF;

    const getIdAndCreatePaymentMethod = (values) => userIsAuthenticated && dispatch(stripeCheckoutPOST(values));

    useEffect(() => {
        if (stripeCheckoutFailureVF) {
            dispatch(setAlert({
                message: stripeCheckoutError || "Something went wrong! Please try again.",
                type: "error"
            }));
            dispatch(incrementAlertKey());

            const timer = setTimeout(() => {
                dispatch(clearStripeCheckoutVF());
            }, 4000);

            return () => clearTimeout(timer);
        } else if (stripeCheckoutSuccessVF) {
            // If the front-end Stripe element succeeds on retrieving the payment method ID, then we can proceed to create the payment method on the backend
            createPaymentMethod({payment_method_id: stripeCheckoutResponse})

            const timer = setTimeout(() => {
                dispatch(clearStripeCheckoutVF());
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [stripeCheckoutFxnState]);

    return {
        getIdAndCreatePaymentMethod,
        stripeCheckoutFxnState,
        stripeCheckoutError,
        stripeCheckoutVF,
        stripeCheckoutSuccessVF,
        stripeCheckoutFailureVF,
        stripeCheckoutLoadingVF
    };
};

