import React from "react";
import {Link} from "react-router-dom";
import {FaChartSimple, FaRoad} from "react-icons/fa6";
import {BiSolidPencil, BiSolidPieChartAlt2, BiSolidSelectMultiple} from "react-icons/bi";
import {PiPlugsFill} from "react-icons/pi";
import {FaBrain} from "react-icons/fa";
import {IoPeople} from "react-icons/io5";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import {AiFillClockCircle} from "react-icons/ai";

import BgCheckers from "../../../../Components/Bg Checkers/BgCheckers";
import routes from "../../../../Routes/routes";
import educationStockPhoto from "./educationStockPhoto.jpg";


const EducationSolution = () => {

    return (
        <div className="relative py-16 sm:py-24">
            <div className="flex flex-col justify-center mx-auto max-w-7xl col-span-1 px-6 lg:px-8 py-16">
                <div className="flex flex-col justify-center mx-auto max-w-2xl text-start lg:text-center col-span-1 py-2 space-y-6">
                    <div className="space-y-2" >
                        <h2 className="text-base font-medium leading-7 text-primary">Education Solutions</h2>
                        <h1 className="mt-2 font-bold tracking-tight text-base-content text-[min(7.5vw,40px)] leading-tight">Intelligent Personalized Learning</h1>
                    </div>
                    <p className="leading-8 mx-auto max-w-2xl text-[min(5vw,18px)] text-base-content/70">Revolutionize the educational landscape with our intelligence powered platform that custom-creates courses, provides instant answers, and fosters collaboration for a dynamic learning experience.</p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none text-start">
                    <dl className="grid grid-cols-1 lg:grid-cols-3 gap-12 lg:max-w-none">
                        <div className="flex flex-col space-y-4">
                            <div className="flex items-center space-x-3">
                                <BiSolidPencil className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                                <p className="inline font-medium">Tailored Educational Courses</p>
                            </div>
                            <p className="font-light text-base-content/70 leading-7">Dive into a learning experience that’s as unique as you are. Our platform leverages Super Intelligence to curate and generate custom courses on any topic you can imagine, adapting to your pace and style of learning for truly personalized education.</p>
                        </div>
                        <div className="flex flex-col space-y-4">
                            <div className="flex items-center space-x-3">
                                <BiSolidPieChartAlt2 className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                                <p className="inline font-medium">Interactive Charts and Visualizations</p>
                            </div>
                            <p className="font-light text-base-content/70 leading-7">Grasp complex concepts at a glance with interactive charts and visualizations that make learning engaging and intuitive. Our system transforms data and information into visually appealing formats, aiding in retention and understanding.</p>
                        </div>
                        <div className="flex flex-col space-y-4">
                            <div className="flex items-center space-x-3">
                                <PiPlugsFill className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                                <p className="inline font-medium">Utilization of External Sources</p>
                            </div>
                            <p className="inline text-base-content/70 leading-7">Expand your horizons by seamlessly integrating external sources into your learning path. Whether it&apos;s academic journals, databases, or online libraries, our tool brings a world of information to your digital doorstep.</p>
                        </div>
                    </dl>
                </div>
            </div>
            <div className="max-w-7xl mx-auto px-0 lg:px-8 grid grid-cols-2 gap-x-12" >
                <img src={educationStockPhoto} alt={"Education Stock"} className="object-cover w-full lg:rounded-lg col-span-full lg:col-span-1"/>
                <div className="col-span-full lg:col-span-1 lg:grid hidden lg:grid-cols-1 gap-12 content-center">
                    <div className="flex flex-col space-y-4">
                        <div className="flex items-center space-x-3">
                            <FaBrain className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                            <p className="inline font-medium">Rapid Knowledge Retrieval</p>
                        </div>
                        <p className="font-light text-base-content/70 leading-7">Query any topic under the academic sun and receive instant, accurate answers. Our platform acts as your round-the-clock tutor, ready to clarify doubts and deepen your knowledge without the wait.</p>
                    </div>
                    <div className="flex flex-col space-y-4">
                        <div className="flex items-center space-x-3">
                            <IoPeople className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                            <p className="inline font-medium">Collaborative Academic Platform</p>
                        </div>
                        <p className="font-light text-base-content/70 leading-7">Connect with educators and learners across the globe. Create and participate in collaborative courses, share insights, and build a community of knowledge seekers and providers.</p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 space-6 mx-auto max-w-7xl px-6 lg:px-8 pt-16">
                <div className="flex flex-col space-y-4 lg:hidden">
                    <div className="flex items-center space-x-3">
                        <FaBrain className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                        <p className="inline font-medium">Rapid Knowledge Retrieval</p>
                    </div>
                    <p className="font-light text-base-content/70 leading-7">Query any topic under the academic sun and receive instant, accurate answers. Our platform acts as your round-the-clock tutor, ready to clarify doubts and deepen your knowledge without the wait.</p>
                </div>
                <div className="flex flex-col space-y-4 lg:hidden">
                    <div className="flex items-center space-x-3">
                        <IoPeople className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                        <p className="inline font-medium">Collaborative Academic Platform</p>
                    </div>
                    <p className="font-light text-base-content/70 leading-7">Connect with educators and learners across the globe. Create and participate in collaborative courses, share insights, and build a community of knowledge seekers and providers.</p>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center space-x-3">
                        <BsFillQuestionCircleFill className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                        <p className="inline font-medium">On-the-Fly Exams and Quizzes</p>
                    </div>
                    <p className="font-light text-base-content/70 leading-7">Assess understanding and reinforce learning with customized exams and quizzes created in real-time. Our platform allows educators to construct meaningful assessments effortlessly, and students to test their knowledge with immediate feedback.</p>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center space-x-3">
                        <FaRoad className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                        <p className="inline font-medium">Comprehensive Exam Preparation</p>
                    </div>
                    <p className="font-light text-base-content/70 leading-7">Confront any exam question with confidence. Students can receive guided solutions and explanations, helping to prepare more effectively for assessments.</p>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center space-x-3">
                        <BiSolidPieChartAlt2 className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                        <p className="inline font-medium">Efficient Resource Creation</p>
                    </div>
                    <p className="font-light text-base-content/70 leading-7">Empower educators to generate teaching materials at the click of a button. From lecture notes to comprehensive study guides, our platform streamlines the creation process, saving time and enhancing the quality of educational content.</p>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center space-x-3">
                        <AiFillClockCircle className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                        <p className="inline font-medium">Adaptive Learning Paths</p>
                    </div>
                    <p className="font-light text-base-content/70 leading-7">Our platform&apos;s intelligent algorithms assess your understanding and adapt in real time, creating a learning path that&apos;s optimized for your progress and performance. This responsive approach ensures that each learner is challenged appropriately, closing knowledge gaps and reinforcing strengths for a truly individualized educational journey.</p>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center space-x-3">
                        <BiSolidSelectMultiple className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                        <p className="inline font-medium">Multi-Modal Content Delivery</p>
                    </div>
                    <p className="font-light text-base-content/70 leading-7">Cater to diverse learning preferences with our multi-modal content delivery. Whether you learn best through text, audio, interactive simulations, or video, our platform provides a variety of content formats. This feature ensures that every learner has access to the resources that resonate most with them, enhancing engagement and comprehension.</p>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center space-x-3">
                        <FaChartSimple className="size-5 text-primary-focus dark:text-primary-focus-dark shrink-0"/>
                        <p className="inline font-medium">Real-Time Progress Tracking</p>
                    </div>
                    <p className="font-light text-base-content/70 leading-7">Stay informed with real-time analytics on your learning progress. Our platform provides detailed insights into your study habits, course completion rates, and assessment scores. Educators can utilize this data to identify areas where students might be struggling and offer additional support.</p>
                </div>
            </div>
            <div className="space-y-2 mt-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h3 className="text-xl font-medium">Begin Your Intelligent Learning Journey</h3>
                <p className="text-base-content/70 text-lg">Embark on an educational adventure that&apos;s enriched by Super Intelligence using <Link
                    className="text-secondary hover:text-secondary-focus dark:hover:text-secondary-focus-dark"
                    to={routes.unAuth.products.find(
                        product => product.name === "Scholar"
                    ).href}>Scholar</Link>, yet driven by your personal goals and curiosity. Our platform is not just about smarter learning; it’s about setting the stage for a lifetime of discovery. Join us and transform the way you learn, teach, and grow.</p>
            </div>
        </div>
    )
};

export default EducationSolution;
