import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const stripeCustomerCREATE = createAsyncThunk(
    'payments/stripe-customer/create',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_CREATE_STRIPE_CUSTOMER_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    stripeCustomerCreationApiState: "idle",
    stripeCustomerCreationVF: false,
    stripeCustomerCreationResponse: null,
    stripeCustomerCreationError: null,
};

export const createStripeCustomerSlice = createSlice({
    name: 'stripeCustomerCreation',
    initialState: INITIAL_STATE,
    reducers: {
        clearStripeCustomerCreationVF: (state) => {
            state.stripeCustomerCreationVF = false;
        },
        resetStripeCustomerCreation: (state) => {
            state.stripeCustomerCreationVF = false;
            state.stripeCustomerCreationApiState = 'idle';
            state.stripeCustomerCreationResponse = null;
            state.stripeCustomerCreationError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(stripeCustomerCREATE.pending, (state) => {
                state.stripeCustomerCreationApiState = 'loading';
                state.stripeCustomerCreationVF = true;
            })
            .addCase(stripeCustomerCREATE.fulfilled, (state, action) => {
                state.stripeCustomerCreationApiState = 'succeeded';
                state.stripeCustomerCreationResponse = action.payload;
                state.stripeCustomerCreationVF = true;
                state.stripeCustomerCreationError = null;
            })
            .addCase(stripeCustomerCREATE.rejected, (state, action) => {
                state.stripeCustomerCreationApiState = 'failed';
                state.stripeCustomerCreationError = action?.payload;
                state.stripeCustomerCreationVF = true;
                state.stripeCustomerCreationResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const { 
    clearStripeCustomerCreationVF,
    resetStripeCustomerCreation
} = createStripeCustomerSlice.actions;

export default createStripeCustomerSlice.reducer;
