import React, {Fragment} from "react";
import {Dialog, Disclosure, Transition} from "@headlessui/react";
import {HiChevronDown, HiChevronUp} from "react-icons/hi";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {AiOutlineClose} from "react-icons/ai";

import {useAuthentication} from "../../Hooks/apiHooks/users/useAuthentication";
import routes from "../../Routes/routes";
import {productBottomSection, products, solutions} from "./hedaerNavRoutes";
import ThemeButtons from "../ThemeButtons/ThemeButtons";


const HeaderSideBar = ({ isSidebarOpen, handleMenuClick }) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();

    return (
        <Transition.Root show={isSidebarOpen} as={Fragment}>
            <Dialog as="div" className="absolute z-50 lg:hidden" onClose={handleMenuClick}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-250"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-250"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full px-6 py-6 sm:max-w-sm shadow-2xl bg-base-200 text-base-content/70  overflow-y-auto scrollbar">
                        <div className="flex items-center justify-between">
                            <ThemeButtons className="ring-0 rounded-full p-1.5 cursor-pointer outline-none hover:sm:sm:bg-base-100 text-base-content" />
                            <button
                                type="button"
                                className="rounded-full p-2 hover:sm:sm:bg-base-100"
                                onClick={handleMenuClick}
                            >
                                <span className="sr-only">Close menu</span>
                                <AiOutlineClose className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-neutral-focus/70 dark:divide-neutral-focus-dark/70">
                                <div className="space-y-2 py-6">
                                    <Link
                                        to={routes.unAuth.home.href}
                                        onClick={handleMenuClick}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:sm:bg-base-200 hover:sm:text-base-content"
                                    >
                                        Home
                                    </Link>
                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button
                                                    className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:sm:bg-base-200 hover:sm:text-base-content">
                                                    Product
                                                    {open ? <HiChevronDown className="h-5 w-5 flex-none" aria-hidden="true" /> : <HiChevronUp className="h-5 w-5 flex-none" aria-hidden="true" />}
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="mt-2 space-y-2">
                                                    {[...products, ...productBottomSection].map((item) => (
                                                        <div key={item.name} className="flex items-center space-x-1" >
                                                            <Link
                                                                key={item.name}
                                                                to={item.href}
                                                                onClick={handleMenuClick}
                                                                className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 hover:sm:bg-base-200 hover:sm:text-base-content"
                                                            >
                                                                {item.name}
                                                            </Link>
                                                            {!item.active && <div className="inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-medium capitalize text-secondary bg-secondary/10" >Coming Soon</div>}
                                                        </div>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                    <Disclosure as="div" className="-mx-3">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className={"flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:sm:bg-base-200 hover:sm:text-base-content"}>
                                                    Solutions
                                                    {open ? <HiChevronDown className="h-5 w-5 flex-none" aria-hidden="true" /> : <HiChevronUp className="h-5 w-5 flex-none" aria-hidden="true" />}
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="mt-2 space-y-2">
                                                    {[...solutions].map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            onClick={handleMenuClick}
                                                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 hover:sm:bg-base-200 hover:sm:text-base-content"
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                    <Link
                                        to={routes.unAuth.products.find((product) => product.name === 'Pricing').href}
                                        onClick={handleMenuClick}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:sm:bg-base-200 hover:sm:text-base-content"
                                    >
                                        Pricing
                                    </Link>
                                    <Link
                                        to={routes.unAuth.about.href}
                                        onClick={handleMenuClick}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:sm:bg-base-200 hover:sm:text-base-content"
                                    >
                                        About us
                                    </Link>
                                </div>
                                <div className="flex flex-row py-6">
                                    <Link
                                        to={routes.unAuth.login.href}
                                        onClick={() => {
                                            !userIsAuthenticated && dispatch({type: "RESET_USER_MANAGEMENT"})
                                            handleMenuClick();
                                        }}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:sm:bg-base-200 hover:sm:text-base-content"
                                    >
                                        {userIsAuthenticated ? "Dashboard" : "Sign in"}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    )
};

HeaderSideBar.propTypes = {
    isSidebarOpen: PropTypes.bool,
    handleMenuClick: PropTypes.func,
};

export default HeaderSideBar;
