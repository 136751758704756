import React, {useEffect} from 'react';
import PropTypes from "prop-types";


const MdImages = ({ alt, src, ...props }) => {
    const [hasError, setHasError] = React.useState(false);

    if (hasError) {
        return null;
    } else {
        return <img
            onError={() => setHasError(true)}
            {...props} alt={alt} className="max-w-3xs rounded-md"
        />;
    }
};

MdImages.propTypes = {
    alt: PropTypes.string,
    props: PropTypes.object,
    src: PropTypes.string,
}

export default MdImages;
