import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";
import {formatDateToISO} from "../../../../../reusableFxns/dateTimeFxns";


export const calendarDatesLIST = createAsyncThunk(
    'memoMinder/calendar/dates/list',
    async ({ startYear, numYears, today }, thunkAPI) => {
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_MEMOMINDER_LIST_CALENDAR_DATES}?start_year=${startYear}&num_years=${numYears}&today=${formatDateToISO(new Date())}`,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    calendarDatesListingVF: false,
    calendarDatesListingApiState: 'idle',
    calendarDatesListingResponse: null,
    calendarDatesListingError: null,
};

export const listCalendarDatesSlice = createSlice({
    name: 'calendarDatesListing',
    initialState: INITIAL_STATE,
    reducers: {
        clearCalendarDatesListingVF: (state) => {
            state.calendarDatesListingVF = false;
        },
        resetCalendarDatesListing: (state) => {
            state.calendarDatesListingVF = false;
            state.calendarDatesListingApiState = 'idle';
            state.calendarDatesListingResponse = null;
            state.calendarDatesListingError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(calendarDatesLIST.pending, (state) => {
                state.calendarDatesListingApiState = 'loading';
                state.calendarDatesListingVF = true;
            })
            .addCase(calendarDatesLIST.fulfilled, (state, action) => {
                state.calendarDatesListingApiState = 'succeeded';
                state.calendarDatesListingResponse = action?.payload;
                state.calendarDatesListingVF = true;
                state.calendarDatesListingError = null;
            })
            .addCase(calendarDatesLIST.rejected, (state, action) => {
                state.calendarDatesListingApiState = 'failed';
                state.calendarDatesListingError = action?.payload;
                state.calendarDatesListingVF = true;
                state.calendarDatesListingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearCalendarDatesListingVF,
    resetCalendarDatesListing,
} = listCalendarDatesSlice.actions;

export default listCalendarDatesSlice.reducer;
