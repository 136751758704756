import React from "react";


const SubscriptionFormSkeleton = () => {
    
    return (
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:py-0 lg:grid-cols-9 w-full px-px">
            <div className="col-span-full">
                <div className="relative col-span-full flex w-full gap-x-4">
                    <div className="bg-base-300/30 rounded w-4 h-4 skeleton"/>
                    <div className="text-sm leading-6 w-full space-y-3.5">
                        <div className="flex items-center w-32 rounded bg-base-300/30 h-4 skeleton" />
                        <div className="space-y-3 w-full">
                            <div className="bg-base-300/30 h-4 w-full rounded skeleton"/>
                            <div className="bg-base-300/30 h-4 w-full rounded skeleton"/>
                            <div className="bg-base-300/30 h-4 w-2/3 rounded skeleton"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-full md:col-span-4 md:hidden">
                <div className="h-4 w-36 bg-base-300/30 rounded-md"/>
                <div className="mt-4 space-y-3">
                    <div className="bg-base-300/30 w-full h-4 rounded-md"/>
                    <div className="bg-base-300/30 w-2/3 h-4 rounded-md"/>
                </div>
            </div>
            <div className="col-span-full py-px">
                <div className="grid grid-cols-2 gap-x-6 gap-y-6 border-b border-t border-base-300/30 py-8">
                    <div className="col-span-full md:col-span-1 bg-base-300/30 rounded-md h-8 w-full skeleton"/>
                    <div className="col-span-full md:col-span-1 bg-base-300/30 rounded-md h-8 w-full skeleton"/>
                    <div className="col-span-full bg-base-300/30 rounded-md h-8 w-full skeleton"/>
                    <div className="col-span-full md:col-span-1 bg-base-300/30 rounded-md h-8 w-full skeleton"/>
                    <div className="col-span-full md:col-span-1 bg-base-300/30 rounded-md h-8 w-full skeleton"/>
                    <div className="col-span-full rounded-md h-8 w-full bg-base-300/30 skeleton"/>
                    <div className="col-span-full bg-base-300/30 rounded-md h-8 w-full skeleton"/>
                </div>
                <div className="col-span-full space-y-4 border-b border-base-300/30 py-6">
                    <div className="flex items-center w-full space-x-6">
                        <div className="col-span-full bg-base-300/30 rounded-md h-8 w-full skeleton"/>
                        <div className="rounded-md px-3 py-2 bg-base-300/30 h-8 w-20 skeleton"/>
                    </div>
                </div>
                <div className="col-span-full py-5 space-y-3 text-sm">
                    <div className="flex items-center justify-between text-base-content/70">
                        <div className="bg-base-300/30 rounded h-3.5 w-16 skeleton"/>
                        <div className="bg-base-300/30 rounded h-3.5 w-20 skeleton"/>
                    </div>
                    <div className="flex items-center justify-between text-base-content/70">
                        <div className="bg-base-300/30 rounded h-3.5 w-16 skeleton"/>
                        <div className="bg-base-300/30 rounded h-3.5 w-20 skeleton"/>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <div className="h-3.5 w-16 bg-base-300/30 rounded skeleton"/>
                    <div className="h-8 w-28 bg-base-300/30 rounded-md skeleton"/>
                </div>
            </div>
        </div>
    )
};

export default SubscriptionFormSkeleton;


