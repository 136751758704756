import React from 'react';
import {Form, Formik} from "formik";
import {AnimatePresence} from "framer-motion";
import {CgSpinner} from "react-icons/cg";
import clsx from "clsx";
import PropTypes from "prop-types";
import {RiCheckboxCircleLine, RiCloseCircleLine} from "react-icons/ri";

import SingleSelect from "../../../../../../Components/Selects/SingleSelect";
import {useUpdateSubscriptionPaymentMethod} from "../../../../../../Hooks/apiHooks/payments/useUpdateSubscriptionPaymentMethod";
import {calculateDiscountAmount} from "../SubscriptionManagement";
import {getPeriodEnd} from "../../Subscriptions";
import ExistingSubscriptionModal from "./ExistingSubscriptionModal";


const ExistingSubscriptionForm = (
    { paymentList, subscriptionStatus, initialValues, userSubscription }
) => {
    const notAvailable = subscriptionStatus === 'Not available';

    const {
        updateUserSubscriptionPaymentMethod,
        subscriptionPaymentMethodUpdateVF,
        subscriptionPaymentMethodUpdateFailureVF,
        subscriptionPaymentMethodUpdateSuccessVF,
        subscriptionPaymentMethodUpdateLoadingVF
    } = useUpdateSubscriptionPaymentMethod({ vfExists: true });

    const onPaymentMethodUpdate = (values) => {
        updateUserSubscriptionPaymentMethod(
            userSubscription?.price_id,
            values.payment_method.id
        )
    };

    const discountAmount = calculateDiscountAmount(
        userSubscription?.discount?.type,
        userSubscription?.discount?.value,
        userSubscription?.original_price
    );

    const subscriptionIsCancelled = userSubscription?.is_cancellation_scheduled;

    return (
        <>
            {!subscriptionIsCancelled && <Formik
                initialValues={initialValues}
                onSubmit={onPaymentMethodUpdate}
                // validationSchema={preferenceValidationSchema}
            >
                {({errors, touched, isSubmitting, isValid, values, setFieldValue, handleBlur, validateForm, resetForm}) => (
                    <Form className="grid grid-cols-5 gap-2">
                        <p className="text-sm font-medium text-base-content/70 col-span-full sm:col-span-2">
                            Payment Method
                        </p>
                        <div className="flex items-center gap-x-4 col-span-full md:col-span-3">
                            <AnimatePresence>
                                <div
                                    className="relative w-full">
                                    <SingleSelect
                                        name="payment_method"
                                        list={paymentList}
                                        disabled={notAvailable}
                                        placeholder="Select"
                                    />
                                </div>
                            </AnimatePresence>
                            <button
                                type="submit"
                                className={clsx(
                                    subscriptionPaymentMethodUpdateFailureVF ? "error-button" :
                                        subscriptionPaymentMethodUpdateSuccessVF ? "success-button" :
                                            "standard-button")}
                            >
                                <CgSpinner
                                    className={clsx(
                                        "absolute inset-0 m-auto animate-spin h-5 w-5",
                                        subscriptionPaymentMethodUpdateLoadingVF ? "" : "hidden"
                                    )}
                                    aria-hidden="true"
                                />
                                <RiCloseCircleLine
                                    className={clsx(
                                        "absolute inset-0 m-auto h-5 w-5",
                                        subscriptionPaymentMethodUpdateFailureVF ? "" : "hidden"
                                    )}
                                    aria-hidden="true"
                                />
                                <RiCheckboxCircleLine
                                    className={clsx(
                                        "absolute inset-0 m-auto h-5 w-5",
                                        subscriptionPaymentMethodUpdateSuccessVF ? "" : "hidden"
                                    )}
                                    aria-hidden="true"
                                />
                                <p className={subscriptionPaymentMethodUpdateVF ? "invisible" : ""}>
                                    Update
                                </p>
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>}
            <div className="col-span-2 col-start-2 space-y-2">
                <div className="flex items-center justify-between text-base-content/70">
                    <p>Subtotal</p>
                    <div className="flex items-center space-x-0.5">
                        <p>${userSubscription?.original_price}</p>
                        <p>/month</p>
                    </div>
                </div>
                {userSubscription?.discount && (
                    <div className="flex items-center justify-between text-base-content/70">
                        <div className="flex items-center space-x-1">
                            <p>Discount</p>
                            <p className="uppercase">({userSubscription?.discount?.name})</p>
                        </div>
                        <div className="flex items-center space-x-0.5">
                            <p>-${discountAmount}</p>
                            <p>/month</p>
                        </div>
                    </div>
                )}
                <div className={clsx("flex items-center justify-between",
                    !subscriptionIsCancelled ? "text-base-content font-medium" : "text-base-content/70"
                )}>
                    <p>{!subscriptionIsCancelled ? "Next invoice" : "Total"}</p>
                    <div className="flex items-center space-x-0.5">
                        <p>{!subscriptionIsCancelled ?
                            `${getPeriodEnd(userSubscription?.current_period_end)} for $${userSubscription?.effective_price}` :
                            `$${userSubscription?.effective_price}/month`}
                        </p>
                    </div>
                </div>
                {subscriptionIsCancelled && <div className="flex items-center justify-between text-base-content font-medium">
                    <p>Cancels on</p>
                    <div className="flex items-center space-x-0.5">
                        <p>{getPeriodEnd(userSubscription?.current_period_end)}</p>
                    </div>
                </div>}
            </div>
            <div className="flex justify-end col-span-full">
                <ExistingSubscriptionModal
                    product_name={userSubscription?.product_name}
                    price_id={userSubscription?.price_id}
                    billing_end={getPeriodEnd(userSubscription?.current_period_end)}
                />
            </div>
        </>
    );
};

ExistingSubscriptionForm.propTypes = {
    subscriptionStatus: PropTypes.string,
    paymentList: PropTypes.array,
    initialValues: PropTypes.object,
    userSubscription: PropTypes.object,
    subscriptionPlan: PropTypes.object
};

export default ExistingSubscriptionForm;
