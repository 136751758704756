import React from "react";
import {motion} from "framer-motion";
import PropTypes from "prop-types";
import clsx from "clsx";
import {TbArrowNarrowDown} from "react-icons/tb";
import {BiChevronDown, BiChevronUp} from "react-icons/bi";

import {useGetSingleTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetSingleTgConversation";
import {useStreamTaskGlide} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";
import {useDeleteSingleTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteSingleTgConversation";
import {useListAllTgConversations} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useListAllTgConversations";
import FollowUpExpandedModal from "./FollowUpExpandedModal";
import {useGetCurrentTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetCurrentTgConversation";


const TaskGlideAddOnTab = (
    { formik, setIsAutoScrollEnabled, isAutoScrollEnabled, followUpsAreCollapsed, setFollowUpsAreCollapsed }
) => {
    const { taskGlideStreamVF } = useStreamTaskGlide({ isTest: false });
    const { tgSingleConversationRetrievalVF } = useGetSingleTgConversation();
    const { tgSingleConversationDeleteVF } = useDeleteSingleTgConversation();
    const { tgConversationsListingResponse } = useListAllTgConversations();
    const { currentTgConversation } = useGetCurrentTgConversation();

    const disabledState = taskGlideStreamVF || tgSingleConversationRetrievalVF || tgSingleConversationDeleteVF;

    const interactions = currentTgConversation?.interactions?.results;
    const lastInteraction = interactions?.length > 0 ? interactions[interactions?.length - 1] : null;
    const glide_responses = lastInteraction?.messages?.filter(message => message?.type === 'glide_response')
    const followUps = glide_responses && glide_responses[glide_responses?.length - 1]?.content?.follow_ups;

    const buttonVariants = {
        hidden: index => ({
            opacity: 0,
            transition: { delay: index * 0.1 }
        }),
        visible: index => ({
            opacity: 1,
            transition: {
                delay: index * 0.1,
            }
        })
    };

    const handleButtonClick = (followUpText) => {
        formik.setFieldValue('text_input', followUpText, false);
        formik.submitForm();
    };

    const handleEnableAutoScroll = () => {
        setIsAutoScrollEnabled(true);
    };

    if (!tgConversationsListingResponse) {
        return (
            <div className="absolute bottom-1 flex items-center space-x-3 pointer-events-auto justify-stretch py-2 px-4 sm:px-6 lg:px-8 w-full z-40">
                <div className="grid grid-cols-2 grid-rows-1 gap-x-3 w-full">
                    <div className="bg-base-200 text-base-content font-light rounded-md ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 hover:sm:text-base-content disabled:cursor-not-allowed disabled:hover:text-base-content text-sm px-4 py-2 overflow-hidden flex items-center justify-between space-x-2 h-8 bg-base-300/30 skeleton" />
                    <div className="bg-base-200 text-base-content font-light rounded-md ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 hover:sm:text-base-content disabled:cursor-not-allowed disabled:hover:text-base-content text-sm px-4 py-2 overflow-hidden flex items-center justify-between space-x-2 h-8 bg-base-300/30 skeleton" />
                </div>
                <div className="flex items-center space-x-3 w-fit">
                    <div className="sticky hover:sm:text-base-content text-sm flex items-center justify-center disabled:cursor-not-allowed disabled:hover:text-base-content disabled:hover:sm:text-base-content disabled:opacity-75 bg-base-200 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 p-0.5 rounded-full bg-base-300/30 skeleton w-8 h-8" />
                    <div className="sticky hover:sm:text-base-content text-sm flex items-center justify-center disabled:cursor-not-allowed disabled:hover:text-base-content disabled:hover:sm:text-base-content disabled:opacity-75 rounded-full bg-base-200 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 p-0.5 bg-base-300/30 skeleton w-8 h-8" />
                </div>
            </div>
        )
    }

    if (currentTgConversation?.interactions?.results?.length > 0) {
        return (
            <div className={clsx(
                "absolute bottom-1 flex items-center space-x-3 pointer-events-auto justify-stretch py-2 px-4 sm:px-6 lg:px-8 w-full z-40", followUps?.length > 0 && `grid-cols-${followUps?.length}`,
            )}>
                <form
                    onSubmit={formik.handleSubmit}
                    className={clsx(
                        "grid grid-rows-1 gap-x-3 w-full",
                        followUps?.slice(0, 2)?.length === 1 ? "grid-cols-1" : "grid-cols-2"
                    )}
                >
                    {(!!currentTgConversation?.id && !!interactions && followUps?.length > 0) && followUps?.slice(0, 2).map((followUp, index) => {
                        return (
                            <motion.div
                                key={index}
                                variants={buttonVariants}
                                initial="hidden"
                                animate={followUpsAreCollapsed ? "hidden" : "visible"}
                                exit="hidden"
                                custom={index}
                                className={clsx("h-full bg-base-200 text-base-content font-light rounded-lg ring-[0.5px] ring-base-content/20 hover:sm:text-base-content disabled:cursor-not-allowed disabled:hover:text-base-content text-sm flex items-center justify-between space-x-0.5 sm:space-x-1 overflow-visible shadow-[0px_0px_5px_rgba(0,0,0,0.10)] dark:shadow-[0px_0px_10px_rgba(0,0,0,0.50)]", followUpsAreCollapsed && "pointer-events-none")}
                                disabled={disabledState || !followUp || followUpsAreCollapsed}
                            >
                                <button
                                    type="submit"
                                    className="truncate line-clamp-1 prose-p:leading-snug text-start break-all px-2 sm:px-4 py-2 w-full button-ring-focus"
                                    onClick={() => handleButtonClick(followUp)}
                                >
                                    {followUp}
                                </button>
                                <FollowUpExpandedModal
                                    index={index}
                                    text={followUp}
                                    onSubmit={() => handleButtonClick(followUp)}
                                    isLoading={disabledState || !followUp || followUpsAreCollapsed}
                                />
                            </motion.div>
                        )})}
                </form>
                <div className="flex items-center space-x-3 w-fit">
                    <button
                        className="sticky hover:sm:text-base-content text-sm flex items-center justify-center disabled:cursor-not-allowed disabled:hover:text-base-content w-full disabled:hover:sm:text-base-content disabled:opacity-75 rounded-full bg-base-200 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 p-0.5 group/expand-followups focus-visible:ring focus-visible:ring-info/40 focus-visible:outline-none dark:focus-visible:ring-info/40 focus-visible:rounded-full shadow-[0px_0px_5px_rgba(0,0,0,0.10)]"
                        onClick={() => setFollowUpsAreCollapsed(!followUpsAreCollapsed) || followUps?.length === 0}
                        disabled={!followUps}
                    >
                        {followUpsAreCollapsed ?
                            <BiChevronUp className={clsx(
                                "w-6 h-6 shrink-0 flex",
                                followUps?.length === 0 && "group-hover/collapse-followups:sm:hidden"
                            )}/> :
                            <BiChevronDown className={clsx(
                                "w-6 h-6 shrink-0 flex",
                                followUps?.length === 0 && "group-hover/collapse-followups:sm:hidden"
                            )}/>}
                        <div className="top-tooltips bottom-10 group-hover/expand-followups:lg:block">{followUpsAreCollapsed ? "Show follow-ups" : "Hide follow-ups"}</div>
                    </button>
                    <button
                        className="group/scroll-down sticky text-base-content hover:sm:text-base-content text-sm flex items-center justify-center disabled:cursor-not-allowed w-full disabled:opacity-50 rounded-full bg-base-200 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 p-0.5 focus-visible:ring focus-visible:ring-info/40 focus-visible:outline-none dark:focus-visible:ring-info/40 focus-visible:rounded-full shadow-[0px_0px_5px_rgba(0,0,0,0.10)] dark:shadow-[0px_0px_5px_rgba(0,0,0,0.30)]"
                        disabled={isAutoScrollEnabled}
                        onClick={handleEnableAutoScroll}
                    >
                        <TbArrowNarrowDown className="w-6 h-6 shrink-0" />
                        {!isAutoScrollEnabled && <div className="top-tooltips bottom-10 group-hover/scroll-down:lg:block">Scroll down</div>}
                    </button>
                </div>
            </div>
        )
    }
};

TaskGlideAddOnTab.propTypes = {
    formik: PropTypes.object,
    setIsAutoScrollEnabled: PropTypes.func,
    isAutoScrollEnabled: PropTypes.bool,
    followUpsAreCollapsed: PropTypes.bool,
    setFollowUpsAreCollapsed: PropTypes.func,
    sessionInfo: PropTypes.object,
    selectedGlides: PropTypes.array,
};

export default TaskGlideAddOnTab;
