import React from "react";
import {Switch} from "@headlessui/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {IoCheckmarkSharp, IoCloseSharp} from "react-icons/io5";
import {ImSpinner8} from "react-icons/im";


const ToggleButton = ({ checked, onChange, title, description, accessibleTitle, loading, className, badgeText,...props }) => {
    
    return (
        <Switch.Group as="div" className={clsx("flex items-start cursor-pointer", className)}>
            <Switch
                checked={checked}
                onChange={onChange}
                className={clsx(
                    checked ? 'bg-primary text-primary' : 'bg-base-300/70 text-base-300/70',
                    'toggle-button'
                )}
                {...props}
            >
                <span className="sr-only">{accessibleTitle}</span>
                <span
                    className={clsx(
                        checked ? 'translate-x-4' : 'translate-x-0',
                        'flex items-center justify-center pointer-events-none relative h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                >
                        {loading ? <ImSpinner8 className="animate-spin h-2.5 w-2.5"/> :
                            (<>
                             <span
                                 className={clsx(
                                     checked ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                                     'absolute inset-0 flex h-full w-full items-center justify-center'
                                 )}
                                 aria-hidden="true"
                             >
                              <IoCloseSharp className="h-2.5 w-2.5"/>
                            </span>
                            <span
                                className={clsx(
                                    checked ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                                    'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                )}
                                aria-hidden="true"
                            >
                              <IoCheckmarkSharp className="h-2.5 w-2.5"/>
                            </span>
                            </>)}
                  </span>
            </Switch>
            <Switch.Label as="span" className="flex flex-col space-y-1 text-sm ml-3 -mt-1">
                <div className="inline space-x-3" >
                    <span className="text-base-content inline">{title}</span>{' '}
                    {badgeText && <span className="secondary-badge inline">{badgeText}</span>}
                </div>
                <p className="text-base-content/70">{description}</p>
            </Switch.Label>
        </Switch.Group>
    )
};

ToggleButton.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    badgeText: PropTypes.string,
    accessibleTitle: PropTypes.string,
    loading: PropTypes.bool,
    className: PropTypes.string,
};

export default ToggleButton;
