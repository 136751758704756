import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "./useAuthentication";
import {clearFeedbackCreationVF, feedbackPOST, resetFeedbackCreation} from "../../../api/features/users/createFeedbackSlice";
import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";


export const useCreateFeedback = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();
    const {
        feedbackCreateVF,
        feedbackCreateApiState,
        feedbackCreateResponse,
        feedbackCreateError,
    } = useSelector(state => state.createFeedbackReducer);
    const { userIsAuthenticated } = useAuthentication();

    const feedbackCreateSuccessVF = feedbackCreateApiState === 'succeeded' && feedbackCreateVF;
    const feedbackCreateFailureVF = feedbackCreateApiState === 'failed' && feedbackCreateVF;

    const createFeedback = (values) => userIsAuthenticated && dispatch(feedbackPOST(values));

    useEffect(() => {
        if (vfExists) {
            if (feedbackCreateSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearFeedbackCreationVF());
                }, 250);

                return () => clearTimeout(timer);
            } else if (feedbackCreateFailureVF) {
                dispatch(setAlert({
                    message: feedbackCreateResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetFeedbackCreation());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [feedbackCreateApiState, feedbackCreateVF]);

    return {
        createFeedback,
        feedbackCreateVF,
        feedbackCreateApiState,
        feedbackCreateResponse,
        feedbackCreateError,
        feedbackCreateSuccessVF,
        feedbackCreateFailureVF
    };
};
