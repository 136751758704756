import React from "react";
import PropTypes from "prop-types";

import TaskGlideBrowsingSources from "./TaskGlideBrowsingSources";


const TaskGlideAddOns = ({ message }) => {
    const state = message?.glide_response?.status?.state;
    const isValid = state === "saved" || state === "completed" || state === "finalized" || state === "stopped";

    if (isValid) {
        return (
            <>
                <TaskGlideBrowsingSources message={message} />
                {/*<EmailConsentButtons />*/}
            </>
        )
    }
};

TaskGlideAddOns.propTypes = {
    message: PropTypes.object.isRequired,
    controller: PropTypes.object,
}

export default TaskGlideAddOns;
