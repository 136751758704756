import React, {useRef} from "react";
import {FiPaperclip} from "react-icons/fi";
import {Menu} from "@headlessui/react";
import {CgMenuGridO} from "react-icons/cg";
import {ImAttachment} from "react-icons/im";
import PropTypes from "prop-types";


const ButtonAndDropDown = ({
    handleFileOrPhotoSelect,
    hasMenu,
    additionalMenuButtons,
    disabled
}) => {
    const inputRef = useRef(null);

    return (
        <>
            <label className="absolute left-3 bottom-[7px] h-fit group p-1.5 bg-transparent text-base-content group/menu cursor-pointer lg:flex hidden">
                <span className="sr-only">Attach a file or photo</span>
                <input
                    multiple
                    type="file"
                    ref={inputRef}
                    accept=".eml,.html,.json,.md,.msg,.rst,.rtf,.txt,.xml,.jpeg,.png,.csv,.doc,.docx,.epub,.odt,.pdf,.ppt,.pptx,.tsv,.xlsx,image/*"
                    disabled={disabled}
                    onChange={handleFileOrPhotoSelect}
                    className="w-0 text-base-content/70 file:py-2 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-base-100/70 file:text-base-content/90 hover:sm:file:text-base-content hover:sm:file:bg-base-100 file:ring-1 file:ring-neutral-focus file:ring-inset focus:outline-0 file:active:scale-95 absolute hidden group-disabled:hover:sm:cursor-not-allowed file:cursor-pointer"
                />
                <FiPaperclip className="h-6 w-6 shrink-0" aria-hidden="true" />
            </label>
            {hasMenu && (
                <Menu as="div" className="relative dropdown dropdown-top text-base-300/70 flex lg:hidden">
                    <Menu.Button
                        data-tip="TaskGlide Menu"
                        className="absolute left-3 bottom-[7px] h-fit group p-1.5 bg-transparent text-base-content group/menu"
                    >
                        <span className="sr-only">Open TaskGlide Menu</span>
                        <CgMenuGridO className="h-6 w-6" aria-hidden="true" />
                        <div className="top-tooltips bottom-12 group-hover/menu:lg:block">Menu</div>
                    </Menu.Button>

                    <Menu.Items className="button-dropdown left-0 mr-2 mb-2 w-40">
                        <label className="dropdown-item">
                            <span className="sr-only">Attach a file</span>
                            <input
                                multiple
                                type="file"
                                ref={inputRef}
                                accept=".eml,.html,.json,.md,.msg,.rst,.rtf,.txt,.xml,.jpeg,.png,.csv,.doc,.docx,.epub,.odt,.pdf,.ppt,.pptx,.tsv,.xlsx,image/*"
                                disabled={disabled}
                                onChange={handleFileOrPhotoSelect}
                                className="w-0 text-base-content/70 file:py-2 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-base-100/70 file:text-base-content/90 hover:sm:file:text-base-content hover:sm:file:bg-base-100 file:ring-1 file:ring-neutral-focus file:ring-inset focus:outline-0 file:active:scale-95 absolute hidden group-disabled:hover:sm:cursor-not-allowed file:cursor-pointer"
                            />
                            <ImAttachment className="h-4 w-4 shrink-0" aria-hidden="true" />
                            <p>Attach all</p>
                        </label>
                        {typeof additionalMenuButtons === 'function' ? additionalMenuButtons() : additionalMenuButtons}
                    </Menu.Items>
                </Menu>
            )}
        </>
    );
};

ButtonAndDropDown.propTypes = {
    handleFileOrPhotoSelect: PropTypes.func,
    hasMenu: PropTypes.bool,
    additionalMenuButtons: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    disabled: PropTypes.bool
};

export default ButtonAndDropDown;
