import React from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import {IoMdCloseCircle} from "react-icons/io";
import PropTypes from "prop-types";
import clsx from "clsx";
import {CgSpinner} from "react-icons/cg";

import {FileIcon} from "../../../../../../../Components/TaskGlide/TaskGlideDocuments";
import {useParseDocuments} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useParseDocuments";



const TaskGlidePhotoFileInputField = ({ selectedPhotos, selectedFiles, handleFileRemove, handlePhotoRemove }) => {
    const { parseDocumentsVF } = useParseDocuments({ vfExists: true });

    const mimeToExtension = {
        'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    };

    const knownFileTypes = ['pdf', 'jpeg', 'jpg', 'png', 'gif', 'doc', 'xls', 'ppt', 'txt'];

    const getSimpleFileType = (mimeType) => {
        const parts = mimeType?.split('/');
        const lastPart = parts[parts?.length - 1].toLowerCase();
        if (mimeToExtension[lastPart]) {
            return mimeToExtension[lastPart];
        } else if (knownFileTypes.includes(lastPart)) {
            return lastPart;
        } else {
            return "Document";
        }
    };

    return (
        (selectedPhotos?.length > 0 || selectedFiles?.length > 0) && (
            <div className="relative self-start flex flex-wrap gap-4 pt-2 px-2 w-full">
                {selectedPhotos?.map((photo, index) => (
                    <div key={index} className="relative w-fit">
                        <button
                            type="button"
                            className="absolute top-0 right-0 overflow-hidden rounded-full"
                            onClick={() => handlePhotoRemove(index)}
                        >
                            <AiFillCloseCircle className="h-5 w-5 shrink-0 m-0.5 text-base-200/70 hover:sm:text-base-200"/>
                        </button>
                        <img src={URL.createObjectURL(photo)} alt="Selected" className="max-w-xs max-h-32 object-cover rounded-2xl self-start"/>
                    </div>
                ))}
                {selectedFiles?.map((file, index) => (
                    <div key={index} className="col-span-2 sm:col-span-1 bg-base-100 ring-[0.5px] ring-inset ring-base-content/30 rounded-md px-3 py-2.5 w-fit max-w-3xs h-full self-end text-xs items-center overflow-hidden flex flex-col space-y-2 justify-between text-center">
                        <div className="bg-base-200 p-0.5 rounded-md ring-[0.5px] ring-inset ring-base-content shadow">
                            <CgSpinner className={clsx("size-8 animate-spin shrink-0", !parseDocumentsVF && "hidden")}/>
                            <FileIcon
                                fileFormat={file?.type?.split('/')[1]}
                                className={clsx("size-8 p-0.5", parseDocumentsVF && "hidden")}
                                aria-hidden="true"
                            />
                        </div>
                        <div className="space-y-1 w-fit overflow-hidden break-all">
                            <p>{file.name}</p>
                            <p className="uppercase font-light">{getSimpleFileType(file.type)}</p>
                        </div>
                        <button
                            type="button"
                            className="h-fit bg-base-100 text-base-content/70 hover:sm:text-base-content"
                            onClick={() => handleFileRemove(index)}
                        >
                            <IoMdCloseCircle className="h-5 w-5"/>
                        </button>
                    </div>
                ))}
            </div>
        )
    );
};

TaskGlidePhotoFileInputField.propTypes = {
    selectedPhotos: PropTypes.array,
    selectedFiles: PropTypes.array,
    handleFileRemove: PropTypes.func,
    handlePhotoRemove: PropTypes. func
};

export default TaskGlidePhotoFileInputField;
