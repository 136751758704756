import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";


const BgCheckers = ({ lines, boxes }) => {
    const strokeColor = lines ? lines : "stroke-primary/10";
    const fillColor = boxes ? boxes : "fill-primary/10";

    return (
        <svg className={clsx("absolute inset-0 -z-20 h-full w-full [mask-image:radial-gradient(100%_100%_at_center,white,transparent)] opacity-75 dark:opacity-50", strokeColor)} aria-hidden="true" >
            <defs>
                <pattern
                    id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                    width={200}
                    height={200}
                    x="50%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                >
                    <path d="M.5 200V.5H200" fill="none" />
                </pattern>
            </defs>
            <svg x="50%" y={-1} className={clsx("overflow-visible", fillColor)} >
                <path
                    d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                    strokeWidth={0}
                />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
        </svg>
    )
};

BgCheckers.propTypes = {
    lines: PropTypes.string,
    boxes: PropTypes.string,
}

BgCheckers.defaultProps = {
    color: "primary",
};

export default BgCheckers;
