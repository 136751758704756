import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {setTestConversation} from "../../../../../api/features/frontend/taskGlideUiActionsSlice";
import {clearTestConversationDeletionVF, testConversationDELETE} from "../../../../../api/features/superIntelligence/taskGlide/conversations/deleteTestConversationSlice";


export const useDeleteTestConversation = (
    { vfExists=false, autoUpdate=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Task Glide
    const {
        testConversationDeletionVF,
        testConversationDeletionApiState,
        testConversationDeletionResponse,
        testConversationDeletionError,
    } = useSelector(state => state.deleteTestConversationReducer);

    // API states with visual feedback
    const testConversationDeletionSuccessVF = testConversationDeletionApiState === 'succeeded' && testConversationDeletionVF;
    const testConversationDeletionFailureVF = testConversationDeletionApiState === 'succeeded' && testConversationDeletionVF;

    const deleteTestConversation = (user_glide_uuid) => {
        userIsAuthenticated && dispatch(testConversationDELETE({ user_glide_uuid }))
    };

    useEffect(() => {
        if (vfExists && testConversationDeletionSuccessVF) {
            const timer = setTimeout(() => {
                dispatch(clearTestConversationDeletionVF());
            }, 250);

            return () => clearTimeout(timer);
        }
    }, [testConversationDeletionApiState, testConversationDeletionVF]);

    useEffect(() => {
        if (autoUpdate && testConversationDeletionApiState === 'succeeded' && !testConversationDeletionVF) {
            dispatch(setTestConversation(null));
        }
    }, [testConversationDeletionApiState, testConversationDeletionVF]);

    return {
        deleteTestConversation,
        testConversationDeletionVF,
        testConversationDeletionApiState,
        testConversationDeletionResponse,
        testConversationDeletionError,
        testConversationDeletionSuccessVF,
        testConversationDeletionFailureVF
    };
};
