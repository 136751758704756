import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearPublicGlidesListingVF, publicGlidesGET, resetPublicGlidesListing} from "../../../../../api/features/superIntelligence/taskGlide/glides/listPublicGlidesSlice";


export const useListPublicGlides = ({ vfExists=false } = {}) => {
    const dispatch = useDispatch();

    // MyGlides
    const {
        publicGlidesListingVF,
        publicGlidesListingApiState,
        publicGlidesListingResponse,
        publicGlidesListingError,
    } = useSelector(state => state.listPublicGlidesReducer);

    // API states with visual feedback
    const publicGlidesListingSuccessVF = publicGlidesListingApiState === 'succeeded' && publicGlidesListingVF;
    const publicGlidesListingFailureVF = publicGlidesListingApiState === 'failed' && publicGlidesListingVF;

    const getPublicGlides = () => dispatch(publicGlidesGET());

    useEffect(() => {
        if (vfExists) {
            if (publicGlidesListingSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearPublicGlidesListingVF());
                }, 250);

                return () => clearTimeout(timer);
            } else if (publicGlidesListingFailureVF) {
                dispatch(setAlert({
                    message: publicGlidesListingResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetPublicGlidesListing());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [publicGlidesListingApiState, publicGlidesListingVF]);

    return {
        getPublicGlides,
        publicGlidesListingVF,
        publicGlidesListingApiState,
        publicGlidesListingResponse,
        publicGlidesListingError,
        publicGlidesListingSuccessVF,
        publicGlidesListingFailureVF
    };
};
