import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearTgSingleConversationRetrievalVF, resetTgSingleConversationRetrieval, singleTgConversationGET} from "../../../../../api/features/superIntelligence/taskGlide/conversations/getSingleTgConversationSlice";
import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {setTgStreamConversationUuid, setTgStreamInteractionId, setTgStreamInteractionMessages, setTgStreamInteractionStatus, setTgStreamSchema} from "../../../../../api/features/superIntelligence/taskGlide/frontend/getTgInteractionStreamSlice";
import {setCurrentTgConversation} from "../../../../../api/features/superIntelligence/taskGlide/frontend/getCurrentTgConversationSlice";
import {resetTaskGlideStreaming} from "../../../../../api/features/superIntelligence/taskGlide/taskGlide/streamTaskGlideSlice";


export const useGetSingleTgConversation = (
    {vfExists=false, autoSelectPostFetch=false} = {}
) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();
    const {
        tgSingleConversationRetrievalVF,
        tgSingleConversationRetrievalApiState,
        tgSingleConversationRetrievalResponse,
        tgSingleConversationRetrievalError,
    } = useSelector(state => state.getSingleTGConversationReducer);

    const tgConversationRetrievalSuccessVF = tgSingleConversationRetrievalApiState === 'succeeded' && tgSingleConversationRetrievalVF;
    const tgConversationRetrievalFailureVF = tgSingleConversationRetrievalApiState === 'failed' && tgSingleConversationRetrievalVF;

    const getSingleTGConversation = (conversation_uuid, page_size = 5) => {
        if (userIsAuthenticated) {
            dispatch(singleTgConversationGET({ conversation_uuid, page_size }));
        }
    };

    useEffect(() => {
        if (vfExists) {
            if (tgConversationRetrievalSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearTgSingleConversationRetrievalVF());
                }, 100);

                return () => clearTimeout(timer);
            } else if (tgConversationRetrievalFailureVF) {
                dispatch(setAlert({
                    message: tgSingleConversationRetrievalResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetTgSingleConversationRetrieval());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [tgSingleConversationRetrievalApiState, tgSingleConversationRetrievalVF]);

    useEffect(() => {
        if (autoSelectPostFetch && tgSingleConversationRetrievalApiState === 'succeeded' && !tgSingleConversationRetrievalVF) {
            dispatch(setCurrentTgConversation(tgSingleConversationRetrievalResponse));
            dispatch(resetTgSingleConversationRetrieval());
            dispatch(resetTaskGlideStreaming());

            // Stream clearance
            dispatch(setTgStreamConversationUuid(null));
            // dispatch(setTgStreamSelectedGlides(null));
            dispatch(setTgStreamInteractionId(null))
            dispatch(setTgStreamInteractionStatus(null))
            dispatch(setTgStreamInteractionMessages(null))
            // dispatch(setTgStreamUserMessage(null));
            // dispatch(setTgStreamGlideResponse(null));
            dispatch(setTgStreamSchema(null));
        }
    }, [tgSingleConversationRetrievalApiState, tgSingleConversationRetrievalVF]);

    return {
        getSingleTGConversation,
        tgSingleConversationRetrievalVF,
        tgSingleConversationRetrievalApiState,
        tgSingleConversationRetrievalResponse,
        tgSingleConversationRetrievalError,
        tgConversationRetrievalSuccessVF,
        tgConversationRetrievalFailureVF
    };
};
