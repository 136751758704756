import React, {useEffect, useRef} from "react";
import {IoIosPaperPlane} from "react-icons/io";
import clsx from "clsx";
import {Field, Form, Formik} from "formik";
import {CgSpinner} from "react-icons/cg";
import {useNavigate, useSearchParams} from "react-router-dom";
import PropTypes from "prop-types";
import routes from "../../../../../../Routes/routes";


const NewSubscriptionModal = ({ disabledState, product_name, subscriptionCreationHook }) => {
    const navigate = useNavigate();
    const initialFocusRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const isModalOpen = searchParams.get('new_subscription') === 'open';
    const isModalOpenParam = searchParams.get('new_subscription') === 'open';

    const {
        subscriptionCreationVF,
        subscriptionCreationApiState,
        subscriptionCreationResponse,
        subscriptionCreationError,
    } = subscriptionCreationHook({ vfExists: true });

    useEffect(() => {
        const dialogElement = document.getElementById('newSubscriptionModal');
        if (isModalOpenParam) {
            dialogElement?.showModal();
            initialFocusRef.current?.focus();
        } else {
            dialogElement?.close();
        }
    }, [isModalOpenParam]);

    const handleOpenModal = (e) => {
        if (!disabledState) {
            e.preventDefault();
            const dialogElement = document.getElementById('newSubscriptionModal');
            dialogElement.showModal();
            setSearchParams({ 'new_subscription': 'open' });
        }
    };

    const handleCloseModal = () => {
        const dialogElement = document.getElementById('newSubscriptionModal');
        if (dialogElement) {
            dialogElement.close();
        }
        setSearchParams({ 'new_subscription': 'close' });
    };

    useEffect(() => {
        if (subscriptionCreationApiState === 'succeeded' || subscriptionCreationApiState === 'failed') {
            navigate(routes.auth.billing.subscriptions.find(route => route.label === 'Subscriptions').href);
        }
    }, [subscriptionCreationVF, subscriptionCreationApiState, setSearchParams]);

    return (
        <>
            <button
                type="button"
                disabled={disabledState}
                className="primary-button"
                onClick={handleOpenModal}
            >
                Subscribe
            </button>
            <dialog
                id="newSubscriptionModal"
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={handleCloseModal}
            >

                <div className="modal-wrapper space-y-4">
                    <div className="sm:text-left w-full">
                        <h3 className="text-base font-semibold leading-6 text-base-content">
                            Subscribe to {product_name}
                        </h3>
                        <div className="mt-2">
                        <p className="text-sm text-base-content/70">
                            Are you sure you want to subscribe to {product_name}? This will result in a monthly charge to your account.
                        </p>
                    </div>
                </div>
                <div className="flex items-center justify-end gap-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                const dialogElement = document.getElementById('newSubscriptionModal');
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                            className="standard-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            ref={initialFocusRef}
                            disabled={subscriptionCreationVF || disabledState}
                            className="primary-button"
                        >
                            <CgSpinner
                                className={clsx(
                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                    subscriptionCreationVF ? "" : "hidden"
                                )}
                                aria-hidden="true"
                            />
                            <p className={subscriptionCreationVF ? "invisible" : ""}>
                                Subscribe
                            </p>
                        </button>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button
                        tabIndex={-1}
                        className="cursor-default focus-visible:outline-0 focus-visible:ring-0"
                        onClick={() => {
                            const dialogElement = document.getElementById('newSubscriptionModal');
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >close
                    </button>
                </form>
            </dialog>
        </>
    )
};

NewSubscriptionModal.propTypes = {
    disabledState: PropTypes.bool,
    product_name: PropTypes.string,
    subscriptionCreationHook: PropTypes.func
};

export default NewSubscriptionModal;
