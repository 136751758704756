import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {useFormik} from "formik";
import {HiOutlineMagnifyingGlass} from "react-icons/hi2";
import PropTypes from "prop-types";
import {useSearchParams} from "react-router-dom";
import {formatDistanceToNow, isThisMonth, isThisWeek, isToday, isYesterday} from "date-fns";
import {PiListPlus, PiMagnifyingGlass} from "react-icons/pi";
import {AnimatePresence, motion} from "framer-motion";
import {SlPin} from "react-icons/sl";
import {CgArrowUp} from "react-icons/cg";
import {GoStarFill} from "react-icons/go";
import {BsPlusCircle} from "react-icons/bs";

import {useListAllTgConversations} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useListAllTgConversations";
import {useGetSingleTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetSingleTgConversation";
import {useSearchTgConversations} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useSearchTgConversations";
import {useDeleteSingleTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteSingleTgConversation";
import {useStreamTaskGlide} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";
import AllConversationDeletionModal from "../../../ConversationHistory/AllConversationDeletionModal";
import InteractionHistoryItem from "../../../ConversationHistory/InteractionHistoryItem";
import {useDeleteAllTgConversations} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteAllTgConversations";
import {useGetCurrentTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetCurrentTgConversation";
import SelectedGlidesIcons from "../../../../../../../Components/TaskGlide/SelectedGlidesIcons";
import {useTogglePinMessage} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useTogglePinMessage";
import {useGetPinnedMessage} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetPinnedMessage";
import {toggleConversationHistorySideBar} from "../../../ConversationHistory/ConversationHistoryButton";


export const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    if (isNaN(date.getTime())) {return "Invalid Date";}
    if (isToday(date)) {return "Today";}
    if (isYesterday(date)) {return "Yesterday";}
    if (isThisWeek(date)) {return "Last 7 days";}
    if (isThisMonth(date)) {return "Last 30 days";}

    return formatDistanceToNow(date, { addSuffix: true });
};

const EmptyInteractionHistoryResults = ({ history, searchHistory }) => {
    const emptyStateAnimation = {
        initial: { opacity: 0, y: 10 },
        animate: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 0.5, ease: "easeOut" } },
        exit: { opacity: 0, y: -10, transition: { duration: 0.3, ease: "easeIn" } },
    };

    if (history?.count === 0) {
        return (
            <motion.div
                className="flex flex-col justify-center text-center h-full"
                variants={emptyStateAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <PiListPlus className="h-8 w-8 text-base-300 mx-auto"/>
                <h3 className="mt-2 text-sm font-semibold text-base-content/70">No previous conversation</h3>
                <p className="mt-1 text-sm text-base-content/70">Start by communicating with TaskGlide.</p>
            </motion.div>
        );
    } else if (searchHistory?.count === 0) {
        return (
            <motion.div
                className="flex flex-col justify-center text-center h-full"
                variants={emptyStateAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <PiMagnifyingGlass className="h-8 w-8 text-base-300 mx-auto"/>
                <h3 className="mt-2 text-sm font-semibold text-base-content/70">No conversation found.</h3>
                <p className="mt-1 text-sm text-base-content/70">Search for something else. You can search for any word in any conversation as well as their titles.</p>
            </motion.div>
        );
    }
};

EmptyInteractionHistoryResults.propTypes = {
    history: PropTypes.object,
    searchHistory: PropTypes.object,
};


const ConversationHistory = ({ onConversationDelete }) => {
    const interactionHistoryRef = useRef(null);
    const searchTimeout = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const [interactionHistoryResults, setInteractionHistoryResults] = useState([]);
    const [searchMade, setSearchMade] = useState(false);
    const conversationUuid = searchParams.get('conversation_uuid');

    const { currentTgConversation, handleClearConversation, handleClickNewConversation } = useGetCurrentTgConversation();
    const { tgConversationsListingResponse, tgConversationsListingVF, tgConversationsListingApiState, listAllTgConversations } = useListAllTgConversations({ vfExists: true });
    const { tgSingleConversationRetrievalVF } = useGetSingleTgConversation();
    const { searchTGConversations, tgConversationSearchResponse, tgConversationSearchVF } = useSearchTgConversations({ vfExists: true });
    const { taskGlideStreamVF } = useStreamTaskGlide({ isTest: false });
    const {tgSingleConversationDeleteVF, tgSingleConversationDeleteApiState} = useDeleteSingleTgConversation();
    const { deleteAllTGConversations,  tgAllConversationsDeleteVF } = useDeleteAllTgConversations({ vfExists: true, autoUpdate: true });
    const { togglePinMessage, togglePinMessageVF } = useTogglePinMessage({ vfExists: true });
    const { pinnedMessageRetrievalResponse } = useGetPinnedMessage({ vfExists: true });

    useEffect(() => {
        if (tgConversationsListingApiState === 'idle') {
            listAllTgConversations();
        }
    }, [tgConversationsListingApiState]);

    const taskHistoryGroupedByDates = interactionHistoryResults?.reduce((groups, item) => {
        const formattedDate = formatDate(item?.created_at);
        if (!groups[formattedDate]) {groups[formattedDate] = [];}
        groups[formattedDate].push(item);
        return groups;
    }, {});

    const handleDeleteAllConversationsClick = () => {
        deleteAllTGConversations();
        handleClearConversation();
    }

    const [deletingConversationId, setDeletingConversationId] = useState(null);

    const handleDeleteSingleConversationClick = (id) => {
        setDeletingConversationId(id);
        onConversationDelete(id);
    };

    useEffect(() => {
        if (currentTgConversation?.conversation_uuid === deletingConversationId && tgSingleConversationDeleteApiState === 'succeeded' && !tgSingleConversationDeleteVF) {
            handleClearConversation();
        }
    }, [currentTgConversation?.conversation_uuid, tgSingleConversationDeleteVF]);

    const formik = useFormik({
        initialValues: { search: '' },
        onSubmit: values => searchTGConversations(values.search)
    });

    useEffect(() => {
        const searchValue = formik.values.search.trim();

        if (searchValue !== "") {
            if (searchTimeout.current) {clearTimeout(searchTimeout.current);}

            searchTimeout.current = setTimeout(() => {
                setSearchMade(true);
                formik.submitForm();
            }, 1000);
        } else if (searchMade) {
            if (searchTimeout.current) {clearTimeout(searchTimeout.current);}
            setSearchMade(false);
            setInteractionHistoryResults(tgConversationsListingResponse?.results);
        }
    }, [formik.values.search]);

    useEffect(() => {
        if (tgConversationSearchResponse) {
            setInteractionHistoryResults(tgConversationSearchResponse?.results);
        }
    }, [tgConversationSearchResponse]);

    useEffect(() => {
        if (tgConversationsListingResponse?.results) {
            setInteractionHistoryResults(tgConversationsListingResponse?.results);
        }
    }, [tgConversationsListingResponse]);

    const disabledState = taskGlideStreamVF || tgSingleConversationRetrievalVF || tgSingleConversationDeleteVF || tgConversationsListingVF || tgAllConversationsDeleteVF || tgConversationSearchVF || tgConversationsListingResponse?.count === 0;

    const taskHistoryAnimation = {
        initial: { opacity: 0, y: -10 },
        enter: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 10 },
    };

    const pinnedMessageText = pinnedMessageRetrievalResponse?.is_user
        ? pinnedMessageRetrievalResponse?.message?.text_input
        : pinnedMessageRetrievalResponse?.message?.response;

    return (
        <Transition.Root show={searchParams.get('conversation-history') === 'open'} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={() => toggleConversationHistorySideBar('close', searchParams, setSearchParams)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
                </Transition.Child>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-250 sm:duration-500"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-250 sm:duration-500"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex flex-col pt-4 h-full overflow-hidden bg-base-100 shadow-xl">
                                        <div className="relative px-3 mb-4 flex gap-x-3 max-h-18">
                                            <button
                                                type="button"
                                                disabled={disabledState}
                                                onClick={currentTgConversation && handleClearConversation}
                                                className="neutral-button w-2/3 mx-auto space-x-2"
                                            >
                                                <BsPlusCircle className="h-auto w-4" aria-hidden="true"/>
                                                <span>New Conversation</span>
                                            </button>
                                            <AllConversationDeletionModal
                                                onDelete={() => handleDeleteAllConversationsClick()}
                                                disabledState={disabledState}
                                            />
                                        </div>
                                        <header className="px-3 space-y-1 mb-4">
                                            <div className="w-full max-w-lg lg:max-w-md">
                                                <label htmlFor="search" className="sr-only">
                                                    Search
                                                </label>
                                                <div className="relative group">
                                                    <div className="absolute inset-y-0 left-0 flex items-center px-2 focus:outline-none">
                                                        <HiOutlineMagnifyingGlass className="h-4 w-4 text-base-300" aria-hidden="true"/>
                                                    </div>
                                                    <input
                                                        id="search"
                                                        name="search"
                                                        value={formik.values.search}
                                                        onChange={formik.handleChange}
                                                        disabled={disabledState}
                                                        className="pl-9 input-field dark:bg-base-100"
                                                        placeholder="Search Interactions"
                                                        type="text"
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </header>
                                        <ol ref={interactionHistoryRef} className="overflow-y-auto overflow-x-hidden w-full h-full scrollbar scroll-smooth rounded-md">
                                            <AnimatePresence>
                                                {interactionHistoryResults.some(item => item.is_pinned) && (
                                                    <motion.li
                                                        layout
                                                        variants={taskHistoryAnimation}
                                                        initial="initial"
                                                        animate="enter"
                                                        exit="exit"
                                                        className="flex items-center capitalize sticky top-0 bg-base-100/70 backdrop-blur-3xl text-xs py-1.5 px-4 z-[2] font-medium text-base-content/70 space-x-2"
                                                    >
                                                        <p>Pinned</p>
                                                        <SlPin className="w-3 sm:w-2.5 h-auto"/>
                                                    </motion.li>
                                                )}
                                                {interactionHistoryResults?.map((task) => {
                                                    return (task?.is_pinned && task?.is_pinned) &&
                                                        <InteractionHistoryItem
                                                            key={task?.conversation_uuid}
                                                            disabledState={disabledState}
                                                            conversationItem={task}
                                                            selectedConversation={currentTgConversation}
                                                            handleConversationClick={handleClickNewConversation}
                                                            handleDeleteSingleConversationClick={handleDeleteSingleConversationClick}
                                                            itemLoadingState={(conversationUuid === task?.conversation_uuid && tgSingleConversationRetrievalVF) || (deletingConversationId === task?.id)}
                                                            interactionHistoryRef={interactionHistoryRef}
                                                        />
                                                })}
                                                {interactionHistoryResults?.length > 0 ?
                                                    Object.entries(taskHistoryGroupedByDates).map(([date, items], index) => (
                                                        <React.Fragment key={index}>
                                                            <motion.li
                                                                key={index}
                                                                layout
                                                                variants={taskHistoryAnimation}
                                                                initial="initial"
                                                                animate="enter"
                                                                exit="exit"
                                                                className="flex items-center capitalize sticky top-0 bg-base-100/70 backdrop-blur-3xl text-xs py-1.5 px-4 z-[2] font-medium text-base-content/70">
                                                                {date}
                                                            </motion.li>
                                                            <motion.ol
                                                                layout
                                                                initial="initial"
                                                                animate="enter"
                                                                exit="exit"
                                                                variants={taskHistoryAnimation}
                                                                className="space-y-1 py-1"
                                                            >
                                                                {items.map((task) => {
                                                                    if (!task?.is_pinned) {
                                                                        return <InteractionHistoryItem
                                                                            key={task?.conversation_uuid}
                                                                            disabledState={disabledState}
                                                                            conversationItem={task}
                                                                            selectedConversation={currentTgConversation}
                                                                            handleConversationClick={handleClickNewConversation}
                                                                            handleDeleteSingleConversationClick={handleDeleteSingleConversationClick}
                                                                            itemLoadingState={(conversationUuid === task?.conversation_uuid && tgSingleConversationRetrievalVF) || (deletingConversationId === task?.id)}
                                                                            interactionHistoryRef={interactionHistoryRef}
                                                                        />
                                                                    }
                                                                })}
                                                            </motion.ol>
                                                        </React.Fragment>
                                                    )) : (!tgAllConversationsDeleteVF &&
                                                        <EmptyInteractionHistoryResults history={tgConversationsListingResponse} searchHistory={tgConversationSearchResponse}/>
                                                    )}
                                            </AnimatePresence>
                                        </ol>
                                        {(currentTgConversation && pinnedMessageText) && <div className="p-3 max-h-28">
                                            <div className="p-2 rounded-md bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50">
                                                <div className="flex items-center justify-between" >
                                                    <p className="line-clamp-1 text-xs font-semibold text-base-content/70 truncate capitalize">{pinnedMessageRetrievalResponse?.message?.agent?.name}</p>
                                                    <SelectedGlidesIcons
                                                        selectedGlides={currentTgConversation?.glide_selection}
                                                        size="h-5 w-5"
                                                    />
                                                </div>
                                                <div className="flex items-center justify-between mt-2">
                                                    <div className="flex items-center" >
                                                        <GoStarFill className="size-3.5 shrink-0 text-yellow-400 dark:text-yellow-500 mr-2"/>
                                                        <p className="line-clamp-2 text-sm sm:text-xs text-base-content/70 overflow-hidden break-all font-normal text-start">{pinnedMessageText}</p>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="hover:sm:bg-base-100 bg-base-200 rounded-full p-0.5 group/unpin relative ml-3"
                                                        onClick={() => console.log("scroll to message")}
                                                    >
                                                        <CgArrowUp className="w-4 h-4 shrink-0"/>
                                                        <div className="left-tooltips right-8 group-hover/unpin:lg:block z-50">Scroll to message</div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
};

ConversationHistory.propTypes = {
    onConversationDelete: PropTypes.func,
};

export default ConversationHistory;
