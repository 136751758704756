import React, {useRef, useState} from "react";
import {HiOutlineCheckCircle, HiOutlineDotsVertical} from "react-icons/hi";
import {RiChat1Line} from "react-icons/ri";
import {PiGearSixBold} from "react-icons/pi";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";

import {useGetUserInfo} from "../../../../../../Hooks/apiHooks/users/useGetUserInfo";
import {useUpdateUserGlideDefaultState} from "../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useUpdateUserGlideDefaultState";
import {useAddOrRemoveGlideOfUserGlides} from "../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useAddOrRemoveGlideOfUserGlides";
import DeleteMyGlideModal from "./DeleteMyGlideModal";
import DropDown from "../../../../../../Components/DropDown/DropDown";


const MyGlideExtrasMenu = ({ item, setDefaultStateUpdated, myGlideMenuParentRef }) => {
    const navigate = useNavigate();
    const modalRef = useRef(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const { userInfoRetrievalResponse } = useGetUserInfo();
    const {
        updateUserGlideDefaultState,
        userGlideDefaultStateUpdateVF
    } = useUpdateUserGlideDefaultState({ vfExists: true, autoUpdate: true });
    const {addOrRemoveGlideOfUserGlides} = useAddOrRemoveGlideOfUserGlides({ vfExists: true, autoUpdate: true });

    const isUserCreatedGlide = (glide) => glide?.user_created && glide?.created_by_username === userInfoRetrievalResponse?.username;
    const agent = item?.glide;

    const items = [
        ...(!item?.is_default ?
            [{
            label: "Set as default",
            icon: !userGlideDefaultStateUpdateVF ?
                <HiOutlineCheckCircle/> :
                <CgSpinner/>,
            onClick: () => {
                setDefaultStateUpdated(true);
                updateUserGlideDefaultState(item?.id)
            }}] : []
        ),
        {
            label: "Test",
            icon: <RiChat1Line/>,
            onClick: () => navigate(`${agent?.uuid}/test`),
        },
        isUserCreatedGlide(agent) && {
            label: "Setup",
            icon: <PiGearSixBold/>,
            onClick: () => navigate(`${agent?.uuid}/setup`),
        },
        <DeleteMyGlideModal
            key="delete"
            ref={modalRef}
            agent={agent}
            isUserCreatedGlide={isUserCreatedGlide}
            disabledState={agent?.uuid === process.env.REACT_APP_TASKGLIDE_TG_UUID}
            onDelete={() => {
                addOrRemoveGlideOfUserGlides(item?.glide?.uuid, 'remove');
                setDropdownVisible(false);
            }}
        />
    ];

    return (
        <DropDown
            dropDownClass="w-40"
            dropdownIcon={<HiOutlineDotsVertical className="w-4 h-4 shrink-0"/>}
            dropdownVisible={dropdownVisible}
            setDropdownVisible={setDropdownVisible}
            items={items}
            dropDownParent={myGlideMenuParentRef}
            additionalRefs={[modalRef]}
        />
    )
};

MyGlideExtrasMenu.propTypes = {
    item: PropTypes.object,
    setDefaultStateUpdated: PropTypes.func,
    myGlideMenuParentRef: PropTypes.object,
}

export default MyGlideExtrasMenu;
