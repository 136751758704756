import React from "react";
import {motion} from "framer-motion";
import clsx from "clsx";
import {LuMoonStar, LuSunMedium} from "react-icons/lu";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import {toggleTheme} from "../../api/features/frontend/uiSlice";


const ThemeButtons = ({ btnSize, className }) => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.frontendReducer.theme);

    const sunVariant = {
        hidden: { rotate: 0 },
        visible: { rotate: 360 },
    };

    const moonVariant = {
        hidden: { rotate: 0 },
        visible: { rotate: -360 },
    };

    return (
        <motion.button
            type="button"
            onClick={() => dispatch(toggleTheme())}
            className={clsx(className)}
        >
            <span className="sr-only">Change theme</span>

            <motion.div
                variants={sunVariant}
                animate={theme === 'light' ? "visible" : "hidden"}
                className={clsx(theme === "light" && "hidden")}
            >
                <LuSunMedium className={btnSize} />
            </motion.div>

            <motion.div
                variants={moonVariant}
                animate={theme === 'dark' ? "visible" : "hidden"}
                className={clsx(theme === "dark" && "hidden")}
            >
                <LuMoonStar className={btnSize} />
            </motion.div>
        </motion.button>
    )
};

ThemeButtons.defaultProps = {
    btnSize: 'w-5 h-5',
    className: 'rounded-full px-4 hover:text-base-content text-base-content/70 cursor-pointer outline-none',
};

ThemeButtons.propTypes = {
    btnSize: PropTypes.string,
    className: PropTypes.string,
}

export default ThemeButtons;
