import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../users/useAuthentication";
import {clearSubscriptionPlansListingVF, subscriptionPlansLIST} from "../../../api/features/payments/listSubscriptionPlansSlice";


export const useListSubscriptionPlans = (
    { vfExists=false }
) => {
    const dispatch = useDispatch();

    // Authentication
    const {userIsAuthenticated} = useAuthentication();

    // Payment methods
    const {
        subscriptionPlansListingVF,
        subscriptionPlansListingApiState,
        subscriptionPlansListingResponse,
        subscriptionPlansListingError,
    } = useSelector(state => state.listSubscriptionPlansReducer);

    // API states with visual feedback
    const subscriptionPlansListingSuccessVF = subscriptionPlansListingApiState === 'succeeded' && subscriptionPlansListingVF;

    const listSubscriptionPlans = () => userIsAuthenticated && dispatch(subscriptionPlansLIST());

    useEffect(() => {
        if (vfExists) {
            if (subscriptionPlansListingSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearSubscriptionPlansListingVF());
                }, 500);
                return () => clearTimeout(timer);
            }
        }
    }, [subscriptionPlansListingApiState, subscriptionPlansListingVF]);

    return {
        listSubscriptionPlans,
        subscriptionPlansListingVF,
        subscriptionPlansListingApiState,
        subscriptionPlansListingResponse,
        subscriptionPlansListingError,
        subscriptionPlansListingSuccessVF
    };
};
