import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const calendarEventCREATE = createAsyncThunk(
    'memoMinder/calendar/event/create',
    async ({ values }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_MEMOMINDER_GET_CALENDAR_EVENT}`,
                    accessCode: access,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    calendarEventCreateVF: false,
    calendarEventCreateApiState: 'idle',
    calendarEventCreateResponse: null,
    calendarEventCreateError: null,
};

export const createCalendarEventSlice = createSlice({
    name: 'calendarEventCreate',
    initialState: INITIAL_STATE,
    reducers: {
        clearCalendarEventCreateVF: (state) => {
            state.calendarEventCreateVF = false;
        },
        resetCalendarEventCreate: (state) => {
            state.calendarEventCreateVF = false;
            state.calendarEventCreateApiState = 'idle';
            state.calendarEventCreateResponse = null;
            state.calendarEventCreateError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(calendarEventCREATE.pending, (state) => {
                state.calendarEventCreateApiState = 'loading';
                state.calendarEventCreateVF = true;
            })
            .addCase(calendarEventCREATE.fulfilled, (state, action) => {
                state.calendarEventCreateApiState = 'succeeded';
                state.calendarEventCreateResponse = action?.payload;
                state.calendarEventCreateVF = true;
                state.calendarEventCreateError = null;
            })
            .addCase(calendarEventCREATE.rejected, (state, action) => {
                state.calendarEventCreateApiState = 'failed';
                state.calendarEventCreateError = action?.payload;
                state.calendarEventCreateVF = true;
                state.calendarEventCreateResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearCalendarEventCreateVF,
    resetCalendarEventCreate,
} = createCalendarEventSlice.actions;

export default createCalendarEventSlice.reducer;
