import React, {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {RiInformationFill} from "react-icons/ri";
import {BsFillFileEarmarkMedicalFill} from "react-icons/bs";


const FAQ = () => {

    return (
        <Menu as="div" className="relative">
            <Menu.Button
                data-tip="TaskGlide Menu"
                className="lg:fixed bottom-4 right-4 group/faqs cursor-pointer z-40 w-6 h-6 lg:flex hover:sm:shadow-md hidden button-ring-focus focus-visible:rounded-full relative items-center justify-center bg-base-300 text-white ring-base-300 ring-[0.5px] rounded-full px-2 py-1 shadow-sm hover:drop-shadow shadow-gray-400 dark:shadow-black/50 outline-none text-sm min-w-max button-ring-focus"
            >
                <span className="sr-only">Open FAQ menu</span>
                <p className="text-center text-sm" >?</p>
                <div className="left-tooltips right-9 lg:group-hover/faqs:sm:flex hidden min-w-fit whitespace-nowrap group-hover/collapse-followups:group-disabled:hidden ring-0">FAQ</div>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out"
                enterFrom="transform opacity-0 translate-y-2"
                enterTo="transform opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="transform opacity-100 translate-y-0"
                leaveTo="transform opacity-0 translate-y-2"
            >
                <Menu.Items className="hidden lg:flex flex-col absolute right-4 bottom-12 w-36 origin-top-right rounded-md bg-base-100 shadow-[0px_0px_15px_rgba(0,0,0,0.25)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.75)] ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 focus:outline-none divide-y divide-transparent z-50 py-1 space-y-0.5 cursor-default">
                    <div className="flex flex-row justify-between items-center text-xs px-4 py-2 hover:sm:bg-base-200 w-full" >
                        <RiInformationFill className="h-4 w-4" aria-hidden="true"/>
                        <p>FAQ & Help</p>
                    </div>
                    <div className="flex flex-row justify-between items-center text-xs px-4 py-2 hover:sm:bg-base-200 w-full" >
                        <BsFillFileEarmarkMedicalFill className="h-4 w-4" aria-hidden="true"/>
                        <p>Release Notes</p>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
};

export default FAQ;

