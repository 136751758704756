const projectState = process.env.REACT_APP_PROJECT_STATE;

const config = {
    stripePublishableKey: projectState === 'development' ? process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_KEY : process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY,
    taskGlidePriceId: projectState === 'development' ? process.env.REACT_APP_TASKGLIDE_DEV_PRICE_ID : process.env.REACT_APP_TASKGLIDE_PROD_PRICE_ID,
    backendUrl: projectState === 'development' ? process.env.REACT_APP_DEV_BACKEND_URL : process.env.REACT_APP_PROD_BACKEND_URL,
    googleOAuth2RedirectUrl: projectState === 'development' ? process.env.REACT_APP_GOOGLE_OAUTH2_DEV_REDIRECT_URI : process.env.REACT_APP_GOOGLE_OAUTH2_PROD_REDIRECT_URI,
    frontendUrl: projectState === 'development' ? process.env.DEV_FRONTEND : process.env.PROD_FRONTEND,
};

export default config;
