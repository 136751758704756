import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const subscriptionCREATE = createAsyncThunk(
    'payments/subscription/create',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_SUBSCRIPTION_CREATE_PATH}`,
                    args: values,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    subscriptionCreationVF: false,
    subscriptionCreationApiState: 'idle',
    subscriptionCreationResponse: null,
    subscriptionCreationError: null,
}

export const createSubscriptionSlice = createSlice({
    name: 'subscriptionCreation',
    initialState: INITIAL_STATE,
    reducers: {
        clearSubscriptionCreationVF: (state) => {
            state.subscriptionCreationVF = false;
        },
        resetSubscriptionCreation: (state) => {
            state.subscriptionCreationVF = false;
            state.subscriptionCreationApiState = 'idle';
            state.subscriptionCreationResponse = null;
            state.subscriptionCreationError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(subscriptionCREATE.pending, (state) => {
                state.subscriptionCreationApiState = 'loading';
                state.subscriptionCreationVF = true;
            })
            .addCase(subscriptionCREATE.fulfilled, (state, action) => {
                state.subscriptionCreationApiState = 'succeeded';
                state.subscriptionCreationResponse = action.payload;
                state.subscriptionCreationVF = true;
                state.subscriptionCreationError = null;
            })
            .addCase(subscriptionCREATE.rejected, (state, action) => {
                state.subscriptionCreationApiState = 'failed';
                state.subscriptionCreationError = action?.payload;
                state.subscriptionCreationVF = true;
                state.subscriptionCreationResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearSubscriptionCreationVF,
    resetSubscriptionCreation
} = createSubscriptionSlice.actions;

export default createSubscriptionSlice.reducer;
