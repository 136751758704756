import React, {useEffect, useState} from "react";
import {BsFileEarmarkCode, BsFileEarmarkImage, BsFileEarmarkRuledFill} from "react-icons/bs";
import PropTypes from "prop-types";
import {useDropzone} from "react-dropzone";
import {useDispatch} from "react-redux";
import clsx from "clsx";

import {incrementAlertKey, setAlert} from "../../../../../../../api/features/frontend/alertSlice";
import {useAlert} from "../../../../../../../Hooks/frontendHooks/useAlert";
import Alerts from "../../../../../../../Components/Alerts/Alerts";



const DragAndDrop = (
    { handleFileSelect, handlePhotoSelect }
) => {
    const dispatch = useDispatch();
    const [isDragging, setIsDragging] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const { alert, alertKey } = useAlert();

    useEffect(() => {
        const handleDragEnter = (event) => {
            event.preventDefault();
            setIsDragging(true);
        };

        const handleDragLeave = (event) => {
            event.preventDefault();
            setIsDragging(false);
        };

        const handleDragOver = (event) => {
            event.preventDefault();
            setIsDragging(true);
        };

        const handleDrop = (event) => {
            event.preventDefault();
            setIsDragging(false);
        };

        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragleave', handleDragLeave);
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('drop', handleDrop);

        return () => {
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragleave', handleDragLeave);
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('drop', handleDrop);
        };
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            acceptedFiles.forEach(file => {
                if (file.type.startsWith('image/')) {
                    handlePhotoSelect({ target: { files: [file] } });
                } else {
                    handleFileSelect({ target: { files: [file] } });
                }
            });
        },
        noClick: true,
        noKeyboard: true,
        accept: {
            'text/eml': ['.eml'],
            'text/html': ['.html'],
            'application/json': ['.json'],
            'text/markdown': ['.md'],
            'application/vnd.ms-outlook': ['.msg'],
            'text/rst': ['.rst'],
            'text/rtf': ['.rtf'],
            'text/plain': ['.txt'],
            'application/xml': ['.xml'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'text/csv': ['.csv'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/epub+zip': ['.epub'],
            'application/vnd.oasis.opendocument.text': ['.odt'],
            'application/pdf': ['.pdf'],
            'application/vnd.ms-powerpoint': ['.ppt'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'text/tab-separated-values': ['.tsv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'image/*': [],
        },
        onDropRejected: () => {
            setIsRejected(true);
            dispatch(setAlert({
                message: "File type not accepted. Please upload a valid file.",
                type: "error"
            }));
            dispatch(incrementAlertKey());
        },
    });

    useEffect(() => {
        if (isRejected) {
            const timer = setTimeout(() => {
                setIsRejected(false);
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [isRejected]);

    return (
        <>
            <div {...getRootProps()} className={clsx(
                "fixed inset-0 p-4 w-full overflow-hidden",
                isDragging ? "bg-black/50 backdrop-blur-sm z-[99]" : "pointer-events-none z-[-1]"
            )}>
                <input {...getInputProps()} />
                {isDragging && (
                    <div className="flex flex-col items-center justify-center w-full h-full">
                        <div className="relative bottom-20 mx-auto inset-x-0">
                            <BsFileEarmarkRuledFill className="absolute top-0 -left-4 size-14 shrink-0 z-10"/>
                            <BsFileEarmarkImage className="absolute -top-8 left-6 size-16 shrink-0 rotate-45 text-primary-focus dark:text-primary-focus-dark opacity-70"/>
                            <BsFileEarmarkCode className="absolute -top-6 right-0 size-14 shrink-0 -rotate-12 opacity-70"/>
                        </div>
                        <div className="text-center max-w-md w-full mx-auto space-y-2">
                            <p className="text-2xl font-medium">Drop any file</p>
                            <p className="text-lg leading-6">Drop any file such as photos, code, excel sheets, pdf, etc. to add to conversation</p>
                        </div>
                    </div>
                )}
            </div>
            {isRejected && <Alerts message={alert.message} type={alert.type} keyValue={alertKey}/>}
        </>

    );
};

DragAndDrop.propTypes = {
    handleFileSelect: PropTypes.func,
    handlePhotoSelect: PropTypes.func,
}

export default DragAndDrop;
