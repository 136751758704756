import React from "react";
import {SiAdobe, SiArxiv, SiMicrosoft, SiOpenai, SiTailwindcss, SiZapier} from "react-icons/si";
import {GiEgyptianBird} from "react-icons/gi";
import {GrStripe} from "react-icons/gr";

const LogoClouds = () => {

    return (
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
                <div className="space-y-4 mx-auto w-full max-w-2xl lg:mx-0">
                    <h2 className="text-3xl font-bold text-base-content">Leveraging the world’s most innovative and trusted services</h2>
                    <p className="text-lg leading-8 text-base-content/70">
                        By leveraging established technologies and services from renowned companies, we aim to instill trust and assurance in our customers, reaffirming our commitment to deliver the highest quality of performance, security, and user experience.
                    </p>
                </div>
                <div className="justify-self-end mx-auto grid max-w-xl grid-cols-2 justify-items-center w-[18rem] gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none text-base-300 text-5xl">
                    <SiOpenai className={'justify-self-start max-h-12'} />
                    <GiEgyptianBird className={'justify-self-end max-h-12'} />
                    <SiAdobe className={'justify-self-start max-h-12'} />
                    <SiMicrosoft className={'justify-self-end max-h-12'} />
                    <SiArxiv className={'justify-self-start max-h-12'} />
                    <GrStripe className={'justify-self-end max-h-12'} />
                    <SiZapier className={'justify-self-start max-h-12'} />
                    <SiTailwindcss className={'justify-self-end max-h-12'} />
                </div>
            </div>
        </div>
    )
};

export default LogoClouds;
