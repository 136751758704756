import React from "react";


const PreferencesSettingsSkeleton = () => {

    return (
        <main className="bg-base-100 overflow-y-auto h-[calc(100dvh-112px)] scrollbar">
            <div className="">
                <div className="grid grid-cols-1 gap-x-20 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="col-span-full md:col-span-4 space-y-4">
                        <div className="h-4 w-56 skeleton bg-base-300/30 rounded"/>
                        <div className="space-y-3">
                            <div className="skeleton bg-base-300/30 w-full h-3 rounded"/>
                            <div className="skeleton bg-base-300/30 w-2/3 h-3 rounded"/>
                        </div>
                    </div>
                    <div className="relative md:col-start-5 md:col-span-5 lg:col-start-6">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-none sm:grid-cols-4">
                            <fieldset className="col-span-full flex items-center gap-x-8 ">
                                <div className="mt-6 space-y-7 w-full">
                                    <div className="relative flex gap-x-4 w-full">
                                        <div className="p-1 skeleton bg-base-300/30 rounded w-4 h-4"/>
                                        <div className="space-y-3 w-full">
                                            <div className="skeleton bg-base-300/30 rounded h-4 w-32"/>
                                            <div className="skeleton bg-base-300/30 rounded h-4 w-2/3"/>
                                        </div>
                                    </div>
                                    <div className="relative flex gap-x-4 w-full">
                                        <div className="p-1 skeleton bg-base-300/30 rounded w-4 h-4"/>
                                        <div className="space-y-3 w-full">
                                            <div className="skeleton bg-base-300/30 rounded h-4 w-32"/>
                                            <div className="skeleton bg-base-300/30 rounded h-4 w-2/3"/>
                                        </div>
                                    </div>
                                    <div className="relative flex gap-x-4 w-full">
                                        <div className="p-1 skeleton bg-base-300/30 rounded w-4 h-4"/>
                                        <div className="space-y-3 w-full">
                                            <div className="skeleton bg-base-300/30 rounded h-4 w-32"/>
                                            <div className="skeleton bg-base-300/30 rounded h-4 w-2/3"/>
                                        </div>
                                    </div>

                                    <div className="pt-4 group relative col-span-full grid grid-cols-7 items-center">
                                        <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                        <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <div className="h-8 w-12 skeleton bg-base-300/30 rounded-md"/>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
};

export default PreferencesSettingsSkeleton;
