import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {calendarDatesLIST, clearCalendarDatesListingVF, resetCalendarDatesListing} from "../../../../../api/features/superIntelligence/memoMinder/calendar/listCalendarDatesSlice";


export const useListCalendarDates = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();
    const {
        calendarDatesListingVF,
        calendarDatesListingApiState,
        calendarDatesListingResponse,
        calendarDatesListingError,
    } = useSelector(state => state.getCalendarReducer);

    const calendarDatesListingSuccessVF = calendarDatesListingApiState === 'succeeded' && calendarDatesListingVF;
    const calendarDatesListingFailureVF = calendarDatesListingApiState === 'failed' && calendarDatesListingVF;

    const listCalendarDates = (startYear, numYears, today=null) => dispatch(calendarDatesLIST({ startYear, numYears, today }));

    useEffect(() => {
        if (vfExists) {
            if (calendarDatesListingSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearCalendarDatesListingVF());
                }, 100);

                return () => clearTimeout(timer);
            } else if (calendarDatesListingFailureVF) {
                dispatch(setAlert({
                    message: calendarDatesListingResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetCalendarDatesListing());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [calendarDatesListingApiState, calendarDatesListingVF]);

    return {
        listCalendarDates,
        calendarDatesListingVF,
        calendarDatesListingApiState,
        calendarDatesListingResponse,
        calendarDatesListingError,
        calendarDatesListingSuccessVF,
        calendarDatesListingFailureVF,
    };
};
