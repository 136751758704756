import React from "react";
import {Link, useLocation} from "react-router-dom";
import clsx from "clsx";

import routes from "../../../Routes/routes";

const billingNav = [
    { name: 'Linked Cards', href: routes.auth.billing.payment.href, current: false },
    { name: 'Invoices', href: routes.auth.billing.invoices.href, current: false },
    { name: 'Subscriptions', href: routes.auth.billing.subscriptions.find(plan => plan?.label === 'Subscriptions').href, current: false },
]

const BillingNav = () => {
    const location = useLocation();
    const path = location.pathname;

    const updatedBillingNav = billingNav.map(item => {
        // Check if the current path starts with the item's href and handle deeper paths
        const isCurrent = path.startsWith(item.href);
        return {
            ...item,
            current: isCurrent,
        };
    });

    return (
        <header className="flex items-center sticky top-14 h-14 z-[2] bg-base-100 border-b border-neutral">
            <nav className="overflow-x-auto hide-scrollbar w-full mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                <ul className="flex min-w-full flex-none space-x-4 py-2 text-sm font-semibold leading-6">
                    {updatedBillingNav.map((item) => (
                        <Link
                            key={item.name}
                            to={item.href}
                            className={clsx(
                                item.current ?
                                    'bg-base-200 text-base-300' :
                                    'hover:bg-base-200',
                                'text-base-content inline-flex items-center rounded-md py-1.5 px-3 text-sm font-medium whitespace-nowrap')}
                        >
                            {item.name}
                        </Link>
                    ))}
                </ul>
            </nav>
        </header>
    )
};

export default BillingNav;

