import {BsFacebook, BsInstagram, BsTwitter} from "react-icons/bs";

const routes = {
    unAuth: {
        // Unauthenticated routes
        home: { label: "Home", href: '/' },
        roadmap: { label: "Roadmap", href: '/roadmap' },
        termsOfUse: { label: "Terms of use", href: '/terms-of-use' },
        privacyPolicy: { label: "Privacy policy", href: '/privacy-policy' },
        usagePolicies: { label: "Usage policies", href: '/usage-policies' },
        contact: { label: "Contact us", href: '/contact-us' },
        about: { label: "About us", href: '/About' },

        // Login, Register, Forgot Password routes
        login: { label: "Sign in" || "Login", href: '/login' },
        register: { label: "Register", href: '/register' },
        forgotPassword: { label: "Forgot Password", href: '/forgot-password' },
        resetPassword: { label: "Reset Password", href: '/reset-password/:token' },
        accountVerification: { label: "Verify Account", href: '/verify-account' },
        successfulAccountVerification: { label: "Successful AccountSettings Verification", href: '/verify-account/successful' },
        invalidAccountVerification: { label: "Invalid AccountSettings Verification", href: '/verify-account/invalid' },
        expiredAccountVerification: { label: "Expired AccountSettings Verification", href: '/verify-account/expired' },

        // Settings, Pricing and Store routes
        products: [
            { name: "Pricing", href: '/products/pricing' },
            { name: "Store", href: '/products/store' },
            { name: "TaskGlide", href: '/products/task-glide', children: [
                { name: "Glides", href: '/products/task-glide/glides' },
            ]},
            { name: "MemoMinder", href: '/products/memo-minder' },
            { name: "Scholar", href: '/products/scholar' },
            { name: "Scholar", href: '/products/scholar' },
            { name: "CodeCraft", href: '/products/code-craft' },
            { name: "ProseMaster", href: '/products/prose-master' },
            { name: "SavantIQ", href: '/products/savant-iq' },
            { name: "FiscalFlow", href: '/products/fiscal-flow' },
            { name: "DataDive", href: '/products/data-dive' },
        ],

        // Solutions routes
        solutions: [
            { name: 'All in one', href: '/solutions' },
            { name: 'Programming', href: '/solutions/programming' },
            { name: 'Research', href: '/solutions/research' },
            { name: 'Documentation', href: '/solutions/documentation' },
            { name: 'Education', href: '/solutions/education'},
        ],

        // Subscriptions.js routes
        subscribe: [
            { name: 'All in one', href: '/subscribe/tier-all-in-one' },
            { name: 'Pay as you go', href: '/subscribe/tier-pay-as-you-go' },
            { name: 'TaskGlide', href: '/subscribe/task-glide' },
            { name: 'MemoMinder', href: '/subscribe/memo-minder' },
            { name: 'SavantIQ', href: '/subscribe/savant-iq' },
            { name: 'FiscalFlow', href: '/subscribe/fiscal-flow' },
            { name: 'DataDive', href: '/subscribe/data-dive' },
            { name: 'ProseMaster', href: '/subscribe/prose-master' },
            { name: 'CodeCraft', href: '/subscribe/code-craft' },
            { name: 'Scholar', href: '/subscribe/scholar' },
        ],

        // Social media links
        socials: [
            { name: "Facebook", href: 'https://www.facebook.com/doviseai', icon: BsFacebook },
            { name: "Instagram", href: 'https://www.instagram.com/doviseai', icon: BsInstagram },
            { name: "Twitter", href: 'https://www.twitter.com/doviseai', icon: BsTwitter },
        ],
    },
    auth: {
        taskGlide: {
            home: { label: "Chat", href: '/task-glide/chat' },
            googleOAuthRedirectHandler: { label: "Google OAuth Redirect", href: '/task-glide/google-oauth' },
            products: [
                { name: 'Scholar', label: "Scholar", href: '/products/scholar' },
                { name: 'CodeCraft', label: "CodeCraft", href: '/products/code-craft' },
                { name: 'ProseMaster', label: "ProseMaster", href: '/products/prose-master' },
                { name: 'SavantIQ', label: "SavantIQ", href: '/products/savant-iq' },
                { name: 'FiscalFlow', label: "FiscalFlow", href: '/products/fiscal-flow' },
                { name: 'DataDive', label: "DataDive", href: '/products/data-dive' },
                { name: 'VitalHealth', label: "VitalHealth", href: '/products/vital-health' },
                { name: 'EcomEnhance', label: "EcomEnhance", href: '/products/ecom-enhance' },
            ],
            myGlides: { label: "My Glides", href: "/task-glide/glides" },
            glideStore: { label: "Glide Store", href: "/task-glide/glides/store" },
            newGlideSetup: { label: "Setup Glide", href: "/task-glide/glides/new/setup" },
            existingGlideSetup: { label: "Setup Glide", href: "/task-glide/glides/:uuid/setup" },
            glideTest: { label: "Test Chat", href: "/task-glide/glides/:uuid/test" },
            settings: { label: "Settings", href: "/task-glide/settings" },
            history: { label: "History", href: "/history" },
        },
        memoMinder: {
            calendar: { label: "Calendar", href: '/memo-minder/calendar' },
            settings: { label: "Settings", href: '/memo-minder/settings' },
        },
        registration: [
            { name: "Registration", label: "Registration", href: "/registration/:step"},
            { name: "Personal Information", label: "Personal Information", href: "/registration/personal-info"},
            { name: "Preferences", label: "Preferences", href: "/registration/preferences"},
            { name: "Subscription", label: "Subscription", href: "/registration/subscription"},
        ],
        store: { label: "Store", href: "/store" },
        scholar: { label: "Scholar", href: "/scholar" },
        codeCraft: { label: "CodeCraft", href: "/code-craft" },
        settings: {
            account: { label: "AccountSettings Settings", href: '/settings/account' },
            preferences: { label: "PreferenceSettings Settings", href: '/settings/preferences' },
            billing: { label: "Billing Settings", href: '/settings/billing' },
            products: [
                { name: 'Scholar', label: "ScholarSettings Settings", href: '/products/scholar/setting' },
                { name: 'CodeCraft', label: "CodeCraft Settings", href: '/products/code-craft/setting' },
                { name: 'ProseMaster', label: "ProseMaster Settings", href: '/products/prose-master/setting' },
                { name: 'SavantIQ', label: "SavantIQ Settings", href: '/products/savant-iq/setting' },
                { name: 'FiscalFlow', label: "FiscalFlow Settings", href: '/products/fiscal-flow/setting' },
                { name: 'DataDive', label: "DataDive Settings", href: '/products/data-dive/setting' },
            ]
        },
        profile: { label: "Profile", href: '/task-glide/profile' },
        billing: {
            payment: { label: "Payment methods", href: '/billing/payment-methods' },
            invoices: { label: "Invoices", href: '/billing/invoices' },
            transaction: { label: "Transaction", href: '/billing/invoices/transaction/:transaction_id' },
            subscriptions: [
                { label: "Subscriptions", href: '/billing/subscriptions' },
                { label: "Subscription Plan", href: '/billing/subscriptions/:product_name' },
            ]
        },
        feedbacks: [
            { label: "Subscription Confirmation", href: "/feedbacks/subscription-confirmation" },
        ],
        support: { label: "Support", href: "/task-glide/support" },
        signOut: { label: "Sign out", href: "/logout" }
    }
};

export default routes;
