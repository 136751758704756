import React from 'react';
import PropTypes from "prop-types";


const TransactionTable = ({ transaction }) => {
    const subtotal = transaction?.discount_amount + transaction?.amount;
    const discountPercent = (transaction?.discount_amount / subtotal) * 100;

    return (
        <table className="w-full whitespace-nowrap text-left text-sm leading-6">
            <thead className="border-b border-neutral w-full">
                <tr className="grid grid-cols-7 gap-x-3">
                    <th className="text-start col-span-3 font-semibold">
                        Description
                    </th>
                    <th className="text-start col-span-2 font-semibold hidden md:block">
                        Transaction ID
                    </th>
                    <th className="col-span-4 md:col-span-2 text-end font-semibold">
                        Amount
                    </th>
                </tr>
            </thead>
            <tbody className="space-y-2" >
                <tr className="grid grid-cols-7 gap-x-3">
                    <td className="py-4 col-span-3" >
                        <p className="">{transaction?.description}</p>
                        <p className="md:hidden text-base-content/70">#{transaction?.id}</p>
                    </td>
                    <td className="py-4 truncate col-span-2 hidden md:block">#{transaction?.id}</td>
                    <td className="py-4 col-span-4 md:col-span-2 text-end">${subtotal?.toFixed(2)}</td>
                </tr>
            </tbody>
            <tfoot className="space-y-2" >
                <tr className="grid grid-cols-7 gap-x-3 mt-4 text-base-content/70">
                    <td className="col-start-3 md:col-start-5 col-span-3 md:col-span-1 text-start">{transaction?.discount_amount ? `${transaction?.discount_code} (${discountPercent}% off)` : "Discount"}</td>
                    <td className="col-span-2 text-end">{transaction?.discount_amount ? '-$' + transaction?.discount_amount?.toFixed(2) : '-'}</td>
                </tr>
                <tr className="grid grid-cols-7 gap-x-3">
                    <th className="col-start-3 md:col-start-5 col-span-3 md:col-span-1 text-start font-semibold">Total {transaction?.status === 'refunded' ? 'refunded' : 'paid'}</th>
                    <td className="col-span-2 text-end">${transaction?.amount?.toFixed(2)}</td>
                </tr>
            </tfoot>
        </table>
    )
};


TransactionTable.propTypes = {
    transaction: PropTypes.object,
};

export default TransactionTable;
