import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";
import {clearTransactionReceiptRetrievalVF, transactionReceiptGET} from "../../../api/features/payments/getTransactionReceiptSlice";



export const useGetTransactionReceipt = (
    { vfExists=false }
) => {
    const dispatch = useDispatch();
    const {
        transactionReceiptRetrievalVF,
        transactionReceiptRetrievalApiState,
        transactionReceiptRetrievalResponse,
        transactionReceiptRetrievalError,
    } = useSelector(state => state.getTransactionReceiptReducer);

    const transactionReceiptRetrievalSuccessVF = transactionReceiptRetrievalApiState === 'succeeded' && transactionReceiptRetrievalVF;

    const getTransactionReceipt = (transaction_id) => dispatch(transactionReceiptGET(transaction_id));

    useEffect(() => {
        if (vfExists) {
            if (transactionReceiptRetrievalSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearTransactionReceiptRetrievalVF());
                }, 500);
                return () => clearTimeout(timer);
            }
        }
    }, [transactionReceiptRetrievalApiState]);

    return {
        getTransactionReceipt,
        transactionReceiptRetrievalVF,
        transactionReceiptRetrievalApiState,
        transactionReceiptRetrievalResponse,
        transactionReceiptRetrievalError,
        transactionReceiptRetrievalSuccessVF
    };
};
