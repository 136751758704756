import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";
import {IoTrash} from "react-icons/io5";
import clsx from "clsx";

import {useDeleteAllUserGlides} from "../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useDeleteAllUserGlides";


const AllMyGlidesDeletionModal = ({ onDelete, disabledState }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        userDefinedGlidesDeleteAllVF,
        userDefinedGlideCreateSuccessVF,
        userDefinedGlideCreateFailureVF,
        userDefinedGlidesDeleteAllApiState
    } = useDeleteAllUserGlides({ vfExists: true, autoUpdate: true });

    useEffect(() => {
        const dialogElement = document.getElementById('allMyGlidesDeletion');

        if (userDefinedGlideCreateSuccessVF || userDefinedGlideCreateFailureVF) {
            dialogElement?.close();
            setIsModalOpen(false);
        }
    }, [userDefinedGlidesDeleteAllApiState, userDefinedGlidesDeleteAllVF]);

    return (
        <>
            <button
                type="button"
                disabled={disabledState}
                className="error-button"
                onClick={(e) => {
                    if (!disabledState) {
                        e.preventDefault();
                        window.allMyGlidesDeletion.showModal();
                        setIsModalOpen(true);
                    }
                }}
            >
                Delete All
            </button>
            <dialog
                id="allMyGlidesDeletion"
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={() => setIsModalOpen(false)}
            >
                <form
                    method="dialog"
                    className="modal-wrapper"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-error-content sm:mx-0 sm:h-10 sm:w-10">
                            <IoTrash className="h-6 w-6 text-error" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mx-4 sm:mt-2 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-base-content">
                                Delete All Glides
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-base-content/70">
                                    Are you sure you want to delete all Glides that you have created? This action cannot be undone.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-end gap-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                const dialogElement = document.getElementById('allMyGlidesDeletion');
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                            className="standard-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            disabled={disabledState}
                            className="error-button"
                            onClick={onDelete}
                        >
                            {disabledState ? <CgSpinner className="animate-spin h-5 w-5 inline-block"/> : "Delete"}
                        </button>
                    </div>
                </form>
                <form method="dialog" className="modal-backdrop">
                    <button
                        tabIndex={-1}
                        className="cursor-default focus-visible:outline-0 focus-visible:ring-0"
                    >
                        close
                    </button>
                </form>
            </dialog>
        </>
    )
};

AllMyGlidesDeletionModal.propTypes = {
    onDelete: PropTypes.func,
    disabledState: PropTypes.bool,
}

export default AllMyGlidesDeletionModal;
