import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";

import FAQ from "../FAQ/FAQ";
import DragAndDrop from "./ChatBox/Chat/DragAndDrop";
import {useTaskGlideFilePhotoHandler} from "./InputField/Fields/Hooks/useTaskGlideFilePhotoHandler";
import ConversationHistoryButton from "../ConversationHistory/ConversationHistoryButton";
import {useAlert} from "../../../../../Hooks/frontendHooks/useAlert";
import {useCreatePaymentMethod} from "../../../../../Hooks/apiHooks/payments/useCreatePaymentMethod";
import {useCreateSubscription} from "../../../../../Hooks/apiHooks/payments/useCreateSubscription";
import {useTogglePinMessage} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useTogglePinMessage";
import {useGetCurrentTgConversation} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetCurrentTgConversation";
import {useStreamTaskGlide} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";
import {useDeleteSingleTgConversation} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteSingleTgConversation";
import {useListAllTgConversations} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useListAllTgConversations";
import {useGetPinnedMessage} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetPinnedMessage";
import {useListUserGlides} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useListUserGlides";
import {useSessionInfo} from "../../../../../Hooks/frontendHooks/useSessionInfo";
import {useAutoScroll} from "../../../../../Hooks/frontendHooks/useAutoScroll";
import {useGetSingleTgConversation} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetSingleTgConversation";
import {useTaskGlideFormActions} from "./InputField/Fields/Hooks/useTaskGlideFormActions";
import {setCurrentTgConversation} from "../../../../../api/features/superIntelligence/taskGlide/frontend/getCurrentTgConversationSlice";
import TaskGlideChatBox from "./ChatBox/Chat/TaskGlideChatBox";
import TaskGlideInputAdditionalButtons from "./InputField/Fields/TaskGlideInputAdditionalButtons";
import GenericChatInputField from "../../../../../Components/TaskGlide/InputField/GenericChatInputField";
import TaskGlideAddOnTab from "./InputField/AddOns/TaskGlideAddOnTab";
import ConversationHistory from "./InputField/Actions/ConversationHistory";
import Alerts from "../../../../../Components/Alerts/Alerts";
import Nav from "../../../Shell/Nav/Nav";


const TaskGlideChat = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const conversationUUID = searchParams.get('conversation_uuid');
    const { alert, alertKey } = useAlert();

    useCreatePaymentMethod({ vfExists: false, refreshAndReset: true });
    useCreateSubscription({ vfExists: true, refreshAndReset: true });
    useTogglePinMessage({ vfExists: true, autoUpdate: true, conversationUUID })
    const { currentTgConversation } = useGetCurrentTgConversation();
    const { taskGlideStreamVF } = useStreamTaskGlide({ isTest: false, vfExists: true });
    const { deleteSingleTGConversation, tgConversationDeleteFailureVF } = useDeleteSingleTgConversation({ vfExists: true, autoUpdate: true });
    const { tgConversationRetrievalFailureVF, getSingleTGConversation, tgSingleConversationRetrievalVF } = useGetSingleTgConversation({ vfExists: true, autoSelectPostFetch: true });
    const { tgConversationsListingResponse } = useListAllTgConversations();
    const { getPinnedMessage } = useGetPinnedMessage({ vfExists: true });
    const { glidesListingResponse, glidesListingVF } = useListUserGlides({ vfExists: true });

    const sessionInfo = useSessionInfo();
    const {
        pageEndRef,
        isAutoScrollEnabled,
        setIsAutoScrollEnabled
    } = useAutoScroll();

    const [followUpsAreCollapsed, setFollowUpsAreCollapsed] = useState(false);
    const [selectedGlides, setSelectedGlides] = useState([]);

    const chatBoxRef = useRef(null);
    const [pageSize, setPageSize] = useState(5);

    useEffect(() => {
        dispatch(setCurrentTgConversation(null))
    }, []);

    useEffect(() => {
        const default_agent = glidesListingResponse?.find(item => item?.is_default);
        if (default_agent) {
            setSelectedGlides([default_agent?.glide]);
        }
    }, [glidesListingVF, glidesListingResponse]);

    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [parsedDocuments, setParsedDocuments] = useState([]);
    const { formik, handleAbort } = useTaskGlideFormActions({
        sessionInfo,
        selectedGlides,
        isTest: false,
        selectedPhotos,
        setSelectedPhotos,
        selectedFiles,
        setSelectedFiles,
        setIsAutoScrollEnabled,
        parsedDocuments,
        setParsedDocuments
    });

    useEffect(() => {
        if (conversationUUID) {
            setPageSize(5);
            getSingleTGConversation(conversationUUID, 5);
            getPinnedMessage(conversationUUID)
        }
    }, [conversationUUID]);

    useEffect(() => {
        if (!tgSingleConversationRetrievalVF && !!conversationUUID && pageSize <= 5) {
            setIsAutoScrollEnabled(true);
        }
    }, [conversationUUID, tgSingleConversationRetrievalVF, pageSize]);

    const handleInteractionPagination = () => {
        if (!!currentTgConversation?.interactions?.next && !tgSingleConversationRetrievalVF) {
            const newPageSize = pageSize + 5;
            setPageSize(newPageSize);
            if (conversationUUID) {
                getSingleTGConversation(conversationUUID, newPageSize);
            }
        }
    };

    const { handleFileSelect, handlePhotoSelect, handleFileRemove, handlePhotoRemove } = useTaskGlideFilePhotoHandler({
        setSelectedFiles,
        setParsedDocuments,
        selectedPhotos,
        setSelectedPhotos
    });

    return (
        <>
            <Nav />
            <DragAndDrop handleFileSelect={handleFileSelect} handlePhotoSelect={handlePhotoSelect} />
            <div className="relative h-[calc(100dvh-56px)] overflow-hidden grid grid-cols-9 max-h-[calc(100dvh-56px)]">
                <div className="col-span-full flex flex-col w-full bg-transparent z-20 max-h-[calc(100dvh-56px)]">
                    <div ref={chatBoxRef} className="overflow-y-auto overflow-x-hidden scroll-smooth scrollbar h-full">
                        <div className="px-0 lg:px-6 mx-auto max-w-5xl h-full">
                            <TaskGlideChatBox
                                formik={formik}
                                followUpsAreCollapsed={followUpsAreCollapsed}
                                selectedGlides={selectedGlides}
                                setSelectedGlides={setSelectedGlides}
                                sessionInfo={sessionInfo}
                                handleInteractionPagination={handleInteractionPagination}
                                chatBoxRef={chatBoxRef}
                            />
                            <div ref={pageEndRef}/>
                        </div>
                        <ConversationHistoryButton/>
                    </div>
                    <div className="sticky bottom-0 z-20 mx-auto max-w-5xl w-full">
                        <TaskGlideAddOnTab
                            formik={formik}
                            isAutoScrollEnabled={isAutoScrollEnabled}
                            setIsAutoScrollEnabled={setIsAutoScrollEnabled}
                            followUpsAreCollapsed={followUpsAreCollapsed}
                            setFollowUpsAreCollapsed={setFollowUpsAreCollapsed}
                        />
                    </div>
                    <div className="pt-px px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl w-full">
                        {tgConversationsListingResponse ?
                            <GenericChatInputField
                                formik={formik}
                                handleAbort={handleAbort}
                                disabled={taskGlideStreamVF || glidesListingVF || !selectedGlides}
                                loadingState={taskGlideStreamVF}
                                selectedGlides={selectedGlides}
                                additionalMenuButtons={() => <TaskGlideInputAdditionalButtons/>}
                                selectedPhotos={selectedPhotos}
                                setSelectedPhotos={setSelectedPhotos}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                setParsedDocuments={setParsedDocuments}
                                handleFileSelect={handleFileSelect}
                                handlePhotoSelect={handlePhotoSelect}
                                handleFileRemove={handleFileRemove}
                                handlePhotoRemove={handlePhotoRemove}
                            /> :
                            <div>
                                <div className="rounded-xl w-full flex items-center justify-between bg-base-200 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 px-5 py-3.5">
                                    <div className="h-6 w-6 rounded-full bg-base-300/30 skeleton"/>
                                    <div className="h-6 w-6 rounded-full bg-base-300/30 skeleton"/>
                                </div>
                                <div className="py-3">
                                    <div className="h-3 bg-base-300/30 rounded skeleton mx-auto w-2/3"/>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
            <ConversationHistory onConversationDelete={deleteSingleTGConversation}/>
            <FAQ/>
            {(tgConversationDeleteFailureVF || tgConversationRetrievalFailureVF) &&
            <Alerts message={alert?.message} type={alert?.type} keyValue={alertKey}/>}
        </>
    )
};

export default TaskGlideChat;
