import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {useAuthentication} from "../users/useAuthentication";
import {clearSubscriptionCreationVF, resetSubscriptionCreation, subscriptionCREATE} from "../../../api/features/payments/createSubscriptionSlice";
import {useListUserSubscriptions} from "./useListUserSubscriptions";
import {useListTransactions} from "./useListTransactions";


export const useCreateSubscription = (
    { vfExists=false, refreshAndReset=false } = {}
) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();
    const {
        subscriptionCreationVF,
        subscriptionCreationApiState,
        subscriptionCreationResponse,
        subscriptionCreationError,
    } = useSelector(state => state.createSubscriptionReducer);
    const { listUserSubscriptions } = useListUserSubscriptions({ vfExists: true });
    const { listTransactions } = useListTransactions({ vfExists: true });

    const subscriptionCreationSuccessVF = subscriptionCreationApiState === 'succeeded' && subscriptionCreationVF;
    const subscriptionCreationFailureVF = subscriptionCreationApiState === 'failed' && subscriptionCreationVF;

    const createSubscription = (values) => userIsAuthenticated && dispatch(subscriptionCREATE(values));

    useEffect(() => {
        if (vfExists) {
            if (subscriptionCreationSuccessVF) {
                dispatch(setAlert({
                    message: subscriptionCreationResponse?.message,
                    type: 'success'
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearSubscriptionCreationVF());
                }, 4000);

                return () => clearTimeout(timer);
            } else if (subscriptionCreationFailureVF) {
                dispatch(setAlert({
                    message: subscriptionCreationError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetSubscriptionCreation());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [subscriptionCreationApiState]);

    useEffect(() => {
        if (refreshAndReset && subscriptionCreationApiState === 'succeeded') {
            listUserSubscriptions();
            listTransactions();
            dispatch(resetSubscriptionCreation());
        }
    }, [subscriptionCreationApiState]);

    return {
        createSubscription,
        subscriptionCreationVF,
        subscriptionCreationSuccessVF,
        subscriptionCreationFailureVF,
        subscriptionCreationApiState,
        subscriptionCreationResponse
    };
};

