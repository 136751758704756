import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const userAccountDELETE = createAsyncThunk(
    'users/user-account/delete',
    async (_, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'DELETE',
                    path: process.env.REACT_APP_POST_DELETE_USER_ACCOUNT_PATH,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userAccountDeletionVF: false,
    userAccountDeletionApiState: 'idle',
    userAccountDeletionResponse: null,
    userAccountDeletionError: null,
};

export const deleteUserAccountSlice = createSlice({
        name: 'userAccountDeletion',
        initialState: INITIAL_STATE,
        reducers: {
            clearUserAccountDeletionVF: (state) => {
                state.userAccountDeletionVF = false;
            },
            resetUserAccountDeletion: (state) => {
                state.userAccountDeletionVF = false;
                state.userAccountDeletionApiState = 'idle';
                state.userAccountDeletionResponse = null;
                state.userAccountDeletionError = null;
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(userAccountDELETE.pending, (state) => {
                    state.userAccountDeletionApiState = 'loading';
                    state.userAccountDeletionVF = true;
                })
                .addCase(userAccountDELETE.fulfilled, (state, action) => {
                    state.userAccountDeletionApiState = 'succeeded';
                    state.userAccountDeletionResponse = action.payload;
                    state.userAccountDeletionVF = true;
                    state.userAccountDeletionError = null;
                })
                .addCase(userAccountDELETE.rejected, (state, action) => {
                    state.userAccountDeletionApiState = 'failed';
                    state.userAccountDeletionError = action?.payload;
                    state.userAccountDeletionVF = true;
                    state.userAccountDeletionResponse = null;
                })
                .addDefaultCase((state, action) => {
                    if (action.type === 'RESET') {
                        return INITIAL_STATE;
                    }
                });
        }
    }
);

export const {
    clearUserAccountDeletionVF,
    resetUserAccountDeletion
} = deleteUserAccountSlice.actions;

export default deleteUserAccountSlice.reducer;
