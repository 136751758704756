import React from "react";
import { jellyTriangle } from 'ldrs'
import PropTypes from "prop-types";
jellyTriangle.register()


const DoviseLoader = ({ size, speed, lightColor, darkColor }) => {
    return (
        <>
            <div className="-rotate-[30deg] animate-pulse w-fit block dark:hidden">
                <l-jelly-triangle
                    size={size}
                    speed={speed}
                    color={lightColor}
                ></l-jelly-triangle>
            </div>
            <div className="-rotate-[30deg] animate-pulse w-fit hidden dark:block">
                <l-jelly-triangle
                    size={size}
                    speed={speed}
                    color={darkColor}
                ></l-jelly-triangle>
            </div>
        </>
    )
};

DoviseLoader.propTypes = {
    size: PropTypes.string,
    speed: PropTypes.string,
    darkColor: PropTypes.string,
    lightColor: PropTypes.string,
};

export default DoviseLoader;