import React from "react";
import {CheckIcon, MinusSmallIcon, PlusIcon, PlusSmallIcon} from "@heroicons/react/20/solid";
import {Disclosure} from "@headlessui/react";
import {AnimatePresence, motion} from "framer-motion";
import {Link} from "react-router-dom";

import routes from "../../../Routes/routes";


const faqs = [
    {
        id: 1,
        question: "Which plan is right for me?",
        answer: "Choose based on your needs: Product-Specific for one product, All-in-One for full access, Pay-as-You-Go for maximum flexibility.",
    },
    {
        id: 2,
        question: 'How do I add new products?',
        answer: 'You can manage your products in the subscriptions settings of your account.'
    },
    {
        id: 3,
        question: "Can I switch plans?",
        answer: "Yes, you can switch between plans anytime. Changes will take effect from the next billing cycle."
    },
    {
        id: 4,
        question: "How do annual subscriptions save money?",
        answer: "Annual subscriptions save you the equivalent of 2 months' fees compared to monthly billing."
    },
    {
        id: 5,
        question: "Can I cancel my subscription?",
        answer: "Yes, you can cancel your subscription anytime. Changes will take effect from the next billing cycle."
    },
    {
        id: 6,
        question: "Will pricing plans stay the same?",
        answer: "We may change our pricing plans from time to time. We will notify you of any changes in advance."
    }
];

const checklist = [
    'Unlimited usage',
    'Cancel any time',
    'Pay for products you use',
    'Start and add more',
    'Stay with innovation',
    'Continuous updates',
];


const Pricing = () => {

    return (
        <div className="bg-gradient-to-b from-primary/10 to-base-100" >
            {/* Pricing section */}
            <div className="isolate py-14">
                <div className="flow-root pt-16 sm:pt-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-4xl text-center">
                            <h2 className="text-base font-medium leading-7 text-primary-focus dark:text-primary-focus-dark">Pricing</h2>
                            <p className="mt-4 text-4xl font-bold tracking-tight text-base-content sm:text-5xl">
                                Simple Pricing for Every User
                            </p>
                        </div>
                        <p className="mx-auto mt-6 max-w-2xl text-center text-[min(5vw,18px)] text-base-content/70">
                            Discover our diverse pricing options designed to match your unique needs and budget. Choose the right plan for you from our selection below.
                        </p>
                    </div>
                </div>
            </div>

            {/* Pricing tiers */}
            <div className="relative w-full">
                <div className="px-6 py-16 sm:py-20 flex flex-col items-center justify-center mx-auto max-w-7xl w-full lg:px-8 space-y-10 border-t border-b border-neutral-focus/70 dark:border-neutral-focus-dark/70">
                    <p className="text-2xl font-bold text-base-content sm:text-3xl sm:tracking-tight">
                        Everything you need in one plan.
                    </p>
                    <div className="mx-auto w-full max-w-lg space-y-8 lg:mx-0">
                        <div className="flex sm:flex-col gap-x-4 gap-y-6 mx-auto w-fit" >
                            <div className="relative grid grid-rows-3 grid-cols-1 sm:grid-cols-3 sm:grid-rows-1">
                                <span className="flex flex-col text-center space-y-2 ">
                                    <span className="text-primary-focus dark:text-primary-focus-dark font-medium">TaskGlide</span>
                                    <span className="text-5xl font-bold tracking-tight text-base-content">$25</span>
                                    <span className="text-sm text-base-content/70">Per month</span>
                                    <span className="sr-only">plus</span>
                                </span>
                                <span className="flex w-full items-center justify-center" aria-hidden="true">
                                    <PlusIcon className="h-6 w-6 text-primary-focus dark:text-primary-focus-dark" aria-hidden="true"/>
                                </span>
                                <span className="flex flex-col text-center space-y-2">
                                    <span className="text-primary-focus dark:text-primary-focus-dark font-medium">Each new product</span>
                                    <span className="text-5xl font-bold tracking-tight text-base-content">$5</span>
                                    <span className="text-sm text-base-content/70">Per month</span>
                                </span>
                            </div>
                            <ul className="overflow-hidden grid grid-cols-1 mx-auto sm:grid-cols-2 grid-flow-row gap-y-px gap-x-4 text-sm">
                                {checklist.map((item) => (
                                    <li key={item} className="flex text-base text-base-content px-3 py-2 space-x-2 ">
                                        <CheckIcon className="size-6 text-base-content shrink-0" aria-hidden="true"/>
                                        <span className="whitespace-nowrap">{item}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <Link
                            to={routes.unAuth.register.href}
                            className="primary-button w-full mx-auto"
                        >
                            Get started today
                        </Link>
                    </div>
                </div>
            </div>

            {/* FAQ section */}
            <div className="mx-auto py-20 max-w-7xl w-full px-6 sm:py-24 lg:px-8">
                <div className="mx-auto divide-y divide-neutral-focus/50 dark:divide-neutral-focus-dark/50">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-base-content">Frequently asked questions</h2>
                    <dl className="mt-10 divide-y divide-neutral-focus/50 dark:divide-neutral-focus-dark/50">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="py-4 overflow-hidden">
                                {({open}) => (
                                    <>
                                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-base-content">
                                            <span className="text-base font-semibold leading-7">{faq.question}</span>
                                            <span className="ml-6 flex h-7 items-center">
                                            {open ?
                                                <MinusSmallIcon className="size-6" aria-hidden="true"/> :
                                                <PlusSmallIcon className="size-6" aria-hidden="true"/>}
                                          </span>
                                        </Disclosure.Button>
                                        <AnimatePresence initial={false}>
                                            {open && (
                                                <motion.dd
                                                    initial={{opacity: 0, scale: 0.95, height: 0}}
                                                    animate={{opacity: 1, scale: 1, height: 'auto'}}
                                                    exit={{opacity: 0, scale: 0.95, height: 0}}
                                                    transition={{duration: 0.3}}
                                                >
                                                    <p className="text-base leading-7 text-base-content/70 pt-2">{faq.answer}</p>
                                                </motion.dd>
                                            )}
                                        </AnimatePresence>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
};

export default Pricing;
