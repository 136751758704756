import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import Shell from "../../Pages/Auth/Shell/Shell";
import routes from "../../Routes/routes";
import {useGetRegistrationStatus} from "../../Hooks/apiHooks/users/useGetRegistrationStatus";
import {useGetUserInfo} from "../../Hooks/apiHooks/users/useGetUserInfo";
import {useUpdateUserPreferences} from "../../Hooks/apiHooks/users/useUpdateUserPreferences";
import {useUpdateUserInfo} from "../../Hooks/apiHooks/users/useUpdateUserInfo";
import {useAuthentication} from "../../Hooks/apiHooks/users/useAuthentication";
import {useCreatePaymentMethod} from "../../Hooks/apiHooks/payments/useCreatePaymentMethod";
import {useCreateSubscription} from "../../Hooks/apiHooks/payments/useCreateSubscription";
import DoviseLoader from "../../Components/DoviseLoader/DoviseLoader";


export const AuthWrapper = ({ page: Dash }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {authenticationVF, userIsAuthenticated} = useAuthentication({ vfExists: true });
    const {
        getRegistrationStatus,
        registrationStatusResponse,
        registrationStatusVF
    } = useGetRegistrationStatus({ vfExists: true });
    const { getUserInfo, userInfoRetrievalApiState } = useGetUserInfo({ vfExists: true });
    const { userInfoUpdateApiState } = useUpdateUserInfo();
    const { userPreferencesUpdateApiState } = useUpdateUserPreferences();
    const { paymentCreationApiState } = useCreatePaymentMethod({ vfExists: false, refreshAndReset: false });
    const { subscriptionCreationApiState } = useCreateSubscription({ vfExists: false });

    // TODO: UPDATE THIS TO BE RUN WHEN USER INFO/ USER PREF/ PAYMENT METHODS ARE UPDATED
    useEffect(() => {
        if (!registrationStatusResponse) {
            getRegistrationStatus();
        } else if (!registrationStatusResponse?.fully_registered) {
            console.log("not fully registered")
            if (userInfoUpdateApiState === 'succeeded' || userPreferencesUpdateApiState === 'succeeded' || paymentCreationApiState === 'succeeded' || subscriptionCreationApiState === 'succeeded') {
                console.log("should get registration status")
                getRegistrationStatus();
            }
        }
    }, [userIsAuthenticated, userInfoUpdateApiState, userPreferencesUpdateApiState, paymentCreationApiState, subscriptionCreationApiState]);

    useEffect(() => {
        if (userIsAuthenticated && userInfoRetrievalApiState === 'idle') {
            getUserInfo();
        }
    }, [userInfoRetrievalApiState, userIsAuthenticated]);

    useEffect(() => {
        if (!userIsAuthenticated && !authenticationVF) {
            navigate(routes.unAuth.login.href);
            dispatch({ type: 'RESET' });
        }
    }, [userIsAuthenticated, authenticationVF]);

    if (authenticationVF || registrationStatusVF) {
        return (
            <div className="flex justify-center items-center h-screen w-full max-w-7xl mx-auto">
                <DoviseLoader size="65" speed="1.75" lightColor="#1e293b" darkColor="#e2e8f0" />
            </div>
        );
    }
    return (
        <>
            <Shell>
                <Dash/>
            </Shell>
        </>
    )
};

AuthWrapper.propTypes = {
    page: PropTypes.elementType.isRequired,
};
