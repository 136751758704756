import React from "react";
import PropTypes from "prop-types";
import {AnimatePresence} from "framer-motion";

import {useGetUserInfo} from "../../../../../../../Hooks/apiHooks/users/useGetUserInfo";
import UserMessage from "../../../../../../../Components/TaskGlide/Messages/UserMessage";
import GlideResponse from "../../../../../../../Components/TaskGlide/Messages/GlideResponse";


const TaskGlideMessage = ({ isLastMessage, interaction, onUserMessageDelete, onTaskGlideResponseDelete, chatBoxRef, pinningIsEnabled }) => {
    const { userInfoRetrievalResponse } = useGetUserInfo();
    const { messages } = interaction;

    const userMessageId = `userMessage${interaction?.id || 0}`;
    const taskGlideMessageId = `taskGlideMessage${interaction?.id || 0}`;

    const renderMessage = (message) => {
        switch (message?.type) {
            case 'user_message':
                return <UserMessage
                    key={userMessageId}
                    userInfo={userInfoRetrievalResponse}
                    isLastMessage={isLastMessage}
                    onMessageDelete={onUserMessageDelete}
                    message={message}
                    animationVariants={animationVariants}
                    chatBoxRef={chatBoxRef}
                    pinningIsEnabled={pinningIsEnabled}
                />;
            case 'glide_response':
                return <GlideResponse
                    key={taskGlideMessageId}
                    interaction={interaction}
                    message={message}
                    isLastMessage={isLastMessage}
                    onResponseDelete={onTaskGlideResponseDelete}
                    chatBoxRef={chatBoxRef}
                    pinningIsEnabled={pinningIsEnabled}
                />;
        }
    }

    return (
        <div className="grid grid-cols-1 gap-y-6 group w-full rounded-none px-1 sm:px-4 z-0" >
            <AnimatePresence>
                {messages?.map(message => renderMessage(message))}
            </AnimatePresence>
        </div>
    );
};

const animationVariants = {
    initial: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
    transition: { duration: 0.25 }
};

TaskGlideMessage.propTypes = {
    isLastMessage: PropTypes.bool,
    interaction: PropTypes.object,
    onUserMessageDelete: PropTypes.func,
    onTaskGlideResponseDelete: PropTypes.func,
    chatBoxRef: PropTypes.object,
    pinningIsEnabled: PropTypes.bool,
};

export default TaskGlideMessage;
