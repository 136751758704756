import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const taskGlideSettingsPUT = createAsyncThunk(
    'taskGlide/settings/update',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'PUT',
                    path: `${process.env.REACT_APP_TASKGLIDE_SETTINGS_PATH}`,
                    accessCode: access,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    taskGlideSettingsUpdateVF: false,
    taskGlideSettingsUpdateApiState: 'idle',
    taskGlideSettingsUpdateResponse: null,
    taskGlideSettingsUpdateError: null,
};

export const updateTaskGlideSettingsSlice = createSlice({
    name: 'taskGlideSettingsUpdate',
    initialState: INITIAL_STATE,
    reducers: {
        clearTaskGlideSettingsUpdateVF: (state) => {
            state.taskGlideSettingsUpdateVF = false;
        },
        resetTaskGlideSettingsUpdate: (state) => {
            state.taskGlideSettingsUpdateVF = false;
            state.taskGlideSettingsUpdateApiState = 'idle';
            state.taskGlideSettingsUpdateResponse = null;
            state.taskGlideSettingsUpdateError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(taskGlideSettingsPUT.pending, (state) => {
                state.taskGlideSettingsUpdateApiState = 'loading';
                state.taskGlideSettingsUpdateVF = true;
            })
            .addCase(taskGlideSettingsPUT.fulfilled, (state, action) => {
                state.taskGlideSettingsUpdateApiState = 'succeeded';
                state.taskGlideSettingsUpdateResponse = action?.payload;
                state.taskGlideSettingsUpdateVF = true;
                state.taskGlideSettingsUpdateError = null;
            })
            .addCase(taskGlideSettingsPUT.rejected, (state, action) => {
                state.taskGlideSettingsUpdateApiState = 'failed';
                state.taskGlideSettingsUpdateError = action?.payload;
                state.taskGlideSettingsUpdateVF = true;
                state.taskGlideSettingsUpdateResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTaskGlideSettingsUpdateVF, resetTaskGlideSettingsUpdate
} = updateTaskGlideSettingsSlice.actions;

export default updateTaskGlideSettingsSlice.reducer;
