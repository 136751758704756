import React from 'react';


const TransactionSkeleton = () => {

    return (
        <div className="p-4 sm:p-16 shadow-sm ring-1 ring-neutral sm:mx-0 rounded-md grid grid-cols-2 xl:grid-cols-4 gap-8">
            <div className="col-span-1 xl:col-span-2 space-y-8">
                <div className="flex items-center space-x-3">
                    <div className="h-4 w-16 bg-base-300/30 skeleton rounded" />
                    <p className="h-4 w-48 bg-base-300/30 skeleton rounded" />
                </div>
                <div className="xl:flex xl:justify-between space-y-10 xl:space-x-6 xl:space-y-0 text-base-content">
                    <div className="space-y-3 w-full">
                        <div className="h-4 w-16 bg-base-300/30 skeleton rounded"/>
                        <dd className="space-y-3 w-full">
                            <div className="h-4 w-24 bg-base-300/30 skeleton rounded"/>
                            <div className="font-light space-y-3">
                                <div className="h-4 w-28 bg-base-300/30 skeleton rounded"/>
                                <div className="h-4 w-32 bg-base-300/30 skeleton rounded"/>
                                <div className="h-4 w-48 bg-base-300/30 skeleton rounded"/>
                                <div className="h-4 w-48 bg-base-300/30 skeleton rounded"/>
                            </div>
                        </dd>
                    </div>
                    <div className="space-y-3 w-full xl:pl-6">
                        <div className="h-4 w-16 bg-base-300/30 skeleton rounded"/>
                        <dd className="space-y-3 w-full">
                            <div className="h-4 w-24 bg-base-300/30 skeleton rounded"/>
                            <div className="font-light space-y-3">
                                <div className="h-4 w-32 bg-base-300/30 skeleton rounded"/>
                                <div className="h-4 w-32 bg-base-300/30 skeleton rounded"/>
                            </div>
                        </dd>
                    </div>
                </div>
            </div>
            <div className="col-span-full md:col-span-1 xl:col-span-2 md:max-w-sm justify-self-end w-full">
                <div className="w-full text-base-content ">
                    <div className="rounded-lg bg-base-300/30 skeleton shadow-sm ring-1 ring-neutral p-5">
                        <dl className="w-full divide-y divide-base-100">
                            <div className="flex justify-between items-center pb-5">
                                <div className="text-sm font-semibold leading-6 space-y-3">
                                    <p className="h-4 w-24 bg-base-100 rounded"/>
                                    <p className="h-4 w-12 bg-base-100 rounded"/>
                                </div>
                                <div className="h-4 w-24 bg-base-100 rounded"/>
                            </div>
                            <div className="space-y-4 py-5">
                                <div className="flex items-center w-full flex-none gap-x-4">
                                    <div className="">
                                        <div className="size-5 bg-base-100 rounded-full" aria-hidden="true"/>
                                    </div>
                                    <div className="h-4 w-24 bg-base-100 rounded"/>
                                </div>
                                <div className="flex items-center w-full flex-none gap-x-4">
                                    <dt className="flex-none">
                                        <div className="h-4 w-6 bg-base-100 rounded" aria-hidden="true"/>
                                    </dt>
                                    <dd className="text-sm leading-6 text-base-content/70">
                                        <div className="h-4 w-24 bg-base-100 skeleton rounded"/>
                                    </dd>
                                </div>
                                <div className="flex items-center w-full flex-none gap-x-4">
                                    <div className="h-4 w-6 bg-base-100 rounded" aria-hidden="true"/>
                                    <div className="h-4 w-24 bg-base-100 skeleton rounded"/>
                                </div>
                            </div>
                            <div className="pt-5">
                                <div className="h-4 w-32 bg-base-100 rounded"/>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className="col-span-full">
                <table className="w-full whitespace-nowrap text-left text-sm leading-6">
                    <thead className="border-b border-neutral w-full">
                        <tr className="grid grid-cols-7 gap-x-3">
                            <th className="text-start col-span-3 py-4">
                                <div className="bg-base-300/30 skeleton rounded h-4 w-24"/>
                            </th>
                            <th className="text-start col-span-2 py-4 hidden md:block">
                                <div className="bg-base-300/30 skeleton rounded h-4 w-24"/>
                            </th>
                            <th className="col-span-4 md:col-span-2 py-4 flex items-center justify-end">
                                <div className="bg-base-300/30 skeleton rounded h-4 w-24"/>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="space-y-2">
                        <tr className="grid grid-cols-7 gap-x-3">
                            <td className="py-4 col-span-3 space-y-2">
                                <div className="bg-base-300/30 skeleton rounded h-4 w-24" />
                                <div className="md:hidden bg-base-300/30 skeleton rounded h-4 w-24" />
                            </td>
                            <td className="py-4 truncate col-span-2 hidden md:block">
                                <div className="bg-base-300/30 skeleton rounded h-4 w-48"/>
                            </td>
                            <td className="col-span-4 md:col-span-2 py-4 flex items-center justify-end">
                                <div className="bg-base-300/30 skeleton rounded h-4 w-24" />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot className="space-y-3">
                    <tr className="grid grid-cols-7 gap-x-3 mt-4 text-base-content/70">
                        <td className="col-start-3 md:col-start-5 col-span-3 md:col-span-1 bg-base-300/30 skeleton rounded h-4 w-24"/>
                        <td className="col-span-2 flex items-center justify-end">
                            <div className="skeleton bg-base-300/30 rounded h-4 w-24"/>
                        </td>
                    </tr>
                    <tr className="grid grid-cols-7 gap-x-3">
                        <th className="col-start-3 md:col-start-5 col-span-3 md:col-span-1">
                        <div className="bg-base-300/30 skeleton rounded h-4 w-24" />
                            </th>
                            <td className="col-span-2 flex items-center justify-end">
                                <div className="skeleton bg-base-300/30 rounded h-4 w-24" />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
};

export default TransactionSkeleton;
