import React from "react";
import {FcGoogle} from "react-icons/fc";

import {useGetTaskGlideSettings} from "../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useGetTaskGlideSettings";
import config from "../../../../../../config";


const GoogleOAuthButton = () => {
    const { taskGlideSettingsRetrievalResponse } = useGetTaskGlideSettings();
    const isAuthed = taskGlideSettingsRetrievalResponse?.access_google_account && taskGlideSettingsRetrievalResponse?.google_account && taskGlideSettingsRetrievalResponse?.google_access_token && taskGlideSettingsRetrievalResponse?.google_refresh_token;

    const handleGoogleLogin = () => {
        const scope = "https://mail.google.com/ https://www.googleapis.com/auth/userinfo.email";
        const authParams = {
            response_type: "code",
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            redirect_uri: config.googleOAuth2RedirectUrl,
            scope: scope,
            access_type: "offline",
            prompt: "consent"
        };

        const queryString = new URLSearchParams(authParams).toString();
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;

        window.location.href = authUrl;
    };

    return (
        <button
            type="button"
            className="standard-button space-x-2"
            onClick={handleGoogleLogin}
        >
            <FcGoogle className="w-4 h-4 inline-block" />
            <p>{!isAuthed ? "Connect Google account" : "Disconnect Google account"}</p>
        </button>
    )
};

export default GoogleOAuthButton;
