import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const forgotPasswordPOST = createAsyncThunk(
    'users/account/forgot-password',
    async (values, thunkAPI) => {
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_POST_FORGOT_PASSWORD_PATH}`,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    passwordForgotVF: false,
    passwordForgotApiState: 'idle',
    passwordForgotResponse: null,
    passwordForgotError: null,
};

export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState: INITIAL_STATE,
    reducers: {
        clearPasswordForgotVF: (state) => {
            state.passwordForgotVF = false;
        },
        resetPasswordForgot: (state) => {
            state.passwordForgotVF = false;
            state.passwordForgotApiState = 'idle';
            state.passwordForgotResponse = null;
            state.passwordForgotError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(forgotPasswordPOST.pending, (state) => {
                state.passwordForgotApiState = 'loading';
                state.passwordForgotVF = true;
            })
            .addCase(forgotPasswordPOST.fulfilled, (state, action) => {
                state.passwordForgotApiState = 'succeeded';
                state.passwordForgotResponse = action.payload;
                state.passwordForgotVF = true;
                state.passwordForgotError = null;
            })
            .addCase(forgotPasswordPOST.rejected, (state, action) => {
                state.passwordForgotApiState = 'failed';
                state.passwordForgotError = action?.payload;
                state.passwordForgotVF = true;
                state.passwordForgotResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET' || action.type === 'RESET_USER_MANAGEMENT') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearPasswordForgotVF,
    resetPasswordForgot
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
