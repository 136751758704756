import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../users/useAuthentication";
import {useListPaymentMethods} from "./useListPaymentMethods";
import {clearPaymentMethodDefaultSettingVF, paymentMethodSetDefaultPOST, resetPaymentMethodDefaultSetting} from "../../../api/features/payments/setDefaultPaymentMethodSlice";
import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";


export const useSetDefaultPaymentMethod = (
    {vfExists=false, refreshAndReset=true} = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Payment Method
    const { listPaymentMethods } = useListPaymentMethods();
    const {
        paymentMethodDefaultSettingVF,
        paymentMethodDefaultSettingApiState,
        paymentMethodDefaultSettingResponse,
        paymentMethodDefaultSettingError,
    } = useSelector(state => state.setDefaultPaymentMethodReducer);

    // API State with visual feedback
    const paymentMethodDefaultSettingSuccessVF = paymentMethodDefaultSettingApiState === 'succeeded' && paymentMethodDefaultSettingVF;
    const paymentMethodDefaultSettingFailureVF = paymentMethodDefaultSettingApiState === 'failed' && paymentMethodDefaultSettingVF;

    const setDefaultPaymentMethod = (values) => {
        userIsAuthenticated && dispatch(paymentMethodSetDefaultPOST(values));
    };

    useEffect(() => {
        if (vfExists) {
            if (paymentMethodDefaultSettingSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearPaymentMethodDefaultSettingVF());
                }, 100);

                return () => clearTimeout(timer);
            }  else if (paymentMethodDefaultSettingFailureVF) {
                dispatch(setAlert({
                    message: paymentMethodDefaultSettingError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                dispatch(resetPaymentMethodDefaultSetting());
            }
        }
    }, [paymentMethodDefaultSettingApiState]);

    useEffect(() => {
        if (refreshAndReset && paymentMethodDefaultSettingApiState === 'succeeded') {
            // If payment method is successfully set as default, then refresh the list of payment methods and reset the states of the payment method default setting
            if (!paymentMethodDefaultSettingVF) {
                listPaymentMethods(1, 30);
                dispatch(resetPaymentMethodDefaultSetting());
            }
        }
    }, [paymentMethodDefaultSettingApiState, paymentMethodDefaultSettingVF]);

    return {
        setDefaultPaymentMethod,
        paymentMethodDefaultSettingVF,
        paymentMethodDefaultSettingApiState,
        paymentMethodDefaultSettingSuccessVF,
        paymentMethodDefaultSettingFailureVF,
        paymentMethodDefaultSettingResponse,
        paymentMethodDefaultSettingError,
    };
};

