import React from "react";
import {BsArrowRightSquareFill, BsStars} from "react-icons/bs";


const MemoMinderInputField = () => {

    return (
        <div className="relative w-full group/input-field">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <BsStars aria-hidden="true" className="size-4 group-focus-within/input-field:text-primary"/>
            </div>
            <input
                id="text"
                name="text"
                type="text"
                placeholder="I have an appointment with Jane at 2 oclock on June 3rd..."
                className="input-field px-10 py-1"
            />
            <button
                type="button"
                className="absolute top-0 inset-y-0 right-0 mr-2 p-0.5 my-0 flex items-center group/submit outline-none disabled:pointer-events-none rounded-md h-fit place-self-center bg-transparent disabled:opacity-50 text-base-content disabled:hover:sm:text-base-content bg-base-100 button-ring-focus"
            >
                <BsArrowRightSquareFill className="size-5 shrink-0"/>
                <div className="left-tooltips right-12 group-disabled/submit:sm:hidden group-hover/submit:sm:block">Send</div>
            </button>
        </div>
    )
};

export default MemoMinderInputField;
