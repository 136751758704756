import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {useCallBackend} from "../../calls/useCallBackend";


export const promoCodeValidationPOST = createAsyncThunk(
    'payments/promo-code-validation',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_PROMO_CODE_VALIDATION_PATH}`,
                    args: values,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    promoCodeValidationVF: false,
    promoCodeValidationApiState: 'idle',
    promoCodeValidationResponse: null,
    promoCodeValidationError: null,
}

export const validatePromoCodeSlice = createSlice({
    name: 'promoCodeValidation',
    initialState: INITIAL_STATE,
    reducers: {
        clearPromoCodeValidationVF: (state) => {
            state.promoCodeValidationVF = false;
        },
        resetPromoCodeValidation: (state) => {
            state.promoCodeValidationVF = false;
            state.promoCodeValidationApiState = 'idle';
            state.promoCodeValidationResponse = null;
            state.promoCodeValidationError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(promoCodeValidationPOST.pending, (state) => {
                state.promoCodeValidationApiState = 'loading';
                state.promoCodeValidationVF = true;
            })
            .addCase(promoCodeValidationPOST.fulfilled, (state, action) => {
                state.promoCodeValidationApiState = 'succeeded';
                state.promoCodeValidationResponse = action.payload;
                state.promoCodeValidationVF = true;
                state.promoCodeValidationError = null;
            })
            .addCase(promoCodeValidationPOST.rejected, (state, action) => {
                state.promoCodeValidationApiState = 'failed';
                state.promoCodeValidationError = action?.payload;
                state.promoCodeValidationVF = true;
                state.promoCodeValidationResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearPromoCodeValidationVF,
    resetPromoCodeValidation
} = validatePromoCodeSlice.actions;

export default validatePromoCodeSlice.reducer;
