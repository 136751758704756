import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {useAuthentication} from "../../../users/useAuthentication";
import {useListAllTgConversations} from "../conversations/useListAllTgConversations";
import {clearTaskGlideStreamingVF, streamTaskGlidePOST} from "../../../../../api/features/superIntelligence/taskGlide/taskGlide/streamTaskGlideSlice";
import {useGetSingleTgConversation} from "../conversations/useGetSingleTgConversation";
import {useGetTestConversation} from "../conversations/useGetTestConversation";
import {setTgStreamConversationUuid, setTgStreamInteractionId, setTgStreamInteractionMessages, setTgStreamInteractionStatus, setTgStreamSchema} from "../../../../../api/features/superIntelligence/taskGlide/frontend/getTgInteractionStreamSlice";
import {useGetTgInteractionStream} from "../frontend/useGetTgInteractionStream";
import {useGetCurrentTgConversation} from "../frontend/useGetCurrentTgConversation";


export const useStreamTaskGlide = (
    {vfExists=false, refreshAndReset=false, isTest=false} = {}
) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [prevConv, setPrevConv] = useState(null);

    const conversationUuid = searchParams.get('conversation_uuid');

    const { userIsAuthenticated } = useAuthentication();
    const { listAllTgConversations } = useListAllTgConversations();
    const { getSingleTGConversation } = useGetSingleTgConversation();
    const { getTestConversation } = useGetTestConversation();
    const { currentTgConversation, currentTestTgConversation } = useGetCurrentTgConversation();
    const {
        taskGlideStreamVF,
        taskGlideStreamApiState,
        taskGlideStreamResponse,
        taskGlideStreamError,
    } = useSelector(state => state.streamTaskGlideReducer);

    const {
        tgStreamConversationUuid,
        // tgStreamSelectedGlides,
        tgStreamInteractionId,
        tgStreamInteractionStatus,
        tgStreamInteractionMessages,
        // tgStreamUserMessage,
        // tgStreamGlideResponse,
        tgStreamSchema,
    } = useGetTgInteractionStream();

    const taskGlideStreamSuccessVF = taskGlideStreamApiState === 'succeeded' && taskGlideStreamVF;
    const taskGlideStreamFailureVF = taskGlideStreamApiState === 'failed' && taskGlideStreamVF;

    const currentConversation = isTest ? currentTestTgConversation : currentTgConversation;

    const updateConversation = (dataChunk) => {
        const lastConversationUuidInstance = dataChunk?.conversation_uuid;
        (lastConversationUuidInstance !== tgStreamConversationUuid) && dispatch(setTgStreamConversationUuid(lastConversationUuidInstance));

        // const lastSelectedGlidesInstance = dataChunk?.selected_agents;
        // (lastSelectedGlidesInstance !== tgStreamSelectedGlides) && dispatch(setTgStreamSelectedGlides(lastSelectedGlidesInstance));

        const lastInteractionIdInstance = dataChunk?.interactions?.results[0]?.id;
        (lastInteractionIdInstance !== tgStreamInteractionId) && dispatch(setTgStreamInteractionId(lastInteractionIdInstance));

        const lastInteractionStatusInstance = dataChunk?.interactions?.results[0]?.status;
        (lastInteractionStatusInstance !== tgStreamInteractionStatus) && dispatch(setTgStreamInteractionStatus(lastInteractionStatusInstance));

        const lastInteractionMessagesInstance = dataChunk?.interactions?.results[0]?.messages;
        (lastInteractionMessagesInstance !== tgStreamInteractionMessages) && dispatch(setTgStreamInteractionMessages(lastInteractionMessagesInstance));

        // const lastUserMessageInstance = dataChunk?.interactions?.results[0]?.user_message;
        // (lastUserMessageInstance !== tgStreamUserMessage) && dispatch(setTgStreamUserMessage(lastUserMessageInstance));
        //
        // const lastGlideResponseInstance = dataChunk?.interactions?.results[0]?.glide_response;
        // (lastGlideResponseInstance !== tgStreamGlideResponse) && dispatch(setTgStreamGlideResponse(lastGlideResponseInstance));

        const lastSchemaInstance = dataChunk?.interactions?.results[0]?.schema;
        (lastSchemaInstance !== tgStreamSchema) && dispatch(setTgStreamSchema(lastSchemaInstance));
    };

    const streamTaskGlide = (values, sessionInfo, tgConversationId, signal) => {
        if (userIsAuthenticated) {
            return dispatch(streamTaskGlidePOST({
                values: values,
                tgConversationId: tgConversationId,
                onStreamData: async (dataChunk) => {
                    if (dataChunk && JSON.stringify(dataChunk) !== JSON.stringify(prevConv)) {
                        updateConversation(dataChunk);
                        setPrevConv(dataChunk);
                    }
                },
                sessionInfo: sessionInfo,
                signal: signal,
            }));
        }
    };

    useEffect(() => {
        if (vfExists) {
            if (taskGlideStreamSuccessVF || taskGlideStreamFailureVF) {
                dispatch(clearTaskGlideStreamingVF());
                setPrevConv(null);
            }
        }
    }, [taskGlideStreamApiState, taskGlideStreamVF]);

    useEffect(() => {
        if (refreshAndReset && (taskGlideStreamApiState === 'succeeded' || taskGlideStreamApiState === 'failed') && !taskGlideStreamVF) {
            if (!isTest && tgStreamConversationUuid !== conversationUuid) {
                setSearchParams({ 'conversation_uuid': tgStreamConversationUuid });
            }
            !isTest ? getSingleTGConversation(tgStreamConversationUuid) : getTestConversation(currentConversation?.selected_agents[0]?.uuid)
            !isTest && listAllTgConversations();
        }
    }, [taskGlideStreamApiState, taskGlideStreamVF]);

    return {
        streamTaskGlide,
        taskGlideStreamSuccessVF,
        taskGlideStreamFailureVF,
        taskGlideStreamError,
        taskGlideStreamVF,
        taskGlideStreamApiState,
        taskGlideStreamResponse,
    };
};
