export const formatDateToReadable = (dateString) => {
    if (!dateString) { return ''; }
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [year, month, day] = dateString.split('-');
    const date = new Date(year, month - 1, day);
    const dayOfWeek = daysOfWeek[date.getDay()];
    const monthName = monthsOfYear[date.getMonth()];
    return `${dayOfWeek}, ${monthName} ${day}`;
};

export const formatDateTimeTo12Hour = (timeString) => {
    if (!timeString) { return ''; }
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(hours, minutes);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleTimeString('en-US', options);
};

export const formatTimeTo24HourFormat = (time12Hour) => {
    const [time, modifier] = time12Hour.split(' ');
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }
    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
};

export const formatTime12Hour = (date) => {
    const selectedDate = new Date(date);
    const hour = selectedDate.getHours() % 12 || 12;
    const minute = selectedDate.getMinutes().toString().padStart(2, '0');
    const period = selectedDate.getHours() < 12 ? 'AM' : 'PM';
    return `${hour.toString().padStart(2, '0')}:${minute} ${period}`;
};

export const roundTimeToNearest15Minutes = (date) => {
    const minutes = Math.ceil(date.getMinutes() / 15) * 15;
    date.setMinutes(minutes);
    return date;
};

export const isTimeDisabled = (time, date, referenceTime, referenceDate) => {
    if (!time || !date) { return false; }

    const [hour, minute, period] = time.split(/[:\s]/);
    const dateTime = new Date(date);
    dateTime.setHours(period === 'PM' ? parseInt(hour) % 12 + 12 : parseInt(hour) % 12, parseInt(minute), 0, 0);

    if (!referenceTime || !referenceDate) { return false; }

    const [referenceHour, referenceMinute, referencePeriod] = referenceTime.split(/[:\s]/);
    const referenceDateTime = new Date(referenceDate);
    referenceDateTime.setHours(referencePeriod === 'PM' ? parseInt(referenceHour) % 12 + 12 : parseInt(referenceHour) % 12, parseInt(referenceMinute), 0, 0);

    if (formatDateToISO(date) === formatDateToISO(referenceDate)) {
        return dateTime < referenceDateTime;
    } else {
        return false;
    }
};

export const isDateDisabled = (date, referenceDate) => {
    if (!date) { return false; }

    const dateToCheck = formatDateToISO(new Date(date));
    const referenceDateToCheck = formatDateToISO(new Date(referenceDate));
    return dateToCheck < referenceDateToCheck;
};

export const formatDateToISO = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const groupDatesByMonth = (dates) => {
    const months = dates?.map(month => ({
        name: month.month_name,
        days: month.days
    }));
    return months || [];
};

export const findMonthIndexByDate = (groupedDates, date) => {
    return groupedDates.findIndex(month =>
        month.days.some(day => day.date === date)
    );
};

export const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const period = hour < 12 ? 'AM' : 'PM';
            const displayHour = hour % 12 === 0 ? 12 : hour % 12;
            const time = `${displayHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
            times.push(time);
        }
    }
    return times;
};
