import React from "react";

import Markdown from "../../../Components/Markdown/Markdown";


const TermsOfUse = () => {

    return (
        <div className="bg-base-100 px-6 py-32 lg:px-8">
            <div className="mx-auto max-w-3xl text-base leading-7">
                <p className="text-base font-semibold leading-7 text-primary mb-2">Terms</p>
                <h1 className="text-4xl leading-7 mt-4 mb-6 font-bold">Terms of Use</h1>
                <Markdown
                    className="prose-h1:text-4xl prose-h1:pb-8 prose-p:text-base prose-p:font-light prose-p:pt-2 prose-p:leading-7 leading-7"
                    text={`Thank you for using Dovise!

These Terms of Use apply to your use of Dovise's products and services, including TaskGlide, CodeCraft, Scholar, ProseMaster, FiscalFlow, DataDive, SavantIQ, VitalHealth, and EcomEnhance, along with any associated software applications and websites (all together, “Services”). These Terms form an agreement between you and Dovise, LLC, a New York company, and include our Service Terms and important provisions for resolving disputes through arbitration. By using our Services, you agree to these Terms.

**Registration and Access**

1. **Minimum Age:** You must be at least 13 years old or the minimum age required in your country to consent to use the Services. If you are under 18, you must have your parent or legal guardian’s permission to use the Services.
2. **Registration:** You must provide accurate and complete information to register for an account to use our Services. You are responsible for all activities that occur under your account.

**Using Our Services**

1. **Permitted Uses:** Subject to your compliance with these Terms, you may access and use our Services. You must comply with all applicable laws as well as our Usage Policies.
2. **Prohibited Uses:** You may not use our Services for any illegal, harmful, or abusive activity. This includes but is not limited to:
   - Infringing on the rights of others.
   - Modifying, copying, leasing, selling, or distributing any of our Services.
   - Attempting to reverse engineer or discover the source code or underlying components of our Services.
   - Using Output to develop models that compete with Dovise.

**Ownership and Content**

1. **Your Content:** You retain ownership rights in your input data (“Input”). We own the output generated based on the Input (“Output”). However, we assign you all our right, title, and interest, if any, in and to Output.
2. **Our Use of Content:** We may use Content to provide, maintain, develop, and improve our Services, comply with applicable law, enforce our terms and policies, and keep our Services safe.

**Billing and Subscriptions**

1. **Billing:** If you purchase any Services, you will provide complete and accurate billing information, including a valid payment method.
2. **Cancellation:** You can cancel your paid subscription at any time. Payments are non-refundable, except where required by law.

**Termination and Suspension**

1. **Termination:** You are free to stop using our Services at any time. We reserve the right to suspend or terminate your access if you breach these Terms or our Usage Policies, if we must do so to comply with the law, or if your use of our Services could cause harm to Dovise or others.

**Dispute Resolution**

1. **Mandatory Arbitration:** You and Dovise agree to resolve any claims through final and binding arbitration. You may opt out of arbitration within 30 days of account creation.
2. **Class and Jury Trial Waivers:** Disputes must be brought on an individual basis only. Class actions are prohibited.`}
                    includeHtml={true}
                />
            </div>
        </div>
    )
};

export default TermsOfUse;

