import React from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

import routes from "../../../../Routes/routes";
import Nav from "../../Shell/Nav/Nav";


const SubscriptionConfirmation = () => {
    const navigate = useNavigate();

    return (
        <>
            <Nav/>
            <div className="relative h-[calc(100dvh-56px)] overflow-hidden px-0 lg:px-6 mx-auto max-h-[calc(100dvh-56px)] self-center justify-center sm:items-center p-5 rounded-md overflow-y-auto scrollbar flex flex-col items-center gap-y-3">
                <div className="text-base font-semibold text-primary">
                    Welcome
                </div>
                <div className="text-center">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight leading-[1.1] text-base-content text-[min(9vw,50px)]">
                        Subscription Successful
                    </h1>
                    <p className="mt-2 text-base text-base-content/70 max-w-lg">
                        Thank you for subscribing to Dovise. Your subscription is now active. You can now explore and enjoy our suite of tools and features.
                    </p>
                </div>
                <button
                    type="button"
                    onClick={() => navigate(routes.auth.taskGlide.home.href)}
                    className="primary-button mt-6"
                >
                    Navigate to TaskGlide
                </button>
            </div>
        </>
    )
};

SubscriptionConfirmation.propTypes = {
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
}

export default SubscriptionConfirmation;
