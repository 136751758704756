import React from "react";
import {SiAdobe, SiArxiv, SiMicrosoft, SiOpenai, SiTailwindcss, SiZapier} from "react-icons/si";
import {GiEgyptianBird} from "react-icons/gi";
import {GrStripe} from "react-icons/gr";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";

import routes from "../../../Routes/routes";
import BgCheckers from "../../../Components/Bg Checkers/BgCheckers";

const About = () => {
    const values = [
        {
            name: 'Putting Users First',
            description:
                'Our users are at the heart of everything we do. We understand the power of feedback and we strive to understand our user\'s needs, preferences, and goals, ensuring that our products and services consistently meet and exceed their expectations.',
        },
        {
            name: 'Innovating Beyond Boundaries',
            description:
                'We believe in innovation as a perpetual journey, never a destination. By challenging the status quo and daring to envision the impossible, we continue to create breakthrough solutions that shape the future of productivity.',
        },
        {
            name: 'Driving Efficiency',
            description:
                'Productivity isn\'t just About working harder—it\'s About working smarter. We\'re committed to driving efficiency, creating tools that allow our users to achieve their goals with minimal time and effort.',
        },
        {
            name: 'Upholding Integrity',
            description:
                'Trust is the foundation of our relationship with users. We uphold high standards of honesty, transparency, and respect in all our interactions, fostering an product where users feel valued and secure.',
        },
        {
            name: 'Fostering Collaboration',
            description:
                'We believe in the power of collective creativity and shared growth which drives our team dynamics and shapes how we build our products—focused on promoting collaborative effort and open communication among users.',
        },
        {
            name: 'Empowering Potential',
            description:
                'We don\'t just provide tools—we empower people. By delivering solutions that unlock our users\' full potential, we enable them to push their boundaries, realize their visions, and achieve more than they ever thought possible.',
        },
    ];

    const timeline = [
        {
            name: 'Project Kick-off',
            description:
                'At this time we begin the high-level application development. We start laying the groundwork for TaskGlideChatBox, ScholarSettings, and CodeCraft. This is the start of intensive backend and frontend infrastructure development.',
            date: 'Q2 2023',
            dateTime: '2023-04',
        },
        {
            name: 'Development Phase',
            description:
                'In the second quarter, we continue on the development of backend and frontend infrastructure. We spend time fine-tuning our applications in preparation for beta testing.',
            date: 'Q3 2023',
            dateTime: '2023-07',
        },
        {
            name: 'Released beta',
            description:
                'This is the beta testing phase for ScholarSettings and CodeCraft. At this time we analyze user feedback for product improvements. We are aiming to officially launch ScholarSettings and CodeCraft.',
            date: 'Q4 2023',
            dateTime: '2023-10',
        },
        {
            name: 'Global launch of product',
            description:
                'At this time we start with post-launch monitoring and improvements and we prepare and initiate the investment outreach. We will be planning for the next year with new developments and milestones.',
            date: 'Q1 2024',
            dateTime: '2024-01',
        },
    ];

    return (
        <>
            <main className="isolate">
                <div className="relative isolate -z-10 pt-14">
                    <BgCheckers/>
                    <div
                        className="absolute left-1/2 top-0 -z-10 -ml-24 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-primary to-primary-focus dark:to-primary-focus-dark opacity-30"
                            style={{
                                clipPath:
                                    'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                            }}
                        />
                    </div>
                    <div className="mx-auto max-w-7xl px-6 py-20 lg:px-8 sm:py-24">
                        <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                                <h1 className="text-4xl font-bold tracking-tight text-base-content sm:text-6xl mb-14">
                                    <motion.p
                                        variants={{
                                            hidden: { opacity: 1 },
                                            visible: {
                                                opacity: 1,
                                                transition: {
                                                    staggerChildren: 0.03,
                                                }
                                            }
                                        }}
                                        initial={"hidden"}
                                        animate={"visible"}
                                    >
                                        {"We’re changing the way people work.".split("").map((letter, index) => (
                                            <motion.span
                                                key={index}
                                                variants={{
                                                    hidden: { opacity: 0, y: 50 },
                                                    visible: {
                                                        opacity: 1,
                                                        y: 0,
                                                    }
                                                }}
                                            >
                                                {letter}
                                            </motion.span>
                                        ))}
                                    </motion.p>
                                </h1>
                                <p className="relative mt-6 text-lg leading-8 text-base-content/70 sm:max-w-md lg:max-w-none space-y-10">
                                    <p>
                                        Dovise is not just a company; it is an idea, a vision for a future where productivity is more than a buzzword—it&apos;s a reality. We have dedicated ourselves to building intelligent tools that drastically enhance and streamline productivity across a broad spectrum of tasks and fields. Whether it&apos;s shopping, documentation, programming, research, or education, Dovise is there to revolutionize your experience.
                                    </p>
                                    <p>
                                        We see productivity not just in terms of work output but as a lifestyle, influencing every aspect of our day-to-day lives. It&apos;s about enabling you to achieve more with less—less time, less effort, and less stress. Our ultimate goal? To create intelligent models that understand you and your data so intimately that they can assist you in the best and fastest way possible.
                                    </p>
                                </p>
                            </div>
                            <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-16 lg:mt-0 lg:pl-0">
                                <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                                    <div className="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1579389083395-4507e98b5e67?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"
                                            alt=""
                                            className="aspect-[2/3] w-full rounded-xl object-cover shadow-lg"
                                        />
                                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-neutral" />
                                    </div>
                                </div>
                                <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                                    <div className="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1603202662706-62ead3176b8f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=738&q=80"
                                            alt=""
                                            className="aspect-[2/3] w-full rounded-xl object-cover shadow-lg"
                                        />
                                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-neutral" />
                                    </div>
                                    <div className="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=776&q=80"
                                            alt=""
                                            className="aspect-[2/3] w-full rounded-xl object-cover shadow-lg"
                                        />
                                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-neutral" />
                                    </div>
                                </div>
                                <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                                    <div className="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1573496358961-3c82861ab8f4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=776&q=80"
                                            alt=""
                                            className="aspect-[2/3] w-full rounded-xl object-cover shadow-lg"
                                        />
                                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-neutral" />
                                    </div>
                                    <div className="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80"
                                            alt=""
                                            className="aspect-[2/3] w-full rounded-xl object-cover shadow-lg"
                                        />
                                        <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-neutral" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            {/* Content section */}
            <div className="mx-auto mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <h2 className="text-3xl font-bold tracking-tight text-base-content sm:text-4xl">Our mission</h2>
                    <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                        <div className="w-full lg:flex-auto">
                            <p className="text-lg leading-8 text-base-content/70">
                                At Dovise, our mission is to redefine productivity by delivering a suite of intelligent solutions that seamlessly integrate with users&apos; daily tasks. We aim to build advanced digital assistants capable of making everyday life easier, helping our users save valuable time and effort on tasks that can be automated or streamlined. Our solutions, from processing research data to optimizing code, are designed to help our users focus on what truly matters, leaving the routine and repetitive to us.
                            </p>
                            <div className="mt-10 w-full text-lg leading-7 text-base-content/70">
                                <p>
                                    We believe that technology should not only facilitate but also enhance human capabilities. Our mission extends beyond simply developing intelligent tools; we strive to create an intuitive, user-friendly ecosystem that understands and caters to our users&apos; unique needs and preferences. By harnessing the power of intelligent systems, we aim to provide personalized assistance that can learn and grow with our users, anticipating their needs and evolving to serve them better over time.
                                </p>
                                <p className="mt-10 text-lg">
                                    Our mission is also to foster a symbiotic relationship between humans and technology, where each complements the other. We envisage a world where technology is not viewed as a threat but as an empowering ally, a world where intelligent systems assists and augments human abilities rather than replacing them. In this light, we are dedicated to developing intelligent applications that respect user data privacy and security, ensuring that our solutions are both safe and trustworthy.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Image section */}
            <div className="relative isolate mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
                <img
                    src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
                    alt="Productive team meeting"
                    className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
                />
                <div
                    className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-secondary to-primary opacity-30"
                        style={{
                            clipPath:
                                'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                        }}
                    />
                </div>
            </div>

            {/* Values section */}
            <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-full lg:mx-0">
                    <h2 className="text-3xl font-bold tracking-tight text-base-content sm:text-4xl">Our vision and values</h2>
                    <p className="mt-6 text-lg leading-8 text-base-content/70">
                        Our vision is to reinvent productivity, breaking it free from the confines of office spaces and work hours, and embedding it into the very fabric of our everyday lives. We aspire to create a future where work becomes more enjoyable, less burdensome, and infinitely more efficient.
                    </p>
                </div>
                <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-12 gap-y-14 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {values.map((value) => (
                        <div key={value.name}>
                            <dt className="font-semibold text-base-content">{value.name}</dt>
                            <dd className="mt-1 text-base-content/70">{value.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>

            {/* Logo cloud */}
            <div className="relative isolate -z-10 mt-32 sm:mt-40">
                <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
                    <svg className="h-[40rem] w-[80rem] flex-none stroke-primary/10 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]" aria-hidden="true">
                        <defs>
                            <pattern
                                id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                                width={200}
                                height={200}
                                x="50%"
                                y="50%"
                                patternUnits="userSpaceOnUse"
                                patternTransform="translate(-100 0)"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y="50%" className="overflow-visible fill-primary/10">
                            <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" strokeWidth={0} />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)" />
                    </svg>
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <h2 className="text-center text-lg font-semibold leading-8 text-base-content">
                        Leveraging the world’s most innovative and trusted services
                    </h2>
                    <div className="mx-auto mt-10 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:gap-x-14 lg:mx-0 lg:max-w-none sm:grid-cols-4 justify-items-center text-base-300 text-5xl">
                        <SiOpenai className={'max-h-12'} />
                        <GiEgyptianBird className={'max-h-12'} />
                        <SiAdobe className={'max-h-12'} />
                        <SiMicrosoft className={'max-h-12'} />
                        <SiArxiv className={'max-h-12'} />
                        <GrStripe className={'max-h-12'} />
                        <SiZapier className={'max-h-12'} />
                        <SiTailwindcss className={'max-h-12'} />
                    </div>
                </div>
            </div>

            <div className="relative isolate mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-full lg:mx-0 space-y-4">
                    <h2 className="text-3xl font-bold tracking-tight text-base-content sm:text-4xl">
                        Roadmap
                    </h2>
                    <p className="text-lg leading-8 text-base-content/70">
                        Our roadmap is a testament to our commitment to our vision. Our intelligent suite of tools represent the first steps towards making our dream a reality. We&apos;re continuously refining these products, guided by user feedback and the latest technological advancements.
                    </p>
                </div>
                <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                        {timeline.map((item) => (
                            <div key={item.name}>
                                <time
                                    dateTime={item.dateTime}
                                    className="flex items-center text-sm font-medium leading-6 text-primary dark:text-secondary-focus-dark"
                                >
                                    <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                                        <circle cx={2} cy={2} r={2} fill="currentColor" />
                                    </svg>
                                    {item.date}
                                    <div
                                        className="absolute -ml-2 h-px w-screen -translate-x-full bg-neutral-focus dark:bg-neutral-focus-dark sm:-ml-4 lg:static lg:-mr-6 lg:ml-2 lg:w-auto lg:flex-auto lg:translate-x-0"
                                        aria-hidden="true"
                                    />
                                </time>
                                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-base-content">{item.name}</p>
                                <p className="mt-1 text-base leading-7 text-base-content/70">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="mx-auto my-32 max-w-7xl px-6 sm:my-40 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none space-y-4">
                    <h2 className="text-3xl font-bold tracking-tight text-base-content">Call to Action</h2>
                    <div className="flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                        <p className="leading-8 text-base-content/70 text-lg">
                            We invite you to join us on our exciting journey. Whether you&apos;re an investor interested in helping us grow, a creative mind eager to be part of our team, or a user excited about our products, we&apos;d love to hear from you. Let&apos;s work together to redefine productivity and make everyday tasks enjoyable and effortless. {' '}
                            <Link to={routes.unAuth.contact.href} className="text-secondary hover:text-secondary-focus dark:hover:text-secondary-focus-dark">
                                Connect with us today!
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            </main>
        </>
    )
};

export default About;
