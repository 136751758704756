import {useCallback, useEffect, useRef, useState} from 'react';


export const useAutoScroll = () => {
    const pageEndRef = useRef(null);
    const lastScrollTop = useRef(0);
    const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(false);

    const handleAutoScroll = useCallback(() => {
        if (isAutoScrollEnabled && pageEndRef?.current) {
            pageEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isAutoScrollEnabled]);

    useEffect(() => {
        const scrollContainer = pageEndRef.current?.parentNode;

        const handleScroll = () => {
            let currentScrollTop = scrollContainer?.scrollTop || 0;
            let maxScrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight;

            if (currentScrollTop < lastScrollTop.current && currentScrollTop < maxScrollTop - 10) {
                setIsAutoScrollEnabled(false);
            } else if (currentScrollTop >= maxScrollTop - 10) {
                setIsAutoScrollEnabled(true);
            }
            lastScrollTop.current = currentScrollTop;
        };

        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            const observer = new MutationObserver(() => {
                if (isAutoScrollEnabled) {
                    handleAutoScroll();
                }
            });
            observer.observe(scrollContainer, { childList: true, subtree: true });

            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
                observer.disconnect();
            };
        }
    }, [isAutoScrollEnabled, handleAutoScroll]);

    useEffect(() => {
        handleAutoScroll();
    }, [isAutoScrollEnabled]);

    return {
        pageEndRef,
        isAutoScrollEnabled,
        setIsAutoScrollEnabled
    };
};
