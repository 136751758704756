import React, { useEffect, useState } from "react";
import {BsPlusCircleDotted} from "react-icons/bs";
import {CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {useSelector} from "react-redux";
import {loadStripe} from "@stripe/stripe-js";
import clsx from "clsx";
import {RiCheckboxCircleLine, RiCloseCircleLine} from "react-icons/ri";
import {CgSpinner} from "react-icons/cg";
import PropTypes from "prop-types";
import {motion} from "framer-motion";

import {cardLogos} from "./LinkedCards";
import {useCreatePaymentMethod} from "../../../../Hooks/apiHooks/payments/useCreatePaymentMethod";
import Alerts from "../../../../Components/Alerts/Alerts";
import {useStripeCheckout} from "../../../../Hooks/frontendHooks/useStripeCheckout";
import AddressAutocompletion from "../../../../Components/AddressAutocompletion/AddressAutocompletion";
import config from "../../../../config";
import {useAlert} from "../../../../Hooks/frontendHooks/useAlert";


const stripePromise = loadStripe(config.stripePublishableKey);

const CardCheckout = ({ setNewPMCreation }) => {
    const stripe = useStripe();
    const elements = useElements();
    const {alert, alertKey} = useAlert();
    const theme = useSelector(state => state.frontendReducer.theme);

    const {
        stripeCheckoutFailureVF,
        stripeCheckoutLoadingVF,
        getIdAndCreatePaymentMethod,
        stripeCheckoutVF
    } = useStripeCheckout();
    const {
        paymentCreationVF,
        paymentCreationFailureVF,
        paymentCreationApiState,
        paymentCreationSuccessVF,
        paymentCreationLoadingVF
    } = useCreatePaymentMethod({vfExists: true, refreshAndReset: false});

    const cardNumberElementRef = React.useRef(null);
    const cardExpiryElementRef = React.useRef(null);
    const cardCvcElementRef = React.useRef(null);

    // Card Detail States
    const [cardBrand, setCardBrand] = useState('unknown');
    const [cardName, setCardName] = useState('');
    const [address, setAddress] = useState({
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
    });

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: theme === 'dark' ? '#e2e8f0' : '#1e293b',
                fontSize: "14px",
                fontWeight: "300",
                "::placeholder": {
                    color: theme === 'dark' ? 'rgba(226,232,240,0.3)' : 'rgba(30,41,59,0.3)'
                },
            },
            invalid: {
                color: theme === 'dark' ? '#f87171' : '#dc2626',
                iconColor: theme === 'dark' ? '#7f1d1d' : '#fee2e2',
                ":-webkit-autofill": {
                    color: theme === 'dark' ? '#f87171' : '#dc2626',
                }
            },
        }
    };

    const handleCardNumberChange = (event) => {
        if (event.brand) {
            setCardBrand(event.brand);
        }
    };

    const handleFocus = (ref) => {
        if (ref.current) {
            ref.current.classList.add("ring", "ring-info/30", "rounded-md", "ring-offset-1", "ring-offset-info/10", "text-base-content");
        }
    };

    const handleBlur = (ref) => {
        if (ref.current) {
            ref.current.classList.remove("ring", "ring-info/30", "rounded-md", "ring-offset-1", "ring-offset-info/10", "text-base-content");
        }
    };

    // Handle Submit
    const handleSubmit = async (event) => {
        event.preventDefault();
        getIdAndCreatePaymentMethod({
            stripe: stripe,
            elements: elements,
            address: address,
            cardName: cardName
        });

        // Prevents the skeleton visual feedback to re-render after the payment method is created and modal is closed
        setNewPMCreation(true);
    };

    /* This checks if card name or address field are empty to disable the submit button */
    const validateFields = () => {
        const isCardNameEmpty = cardName === '';
        const isAddressEmpty = !address?.line1 || !address?.city || !address?.state || !address?.country || !address?.postal_code;

        return !(isCardNameEmpty || isAddressEmpty);
    };

    // Close Modal on Success
    useEffect(() => {
        if (paymentCreationApiState === 'succeeded') {
            if (!paymentCreationVF) {
                const dialogElement = document.getElementById('cardAddition');
                if (dialogElement) {
                    dialogElement.close();
                }
            }
        }
    }, [paymentCreationApiState, paymentCreationVF]);

    return (
        <>
            <form
                onSubmit={handleSubmit}
                method="dialog"
                className="modal-wrapper space-y-6"
            >
                <div className="mt-3 sm:mt-2 sm:text-left w-full">
                    <h3 className="text-base font-semibold leading-6 text-base-content">
                        Add a new card
                    </h3>
                    <div className="mt-2">
                        <p className="text-sm text-base-content/70">
                            Your default card will be charged at the end of each billing period.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-y-5">
                    <div className="group relative col-span-full grid grid-cols-5 items-center">
                        <span className="col-span-2 text-sm">Card number</span>
                        <div className="group relative col-span-3" ref={cardNumberElementRef}>
                            <CardNumberElement
                                onFocus={() => handleFocus(cardNumberElementRef)}
                                onBlur={() => handleBlur(cardNumberElementRef)}
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={handleCardNumberChange}
                                className="block w-full appearance-none rounded-md border-0 bg-base-100 shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-[0.75px] ring-base-content/20 dark:ring-base-content/20 outline-none text-base-content/70 text-sm sm:leading-6 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light disabled:cursor-not-allowed disabled:text-base-content/50 py-2 px-2 input-ring-focus font-light"
                            />
                            <img
                                src={cardBrand !== "unknown" ? cardLogos[cardBrand] : cardLogos["cardGeneric"]}
                                alt={cardBrand.toUpperCase()}
                                className="w-auto h-4 absolute right-2 top-2 shadow ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-sm shrink-0"
                            />
                        </div>
                    </div>
                    <div className="group relative col-span-full grid grid-cols-5 items-center">
                        <span className="col-span-2 text-sm">Expiry date</span>
                        <div className="group relative col-span-3" ref={cardExpiryElementRef}>
                            <CardExpiryElement
                                onFocus={() => handleFocus(cardExpiryElementRef)}
                                onBlur={() => handleBlur(cardExpiryElementRef)}
                                options={CARD_ELEMENT_OPTIONS}
                                className="block w-full appearance-none rounded-md border-0 bg-base-100 shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-[0.75px] ring-base-content/20 dark:ring-base-content/20 outline-none text-base-content text-sm sm:leading-6 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light disabled:cursor-not-allowed disabled:text-base-content/70 py-2 px-2 input-ring-focus font-light"
                            />
                        </div>
                    </div>
                    <div className="group relative col-span-full grid grid-cols-5 items-center">
                        <span className="col-span-2 text-sm">CVC</span>
                        <div className="group relative col-span-3" ref={cardCvcElementRef}>
                            <CardCvcElement
                                onFocus={() => handleFocus(cardCvcElementRef)}
                                onBlur={() => handleBlur(cardCvcElementRef)}
                                options={CARD_ELEMENT_OPTIONS}
                                className="block w-full appearance-none rounded-md border-0 bg-base-100 shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-[0.75px] ring-base-content/20 dark:ring-base-content/20 outline-none text-base-content text-sm sm:leading-6 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light disabled:cursor-not-allowed disabled:text-base-content/70 py-2 px-2 input-ring-focus font-light"
                            />
                        </div>
                    </div>
                    <div className="group relative col-span-full grid grid-cols-5 items-center">
                        <span className="col-span-2 text-sm">Cardholder name</span>
                        <input
                            type="text"
                            name="cardholder-name"
                            className="input-field col-span-3"
                            placeholder="John Doe"
                            onChange={(event) => setCardName(event.target.value)}
                        />
                    </div>
                    <div className="group relative col-span-full grid grid-cols-5 items-center">
                        <span className="col-span-2 text-sm">Billing address</span>
                        <AddressAutocompletion
                            placeholder="123 Main St, New York, NY, USA, 10001"
                            wrapperClass="col-span-3"
                            onSelect={addressComponents => {
                                setAddress({
                                    line1: addressComponents?.address1,
                                    line2: addressComponents?.address2,
                                    city: addressComponents?.city,
                                    state: addressComponents?.state,
                                    country: addressComponents?.countryCode,
                                    postal_code: addressComponents?.zip_code,
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-end gap-x-3">
                    <button
                        type="button"
                        onClick={() => {
                            const dialogElement = document.getElementById('cardAddition');
                            dialogElement?.close();
                        }}
                        className="standard-button"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={!validateFields() || !stripe || !elements || stripeCheckoutVF || paymentCreationVF}
                        className={clsx(
                            paymentCreationFailureVF ? "error-button" :
                                paymentCreationSuccessVF ? "success-button" :
                                    "primary-button")}
                    >
                        <CgSpinner
                            className={clsx(
                                "absolute inset-0 m-auto animate-spin h-5 w-5",
                                (stripeCheckoutLoadingVF || paymentCreationLoadingVF) ? "" : "hidden"
                            )}
                            aria-hidden="true"
                        />
                        <RiCloseCircleLine
                            className={clsx(
                                "absolute inset-0 m-auto h-5 w-5",
                                paymentCreationFailureVF ? "" : "hidden"
                            )}
                            aria-hidden="true"
                        />
                        <RiCheckboxCircleLine
                            className={clsx(
                                "absolute inset-0 m-auto h-5 w-5",
                                paymentCreationSuccessVF ? "" : "hidden"
                            )}
                            aria-hidden="true"
                        />
                        <p className={(stripeCheckoutVF || paymentCreationVF) ? "invisible" : ""}>
                            Submit
                        </p>
                    </button>
                </div>
            </form>
            {(paymentCreationSuccessVF || paymentCreationFailureVF || stripeCheckoutFailureVF) &&
                <Alerts message={alert.message} type={alert.type} keyValue={alertKey}/>}
        </>
    );
};

CardCheckout.propTypes = {
    setNewPMCreation: PropTypes.func,
}

const CardAdditionModal = ({setNewPMCreation}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <motion.button
                type="button"
                layout
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                onClick={(e) => {
                    e.preventDefault();
                    const dialogElement = document.getElementById('cardAddition');
                    dialogElement.showModal();
                    setIsModalOpen(true);
                }}
                className="group flex justify-center items-center min-h-[120px] sm:min-h-0 border border-base-300/50 border-dashed rounded-sm bg-base-200 input-ring-focus"
            >
                <div className="px-6 py-4 flex items-center justify-center w-full cursor-pointer max-h-48">
                    <div
                        className="flex flex-col items-center justify-center space-y-1 focus:outline-none"
                    >
                        <BsPlusCircleDotted className="h-6 w-6 text-base-content/70 group-hover:text-base-content" aria-hidden="true" />
                        <span className="text-sm font-medium text-base-content/70 group-hover:text-base-content">Add a new card</span>
                    </div>
                </div>
            </motion.button>
            <dialog
                id="cardAddition"
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={() => setIsModalOpen(false)}
            >
                <Elements stripe={stripePromise}>
                    <CardCheckout setNewPMCreation={setNewPMCreation} />
                </Elements>
                <form method="dialog" className="modal-backdrop">
                    <button
                        tabIndex={-1}
                        className="cursor-default focus-visible:outline-0 focus-visible:ring-0"
                        onClick={() => {
                            const dialogElement = document.getElementById('cardAddition');
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >
                        close
                    </button>
                </form>
            </dialog>
        </>
    );
};

CardAdditionModal.propTypes = {
    setNewPMCreation: PropTypes.func,
};

export default CardAdditionModal;
