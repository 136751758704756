import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import TaskGlideEmptyState from "./TaskGlideEmptyState";
import TaskGlideMessage from "../Messages/TaskGlideMessage";
import {useGetCurrentTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetCurrentTgConversation";
import {useStreamTaskGlide} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";
import {useGetSingleTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetSingleTgConversation";
import {useDeleteTaskGlideMessage} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteTaskGlideMessage";
import {useListAllTgConversations} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useListAllTgConversations";
import {useGetTgInteractionStream} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetTgInteractionStream";
import TaskGlideEmptyStateSkeleton from "./TaskGlideEmptyStateSkeleton";
import MessageLoader from "../../../../../../../Components/MessageLoader/MessageLoader";
import {userAvatar} from "../../../../../Settings/Account/AccountSettings";


const TaskGlideChatBox = (
    { formik, followUpsAreCollapsed, selectedGlides, setSelectedGlides, handleInteractionPagination, chatBoxRef }
) => {
    const { tgConversationsListingResponse, tgConversationsListingVF } = useListAllTgConversations();
    const { deleteTaskGlideMessage, taskGlideMessageDeletionVF } = useDeleteTaskGlideMessage(
        { vfExists: true, autoUpdate: true }
    );
    const { taskGlideStreamVF } = useStreamTaskGlide();
    const { currentTgConversation } = useGetCurrentTgConversation();
    const { tgSingleConversationRetrievalVF } = useGetSingleTgConversation();
    const {
        tgStreamInteractionId,
        tgStreamInteractionStatus,
        tgStreamInteractionMessages,
        tgStreamSchema,
    } = useGetTgInteractionStream();

    if (!tgConversationsListingResponse) {
        return <TaskGlideEmptyStateSkeleton />
    } else if (!currentTgConversation || currentTgConversation?.interactions?.results?.length === 0) {
        return <TaskGlideEmptyState
            formik={formik}
            selectedGlides={selectedGlides}
            setSelectedGlides={setSelectedGlides}
        />;
    }

    return (
        <div className="relative flex-1">
            <div className={clsx(
                "px-4 lg:px-2 space-y-6 pt-4",
                followUpsAreCollapsed ? "pb-12" : "pb-20"
            )} >
                {!!currentTgConversation?.interactions?.next && <MessageLoader onLoad={handleInteractionPagination} isLoading={tgSingleConversationRetrievalVF} disabled={taskGlideStreamVF || tgConversationsListingVF || taskGlideMessageDeletionVF} />}
                {currentTgConversation?.interactions?.results?.map((interaction) => {
                    const isLastMessage = currentTgConversation?.interactions?.results?.[currentTgConversation?.interactions?.results?.length - 1]?.id === interaction?.id;

                    const currentInteraction = (isLastMessage && tgStreamInteractionMessages && interaction?.messages?.length > 0) ? {
                        "id": tgStreamInteractionId,
                        "status": tgStreamInteractionStatus,
                        "messages": tgStreamInteractionMessages,
                        "schema": tgStreamSchema,
                    } : interaction;

                    return <TaskGlideMessage
                            key={currentInteraction?.id || 0}
                            isLastMessage={isLastMessage}
                            interaction={currentInteraction}
                            userAvatar={userAvatar}
                            onUserMessageDelete={() => deleteTaskGlideMessage(currentInteraction?.id, true)}
                            onTaskGlideResponseDelete={() => deleteTaskGlideMessage(currentInteraction?.id, false)}
                            chatBoxRef={chatBoxRef}
                            pinningIsEnabled={true}
                        />
                    })}
            </div>
        </div>
    );
};

TaskGlideChatBox.propTypes = {
    formik: PropTypes.object,
    followUpsAreCollapsed: PropTypes.bool,
    selectedGlides: PropTypes.array,
    setSelectedGlides: PropTypes.func,
    handleInteractionPagination: PropTypes.func,
    chatBoxRef: PropTypes.object,
};

export default TaskGlideChatBox;
