import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const userGlideDefaultStatePOST = createAsyncThunk(
    'taskGlide/glide/set-default',
    async ({ user_glide_id }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_TASKGLIDE_UPDATE_USER_GLIDE_DEFAULT_STATE_PATH}${user_glide_id}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userGlideDefaultStateUpdateVF: false,
    userGlideDefaultStateUpdateApiState: 'idle',
    userGlideDefaultStateUpdateResponse: null,
    userGlideDefaultStateUpdateError: null,
};

export const updateUserGlideDefaultStateSlice = createSlice({
    name: 'userGlideDefaultStateUpdate',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserGlideDefaultStateUpdateVF: (state) => {
            state.userGlideDefaultStateUpdateVF = false;
        },
        resetUserGlideDefaultStateUpdate: (state) => {
            state.userGlideDefaultStateUpdateVF = false;
            state.userGlideDefaultStateUpdateApiState = 'idle';
            state.userDefinedGlideUpdateResponse = null;
            state.userDefinedGlideUpdateError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userGlideDefaultStatePOST.pending, (state) => {
                state.userGlideDefaultStateUpdateApiState = 'loading';
                state.userGlideDefaultStateUpdateVF = true;
            })
            .addCase(userGlideDefaultStatePOST.fulfilled, (state, action) => {
                state.userGlideDefaultStateUpdateApiState = 'succeeded';
                state.userGlideDefaultStateUpdateResponse = action?.payload;
                state.userGlideDefaultStateUpdateVF = true;
                state.userGlideDefaultStateUpdateError = null;
            })
            .addCase(userGlideDefaultStatePOST.rejected, (state, action) => {
                state.userGlideDefaultStateUpdateApiState = 'failed';
                state.userGlideDefaultStateUpdateError = action?.payload;
                state.userGlideDefaultStateUpdateVF = true;
                state.userGlideDefaultStateUpdateResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUserGlideDefaultStateUpdateVF, resetUserGlideDefaultStateUpdate
} = updateUserGlideDefaultStateSlice.actions;

export default updateUserGlideDefaultStateSlice.reducer;
