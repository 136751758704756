export function handleFetchError(error, thunkAPI) {
    console.error('Error in useCallBackend:', error);
    return thunkAPI.rejectWithValue(error.message || 'An error occurred while fetching data');
}

export async function handleResponseError(response, thunkAPI) {
    const errorResponse = await response.json();
    console.error(`Error ${response.status}: ${response.statusText}`);
    return thunkAPI.rejectWithValue(errorResponse);
}
