import React from "react";

import Markdown from "../../../Components/Markdown/Markdown";


const UsagePolicies = () => {

    return (
        <div className="bg-base-100 px-6 py-32 lg:px-8">
            <div className="mx-auto max-w-3xl text-base leading-7">
                <p className="text-base font-semibold leading-7 text-primary mb-2">policies</p>
                <h1 className="text-4xl leading-7 mt-4 mb-6 font-bold">Usage Policies</h1>
                <Markdown
                    className="prose-h1:text-4xl prose-h1:pb-8 prose-p:text-base prose-p:font-light prose-p:pt-2 prose-p:leading-7 leading-7"
                    text={`By using our Services, you agree to adhere to the following usage policies:

**1. Universal Policies**

- **Compliance with Laws:** Do not use our services for illegal activities.
- **Harm Prevention:** Do not use our services to harm yourself or others.
- **Respect Safeguards:** Do not circumvent safety measures.

**2. API Policies**

- **Privacy Protection:** Do not misuse personal data.
- **High-Stakes Decisions:** Do not use the API for decisions affecting safety or rights without professional oversight.

**3. Dovise products Policies**

- **Privacy Protection:** Do not collect sensitive personal data without consent.
- **Accuracy and Transparency:** Ensure automated systems disclose they are AI.

**4. Content Policies**

- **Accuracy:** Evaluate the accuracy of AI-generated content before use.
- **Appropriateness:** Ensure content is appropriate for your audience.

### Sections Requiring User Agreement Before Signing Up

Users need to agree to the following sections before signing up:

1. **Terms of Use:** Ensures users understand their rights and responsibilities.
2. **Privacy Policy:** Informs users about data collection and usage practices.
3. **Usage Policies:** Sets guidelines for acceptable use of the services.

These agreements ensure users are fully informed and compliant with the terms and conditions of using Dovise's services.`}
                    includeHtml={true}
                />
            </div>
        </div>
    )
};

export default UsagePolicies;

