import {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {useAuthentication} from "../users/useAuthentication";
import {clearPaymentMethodDeletionVF, paymentMethodDELETE, resetPaymentMethodDeletion} from "../../../api/features/payments/deletePaymentMethodSlice";
import {useListPaymentMethods} from "./useListPaymentMethods";
import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";


export const useDeletePaymentMethod =  ({vfExists=false, refreshAndReset=true} = {}) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Payment Method
    const { listPaymentMethods } = useListPaymentMethods();
    const {
        paymentMethodDeletionVF,
        paymentMethodDeletionApiState,
        paymentMethodDeletionResponse,
        paymentMethodDeletionError,
    } = useSelector(state => state.deletePaymentMethodReducer);

    // API states with visual feedback
    const paymentMethodDeletionSuccessVF = paymentMethodDeletionApiState === 'succeeded' && paymentMethodDeletionVF;
    const paymentMethodDeletionFailureVF = paymentMethodDeletionApiState === 'failed' && paymentMethodDeletionVF;

    const deletePaymentMethod = (paymentMethodId) => userIsAuthenticated && dispatch(paymentMethodDELETE(paymentMethodId));

    useEffect(() => {
        if (vfExists) {
            if (paymentMethodDeletionSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearPaymentMethodDeletionVF());
                }, 100);

                return () => clearTimeout(timer);
            } else if (paymentMethodDeletionFailureVF) {
                dispatch(setAlert({
                    message: paymentMethodDeletionError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearPaymentMethodDeletionVF());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [paymentMethodDeletionApiState]);

    useEffect(() => {
        if (refreshAndReset && paymentMethodDeletionApiState === 'succeeded') {
            // If payment method is successfully set as default, then refresh the list of payment methods and reset the states of the payment method default setting
            if (!paymentMethodDeletionVF) {
                listPaymentMethods(1, 30);
                dispatch(resetPaymentMethodDeletion());
            }
        }
    }, [paymentMethodDeletionApiState, paymentMethodDeletionVF]);

    return {
        deletePaymentMethod,
        paymentMethodDeletionVF,
        paymentMethodDeletionApiState,
        paymentMethodDeletionSuccessVF,
        paymentMethodDeletionFailureVF,
    };
};
