import React from "react";
import clsx from "clsx";
import {HiUser} from "react-icons/hi2";
import {BiDotsHorizontalRounded, BiSolidBrain} from "react-icons/bi";
import {AiFillTool} from "react-icons/ai";
import {MdOutlinePlayCircleFilled} from "react-icons/md";
import {BsBarChartFill} from "react-icons/bs";
import {RiBubbleChartFill} from "react-icons/ri";

import {glideColorClasses, getGlideIcon} from "../../../../Auth/Products/TaskGlide/Chat/ChatBox/Messages/glideInfo";


const GlidesProducts = () => {
    const glideWrapClassName = "w-20 h-20 -ml-6 rounded-full text-base-100 leading-none font-medium shadow shadow-gray-500 dark:shadow-black flex items-center justify-center shrink-0 bg-gradient-to-t from-base-100/50";
    const glideClassName = "w-10 h-10 text-xl";

    return (
        <div className="relative isolate py-24">
            <div
                className="absolute left-1/2 top-0 -z-10 -ml-24 transform-gpu blur-3xl lg:ml-24 xl:ml-24"
                aria-hidden="true"
            >
                <div
                    className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-primary to-primary-focus dark:to-primary-focus-dark opacity-20"
                    style={{
                        clipPath:
                            'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                    }}
                />
            </div>
            <div aria-hidden="true" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-12 space-y-10">
                <div className="relative flex mx-auto w-fit p-2">
                    <div className={clsx("w-20 h-20 rounded-full text-base-100 leading-none font-medium shadow shadow-gray-500 dark:shadow-black flex items-center justify-center shrink-0 bg-gradient-to-t from-base-100/50", glideColorClasses['indigo']?.bg)}
                    >
                        {getGlideIcon('TaskGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "z-[2]", glideColorClasses['red']?.bg)}
                    >
                        {getGlideIcon('NewsGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "z-[3]", glideColorClasses['green']?.bg)}
                    >
                        {getGlideIcon('EcoGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "z-[4]", glideColorClasses['indigo']?.bg)}
                    >
                        {getGlideIcon('TechGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "hidden lg:flex z-[5]", glideColorClasses['slate']?.bg)}
                    >
                        {getGlideIcon('LegalGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "hidden lg:flex z-[6]", glideColorClasses['blue']?.bg)}
                    >
                        {getGlideIcon('SportGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "hidden lg:flex z-[7]", glideColorClasses['orange']?.bg)}
                    >
                        {getGlideIcon('CookGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "hidden lg:flex z-[8]", glideColorClasses['yellow']?.bg)}
                    >
                        {getGlideIcon('MathGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "hidden lg:flex z-[9]", glideColorClasses['violet']?.bg)}
                    >
                        {getGlideIcon('PhiloGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "hidden lg:flex z-[10]", glideColorClasses['red']?.bg)}
                    >
                        {getGlideIcon('HealthGlide', glideClassName)}
                    </div>
                    <div className={clsx(glideWrapClassName, "z-[11]", glideColorClasses['gray']?.bg)}
                    >
                        <BiDotsHorizontalRounded className={glideClassName} />
                    </div>
                </div>
                <div className="space-y-16">
                    <div className="relative text-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 z-40 space-y-4">
                        <h1 className="font-semibold tracking-tight text-[min(10vw,50px)]">Glides by Dovise</h1>
                        <p className="text-base-content/70">Your Personalized Path to Mastery and Efficiency</p>
                    </div>
                    <div className="mx-auto max-w-7xl">
                        <p className="text-base-content/70">At Dovise, we believe in empowering you with tools that transform how you interact with the world around you. Glides are our latest innovation, designed to be your personalized assistants, each with expertise in various domains. From delving into the arts to unraveling the mysteries of science, Glides stand ready to enhance your knowledge, streamline your tasks, and enrich your daily experiences.</p>
                    </div>
                    <div className="max-w-7xl mx-auto">
                        <dl className="mx-auto grid max-w-2xl lg:max-w-none grid-cols-1 sm:grid-cols-2 gap-x-16 gap-y-14">
                            <div className="border-t border-neutral pt-4 z-40">
                                <dt className="flex items-center space-x-2">
                                    <HiUser className="w-4 h-4 text-primary-focus dark:text-primary-focus-dark"/>
                                    <h3 className="font-medium text-base-content">Diverse Expertise</h3>
                                </dt>
                                <dd className="mt-2 text-base-content/70">Choose from a wide range of Glides, each an expert in different fields. Whether you are looking to explore new hobbies, get professional advice, or simply make informed decisions, there is a Glide for you.</dd>
                            </div>
                            <div className="border-t border-neutral pt-4 z-40">
                                <dt className="flex items-center space-x-2">
                                    <AiFillTool className="w-4 h-4 text-primary-focus dark:text-primary-focus-dark"/>
                                    <h3 className="font-medium text-base-content">Integrated Tool Access</h3>
                                </dt>
                                <dd className="mt-2 text-base-content/70">Each Glide is equipped with a specialized set of tools, from calculators to document readers, designed to facilitate and expedite the completion of your tasks. This seamless integration ensures that your Glide is not just a source of information but a practical aid in your daily life.</dd>
                            </div>
                            <div className="border-t border-neutral pt-4 z-40">
                                <dt className="flex items-center space-x-2">
                                    <MdOutlinePlayCircleFilled className="w-4 h-4 text-primary-focus dark:text-primary-focus-dark"/>
                                    <h3 className="font-medium text-base-content">Create Your Own Glide</h3>
                                </dt>
                                <dd className="mt-2 text-base-content/70">The power of Glide extends beyond our default offerings. Feel the urge to craft a personal assistant with a specific skill set? Our platform enables you to create your very own Glide, ensuring that no niche or need is left unaddressed.</dd>
                            </div>
                            <div className="border-t border-neutral pt-4 z-40">
                                <dt className="flex items-center space-x-2">
                                    <BsBarChartFill className="w-4 h-4 text-primary-focus dark:text-primary-focus-dark"/>
                                    <h3 className="font-medium text-base-content">Improved Interaction</h3>
                                </dt>
                                <dd className="mt-2 text-base-content/70">Glides are built on the foundation of cutting-edge Super Intelligent technology, enabling them to understand your needs, learn from your preferences, and deliver responses that are not only relevant but also insightful and enriching.</dd>
                            </div>
                            <div className="border-t border-neutral pt-4 z-40">
                                <dt className="flex items-center space-x-2">
                                    <BiSolidBrain className="w-4 h-4 text-primary-focus dark:text-primary-focus-dark"/>
                                    <h3 className="font-medium text-base-content">Advanced Memory Integration</h3>
                                </dt>
                                <dd className="mt-2 text-base-content/70">Equipped with sophisticated short-term and long-term memory capabilities, enabling them to remember past interactions and build upon them. This ensures a continually improving and personalizing user experience, as your Glide recalls your preferences, interests, and even the nuances of your requests over time.</dd>
                            </div>
                            <div className="border-t border-neutral pt-4 z-40">
                                <dt className="flex items-center space-x-2">
                                    <RiBubbleChartFill className="w-4 h-4 text-primary-focus dark:text-primary-focus-dark"/>
                                    <h3 className="font-medium text-base-content">Interconnected Expertise</h3>
                                </dt>
                                <dd className="mt-2 text-base-content/70">Glide assistants are not only experts in their individual domains but can also collaborate with one another to provide comprehensive insights and solutions. Whether you are planning a trip that needs insights into health precautions, or looking for a historical perspective on a piece of art, Glides communicate with each other seamlessly to bring you enriched, multi-dimensional advice.</dd>
                            </div>
                        </dl>
                    </div>
                    <div className="max-w-7xl mx-auto">
                        <h2 className="text-xl text-base-content font-medium">Turning Curiosity Into Mastery</h2>
                        <div className="text-base-content/70">
                            <p className="mt-2">
                                With Glide, your quest for knowledge and efficiency doesn&apos;t just meet expectations—it surpasses them. Glide is more than an assistant; it&apos;s a companion, a guide, and a gateway to mastering your world. Whether it&apos;s navigating the day-to-day, exploring your passions, or expanding your horizons, Glide is here to make every journey rewarding.
                            </p>
                            <br/>
                            <p>
                                Dovise&apos;s Glides are more than just virtual assistants; they&apos;re your partners in exploration, learning, and productivity. By briditing expertise across various domains into your hands, we&apos;re not just providing answers; we&apos;re opening doors to new opportunities and experiences.
                            </p>
                            <br/>
                            <p>
                                Embrace the future with Glides and turn every interaction into a stepping stone towards mastery. Become a subscriber today and unlock the full potential of personalized assistance, crafted to make your life more informed, efficient, and exciting.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GlidesProducts;
