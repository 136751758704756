import React from "react";
import PropTypes from "prop-types";

import {ShellMobileSideBar} from "./ShellMobileSideBar";


const Shell = ({ children }) => {

    return (
        <>
            <ShellMobileSideBar />

            <div className="w-full max-h-dvh h-dvh overflow-hidden hide-scrollbar">
                {children}
            </div>
        </>
    )
};

Shell.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Shell;
