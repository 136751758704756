import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const paymentMethodDELETE = createAsyncThunk(
    'payments/paymentMethod/delete',
    async (paymentMethodId, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'DELETE',
                    path: `${process.env.REACT_APP_DELETE_STRIPE_PAYMENT_METHOD_PATH}/${paymentMethodId}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    paymentMethodDeletionVF: false,
    paymentMethodDeletionApiState: 'idle',
    paymentMethodDeletionResponse: null,
    paymentMethodDeletionError: null,
};

export const deletePaymentMethodSlice = createSlice({
        name: 'paymentMethodDeletion',
        initialState: INITIAL_STATE,
        reducers: {
            clearPaymentMethodDeletionVF: (state) => {
                state.paymentMethodDeletionVF = false;
            },
            resetPaymentMethodDeletion: (state) => {
                state.paymentMethodDeletionVF = false,
                state.paymentMethodDeletionApiState = 'idle',
                state.paymentMethodDeletionResponse = null,
                state.paymentMethodDeletionError = null
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(paymentMethodDELETE.pending, (state) => {
                    state.paymentMethodDeletionApiState = 'loading';
                    state.paymentMethodDeletionVF = true;
                })
                .addCase(paymentMethodDELETE.fulfilled, (state, action) => {
                    state.paymentMethodDeletionApiState = 'succeeded';
                    state.paymentMethodDeletionResponse = action.payload;
                    state.paymentMethodDeletionVF = true;
                    state.paymentMethodDeletionError = null;
                })
                .addCase(paymentMethodDELETE.rejected, (state, action) => {
                    state.paymentMethodDeletionApiState = 'failed';
                    state.paymentMethodDeletionError = action?.payload;
                    state.paymentMethodDeletionVF = true;
                    state.paymentMethodDeletionResponse = null;
                })
                .addDefaultCase((state, action) => {
                    if (action.type === 'RESET') {
                        return INITIAL_STATE;
                    }
                });
        }
    }
);

export const {
    clearPaymentMethodDeletionVF,
    resetPaymentMethodDeletion
} = deletePaymentMethodSlice.actions;

export default deletePaymentMethodSlice.reducer;
