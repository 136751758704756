import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const singleTgConversationDELETE = createAsyncThunk(
    'taskGlide/conversation/delete',
    async ({ conversation_id }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'DELETE',
                    path: `${process.env.REACT_APP_TASKGLIDE_GET_DELETE_SINGLE_CONVO_PATH}${conversation_id}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    tgSingleConversationDeleteVF: false,
    tgSingleConversationDeleteApiState: 'idle',
    tgSingleConversationDeleteResponse: null,
    tgSingleConversationDeleteError: null,
};

export const deleteSingleTgConversationSlice = createSlice({
    name: 'deleteSingleTGConversation',
    initialState: INITIAL_STATE,
    reducers: {
        clearTgSingleConversationDeleteVF: (state) => {
            state.tgSingleConversationDeleteVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(singleTgConversationDELETE.pending, (state) => {
                state.tgSingleConversationDeleteApiState = 'loading';
                state.tgSingleConversationDeleteVF = true;
            })
            .addCase(singleTgConversationDELETE.fulfilled, (state, action) => {
                state.tgSingleConversationDeleteApiState = 'succeeded';
                state.tgSingleConversationDeleteResponse = action.payload;
                state.tgSingleConversationDeleteVF = true;
                state.tgSingleConversationDeleteError = null;
            })
            .addCase(singleTgConversationDELETE.rejected, (state, action) => {
                state.tgSingleConversationDeleteApiState = 'failed';
                state.tgSingleConversationDeleteError = action?.payload;
                state.tgSingleConversationDeleteVF = true;
                state.tgSingleConversationDeleteResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTgSingleConversationDeleteVF
} = deleteSingleTgConversationSlice.actions;

export default deleteSingleTgConversationSlice.reducer;
