import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import PropTypes from "prop-types";

import {useUpdateTaskGlideSettings} from "../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useUpdateTaskGlideSettings";
import routes from "../../../../../../Routes/routes";
import config from "../../../../../../config";
import DoviseLoader from "../../../../../../Components/DoviseLoader/DoviseLoader";


const GoogleOAuthRedirectHandler = () => {
    const navigate = useNavigate();
    const { updateTaskGlideSettings } = useUpdateTaskGlideSettings();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            exchangeCodeForToken(code);
        }

        navigate(`${routes.auth.taskGlide.home.href}?settings=open`)
    }, []);

    const exchangeCodeForToken = async (code) => {
        try {
            const tokenResponse = await fetch('https://oauth2.googleapis.com/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    code: code,
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
                    redirect_uri: config.googleOAuth2RedirectUrl,
                    grant_type: 'authorization_code',
                }),
            });

            const tokenData = await tokenResponse.json();
            const accessToken = tokenData.access_token;
            const refreshToken = tokenData.refresh_token;
            fetchUserEmail(accessToken, refreshToken);
        } catch (error) {
            console.error('Error exchanging code for token', error);
        }
    };

    const fetchUserEmail = async (accessToken, refreshToken) => {
        try {
            const userinfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            const userInfo = await userinfoResponse.json();
            const userEmail = userInfo.email;

            // Update backend with the Google account details
            updateTaskGlideSettings({
                google_account: userEmail,
                google_access_token: accessToken,
                google_refresh_token: refreshToken,
                access_google_account: true,
            });

            console.log('User Email:', userEmail);
        } catch (error) {
            console.error('Error fetching user email', error);
        }
    };

    return (
        <div className="flex justify-center items-center h-screen w-full max-w-7xl mx-auto">
            <DoviseLoader size="65" speed="1.75" lightColor="#1e293b" darkColor="#e2e8f0" />
        </div>
    );
};

GoogleOAuthRedirectHandler.propTypes = {
    updateTaskGlideSettings: PropTypes.func.isRequired,
};

export default GoogleOAuthRedirectHandler;
