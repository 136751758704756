import React, {Fragment, useEffect, useState} from "react";
import {HiOutlineMagnifyingGlass} from "react-icons/hi2";
import {Field, Form, Formik} from "formik";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {motion} from "framer-motion";
import * as yup from "yup";
import clsx from "clsx";

import {useGetUserGlideDetails} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useGetUserGlideDetails";
import {useListGlideTools} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useListGlideTools";
import Nav from "../../../../../Shell/Nav/Nav";
import routes from "../../../../../../../Routes/routes";
import GlideToolsStackList from "./GlideToolsStackList";
import {useUpdateUserGlide} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useUpdateUserGlide";
import {glideColorClasses, availableColorsList} from "../../../Chat/ChatBox/Messages/glideInfo";
import GlideCreatorSetupSkeleton from "./GlideCreatorSetupSkeleton";
import {CgSpinner} from "react-icons/cg";


const ExistingGlideCreatorSetup = () => {
    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();
    const {uuid} = useParams();
    const {
        getUserDefinedGlide, userDefinedGlideRetrievalResponse, userDefinedGlideRetrievalVF
    } = useGetUserGlideDetails({ vfExists: true });
    const glide = userDefinedGlideRetrievalResponse;
    const {
        listGlideTools, glideToolsListingResponse, glideToolsListingVF
    } = useListGlideTools({ vfExists: true });
    const {
        updateUserDefinedGlide, userDefinedGlideUpdateApiState, userDefinedGlideUpdateVF
    } = useUpdateUserGlide({ vfExists: true, autoUpdate: true, uuid: uuid });

    const [searchGlideTools, setSearchGlideTools] = useState('');
    const [showAllGlideTools, setShowAllGlideTools] = useState(false);

    const filteredGlideTools = glideToolsListingResponse
        ?.filter(tool => tool?.name_tag
            ?.toLowerCase()
            ?.includes(searchGlideTools.toLowerCase()));

    const displayedGlideTools = showAllGlideTools
        ? filteredGlideTools
        : filteredGlideTools?.slice(0, 5);

    useEffect(() => {
        getUserDefinedGlide(uuid);
    }, [uuid]);

    const handleSearchGlideToolsChange = (event) => {
        setSearchGlideTools(event.target.value);
    };

    useEffect(() => {
        listGlideTools();
    }, []);

    useEffect(() => {
        if (userDefinedGlideUpdateApiState === "succeeded" && !userDefinedGlideUpdateVF) {
            navigate(routes.auth.taskGlide.glideTest.href.replace(':uuid', uuid));
        }
    }, [userDefinedGlideUpdateApiState, userDefinedGlideUpdateVF]);

    const taskGlideNav = [
        { name: routes.auth.taskGlide.existingGlideSetup.label, href:`/task-glide/glides/${uuid}/setup` },
        { name: routes.auth.taskGlide.glideTest.label, href:`/task-glide/glides/${uuid}/test` },
    ];

    const matchPath = (template, path) => {
        const regex = new RegExp(template.replace(':uuid', '[a-f0-9-]+'));
        return regex.test(path);
    };

    const updatedGlideCreatorNav = taskGlideNav.map(item => ({
        ...item,
        current: matchPath(item.href, window.location.pathname),
    }));

    const pages = [
        {
            name: routes.auth.taskGlide.glideStore.label,
            href: routes.auth.taskGlide.glideStore.href
        },
        {
            name: "Glide Creator",
            href:`/task-glide/glides/${uuid}/setup`,
        },
    ];

    return (
        <>
            <Nav/>
            <div className="bg-base-100 sticky top-0 right-0 left-0 lg:left-16 border-b border-neutral-focus/50 dark:border-neutral-focus-dark/50 z-[2]">
                <div className="md:flex md:items-center md:justify-between md:space-y-0 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="text-sm breadcrumbs font-medium py-4">
                        <ul>
                            {pages.map((page, index) => (
                                <li key={page.name}>
                                    <Link
                                        to={page.href}
                                        className={path === page.href ? "hover:no-underline text-primary" : "hover:no-underline text-base-content/70"}
                                        aria-current={path === page.href ? "page" : undefined}
                                    >
                                        {page.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <header className="flex items-center sticky w-full top-14 h-14 bg-base-100 ring-1 ring-neutral z-[25]">
                <nav className="overflow-x-auto hide-scrollbar w-full mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <ul className="flex min-w-full flex-none space-x-4 py-2 text-sm font-semibold leading-6">
                        {updatedGlideCreatorNav?.map((item) => (
                            <Link
                                key={item?.href}
                                to={item?.href}
                                className={clsx(item.current ? 'bg-base-200 text-base-300' : 'hover:bg-base-200', 'inline-flex items-center rounded-md py-1.5 px-3 text-sm font-medium whitespace-nowrap text-base-content')}
                            >
                                {item?.name}
                            </Link>
                        ))}
                    </ul>
                </nav>
            </header>
            <div className="relative h-[calc(100dvh-163px)] max-h-[calc(100dvh-163px)] overflow-hidden">
                <Formik
                    initialValues={{
                        is_public: glide?.is_public || false,
                        color: availableColorsList.find(color => color?.name === glide?.color) || '',
                        name: glide?.name || '',
                        description: glide?.description || '',
                        instructions: glide?.instructions || '',
                        added_tools: glide?.added_tools.map(tool => tool.uuid) || [],
                    }}
                    validationSchema={yup.object().shape({
                        is_public: yup.boolean().required("Required"),
                        color: yup.object().shape({
                            id: yup.number().required("Please choose a color"),
                            name: yup.string().required("Please choose a color"),
                        }),
                        name: yup.string().required("Required"),
                        instructions: yup.string().required("Required"),
                    })}
                    onSubmit={(values) => {
                        console.log('Form submitted with:', {
                            ...values,
                            color: values.color.name,
                            added_tools: glide?.added_tools.map(tool => tool.uuid) || [],
                        });
                        updateUserDefinedGlide(uuid, {
                            ...values,
                            color: values.color.name,
                            added_tools: values.added_tools.map(toolUUID => toolUUID),
                        });
                    }}
                >
                    {({setFieldValue, values, isSubmitting}) => {
                        const handleColorSelect = (color) => {
                            setFieldValue('color', color);
                        };

                        const handleCheckboxChange = (uuid, isChecked) => {
                            const updatedTools = isChecked
                                ? [...values.added_tools, uuid]
                                : values.added_tools.filter(id => id !== uuid);

                            setFieldValue('added_tools', updatedTools);
                        };

                        if (userDefinedGlideRetrievalVF || glideToolsListingVF) {
                            return <GlideCreatorSetupSkeleton/>;
                        }
                        return (
                            <Form className="h-full overflow-y-auto scrollbar">
                                <motion.div
                                    initial={{opacity: 0, y: -10}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{duration: 0.5}}
                                    className="w-full"
                                >
                                    <div className="border-b border-neutral">
                                        <div className="grid grid-cols-1 gap-x-20 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                                            <div className="col-span-full md:col-span-4">
                                                <h2 className="text-base font-semibold leading-7 text-base-content">
                                                    Basics
                                                </h2>
                                                <p className="mt-1 text-sm leading-6 text-base-content/70">
                                                    Your personal information will be used to give you a personalized experience in your interactions with the assistants.
                                                </p>
                                            </div>
                                            <div className="relative md:col-start-5 md:col-span-5 lg:col-start-6 grid grid-cols-1 gap-y-5">
                                                <div className="relative col-span-full flex w-full gap-x-4">
                                                    <div className="flex h-6 items-center">
                                                        <Field
                                                            name="is_public"
                                                            type="checkbox"
                                                            className="standard-checkbox"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="is_public" className="font-medium text-base-content">
                                                            Public
                                                        </label>
                                                        <p className="text-base-content/70">If set to public, your Glide will be available to all users.</p>
                                                    </div>
                                                </div>
                                                <div className="group relative col-span-full space-y-3 py-1">
                                                    <label htmlFor="color" className="block text-sm font-medium text-base-content">
                                                        Choose a color
                                                    </label>
                                                    <div className="grid grid-rows-3 grid-flow-col gap-y-3 justify-items-center p-1 rounded-md">
                                                        {availableColorsList.map((color) => (
                                                            <button
                                                                type="button"
                                                                key={color.id}
                                                                value={color.name}
                                                                onClick={() => handleColorSelect(color)}
                                                                className={clsx(
                                                                    glideColorClasses[color?.name]?.bg,
                                                                    "group/color relative h-8 w-8 flex cursor-pointer items-center justify-center rounded-full p-0.5",
                                                                    "focus:outline-none focus-visible:ring focus-visible:ring-info/40 focus-visible:outline-none dark:focus-visible:ring-info/40 focus-visible:rounded-full focus-visible:ring-offset-1 focus-visible:ring-offset-base-100",
                                                                    values.color?.name === color.name && "ring ring-primary outline-none dark:ring-primary rounded-full ring-offset-1 ring-offset-base-100"
                                                                )}>
                                                                <div className="top-tooltips bottom-12 group-hover/color:lg:block capitalize">{color?.name}</div>
                                                            </button>
                                                        ))}
                                                    </div>
                                                    <div className="pt-4 group relative col-span-full grid grid-cols-7 items-center">
                                                        <label htmlFor="glide-name" className="col-span-2 text-sm">
                                                            Name
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="name"
                                                            autoComplete="glide-name"
                                                            placeholder="Name of your Glide"
                                                            className="input-field col-span-5"
                                                        />
                                                    </div>
                                                    <div className="pt-4 group relative col-span-full grid grid-cols-7 items-start">
                                                        <label htmlFor="description" className="col-span-2 text-sm">
                                                            Description
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="description"
                                                            placeholder="A basic description for your Glide"
                                                            className="input-field col-span-5"
                                                        />
                                                    </div>
                                                    <div className="pt-4 group relative col-span-full grid grid-cols-7 items-start">
                                                        <label htmlFor="instructions" className="col-span-2 text-sm">
                                                            Instructions
                                                        </label>
                                                        <Field
                                                            as="textarea"
                                                            name="instructions"
                                                            placeholder="You think step-by-step and you are an expert at..."
                                                            className="m-0 resize-none h-[120px] overflow-y-auto appearance-none input-field col-span-5 w-full"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-b border-neutral">
                                        <div className="col-span-full grid grid-cols-1 md:grid-cols-9 gap-y-3 py-14 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                                            <div className="col-span-full md:col-span-4">
                                                <h2 className="text-base font-semibold leading-7 text-base-content">
                                                    Capabilities
                                                </h2>
                                                <p className="mt-1 text-sm leading-6 text-base-content/70">
                                                    Add desired capabilities to your Glide in order to supercharge it with access to external information, services, and tools.
                                                </p>
                                            </div>
                                            <div className="relative md:col-start-6 lg:col-start-7 md:col-span-4">
                                                <div className="col-span-full row-span-3 md:col-span-1 px-1 py-2 overflow-y-auto scrollbar">
                                                    <label htmlFor="search" className="sr-only">
                                                        Search
                                                    </label>
                                                    <div className="relative group">
                                                        <div className="absolute inset-y-0 left-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                            <HiOutlineMagnifyingGlass className="h-4 w-4 text-base-300" aria-hidden="true"/>
                                                        </div>
                                                        <input
                                                            id="search"
                                                            name="search"
                                                            value={searchGlideTools}
                                                            onChange={handleSearchGlideToolsChange}
                                                            className="pl-9 input-field dark:bg-base-100"
                                                            placeholder="Search Capability"
                                                            type="text"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-full h-full">
                                                <GlideToolsStackList
                                                    glideTools={displayedGlideTools}
                                                    values={values}
                                                    onCheckboxChange={handleCheckboxChange}
                                                    showAllGlideTools={showAllGlideTools}
                                                    setShowAllGlideTools={setShowAllGlideTools}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <motion.div
                                        initial={{opacity: 0, y: 10}}
                                        animate={{opacity: 1, y: 0}}
                                        transition={{duration: 0.5}}
                                        className="col-span-full row-span-1 w-full py-8 px-4 sm:px-6 lg:px-8 h-fit place-self-end mx-auto max-w-5xl">
                                        <div className="flex items-center justify-end gap-x-3">
                                            <Link
                                                to={routes.auth.taskGlide.myGlides.href}
                                                className="standard-button"
                                            >
                                                Back
                                            </Link>
                                            <button
                                                type="submit"
                                                className="primary-button"
                                            >
                                                {(userDefinedGlideUpdateVF || isSubmitting) ?
                                                    <CgSpinner className="animate-spin h-5 w-5 inline-block"/> :
                                                    <p>Save and Test</p>}
                                            </button>
                                        </div>
                                    </motion.div>
                                </motion.div>
                            </Form>
                        )}}
                </Formik>
            </div>
        </>
    );
}

ExistingGlideCreatorSetup.propTypes = {};

export default ExistingGlideCreatorSetup;
