import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {useGetSingleTgConversation} from "./useGetSingleTgConversation";
import {clearTaskGlideMessageDeletionVF, resetTaskGlideMessageDeletionState, taskGlideMessageDELETE} from "../../../../../api/features/superIntelligence/taskGlide/conversations/deleteTaskGlideMessageSlice";
import {useListAllTgConversations} from "./useListAllTgConversations";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {useGetCurrentTgConversation} from "../frontend/useGetCurrentTgConversation";


export const useDeleteTaskGlideMessage = (
    {vfExists=false, autoUpdate=false} = {}
) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();
    const { getSingleTGConversation } = useGetSingleTgConversation();
    const { listAllTgConversations } = useListAllTgConversations();
    const { currentTgConversation } = useGetCurrentTgConversation();

    const {
        taskGlideMessageDeletionVF,
        taskGlideMessageDeletionApiState,
        taskGlideMessageDeletionResponse,
        taskGlideMessageDeletionError,
    } = useSelector(state => state.deleteTaskGlideMessageReducer);

    const taskGlideMessageDeleteSuccessVF = taskGlideMessageDeletionApiState === 'succeeded' && taskGlideMessageDeletionVF;
    const taskGlideMessageDeleteFailureVF = taskGlideMessageDeletionApiState === 'failed' && taskGlideMessageDeletionVF;

    const deleteTaskGlideMessage = (interaction_id, is_user_message) => {
        userIsAuthenticated && dispatch(taskGlideMessageDELETE({interaction_id, is_user_message}))
    };

    useEffect(() => {
        if (vfExists) {
            if (taskGlideMessageDeleteSuccessVF) {
                dispatch(clearTaskGlideMessageDeletionVF());
            } else if (taskGlideMessageDeleteFailureVF) {
                dispatch(setAlert({
                    message: taskGlideMessageDeletionError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearTaskGlideMessageDeletionVF());
                }, 1000);

                return () => clearTimeout(timer);
            }
        }
    }, [taskGlideMessageDeletionApiState, taskGlideMessageDeletionVF]);

    useEffect(() => {
        if (autoUpdate && taskGlideMessageDeletionApiState === 'succeeded' && !taskGlideMessageDeletionVF && currentTgConversation?.conversation_uuid) {
            getSingleTGConversation(currentTgConversation?.conversation_uuid);
            listAllTgConversations();
            dispatch(resetTaskGlideMessageDeletionState());
        }
    }, [taskGlideMessageDeletionApiState, taskGlideMessageDeletionVF]);

    return {
        deleteTaskGlideMessage,
        taskGlideMessageDeletionVF,
        taskGlideMessageDeletionApiState,
        taskGlideMessageDeletionResponse,
        taskGlideMessageDeletionError,
        taskGlideMessageDeleteSuccessVF,
        taskGlideMessageDeleteFailureVF,
    };
};
