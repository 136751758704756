import React from "react";


const TaskGlideEmptyStateSkeleton = () => {

    return (
        <div className="relative flex flex-col justify-center h-full text-base-300/50 space-y-7 p-4 sm:p-6 w-full mx-auto">
            <div className="group/selected-glides absolute top-5 right-5 z-40" >
                <div className="flex relative w-fit">
                    <div className="skeleton bg-[#E0E3E6] dark:bg-[#282828] w-7 h-7 rounded-full shadow-sm shadow-gray-400/50 dark:shadow-black/50" />
                    <div className="skeleton bg-[#E0E3E6] dark:bg-[#282828] w-7 h-7 rounded-full shadow-sm shadow-gray-400/50 dark:shadow-black/50 -ml-1.5"/>
                    <div className="skeleton bg-[#E0E3E6] dark:bg-[#282828] w-7 h-7 rounded-full shadow-sm shadow-gray-400/50 dark:shadow-black/50 -ml-1.5"/>
                </div>
            </div>
            <div className="space-y-4 hidden sm:block w-full mx-auto">
                <div className="skeleton bg-base-300/30 h-7 w-32 rounded-md" />
                <div className="skeleton bg-base-300/30 h-5 w-full rounded-md"/>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex flex-col bg-base-200 rounded-xl p-5 space-y-5 w-full mx-auto">
                    <div className="skeleton bg-base-300/30 h-5 w-24 rounded-md"/>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <div className="skeleton bg-base-300/30 flex items-center justify-center rounded-xl w-full h-9"/>
                        <div className="skeleton bg-base-300/30 flex items-center justify-center rounded-xl w-full h-9"/>
                    </div>
                </div>
                <div className="flex flex-col bg-base-200 rounded-xl p-5 space-y-5 w-full mx-auto">
                    <div className="skeleton bg-base-300/30 h-5 w-24 rounded-md"/>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <div className="skeleton bg-base-300/30 flex items-center justify-center rounded-xl w-full h-9"/>
                        <div className="skeleton bg-base-300/30 flex items-center justify-center rounded-xl w-full h-9"/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TaskGlideEmptyStateSkeleton;
