import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {setTestConversation} from "../../../../../api/features/frontend/taskGlideUiActionsSlice";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearTestConversationRetrievalVF, resetTestConversationRetrieval, testConversationGET} from "../../../../../api/features/superIntelligence/taskGlide/conversations/getTestConversationSlice";
import {singleTgConversationGET} from "../../../../../api/features/superIntelligence/taskGlide/conversations/getSingleTgConversationSlice";


export const useGetTestConversation = (
    {vfExists=false, autoSelectPostFetch=false} = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Task Glide
    const {
        testConversationRetrievalVF,
        testConversationRetrievalApiState,
        testConversationRetrievalResponse,
        testConversationRetrievalError,
    } = useSelector(state => state.getTestConversationReducer);

    // API states with visual feedback
    const testConversationRetrievalSuccessVF = testConversationRetrievalApiState === 'succeeded' && testConversationRetrievalVF;
    const testConversationRetrievalFailureVF = testConversationRetrievalApiState === 'failed' && testConversationRetrievalVF;

    const getTestConversation = (glide_uuid, page_size = 5) => {
        if (userIsAuthenticated) {
            dispatch(testConversationGET({ glide_uuid, page_size }));
        }
    };

    useEffect(() => {
        if (vfExists) {
            if (testConversationRetrievalSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearTestConversationRetrievalVF());
                }, 100);

                return () => clearTimeout(timer);
            } else if (testConversationRetrievalFailureVF) {
                dispatch(setAlert({
                    message: testConversationRetrievalResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetTestConversationRetrieval());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [testConversationRetrievalApiState, testConversationRetrievalVF]);

    useEffect(() => {
        if (autoSelectPostFetch && testConversationRetrievalApiState === 'succeeded' && !testConversationRetrievalVF) {
            dispatch(setTestConversation(testConversationRetrievalResponse));
            dispatch(resetTestConversationRetrieval());
        }
    }, [testConversationRetrievalApiState, testConversationRetrievalVF]);

    return {
        getTestConversation,
        testConversationRetrievalVF,
        testConversationRetrievalApiState,
        testConversationRetrievalResponse,
        testConversationRetrievalError,
        testConversationRetrievalSuccessVF,
        testConversationRetrievalFailureVF
    };
};
