import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {CgSpinner} from "react-icons/cg";
import {Field, Form, Formik} from "formik";
import * as yup from "yup";
import clsx from "clsx";

import routes from "../../../Routes/routes";
import {companyLogo} from "../../../Design/companyLogo";
import Alerts from "../../../Components/Alerts/Alerts";
import {resetPasswordReset} from "../../../api/features/users/passwordResetSlice";
import {useResetPassword} from "../../../Hooks/apiHooks/users/useResetPassword";
import {useRegister} from "../../../Hooks/apiHooks/users/useRegister";
import {setAlert, incrementAlertKey} from "../../../api/features/frontend/alertSlice";
import {useGetRegistrationStatus} from "../../../Hooks/apiHooks/users/useGetRegistrationStatus";
import {clearRegistrationVF, resetRegistration} from "../../../api/features/users/registrationSlice";
import {useAuthentication} from "../../../Hooks/apiHooks/users/useAuthentication";
import {useAlert} from "../../../Hooks/frontendHooks/useAlert";


const initialValues = {
    username: "",
    password: "",
}

const validationSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required')
});

const Login = () => {
    const dispatch = useDispatch();
    const {alert, alertKey} = useAlert();

    const {
        login,
        authenticationLoadingVF,
        authenticationFailureVF,
    } = useAuthentication({ vfExists: true });
    const {passwordResetSuccessVF} = useResetPassword({ vfExists: true });
    const {registrationStatusVF} = useGetRegistrationStatus({ vfExists: true });
    const {registrationSuccessVF, registrationApiState, registrationVF} = useRegister();
    const {passwordResetApiState} = useResetPassword();

    useEffect(() => {
        if (registrationApiState === 'succeeded' && !registrationVF) {
            dispatch(resetRegistration());
        }
    }, [registrationApiState, registrationVF]);

    useEffect(() => {
        if (registrationSuccessVF) {
            dispatch(setAlert({
                message: "Please check your email for a confirmation link.",
                type: "success"
            }));
            dispatch(incrementAlertKey());

            const timer = setTimeout(() => {
                dispatch(clearRegistrationVF());
            }, 4000);
            return () => clearTimeout(timer);
        }

        // If password reset fails, show password reset failed alert
        if (passwordResetApiState === "succeeded") {
            dispatch(setAlert({
                message: "Please login with your new password.",
                type: "success",
            }))
            dispatch(incrementAlertKey());

            // resetting reset password state after 5 seconds to prevent alert from showing up again
            const timer = setTimeout(() => {
                dispatch(resetPasswordReset());
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [passwordResetApiState, registrationApiState]);

    const onSubmit = (values, { setSubmitting, setFieldValue }) => {
        login(values);
        setSubmitting(false);
        setFieldValue('password', '');
    };

    return (
        <>
            <div className="flex min-h-screen flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 bg-base-100 space-y-10">
                <div className="mx-auto w-full max-w-sm space-y-6">
                    <Link to={routes.unAuth.home.href} className="outline:none focus:outline-none">
                        <span className="sr-only">Dovise</span>
                        <img
                            className="mx-auto h-10 w-auto transition-transform hover:scale-110 duration-500"
                            src={companyLogo}
                            alt="Your Company"
                        />
                    </Link>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-base-content">
                        Sign in to your account
                    </h2>
                </div>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {({isSubmitting, isValid, values}) => (
                        <Form className="mx-auto w-full max-w-sm space-y-6">
                            <div className="space-y-2">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-base-content">
                                    Username
                                </label>
                                <Field
                                    type="text"
                                    name="username"
                                    autoComplete="username"
                                    required
                                    className="input-field"
                                />
                            </div>
                            <div className="space-y-2">
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-base-content">
                                        Password
                                    </label>
                                    <div className="text-sm leading-6">
                                        <Link
                                            to={routes.unAuth.forgotPassword.href}
                                            onClick={() => dispatch({type: "RESET_USER_MANAGEMENT"})}
                                            className="font-medium text-primary hover:text-primary-focus dark:hover:text-primary-focus-dark outline:none focus:outline-none"
                                        >
                                            Forgot password?
                                        </Link>
                                    </div>
                                </div>
                                <Field
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="input-field"
                                />
                            </div>
                            <button
                                type="submit"
                                disabled={
                                    !isValid || isSubmitting || authenticationLoadingVF || values.username === "" || values.password === "" || registrationStatusVF}
                                className="primary-button w-full"
                            >
                                <CgSpinner
                                    className={clsx(
                                        "absolute inset-0 m-auto animate-spin h-5 w-5",
                                        (authenticationLoadingVF || isSubmitting) ? "" : "hidden"
                                    )}
                                    aria-hidden="true"
                                />
                                <p className={(authenticationLoadingVF || isSubmitting) ? "invisible" : ""}>
                                    Sign in
                                </p>
                            </button>
                        </Form>
                    )}
                </Formik>

                <div className="mx-auto w-fit max-w-sm space-y-1 text-center text-sm text-base-content/70 leading-6">
                    <div className="space-x-1" >
                        <p className="inline" >Don&apos;t have an account yet?</p>
                        <Link
                            to={routes.unAuth.register.href}
                            onClick={() => dispatch({type: "RESET_USER_MANAGEMENT"})}
                            className="inline font-medium text-primary hover:text-primary-focus dark:hover:text-primary-focus-dark outline:none focus:outline-none">
                            Start for free
                        </Link>
                    </div>
                    <div className="space-x-1" >
                        <p className="inline" >Did&apos;t receive verification email?</p>
                        <Link
                            to={routes.unAuth.accountVerification.href}
                            onClick={() => dispatch({type: "RESET_USER_MANAGEMENT"})}
                            className="inline font-medium text-primary hover:text-primary-focus dark:hover:text-primary-focus-dark outline:none focus:outline-none">
                            Try again
                        </Link>
                    </div>
                </div>
            </div>
            {(authenticationFailureVF || registrationSuccessVF || passwordResetSuccessVF) &&
                <Alerts message={alert?.message} type={alert.type} keyValue={alertKey}/>}
        </>
    )
};

export default Login;
