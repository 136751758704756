import PropTypes from "prop-types";
import React, {useEffect} from "react";

import {useGetRegistrationStatus} from "../../Hooks/apiHooks/users/useGetRegistrationStatus";
import {useAuthentication} from "../../Hooks/apiHooks/users/useAuthentication";
import SearchCommand from "../../Components/SearchCommand/SearchCommand";
import DoviseLoader from "../../Components/DoviseLoader/DoviseLoader";


export const UserManagementUnAuthWrapper = ({ page: Page }) => {
    const {getRegistrationStatus, registrationStatusApiState} = useGetRegistrationStatus();
    const {userIsAuthenticated, authenticationSuccessVF} = useAuthentication({ vfExists: true });

    useEffect(() => {
        if (userIsAuthenticated && registrationStatusApiState === 'idle') {
            getRegistrationStatus();
        }
    }, [userIsAuthenticated, registrationStatusApiState]);

    if (userIsAuthenticated || authenticationSuccessVF) {
        return (
            <div className="flex justify-center items-center h-screen w-full max-w-7xl mx-auto">
                <DoviseLoader size="65" speed="1.75" lightColor="#1e293b" darkColor="#e2e8f0" />
            </div>
        );
    }
    return (
        <>
            <SearchCommand />
            <Page/>
        </>
    )
};

UserManagementUnAuthWrapper.propTypes = {
    page: PropTypes.elementType.isRequired,
}


