import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {clearPaymentCreationVF, paymentMethodCREATE, resetPaymentCreation} from "../../../api/features/payments/createPaymentMethodSlice";
import {useAuthentication} from "../users/useAuthentication";
import {useListPaymentMethods} from "./useListPaymentMethods";


export const useCreatePaymentMethod = ({vfExists=false, refreshAndReset=true} = {}) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Payment Method
    const { listPaymentMethods } = useListPaymentMethods();
    const {
        paymentCreationVF,
        paymentCreationApiState,
        paymentCreationResponse,
        paymentCreationError,
    } = useSelector(state => state.createPaymentMethodReducer);

    // API State with visual feedback
    const paymentCreationSuccessVF = paymentCreationApiState === 'succeeded' && paymentCreationVF;
    const paymentCreationFailureVF = paymentCreationApiState === 'failed' && paymentCreationVF;
    const paymentCreationLoadingVF = paymentCreationApiState === 'loading' && paymentCreationVF;

    const createPaymentMethod = (values) => userIsAuthenticated && dispatch(paymentMethodCREATE(values));

    useEffect(() => {
        if (vfExists) {
            if (paymentCreationSuccessVF) {
                dispatch(setAlert({
                    message: paymentCreationResponse?.message,
                    type: "success"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearPaymentCreationVF());
                }, 1000);

                return () => clearTimeout(timer);
            } else if (paymentCreationFailureVF) {
                dispatch(setAlert({
                    message: paymentCreationError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearPaymentCreationVF());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [paymentCreationApiState]);

    useEffect(() => {
        if (refreshAndReset && paymentCreationApiState === 'succeeded') {
            // If payment method is created successfully, then we can refresh the payment method list and reset the creation states
            if (!paymentCreationVF) {
                listPaymentMethods();
                dispatch(resetPaymentCreation());
            }
        }
    }, [paymentCreationApiState, paymentCreationVF]);

    return {
        createPaymentMethod,
        paymentCreationVF,
        paymentCreationLoadingVF,
        paymentCreationSuccessVF,
        paymentCreationFailureVF,
        paymentCreationApiState,
        paymentCreationResponse
    };
};

