import React from "react";
import PropTypes from "prop-types";


const UserGlideItem = ({ isDefault, animationDelay }) => {
    const delay = (ms) => ({animationDelay: `${ms}ms`});

    return (
        <div className="relative bg-base-100" style={animationDelay ? delay(animationDelay) : {}}>
            <div className="flex items-center justify-between space-x-4 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-sm w-full">
                <div className="flex items-center space-x-4 text-start flex-1 py-4">
                    <div className="rounded-full p-3.5 bg-base-300/30 skeleton w-12 h-12 sm:w-10 sm:h-10" />
                    <div className="space-y-2.5 w-full flex-1">
                        <div className="flex items-center space-x-4">
                            <div className="bg-base-300/30 skeleton w-24 h-3 rounded" />
                            {isDefault && <div className="rounded w-12 h-4 bg-base-300/30 skeleton" />}
                        </div>
                        <div className="bg-base-300/30 skeleton h-3 w-2/3 md:w-1/2 rounded" />
                    </div>
                </div>
                <div className="flex items-center space-x-3">
                    <div className="flex flex-col items-end w-full space-y-2 text-end flex-1">
                        <div className="inline-flex items-center rounded px-1.5 py-0.5 bg-base-300/30 skeleton w-12 h-4" />
                        <div className="inline-flex rounded px-1 w-28 h-2.5 bg-base-300/30 skeleton" />
                    </div>
                    <div className="w-5 h-5 rounded-full bg-base-300/30 skeleton"/>
                </div>
            </div>
        </div>
    )
};

UserGlideItem.propTypes = {
    isDefault: PropTypes.bool,
    animationDelay: PropTypes.number,
};

UserGlideItem.defaultProps = {
    isDefault: false,
    animationDelay: null,
};


const MyGlideSkeleton = () => {
    return (
        <div className="relative flex flex-col w-full z-10 h-[calc(100dvh-210px)] md:h-[calc(100dvh-121px)] scrollbar overflow-y-auto space-y-1.5">
            <UserGlideItem isDefault={true} />
            <UserGlideItem animationDelay={350} />
            <UserGlideItem />
            <UserGlideItem animationDelay={350} />
            <UserGlideItem />
            <UserGlideItem animationDelay={350} />
        </div>
    )
};

export default MyGlideSkeleton;
