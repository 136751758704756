import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import Nav from "../../../Shell/Nav/Nav";
import FAQ from "../../TaskGlide/FAQ/FAQ";
import DoubleCalendar from "../../../../../Components/Calendar/DoubleCalendar";
import {useListCalendarDates} from "../../../../../Hooks/apiHooks/superIntelligence/memoMinder/calendar/useListCalendarDates";
import {useGetCalendarEvent} from "../../../../../Hooks/apiHooks/superIntelligence/memoMinder/calendar/useGetCalendarEvent";
import MemoMinderCalendarEvents from "./MemoMinderCalendarEvents";
import MemoMinderInputField from "./MemoMinderInputField";
import MemoMinderEventCreationModal from "./MemoMinderEventCreationModal";
import {formatDateToISO} from "../../../../../reusableFxns/dateTimeFxns";


const MemoMinderCalendar = () => {
    const {
        listCalendarDates,
        calendarDatesListingResponse
    } = useListCalendarDates({ vfExists: true });
    const {
        getCalendarEvent,
        calendarEventRetrievalVF,
        calendarEventRetrievalResponse
    } = useGetCalendarEvent({ vfExists: true });

    const [searchParams, setSearchParams] = useSearchParams();
    const today = formatDateToISO(new Date());
    const [selectedDate, setSelectedDate] = useState(searchParams.get('date') || today);

    const handleTodayClick = () => {
        setSelectedDate(today);
    };

    useEffect(() => {
        if (!calendarDatesListingResponse) {
            listCalendarDates(null, 50);
        }
    }, [calendarDatesListingResponse]);

    useEffect(() => {
        getCalendarEvent(selectedDate);
        setSearchParams({ date: selectedDate });
    }, [selectedDate]);

    const handleSingleDateClick = (date) => {
        setSelectedDate(date);
    };

    if (!calendarDatesListingResponse) {
        return 'Loading';
    }

    return (
        <>
            <Nav />
            <div className="relative h-[calc(100dvh-56px)] overflow-hidden grid grid-cols-9 max-h-[calc(100dvh-56px)]">
                <div className="col-span-full flex flex-col w-full bg-transparent z-20 max-h-[calc(100dvh-56px)]">
                    <div className="overflow-y-auto overflow-x-hidden scroll-smooth scrollbar h-full">
                        <div className="relative px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl h-full">
                            <div className="h-full w-full mx-auto max-h-[calc(100dvh-56px)]" >
                                <div className="md:sticky top-0 z-20 bg-base-100 pt-4">
                                    <DoubleCalendar
                                        calendarDates={calendarDatesListingResponse}
                                        handleDateClick={handleSingleDateClick}
                                        singleDateIsLoading={calendarEventRetrievalVF}
                                        selectedDate={selectedDate}
                                        handleTodayClick={handleTodayClick}
                                    />
                                    <div className="flex items-center gap-x-4 my-4">
                                       <MemoMinderInputField />
                                        <MemoMinderEventCreationModal />
                                        <button
                                            type="button"
                                            className="error-button"
                                        >
                                            Delete all
                                        </button>
                                    </div>
                                </div>
                                <MemoMinderCalendarEvents
                                    eventList={calendarEventRetrievalResponse}
                                    selectedDate={selectedDate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FAQ />
        </>
    );
};

export default MemoMinderCalendar;
