import imageCompression from "browser-image-compression";

import {useParseDocuments} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useParseDocuments";
import {useEffect} from "react";


export const useTaskGlideFilePhotoHandler = ({ setSelectedFiles, setParsedDocuments, selectedPhotos, setSelectedPhotos }) => {
    const {
        parseDocuments,
        parseDocumentsResponse
    } = useParseDocuments({ vfExists: true });

    const handlePhotoSelect = async (e) => {
        console.log("handlePhotoChange called");
        const files = Array.from(e.target.files);

        const compressedFiles = await Promise.all(files.map(async (file) => {
            const image = new Image();
            const URLObject = window.URL || window.webkitURL;
            image.src = URLObject.createObjectURL(file);

            await new Promise(resolve => {
                image.onload = resolve;
            });

            const aspectRatio = image.width / image.height;
            let maxWidthOrHeight;
            if (aspectRatio > 1) {
                maxWidthOrHeight = aspectRatio > (1333 / 800) ? 1333 : 800 * aspectRatio;
            } else {
                maxWidthOrHeight = aspectRatio < (800 / 1333) ? 800 : 1333 / aspectRatio;
            }

            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: maxWidthOrHeight,
                useWebWorker: true
            };

            try {
                const compressedFile = await imageCompression(file, options);
                console.log('Compression successful', compressedFile.size);
                return compressedFile;
            } catch (error) {
                console.log("Compression failed:", error);
                return file;
            }
        }));

        setSelectedPhotos(prevPhotos => [...(prevPhotos || []), ...compressedFiles]);
    };

    const handleFileSelect = async (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
            setSelectedFiles(prevFiles => (prevFiles ? [...prevFiles, ...files] : files));
            const formData = new FormData();
            files.forEach(file => {
                formData.append('documents', file);
            });
            await parseDocuments(formData);
        } else {
            console.log('No file selected');
        }
    };

    useEffect(() => {
        if (Array.isArray(parseDocumentsResponse?.documents)) {
            setParsedDocuments(prevParsedDocs => [...prevParsedDocs, ...parseDocumentsResponse.documents]);
        } else {
            console.log('parseDocumentsResponse.documents is not an array:', parseDocumentsResponse?.documents);
        }
    }, [parseDocumentsResponse]);

    const handleFileRemove = (index) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        setParsedDocuments(prevParsedDocs => prevParsedDocs.filter((_, i) => i !== index));
    };

    const handlePhotoRemove = (index) => {
        setSelectedPhotos(prevPhotos => prevPhotos.filter((_, i) => i !== index));
    };

    return {
        handleFileSelect,
        selectedPhotos,
        setSelectedPhotos,
        handlePhotoSelect,
        handleFileRemove,
        handlePhotoRemove,
    };
};
