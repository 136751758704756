import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import clsx from "clsx";
import {HiOutlineXMark} from "react-icons/hi2";
import {AiFillCheckCircle, AiFillCloseCircle, AiFillInfoCircle, AiFillWarning} from "react-icons/ai";



const Alert = ({ message, type, keyValue }) => {
    const [show, setShow] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let timer;
        let animationFrame;
        let startTime = null;
        const duration = 4000;

        const animateProgress = (timestamp) => {
            if (!startTime) {startTime = timestamp;}
            const elapsed = timestamp - startTime;
            setProgress((elapsed / duration) * 100);
            if (elapsed < duration) {
                animationFrame = requestAnimationFrame(animateProgress);
            } else {
                setShow(false);
            }
        };

        if (message) {
            setShow(true);
            setProgress(0);
            timer = setTimeout(() => {
                setShow(false);
            }, duration);
            animationFrame = requestAnimationFrame(animateProgress);
        }

        return () => {
            clearTimeout(timer);
            cancelAnimationFrame(animationFrame);
        };
    }, [message, type, keyValue]);

    if (!show) {
        return null;
    }

    return (
        <>
            <div className="fixed bottom-4 inset-x-0 px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl z-[100]">
                <div className={clsx(
                    "relative overflow-hidden flex items-center rounded-md backdrop-blur h-full w-full shadow-[0px_0px_10px_rgba(0,0,0,0.10)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.3)]",
                    type === 'success' && 'success-alert-colors',
                    type === 'error' && 'error-alert-colors',
                    type === 'warning' && 'warning-alert-colors',
                    type === 'info' && 'info-alert-colors',
                )}>
                    <div className="flex items-center justify-between w-full px-4 pt-2.5 pb-3.5" >
                        <div className="flex items-center gap-x-3">
                            <AiFillCheckCircle
                                className={clsx("h-5 w-5 shrink-0", type === 'success' ? "block" : "hidden")}
                                aria-hidden="true"
                            />
                            <AiFillCloseCircle
                                className={clsx("h-5 w-5 shrink-0", type === 'error' ? "block" : "hidden")}
                                aria-hidden="true"
                            />
                            <AiFillInfoCircle
                                className={clsx("h-5 w-5 shrink-0", type === 'info' ? "block" : "hidden")}
                                aria-hidden="true"
                            />
                            <AiFillWarning
                                className={clsx("h-5 w-5 shrink-0", type === 'warning' ? "block" : "hidden")}
                                aria-hidden="true"
                            />
                            <p className="text-sm">{message}</p>
                        </div>
                        <button
                            type="button"
                            className="inline-flex rounded-md hover:bg-base-100/30 p-0.5 button-ring-focus"
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            <HiOutlineXMark className="h-5 w-5 shrink-0" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="overflow-hidden w-full bg-base-200 absolute bottom-0">
                        <div className={clsx(
                            "h-1.5",
                            type === 'success' && 'bg-emerald-700',
                            type === 'error' && 'bg-rose-700',
                            type === 'warning' && 'bg-amber-700',
                            type === 'info' && 'bg-blue-700',
                        )} style={{width: `${progress}%`}}/>
                    </div>
                </div>
            </div>
        </>
    );
};

Alert.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
    keyValue: PropTypes.number,
};

export default Alert;
