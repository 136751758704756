import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {MdArrowOutward, MdCheck, MdPlaylistRemove} from "react-icons/md";
import {RiBox3Fill} from "react-icons/ri";
import {LuTimerReset} from "react-icons/lu";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {HiUserGroup} from "react-icons/hi2";
import {PiChatsFill} from "react-icons/pi";
import {getToolIcon} from "../../../Chat/ChatBox/Messages/glideInfo";
import {CgSpinner} from "react-icons/cg";
import {TbArrowNarrowDown} from "react-icons/tb";

import Nav from "../../../../../Shell/Nav/Nav";
import routes from "../../../../../../../Routes/routes";
import {formatUsageCount} from "../../GlideStore/GlideItem";
import {useSessionInfo} from "../../../../../../../Hooks/frontendHooks/useSessionInfo";
import {useGetUserInfo} from "../../../../../../../Hooks/apiHooks/users/useGetUserInfo";
import GenericChatInputField from "../../../../../../../Components/TaskGlide/InputField/GenericChatInputField";
import {useTaskGlideFormActions} from "../../../Chat/InputField/Fields/Hooks/useTaskGlideFormActions";
import {useListUserGlides} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useListUserGlides";
import {useGetUserGlideDetails} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useGetUserGlideDetails";
import {useStreamTaskGlide} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";
import GlideCreatorInputAdditionalButtons from "./GlideCreatorInputAdditionalButtons";
import GlideCreatorTestChatBox from "./GlideCreatorTestChatBox";
import {useGetTestConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetTestConversation";
import {setTestConversation} from "../../../../../../../api/features/frontend/taskGlideUiActionsSlice";
import {useAddOrRemoveGlideOfUserGlides} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useAddOrRemoveGlideOfUserGlides";
import {useGeneralUiActions} from "../../../../../../../Hooks/frontendHooks/useGeneralUiActions";
import {useDeleteTestConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteTestConversation";
import {resetUserDefinedGlideRetrieval} from "../../../../../../../api/features/superIntelligence/taskGlide/glides/getUserGlideDetailsSlice";
import {resetTestConversationRetrieval} from "../../../../../../../api/features/superIntelligence/taskGlide/conversations/getTestConversationSlice";
import GlideCreatorTestSkeleton from "./GlideCreatorTestSkeleton";
import {useAutoScroll} from "../../../../../../../Hooks/frontendHooks/useAutoScroll";
import DragAndDrop from "../../../Chat/ChatBox/Chat/DragAndDrop";
import {useTaskGlideFilePhotoHandler} from "../../../Chat/InputField/Fields/Hooks/useTaskGlideFilePhotoHandler";
import {useGetCurrentTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetCurrentTgConversation";


const GlideCreatorTest = () => {
    const location = useLocation();
    const path = location.pathname;
    const { uuid } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);
    const { firstRender, handleFirstRender } = useGeneralUiActions();
    const { userInfoRetrievalResponse, userInfoRetrievalVF } = useGetUserInfo();
    const { taskGlideStreamVF } = useStreamTaskGlide({ vfExists: true, isTest: true });
    const {
        getUserDefinedGlide,
        userDefinedGlideRetrievalResponse,
        userDefinedGlideRetrievalVF,
    } = useGetUserGlideDetails({ vfExists: true });
    const {
        getGlides,
        glidesListingResponse,
        glidesListingVF
    } = useListUserGlides({ vfExists: true });
    const {
        addOrRemoveGlideOfUserGlidesVF,
        addOrRemoveGlideOfUserGlidesApiState,
        addOrRemoveGlideOfUserGlides,
        addOrRemoveGlideOfUserGlidesResponse
    } = useAddOrRemoveGlideOfUserGlides({ vfExists: true, autoUpdate: true });
    const { getTestConversation, testConversationRetrievalVF } = useGetTestConversation({ vfExists: true, autoSelectPostFetch: true });
    const { currentTestTgConversation } = useGetCurrentTgConversation();
    const {
        deleteTestConversation,
        testConversationDeletionVF
    } =useDeleteTestConversation({ vfExists: true, autoUpdate: true });
    const {
        pageEndRef,
        isAutoScrollEnabled,
        setIsAutoScrollEnabled
    } = useAutoScroll();

    const isUserCreatedGlide = (glide) => glide?.user_created && glide?.created_by_username === userInfoRetrievalResponse?.username;
    const glideInMyGlides = !!glidesListingResponse?.find(item => item?.glide?.uuid === uuid);
    const isGlideTaskGlide = userDefinedGlideRetrievalResponse?.uuid === process.env.REACT_APP_TASKGLIDE_TG_UUID;
    const loadingStates = testConversationRetrievalVF || userInfoRetrievalVF || glidesListingVF || userDefinedGlideRetrievalVF || addOrRemoveGlideOfUserGlidesVF || !userDefinedGlideRetrievalResponse;
    const allUserGlideTools = (userDefinedGlideRetrievalResponse?.default_tools?.concat(userDefinedGlideRetrievalResponse?.added_tools) || []).filter(Boolean);

    const [conversationResetIsConfirmed, setConversationResetIsConfirmed] = useState(false);
    const [glideRemovalOrAdditionIsConfirmed, setGlideRemovalOrAdditionIsConfirmed] = useState(false);
    const confirmationTimer = 2000;

    const chatBoxRef = useRef(null);
    const [pageSize, setPageSize] = useState(5);

    useEffect(() => {
        dispatch(setTestConversation(null));
        dispatch(resetUserDefinedGlideRetrieval());
        dispatch(resetTestConversationRetrieval());

        getGlides();
        getTestConversation(uuid);
    }, [uuid]);

    useEffect(() => {
        getUserDefinedGlide(uuid);
    }, [uuid, addOrRemoveGlideOfUserGlidesResponse]);

    const handleInteractionPagination = () => {
        if (currentTestTgConversation?.interactions?.next && !testConversationRetrievalVF) {
            const newPageSize = pageSize + 5;
            setPageSize(newPageSize);
            if (uuid) {
                getTestConversation(uuid, newPageSize);
            }
        }
    };

    useEffect(() => {
        if (addOrRemoveGlideOfUserGlidesApiState === 'succeeded' && !addOrRemoveGlideOfUserGlidesVF && !glideInMyGlides) {
            navigate(routes.auth.taskGlide.myGlides.href);
        }
    }, [addOrRemoveGlideOfUserGlidesApiState, addOrRemoveGlideOfUserGlidesVF, glideInMyGlides]);

    const glideTestTabs = [
        { name: routes.auth.taskGlide.existingGlideSetup.label, href:`/task-glide/glides/${uuid}/setup` },
        { name: routes.auth.taskGlide.glideTest.label, href:`/task-glide/glides/${uuid}/test` },
    ];

    const matchPath = (template, path) => {
        const basePath = template.split("/:uuid")[0];
        return path.startsWith(basePath) && path.endsWith('/test');
    };

    const updatedGlideCreatorNav = glideTestTabs.map(item => ({
        ...item,
        current: matchPath(item.href, window.location.pathname),
    }));

    const sessionInfo = useSessionInfo();
    const selectedGlides = [userDefinedGlideRetrievalResponse];

    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [parsedDocuments, setParsedDocuments] = useState([]);
    const { formik, handleAbort } = useTaskGlideFormActions({
        sessionInfo,
        selectedGlides,
        isTest: true,
        selectedPhotos,
        setSelectedPhotos,
        selectedFiles,
        setSelectedFiles,
        setIsAutoScrollEnabled,
        parsedDocuments,
        setParsedDocuments
    });

    const pages = [
        {name: routes.auth.taskGlide.glideStore.label, href: routes.auth.taskGlide.glideStore.href},
        {name: "Glide Creator", href:`/task-glide/glides/${uuid}/test`},
    ];

    const handleRemoveOrAddGlide = () => {
        if (glideRemovalOrAdditionIsConfirmed) {
            addOrRemoveGlideOfUserGlides(uuid, !glideInMyGlides ? 'add' : 'remove')
        } else {
            setGlideRemovalOrAdditionIsConfirmed(true);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setGlideRemovalOrAdditionIsConfirmed(false);
        }, confirmationTimer);

        return () => clearTimeout(timer);
    }, [glideRemovalOrAdditionIsConfirmed]);

    const handleResetConversation = () => {
        if (conversationResetIsConfirmed) {
            deleteTestConversation(userDefinedGlideRetrievalResponse?.uuid)
        } else {
            setConversationResetIsConfirmed(true);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setConversationResetIsConfirmed(false);
        }, confirmationTimer);

        return () => clearTimeout(timer);
    }, [conversationResetIsConfirmed]);

    useEffect(() => {
        if (!loadingStates && pageSize <= 5) {
            handleFirstRender(1000);
            setIsAutoScrollEnabled(true);
        }
    }, [loadingStates, uuid]);

    const {
        handleFileSelect, handlePhotoSelect, handleFileRemove, handlePhotoRemove
    } = useTaskGlideFilePhotoHandler({ setSelectedFiles, setParsedDocuments, selectedPhotos, setSelectedPhotos });

    return (
        <>
            <Nav/>
            <DragAndDrop handleFileSelect={handleFileSelect} handlePhotoSelect={handlePhotoSelect} />
            <div className="bg-base-100 sticky top-0 right-0 left-0 lg:left-16 ring-[0.5px] ring-neutral shadow-sm z-30">
                <div className="md:flex md:items-center md:justify-between md:space-y-0 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="text-sm breadcrumbs font-medium py-4">
                        <ul>
                            {pages.map((page) => (
                                <li key={page.name} >
                                    <Link
                                        to={page.href}
                                        className={path === page.href ? "hover:no-underline text-primary" : "hover:no-underline text-base-content/70"}
                                        aria-current={path === page.href ? "page" : undefined}
                                    >
                                        {page.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <header className="flex items-center sticky w-full top-14 h-14 bg-base-100 ring-[0.5px] ring-neutral shadow-sm z-20">
                <nav className="overflow-x-auto hide-scrollbar w-full mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <ul className="flex min-w-full flex-none space-x-4 py-2 text-sm font-semibold leading-6">
                        {updatedGlideCreatorNav?.map((item) => (
                            <button
                                key={item?.href}
                                onClick={() => navigate(item.href)}
                                disabled={item?.href === `/task-glide/glides/${uuid}/setup` && !isUserCreatedGlide(userDefinedGlideRetrievalResponse)}
                                className={clsx(item.current ? 'bg-base-200 text-base-300' : 'hover:bg-base-200', 'inline-flex items-center rounded-md py-1.5 px-3 text-sm font-medium whitespace-nowrap text-base-content disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-base-100')}
                            >
                                {item?.name}
                            </button>
                        ))}
                    </ul>
                </nav>
            </header>
            {(firstRender && loadingStates) ? <GlideCreatorTestSkeleton/> :
            <div className="grid md:grid-cols-9 lg:grid-cols-11 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-sm w-full z-10 h-[calc(100dvh-163px)]">
                <div className="col-span-full lg:col-span-8 flex flex-col max-h-[calc(100dvh-163px)]">
                    <div ref={chatBoxRef} className="overflow-y-auto overflow-x-hidden scroll-smooth scrollbar grow">
                        <GlideCreatorTestChatBox
                            glide={userDefinedGlideRetrievalResponse}
                            onImageLoad={setImageLoaded}
                            handleInteractionPagination={handleInteractionPagination}
                        />
                        <div ref={pageEndRef}/>
                    </div>
                    <div className="sticky bottom-0 z-20">
                        {currentTestTgConversation?.interactions?.results?.length > 0 && <div className="absolute right-0 bottom-1 flex items-center space-x-3 pointer-events-auto justify-stretch py-2 px-5 w-fit z-40">
                            <button
                                className="group/scroll-down sticky text-base-content hover:sm:text-base-content text-sm flex items-center justify-center disabled:cursor-not-allowed w-full disabled:opacity-50 rounded-full bg-base-200 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 p-0.5"
                                disabled={isAutoScrollEnabled}
                                onClick={() => setIsAutoScrollEnabled(true)}
                            >
                                <TbArrowNarrowDown className="w-6 h-6 shrink-0"/>
                                {!isAutoScrollEnabled && <div className="top-tooltips bottom-10 group-hover/scroll-down:lg:block">Scroll down</div>}
                            </button>
                        </div>}
                    </div>
                    <div className="px-4">
                        <GenericChatInputField
                            formik={formik}
                            handleAbort={handleAbort}
                            disabled={taskGlideStreamVF || glidesListingVF || !selectedGlides}
                            loadingState={taskGlideStreamVF}
                            selectedGlides={selectedGlides}
                            placeholder={`Test ${userDefinedGlideRetrievalResponse?.name}...`}
                            additionalMenuButtons={() => <GlideCreatorInputAdditionalButtons/>}
                            selectedPhoto={selectedPhotos}
                            setSelectedPhoto={setSelectedPhotos}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            setParsedDocuments={setParsedDocuments}
                            handleFileSelect={handleFileSelect}
                            handlePhotoSelect={handlePhotoSelect}
                            handleFileRemove={handleFileRemove}
                            handlePhotoRemove={handlePhotoRemove}
                        />
                    </div>
                </div>
                <div className="hidden md:col-span-3 lg:flex flex-col justify-between items-start py-4 px-4 h-full border-l border-l-neutral">
                    <div className="w-full space-y-1">
                        <div className="flex items-center py-2 px-4 text-sm space-x-2 bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 rounded-t-lg">
                            <div className="flex items-center space-x-2 text-base-content">
                                <HiUserGroup className="w-4 h-4"/>
                                <h2>Active Users:</h2>
                            </div>
                            <p className="text-base-content/70 text-sm">
                                {formatUsageCount(userDefinedGlideRetrievalResponse?.user_count)}
                            </p>
                        </div>
                        <div className="flex items-center py-2 px-4 text-sm space-x-2 bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50">
                            <div className="flex items-center space-x-2 text-base-content">
                                <PiChatsFill className="w-4 h-4"/>
                                <h2>Chat Count:</h2>
                            </div>
                            <p className="text-base-content/70 text-sm">
                                {formatUsageCount(userDefinedGlideRetrievalResponse?.usage_count)}
                            </p>
                        </div>
                        <div className="flex flex-col justify-start space-y-2 py-2 px-4 text-sm text-base-content bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 rounded-b-lg">
                            <div className="flex items-center space-x-2 text-base-content">
                                <RiBox3Fill className="w-4 h-4"/>
                                <h2>Tools:</h2>
                            </div>
                            <div className="flex flex-col justify-start space-y-1 pl-1 scrollbar overflow-y-auto">
                                {allUserGlideTools?.length > 0 ? allUserGlideTools?.map((tool, index) => (
                                    <div key={index} className="flex items-center space-x-1.5">
                                        <div className="p-1 rounded-full bg-base-100 ring-[0.5px] ring-base-content/30 dark:ring-base-content/40">
                                            <span>{getToolIcon(tool?.name_tag, 'w-2.5 h-2.5 shrink-0')}</span>
                                        </div>
                                        <span className="text-sm text-base-content/70">{tool?.name_tag}</span>
                                    </div>
                                )) : <p className="text-sm text-base-content/70">No tools added</p>}
                            </div>
                        </div>
                    </div>
                    <div className="w-full space-y-2">
                        <button
                            type="button"
                            onClick={handleResetConversation}
                            disabled={currentTestTgConversation?.interactions?.results?.length === 0 || !currentTestTgConversation}
                            className="neutral-button w-full space-x-2"
                        >
                            {!testConversationDeletionVF ?
                                <>
                                    <p className="whitespace-nowrap">Reset Conversation</p>
                                    {conversationResetIsConfirmed ?
                                        <MdCheck className="w-4 h-4 shrink-0"/> :
                                        <LuTimerReset className="h-4 w-4 shrink-0"/>}
                                </> :
                                <CgSpinner className="animate-spin h-5 w-5 inline-block shrink-0"/>}
                        </button>
                        <button
                            type="button"
                            disabled={isGlideTaskGlide}
                            onClick={handleRemoveOrAddGlide}
                            className={clsx(
                                "space-x-2 w-full",
                                glideInMyGlides ? "error-button" : "primary-button",
                                isGlideTaskGlide && "cursor-not-allowed opacity-50"
                            )}
                        >
                            {(!addOrRemoveGlideOfUserGlidesVF && !glidesListingVF) ?
                                glideInMyGlides ?
                                    (<>
                                        <p className="whitespace-nowrap">
                                            Remove from my Glides
                                        </p>
                                        {glideRemovalOrAdditionIsConfirmed ?
                                            <MdCheck className="w-4 h-4 shrink-0"/> :
                                            <MdPlaylistRemove className="w-4 h-4 shrink-0"/>}
                                    </>) :
                                    (<>
                                        <p className="whitespace-nowrap">
                                            Add to my Glides
                                        </p>
                                        {glideRemovalOrAdditionIsConfirmed ?
                                            <MdCheck className="w-4 h-4 shrink-0"/> :
                                            <MdArrowOutward className="w-4 h-4 shrink-0"/>}
                                    </>) :
                                <CgSpinner className="animate-spin h-5 w-5 inline-block shrink-0"/>}
                        </button>
                    </div>
                </div>
            </div>}
        </>
    )
};

GlideCreatorTest.propTypes = {};

export default GlideCreatorTest;
