import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const pinnedMessageGET = createAsyncThunk(
    'taskGlide/conversation/pinned-message/get',
    async ({ conversation_uuid }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_GET_PINNED_MESSAGE_PATH}${conversation_uuid}/pinned-message/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    pinnedMessageRetrievalVF: false,
    pinnedMessageRetrievalApiState: 'idle',
    pinnedMessageRetrievalResponse: null,
    pinnedMessageRetrievalError: null,
};

export const getPinnedMessageSlice = createSlice({
    name: 'getPinnedMessage',
    initialState: INITIAL_STATE,
    reducers: {
        clearPinnedMessageRetrievalVF: (state) => {
            state.pinnedMessageRetrievalVF = false;
        },
        resetPinnedMessageRetrieval: (state) => {
            state.pinnedMessageRetrievalVF = false;
            state.pinnedMessageRetrievalApiState = 'idle';
            state.pinnedMessageRetrievalResponse = null;
            state.pinnedMessageRetrievalError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(pinnedMessageGET.pending, (state) => {
                state.pinnedMessageRetrievalApiState = 'loading';
                state.pinnedMessageRetrievalVF = true;
            })
            .addCase(pinnedMessageGET.fulfilled, (state, action) => {
                state.pinnedMessageRetrievalApiState = 'succeeded';
                state.pinnedMessageRetrievalResponse = action.payload;
                state.pinnedMessageRetrievalVF = true;
                state.pinnedMessageRetrievalError = null;
            })
            .addCase(pinnedMessageGET.rejected, (state, action) => {
                state.pinnedMessageRetrievalApiState = 'failed';
                state.pinnedMessageRetrievalError = action?.payload;
                state.pinnedMessageRetrievalVF = true;
                state.pinnedMessageRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearPinnedMessageRetrievalVF,
    resetPinnedMessageRetrieval,
} = getPinnedMessageSlice.actions;

export default getPinnedMessageSlice.reducer;
