import {handleResponseError} from "./errorHandlers";


export async function processStream(response, onStreamData, thunkAPI) {
    if (!response.ok) {
        return handleResponseError(response, thunkAPI);
    }

    let lastData = null;
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let accumulatedResponse = "";

    try {
        let done = false;
        while (!done) {
            const { value, done: streamDone } = await reader.read();
            done = streamDone;
            if (done) {
                if (accumulatedResponse) {
                    try {
                        const data = JSON.parse(accumulatedResponse);
                        onStreamData(data);
                        lastData = data;
                    } catch (error) {
                        console.error("Error parsing the final chunk:", error);
                    }
                }
                break;
            }

            const chunk = decoder.decode(value || new Uint8Array(), { stream: true });
            accumulatedResponse += chunk;

            while (accumulatedResponse.indexOf('}\n') > -1 || accumulatedResponse.endsWith('}')) {
                let splitIndex;

                if (accumulatedResponse.indexOf('}\n') > -1) {
                    splitIndex = accumulatedResponse.indexOf('}\n') + 1;
                } else {
                    splitIndex = accumulatedResponse.lastIndexOf('}') + 1;
                }

                const jsonStr = accumulatedResponse.slice(0, splitIndex);
                accumulatedResponse = accumulatedResponse.slice(splitIndex + 1);

                try {
                    const data = JSON.parse(jsonStr);
                    onStreamData(data);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            }
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            return thunkAPI.rejectWithValue({ message: error.message || 'An error occurred while fetching data' });
        } else {
            return thunkAPI.rejectWithValue({ message: error.message || 'An error occurred while fetching data' });
        }
    }

    return lastData;
}
