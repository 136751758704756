import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../users/useAuthentication";
import {clearUserSubscriptionsListingVF, userSubscriptionsLIST} from "../../../api/features/payments/listUserSubscriptionsSlice";
import {useUndoCancellation} from "./useUndoCancellation";


export const useListUserSubscriptions = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const {userIsAuthenticated} = useAuthentication();

    // Payment methods
    const {
        userSubscriptionsListingVF,
        userSubscriptionsListingApiState,
        userSubscriptionsListingResponse,
        userSubscriptionsListingError,
    } = useSelector(state => state.listUserSubscriptionsReducer);

    // API states with visual feedback
    const userSubscriptionsListingSuccessVF = userSubscriptionsListingApiState === 'succeeded' && userSubscriptionsListingVF;

    const listUserSubscriptions = () => userIsAuthenticated && dispatch(userSubscriptionsLIST());

    useEffect(() => {
        if (vfExists && userSubscriptionsListingSuccessVF) {
            const timer = setTimeout(() => {
                dispatch(clearUserSubscriptionsListingVF());
            }, 250);
            return () => clearTimeout(timer);
        }
    }, [userSubscriptionsListingApiState, userSubscriptionsListingVF]);

    return {
        listUserSubscriptions,
        userSubscriptionsListingVF,
        userSubscriptionsListingApiState,
        userSubscriptionsListingResponse,
        userSubscriptionsListingError,
        userSubscriptionsListingSuccessVF
    };
};
