import React from "react";
import {Link} from "react-router-dom";
import {ChevronRightIcon, EnvelopeIcon} from "@heroicons/react/20/solid";
import {SiHomeassistantcommunitystore} from "react-icons/si";
import {HiLightBulb} from "react-icons/hi2";
import {useSelector} from "react-redux";
import clsx from "clsx";
import routes from "../../../Routes/routes";
import {RiArrowRightLine, RiArrowRightSLine} from "react-icons/ri";

const links = [
    {
        name: 'Solutions',
        href: routes.unAuth.solutions.find(solution => solution.name === 'All in one').href,
        description: 'Learn about all of our products and services.',
        icon: HiLightBulb
    },
    {
        name: 'Store',
        href: routes.unAuth.products.find(product => product.name === 'Store').href,
        description: 'Browse our store to subscribe to a specific product.',
        icon: SiHomeassistantcommunitystore
    },
    {
        name: 'Contact us', href: routes.unAuth.contact.href,
        description: 'Read our latest news and articles.',
        icon: EnvelopeIcon
    },
];

const NotFound = () => {
    const mobileMenuOpen = useSelector(state => state.frontendReducer.value);

    return (
        <div className={clsx("relative bg-base-100 h-dvh isolate flex items-center justify-center", mobileMenuOpen ? 'blur-sm lg:blur-none' : '')} >
            <div className="relative isolate">
                <div className="mx-auto grid min-h-full place-items-center px-6 py-20 sm:py-40 lg:px-8">
                    <div className="text-center">
                        <div className="space-y-2" >
                            <p className="text-base font-medium text-primary">404</p>
                            <h1 className="mt-4 text-3xl font-bold tracking-tight text-base-content sm:text-5xl">Page not found</h1>
                        </div>
                        <p className="mt-6 text-base leading-7 text-base-content/70">Sorry, we couldn’t find the page you’re looking for.</p>
                        <div className="mt-10 flex items-center justify-center gap-x-8">
                            <Link type="button" to={'/'} className="primary-button group space-x-0.5">
                                Go back home
                            </Link>
                            <Link
                                to={routes.unAuth.contact.href}
                                className="rounded-empty-button group"
                            >
                                <p>Contact us</p>
                                <RiArrowRightSLine className='flex group-hover:hidden size-4' aria-hidden="true" />
                                <RiArrowRightLine className='group-hover:flex hidden size-4' aria-hidden="true" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NotFound;
