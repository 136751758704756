import {configureStore} from '@reduxjs/toolkit';
import {createLogger} from "redux-logger/src";

import frontendReducer from "../api/features/frontend/uiSlice";
import alertReducer from "../api/features/frontend/alertSlice";
import registrationReducer from "../api/features/users/registrationSlice";
import forgotPasswordReducer from "../api/features/users/forgotPasswordSlice";
import passwordResetReducer from "../api/features/users/passwordResetSlice";
import accountVerificationReducer from "../api/features/users/accountVerificationSlice";
import getUserInfoReducer from "../api/features/users/getUserInfoSlice";
import updateUserInfoReducer from "../api/features/users/updateUserInfoSlice";
import getUserPreferencesReducer from "../api/features/users/getUserPreferencesSlice";
import createStripeAccountReducer from "../api/features/payments/createStripeCustomerSlice";
import getStripeAccountReducer from "../api/features/payments/getStripeCustomerSlice";
import updateUserPreferencesReducer from "../api/features/users/updateUserPreferencesSlice";
import createPaymentMethodReducer from "../api/features/payments/createPaymentMethodSlice";
import deletePaymentMethodReducer from "../api/features/payments/deletePaymentMethodSlice";
import listPaymentMethodsReducer from "../api/features/payments/listPaymentMethodsSlice";
import listAllTGConversationsReducer from "../api/features/superIntelligence/taskGlide/conversations/listAllTgConversationsSlice";
import getSingleTGConversationReducer from "../api/features/superIntelligence/taskGlide/conversations/getSingleTgConversationSlice";
import getStripeCheckoutReducer from "../api/features/payments/stripeCheckoutSlice";
import getRegistrationStatusReducer from "../api/features/users/getRegistrationStatusSlice";
import {authenticationReducer} from "../api/features/users/authenticationSlice";
import setDefaultPaymentMethodReducer from "../api/features/payments/setDefaultPaymentMethodSlice";
import taskGlideUiActionsReducer from "../api/features/frontend/taskGlideUiActionsSlice";
import streamTaskGlideReducer from "../api/features/superIntelligence/taskGlide/taskGlide/streamTaskGlideSlice";
import searchTgConversationsReducer from "../api/features/superIntelligence/taskGlide/conversations/searchTgConversationsSlice";
import deleteSingleTgConversationReducer from "../api/features/superIntelligence/taskGlide/conversations/deleteSingleTgConversationSlice";
import deleteAllTgConversationsReducer from "../api/features/superIntelligence/taskGlide/conversations/deleteAllTgConversationsSlice";
import deleteTaskGlideMessageReducer from "../api/features/superIntelligence/taskGlide/conversations/deleteTaskGlideMessageSlice";
import getTaskGlideSettingsReducer from "../api/features/superIntelligence/taskGlide/taskGlide/getTaskGlideSettingsSlice";
import updateTaskGlideSettingsReducer from "../api/features/superIntelligence/taskGlide/taskGlide/updateTaskGlideSettingsSlice";
import listGlidesReducer from "../api/features/superIntelligence/taskGlide/glides/listUserGlidesSlice";
import getUserDefinedGlideReducer from "../api/features/superIntelligence/taskGlide/glides/getUserGlideDetailsSlice";
import updateUserDefinedGlideReducer from "../api/features/superIntelligence/taskGlide/glides/updateUserGlideSlice";
import createUserDefinedGlideReducer from "../api/features/superIntelligence/taskGlide/glides/createUserGlideSlice";
import listGlideToolsReducer from "../api/features/superIntelligence/taskGlide/glides/listGlideToolsSlice";
import deleteAllUserDefinedGlidesReducer from "../api/features/superIntelligence/taskGlide/glides/deleteAllUserGlidesSlice";
import listPublicGlidesReducer from "../api/features/superIntelligence/taskGlide/glides/listPublicGlidesSlice";
import updateUserGlideDefaultStateReducer from "../api/features/superIntelligence/taskGlide/glides/updateUserGlideDefaultStateSlice";
import getTestConversationReducer from "../api/features/superIntelligence/taskGlide/conversations/getTestConversationSlice";
import addOrRemoveGlideOfUserGlidesReducer from "../api/features/superIntelligence/taskGlide/glides/addOrRemoveGlideOfUserGlidesSlice";
import deleteTestConversationReducer from "../api/features/superIntelligence/taskGlide/conversations/deleteTestConversationSlice";
import createFeedbackReducer from "../api/features/users/createFeedbackSlice";
import downloadDocumentReducer from "../api/features/superIntelligence/taskGlide/conversations/downloadDocumentSlice";
import togglePinMessageReducer from "../api/features/superIntelligence/taskGlide/conversations/togglePinMessageSlice";
import listSubscriptionPlansReducer from "../api/features/payments/listSubscriptionPlansSlice";
import createSubscriptionReducer from "../api/features/payments/createSubscriptionSlice";
import validatePromoCodeReducer from "../api/features/payments/validatePromoCodeSlice";
import listTransactionReducer from "../api/features/payments/listTransactionSlice";
import listUserSubscriptionsReducer from "../api/features/payments/listUserSubscriptionsSlice";
import unsubscribeReducer from "../api/features/payments/unsubscribeSlice";
import emailSubscriptionReducer from "../api/features/users/emailSubscriptionSlice";
import undoCancellationReducer from "../api/features/payments/undoCancellationSlice";
import getSubscriptionPlanReducer from "../api/features/payments/getSubscriptionPlanSlice";
import getUserSubscriptionReducer from "../api/features/payments/getUserSubscriptionSlice";
import subscriptionPaymentMethodUpdateReducer from "../api/features/payments/updateSubscriptionPaymentMethod";
import deleteUserAccountReducer  from "../api/features/users/deleteUserAccountSlice";
import passwordChangeReducer from "../api/features/users/passwordChangeSlice";
import getUserTransactionReducer from "../api/features/payments/getUserTransactionSlice";
import getTransactionReceiptReducer from "../api/features/payments/getTransactionReceiptSlice";
import getPinnedMessageReducer from "../api/features/superIntelligence/taskGlide/conversations/getPinnedMessageSlice";
import parseDocumentsReducer from "../api/features/superIntelligence/taskGlide/taskGlide/parseDocumentsSlice";
import tgInteractionStreamReducer from "../api/features/superIntelligence/taskGlide/frontend/getTgInteractionStreamSlice";
import getCurrentTgConversationReducer from "../api/features/superIntelligence/taskGlide/frontend/getCurrentTgConversationSlice";
import getCalendarReducer from "../api/features/superIntelligence/memoMinder/calendar/listCalendarDatesSlice";
import getCalendarEventReducer from "../api/features/superIntelligence/memoMinder/calendar/getCalendarEventSlice";
import createCalendarEventReducer from "../api/features/superIntelligence/memoMinder/calendar/createCalendarEventSlice";


const customLogger = createLogger({
    predicate: (getState, action) => {
        const blacklist = [
            'taskGlide/interaction/create/pending', 'taskGlide/interaction/create/fulfilled', 'taskGlide/interaction/create/rejected',
        ];
        const whitelist = ['taskGlide/conversation/list/pending', 'taskGlide/conversation/list/fulfilled', 'taskGlide/conversation/list/rejected'];

        return !blacklist.includes(action.type);
    }
});

export default configureStore({
    reducer: {
        frontendReducer: frontendReducer,
        alertReducer: alertReducer,
        getRegistrationStatusReducer: getRegistrationStatusReducer,
        registrationReducer: registrationReducer,
        passwordResetReducer: passwordResetReducer,
        forgotPasswordReducer: forgotPasswordReducer,
        accountVerificationReducer: accountVerificationReducer,
        getUserInfoReducer: getUserInfoReducer,
        updateUserInfoReducer: updateUserInfoReducer,
        getUserPreferencesReducer: getUserPreferencesReducer,
        updateUserPreferencesReducer: updateUserPreferencesReducer,
        createStripeAccountReducer: createStripeAccountReducer,
        getStripeAccountReducer: getStripeAccountReducer,
        createPaymentMethodReducer: createPaymentMethodReducer,
        deletePaymentMethodReducer: deletePaymentMethodReducer,
        listPaymentMethodsReducer: listPaymentMethodsReducer,
        listAllTGConversationsReducer: listAllTGConversationsReducer,
        getSingleTGConversationReducer: getSingleTGConversationReducer,
        getTestConversationReducer: getTestConversationReducer,
        deleteSingleTgConversationReducer: deleteSingleTgConversationReducer,
        deleteTaskGlideMessageReducer: deleteTaskGlideMessageReducer,
        getStripeCheckoutReducer: getStripeCheckoutReducer,
        authenticationReducer: authenticationReducer,
        setDefaultPaymentMethodReducer: setDefaultPaymentMethodReducer,
        taskGlideUiActionsReducer: taskGlideUiActionsReducer,
        getTaskGlideSettingsReducer: getTaskGlideSettingsReducer,
        updateTaskGlideSettingsReducer: updateTaskGlideSettingsReducer,
        streamTaskGlideReducer: streamTaskGlideReducer,
        searchTgConversationsReducer: searchTgConversationsReducer,
        deleteAllTgConversationsReducer: deleteAllTgConversationsReducer,
        listGlidesReducer: listGlidesReducer,
        getUserDefinedGlideReducer: getUserDefinedGlideReducer,
        updateUserDefinedGlideReducer: updateUserDefinedGlideReducer,
        updateUserGlideDefaultStateReducer: updateUserGlideDefaultStateReducer,
        createUserDefinedGlideReducer: createUserDefinedGlideReducer,
        listGlideToolsReducer: listGlideToolsReducer,
        deleteAllUserDefinedGlidesReducer: deleteAllUserDefinedGlidesReducer,
        listPublicGlidesReducer: listPublicGlidesReducer,
        addOrRemoveGlideOfUserGlidesReducer: addOrRemoveGlideOfUserGlidesReducer,
        deleteTestConversationReducer: deleteTestConversationReducer,
        createFeedbackReducer: createFeedbackReducer,
        downloadDocumentReducer: downloadDocumentReducer,
        togglePinMessageReducer: togglePinMessageReducer,
        createSubscriptionReducer: createSubscriptionReducer,
        validatePromoCodeReducer: validatePromoCodeReducer,
        listTransactionReducer: listTransactionReducer,
        getUserTransactionReducer: getUserTransactionReducer,
        listSubscriptionPlansReducer: listSubscriptionPlansReducer,
        listUserSubscriptionsReducer: listUserSubscriptionsReducer,
        unsubscribeReducer: unsubscribeReducer,
        emailSubscriptionReducer: emailSubscriptionReducer,
        undoCancellationReducer: undoCancellationReducer,
        getSubscriptionPlanReducer: getSubscriptionPlanReducer,
        getUserSubscriptionReducer: getUserSubscriptionReducer,
        subscriptionPaymentMethodUpdateReducer: subscriptionPaymentMethodUpdateReducer,
        deleteUserAccountReducer: deleteUserAccountReducer,
        passwordChangeReducer: passwordChangeReducer,
        getTransactionReceiptReducer: getTransactionReceiptReducer,
        getPinnedMessageReducer: getPinnedMessageReducer,
        parseDocumentsReducer: parseDocumentsReducer,
        tgInteractionStreamReducer: tgInteractionStreamReducer,
        getCurrentTgConversationReducer: getCurrentTgConversationReducer,
        getCalendarReducer: getCalendarReducer,
        getCalendarEventReducer: getCalendarEventReducer,
        createCalendarEventReducer: createCalendarEventReducer,
    },
});
