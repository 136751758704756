import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {useGetUserGlideDetails} from "./useGetUserGlideDetails";
import {clearUserDefinedGlideCreateVF, resetUserDefinedGlideCreate, userDefinedGlidePOST} from "../../../../../api/features/superIntelligence/taskGlide/glides/createUserGlideSlice";


export const useCreateUserDefinedGlide = (
    { vfExists=false, autoUpdate=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // MyGlides
    const {
        userDefinedGlideCreateVF,
        userDefinedGlideCreateApiState,
        userDefinedGlideCreateResponse,
        userDefinedGlideCreateError,
    } = useSelector(state => state.createUserDefinedGlideReducer);
    const { getUserDefinedGlide } = useGetUserGlideDetails();

    // API states with visual feedback
    const userDefinedGlideCreateSuccessVF = userDefinedGlideCreateApiState === 'succeeded' && userDefinedGlideCreateVF;
    const userDefinedGlideCreateFailureVF = userDefinedGlideCreateApiState === 'failed' && userDefinedGlideCreateVF;

    const createUserDefinedGlide = (values) => userIsAuthenticated && dispatch(userDefinedGlidePOST({ values }));

    useEffect(() => {
        if (vfExists) {
            if (userDefinedGlideCreateSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearUserDefinedGlideCreateVF());
                }, 500);

                return () => clearTimeout(timer);
            } else if (userDefinedGlideCreateFailureVF) {
                dispatch(setAlert({
                    message: userDefinedGlideCreateResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUserDefinedGlideCreate());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [userDefinedGlideCreateApiState, userDefinedGlideCreateVF]);

    useEffect(() => {
        if (autoUpdate && userDefinedGlideCreateApiState === 'succeeded' && !userDefinedGlideCreateVF) {
            getUserDefinedGlide(userDefinedGlideCreateResponse?.uuid);
            dispatch(resetUserDefinedGlideCreate());
        }
    }, [userDefinedGlideCreateApiState, userDefinedGlideCreateVF]);

    return {
        createUserDefinedGlide,
        userDefinedGlideCreateVF,
        userDefinedGlideCreateApiState,
        userDefinedGlideCreateResponse,
        userDefinedGlideCreateError,
        userDefinedGlideCreateSuccessVF,
        userDefinedGlideCreateFailureVF
    };
};
