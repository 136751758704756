import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const glideOfUserGlidesPOSTDELETE = createAsyncThunk(
    'taskGlide/glides/glide/add-or-remove',
    async ({ glide_uuid, action }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: action === 'add' ? 'POST' : action === 'remove' && 'DELETE',
                    path: `${process.env.REACT_APP_TASKGLIDE_GET_USER_DEFINED_GLIDES_PATH}${action}/${glide_uuid}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    addOrRemoveGlideOfUserGlidesVF: false,
    addOrRemoveGlideOfUserGlidesApiState: 'idle',
    addOrRemoveGlideOfUserGlidesResponse: null,
    addOrRemoveGlideOfUserGlidesError: null,
};

export const addOrRemoveGlideOfUserGlidesSlice = createSlice({
    name: 'glideToUserGlideAdditionOrRemoval',
    initialState: INITIAL_STATE,
    reducers: {
        clearAddOrRemoveGlideOfUserGlidesVF: (state) => {
            state.addOrRemoveGlideOfUserGlidesVF = false;
        },
        resetGlideOfUserGlideAdditionOrRemoval: (state) => {
            state.addOrRemoveGlideOfUserGlidesVF = false;
            state.addOrRemoveGlideOfUserGlidesApiState = 'idle';
            state.addOrRemoveGlideOfUserGlidesResponse = null;
            state.addOrRemoveGlideOfUserGlidesError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(glideOfUserGlidesPOSTDELETE.pending, (state) => {
                state.addOrRemoveGlideOfUserGlidesApiState = 'loading';
                state.addOrRemoveGlideOfUserGlidesVF = true;
            })
            .addCase(glideOfUserGlidesPOSTDELETE.fulfilled, (state, action) => {
                state.addOrRemoveGlideOfUserGlidesApiState = 'succeeded';
                state.addOrRemoveGlideOfUserGlidesResponse = action?.payload;
                state.addOrRemoveGlideOfUserGlidesVF = true;
                state.addOrRemoveGlideOfUserGlidesError = null;
            })
            .addCase(glideOfUserGlidesPOSTDELETE.rejected, (state, action) => {
                state.addOrRemoveGlideOfUserGlidesApiState = 'failed';
                state.addOrRemoveGlideOfUserGlidesError = action?.payload;
                state.addOrRemoveGlideOfUserGlidesVF = true;
                state.addOrRemoveGlideOfUserGlidesResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearAddOrRemoveGlideOfUserGlidesVF, resetGlideOfUserGlideAdditionOrRemoval
} = addOrRemoveGlideOfUserGlidesSlice.actions;

export default addOrRemoveGlideOfUserGlidesSlice.reducer;
