import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {clearUserAccountDeletionVF, resetUserAccountDeletion, userAccountDELETE} from "../../../api/features/users/deleteUserAccountSlice";


export const useDeleteUserAccount = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();

    const {
        userAccountDeletionVF,
        userAccountDeletionApiState,
        userAccountDeletionResponse,
        userAccountDeletionError,
    } = useSelector(state => state.deleteUserAccountReducer);

    // API State with visual feedback
    const userAccountDeletionSuccessVF = userAccountDeletionApiState === 'succeeded' && userAccountDeletionVF;
    const userAccountDeletionFailureVF = userAccountDeletionApiState === 'failed' && userAccountDeletionVF;
    const userAccountDeletionLoadingVF = userAccountDeletionApiState === 'loading' && userAccountDeletionVF;

    // Function to be called on form submission
    const deleteUserAccount = (values) => dispatch(userAccountDELETE(values));

    useEffect(() => {
        if (vfExists) {
            if (userAccountDeletionSuccessVF) {
                dispatch(setAlert({
                    message: userAccountDeletionResponse?.message,
                    type: "success"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearUserAccountDeletionVF());
                }, 4000);

                return () => clearTimeout(timer);
            }  else if (userAccountDeletionFailureVF) {
                dispatch(setAlert({
                    message: userAccountDeletionError?.message || "Something went wrong. Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUserAccountDeletion());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [userAccountDeletionApiState]);

    return {
        deleteUserAccount,
        userAccountDeletionVF,
        userAccountDeletionApiState,
        userAccountDeletionResponse,
        userAccountDeletionError,
        userAccountDeletionSuccessVF,
        userAccountDeletionFailureVF,
        userAccountDeletionLoadingVF
    };
};
