import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {clearSubscriptionPaymentMethodUpdateVF, resetSubscriptionPaymentMethodUpdate, subscriptionPaymentMethodUpdatePOST} from "../../../api/features/payments/updateSubscriptionPaymentMethod";
import {useGetUserSubscription} from "./useGetUserSubscription";


export const useUpdateSubscriptionPaymentMethod = (
    {vfExists=false, refreshAndReset=true} = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Payment Method
    const {
        subscriptionPaymentMethodUpdateVF,
        subscriptionPaymentMethodUpdateApiState,
        subscriptionPaymentMethodUpdateResponse,
        subscriptionPaymentMethodUpdateError,
    } = useSelector(state => state.subscriptionPaymentMethodUpdateReducer);
    const {getUserSubscription} = useGetUserSubscription({ vfExists: false });

    // API State with visual feedback
    const subscriptionPaymentMethodUpdateSuccessVF = subscriptionPaymentMethodUpdateApiState === 'succeeded' && subscriptionPaymentMethodUpdateVF;
    const subscriptionPaymentMethodUpdateFailureVF = subscriptionPaymentMethodUpdateApiState === 'failed' && subscriptionPaymentMethodUpdateVF;
    const subscriptionPaymentMethodUpdateLoadingVF = subscriptionPaymentMethodUpdateApiState === 'loading' && subscriptionPaymentMethodUpdateVF;

    const updateUserSubscriptionPaymentMethod = (priceId, paymentMethodId) => userIsAuthenticated && dispatch(subscriptionPaymentMethodUpdatePOST({priceId, paymentMethodId}));

    useEffect(() => {
        if (vfExists) {
            if (subscriptionPaymentMethodUpdateSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearSubscriptionPaymentMethodUpdateVF());
                }, 1000);

                return () => clearTimeout(timer);
            }  else if (subscriptionPaymentMethodUpdateFailureVF) {
                dispatch(setAlert({
                    message: subscriptionPaymentMethodUpdateError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetSubscriptionPaymentMethodUpdate());
                }, 1000);

                return () => clearTimeout(timer);
            }
        }
    }, [subscriptionPaymentMethodUpdateApiState]);

    useEffect(() => {
        if (refreshAndReset && subscriptionPaymentMethodUpdateApiState === 'succeeded') {
            if (!subscriptionPaymentMethodUpdateVF) {
                getUserSubscription(subscriptionPaymentMethodUpdateResponse?.product_name);
                dispatch(resetSubscriptionPaymentMethodUpdate());
            }
        }
    }, [subscriptionPaymentMethodUpdateApiState, subscriptionPaymentMethodUpdateVF]);

    return {
        updateUserSubscriptionPaymentMethod,
        subscriptionPaymentMethodUpdateVF,
        subscriptionPaymentMethodUpdateApiState,
        subscriptionPaymentMethodUpdateSuccessVF,
        subscriptionPaymentMethodUpdateFailureVF,
        subscriptionPaymentMethodUpdateResponse,
        subscriptionPaymentMethodUpdateError,
        subscriptionPaymentMethodUpdateLoadingVF
    };
};
