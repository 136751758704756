import React from "react";
import {PiCode, PiCodesandboxLogo, PiShootingStar, PiUsersThree} from "react-icons/pi";
import {TbDatabaseExport, TbPlayerPlay} from "react-icons/tb";
import {MdOutlineConnectWithoutContact} from "react-icons/md";
import {LuLanguages} from "react-icons/lu";
import {GoGear} from "react-icons/go";
import {Link} from "react-router-dom";

import BgCheckers from "../../../../Components/Bg Checkers/BgCheckers";
import ProgrammingMockFrame from "./ProgrammingMockFrame";
import routes from "../../../../Routes/routes";


const ProgrammingSolution = () => {

    return (
        <div className="relative isolate py-16 sm:py-24">
            <BgCheckers lines="stroke-accent/10" boxes="fill-accent/10"/>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 gap-x-6 gap-y-10 content-center mx-auto max-w-7xl px-6 lg:px-8 py-16">
                <div className="flex flex-col justify-center mx-auto max-w-2xl text-start space-y-6">
                    <div className="space-y-2" >
                        <h2 className="text-base font-medium leading-6 text-primary">Programming Solutions</h2>
                        <h1 className="font-bold tracking-tight text-base-content text-[min(7.5vw,40px)] leading-tight">Your Coding Automation</h1>
                    </div>
                    <p className="leading-8 mx-auto max-w-2xl text-[min(5vw,18px)] text-base-content/70">Elevate your programming with our Intelligent driven code generation platform, designed to interpret your requirements and convert them into functional code across any language.</p>
                </div>
                <ProgrammingMockFrame/>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 space-6 mx-auto max-w-7xl px-6 lg:px-8">
                <div className="col-span-1 space-y-3">
                    <div className="p-2 rounded-md bg-primary w-fit">
                        <PiCode className="size-5 text-primary-content shrink-0"/>
                    </div>
                    <div className="space-y-2">
                        <p className="font-medium">Intuitive Code Generation</p>
                        <p className="text-base-content/70">Experience the future of coding with our Super Intelligent interface that translates your commands into optimized code. Whatever your preferred language, our system understands your needs and delivers high-quality, ready-to-run scripts, functions, and classes.</p>
                    </div>
                </div>
                <div className="col-span-1 space-y-3">
                    <div className="p-2 rounded-md bg-primary w-fit">
                        <PiCodesandboxLogo className="size-5 text-primary-content shrink-0"/>
                    </div>
                    <div className="space-y-2">
                        <p className="font-medium">Comprehensive Environment Setup</p>
                        <p className="text-base-content/70">Seamlessly create and manage multiple files, directories, and complete coding environments within the platform. Our intelligent interface simplifies environment configuration, allowing you to focus on innovation rather than setup.</p>
                    </div>
                </div>
                <div className="col-span-1 space-y-3">
                    <div className="p-2 rounded-md bg-primary w-fit">
                        <TbDatabaseExport className="size-5 text-primary-content shrink-0"/>
                    </div>
                    <div className="space-y-2">
                        <p className="font-medium">Local Machine Exporting</p>
                        <p className="text-base-content/70">Move your work from the cloud to your local machine with uncomplicated exporting capabilities. Each file, along with its dependencies, can be downloaded, ensuring you have everything you need to continue working offline or integrate into your existing projects.</p>
                    </div>
                </div>
                <div className="col-span-1 space-y-3">
                    <div className="p-2 rounded-md bg-primary w-fit">
                        <MdOutlineConnectWithoutContact className="size-5 text-primary-content shrink-0"/>
                    </div>
                    <div className="space-y-2">
                        <p className="font-medium">Dynamic File Interaction</p>
                        <p className="text-base-content/70">Witness as your files and directories communicate with one another within the platform, just as they would in a real-world development environment. This feature enables complex tasks and application structures to be orchestrated with precision.</p>
                    </div>
                </div>
                <div className="col-span-1 space-y-3">
                    <div className="p-2 rounded-md bg-primary w-fit">
                        <TbPlayerPlay className="size-5 text-primary-content shrink-0"/>
                    </div>
                    <div className="space-y-2">
                        <p className="font-medium">Run, Test, and Debug</p>
                        <p className="text-base-content/70">Shift from writing to running code in an instant. Our platform provides the tools to execute code snippets or whole applications, alongside debugging features that offer intelligent suggestions for fixing errors and improving your code’s performance.</p>
                    </div>
                </div>
                <div className="col-span-1 space-y-3">
                    <div className="p-2 rounded-md bg-primary w-fit">
                        <LuLanguages className="size-5 text-primary-content shrink-0"/>
                    </div>
                    <div className="space-y-2">
                        <p className="font-medium">Language Agnostic Platform</p>
                        <p className="text-base-content/70">Write in Python, JavaScript, Go, or any other programming language with support that’s as versatile as your project demands. The Super Intelligent assistance is equipped to understand and aid with syntax, libraries, and frameworks across the coding spectrum.</p>
                    </div>
                </div>
                <div className="col-span-1 space-y-3">
                    <div className="p-2 rounded-md bg-primary w-fit">
                        <PiShootingStar className="size-5 text-primary-content shrink-0"/>
                    </div>
                    <div className="space-y-2">
                        <p className="font-medium">Boosted Productivity for Programmers</p>
                        <p className="text-base-content/70">Whether you’re tackling a small script or a large application, our platform acts as a co-pilot, enhancing your productivity and guiding you through complex logic and architecture, so you can deliver robust applications faster.</p>
                    </div>
                </div>
                <div className="col-span-1 space-y-3">
                    <div className="p-2 rounded-md bg-primary w-fit">
                        <PiUsersThree className="size-5 text-primary-content shrink-0"/>
                    </div>
                    <div className="space-y-2">
                        <p className="font-medium">Collaborative Coding</p>
                        <p className="text-base-content/70">Code not in isolation but collaboratively, sharing your work with teammates directly through the platform. Real-time code sharing and editing make joint development natural and productive.</p>
                    </div>
                </div>
                <div className="col-span-1 space-y-3">
                    <div className="p-2 rounded-md bg-primary w-fit">
                        <GoGear className="size-5 text-primary-content shrink-0"/>
                    </div>
                    <div className="space-y-2">
                        <p className="font-medium">Customizable Development Experience</p>
                        <p className="text-base-content/70">Personalize the development environment to your liking. From theme settings to keyboard shortcuts, tailor every aspect of the interface for a comfortable and productive coding session.</p>
                    </div>
                </div>
            </div>
            <div className="space-y-2 mt-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h3 className="text-xl font-medium">Launch Your Super Intelligent Assistant</h3>
                <p className="text-base-content/70 text-lg">Begin a new chapter in programming with our <Link
                    className="text-secondary hover:text-secondary-focus dark:hover:text-secondary-focus-dark"
                    to={routes.unAuth.products.find(
                        product => product.name === "CodeCraft"
                    ).href}>CodeCraft</Link> at your side. Code more, manage less, and accelerate your development lifecycle. Join us now and code the future, today.</p>
            </div>
        </div>
    )
};

export default ProgrammingSolution;
