import React, {useEffect, useRef} from "react";
import clsx from "clsx";
import {CgSpinner} from "react-icons/cg";
import {useNavigate, useSearchParams} from "react-router-dom";
import PropTypes from "prop-types";
import {IoMdCheckmark, IoMdClose} from "react-icons/io";

import routes from "../../../../../../Routes/routes";
import {useUnsubscribe} from "../../../../../../Hooks/apiHooks/payments/useUnsubscribe";
import {useGetUserSubscription} from "../../../../../../Hooks/apiHooks/payments/useGetUserSubscription";
import {useUndoCancellation} from "../../../../../../Hooks/apiHooks/payments/useUndoCancellation";


const ExistingSubscriptionModal = ({ disabledState, product_name, price_id, billing_end }) => {
    const navigate = useNavigate();
    const initialFocusRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const isModalOpen = searchParams.get('unsubscription') === 'open';
    const isModalOpenParam = searchParams.get('unsubscription') === 'open';

    const {
        unsubscribe,
        unsubscribeVF,
        unsubscribeApiState
    } = useUnsubscribe({ vfExists: false, refreshAndReset: false });
    const {
        undoCancellation,
        undoCancellationVF,
        undoCancellationApiState
    } = useUndoCancellation({ vfExists: false, refreshAndReset: false });
    const {
        userSubscriptionRetrievalResponse
    } = useGetUserSubscription({ vfExists: false });

    const subscriptionIsCancelled = userSubscriptionRetrievalResponse?.is_cancellation_scheduled;

    useEffect(() => {
        const dialogElement = document.getElementById('unsubscriptionModal');
        if (isModalOpenParam) {
            dialogElement?.showModal();
            initialFocusRef.current?.focus();
        } else {
            dialogElement?.close();
        }
    }, [isModalOpenParam]);

    const handleOpenModal = (e) => {
        if (!disabledState) {
            e.preventDefault();
            const dialogElement = document.getElementById('unsubscriptionModal');
            dialogElement.showModal();
            setSearchParams({ 'unsubscription': 'open' });
        }
    };

    const handleCloseModal = () => {
        const dialogElement = document.getElementById('unsubscriptionModal');
        if (dialogElement) {
            dialogElement.close();
        }
        setSearchParams({ 'unsubscription': 'close' });
    };

    useEffect(() => {
        if (unsubscribeApiState === 'succeeded' || undoCancellationApiState === 'succeeded') {
            navigate(routes.auth.billing.subscriptions.find(route => route.label === 'Subscriptions').href);
        }
    }, [undoCancellationApiState, unsubscribeApiState, setSearchParams]);

    return (
        <>
            <button
                type="button"
                disabled={disabledState}
                className={!subscriptionIsCancelled ? "error-button" : "primary-button"}
                onClick={handleOpenModal}
            >
                {!subscriptionIsCancelled ? 'Cancel subscription' : 'Undo cancelation'}
            </button>
            <dialog
                id="unsubscriptionModal"
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={handleCloseModal}
            >
                <div className="modal-wrapper">
                    <div className="sm:flex sm:items-start">
                        <div className={clsx(
                            "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
                            !subscriptionIsCancelled ? "bg-error-content text-error" : "bg-primary/30 text-primary"
                        )} >
                            {!subscriptionIsCancelled ?
                                <IoMdClose className="h-6 w-6" aria-hidden="true"/> :
                                <IoMdCheckmark className="h-6 w-6" aria-hidden="true"/>}
                        </div>
                        <div className="mt-3 text-center sm:mx-4 sm:mt-2 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-base-content">
                                Cancel subscription for {product_name}
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-base-content/70">
                                    Are you sure you want to unsubscribe from {product_name}? You will have access to the product until {billing_end} and will not be charged again.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-end gap-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                const dialogElement = document.getElementById('unsubscriptionModal');
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                            className="standard-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            ref={initialFocusRef}
                            disabled={(unsubscribeVF || undoCancellationVF) || disabledState}
                            className={!subscriptionIsCancelled ? "error-button" : "primary-button"}
                            onClick={() => subscriptionIsCancelled ? undoCancellation(price_id) : unsubscribe(price_id)}
                        >
                            <CgSpinner
                                className={clsx(
                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                    (unsubscribeVF || undoCancellationVF) ? "" : "hidden"
                                )}
                                aria-hidden="true"
                            />
                            <p className={(unsubscribeVF || undoCancellationVF) ? "invisible" : ""}>
                                {subscriptionIsCancelled ? 'Undo cancelation' : 'Cancel subscription'}
                            </p>
                        </button>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button
                        tabIndex={-1}
                        className="cursor-default focus-visible:outline-0 focus-visible:ring-0"
                        onClick={() => {
                            const dialogElement = document.getElementById('unsubscriptionModal');
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >close
                    </button>
                </form>
            </dialog>
        </>
    )
};

ExistingSubscriptionModal.propTypes = {
    disabledState: PropTypes.bool,
    product_name: PropTypes.string,
    price_id: PropTypes.string,
    billing_end: PropTypes.string
};

export default ExistingSubscriptionModal;
