import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import clsx from "clsx";
import * as yup from "yup";
import {CgSpinner} from "react-icons/cg";

import routes from "../../../Routes/routes";
import {companyLogo} from "../../../Design/companyLogo";
import Alerts from "../../../Components/Alerts/Alerts";
import {useRegister} from "../../../Hooks/apiHooks/users/useRegister";
import {useAlert} from "../../../Hooks/frontendHooks/useAlert";


const initialValues = {
    username: "",
    email: "",
    password: "",
    password2: "",
    accepts_terms: false
};

const validationSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
    email: yup.string()
        .required('Email is required')
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Email is not valid'),
    password: yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/^(?=.*[a-z]).+$/, 'Password must contain at least one lowercase letter')
        .matches(/^(?=.*[A-Z]).+$/, 'Password must contain at least one uppercase letter')
        .matches(/^(?=.*\d).+$/, 'Password must contain at least one number')
        .matches(/^(?=.*[@$!%*?&]).+$/, 'Password must contain at least one special character (@$!%*?&)'),
    password2: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    accepts_terms: yup.bool()
        .oneOf([true], 'You must accept the terms and conditions')
});

const Register = () => {
    const dispatch = useDispatch();
    const {
        register,
        registrationVisualFeedback,
        registrationLoadingVF,
        registrationFailureVF
    } = useRegister({ vfExists: true });
    const {alert, alertKey} = useAlert();

    const onSubmit = (values, { setSubmitting, setFieldValue }) => {
        register(values);
        setSubmitting(false);
        setFieldValue('password', '');
        setFieldValue('password2', '');
    };

    return (
        <>
            <div className="flex min-h-screen flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 bg-base-100 space-y-10">
                <div className="mx-auto w-full max-w-sm space-y-6">
                    <Link to={routes.unAuth.home.href} className="outline:none focus:outline-none">
                        <span className="sr-only">Dovise</span>
                        <img
                            className="mx-auto h-10 w-auto transition-transform hover:scale-110 duration-500"
                            src={companyLogo}
                            alt="Your Company"
                        />
                    </Link>
                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-base-content">
                        Create your Dovise account
                    </h2>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {({errors, touched, isSubmitting, isValid, values}) => (
                        <Form className="max-w-sm mx-auto w-full">
                            <div
                                className={clsx(
                                    "flex justify-end items-center h-6 -mt-6",
                                    (errors.password && touched.password) ||
                                    (errors.password2 && touched.password2) ||
                                    (errors.email && touched.email) ? 'opacity-100' : 'opacity-0'
                                )}
                            >
                                <div className="text-error text-2xs rounded-md w-fit">
                                    {Object.keys(errors).map((errorKey, index) => {
                                        if (touched[errorKey] && index === 0) {
                                            return <p key={index}>{errors[errorKey]}</p>;
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>

                            <div className="relative inset-0 z-10 rounded-md shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 outline-0">
                                <Field
                                    type="text"
                                    name="username"
                                    placeholder="username"
                                    autoComplete="username"
                                    required
                                    className="relative block w-full rounded-t-md py-1 text-base-content border-0 outline-none focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light focus:z-10 focus-visible:ring focus-visible:outline-none focus-visible:ring-info/30 dark:focus-visible:ring-info/30 focus-visible:ring-offset-1 focus-visible:ring-offset-info/10 focus:ring-0 text-sm sm:leading-6 bg-base-100 dark:bg-base-200 focus:border-neutral-focus/70 dark:focus:border-neutral-focus-dark/70"
                                />
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                    autoComplete="email"
                                    required
                                    className="relative block w-full border-0 py-1 text-base-content border-b border-t border-neutral-focus/50 dark:border-neutral-focus-dark/50 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light focus:z-10 focus-visible:ring focus-visible:outline-none focus-visible:ring-info/30 dark:focus-visible:ring-info/30 focus-visible:ring-offset-1 focus-visible:ring-offset-info/10 focus:ring-0 text-sm sm:leading-6 bg-base-100 dark:bg-base-200 focus:border-neutral-focus/70 dark:focus:border-neutral-focus-dark/70"
                                />
                                <Field
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    autoComplete="current-password"
                                    required
                                    className="relative block w-full border-0 py-1 text-base-content border-b border-neutral-focus/50 dark:border-neutral-focus-dark/50 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light focus:z-10 focus-visible:ring focus-visible:outline-none focus-visible:ring-info/30 dark:focus-visible:ring-info/30 focus-visible:ring-offset-1 focus-visible:ring-offset-info/10 focus:ring-0 text-sm sm:leading-6 bg-base-100 dark:bg-base-200 focus:border-neutral-focus/70 dark:focus:border-neutral-focus-dark/70"
                                />
                                <Field
                                    type="password"
                                    name="password2"
                                    placeholder="Confirm Password"
                                    autoComplete="current-password"
                                    required
                                    className="relative block w-full rounded-b-md border-0 py-1 text-base-content focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light focus:z-10 focus-visible:ring focus-visible:outline-none focus-visible:ring-info/30 dark:focus-visible:ring-info/30 focus-visible:ring-offset-1 focus-visible:ring-offset-info/10 focus:ring-0 text-sm sm:leading-6 bg-base-100 dark:bg-base-200"
                                />
                            </div>

                            <div className="flex items-center py-6 space-x-3">
                                <Field
                                    type="checkbox"
                                    name="accepts_terms"
                                    required
                                    className="standard-checkbox checkbox-ring-focus"
                                />
                                <label htmlFor="remember-me" className="block text-sm leading-6 text-base-content space-x-1">
                                    <p className="inline">I agree to the</p>
                                    <a href={routes.unAuth.termsOfUse.href} target="_blank" rel="noopener noreferrer" className="inline text-primary hover:text-primary-focus dark:hover:text-primary-focus-dark outline:none focus:outline-none">
                                        Terms of Service
                                    </a>
                                    <p className="inline">.</p>
                                </label>
                            </div>

                            <button
                                type="submit"
                                disabled={
                                    !isValid || registrationVisualFeedback || isSubmitting || values.username === '' || values.email === '' || values.password === '' || values.password2 === ''
                                }
                                className="primary-button w-full"
                            >
                                <CgSpinner
                                    className={clsx(
                                        "absolute inset-0 m-auto animate-spin h-5 w-5",
                                        (registrationLoadingVF || isSubmitting) ? "" : "hidden"
                                    )}
                                    aria-hidden="true"
                                />
                                <p className={(registrationLoadingVF || isSubmitting) ? "invisible" : ""}>
                                    Create account
                                </p>
                            </button>
                        </Form>
                    )}
                </Formik>

                <p className="text-center text-sm leading-6 text-base-content/70 space-x-1">
                    <p className="inline" >Have an account?</p>
                    <Link
                        to={routes.unAuth.login.href}
                        onClick={() => dispatch({type: "RESET_USER_MANAGEMENT"})}
                        className="inline font-medium text-primary hover:text-primary-focus dark:hover:text-primary-focus-dark outline:none focus:outline-none">
                        Sign in
                    </Link>
                </p>
            </div>
            {registrationFailureVF && <Alerts message={alert?.message} type={alert.type} keyValue={alertKey}/>}
        </>
    )
};

export default Register;
