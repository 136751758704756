import Test from "../Pages/Test";
import Landing from "../Pages/UnAuth/Landing/Landing";
import Register from "../Pages/UnAuth/Register/Register";
import Login from "../Pages/UnAuth/Login/Login";
import About from "../Pages/UnAuth/About/About";
import Contact from "../Pages/UnAuth/Contact/Contact";
import Pricing from "../Pages/UnAuth/Pricing/Pricing";
import Store from "../Pages/UnAuth/Store/Store";
import NotFound from "../Pages/UnAuth/404/NotFound";
import routes from "./routes";
import ComingSoon from "../Pages/UnAuth/ComingSoon/ComingSoon";
import AccountSettings from "../Pages/Auth/Settings/Account/AccountSettings";
import {UserManagementUnAuthWrapper} from "../App/Wrappers/UserManagementUnAuthWrapper";
import {UnAuthWrapper} from "../App/Wrappers/UnAuthWrapper";
import {AuthWrapper} from "../App/Wrappers/AuthWrapper";
import PreferenceSettings from "../Pages/Auth/Settings/Preferences/PreferenceSettings";
import TaskGlideChat from "../Pages/Auth/Products/TaskGlide/Chat/TaskGlideChat";
import LinkedCards from "../Pages/Auth/Billing/LinkedCards/LinkedCards";
import Invoices from "../Pages/Auth/Billing/Invoices/Invoices";
import Subscriptions from "../Pages/Auth/Billing/Subscriptions/Subscriptions";
import Transaction from "../Pages/Auth/Billing/Transactions/Transaction";
import MyGlides from "../Pages/Auth/Products/TaskGlide/Glides/MyGlides/MyGlides";
import Registration from "../Pages/Auth/Registration/Registration";
import VerifyAccount from "../Pages/UnAuth/VerifyAccount/VerifyAccount";
import ResetPassword from "../Pages/UnAuth/ResetPassword/ResetPassword";
import ForgotPassword from "../Pages/UnAuth/ForgotPassword/ForgotPassword";
import AccountVerificationResponse from "../Pages/UnAuth/VerifyAccount/AccountVerificationResponse";
import GoogleOAuthRedirectHandler from "../Pages/Auth/Products/TaskGlide/Settings/GoogleOAuth/GoogleOAuthRedirectHandler";
import TaskGlideSettings from "../Pages/Auth/Products/TaskGlide/Settings/TaskGlideSettings";
import ExistingGlideCreatorSetup from "../Pages/Auth/Products/TaskGlide/Glides/GlideCreator/GlideCreatorSetup/ExistingGlideCreatorSetup";
import GlideCreatorTest from "../Pages/Auth/Products/TaskGlide/Glides/GlideCreator/GlideCreatorTest/GlideCreatorTest";
import NewGlideCreatorSetup from "../Pages/Auth/Products/TaskGlide/Glides/GlideCreator/GlideCreatorSetup/NewGlideCreatorSetup";
import GlideStore from "../Pages/Auth/Products/TaskGlide/Glides/GlideStore/GlideStore";
import TaskGlideProduct from "../Pages/UnAuth/Products/TaskGlide/TaskGlideProduct";
import GlidesProducts from "../Pages/UnAuth/Products/TaskGlide/Glides/GlidesProducts";
import DocumentationSolution from "../Pages/UnAuth/Solutions/Documentation/DocumentationSolution";
import ResearchSolution from "../Pages/UnAuth/Solutions/Research/ResearchSolution";
import ProgrammingSolution from "../Pages/UnAuth/Solutions/Programming/ProgrammingSolution";
import EducationSolution from "../Pages/UnAuth/Solutions/Education/EducationSolution";
import SubscriptionPlan from "../Pages/Auth/Billing/Subscriptions/SubscriptionManagement/SubscriptionPlan";
import SubscriptionConfirmation from "../Pages/Auth/Registration/Forms/SubscriptionConfirmation";
import TermsOfUse from "../Pages/UnAuth/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "../Pages/UnAuth/PrivacyPolicy/PrivacyPolicy";
import UsagePolicies from "../Pages/UnAuth/UsagePolicies/UsagePolicies";
import MemoMinderCalendar from "../Pages/Auth/Products/MemoMinder/Calendar/MemoMinderCalendar";
import MemoMinderSettings from "../Pages/Auth/Products/MemoMinder/Settings/MemoMinderSettings";


export const routeComponents = [
    // TODO: Remove this test route
    { path: '/test', component: Test },

    // Register, Login, Forgot Password Routes
    {
        path: routes.unAuth.register.href,
        component: UserManagementUnAuthWrapper,
        page: Register,
        name: routes.unAuth.register.label,
        keywords: ['register', 'sign up', 'create account'],
        searchable: true
    },
    {
        path: routes.unAuth.login.href,
        component: UserManagementUnAuthWrapper,
        page: Login,
        name: routes.unAuth.login.label,
        keywords: ['login', 'sign in', 'access account'],
        searchable: true
    },
    {
        path: routes.unAuth.forgotPassword.href,
        component: UserManagementUnAuthWrapper,
        page: ForgotPassword,
        name: routes.unAuth.forgotPassword.label,
        keywords: ['forgot password', 'reset password', 'recover account'],
        searchable: true
    },
    {
        path: routes.unAuth.resetPassword.href,
        component: UserManagementUnAuthWrapper,
        page: ResetPassword,
        name: routes.unAuth.resetPassword.label,
        keywords: ['reset password', 'recover account'],
        searchable: true
    },
    {
        path: routes.unAuth.accountVerification.href,
        component: UserManagementUnAuthWrapper,
        page: VerifyAccount,
        name: routes.unAuth.accountVerification.label,
        keywords: ['verify account', 'confirm account', 'activate account'],
        searchable: false
    },
    { path: routes.unAuth.successfulAccountVerification.href, component: UserManagementUnAuthWrapper, page: AccountVerificationResponse, name: routes.unAuth.successfulAccountVerification.label, searchable: false },
    { path: routes.unAuth.invalidAccountVerification.href, component: UserManagementUnAuthWrapper, page: AccountVerificationResponse, name: routes.unAuth.invalidAccountVerification.label, searchable: false },
    { path: routes.unAuth.expiredAccountVerification.href, component: UserManagementUnAuthWrapper, page: AccountVerificationResponse, name: routes.unAuth.expiredAccountVerification.label, searchable: false },

    // Unauthenticated routes
    {
        path: routes.unAuth.home.href,
        component: UnAuthWrapper, page: Landing,
        name: routes.unAuth.home.label,
        keywords: ['home', 'landing', 'welcome', 'start', 'begin'],
        searchable: true
    },
    {
        path: routes.unAuth.about.href,
        component: UnAuthWrapper, page: About,
        name: routes.unAuth.about.label,
        keywords: ['about', 'information', 'details', 'company', 'us'],
        searchable: true
    },
    {
        path: routes.unAuth.contact.href,
        component: UnAuthWrapper, page: Contact,
        name: routes.unAuth.contact.label,
        keywords: ['contact', 'reach out', 'message', 'email', 'phone'],
        searchable: true
    },
    {
        path: routes.unAuth.termsOfUse.href,
        component: UnAuthWrapper, page: TermsOfUse,
        name: routes.unAuth.termsOfUse.label,
        keywords: ['privacy', 'policies', 'terms', 'use'],
        searchable: true
    },
    {
        path: routes.unAuth.privacyPolicy.href,
        component: UnAuthWrapper, page: PrivacyPolicy,
        name: routes.unAuth.privacyPolicy.label,
        keywords: ['privacy', 'policies', 'terms'],
        searchable: true
    },
    {
        path: routes.unAuth.usagePolicies.href,
        component: UnAuthWrapper, page: UsagePolicies,
        name: routes.unAuth.usagePolicies.label,
        keywords: ['privacy', 'policies', 'terms', 'usage'],
        searchable: true
    },

    // Product page routes
    {
        path: (routes.unAuth.products.find(product => product.name === 'Pricing') || {}).href,
        component: UnAuthWrapper,
        page: Pricing,
        name: (routes.unAuth.products.find(product => product.name === 'Pricing').name || {}),
        keywords: ['pricing', 'cost', 'price', 'plans', 'subscription'],
        searchable: true
    },
    {
        path: (routes.unAuth.products.find(product => product.name === 'Store') || {}).href,
        component: UnAuthWrapper,
        page: Store,
        name: (routes.unAuth.products.find(product => product.name === 'Store').name || {}),
        keywords: ['store', 'shop', 'buy', 'purchase', 'products'],
        searchable: true
    },
    {
        path: (routes.unAuth.products.find(product => product.name === 'Scholar') || {}).href,
        component: UnAuthWrapper,
        page: ComingSoon,
        name: (routes.unAuth.products.find(product => product.name === 'Scholar').name || {}),
        keywords: ['scholar', 'education', 'learn', 'teach', 'school'],
        searchable: true
    },
    {
        path: (routes.unAuth.products.find(product => product.name === 'CodeCraft') || {}).href,
        component: UnAuthWrapper,
        page: ComingSoon,
        name: (routes.unAuth.products.find(product => product.name === 'CodeCraft').name || {}),
        keywords: ['codecraft', 'code', 'develop', 'build', 'create'],
        searchable: true
    },
    {
        path: (routes.unAuth.products.find(product => product.name === 'SavantIQ') || {}).href,
        component: UnAuthWrapper,
        page: ComingSoon,
        name: (routes.unAuth.products.find(product => product.name === 'SavantIQ').name || {}),
        keywords: ['savantiq', 'savant', 'student', 'study', 'learn'],
        searchable: true
    },
    {
        path: (routes.unAuth.products.find(product => product.name === 'ProseMaster') || {}).href,
        component: UnAuthWrapper,
        page: ComingSoon,
        name: (routes.unAuth.products.find(product => product.name === 'ProseMaster').name || {}),
        keywords: ['prosemaster', 'prose', 'write', 'compose', 'author', 'documentation'],
        searchable: true
    },
    {
        path: (routes.unAuth.products.find(product => product.name === 'DataDive') || {}).href,
        component: UnAuthWrapper,
        page: ComingSoon,
        name: (routes.unAuth.products.find(product => product.name === 'DataDive').name || {}),
        keywords: ['datadive', 'data', 'dive', 'analyze', 'explore', 'discover', 'insight', 'analysis'],
        searchable: true
    },
    {
        path: (routes.unAuth.products.find(product => product.name === 'FiscalFlow') || {}).href,
        component: UnAuthWrapper,
        page: ComingSoon,
        name: (routes.unAuth.products.find(product => product.name === 'FiscalFlow').name || {}),
        keywords: ['fiscalflow', 'fiscal', 'flow', 'finance', 'money', 'budget', 'spend', 'save'],
        searchable: true
    },

    // Solutions Marketing page routes
    {
        path: (routes.unAuth.solutions.find(solution => solution.name === 'Programming') || {}).href,
        component: UnAuthWrapper,
        page: ProgrammingSolution,
        name: (routes.unAuth.solutions.find(solution => solution.name === 'Programming').name || {}),
        keywords: ['programming', 'code', 'develop', 'build', 'create'],
        searchable: true
    },
    {
        path: (routes.unAuth.solutions.find(solution => solution.name === 'Research') || {}).href,
        component: UnAuthWrapper,
        page: ResearchSolution,
        name: (routes.unAuth.solutions.find(solution => solution.name === 'Research').name || {}),
        keywords: ['research', 'study', 'learn', 'explore', 'discover', 'insight'],
        searchable: true
    },
    {
        path: (routes.unAuth.solutions.find(solution => solution.name === 'Documentation') || {}).href,
        component: UnAuthWrapper,
        page: DocumentationSolution,
        name: (routes.unAuth.solutions.find(solution => solution.name === 'Documentation').name || {}),
        keywords: ['documentation', 'write', 'compose', 'author', 'documentation'],
        searchable: true
    },
    {
        path: (routes.unAuth.solutions.find(solution => solution.name === 'Education') || {}).href,
        component: UnAuthWrapper,
        page: EducationSolution,
        name: (routes.unAuth.solutions.find(solution => solution.name === 'Education').name || {}),
        keywords: ['education', 'learn', 'teach', 'school'],
        searchable: true
    },

    // Child product routes
    {
        path: (routes.unAuth.products.find(product => product.name === 'TaskGlide').children.find(child => child.name === 'Glides') || {}).href,
        component: UnAuthWrapper,
        page: GlidesProducts,
        name: (routes.unAuth.products.find(product => product.name === 'TaskGlide').children.find(child => child.name === 'Glides').name || {}),
        keywords: ['glides', 'glide', 'create', 'build', 'develop', 'setup', 'new', 'existing'],
        searchable: true
    },

    // Developed product routes
    {
        path: (routes.unAuth.products.find(product => product.name === 'TaskGlide')).href,
        component: UnAuthWrapper,
        page: TaskGlideProduct,
        name: (routes.unAuth.products.find(product => product.name === 'TaskGlide').name || {}),
        keywords: ['taskglide', 'task', 'glide', 'chat', 'messaging', 'communication' ,'basic', 'simple', 'generic'],
        searchable: true
    },

    // Settings routes
    { path: (routes.auth.settings.account.href), component: AuthWrapper, page: AccountSettings },
    { path: (routes.auth.settings.preferences.href), component: AuthWrapper, page: PreferenceSettings },

    // Billing routes
    { path: (routes.auth.billing.payment.href), component: AuthWrapper, page: LinkedCards },
    { path: (routes.auth.billing.invoices.href), component: AuthWrapper, page: Invoices },
    { path: (routes.auth.billing.transaction.href), component: AuthWrapper, page: Transaction },
    { path: (routes.auth.billing.subscriptions.find(plan => plan?.label === 'Subscriptions').href), component: AuthWrapper, page: Subscriptions },
    { path: (routes.auth.billing.subscriptions.find(plan => plan?.label === 'Subscription Plan').href), component: AuthWrapper, page: SubscriptionPlan },

    // TaskGlide routes
    { path: (routes.auth.taskGlide.home.href), component: AuthWrapper, page: TaskGlideChat },
    { path: (routes.auth.registration.find(product => product.name === 'Registration') || {}).href, component: AuthWrapper, page: Registration },
    { path: (routes.auth.taskGlide.googleOAuthRedirectHandler.href), component: AuthWrapper, page: GoogleOAuthRedirectHandler },
    { path: (routes.auth.taskGlide.settings.href), component: AuthWrapper, page: TaskGlideSettings },

    // MyGlides routes
    { path: (routes.auth.taskGlide.glideStore.href), component: AuthWrapper, page: GlideStore },
    { path: (routes.auth.taskGlide.myGlides.href), component: AuthWrapper, page: MyGlides },
    { path: (routes.auth.taskGlide.newGlideSetup.href), component: AuthWrapper, page: NewGlideCreatorSetup },
    { path: (routes.auth.taskGlide.existingGlideSetup.href), component: AuthWrapper, page: ExistingGlideCreatorSetup },
    { path: (routes.auth.taskGlide.glideTest.href), component: AuthWrapper, page: GlideCreatorTest },

    // MemoMinder routes
    { path: (routes.auth.memoMinder.calendar.href), component: AuthWrapper, page: MemoMinderCalendar },
    { path: (routes.auth.memoMinder.settings.href), component: AuthWrapper, page: MemoMinderSettings },

    // 404
    { path: '*', component: UnAuthWrapper, page: NotFound },

    // Feedbacks
    { path: (routes.auth.feedbacks.find(feedback => feedback.label === 'Subscription Confirmation').href), component: AuthWrapper, page: SubscriptionConfirmation },
];
