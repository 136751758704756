import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearUserPreferencesRetrievalVF, userPreferencesRETRIEVE} from "../../../api/features/users/getUserPreferencesSlice";
import {useAuthentication} from "./useAuthentication";


export const useGetUserPreferences = ({vfExists=false} = {}) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();

    const {
        userPreferencesRetrievalApiState,
        userPreferencesRetrievalVF,
        userPreferencesRetrievalResponse,
        userPreferencesRetrievalError,
    } = useSelector(state => state.getUserPreferencesReducer);

    const userPreferencesRetrievalSuccessVF = userPreferencesRetrievalApiState === 'succeeded' && userPreferencesRetrievalVF;

    const getUserPreferences = () => userIsAuthenticated && dispatch(userPreferencesRETRIEVE());

    useEffect(() => {
        if (vfExists && userPreferencesRetrievalSuccessVF) {
            const timer = setTimeout(() => {
                dispatch(clearUserPreferencesRetrievalVF());
            }, 1500);

            return () => clearTimeout(timer);
        }
    }, [userPreferencesRetrievalApiState]);

    return {
        getUserPreferences,
        userPreferencesRetrievalApiState,
        userPreferencesRetrievalResponse,
        userPreferencesRetrievalError,
        userPreferencesRetrievalVF,
        userPreferencesRetrievalSuccessVF,
    };
};
