import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearUserDefinedGlideUpdateVF, resetUserDefinedGlideUpdate, userDefinedGlidePUT} from "../../../../../api/features/superIntelligence/taskGlide/glides/updateUserGlideSlice";
import {useGetUserGlideDetails} from "./useGetUserGlideDetails";


export const useUpdateUserGlide = (
    { vfExists=false, autoUpdate=false, uuid } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // MyGlides
    const {
        userDefinedGlideUpdateVF,
        userDefinedGlideUpdateApiState,
        userDefinedGlideUpdateResponse,
        userDefinedGlideUpdateError,
    } = useSelector(state => state.updateUserDefinedGlideReducer);
    const { getUserDefinedGlide } = useGetUserGlideDetails();

    // API states with visual feedback
    const userDefinedGlideUpdateSuccessVF = userDefinedGlideUpdateApiState === 'succeeded' && userDefinedGlideUpdateVF;
    const userDefinedGlideUpdateFailureVF = userDefinedGlideUpdateApiState === 'failed' && userDefinedGlideUpdateVF;

    const updateUserDefinedGlide = (uuid, values) => userIsAuthenticated && dispatch(userDefinedGlidePUT({ uuid, values }));

    useEffect(() => {
        if (vfExists) {
            if (userDefinedGlideUpdateSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearUserDefinedGlideUpdateVF());
                }, 500);

                return () => clearTimeout(timer);
            } else if (userDefinedGlideUpdateFailureVF) {
                dispatch(setAlert({
                    message: userDefinedGlideUpdateResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUserDefinedGlideUpdate());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [userDefinedGlideUpdateApiState, userDefinedGlideUpdateVF]);

    useEffect(() => {
        if (autoUpdate && userDefinedGlideUpdateApiState === 'succeeded' && !userDefinedGlideUpdateVF) {
            getUserDefinedGlide(uuid);
            dispatch(resetUserDefinedGlideUpdate());
        }
    }, [userDefinedGlideUpdateApiState, userDefinedGlideUpdateVF]);

    return {
        updateUserDefinedGlide,
        userDefinedGlideUpdateVF,
        userDefinedGlideUpdateApiState,
        userDefinedGlideUpdateResponse,
        userDefinedGlideUpdateError,
        userDefinedGlideUpdateSuccessVF,
        userDefinedGlideUpdateFailureVF
    };
};
