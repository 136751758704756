import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {useCallBackend} from "../../calls/useCallBackend";


export const unAuthenticate = createAsyncThunk(
    'authentication/logout',
    async (_, thunkAPI) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
});


export const loginPOST = createAsyncThunk(
    'authentication/login',
    async (values, thunkAPI) => {
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_LOGIN_PATH}`,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


// Combine initial states
const INITIAL_STATE = {
    authenticationApiState: 'idle',
    authenticationVF: false,
    userIsAuthenticated: !!localStorage.getItem('accessToken') || !!localStorage.getItem('refreshToken'),
    authenticationResponse: null,
    authenticationError: null
};

// Single slice for auth
export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: INITIAL_STATE,
    reducers: {
        clearAuthenticationVF: (state) => {
            state.authenticationVF = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginPOST.pending, (state) => {
                state.authenticationApiState = 'loading';
                state.authenticationVF = true;
            })
            .addCase(loginPOST.fulfilled, (state, action) => {
                state.authenticationApiState = 'succeeded';
                state.authenticationResponse = action.payload;
                state.authenticationVF = true;
                state.userIsAuthenticated = true;
                state.authenticationError = null;
                localStorage.setItem('accessToken', action.payload?.access);
                localStorage.setItem('refreshToken', action.payload?.refresh);
            })
            .addCase(loginPOST.rejected, (state, action) => {
                state.authenticationApiState = 'failed';
                state.authenticationError = action?.payload;
                state.authenticationVF = true;
                state.authenticationResponse = null;
            })
            .addCase(unAuthenticate.fulfilled, (state) => {
                state.authenticationApiState = 'succeeded';
                state.userIsAuthenticated = false;
                state.authenticationVF = true;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET' || action.type === 'RESET_USER_MANAGEMENT') {
                    state.authenticationApiState = 'idle';
                    state.authenticationVF = false;
                    state.userIsAuthenticated = false;
                    state.authenticationResponse = null;
                    state.authenticationError = null;
                }
            });
    }
});

export const {
    clearAuthenticationVF,
} = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
