import React from "react";
import {Link} from "react-router-dom";
import {CgSpinner} from "react-icons/cg";
import clsx from "clsx";
import PropTypes from "prop-types";

import routes from "../../../../../Routes/routes";
import {useValidatePromoCode} from "../../../../../Hooks/apiHooks/payments/useValidatePromoCode";
import {useListSubscriptionPlans} from "../../../../../Hooks/apiHooks/payments/useListSubscriptionPlans";


const SubscriptionFormSubmission = (
    { selectedPlans, submissionDisabledState, submissionLoadingState }
) => {
    const { subscriptionPlansListingResponse } = useListSubscriptionPlans({ vfExists: false });
    const { promoCodeValidationResponse } = useValidatePromoCode({ vfExists: false });

    const calculateDiscountAmount = () => {
        if (!promoCodeValidationResponse) {return 0;}
        let totalBeforeDiscount = subscriptionPlansListingResponse?.reduce((total, plan) => {
            if (selectedPlans.includes(plan.price_id)) {
                return total + Number(plan.amount);
            }
            return total;
        }, 0);

        if (promoCodeValidationResponse?.discount_type === "percent_off") {
            return totalBeforeDiscount * (promoCodeValidationResponse?.discount_amount / 100);
        } else if (promoCodeValidationResponse?.discount_type === "amount_off") {
            return promoCodeValidationResponse?.discount_amount;
        }
        return 0;
    }

    const calculateTotal = () => {
        if (!subscriptionPlansListingResponse || !selectedPlans.length) {return 0;}
        return subscriptionPlansListingResponse.reduce((total, plan) => {
            if (selectedPlans.includes(plan.price_id)) {
                // Ensure the amount is treated as a number and add to total
                return total + Number(plan.amount);
            }
            return total;
        }, 0);
    };

    return (
        <div className="col-span-full text-sm">
            <div className="py-5 space-y-2">
                <div className="flex items-center justify-between text-base-content/70">
                    <p>Subtotal</p>
                    <div className="flex items-center space-x-0.5">
                        <p>${calculateTotal()}</p>
                        <p>/month</p>
                    </div>
                </div>
                {promoCodeValidationResponse && (
                    <div className="flex items-center justify-between text-base-content/70">
                        <p>Discount</p>
                        <div className="flex items-center space-x-0.5">
                            <p>-${calculateDiscountAmount()}</p>
                            <p>/month</p>
                        </div>
                    </div>)}
                <div className="flex items-center justify-between font-medium">
                    <p>Total</p>
                    <div className="flex items-center space-x-0.5">
                        <p>${calculateTotal() - calculateDiscountAmount()}</p>
                        <p>/month</p>
                    </div>
                </div>
            </div>
            <div className="col-span-full flex items-center justify-end gap-x-3 mt-2">
                <Link
                    to={routes.auth.registration.find(form => form.name === "Preferences").href}
                    className="empty-button"
                >
                    Previous
                </Link>
                <button
                    type="submit"
                    disabled={submissionDisabledState}
                    className="primary-button"
                >
                    <CgSpinner
                        className={clsx(
                            "absolute inset-0 m-auto animate-spin h-5 w-5",
                            submissionLoadingState ? "" : "hidden"
                        )}
                        aria-hidden="true"
                    />
                    <p className={submissionLoadingState ? "invisible" : ""}>
                        Subscribe
                    </p>
                </button>
            </div>
        </div>
    )
};

SubscriptionFormSubmission.propTypes = {
    selectedPlans: PropTypes.array,
    submissionDisabledState: PropTypes.bool,
    submissionLoadingState: PropTypes.bool,
};

export default SubscriptionFormSubmission;
