import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearPinnedMessageRetrievalVF, pinnedMessageGET, resetPinnedMessageRetrieval} from "../../../../../api/features/superIntelligence/taskGlide/conversations/getPinnedMessageSlice";


export const useGetPinnedMessage = (
    {vfExists=false} = {}
) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();
    const {
        pinnedMessageRetrievalVF,
        pinnedMessageRetrievalApiState,
        pinnedMessageRetrievalResponse,
        pinnedMessageRetrievalError,
    } = useSelector(state => state.getPinnedMessageReducer);

    const pinnedMessageRetrievalSuccessVF = pinnedMessageRetrievalApiState === 'succeeded' && pinnedMessageRetrievalVF;
    const pinnedMessageRetrievalFailureVF = pinnedMessageRetrievalApiState === 'failed' && pinnedMessageRetrievalVF;

    const getPinnedMessage = (conversation_uuid) => {
        if (userIsAuthenticated) {
            dispatch(pinnedMessageGET({ conversation_uuid }));
        }
    };

    useEffect(() => {
        if (vfExists) {
            if (pinnedMessageRetrievalSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearPinnedMessageRetrievalVF());
                }, 100);

                return () => clearTimeout(timer);
            } else if (pinnedMessageRetrievalFailureVF) {
                dispatch(setAlert({
                    message: pinnedMessageRetrievalResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetPinnedMessageRetrieval());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [pinnedMessageRetrievalApiState, pinnedMessageRetrievalVF]);

    return {
        getPinnedMessage,
        pinnedMessageRetrievalVF,
        pinnedMessageRetrievalApiState,
        pinnedMessageRetrievalResponse,
        pinnedMessageRetrievalError,
        pinnedMessageRetrievalSuccessVF,
        pinnedMessageRetrievalFailureVF,
    };
};
