import React, {useEffect, useRef} from "react";
import {FaRegStopCircle} from "react-icons/fa";
import {BsArrowRightSquareFill} from "react-icons/bs";
import PropTypes from "prop-types";
import clsx from "clsx";

import ButtonAndDropDown from "./ButtonAndDropDown";
import TaskGlidePhotoFileInputField from "../../../Pages/Auth/Products/TaskGlide/Chat/InputField/Fields/TaskGlidePhotoFileInputField";


const GenericChatInputField = (
    {
        formik,
        handleAbort,
        disabled,
        placeholder,
        loadingState,
        hasMenu,
        additionalMenuButtons,
        selectedGlides,
        selectedPhotos,
        selectedFiles,
        handleFileSelect,
        handlePhotoSelect,
        handleFileRemove,
        handlePhotoRemove,
    }
) => {
    const textRef = useRef(null);

    const adjustTextareaHeight = () => {
        const textarea = textRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();

            if (formik.values.text_input.trim() !== '') {
                formik.handleSubmit();
                adjustTextareaHeight();
            }}
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [formik.values.text_input]);

    useEffect(() => {
        if (textRef.current) {
            textRef.current.focus();
        }
    }, [disabled]);

    const handleFileOrPhotoSelect = (event) => {
        const files = event.target.files;
        Array.from(files).forEach(file => {
            if (file.type.startsWith('image/')) {
                handlePhotoSelect({ target: { files: [file] } });
            } else {
                handleFileSelect({ target: { files: [file] } });
            }
        });
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="flex-1 flex flex-row w-full px-0.5">
                <div className="relative flex h-full flex-1 items-stretch md:flex-col">
                    <div className="rounded-xl w-full outline-0 focus:ring-0 flex bg-base-200 ring-[0.5px] ring-base-content/20 shadow-[0px_0px_5px_rgba(0,0,0,0.10)] dark:shadow-[0px_0px_10px_rgba(0,0,0,0.50)]">
                        <div className="flex flex-col w-full flex-grow relative items-center">
                            <TaskGlidePhotoFileInputField
                                selectedPhotos={selectedPhotos}
                                selectedFiles={selectedFiles}
                                handleFileRemove={handleFileRemove}
                                handlePhotoRemove={handlePhotoRemove}
                            />
                            <div className="relative flex w-full">
                                <ButtonAndDropDown
                                    handleFileOrPhotoSelect={handleFileOrPhotoSelect}
                                    disabled={disabled}
                                    hasMenu={hasMenu}
                                    additionalMenuButtons={additionalMenuButtons}
                                />
                                <textarea
                                    ref={textRef}
                                    disabled={disabled}
                                    rows={1}
                                    // maxLength={8000}
                                    name="text_input"
                                    id="text_input"
                                    placeholder={placeholder ? placeholder : `Chat with ${selectedGlides?.length === 1 ? selectedGlides?.[0]?.name : "your Super-Intelligents"}`}
                                    className={clsx(
                                        "m-0 w-full bg-transparent focus-visible:ring-0 max-h-32 h-full overflow-y-auto focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light ring-0 focus:ring-0 outline-0 textarea-md text-base scrollbar resize-none bg-base-100 mr-12 py-3 border-0 leading-7", hasMenu ? "ml-10" : "ml-2"
                                    )}
                                    value={formik.values?.text_input}
                                    onChange={formik.handleChange}
                                    onKeyDown={handleKeyDown}
                                />
                                <button
                                    type={loadingState ? "button" : "submit"}
                                    disabled={!loadingState && formik.values?.text_input.trim() === ''}
                                    className="absolute right-3 bottom-2 my-0 flex items-center group/submit outline-none disabled:pointer-events-none rounded-md h-fit place-self-center bg-transparent p-1.5 disabled:opacity-50 text-base-content disabled:hover:sm:text-base-content"
                                    onClick={loadingState ? handleAbort : undefined}
                                >
                                    {loadingState ? <FaRegStopCircle className="h-6 w-6 shrink-0"/> : <BsArrowRightSquareFill className="h-6 w-6 shrink-0"/>}
                                    <div className="top-tooltips bottom-12 group-disabled/submit:sm:hidden group-hover/submit:sm:block">{loadingState ? "Stop" : "Send"}</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <p className="px-3 py-2 mx-0 text-xs text-center text-base-300 w-full break-all whitespace-nowrap line-clamp-1 overflow-hidden" >
                {`${selectedGlides?.length === 1 ? selectedGlides?.[0]?.name : "The Super-Intelligents"} can make mistakes from time to time. Please use with caution.`}
            </p>
        </form>
    )
};

GenericChatInputField.propTypes = {
    formik: PropTypes.object,
    handleAbort: PropTypes.func,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    loadingState: PropTypes.bool,
    hasMenu: PropTypes.bool,
    allowFileAttachment: PropTypes.bool,
    additionalMenuButtons: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node
    ]),
    selectedGlides: PropTypes.array,
    selectedPhotos: PropTypes.array,
    selectedFiles: PropTypes.array,
    handleFileSelect: PropTypes.func,
    handlePhotoSelect: PropTypes.func,
    handleFileRemove: PropTypes.func,
    handlePhotoRemove: PropTypes.func,
};

GenericChatInputField.defaultProps = {
    hasMenu: true,
    allowFileAttachment: true,
    allowPhotoAttachment: true,
}

export default GenericChatInputField;
