import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import PropTypes from "prop-types";
import clsx from "clsx";

import {useCustomHLJS} from "./MdCodeStyles";
import CodeHeader from "./CodeHeader";


const CodeComponent = (
    { inline, className, codeClassName, includeCodeHeader=true, codeIsCustomized, children, ...props }
) => {
    const style = useCustomHLJS();
    const language = /language-(\w+)/.exec(className || "");

    if (!inline && language) {
        return (
            <div className={clsx(
                "relative flex flex-col m-0 code-wrapper hide-scrollbar rounded-md break-all whitespace-pre-wrap",
                (codeIsCustomized && !codeClassName) ? "bg-base-200 border-[0.5px] border-neutral-focus/50 dark:border-neutral-focus-dark/50 p-3 my-1" : codeClassName
            )} >
                {(codeIsCustomized && includeCodeHeader) && <CodeHeader code={children} language={language}/>}
                <div className={clsx("px-2", includeCodeHeader && "mt-6")} >
                    <SyntaxHighlighter
                        wrapLines={true}
                        lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                        className="bg-transparent"
                        language={language[1]}
                        style={(codeIsCustomized && style)}
                        PreTag={"div"}
                        {...props}
                    >
                        {children}
                    </SyntaxHighlighter>
                </div>
            </div>
        )
    }
    return <code className={clsx(
        "rounded-sm font-mono w-fit bg-base-100 break-all whitespace-pre-wrap",
        codeIsCustomized && "font-semibold"
    )} >`{children}`</code>;
};

CodeComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    codeClassName: PropTypes.string,
    inline: PropTypes.bool,
    includeCodeHeader: PropTypes.bool,
    codeIsCustomized: PropTypes.bool,
};

export default CodeComponent;
