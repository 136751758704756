import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import * as yup from "yup";
import {Field, Form, Formik} from "formik";
import ISO6391 from 'iso-639-1';

import SingleSelect from "../../../../../Components/Selects/SingleSelect";
import routes from "../../../../../Routes/routes";
import PreferencesRegFormSkeleton from "./PreferencesRegFormSkeleton";
import {useUpdateUserPreferences} from "../../../../../Hooks/apiHooks/users/useUpdateUserPreferences";
import {useGetUserPreferences} from "../../../../../Hooks/apiHooks/users/useGetUserPreferences";
import {useGetRegistrationStatus} from "../../../../../Hooks/apiHooks/users/useGetRegistrationStatus";
import {useUpdateUserInfo} from "../../../../../Hooks/apiHooks/users/useUpdateUserInfo";
import {CgSpinner} from "react-icons/cg";
import clsx from "clsx";


export const languageList = ISO6391.getAllCodes().map((code, index) => ({
        id: index,
        name: ISO6391.getName(code),
    })
);

const validationSchema = yup.object().shape({
    alerts: yup.boolean(),
    offers: yup.boolean(),
    updates: yup.boolean(),
    language: yup.object().shape({
        id: yup.number().required("Please choose a language"),
        name: yup.string().required("Please choose a language"),
    }),
});

const PreferencesForm = () => {
    const navigate = useNavigate();

    // Registration Status
    const { registrationStatusResponse } = useGetRegistrationStatus();

    // User Info -- This is a hack to reset the updateUserInfo states and refresh the user info after the update from the previous form.
    // eslint-disable-next-line no-unused-vars
    const refreshUserInfoData = useUpdateUserInfo({ vfExists: false, refreshAndReset: true });

    // User PreferenceSettings
    const {getUserPreferences, userPreferencesRetrievalApiState, userPreferencesRetrievalVF, userPreferencesRetrievalResponse} = useGetUserPreferences({vfExists: true});
    const {updateUserPreferences, userPreferencesUpdateApiState, userPreferencesUpdateVF} = useUpdateUserPreferences({
        vfExists: true,
        refreshAndReset: false
    });

    // Formik Initial Values
    const initialValues = {
        alerts: userPreferencesRetrievalResponse?.alerts || false,
        offers: userPreferencesRetrievalResponse?.offers || false,
        updates: userPreferencesRetrievalResponse?.updates || false,
        language: languageList?.find(lang => lang.name === userPreferencesRetrievalResponse?.preferred_language) || languageList?.find(lang => lang.name === 'English')
    };

    // Formik Submit
    const onSubmit = (values, { setSubmitting }) => {
        updateUserPreferences(values);
        setSubmitting(false);
    };

    useEffect(() => {
        if (userPreferencesRetrievalApiState === 'idle') {
            // If user is authenticated and the user preferences retrieval api state is idle, then we need to get the user preferences for retrieving saved data.
            getUserPreferences();
        }
    }, [userPreferencesRetrievalApiState]);

    useEffect(() => {
        if (userPreferencesUpdateApiState === 'succeeded') {
            if (!userPreferencesUpdateVF) {
                // If the user preferences update api state is succeeded and the visual feedback has been cleared, then we need to navigate to the next form.
                navigate(routes.auth.registration.find(form => form.name === "Subscription")?.href);
            }
        }
    }, [userPreferencesUpdateApiState, userPreferencesUpdateVF]);

    if (userPreferencesRetrievalVF) {
        return <PreferencesRegFormSkeleton />
    }
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ isSubmitting, isValid, values }) => {
                    /* If the user has completed the registration process and the language field is empty, then we need to show the skeleton loader. */
                    const isLanguageFieldEmpty = values.language.name === '';

                    if ((registrationStatusResponse?.user_preferences_completed && isLanguageFieldEmpty) || !languageList) {
                        return <PreferencesRegFormSkeleton />
                    }
                    return (
                        <Form>
                            <div className="grid grid-cols-1 gap-x-6 gap-y-10 lg:grid-cols-9 w-full px-px" >
                                <div className="col-span-full md:col-span-4 md:hidden space-y-1" >
                                    <h2 className="text-base font-semibold leading-7 text-base-content">Preferences</h2>
                                    <p className="text-sm leading-6 text-base-content/70">
                                        Choose your preferred language and communication preferences.
                                    </p>
                                </div>
                                <div className="col-span-full py-px grid grid-cols-1 gap-x-6 gap-y-8">
                                    <div className="relative flex gap-x-4">
                                        <div className="flex h-6 items-center">
                                            <Field
                                                name="alerts"
                                                type="checkbox"
                                                className="standard-checkbox"
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="alerts" className="font-medium text-base-content">
                                                Account Alerts
                                            </label>
                                            <p className="text-base-content/70">Get notified when we release a new feature or update.</p>
                                        </div>
                                    </div>
                                    <div className="relative flex gap-x-4">
                                        <div className="flex h-6 items-center">
                                            <Field
                                                name="offers"
                                                type="checkbox"
                                                className="standard-checkbox"
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="offers" className="font-medium text-base-content">
                                                Promotional Offers
                                            </label>
                                            <p className="text-base-content/70">Receive emails about special promotions and discounts.</p>
                                        </div>
                                    </div>
                                    <div className="relative flex gap-x-4">
                                        <div className="flex h-6 items-center">
                                            <Field
                                                name="updates"
                                                type="checkbox"
                                                className="standard-checkbox"
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="updates" className="font-medium text-base-content">
                                                Updates
                                            </label>
                                            <p className="text-base-content/70">Get notified about important account activity.</p>
                                        </div>
                                    </div>
                                    <div className="relative col-span-full my-2">
                                        <label htmlFor="last-name" className="absolute -top-2 left-2.5 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-light text-base-content/70 z-10 leading-none" >
                                            Preferred Language
                                        </label>
                                        <SingleSelect
                                            name="language"
                                            list={languageList}
                                            styles="bg-base-100 dark:bg-base-200 text-base-content placeholder:text-base-content/30"
                                            placeholder={"English"}
                                        />
                                    </div>
                                    <div className="flex items-center justify-end gap-x-3">
                                        <Link
                                            to={routes.auth.registration.find(form => form.name === "Personal Information").href}
                                            className="empty-button"
                                        >
                                            Previous
                                        </Link>
                                        <button
                                            type="submit"
                                            disabled={isSubmitting || !isValid || (!values.language || values.language.name === '') || userPreferencesUpdateVF}
                                            className="primary-button"
                                        >
                                            <CgSpinner
                                                className={clsx(
                                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                                    (userPreferencesUpdateVF || isSubmitting) ? "" : "hidden"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <p className={(userPreferencesUpdateVF || isSubmitting) ? "invisible" : ""}>
                                                Next
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}}
            </Formik>
        </>
    )
};

PreferencesForm.propTypes = {

}

export default PreferencesForm;
