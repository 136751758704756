import React from "react";
import PropTypes from "prop-types";
import {motion} from "framer-motion";
import {PiFilePdfThin, PiFileCsvThin, PiFileDocThin, PiFileJpgThin, PiFileJsxThin, PiFilePngThin, PiFileCloudThin} from "react-icons/pi";
import {IoArrowDownCircleOutline, IoArrowDownCircleSharp} from "react-icons/io5";

import {useDownloadDocument} from "../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDownloadDocument";
import {CgSpinner} from "react-icons/cg";


export const FileIcon = ({ fileFormat, className }) => {
    const icons = {
        'pdf': <PiFilePdfThin className={className} />,
        'csv': <PiFileCsvThin className={className} />,
        'doc': <PiFileDocThin className={className} />,
        'docx': <PiFileDocThin className={className} />,
        'jpg': <PiFileJpgThin className={className} />,
        'jsx': <PiFileJsxThin className={className} />,
        'png': <PiFilePngThin className={className} />
    };
    return icons[fileFormat?.toLowerCase()] || <PiFileCloudThin className={className} />;
};

FileIcon.propTypes = {
    fileFormat: PropTypes.string,
    className: PropTypes.string,
};


const TaskGlideDocuments = ({ document }) => {
    const { downloadDocument } = useDownloadDocument({ vfExists: true });

    if (!document?.document_uuid) {
        return <div className="flex justify-center items-center h-44 w-full bg-base-300/50 animate-pulse duration-700 col-span-2 sm:col-span-1 bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 rounded-md px-3 py-2.5 self-end text-xs overflow-hidden max-w-3xs">
            <CgSpinner className="w-5 h-5 animate-spin duration-200"/>
        </div>
    }
    return (
        <div
            key={document?.document_uuid}
            className="col-span-2 sm:col-span-1 bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 rounded-md px-3 py-2.5 w-fit h-full self-end text-xs items-center overflow-hidden flex flex-col space-y-2 justify-between text-center max-w-3xs"
        >
            <div className="flex flex-col justify-center space-y-2" >
                <div className="self-center bg-base-100 p-0.5 rounded-md ring-[0.5px] ring-inset ring-base-content shadow w-fit">
                    <FileIcon fileFormat={document?.file_format || document?.type?.split('/')[0]} className="size-10 shrink-0"/>
                </div>
                <div className="space-y-1 w-fit overflow-hidden break-all">
                    <p>{document?.file_name || document?.name}</p>
                    <p className="uppercase font-light">{document?.file_format || document?.type?.split('/')[0]}</p>
                </div>
            </div>
            <button
                type="button"
                onClick={() => downloadDocument(document)}
                className="h-fit bg-base-100 rounded-full group/user-download-btn"
                disabled={!document?.document_uuid}
            >
                <IoArrowDownCircleOutline className="w-5 h-5 flex group-hover/user-download-btn:sm:hidden shrink-0"/>
                <IoArrowDownCircleSharp className="w-5 h-5 hidden group-hover/user-download-btn:sm:flex shrink-0"/>
            </button>
        </div>
    );
};

TaskGlideDocuments.propTypes = {
    document: PropTypes.object,
};

export default TaskGlideDocuments;
