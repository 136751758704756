import routes from "../../Routes/routes";

export const footerNav = {
    solutions: routes.unAuth.solutions.slice(1),
    products: routes.unAuth.products.slice(2),
    company: [
        { name: routes.unAuth.about.label, href: routes.unAuth.about.href },
        { name: routes.unAuth.contact.label, href: routes.unAuth.contact.href },
    ],
    legal: [
        { name: routes.unAuth.privacyPolicy.label, href: routes.unAuth.privacyPolicy.href },
        { name: routes.unAuth.termsOfUse.label, href: routes.unAuth.termsOfUse.href },
        { name: routes.unAuth.usagePolicies.label, href: routes.unAuth.usagePolicies.href },
    ],
    social: routes.unAuth.socials,
};
