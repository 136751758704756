import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const allUserDefinedGlidesDELETE = createAsyncThunk(
    'taskGlide/glides/delete',
    async (thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'DELETE',
                    path: `${process.env.REACT_APP_TASKGLIDE_DELETE_ALL_USER_DEFINED_GLIDES_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userDefinedGlidesDeleteAllVF: false,
    userDefinedGlidesDeleteAllApiState: 'idle',
    userDefinedGlidesDeleteAllResponse: null,
    userDefinedGlidesDeleteAllError: null,
};

export const deleteAllUserGlidesSlice = createSlice({
    name: 'deleteAllUserDefinedGlides',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserDefinedGlidesDeleteAllVF: (state) => {
            state.userDefinedGlidesDeleteAllVF = false;
        },
        resetUserDefinedGlidesDeleteAll: (state) => {
            state.userDefinedGlidesDeleteAllVF = false;
            state.userDefinedGlidesDeleteAllApiState = 'idle';
            state.userDefinedGlidesDeleteAllResponse = null;
            state.userDefinedGlidesDeleteAllError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(allUserDefinedGlidesDELETE.pending, (state) => {
                state.userDefinedGlidesDeleteAllApiState = 'loading';
                state.userDefinedGlidesDeleteAllVF = true;
            })
            .addCase(allUserDefinedGlidesDELETE.fulfilled, (state, action) => {
                state.userDefinedGlidesDeleteAllApiState = 'succeeded';
                state.userDefinedGlidesDeleteAllResponse = action.payload;
                state.userDefinedGlidesDeleteAllVF = true;
                state.userDefinedGlidesDeleteAllError = null;
            })
            .addCase(allUserDefinedGlidesDELETE.rejected, (state, action) => {
                state.userDefinedGlidesDeleteAllApiState = 'failed';
                state.userDefinedGlidesDeleteAllError = action?.payload;
                state.userDefinedGlidesDeleteAllVF = true;
                state.userDefinedGlidesDeleteAllResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUserDefinedGlidesDeleteAllVF,
    resetUserDefinedGlidesDeleteAll
} = deleteAllUserGlidesSlice.actions;

export default deleteAllUserGlidesSlice.reducer;
