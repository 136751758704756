import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {changePasswordPOST, clearPasswordChangeVF, resetPasswordChange} from "../../../api/features/users/passwordChangeSlice";
import {useAuthentication} from "./useAuthentication";


export const useChangePassword = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();

    const {
        passwordChangeVF,
        passwordChangeApiState,
        passwordChangeResponse,
        passwordChangeError,
    } = useSelector(state => state.passwordChangeReducer);
    const { userIsAuthenticated } = useAuthentication();

    // API State with visual feedback
    const passwordChangeSuccessVF = passwordChangeApiState === 'succeeded' && passwordChangeVF;
    const passwordChangeFailureVF = passwordChangeApiState === 'failed' && passwordChangeVF;
    const passwordChangeLoadingVF = passwordChangeApiState === 'loading' && passwordChangeVF;

    // Function to be called on form submission
    const changePassword = (values) => userIsAuthenticated && dispatch(changePasswordPOST(values));

    useEffect(() => {
        if (vfExists) {
            if (passwordChangeSuccessVF) {
                dispatch(setAlert({
                    message: passwordChangeResponse?.message,
                    type: "success"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearPasswordChangeVF());
                }, 4000);

                return () => clearTimeout(timer);
            }  else if (passwordChangeFailureVF) {
                dispatch(setAlert({
                    message: passwordChangeError?.message || "Something went wrong. Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetPasswordChange());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [passwordChangeApiState]);

    return {
        changePassword,
        passwordChangeVF,
        passwordChangeApiState,
        passwordChangeResponse,
        passwordChangeError,
        passwordChangeSuccessVF,
        passwordChangeFailureVF,
        passwordChangeLoadingVF
    };
};
