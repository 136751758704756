import React from "react";


const GlideItemSkeleton = () => {

    return (
        <div className="grid grid-cols-1 grid-row-4 bg-base-100 spread-shadow rounded-lg p-5 space-y-5" >
            <div className="row-span-3 flex w-full items-center justify-between space-x-5">
                <div className="space-y-3.5 w-full flex-1">
                    <div className="flex items-center justify-start space-x-3">
                        <div className="bg-base-300/30 skeleton h-4 w-1/3 rounded"/>
                        <div className="flex items-center" >
                            <div className="rounded-full h-6 w-6 sm:h-5 sm:w-5 skeleton bg-[#E0E3E6] dark:bg-[#282828]" />
                            <div className="rounded-full h-6 w-6 sm:h-5 sm:w-5 skeleton -ml-1 bg-[#E0E3E6] dark:bg-[#282828]" />
                            <div className="rounded-full h-6 w-6 sm:h-5 sm:w-5 skeleton -ml-1 bg-[#E0E3E6] dark:bg-[#282828]" />
                            <div className="rounded-full h-6 w-6 sm:h-5 sm:w-5 skeleton -ml-1 bg-[#E0E3E6] dark:bg-[#282828]" />
                        </div>
                    </div>
                    <div className="space-y-2 pr-4">
                    <div className="bg-base-300/30 skeleton h-3 w-full rounded"/>
                        <div className="bg-base-300/30 skeleton h-3 w-1/2 rounded"/>
                    </div>
                    <div className="-mt-1 inline-flex space-x-2 rounded-md text-xs sm:text-2xs w-fit">
                        <div className="bg-base-300/30 skeleton h-2.5 w-20 rounded" />
                        <div className="flex items-center justify-between space-x-2" >
                            <div className="rounded-full w-1 h-1 bg-base-300/30 skeleton" />
                            <div className="h-2.5 w-5 bg-base-300/30 skeleton rounded" />
                        </div>
                    </div>
                </div>
                <div className="rounded-full bg-base-300/30 skeleton w-14 h-14 sm:w-12 sm:h-12" />
            </div>
            <div className="flex justify-end items-center space-x-3 row-span-1">
                <div className="rounded-md bg-base-300/30 skeleton w-20 h-8"/>
                <div className="rounded-md bg-base-300/30 skeleton w-14 h-8"/>
            </div>
        </div>
    )
};


const GlideStoreSkeleton = () => {
    const delay = (ms) => ({ animationDelay: `${ms}ms` });

    return (
        <div className="w-full z-10 h-[calc(100dvh-233px)] sm:h-[calc(100dvh-227px)] overflow-hidden py-12 bg-base-200">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-sm space-y-10">
                <div className="space-y-5">
                    <div className="bg-base-300/30 h-6 w-28 rounded skeleton"/>
                    <ul className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-9">
                        <GlideItemSkeleton/>
                        <GlideItemSkeleton/>
                    </ul>
                </div>
                <div className="space-y-5">
                    <div className="bg-base-300/30 h-6 w-28 rounded skeleton"/>
                    <ul className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-9">
                        <GlideItemSkeleton/>
                        <GlideItemSkeleton/>
                        <GlideItemSkeleton/>
                    </ul>
                </div>
                <div className="space-y-5" style={delay(350)}>
                    <div className="bg-base-300/30 h-6 w-28 rounded skeleton"/>
                    <ul className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-9">
                        <GlideItemSkeleton/>
                        <GlideItemSkeleton/>
                        <GlideItemSkeleton/>
                        <GlideItemSkeleton/>
                        <GlideItemSkeleton/>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default GlideStoreSkeleton;
