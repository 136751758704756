import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {useListUserGlides} from "./useListUserGlides";
import {allUserDefinedGlidesDELETE, clearUserDefinedGlidesDeleteAllVF, resetUserDefinedGlidesDeleteAll} from "../../../../../api/features/superIntelligence/taskGlide/glides/deleteAllUserGlidesSlice";


export const useDeleteAllUserGlides = (
    { vfExists=false, autoUpdate=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // MyGlides
    const {
        userDefinedGlidesDeleteAllVF,
        userDefinedGlidesDeleteAllApiState,
        userDefinedGlidesDeleteAllResponse,
        userDefinedGlidesDeleteAllError,
    } = useSelector(state => state.deleteAllUserDefinedGlidesReducer);
    const { getGlides } = useListUserGlides();

    // API states with visual feedback
    const userDefinedGlideCreateSuccessVF = userDefinedGlidesDeleteAllApiState === 'succeeded' && userDefinedGlidesDeleteAllVF;
    const userDefinedGlideCreateFailureVF = userDefinedGlidesDeleteAllApiState === 'failed' && userDefinedGlidesDeleteAllVF;

    const deleteAllUserDefinedGlides = () => userIsAuthenticated && dispatch(allUserDefinedGlidesDELETE());

    useEffect(() => {
        if (vfExists) {
            if (userDefinedGlideCreateSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearUserDefinedGlidesDeleteAllVF());
                }, 250);

                return () => clearTimeout(timer);
            } else if (userDefinedGlideCreateFailureVF) {
                dispatch(setAlert({
                    message: userDefinedGlidesDeleteAllResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUserDefinedGlidesDeleteAll());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [userDefinedGlidesDeleteAllApiState, userDefinedGlidesDeleteAllVF]);

    useEffect(() => {
        if (autoUpdate && userDefinedGlidesDeleteAllApiState === 'succeeded' && !userDefinedGlidesDeleteAllVF) {
            getGlides();
            dispatch(resetUserDefinedGlidesDeleteAll());
        }
    }, [userDefinedGlidesDeleteAllApiState, userDefinedGlidesDeleteAllVF]);

    return {
        deleteAllUserDefinedGlides,
        userDefinedGlidesDeleteAllVF,
        userDefinedGlidesDeleteAllApiState,
        userDefinedGlidesDeleteAllResponse,
        userDefinedGlidesDeleteAllError,
        userDefinedGlideCreateSuccessVF,
        userDefinedGlideCreateFailureVF
    };
};
