import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const testConversationGET = createAsyncThunk(
    'taskGlide/conversation/test/get',
    async ({ glide_uuid, page_size }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_GET_DELETE_TEST_CONVO_PATH}${glide_uuid}/?page_size=${page_size}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    testConversationRetrievalVF: false,
    testConversationRetrievalApiState: 'idle',
    testConversationRetrievalResponse: null,
    testConversationRetrievalError: null,
};

export const getTestConversationSlice = createSlice({
    name: 'getTestConversation',
    initialState: INITIAL_STATE,
    reducers: {
        clearTestConversationRetrievalVF: (state) => {
            state.testConversationRetrievalVF = false;
        },
        resetTestConversationRetrieval: (state) => {
            state.testConversationRetrievalVF = false;
            state.testConversationRetrievalApiState = 'idle';
            state.testConversationRetrievalResponse = null;
            state.testConversationRetrievalError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(testConversationGET.pending, (state) => {
                state.testConversationRetrievalApiState = 'loading';
                state.testConversationRetrievalVF = true;
            })
            .addCase(testConversationGET.fulfilled, (state, action) => {
                state.testConversationRetrievalApiState = 'succeeded';
                state.testConversationRetrievalResponse = action.payload;
                state.testConversationRetrievalVF = true;
                state.testConversationRetrievalError = null;
            })
            .addCase(testConversationGET.rejected, (state, action) => {
                state.testConversationRetrievalApiState = 'failed';
                state.testConversationRetrievalError = action?.payload;
                state.testConversationRetrievalVF = true;
                state.testConversationRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTestConversationRetrievalVF,
    resetTestConversationRetrieval,
} = getTestConversationSlice.actions;

export default getTestConversationSlice.reducer;
