import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {useAuthentication} from "../users/useAuthentication";
import {useGetRegistrationStatus} from "../users/useGetRegistrationStatus";
import {clearUndoCancellationVF, resetUndoCancellation, undoCancellationPOST} from "../../../api/features/payments/undoCancellationSlice";
import {useListUserSubscriptions} from "./useListUserSubscriptions";


export const useUndoCancellation = (
    { vfExists=false, refreshAndReset=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // API hooks
    const {
        undoCancellationVF,
        undoCancellationApiState,
        undoCancellationResponse,
        undoCancellationError,
    } = useSelector(state => state.undoCancellationReducer);
    const { getRegistrationStatus } = useGetRegistrationStatus();
    const { listUserSubscriptions } = useListUserSubscriptions();

    // API State with visual feedback
    const undoCancellationSuccessVF = undoCancellationApiState === 'succeeded' && undoCancellationVF;
    const undoCancellationFailureVF = undoCancellationApiState === 'failed' && undoCancellationVF;

    const undoCancellation = (price_id) => userIsAuthenticated && dispatch(undoCancellationPOST(price_id));

    useEffect(() => {
        if (vfExists) {
            if (undoCancellationSuccessVF) {
                dispatch(setAlert({
                    message: undoCancellationResponse?.message,
                    type: 'success'
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUndoCancellation());
                }, 4000);

                return () => clearTimeout(timer);
            } else if (undoCancellationFailureVF) {
                dispatch(setAlert({
                    message: undoCancellationError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUndoCancellation());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [undoCancellationVF, undoCancellationApiState]);

    useEffect(() => {
        if (refreshAndReset && undoCancellationApiState === 'succeeded') {
            getRegistrationStatus();
            listUserSubscriptions();
        }
    }, [undoCancellationVF, undoCancellationApiState]);

    return {
        undoCancellation,
        undoCancellationVF,
        undoCancellationSuccessVF,
        undoCancellationFailureVF,
        undoCancellationError,
        undoCancellationResponse,
        undoCancellationApiState
    };
};
