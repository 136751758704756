import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const allTgConversationsDELETE = createAsyncThunk(
    'taskGlide/conversations/delete',
    async ({ conversation_id }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'DELETE',
                    path: `${process.env.REACT_APP_TASKGLIDE_DELETE_ALL_CONVO_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    tgAllConversationsDeleteVF: false,
    tgAllConversationsDeleteApiState: 'idle',
    tgAllConversationsDeleteResponse: null,
    tgAllConversationsDeleteError: null,
};

export const deleteAllTgConversationsSlice = createSlice({
    name: 'deleteAllTGConversations',
    initialState: INITIAL_STATE,
    reducers: {
        clearTgAllConversationsDeleteVF: (state) => {
            state.tgAllConversationsDeleteVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(allTgConversationsDELETE.pending, (state) => {
                state.tgAllConversationsDeleteApiState = 'loading';
                state.tgAllConversationsDeleteVF = true;
            })
            .addCase(allTgConversationsDELETE.fulfilled, (state, action) => {
                state.tgAllConversationsDeleteApiState = 'succeeded';
                state.tgAllConversationsDeleteResponse = action.payload;
                state.tgAllConversationsDeleteVF = true;
                state.tgAllConversationsDeleteError = null;
            })
            .addCase(allTgConversationsDELETE.rejected, (state, action) => {
                state.tgAllConversationsDeleteApiState = 'failed';
                state.tgAllConversationsDeleteError = action?.payload;
                state.tgAllConversationsDeleteVF = true;
                state.tgAllConversationsDeleteResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTgAllConversationsDeleteVF
} = deleteAllTgConversationsSlice.actions;

export default deleteAllTgConversationsSlice.reducer;
