import React from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";

const BrowserFrame = ({ children, browserTab, browserColor, browserSize }) => {

    return (
        <div className={clsx("relative mockup-window shadow-[0px_0px_50px_rgba(0,0,0,0.20)] dark:shadow-[0px_0px_50px_rgba(0,0,0,0.75)] border border-neutral", browserColor, !browserSize ? "scale-75 w-[1100px] h-auto" : browserSize)} >
            <div className="absolute top-0 h-12 left-1/2 transform -translate-x-1/2 flex items-center justify-center w-1/3">
                <div
                    className="block w-full rounded-md border-0 py-1 text-base-content/70 shadow-sm ring-1 ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 placeholder:text-base-content/50 focus:ring-2 focus:ring-inset focus:ring-neutral text-sm sm:leading-6 bg-base-200 text-center whitespace-nowrap"
                >
                    {browserTab || process.env.frontendUrl}
                </div>
            </div>
            <div className="flex border-t border-neutral-focus/50 dark:border-neutral-focus-dark/50 bg-base-100 h-[680px] z-0">
                {children}
            </div>
        </div>
    )
};

BrowserFrame.propTypes = {
    children: PropTypes.node,
    browserTab: PropTypes.string,
    browserColor: PropTypes.string,
    browserSize: PropTypes.string,
}

export default BrowserFrame;
