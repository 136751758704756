import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const userDefinedGlidePUT = createAsyncThunk(
    'taskGlide/glides/user-defined/update',
    async ({ uuid, values }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'PUT',
                    path: `${process.env.REACT_APP_TASKGLIDE_UPDATE_USER_DEFINED_GLIDES_PATH}${uuid}/`,
                    accessCode: access,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userDefinedGlideUpdateVF: false,
    userDefinedGlideUpdateApiState: 'idle',
    userDefinedGlideUpdateResponse: null,
    userDefinedGlideUpdateError: null,
};

export const updateUserGlideSlice = createSlice({
    name: 'userDefinedGlideUpdate',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserDefinedGlideUpdateVF: (state) => {
            state.userDefinedGlideUpdateVF = false;
        },
        resetUserDefinedGlideUpdate: (state) => {
            state.userDefinedGlideUpdateVF = false;
            state.userDefinedGlideUpdateApiState = 'idle';
            state.userDefinedGlideUpdateResponse = null;
            state.userDefinedGlideUpdateError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userDefinedGlidePUT.pending, (state) => {
                state.userDefinedGlideUpdateApiState = 'loading';
                state.userDefinedGlideUpdateVF = true;
            })
            .addCase(userDefinedGlidePUT.fulfilled, (state, action) => {
                state.userDefinedGlideUpdateApiState = 'succeeded';
                state.userDefinedGlideUpdateResponse = action?.payload;
                state.userDefinedGlideUpdateVF = true;
                state.userDefinedGlideUpdateError = null;
            })
            .addCase(userDefinedGlidePUT.rejected, (state, action) => {
                state.userDefinedGlideUpdateApiState = 'failed';
                state.userDefinedGlideUpdateError = action?.payload;
                state.userDefinedGlideUpdateVF = true;
                state.userDefinedGlideUpdateResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUserDefinedGlideUpdateVF, resetUserDefinedGlideUpdate,
} = updateUserGlideSlice.actions;

export default updateUserGlideSlice.reducer;
