import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useElements, useStripe, Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";

import {cardLogos} from "../../../Billing/LinkedCards/LinkedCards";
import SubscriptionFormSkeleton from "./SubscriptionFormSkeleton";
import {useCreatePaymentMethod} from "../../../../../Hooks/apiHooks/payments/useCreatePaymentMethod";
import {useStripeCheckout} from "../../../../../Hooks/frontendHooks/useStripeCheckout";
import Alerts from "../../../../../Components/Alerts/Alerts";
import {useGetRegistrationStatus} from "../../../../../Hooks/apiHooks/users/useGetRegistrationStatus";
import {useUpdateUserPreferences} from "../../../../../Hooks/apiHooks/users/useUpdateUserPreferences";
import {useSetDefaultPaymentMethod} from "../../../../../Hooks/apiHooks/payments/useSetDefaultPaymentMethod";
import {useListSubscriptionPlans} from "../../../../../Hooks/apiHooks/payments/useListSubscriptionPlans";
import {useValidatePromoCode} from "../../../../../Hooks/apiHooks/payments/useValidatePromoCode";
import {useCreateSubscription} from "../../../../../Hooks/apiHooks/payments/useCreateSubscription";
import {useListPaymentMethods} from "../../../../../Hooks/apiHooks/payments/useListPaymentMethods";
import config from "../../../../../config";
import SubscriptionPlanList from "./SubscriptionPlanList";
import SubscriptionPaymentMethodForm from "./SubscriptionPaymentMethodForm";
import SubscriptionPromoSelection from "./SubscriptionPromoSelection";
import SubscriptionFormSubmission from "./SubscriptionFormSubmission";


const stripePromise = loadStripe(config.stripePublishableKey);

const validationSchemaNew = Yup.object().shape({

});

const validationSchemaExisting = Yup.object().shape({
    selectedPaymentMethod: Yup.object().required('Payment method is required'),
    promotion_code: Yup.string(),
    selectedPlans: Yup.array().min(1, 'At least one plan must be selected')
});

export const handleCardNumberChange = (event, setCardBrand) => {
    if (event.brand) {
        setCardBrand(event.brand);
    }
};

export const handleFocus = (ref) => {
    if (ref.current) {
        ref.current.classList.add("ring", "ring-info/30", "rounded-md", "ring-offset-1", "ring-offset-info/10", "text-base-content");
    }
};

export const handleBlur = (ref) => {
    if (ref.current) {
        ref.current.classList.remove("ring", "ring-info/30", "rounded-md", "ring-offset-1", "ring-offset-info/10", "text-base-content");
    }
};

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const {alert, alertKey} = useSelector((state) => state.alertReducer);

    useUpdateUserPreferences({ clearVF: false, refreshAndReset: true });

    const { registrationStatusResponse } = useGetRegistrationStatus();
    const {stripeCheckoutFailureVF, getIdAndCreatePaymentMethod, stripeCheckoutVF} = useStripeCheckout();
    const {
        listSubscriptionPlans,
        subscriptionPlansListingVF,
        subscriptionPlansListingResponse
    } = useListSubscriptionPlans({vfExists: true});
    const {
        paymentCreationVF,
        paymentCreationFailureVF,
        paymentCreationApiState,
        paymentCreationResponse
    } = useCreatePaymentMethod({ vfExists: true, refreshAndReset: false });
    const {
        setDefaultPaymentMethod,
        paymentMethodDefaultSettingVF,
    } = useSetDefaultPaymentMethod({ vfExists: true, refreshAndReset: false });
    const {
        promoCodeValidationResponse,
        promoCodeValidationFailureVF,
    } = useValidatePromoCode({ vfExists: true });
    const {
        createSubscription,
        subscriptionCreationVF,
    } = useCreateSubscription({ vfExists: true, refreshAndReset: false });
    const {
        listPaymentMethods,
        paymentMethodsListingResponse,
    } = useListPaymentMethods({ vfExists: true });

    const [cardBrand, setCardBrand] = useState('unknown');
    const [showExisting, setShowExisting] = useState(false);
    const [showNew, setShowNew] = useState(true);
    const [selectedPlans, setSelectedPlans] = useState([config.taskGlidePriceId]);

    const submissionDisabledState = !stripe || !elements || !registrationStatusResponse?.user_info_completed || !registrationStatusResponse?.user_preferences_completed || paymentCreationVF || stripeCheckoutVF || subscriptionCreationVF || paymentMethodDefaultSettingVF;
    const submissionLoadingState = paymentCreationVF || stripeCheckoutVF || subscriptionCreationVF || paymentMethodDefaultSettingVF;

    const paymentList = paymentMethodsListingResponse?.payment_methods?.map(method => ({
        id: method?.id,
        name: `**** ${method?.last4}`,
        is_default: method?.is_default,
        addOnValue: (
            <div className="flex items-center space-x-2">
                <p className="w-fit text-base-content/70 lg:block hidden">
                    ({method?.name})
                </p>
                <img
                    src={cardLogos[method?.brand]}
                    alt={cardBrand.toUpperCase()}
                    className="w-auto h-4 absolute right-2.5 top-2.5 shadow ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-sm"
                />
                {method?.is_default && <p className="secondary-badge">Default</p>}
            </div>)
    }));

    const initialValuesNew = {
        card_name: '',
        address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: '',
            postal_code: '',
        },
        promotion_code: '',
        selectedPlans: selectedPlans,
    };

    const initialPaymentMethod = paymentList?.find(method => method.is_default);
    const initialValuesExisting = {
        selectedPaymentMethod: initialPaymentMethod ? {
            id: initialPaymentMethod.id,
            name: `**** ${initialPaymentMethod.last4} (${initialPaymentMethod.name})`
        } : '',
        promotion_code: '',
        selectedPlans: selectedPlans,
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        // Form submission logic
        if (showNew) {
            getIdAndCreatePaymentMethod({
                stripe: stripe,
                elements: elements,
                address: values.address,
                card_name: values.card_name
            });
        } else {
            setDefaultPaymentMethod({ payment_method_id: values.selectedPaymentMethod.id });
            createSubscription({
                price_ids: selectedPlans,
                payment_method_id: values.selectedPaymentMethod.id,
                promotion_code: promoCodeValidationResponse?.promotion_code
            })
        }
        setSubmitting(false);
    };

    useEffect(() => {
        listSubscriptionPlans();
        listPaymentMethods();
    }, []);

    useEffect(() => {
        if (paymentCreationApiState === 'succeeded' && paymentCreationResponse?.payment_method_id) {
            setDefaultPaymentMethod({payment_method_id: paymentCreationResponse?.payment_method_id});
            createSubscription({
                price_ids: selectedPlans,
                payment_method_id: paymentCreationResponse?.payment_method_id,
                promotion_code: promoCodeValidationResponse?.promotion_code
            })
        }
    }, [paymentCreationApiState, paymentCreationResponse]);

    if (!stripe || !elements || subscriptionPlansListingVF) {
        return <SubscriptionFormSkeleton />;
    }
    return (
        <>
            <Formik
                initialValues={showNew ? initialValuesNew : initialValuesExisting}
                validationSchema={showNew ? validationSchemaNew : validationSchemaExisting}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 lg:grid-cols-9 w-full px-px">
                            <div className="col-span-full md:col-span-4 md:hidden space-y-1">
                                <h2 className="text-base font-semibold leading-7 text-base-content">Subscription</h2>
                                <p className="text-sm leading-6 text-base-content/70">
                                    Provide your payment information to subscribe and complete your registration.
                                </p>
                            </div>
                            <div className="col-span-full py-px">
                                <div className="grid grid-cols-2 gap-x-6 divide-y divide-neutral-focus/50 dark:divide-neutral-focus-dark/50">
                                    <SubscriptionPlanList
                                        selectedPlans={selectedPlans}
                                        setSelectedPlans={setSelectedPlans}
                                        userSubscriptions={subscriptionPlansListingResponse}
                                    />
                                    <SubscriptionPaymentMethodForm
                                        showNew={showNew}
                                        setShowNew={setShowNew}
                                        showExisting={showExisting}
                                        setShowExisting={setShowExisting}
                                        cardBrand={cardBrand}
                                        setCardBrand={setCardBrand}
                                        setFieldValue={setFieldValue}
                                        paymentList={paymentList}
                                    />
                                    <SubscriptionPromoSelection />
                                    <SubscriptionFormSubmission
                                        selectedPlans={selectedPlans}
                                        submissionDisabledState={submissionDisabledState}
                                        submissionLoadingState={submissionLoadingState}
                                    />
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {(paymentCreationFailureVF || stripeCheckoutFailureVF || promoCodeValidationFailureVF) &&
                <Alerts message={alert.message} type={alert.type} keyValue={alertKey}/>}
        </>
    );
};

const SubscriptionForm = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm/>
        </Elements>
    );
};

SubscriptionForm.propTypes = {}

export default SubscriptionForm;
