import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearPaymentMethodsListingVF, paymentMethodsLIST} from "../../../api/features/payments/listPaymentMethodsSlice";
import {useAuthentication} from "../users/useAuthentication";


export const useListPaymentMethods = ({vfExists=false} = {}) => {
    const dispatch = useDispatch();

    // Authentication
    const {userIsAuthenticated} = useAuthentication();

    // Payment methods
    const {
        paymentMethodsListingVF,
        paymentMethodsListingApiState,
        paymentMethodsListingResponse,
        paymentMethodsListingError,
    } = useSelector(state => state.listPaymentMethodsReducer);

    // API states with visual feedback
    const paymentMethodListingSuccessVF = paymentMethodsListingApiState === 'succeeded' && paymentMethodsListingVF;

    const listPaymentMethods = (page = 1, pageSize = 10) => {
        const params = `?page=${page}&page_size=${pageSize}`;
        userIsAuthenticated && dispatch(paymentMethodsLIST(params));
    };

    useEffect(() => {
        if (vfExists && paymentMethodListingSuccessVF) {
            const timer = setTimeout(() => {
                dispatch(clearPaymentMethodsListingVF());
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [paymentMethodsListingApiState]);

    return {
        listPaymentMethods,
        paymentMethodsListingVF,
        paymentMethodsListingApiState,
        paymentMethodsListingResponse,
        paymentMethodsListingError,
        paymentMethodListingSuccessVF
    };
};
