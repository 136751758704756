import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../users/useAuthentication";
import {clearUserTransactionRetrievalVF, userTransactionGET} from "../../../api/features/payments/getUserTransactionSlice";


export const useGetUserTransaction = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();
    const {
        userTransactionRetrievalVF,
        userTransactionRetrievalApiState,
        userTransactionRetrievalResponse,
        userTransactionRetrievalError,
    } = useSelector(state => state.getUserTransactionReducer);
    const {userIsAuthenticated} = useAuthentication();

    const userTransactionRetrievalSuccessVF = userTransactionRetrievalApiState === 'succeeded' && userTransactionRetrievalVF;
    const userTransactionRetrievalFailureVF = userTransactionRetrievalApiState === 'failed' && userTransactionRetrievalVF;

    const getUserTransaction = (transaction_id) => userIsAuthenticated && dispatch(userTransactionGET(transaction_id));

    useEffect(() => {
        if (vfExists) {
            if (userTransactionRetrievalSuccessVF || userTransactionRetrievalFailureVF) {
                const timer = setTimeout(() => {
                    dispatch(clearUserTransactionRetrievalVF());
                }, 500);
                return () => clearTimeout(timer);
            }
        }
    }, [userTransactionRetrievalApiState]);

    return {
        getUserTransaction,
        userTransactionRetrievalVF,
        userTransactionRetrievalApiState,
        userTransactionRetrievalResponse,
        userTransactionRetrievalError,
        userTransactionRetrievalSuccessVF,
        userTransactionRetrievalFailureVF
    };
};
