import React, {useEffect, useState} from "react";
import { Formik, Form, Field } from 'formik';
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import InputMask from 'react-input-mask';
import clsx from "clsx";

import routes from "../../../../../Routes/routes";
import UserInfoRegFormSkeleton from "./UserInfoRegFormSkeleton";
import {useUpdateUserInfo} from "../../../../../Hooks/apiHooks/users/useUpdateUserInfo";
import {useGetStripeCustomer} from "../../../../../Hooks/apiHooks/payments/useGetStripeCustomer";
import {useCreateStripeCustomer} from "../../../../../Hooks/apiHooks/payments/useCreateStripeCustomer";
import {useGetUserInfo} from "../../../../../Hooks/apiHooks/users/useGetUserInfo";
import AddressAutocompletion from "../../../../../Components/AddressAutocompletion/AddressAutocompletion";
import Alerts from "../../../../../Components/Alerts/Alerts";
import {useGetRegistrationStatus} from "../../../../../Hooks/apiHooks/users/useGetRegistrationStatus";
import {useAlert} from "../../../../../Hooks/frontendHooks/useAlert";
import {CgSpinner} from "react-icons/cg";


const validationSchema = yup.object().shape({
    firstName: yup.string()
        .required('First name is required')
        .min(2, 'Must be at least 2 characters')
        .matches(/^[a-zA-Z\s]*$/, 'Can only contain letters and spaces'),
    lastName: yup.string()
        .required('Last name is required')
        .min(2, 'Must be at least 2 characters')
        .matches(/^[a-zA-Z\s]*$/, 'Can only contain letters and spaces'),
    occupation: yup.string()
        .required('Occupation is required')
        .min(2, 'Must be at least 2 characters')
        .matches(/^[a-zA-Z\s]*$/, 'Can only contain letters and spaces'),
    dateOfBirth: yup.string()
        .required('Date of birth is required')
        .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Must be in format of MM/DD/YYYY')
        .test('valid-date', 'Date is not valid', value => {
            if (!value) { return true; }
            const [month, day, year] = value.split('/');
            const date = new Date(year, month - 1, day);
            return date.getFullYear() === Number(year)
                && date.getMonth() === Number(month) - 1
                && date.getDate() === Number(day);
        })
        .test('not-future-date', 'Date of birth cannot be in the future', value => {
            if (!value) { return true; }
            const [month, day, year] = value.split('/');
            const date = new Date(year, month - 1, day);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date <= today;
        }),
    phoneNumber: yup.string()
        .required('Phone number is required')
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Must be in format of (123) 456-7890'),
    address1: yup.string().required('Street address is required'),
    address2: yup.string(),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    zipCode: yup.string().required('Zip code is required'),
});


const PersonalInformationForm = () => {
    const navigate = useNavigate();
    const {alert, alertKey} = useAlert();
    const { registrationStatusResponse } = useGetRegistrationStatus();
    const {
        userInfoRetrievalResponse,
        userInfoRetrievalVF
    } = useGetUserInfo({ vfExists: true });
    const {
        updateUserInfo,
        userInfoUpdateApiState,
        userInfoUpdateVF,
        userInfoUpdateFailureVF,
    } = useUpdateUserInfo({ vfExists: true, refreshAndReset: false });
    const {
        getStripeCustomer,
        stripeCustomerRetrievalApiState,
        stripeCustomerRetrievalError
    } = useGetStripeCustomer({ vfExists: true });
    const {createStripeCustomer, stripeCustomerCreationVF} = useCreateStripeCustomer({ vfExists: true});
    const [createdStripeCustomer, setCreatedStripeCustomer] = useState(false);

    const initialValues = {
        firstName: userInfoRetrievalResponse?.first_name || '',
        lastName: userInfoRetrievalResponse?.last_name || '',
        occupation: userInfoRetrievalResponse?.occupation || '',
        dateOfBirth: userInfoRetrievalResponse?.date_of_birth || '',
        phoneNumber: userInfoRetrievalResponse?.phone_number || '',
        address1: userInfoRetrievalResponse?.address1 || '',
        address2: userInfoRetrievalResponse?.address2 || '',
        city: userInfoRetrievalResponse?.city || '',
        state: userInfoRetrievalResponse?.state || '',
        country: userInfoRetrievalResponse?.country || '',
        zipCode: userInfoRetrievalResponse?.zip_code || '',
    };

    const onSubmit = (values, { setSubmitting }) => {
        updateUserInfo(values);
        setSubmitting(false);
    }

    useEffect(() => {
        getStripeCustomer();
    }, []);

    useEffect(() => {
        if (stripeCustomerRetrievalError?.message === "No associated Stripe customer" && !createdStripeCustomer) {
            createStripeCustomer();
            setCreatedStripeCustomer(true);
        }
    }, [stripeCustomerRetrievalApiState]);

    useEffect(() => {
        if (userInfoUpdateApiState === "succeeded" && !userInfoUpdateVF) {
            navigate(routes.auth.registration.find(form => form.name === "Preferences").href);
        }
    }, [userInfoUpdateApiState, userInfoUpdateVF]);

    if (userInfoRetrievalVF) {
        return <UserInfoRegFormSkeleton />;
    }
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ errors, touched, isSubmitting, isValid, values, setFieldValue, handleBlur, validateForm }) => {
                    const areAddressFieldsEmpty = values.address1 === "" || values.city === "" || values.state === "" || values.country === "" || values.zipCode === "";

                    if (registrationStatusResponse?.user_info_completed && areAddressFieldsEmpty) {
                        return <UserInfoRegFormSkeleton />;
                    }
                    return (
                        <Form>
                            <div className="grid grid-cols-1 gap-x-6 gap-y-10 lg:grid-cols-9 w-full px-px" >
                                <div className="col-span-full md:col-span-4 md:hidden">
                                    <h2 className="text-base font-semibold leading-7 text-base-content">Personal Information</h2>
                                    <p className="mt-1 text-sm leading-6 text-base-content/70">
                                        Provide your personal information to curate your experience on the platform.
                                    </p>
                                </div>
                                <div className="col-span-full py-px">
                                    <div className="grid grid-cols-1 gap-x-5 gap-y-2">
                                        <div className="col-span-full space-y-2">
                                            <p className="text-end font-light h-5">
                                                {errors.firstName && touched.firstName ? (
                                                    <span className="text-2xs text-error-content">{errors.firstName}</span>
                                                ) : null}
                                            </p>
                                            <div className="relative">
                                                <label htmlFor="first-name" className={clsx(
                                                    "absolute -top-2 left-2 group-focus-within:inline-block bg-base-100 px-0.5 text-xs text-base-content/70 font-light leading-none z-10",
                                                    errors.firstName && touched.firstName ? "group-focus-within:text-error-content/70" : "group-focus-within:text-primary-focus/70 dark:group-focus-within:text-primary-focus-dark/70"
                                                )}>
                                                    First name
                                                </label>
                                                <Field
                                                    name="firstName"
                                                    type="text"
                                                    placeholder="John"
                                                    className="input-field"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-full space-y-2">
                                            <p className="text-end font-light h-5">
                                                {errors.lastName && touched.lastName ? (
                                                    <span className="text-2xs text-error-content">{errors.lastName}</span>
                                                ) : null}
                                            </p>
                                            <div className="relative">
                                                <label htmlFor="last-name" className={clsx(
                                                    "absolute -top-2 left-2 group-focus-within:inline-block bg-base-100 px-0.5 text-xs text-base-content/70 font-light leading-none z-10",
                                                    errors.lastName && touched.lastName ? "group-focus-within:text-error-content/70" : "group-focus-within:text-primary-focus/70 dark:group-focus-within:text-primary-focus-dark/70"
                                                )}>
                                                    Last name
                                                </label>
                                                <Field
                                                    name="lastName"
                                                    type="text"
                                                    placeholder="Doe"
                                                    className="input-field"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-full space-y-2">
                                            <p className="text-end font-light h-5">
                                                {errors.occupation && touched.occupation ? (
                                                    <span className="text-2xs text-error-content">{errors.occupation}</span>
                                                ) : null}
                                            </p>
                                            <div className="relative">
                                                <label htmlFor="occupation" className={clsx(
                                                    "absolute -top-2 left-2 group-focus-within:inline-block bg-base-100 px-0.5 text-xs text-base-content/70 font-light leading-none z-10",
                                                    errors.occupation && touched.occupation ? "group-focus-within:text-error-content/70" : "group-focus-within:text-primary-focus/70 dark:group-focus-within:text-primary-focus-dark/70"
                                                )}>
                                                    Occupation
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="occupation"
                                                    placeholder="Software Engineer"
                                                    autoComplete="organization-title"
                                                    className="input-field"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-full space-y-2">
                                            <p className="text-end font-light h-5">
                                                {errors.dateOfBirth && touched.dateOfBirth ? (
                                                    <span className="text-2xs text-error-content">{errors.dateOfBirth}</span>
                                                ) : null}
                                            </p>
                                            <div className="relative">
                                                <label htmlFor="dateOfBirth" className={clsx(
                                                    "absolute -top-2 left-2 group-focus-within:inline-block bg-base-100 px-0.5 text-xs text-base-content/70 font-light leading-none z-10",
                                                    errors.dateOfBirth && touched.dateOfBirth ? "group-focus-within:text-error-content/70" : "group-focus-within:text-primary-focus/70 dark:group-focus-within:text-primary-focus-dark/70"
                                                )}>
                                                    Date of Birth
                                                </label>
                                                <InputMask
                                                    mask="99/99/9999"
                                                    value={values.dateOfBirth}
                                                    onChange={e => {
                                                        setFieldValue('dateOfBirth', e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                >
                                                    {() => (
                                                        <Field
                                                            type="text"
                                                            name="dateOfBirth"
                                                            placeholder="MM/DD/YYYY"
                                                            autoComplete="bday"
                                                            className="input-field"
                                                        />
                                                    )}
                                                </InputMask>
                                            </div>
                                        </div>
                                        <div className="col-span-full space-y-2">
                                            <p className="text-end font-light h-5">
                                                {errors.phoneNumber && touched.phoneNumber ? (
                                                    <span className="text-2xs text-error-content">{errors.phoneNumber}</span>
                                                ) : null}
                                            </p>
                                            <div className="relative">
                                                <div className="flex justify-between">
                                                    <label htmlFor="phone-number" className="absolute -top-2 left-2.5 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-light text-base-content/70 z-10 leading-none">
                                                        Phone Number
                                                    </label>
                                                </div>
                                                <InputMask
                                                    mask="(999) 999-9999"
                                                    value={values.phoneNumber}
                                                    onChange={e => {
                                                        setFieldValue('phoneNumber', e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                >
                                                    {() => (
                                                        <Field
                                                            type="text"
                                                            name="phoneNumber"
                                                            placeholder="(123) 456-7890"
                                                            autoComplete="tel"
                                                            className="input-field"
                                                        />
                                                    )}
                                                </InputMask>
                                            </div>
                                        </div>

                                        <div className="col-span-full space-y-2">
                                            <p className="text-end font-light h-5">
                                                {errors.address1 && touched.address1 ? (
                                                    <span className="text-2xs text-error-content">
                                                    {errors.address1}
                                                </span>
                                                ) : null}
                                            </p>
                                            <div className="relative">
                                                <div className="flex justify-between">
                                                    <label htmlFor="address" className="absolute -top-2 left-2.5 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-light text-base-content/70 z-10 leading-none">
                                                        Address
                                                    </label>
                                                </div>
                                                <AddressAutocompletion
                                                    defaultValue={[initialValues.address1, initialValues.city, initialValues.state, initialValues.country, initialValues.zipCode].filter(Boolean).join(", ")}
                                                    setFieldValue={setFieldValue}
                                                    placeholder={"123 Main St, New York, NY, USA, 10001"}
                                                    onSelect={addressComponents => {
                                                        setFieldValue("address1", addressComponents.address1);
                                                        setFieldValue("address2", addressComponents.address2);
                                                        setFieldValue("city", addressComponents.city);
                                                        setFieldValue("state", addressComponents.state);
                                                        setFieldValue("country", addressComponents.countryCode);
                                                        setFieldValue("zipCode", addressComponents.zip_code);

                                                        // Trigger Formik validation
                                                        setTimeout(() => {
                                                            validateForm();
                                                        }, 0);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button
                                            type="submit"
                                            disabled={
                                                isSubmitting || !isValid || values.firstName === "" || values.lastName === "" || values.occupation === "" || values.dateOfBirth === "" || values.phoneNumber === "" || values.address1 === "" || values.city === "" || values.state === "" || values.country === "" || values.zipCode === "" || userInfoUpdateVF || stripeCustomerCreationVF
                                            }
                                            className="primary-button"
                                        >
                                            <CgSpinner
                                                className={clsx(
                                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                                    (userInfoUpdateVF || stripeCustomerCreationVF || isSubmitting) ? "" : "hidden"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <p className={(userInfoUpdateVF || stripeCustomerCreationVF || isSubmitting) ? "invisible" : ""}>
                                                Next
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
            {userInfoUpdateFailureVF && <Alerts message={alert.message} type={alert.type} keyValue={alertKey}/>}
        </>
    )
};

PersonalInformationForm.propTypes = {}

export default PersonalInformationForm;
