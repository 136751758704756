import {PiCodesandboxLogoFill, PiListStar} from "react-icons/pi";
import {GiGears, GiMicroscope, GiProgression, GiQuickSlash} from "react-icons/gi";
import {RiPencilFill, RiGraduationCapFill, RiChat1Fill, RiChatVoiceLine} from "react-icons/ri";
import {BsCalendar2WeekFill, BsDatabaseFill, BsStars} from "react-icons/bs";
import {IoFitnessSharp} from "react-icons/io5";
import {HiMiniShoppingBag} from "react-icons/hi2";

import routes from "../../../Routes/routes";
import {HomeIcon, ShoppingBagIcon} from "@heroicons/react/20/solid";
import {HiChartBar} from "react-icons/hi";
import {IoMdCart} from "react-icons/io";
import {MdSettingsSuggest} from "react-icons/md";


export const taskGlideItems = [
    {
        name: routes.auth.taskGlide.home.label,
        href: routes.auth.taskGlide.home.href,
        description: 'Start conversations with your Glides.',
        icon: RiChat1Fill
    },
    {
        name: 'My Glides',
        href: routes.auth.taskGlide.myGlides.href,
        description: 'Manage your Glides and create new ones.',
        icon: BsStars
    },
    {
        name: routes.auth.taskGlide.glideStore.label,
        href: routes.auth.taskGlide.glideStore.href,
        description: 'Access all public facing Glides.',
        icon: IoMdCart
    },
    {
        name: routes.auth.taskGlide.settings.label,
        href: routes.auth.taskGlide.settings.href,
        description: 'Change the settings for all of your Glides.',
        icon: MdSettingsSuggest
    },
];

export const memoMinderItems = [
    {
        name: routes.auth.memoMinder.calendar.label,
        href: routes.auth.memoMinder.calendar.href,
        description: 'Engage with your calendar directly.',
        icon: BsCalendar2WeekFill
    },
    {
        name: routes.auth.memoMinder.settings.label,
        href: routes.auth.memoMinder.settings.href,
        description: 'Change your smart calendar settings.',
        icon: GiGears
    },
];

const taskGlideMenu = taskGlideItems.map(item => ({
    name: item.name,
    href: item.href,
    tooltip: item.name,
    current: item.href === window.location.pathname,
}));

const memoMinderMenu = memoMinderItems.map(item => ({
    name: item.name,
    href: item.href,
    tooltip: item.name,
    current: item.href === window.location.pathname,
}));

export const sideBarItems = [
    {
        name: 'TaskGlide',
        current: taskGlideMenu.some(menuItem => menuItem.current),
        children: taskGlideMenu,
    },
    {
        name: 'MemoMinder',
        current: memoMinderMenu.some(menuItem => menuItem.current),
        children: memoMinderMenu,
    },
];
