import React from "react";


const AccountSettingsSkeleton = () => {
    const delay = (ms) => ({ animationDelay: `${ms}ms` });

    return (
        <div className="bg-base-100 overflow-y-auto h-[calc(100dvh-112px)] scrollbar overflow-hidden">
            <div className="border-b border-neutral">
                <div className="grid grid-cols-1 gap-x-20 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" >
                    <div className="col-span-full md:col-span-4 space-y-4">
                        <div className="h-4 skeleton bg-base-300/30 w-28 rounded"/>
                        <div className="h-3.5 skeleton bg-base-300/30 w-full rounded"/>
                        <div className="h-3.5 skeleton bg-base-300/30 w-2/3 rounded"/>
                    </div>

                    <div className="relative md:col-start-5 md:col-span-5 lg:col-start-6">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-none sm:grid-cols-4">
                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                            </div>
                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                            </div>
                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                            </div>
                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                            </div>
                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                            </div>
                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-4">
                            <div className="rounded bg-base-100 w-12 h-4 skeleton bg-base-300/30"/>
                            <div className="rounded-md bg-base-100 w-14 h-8 skeleton bg-base-300/30"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border-b border-neutral">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" style={delay(350)}>
                    <div className="col-span-full md:col-span-4 space-y-3">
                        <div className="h-4 skeleton bg-base-300/30 w-28 rounded"/>
                        <div className="h-3.5 skeleton bg-base-300/30 w-full rounded"/>
                    </div>
                    <div className="relative md:col-start-5 md:col-span-5 lg:col-start-6">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-none sm:grid-cols-4">
                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                            </div>
                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                            </div>
                            <div className="group relative col-span-full grid grid-cols-7 items-center">
                                <div className="skeleton bg-base-300/30 col-span-3 h-4 rounded w-28"/>
                                <div className="col-span-4 rounded-md shadow skeleton bg-base-300/30 h-8 w-full"/>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-4">
                            <div className="rounded-md bg-base-100 w-14 h-8 skeleton bg-base-300/30"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border-b border-neutral">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" style={delay(350)}>
                    <div className="col-span-full md:col-span-4 space-y-3">
                        <div className="h-4 skeleton bg-base-300/30 w-28 rounded"/>
                        <div className="h-3.5 skeleton bg-base-300/30 w-full rounded"/>
                        <div className="h-3.5 skeleton bg-base-300/30 w-2/3 rounded"/>
                    </div>
                    <div className="relative md:col-start-5 md:col-span-5 lg:col-start-6">
                        <div className="flex items-center justify-end gap-x-4">
                            <div className="rounded-md bg-base-100 w-40 h-8 skeleton bg-base-300/30"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AccountSettingsSkeleton;
