import React, {useEffect, useState} from "react";
import clsx from "clsx";

import Nav from "../../../Shell/Nav/Nav";
import ToggleButton from "../../../../../Components/ToggleButton/ToggleButton";
import {useGeneralUiActions} from "../../../../../Hooks/frontendHooks/useGeneralUiActions";


const TaskGlideSettings = () => {
    const [radioBtnUpdating, setRadioBtnUpdating] = useState('');

    const { firstRender, handleFirstRender } = useGeneralUiActions();

    return (
        <>
            <Nav/>
            <div className="py-3 bg-base-100 sticky top-0 right-0 left-0 lg:left-16 border-b border-neutral z-[2]">
                <div className="md:flex md:items-center md:justify-between space-y-3 md:space-y-0 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="flex items-center space-x-3 py-2">
                        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:tracking-tight text-base-content flex-1">Glides Settings</h1>
                    </div>
                </div>
            </div>
            {/*{(firstRender) ? <TaskGlideSettingsSkeleton /> :*/}
                <main className="bg-base-100 overflow-y-auto h-[calc(100dvh-112px)] scrollbar">
                    <div className="border-b border-neutral">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                            <div className="col-span-full md:col-span-4">
                                <h2 className="text-base font-semibold leading-none text-base-content">Profile Access Control</h2>
                                <p className="mt-1 text-sm leading-6 text-base-content/70">
                                    Fine-tune your privacy by controlling access to your data.
                                </p>
                            </div>
                            <div className="relative md:col-start-6 md:col-span-5 space-y-5">

                            </div>
                        </div>
                    </div>

                    <div className="border-b border-neutral">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                            <div className="col-span-full md:col-span-4">
                                <h2 className="text-base font-semibold leading-none text-base-content">External Browsing Control</h2>
                                <p className="mt-1 text-sm leading-6 text-base-content/70">
                                    Allow TaskGlide to browse external services. This integration allows seamless interaction with less limitations.
                                </p>
                            </div>
                            <div className="relative md:col-start-6 md:col-span-5 space-y-5">

                            </div>
                        </div>
                    </div>

                    <div className="border-b border-neutral">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                            <div className="col-span-full md:col-span-4">
                                <h2 className="text-base font-semibold leading-none text-base-content">External Accounts Control</h2>
                                <p className="mt-1 text-sm leading-6 text-base-content/70">
                                    Allow TaskGlide to access your external accounts. This is originally disabled.
                                </p>
                            </div>
                            <div className="relative md:col-start-6 md:col-span-5 space-y-5">

                            </div>
                        </div>
                    </div>
                </main>
{/*}*/}
        </>
    )
};

export default TaskGlideSettings;
