import React from "react";
import {IoIosSpeedometer,IoIosApps} from "react-icons/io";
import {MdDoneAll} from "react-icons/md";
import {FaBrain} from "react-icons/fa";
import {AiOutlineApi} from "react-icons/ai";
import {FiZap} from "react-icons/fi";

const Feature2 = () => {
    const features = [
        {
            name: 'Rapid Task Completion',
            description: 'Swiftly navigate tasks. Get more done with less.',
            icon: IoIosSpeedometer,
        },
        {
            name: 'Optimized Efficiency',
            description: 'Streamline your work. Achieve maximum productivity.',
            icon: MdDoneAll,
        },
        {
            name: 'Empowered Learning',
            description: 'Simplify learning. Dive deeper and quicker in.',
            icon: FaBrain,
        },
        {
            name: 'Seamless Integration',
            description: 'Experience effortless sync. Get personalized help.',
            icon: AiOutlineApi,
        },
        {
            name: 'Versatile Application',
            description: "Dovise caters to all. Tailored solutions here.",
            icon: IoIosApps,
        },
        {
            name: 'Instant Data Processing',
            description: 'Get curated insights swiftly. Easy research now.',
            icon: FiZap,
        },
    ];

    return (
        <div className="relative pb-32" >
            <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                <h2 className="text-base font-medium text-primary">Deploy faster</h2>
                <div className="space-y-4 mt-4" >
                    <p className="text-3xl font-bold tracking-tight text-base-content sm:text-4xl">
                        Transforming Productivity
                    </p>
                    <p className="mx-auto leading-8 max-w-prose text-xl text-base-content/70">
                        Experience unparalleled productivity with Dovise, your suite of intelligent assistants. Streamline tasks, optimize efficiency, and achieve more.
                    </p>
                </div>
                <div className="mt-16 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3 overflow-visible">
                    {features.map((feature) => (
                        <div key={feature.name} className="pt-6">
                            <div className="flow-root rounded-xl px-6 pb-8 bg-base-200 ring-[0.5px] ring-neutral h-full">
                            <div className="-mt-6">
                                    <div>
                                      <span className="inline-flex items-center justify-center rounded-xl bg-primary p-3">
                                        <feature.icon className="h-7 w-7 text-base-100" aria-hidden="true" />
                                      </span>
                                    </div>
                                    <h3 className="mt-5 text-lg font-semibold leading-8 tracking-tight text-base-content">
                                        {feature.name}
                                    </h3>
                                    <p className="mt-4 text-base leading-7 text-base-content/70">{feature.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
};

export default Feature2;
