import React, {useEffect, useMemo} from "react";
import clsx from "clsx";
import {motion} from "framer-motion";
import {RiArrowRightSLine} from "react-icons/ri";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import BillingNav from "../BillingNav";
import Nav from "../../Shell/Nav/Nav";
import {useListSubscriptionPlans} from "../../../../Hooks/apiHooks/payments/useListSubscriptionPlans";
import {useListUserSubscriptions} from "../../../../Hooks/apiHooks/payments/useListUserSubscriptions";
import SubscriptionSkeleton from "./SubscriptionSkeleton";
import Alerts from "../../../../Components/Alerts/Alerts";
import {useAlert} from "../../../../Hooks/frontendHooks/useAlert";
import {useCreateSubscription} from "../../../../Hooks/apiHooks/payments/useCreateSubscription";
import {useUnsubscribe} from "../../../../Hooks/apiHooks/payments/useUnsubscribe";
import {useUndoCancellation} from "../../../../Hooks/apiHooks/payments/useUndoCancellation";
import {resetUnsubscribe} from "../../../../api/features/payments/unsubscribeSlice";
import {resetSubscriptionCreation} from "../../../../api/features/payments/createSubscriptionSlice";
import {resetUndoCancellation} from "../../../../api/features/payments/undoCancellationSlice";


export const statuses = {
    Subscribed: 'success-badge',
    'Not subscribed': 'neutral-badge',
    'Not available': 'neutral-badge',
};


export const getPeriodEnd = (endDate) => {
    const date = new Date(endDate * 1000);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const day = date.getDate();

    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) {return 'th';} // covers 11th to 20th
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    return `${month} ${day}${getOrdinalSuffix(day)}`;
};

export const currentSubscriptionStatus = (productIsAvailable, isSubscribed) => {
    if (!productIsAvailable) {
        return 'Not available';
    }
    return isSubscribed ? 'Subscribed' : 'Not subscribed';
};

export const futureSubscriptionStatus = (endDate, subscriptionIsCancelled) => {
    const periodEnd = getPeriodEnd(endDate);

    return subscriptionIsCancelled
        ? `Cancels on ${periodEnd}`
        : `Next payment on ${periodEnd}`;
};


const Subscriptions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { alert, alertKey } = useAlert();

    const refreshPostSubscriptionCreation = useCreateSubscription({ refreshAndReset: true });
    const {
        listSubscriptionPlans,
        subscriptionPlansListingResponse,
        subscriptionPlansListingVF
    } = useListSubscriptionPlans({ vfExists: true });
    const {
        userSubscriptionsListingResponse,
        userSubscriptionsListingVF
    } = useListUserSubscriptions({ vfExists: true });
    const {
        subscriptionCreationSuccessVF,
    } = useCreateSubscription({ vfExists: true });
    const {
        unsubscribeSuccessVF
    } = useUnsubscribe({ vfExists: true, refreshAndReset: true });
    const {
        undoCancellationSuccessVF,
    } = useUndoCancellation({ vfExists: true, refreshAndReset: true });

    useEffect(() => {
        if (!subscriptionPlansListingResponse) {
            listSubscriptionPlans();
        }
    }, []);

    const isSubscribed = (priceId) => userSubscriptionsListingResponse?.some(sub => sub.price_id === priceId);
    const subscriptionIsCancelled = (priceId) => userSubscriptionsListingResponse?.find(sub => sub.price_id === priceId)?.is_cancellation_scheduled;
    const productIsAvailable = (priceId) => subscriptionPlansListingResponse.find(plan => plan.price_id === priceId)?.is_active;

    const getEndDate = (priceId) => userSubscriptionsListingResponse?.find(sub => sub.price_id === priceId)?.current_period_end;

    const sortedPlans = useMemo(() => {
        return subscriptionPlansListingResponse?.slice().sort((a, b) => {
            const subscribedA = isSubscribed(a.price_id);
            const subscribedB = isSubscribed(b.price_id);
            const activeA = productIsAvailable(a.price_id);
            const activeB = productIsAvailable(b.price_id);

            if (subscribedA && activeA && !(subscribedB && activeB)) {return -1;}
            if (subscribedB && activeB && !(subscribedA && activeA)) {return 1;}
            return 0;
        });
    }, [subscriptionPlansListingResponse, userSubscriptionsListingResponse]);

    const loadingStates = subscriptionPlansListingVF || userSubscriptionsListingVF;

    const handleSubscriptionItemClick = (plan) => {
        dispatch(resetUnsubscribe());
        dispatch(resetSubscriptionCreation());
        dispatch(resetUndoCancellation());
        navigate(plan?.product_name)
    }

    return (
        <>
            <Nav/>
            <BillingNav/>

            <div className="bg-base-100 sticky top-28 right-0 left-0 py-4 border-b border-neutral z-10">
                <div className="sm:flex sm:items-center sm:justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="min-w-0 flex-1 sm:flex-auto">
                        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:tracking-tight text-base-content">Subscription Plans</h1>
                    </div>
                </div>
            </div>
            <ul className="overflow-y-auto scrollbar h-[calc(100dvh-218px)] sm:h-[calc(100dvh-178px)] bg-base-100 cursor-default" >
                {loadingStates ? <SubscriptionSkeleton /> :
                    sortedPlans?.map((plan, index) => {

                        return (
                            <motion.li
                                key={plan?.price_id}
                                initial={{opacity: 0, y: -10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: index * 0.1}}
                                className={clsx("relative bg-base-100", productIsAvailable(plan?.price_id) && "hover:sm:bg-base-200/50")}
                            >
                                <div className="flex items-center max-w-5xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                                    <button
                                        className="min-w-0 w-full space-y-1 py-4"
                                        onClick={() => handleSubscriptionItemClick(plan)}
                                        disabled={!productIsAvailable(plan?.price_id)}
                                    >
                                        <div className="flex items-center gap-x-2">
                                            <p className="text-sm font-semibold leading-6 text-base-content">
                                                {plan?.product_name}
                                            </p>
                                            <p
                                                className={statuses[currentSubscriptionStatus(
                                                    productIsAvailable(plan?.price_id),
                                                    isSubscribed(plan?.price_id)
                                                )]}
                                            >
                                                {currentSubscriptionStatus(
                                                    productIsAvailable(plan?.price_id),
                                                    isSubscribed(plan?.price_id)
                                                )}
                                            </p>
                                            {isSubscribed(plan?.price_id) && <p
                                                className={clsx(
                                                    statuses['Not subscribed'],
                                                    'rounded whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset hidden md:inline-flex'
                                                )}
                                            >
                                                {futureSubscriptionStatus(
                                                    getEndDate(plan?.price_id),
                                                    subscriptionIsCancelled(plan?.price_id)
                                                )}
                                            </p>}
                                        </div>
                                        <div className="flex justify-start">
                                            <div className="inline-flex space-x-2 text-xs leading-5 text-base-content/70">
                                                <div className="inline whitespace-nowrap font-medium">
                                                    <p className="inline">For </p>
                                                    <p className="inline text-base-content">
                                                        {plan?.users}
                                                    </p>
                                                </div>
                                                <p className="inline leading-none">.</p>
                                                <p className="inline break-words text-start">{plan?.description}</p>
                                            </div>
                                        </div>
                                    </button>
                                    <Link
                                        type="button"
                                        to={plan?.product_name}
                                    >
                                        <RiArrowRightSLine className="w-4 h-4"/>
                                    </Link>
                                </div>
                            </motion.li>
                        )
                    })}
            </ul>
            {(subscriptionCreationSuccessVF || unsubscribeSuccessVF || undoCancellationSuccessVF) &&
                <Alerts
                    message={alert.message}
                    type={alert.type}
                    keyValue={alertKey}
                />}
        </>
    )
};

export default Subscriptions;
