import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";


const RadioBtnSkeleton = ({ isOn }) => {

    return (
        <div className="flex items-start">
            <div className={clsx(
                "relative px-0.5 h-5 w-9 flex-shrink-0 rounded-full bg-base-300/30 flex items-center skeleton",
                isOn ? "justify-end" : "justify-start"
            )} >
                <div className="h-4 w-4 rounded-full bg-base-100" />
            </div>
            <div className="flex flex-col space-y-3 text-sm ml-4 w-full">
                <div className="h-3 w-20 bg-base-300/30 skeleton rounded" />
                <div className="space-y-2" >
                    <div className="h-3 w-full bg-base-300/30 skeleton rounded"/>
                    <div className="h-3 w-2/3 bg-base-300/30 skeleton rounded"/>
                </div>
            </div>
        </div>
    )
};

RadioBtnSkeleton.propTypes = {
    isOn: PropTypes.bool,
};

RadioBtnSkeleton.defaultProps = {
    isOn: false,
};


const TaskGlideSettingsSkeleton = () => {
    const delay = (ms) => ({animationDelay: `${ms}ms`});

    return (
        <div className="bg-base-100 overflow-y-auto h-[calc(100dvh-112px)] scrollbar">
            <div className="border-b border-neutral">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="col-span-full md:col-span-4 space-y-3">
                        <div className="h-4 bg-base-300/30 skeleton w-28 rounded"/>
                        <div className="h-3.5 bg-base-300/30 skeleton w-2/3 rounded"/>
                    </div>
                    <div className="relative md:col-start-6 lg:col-start-7 md:col-span-4 space-y-6">
                        <RadioBtnSkeleton />
                        <RadioBtnSkeleton isOn={true} />
                    </div>
                </div>
            </div>
            <div className="border-b border-neutral">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" style={delay(350)} >
                    <div className="col-span-full md:col-span-4 space-y-4">
                        <div className="h-4 bg-base-300/30 skeleton w-32 rounded"/>
                        <div className="space-y-2.5">
                            <div className="h-3.5 bg-base-300/30 skeleton w-full rounded"/>
                            <div className="h-3.5 bg-base-300/30 skeleton w-1/3 rounded"/>
                        </div>
                    </div>
                    <div className="relative md:col-start-6 lg:col-start-7 md:col-span-4 space-y-6">
                        <RadioBtnSkeleton isOn={true} />
                        <RadioBtnSkeleton/>
                        <RadioBtnSkeleton isOn={true} />
                    </div>
                </div>
            </div>
            <div className="border-b border-neutral">
                <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="col-span-full md:col-span-4 space-y-3">
                        <div className="h-4 bg-base-300/30 skeleton w-28 rounded"/>
                        <div className="space-y-2.5">
                            <div className="h-3.5 bg-base-300/30 skeleton w-full rounded"/>
                            <div className="h-3.5 bg-base-300/30 skeleton w-2/3 rounded"/>
                        </div>
                    </div>
                    <div className="relative md:col-start-6 lg:col-start-7 md:col-span-4 space-y-5">
                        <div className="flex items-center justify-center space-x-3 bg-base-100 rounded-md px-4 py-2 bg-base-300/30 skeleton w-4/5">
                            <div className="w-6 h-6 rounded-full bg-base-100"/>
                            <div className="bg-base-100 rounded h-3 w-full flex-1"/>
                        </div>
                        <div className="block appearance-none rounded-md shadow bg-base-300/30 skeleton h-9 w-full"/>
                        <div className="space-y-7">
                            <RadioBtnSkeleton/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TaskGlideSettingsSkeleton;
