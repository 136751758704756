import React from "react";
import PropTypes from "prop-types";
import {MdFormatListBulletedAdd} from "react-icons/md";
import {IoMdOpen} from "react-icons/io";

import {formatDateTimeTo12Hour, formatDateToReadable} from "../../../../../reusableFxns/dateTimeFxns";


const MemoMinderCalendarEvents = ({ eventList, selectedDate }) => {

    const noEvents = !eventList || !eventList?.results || eventList?.results.length === 0;

    return (
        <div className="divide-y divide-neutral space-y-2 px-2" >
            <div className="flex items-center justify-between" >
                <div className="flex items-center space-x-2" >
                    <h2 className="font-medium">Events</h2>
                    <button
                        type="button"
                        className="group/event-expander relative button-ring-focus p-0.5"
                    >
                        <IoMdOpen className="size-4 shrink-0 text-base-content/70"/>
                        <div className="right-tooltips left-8 group-hover/event-expander:lg:block">View events</div>
                    </button>
                </div>
                {<time dateTime={eventList?.results[0]?.date} className="block font-medium text-base-content/70 text-sm leading-6">
                    {formatDateToReadable(selectedDate)}
                </time>}
            </div>
            {noEvents ?
                <div className="flex flex-col items-center justify-center text-base-content/70 space-y-1 font-light h-[calc(100dvh-510px)]" >
                    <MdFormatListBulletedAdd className="size-8 shrink-0" />
                    <p>No event has been added</p>
                </div> :
                <ol className="divide-y divide-neutral text-sm leading-6">
                    {eventList.results.map((event) => (
                        <li key={event?.id} className="py-3 sm:flex space-y-1">
                            <div className="w-full space-y-0.5">
                                <p className="mt-2 flex-auto text-base-content sm:mt-0">{event?.title}</p>
                                <p className="text-base-content/70 font-light truncate">{event?.description}</p>
                            </div>
                            {event?.all_day ? (
                                <p className="flex-none sm:ml-6">All day</p>
                            ) : (
                                <p className="flex-none sm:ml-6">
                                    <time dateTime={`1970-01-01T${event?.start_time}Z`}>{formatDateTimeTo12Hour(event?.start_time)}</time>
                                    {' - '}
                                    <time dateTime={`1970-01-01T${event?.end_time}Z`}>{formatDateTimeTo12Hour(event?.end_time)}</time>
                                </p>
                            )}
                        </li>
                    ))}
                </ol>}
        </div>
    );
};

MemoMinderCalendarEvents.propTypes = {
    eventList: PropTypes.object,
    selectedDate: PropTypes.string,
};

export default MemoMinderCalendarEvents;
