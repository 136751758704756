import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {useState} from "react";

import {useListAllTgConversations} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useListAllTgConversations";
import {useGetSingleTgConversation} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetSingleTgConversation";
import {useStreamTaskGlide} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";
import {useGetCurrentTgConversation} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetCurrentTgConversation";
import {setCurrentTestTgConversation, setCurrentTgConversation} from "../../../../../../../../api/features/superIntelligence/taskGlide/frontend/getCurrentTgConversationSlice";
import {useGetTgInteractionStream} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetTgInteractionStream";


export const useTaskGlideFormActions = (
    { sessionInfo, selectedGlides, isTest, selectedPhotos, setSelectedPhotos, selectedFiles, setSelectedFiles, setIsAutoScrollEnabled, parsedDocuments, setParsedDocuments }
) => {
    const dispatch = useDispatch();
    const [controller, setController] = useState(new AbortController());
    const { listAllTgConversations } = useListAllTgConversations();
    const { getSingleTGConversation } = useGetSingleTgConversation();
    const { streamTaskGlide, taskGlideStreamVF } = useStreamTaskGlide({ refreshAndReset: true, isTest });
    const { currentTgConversation, currentTestTgConversation } = useGetCurrentTgConversation();
    const { tgStreamConversationUuid } = useGetTgInteractionStream();

    const currentConversation = isTest ? currentTestTgConversation : currentTgConversation;
    const conversationUuid = (currentConversation?.interactions?.results?.length > 0 && !taskGlideStreamVF) ? currentConversation?.conversation_uuid : tgStreamConversationUuid;

    const getInteractionPayload = (values) => {
        return {
            "id": 0,
            "status": {
                "action": "create_conversation",
                "percent_complete": 0
            },
            "messages": [
                {
                    "type": "user_message",
                    "content": {
                        "input_type": "user_typed",
                        ...(selectedPhotos && {"images": selectedPhotos.map(photo => ({ ...photo }))}),
                        ...(selectedFiles && {"documents": selectedFiles.map(file => ({ ...file }))}),
                        "text_input": values?.text_input
                    }
                },
            ],
        }};

    const updateConversation = (setConvFunc) => {
        const basePayload = {
            id: currentConversation?.id,
            conversation_uuid: currentConversation?.conversation_uuid,
            selected_agents: selectedGlides,
            interactions: {
                results: [
                    ...(currentConversation?.interactions?.results || []),
                    getInteractionPayload(formik.values)
                ]
            }};

        if (currentConversation) {
            basePayload.interactions.next = currentConversation.interactions.next;
        }

        dispatch(setConvFunc(basePayload));
    };

    const resetSubmission = () => {
        formik.setFieldValue('text_input', '');
        setSelectedPhotos(null);
        setSelectedFiles(null);
        setParsedDocuments([]);
    };

    const formik = useFormik({
        initialValues: {"text_input": ''},
        onSubmit: async (values) => {
            const formData = new FormData();

            formData.append('is_test', isTest);
            formData.append('input_type', 'user_typed');
            formData.append('text_input', values.text_input);

            if (parsedDocuments?.length > 0 && selectedFiles) {
                selectedFiles.forEach(file => {
                    formData.append('uploaded_files', file);
                });

                const parsedDocumentJson = JSON.stringify({documents: parsedDocuments});
                formData.append('documents_metadata', parsedDocumentJson);
            }
            if (selectedPhotos) {
                selectedPhotos.forEach(photo => {
                    formData.append('image_input', photo);
                });
            }

            selectedGlides?.forEach(glide => {
                formData.append('glides_uuid_list[]', glide?.uuid);
            });

            updateConversation(isTest ? setCurrentTestTgConversation : setCurrentTgConversation);
            streamTaskGlide(formData, sessionInfo, currentConversation?.id, controller.signal);
            resetSubmission();

            setIsAutoScrollEnabled(true);
        },
    });

    const handleAbort = () => {
        controller.abort();
        setController(new AbortController());
        if (!isTest) {
            listAllTgConversations();
            getSingleTGConversation(conversationUuid);
        }
        resetSubmission();
    };

    return { formik, handleAbort };
};
