import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {useCallBackend} from "../../calls/useCallBackend";


export const unsubscribePOST = createAsyncThunk(
    'payments/subscription/unsubscribe',
    async (price_id, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_UNSUBSCRIBE_PATH}${price_id}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    unsubscribeVF: false,
    unsubscribeApiState: 'idle',
    unsubscribeResponse: null,
    unsubscribeError: null,
}

export const unsubscribeSlice = createSlice({
    name: 'unsubscribe',
    initialState: INITIAL_STATE,
    reducers: {
        clearUnsubscribeVF: (state) => {
            state.unsubscribeVF = false;
        },
        resetUnsubscribe: (state) => {
            state.unsubscribeVF = false;
            state.unsubscribeApiState = 'idle';
            state.unsubscribeResponse = null;
            state.unsubscribeError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(unsubscribePOST.pending, (state) => {
                state.unsubscribeApiState = 'loading';
                state.unsubscribeVF = true;
            })
            .addCase(unsubscribePOST.fulfilled, (state, action) => {
                state.unsubscribeApiState = 'succeeded';
                state.unsubscribeResponse = action.payload;
                state.unsubscribeVF = true;
                state.unsubscribeError = null;
            })
            .addCase(unsubscribePOST.rejected, (state, action) => {
                state.unsubscribeApiState = 'failed';
                state.unsubscribeError = action?.payload;
                state.unsubscribeVF = true;
                state.unsubscribeResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUnsubscribeVF,
    resetUnsubscribe
} = unsubscribeSlice.actions;

export default unsubscribeSlice.reducer;
