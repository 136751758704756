import {handleResponseError} from "./errorHandlers";


export async function processResponse(response, thunkAPI) {
    if (!response.ok) {
        return handleResponseError(response, thunkAPI);
    }

    if (response.status === 204) {
        return {};
    }

    return await response.json();
}
