import React from "react";
import PropTypes from "prop-types";


const SkeletonRow = () => {

    return (
        <tr>
            <td className="py-3 px-3">
                <div className="flex items-center gap-x-4">
                    <div className="hidden flex-none text-base-300 sm:block h-5 w-5 bg-base-300/30 skeleton rounded-full" aria-hidden="true"/>
                    <div className="h-3.5 w-1/2 bg-base-300/30 skeleton rounded"/>
                </div>
                <div className="flex flex-col">
                    <div className="mt-2 lg:hidden h-3.5 w-full bg-base-300/30 skeleton rounded"/>
                    <div className="mt-2 md:hidden h-3.5 w-full bg-base-300/30 skeleton rounded"/>
                </div>
            </td>
            <td className='hidden py-3 lg:table-cell px-3'>
                <div className="h-3.5 w-1/2 bg-base-300/30 skeleton rounded"/>
            </td>
            <td className='hidden py-3 md:table-cell px-3'>
                <div className="h-3.5 w-1/2 bg-base-300/30 skeleton rounded"/>
            </td>
            <td className='py-3 px-3'>
                <div className="h-5 w-2/3 sm:w-20 bg-base-300/30 skeleton rounded"/>
            </td>
            <td className="py-2 flex flex-col items-end text-sm font-medium px-2 space-y-3">
                <div className="h-3.5 w-1/2 bg-base-300/30 skeleton rounded"/>
                <div className="hidden md:block h-3.5 w-full bg-base-300/30 skeleton rounded"/>
            </td>
        </tr>
    )
};

const LinkedCardsSkeleton = () => {

    return (
        <table className="table-fixed w-full px-px">
            <thead className="border-b border-neutral text-base-content/70 text-xs">
            <tr>
                <th scope="col" className="py-3.5 font-medium uppercase tracking-wide text-left px-2">
                    Invoice
                </th>
                <th scope="col" className="hidden py-3.5 lg:table-cell uppercase tracking-wide font-medium text-left px-2">
                    Date
                </th>
                <th scope="col" className="hidden py-3.5 md:table-cell uppercase tracking-wide font-medium text-left px-2">
                    Payment Method
                </th>
                <th scope="col" className="py-3.5 uppercase tracking-wide font-medium text-left px-2">
                    Status
                </th>
                <th scope="col" className="py-3.5 uppercase tracking-wide font-medium text-right px-2">
                    <span className="sr-only">Edit</span>
                </th>
            </tr>
            </thead>
            <tbody className="divide-y divide-neutral/5">
                <SkeletonRow />
                <SkeletonRow />
                <SkeletonRow />
                <SkeletonRow />
                <SkeletonRow />
            </tbody>
        </table>
    )
};

export default LinkedCardsSkeleton;
