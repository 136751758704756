import React from "react";
import PropTypes from "prop-types";

import Markdown from "../../Markdown/Markdown";
import {getToolIcon, toolNameTagOfName} from "../../../Pages/Auth/Products/TaskGlide/Chat/ChatBox/Messages/glideInfo";


const ToolMessage = ({ toolData }) => {

    return (
        <div
            key={toolData?.content?.tool_call_id}
            className="text-sm border border-dashed border-neutral-focus dark:border-neutral-focus-dark rounded-md w-fit p-2 leading-none prose-p:leading-none space-y-2"
        >
            <div className="flex space-x-2" >
                {getToolIcon(toolData?.content?.name, "w-4 h-4 text-base-content")}
                <p>{toolNameTagOfName[toolData?.content?.name]}</p>
            </div>
            <div className="leading-none prose-p:leading-none space-y-2 ml-6" >
                <Markdown
                    text={toolData?.content?.tool_output}
                    className="bg-transparent min-w-full text-base-content"
                    codeIsCustomized={true}
                />
            </div>
        </div>
    )
};

ToolMessage.propTypes = {
    toolData: PropTypes.object,
};

export default ToolMessage;
