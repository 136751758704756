import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const tgConversationSearchGET = createAsyncThunk(
    'taskGlide/conversation/search',
    async (query, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_SEARCH_ALL_CONVERSATIONS_PATH}?query=${query}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    tgConversationSearchVF: false,
    tgConversationSearchApiState: 'idle',
    tgConversationSearchResponse: null,
    tgConversationSearchError: null,
}

export const searchTgConversationsSlice = createSlice({
    name: 'tgConversationSearch',
    initialState: INITIAL_STATE,
    reducers: {
        clearTgConversationSearchVF: (state) => {
            state.tgConversationSearchVF = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(tgConversationSearchGET.pending, (state) => {
                state.tgConversationSearchApiState = 'loading';
                state.tgConversationSearchVF = true;
            })
            .addCase(tgConversationSearchGET.fulfilled, (state, action) => {
                state.tgConversationSearchApiState = 'succeeded';
                state.tgConversationSearchResponse = action.payload;
                state.tgConversationSearchVF = true;
                state.tgConversationSearchError = null;
            })
            .addCase(tgConversationSearchGET.rejected, (state, action) => {
                state.tgConversationSearchApiState = 'failed';
                state.tgConversationSearchError = action?.payload;
                state.tgConversationSearchVF = true;
                state.tgConversationSearchResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTgConversationSearchVF,
    resetTgConversationSearch
} = searchTgConversationsSlice.actions;

export default searchTgConversationsSlice.reducer;
