import React, {useEffect} from 'react'
import BillingNav from "../BillingNav";
import {useParams} from "react-router-dom";
import clsx from "clsx";

import Nav from "../../Shell/Nav/Nav";
import {useGetUserTransaction} from "../../../../Hooks/apiHooks/payments/useGetUserTransaction";
import TransactionTable from "./TransactionTable";
import TransactionCard, {formatTransactionDate} from "./TransactionCard";
import TransactionAddress from "./TransactionAddress";
import TransactionSkeleton from "./TransactionSkeleton";



const Transaction = () => {
    const { transaction_id } = useParams();

    const {
        getUserTransaction,
        userTransactionRetrievalResponse,
        userTransactionRetrievalVF
    } = useGetUserTransaction({ vfExists: true });

    useEffect(() => {
        getUserTransaction(transaction_id);
    }, [transaction_id]);

    return (
        <>
            <Nav/>
            <BillingNav/>

            <div className={clsx("mx-auto py-8 h-[calc(100dvh-112px)]", !userTransactionRetrievalVF ? "overflow-y-auto scrollbar" : "overflow-hidden")} >
                <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    {userTransactionRetrievalVF ? <TransactionSkeleton /> : <div className="p-4 sm:p-16 shadow-sm ring-1 ring-neutral sm:mx-0 rounded-md grid grid-cols-2 xl:grid-cols-4 gap-8">
                        <div className="col-span-1 xl:col-span-2 space-y-8">
                            <div className="space-y-1">
                                <div className="flex items-center space-x-2" >
                                    <h2 className="text-base font-semibold leading-6 text-base-content">Invoice</h2>
                                    <p className="text-base-content/70">#{userTransactionRetrievalResponse?.invoice_number}</p>
                                </div>

                                <p className="flex items-center space-x-1 text-base-content/70 font-light text-sm">
                                    Created on {formatTransactionDate(userTransactionRetrievalResponse?.created)}
                                </p>
                            </div>
                            <TransactionAddress transaction={userTransactionRetrievalResponse}/>
                        </div>
                        <div className="col-span-full md:col-span-1 xl:col-span-2 md:max-w-sm justify-self-end w-full">
                            <TransactionCard transaction={userTransactionRetrievalResponse}/>
                        </div>
                        <div className="col-span-full">
                        <TransactionTable transaction={userTransactionRetrievalResponse}/>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
};

Transaction.propTypes = {};

export default Transaction;
