import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const glideToolsGET = createAsyncThunk(
    'taskGlide/glides/tools/list',
    async (thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_LIST_GLIDE_TOOLS_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    glideToolsListingVF: false,
    glideToolsListingApiState: 'idle',
    glideToolsListingResponse: null,
    glideToolsListingError: null,
};

export const listGlideToolsSlice = createSlice({
    name: 'glideToolsListing',
    initialState: INITIAL_STATE,
    reducers: {
        clearGlideToolsListingVF: (state) => {
            state.glideToolsListingVF = false;
        },
        resetGlideToolsListing: (state) => {
            state.glideToolsListingVF = false;
            state.glideToolsListingApiState = 'idle';
            state.glideToolsListingResponse = null;
            state.glideToolsListingError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(glideToolsGET.pending, (state) => {
                state.glideToolsListingApiState = 'loading';
                state.glideToolsListingVF = true;
            })
            .addCase(glideToolsGET.fulfilled, (state, action) => {
                state.glideToolsListingApiState = 'succeeded';
                state.glideToolsListingResponse = action?.payload;
                state.glideToolsListingVF = true;
                state.glideToolsListingError = null;
            })
            .addCase(glideToolsGET.rejected, (state, action) => {
                state.glideToolsListingApiState = 'failed';
                state.glideToolsListingError = action?.payload;
                state.glideToolsListingVF = true;
                state.glideToolsListingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearGlideToolsListingVF,
    resetGlideToolsListing,
} = listGlideToolsSlice.actions;

export default listGlideToolsSlice.reducer;
