import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {clearAccountVerificationVF, resetAccountVerification, verifyAccountPOST} from "../../../api/features/users/accountVerificationSlice";


export const useVerifyAccount = (
    {vfExists=false} = {}
) => {
    const dispatch = useDispatch();

    const {
        accountVerificationVF,
        accountVerificationApiState,
        accountVerificationResponse,
        accountVerificationError,
    } = useSelector(state => state.accountVerificationReducer);

    // API State with visual feedback
    const accountVerificationSuccessVF = accountVerificationApiState === 'succeeded' && accountVerificationVF;
    const accountVerificationFailureVF = accountVerificationApiState === 'failed' && accountVerificationVF;
    const accountVerificationLoadingVF = accountVerificationApiState === 'loading' && accountVerificationVF;

    // Function to be called on form submission
    const verifyAccount = (values) => dispatch(verifyAccountPOST(values));

    useEffect(() => {
        if (vfExists) {
            if (accountVerificationSuccessVF) {
                dispatch(setAlert({
                    message: accountVerificationResponse?.message,
                    type: "success"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearAccountVerificationVF());
                }, 4000);

                return () => clearTimeout(timer);
            }  else if (accountVerificationFailureVF) {
                dispatch(setAlert({
                    message: accountVerificationError?.message || "Something went wrong. Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetAccountVerification());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [accountVerificationApiState]);

    return {
        verifyAccount,
        accountVerificationVF,
        accountVerificationApiState,
        accountVerificationResponse,
        accountVerificationError,
        accountVerificationSuccessVF,
        accountVerificationFailureVF,
        accountVerificationLoadingVF
    };
};
