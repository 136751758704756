import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {FaRegLightbulb} from "react-icons/fa";
import clsx from "clsx";
import {BiTachometer} from "react-icons/bi";
import {RiUploadCloudLine} from "react-icons/ri";
import {LuFileCheck} from "react-icons/lu";

import {glideColorClasses, glideDefaultColor, getGlideIcon, getToolIcon, getUppercaseLetters} from "../../Pages/Auth/Products/TaskGlide/Chat/ChatBox/Messages/glideInfo";


const RadialProgress = ({ interaction, message, toolNameTag }) => {
    const progress = interaction?.status?.percent_complete;
    const action = interaction?.status?.action;

    const glideName = message?.content?.responding_glide?.name;
    const glideColor = message?.content?.responding_glide?.color;

    const getAgentStatusIcon = () => {
        if (progress < 99) {
            switch (action) {
                case 'create_conversation':
                case 'retrieve_conversation':
                    return <FaRegLightbulb className="w-3.5 h-3.5" />;
                case 'uploading_document':
                    return <RiUploadCloudLine className="w-3.5 h-3.5" />;
                case 'uploaded_document':
                    return <LuFileCheck className="w-3.5 h-3.5" />;
                case 'evaluating':
                case 'evaluated':
                    return <BiTachometer className="w-3.5 h-3.5"/>;
                case 'tool_inputting':
                case 'tool_outputting':
                    return toolNameTag ? getToolIcon(toolNameTag) : <BiTachometer className="w-3.5 h-3.5"/>;
                default:
                    return <BiTachometer className="w-3.5 h-3.5"/>;
            }
        } else {
            return <div className="h-7 w-7 sm:h-6 sm:w-6 rounded-full flex items-center justify-center text-base-100 text-xs sm:text-2xs font-medium">
                {getGlideIcon(glideName) || getUppercaseLetters(glideName)}
            </div>;
        }
    };

    const sizeClasses = "w-7 h-7 sm:w-6 sm:h-6";
    const strokeWidth = 2.5;

    const size = 24;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    const [colors, setColors] = useState(glideColorClasses.indigo);

    useEffect(() => {
        setColors(glideColorClasses[glideColor] || glideDefaultColor);
    }, [glideColor]);

    return (
        <div className="relative flex items-center justify-center">
            <svg className={sizeClasses} viewBox={`0 0 ${size} ${size}`}>
                {progress < 99 ? (
                    <>
                        <circle
                            className={clsx([colors?.stroke, "opacity-50"])}
                            cx={size / 2}
                            cy={size / 2}
                            r={radius}
                            strokeWidth={strokeWidth}
                            fill="none"
                        />
                        <circle
                            className={clsx(["transition-all duration-1000 ease-out", colors?.stroke])}
                            cx={size / 2}
                            cy={size / 2}
                            r={radius}
                            strokeWidth={strokeWidth}
                            fill="none"
                            strokeDasharray={circumference}
                            strokeDashoffset={strokeDashoffset}
                            transform={`rotate(-90 ${size / 2} ${size / 2})`}
                        />
                    </>
                ) : (
                    <circle
                        className={clsx([colors?.stroke, colors?.fill])}
                        cx={size / 2}
                        cy={size / 2}
                        r={radius}
                        strokeWidth={strokeWidth}
                    />
                )}
            </svg>
            <div className={clsx(["flex items-center justify-center transition-all rounded-full", colors?.text])} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                {getAgentStatusIcon()}
            </div>
        </div>
    )
};

RadialProgress.propTypes = {
    interaction: PropTypes.object,
    message: PropTypes.object,
    toolNameTag: PropTypes.string,
};

export default RadialProgress;
