import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const singleTgConversationGET = createAsyncThunk(
    'taskGlide/conversation/get',
    async ({ conversation_uuid, page_size }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_GET_DELETE_SINGLE_CONVO_PATH}${conversation_uuid}/?page_size=${page_size}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    tgSingleConversationRetrievalVF: false,
    tgSingleConversationRetrievalApiState: 'idle',
    tgSingleConversationRetrievalResponse: null,
    tgSingleConversationRetrievalError: null,
};

export const getSingleTgConversationSlice = createSlice({
    name: 'getSingleTGConversation',
    initialState: INITIAL_STATE,
    reducers: {
        clearTgSingleConversationRetrievalVF: (state) => {
            state.tgSingleConversationRetrievalVF = false;
        },
        resetTgSingleConversationRetrieval: (state) => {
            state.tgSingleConversationRetrievalVF = false;
            state.tgSingleConversationRetrievalApiState = 'idle';
            state.tgSingleConversationRetrievalResponse = null;
            state.tgSingleConversationRetrievalError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(singleTgConversationGET.pending, (state) => {
                state.tgSingleConversationRetrievalApiState = 'loading';
                state.tgSingleConversationRetrievalVF = true;
            })
            .addCase(singleTgConversationGET.fulfilled, (state, action) => {
                state.tgSingleConversationRetrievalApiState = 'succeeded';
                state.tgSingleConversationRetrievalResponse = action.payload;
                state.tgSingleConversationRetrievalVF = true;
                state.tgSingleConversationRetrievalError = null;
            })
            .addCase(singleTgConversationGET.rejected, (state, action) => {
                state.tgSingleConversationRetrievalApiState = 'failed';
                state.tgSingleConversationRetrievalError = action?.payload;
                state.tgSingleConversationRetrievalVF = true;
                state.tgSingleConversationRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTgSingleConversationRetrievalVF,
    resetTgSingleConversationRetrieval,
} = getSingleTgConversationSlice.actions;

export default getSingleTgConversationSlice.reducer;
