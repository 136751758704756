import clsx from "clsx";
import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {motion} from "framer-motion";
import {MdCheck} from "react-icons/md";
import {AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import {Menu, Transition} from "@headlessui/react";

import {glideColorClasses, getGlideIcon} from "../Messages/glideInfo";
import {IoCheckmarkDoneOutline} from "react-icons/io5";
import {PiAirplaneTakeoff, PiCloudSun, PiGlobe} from "react-icons/pi";
import {useListUserGlides} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useListUserGlides";
import SelectedGlidesIcons from "../../../../../../../Components/TaskGlide/SelectedGlidesIcons";


const RenderButton = ({ formik, text_input, buttonText, icon }) => {
    return (
        <button
            className="flex items-center justify-center bg-base-100 rounded-xl font-light hover:sm:transition-colors hover:sm:duration-150 ring-[0.5px] ring-neutral-focus dark:ring-neutral w-full text-base-content/70 text-sm text-start h-9 gap-x-3 hover:text-base-content px-2 focus-visible:ring focus-visible:ring-info/40 focus-visible:outline-none dark:focus-visible:ring-info/40 focus-visible:rounded-xl"
            onClick={() => {
                formik.setFieldValue('text_input', text_input);
                formik.submitForm();
            }}
        >
            <p className="shrink-0" >{icon}</p>
            <p className="truncate" >{buttonText}</p>
        </button>
    );
};

RenderButton.propTypes = {
    formik: PropTypes.object,
    text_input: PropTypes.string,
    icon: PropTypes.element,
    buttonText: PropTypes.string,
};


const TaskGlideEmptyState = (
    { formik, selectedGlides, setSelectedGlides }
) => {
    const {getGlides, glidesListingResponse} = useListUserGlides();
    const [searchGlides, setSearchGlides] = useState('');

    const handleSearchGlidesChange = (event) => {
        setSearchGlides(event.target.value);
    };

    const filteredGlides = glidesListingResponse?.filter(item =>
        item?.glide?.name.toLowerCase()?.includes(searchGlides.toLowerCase())
    );


    useEffect(() => {
        getGlides();
    }, []);

    const toggleGlideSelection = (uuid) => {
        setSelectedGlides(glide => {
            if (glide?.includes(uuid)) {
                return glide.filter(id => id !== uuid);
            } else {
                return [...glide, uuid];
            }
        });
    };

    const areAllSelected = glidesListingResponse?.every(item =>
        selectedGlides?.includes(item?.glide)
    );

    const toggleAllGlidesSelection = () => {
        if (areAllSelected) {
            setSelectedGlides([]);
        } else {
            setSelectedGlides(glidesListingResponse?.map(item => item?.glide) || []);
        }
    };

    return (
        <div className="relative flex flex-col justify-center h-full text-base-300/50 space-y-6 px-4 sm:px-6 lg:px-8 w-full mx-auto" >
            <Menu as="div" >
                <Menu.Button
                    type="button"
                    data-tip="Selected MyGlides"
                    disabled={glidesListingResponse?.length === 1}
                    className="group/selected-glides absolute top-5 right-5 sm:right-6 lg:right-3 z-40 focus-visible:ring focus-visible:ring-info/40 focus-visible:outline-none dark:focus-visible:ring-info/40 focus-visible:rounded-full"
                >
                    <SelectedGlidesIcons selectedGlides={selectedGlides}/>
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out"
                    enterFrom="transform opacity-0 -translate-y-2"
                    enterTo="transform opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="transform opacity-100 translate-y-0"
                    leaveTo="transform opacity-0 -translate-y-2"
                >
                    <Menu.Items className="absolute top-12 right-5 sm:right-6 lg:right-3 mt-2 w-52 sm:w-48 origin-top-right rounded-md bg-base-200 shadow-[0px_0px_15px_rgba(0,0,0,0.25)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.75)] ring-[0.5px] ring-neutral-focus/70 dark:ring-neutral-focus-dark/70 focus:ring-0 z-20 h-fit overflow-hidden pb-1.5">
                        <div className="p-1.5">
                            <div className="relative bg-base-200 rounded-md">
                                <input
                                    className="bg-base-100 rounded-md w-full outline-0 border-0 focus:ring-0 py-1 text-sm ocus:placeholder-transparent placeholder-base-300/70 placeholder:font-light text-base-content pr-8"
                                    placeholder="Search Glides"
                                    value={searchGlides}
                                    onChange={handleSearchGlidesChange}
                                />
                                <button
                                    className="bg-base-200 rounded-md p-1 absolute right-1.5 top-1.5 my-auto text-base-300 group/all-glide-selection shrink-0"
                                    onClick={toggleAllGlidesSelection}
                                >
                                    {!areAllSelected ?
                                        <AiOutlineCheck className="w-3.5 h-3.5 sm:w-3 sm:h-3 shrink-0"/> :
                                        <AiOutlineClose className="w-3 h-3 shrink-0"/>}
                                    <div className="left-tooltips right-8 group-hover/all-glide-selection:sm:block">
                                        {areAllSelected ? "Deselect All" : "Select All"}
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="overflow-x-hidden overflow-y-auto scrollbar max-h-44">
                            {filteredGlides?.map((item) => {
                                const glide = item?.glide;
                                return (
                                    <button
                                        type="button"
                                        key={glide?.uuid}
                                        className="relative group/glide-name text-base-content/70 hover:sm:text-base-content hover:sm:bg-base-100/70 px-3 py-2 text-sm w-full cursor-pointer flex items-center justify-between"
                                        onClick={() => toggleGlideSelection(glide)}
                                    >
                                        <div className="flex items-center space-x-2 w-fit">
                                            <div
                                                className={clsx("h-5 w-5 p-1 rounded-full flex items-center justify-center text-base-100 text-2xs shrink-0 leading-none tracking-tighter z-40", glideColorClasses[glide?.color]?.bg)}
                                            >
                                                {getGlideIcon(glide?.name)}
                                            </div>
                                            <p className="truncate w-28 text-start">{glide?.name}</p>
                                        </div>
                                        <div className="px-0.5">
                                            {selectedGlides?.includes(glide) && < MdCheck className="w-4 h-4 text-base-300 shrink-0"/>}
                                        </div>
                                    </button>
                                )
                            })}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
            <div className="space-y-3 hidden sm:block w-full mx-auto">
                <motion.h1
                    transition={{duration: 0.5}}
                    initial={{opacity: 0, y: -10}}
                    animate={{opacity: 1, y: 0}}
                    className="bg-base-content inline-block text-transparent bg-clip-text text-3xl lg:text-4xl font-bold"
                >
                    TaskGlide
                </motion.h1>
                <motion.p
                    transition={{duration: 0.5, delay: 0.25}}
                    initial={{opacity: 0, y: -10}}
                    animate={{opacity: 1, y: 0}}
                    className="text-base-content/70 text-lg lg:text-xl font-normal"
                >
                    Your personal hub for efficiency. Glide through tasks with unprecedented ease.
                </motion.p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <motion.div
                    transition={{duration: 0.5, delay: 0.5}}
                    initial={{opacity: 0, y: -10}}
                    animate={{opacity: 1, y: 0}}
                    className="flex flex-col bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 rounded-xl p-4 space-y-4 w-full mx-auto"
                >
                    <div className="flex w-full justify-between items-center">
                        <h2 className="text-base-content font-semibold text-xl">Explore</h2>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <RenderButton
                            formik={formik}
                            text_input="Tell me some interesting facts I wouldn't know!"
                            buttonText="Fun Facts"
                            icon={<IoCheckmarkDoneOutline className="w-5 h-5 text-green-500 shrink-0" />}
                        />
                        <RenderButton
                            formik={formik}
                            text_input="I want to plan a vacation! What are some fun ideas?"
                            buttonText="Vacation Ideas"
                            icon={<PiAirplaneTakeoff className="w-5 h-5 text-blue-500 shrink-0" />}
                        />
                    </div>
                </motion.div>
                <motion.div
                    transition={{duration: 0.5, delay: 0.5}}
                    initial={{opacity: 0, y: -10}}
                    animate={{opacity: 1, y: 0}}
                    className="flex flex-col bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 rounded-xl p-4 space-y-4 w-full mx-auto"
                >
                    <div className="flex w-full justify-between items-center">
                        <h2 className="text-base-content font-semibold text-xl">Discover</h2>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <RenderButton
                            formik={formik}
                            text_input="What's going on in the world today? Any interesting news?"
                            buttonText="Discover News"
                            icon={<PiGlobe className="w-5 h-5 text-red-500 shrink-0" />}
                        />
                        <RenderButton
                            formik={formik}
                            text_input="Give me today's current weather in my location please!"
                            buttonText="Local Weather"
                            icon={<PiCloudSun className="w-5 h-5 text-yellow-500 shrink-0" />}
                        />
                    </div>
                </motion.div>
            </div>
        </div>
    )
};

TaskGlideEmptyState.propTypes = {
    formik: PropTypes.object,
    selectedGlides: PropTypes.array,
    setSelectedGlides: PropTypes.func,
};

export default TaskGlideEmptyState;
