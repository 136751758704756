import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const subscriptionPlanGET = createAsyncThunk(
    'payments/subscription-plans/get',
    async (product_name, thunkAPI) => {
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_SUBSCRIPTION_PLAN_GET_PATH}${product_name}/`,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    subscriptionPlanRetrievalVF: false,
    subscriptionPlanRetrievalApiState: 'idle',
    subscriptionPlanRetrievalResponse: null,
    subscriptionPlanRetrievalError: null,
};

export const getSubscriptionPlanSlice = createSlice({
    name: 'getSubscriptionPlan',
    initialState: INITIAL_STATE,
    reducers: {
        clearSubscriptionPlanRetrievalVF: (state) => {
            state.subscriptionPlanRetrievalVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(subscriptionPlanGET.pending, (state) => {
                state.subscriptionPlanRetrievalApiState = 'loading';
                state.subscriptionPlanRetrievalVF = true;
            })
            .addCase(subscriptionPlanGET.fulfilled, (state, action) => {
                state.subscriptionPlanRetrievalApiState = 'succeeded';
                state.subscriptionPlanRetrievalResponse = action.payload;
                state.subscriptionPlanRetrievalVF = true;
                state.subscriptionPlanRetrievalError = null;
            })
            .addCase(subscriptionPlanGET.rejected, (state, action) => {
                state.subscriptionPlanRetrievalApiState = 'failed';
                state.subscriptionPlanRetrievalError = action?.payload;
                state.subscriptionPlanRetrievalVF = true;
                state.subscriptionPlanRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const { clearSubscriptionPlanRetrievalVF } = getSubscriptionPlanSlice.actions;

export default getSubscriptionPlanSlice.reducer;
