import React, {useEffect, useState} from "react";
import clsx from "clsx";

import Nav from "../../../Shell/Nav/Nav";
import {useGetTaskGlideSettings} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useGetTaskGlideSettings";
import {useUpdateTaskGlideSettings} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useUpdateTaskGlideSettings";
import ToggleButton from "../../../../../Components/ToggleButton/ToggleButton";
import GoogleOAuthButton from "./GoogleOAuth/GoogleOAuthButton";
import TaskGlideSettingsSkeleton from "./TaskGlideSettingsSkeleton";
import {useGeneralUiActions} from "../../../../../Hooks/frontendHooks/useGeneralUiActions";


const TaskGlideSettings = () => {
    const [radioBtnUpdating, setRadioBtnUpdating] = useState('');
    const {
        getTaskGlideSettings,
        taskGlideSettingsRetrievalVF,
        taskGlideSettingsRetrievalResponse
    } = useGetTaskGlideSettings({ vfExists: true });
    const { updateTaskGlideSettings } = useUpdateTaskGlideSettings({ vfExists: true, refreshAndReset: true });
    const { firstRender, handleFirstRender } = useGeneralUiActions();

    const settings = taskGlideSettingsRetrievalResponse;

    useEffect(() => {
        getTaskGlideSettings();
        handleFirstRender(1000);
    }, []);

    const handleUpdate = (settingKey) => {
        if (settings && settingKey in settings) {
            const updatedSettings = {[settingKey]: !settings[settingKey]};
            updateTaskGlideSettings(updatedSettings);
            setRadioBtnUpdating(settingKey);
        }
    };

    return (
        <>
            <Nav/>
            <div className="py-3 bg-base-100 sticky top-0 right-0 left-0 lg:left-16 border-b border-neutral z-[2]">
                <div className="md:flex md:items-center md:justify-between space-y-3 md:space-y-0 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="flex items-center space-x-3 py-2">
                        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:tracking-tight text-base-content flex-1">Glides Settings</h1>
                    </div>
                </div>
            </div>
            {(!taskGlideSettingsRetrievalResponse && taskGlideSettingsRetrievalVF && firstRender) ? <TaskGlideSettingsSkeleton /> :
                <main className="bg-base-100 overflow-y-auto h-[calc(100dvh-112px)] scrollbar">
                    <div className="border-b border-neutral">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                            <div className="col-span-full md:col-span-4">
                                <h2 className="text-base font-semibold leading-none text-base-content">Profile Access Control</h2>
                                <p className="mt-1 text-sm leading-6 text-base-content/70">
                                    Fine-tune your privacy by controlling access to your data.
                                </p>
                            </div>
                            <div className="relative md:col-start-6 md:col-span-5 space-y-5">
                                <ToggleButton
                                    loading={taskGlideSettingsRetrievalVF && radioBtnUpdating === 'access_read_profile'}
                                    onChange={() => handleUpdate('access_read_profile')}
                                    checked={settings?.access_read_profile}
                                    title="Read user profile"
                                    description="Allows your Glides to read your Dovise profile in order to provide you with a personalized experience."
                                    accessibleTitle="Allow TaskGlide to read your profile"
                                />
                                <ToggleButton
                                    loading={taskGlideSettingsRetrievalVF && radioBtnUpdating === 'access_write_profile'}
                                    onChange={() => handleUpdate('access_write_profile')}
                                    checked={settings?.access_write_profile}
                                    title="Edit user profile"
                                    description="Allows your Glides to edit your Dovise profile in case you want to update your information."
                                    accessibleTitle="Allow TaskGlide to edit your profile"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="border-b border-neutral">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                            <div className="col-span-full md:col-span-4">
                                <h2 className="text-base font-semibold leading-none text-base-content">External Browsing Control</h2>
                                <p className="mt-1 text-sm leading-6 text-base-content/70">
                                    Allow TaskGlide to browse external services. This integration allows seamless interaction with less limitations.
                                </p>
                            </div>
                            <div className="relative md:col-start-6 md:col-span-5 space-y-5">
                                <ToggleButton
                                    loading={taskGlideSettingsRetrievalVF && radioBtnUpdating === 'access_web'}
                                    onChange={() => handleUpdate('access_web')}
                                    checked={settings?.access_web}
                                    title="Browse the web (Google, Bing, etc.)"
                                    description="Allows your Glides to browse the web on your behalf."
                                    accessibleTitle="Allow TaskGlide to browse the web"
                                />
                                <ToggleButton
                                    loading={taskGlideSettingsRetrievalVF && radioBtnUpdating === 'access_wikipedia'}
                                    onChange={() => handleUpdate('access_wikipedia')}
                                    checked={settings?.access_wikipedia}
                                    title="Browse Wikipedia"
                                    description="Allows your Glides to browse Wikipedia on your behalf."
                                    accessibleTitle="Get access to the web"
                                />
                                <ToggleButton
                                    loading={taskGlideSettingsRetrievalVF && radioBtnUpdating === 'access_weather'}
                                    onChange={() => handleUpdate('access_weather')}
                                    checked={settings?.access_weather}
                                    title="Weather data"
                                    description="Allows your Glides to get weather data on your behalf."
                                    accessibleTitle="Allow TaskGlide to get weather data"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="border-b border-neutral">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                            <div className="col-span-full md:col-span-4">
                                <h2 className="text-base font-semibold leading-none text-base-content">External Accounts Control</h2>
                                <p className="mt-1 text-sm leading-6 text-base-content/70">
                                    Allow TaskGlide to access your external accounts. This is originally disabled.
                                </p>
                            </div>
                            <div className="relative md:col-start-6 md:col-span-5 space-y-5">
                                <GoogleOAuthButton/>
                                <input
                                    name="google_account"
                                    className="input-field"
                                    placeholder="example@gmail.com"
                                    disabled
                                    value={settings?.google_account}
                                />
                                <div className={clsx("space-y-5", !settings?.access_google_account && "opacity-50")}>
                                    <ToggleButton
                                        disabled={!settings?.access_google_account}
                                        loading={taskGlideSettingsRetrievalVF && radioBtnUpdating === 'access_gmail'}
                                        onChange={() => handleUpdate('access_gmail')}
                                        checked={settings?.access_gmail}
                                        title="Read, draft, send, search, etc."
                                        description="Allows your Glides to use the Gmail tool which can read, draft, send, delete and search emails on your behalf."
                                        accessibleTitle="Allows TaskGlide to read, draft and send emails using your Gmail account"
                                        badgeText="Beta"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>}
        </>
    )
};

export default TaskGlideSettings;
