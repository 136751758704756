import React from "react";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";

const MobileRegistrationSteps = ({ steps }) => {
    const location = useLocation();
    const currentStepIndex = steps.findIndex((step) => step.href === location.pathname);
    const progressWidth = `${((currentStepIndex + 1) / steps.length) * 100}%`;

    return (
        <div className="w-full py-4 px-4 sm:px-6 md:px-px space-y-4 block md:hidden border-b border-neutral-focus dark:border-neutral-focus-dark">
            <h2 className="text-2xl sm:text-3xl font-bold leading-7 text-base-content sm:truncate sm:tracking-tight">Join Dovise</h2>
            <div className="space-y-3" >
                <div className="overflow-hidden rounded-full bg-base-content/10">
                    <div className="h-2 rounded-full bg-indigo-600" style={{width: progressWidth}}/>
                </div>
                <div className="justify-between text-left text-sm font-medium text-gray-600 hidden sm:flex">
                    {steps.map((step, index) => (
                        <div
                            key={step.name}
                            className={`${index <= currentStepIndex ? 'text-indigo-600' : ''}`}
                        >
                            {step.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

MobileRegistrationSteps.propTypes = {
    steps: PropTypes.array.isRequired,
};

export default MobileRegistrationSteps;
