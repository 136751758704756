import { useEffect } from "react";
import { useSelector } from "react-redux";

const ThemeController = () => {
    const theme = useSelector(state => state.frontendReducer.theme);

    useEffect(() => {
        const root = document.documentElement;
        if (theme === "dark") {
            root.setAttribute('data-theme', 'dark');
            root.classList.add('dark');
        } else {
            root.setAttribute('data-theme', 'light');
            root.classList.remove('dark');
        }
    }, [theme]);

    return null;
};

export default ThemeController;
