import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const userSubscriptionsLIST = createAsyncThunk(
    'payments/user-subscriptions/list',
    async (thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_USER_SUBSCRIPTIONS_LIST_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userSubscriptionsListingVF: false,
    userSubscriptionsListingApiState: 'idle',
    userSubscriptionsListingResponse: null,
    userSubscriptionsListingError: null,
};

export const listUserSubscriptionsSlice = createSlice({
    name: 'userSubscriptionsListing',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserSubscriptionsListingVF: (state) => {
            state.userSubscriptionsListingVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userSubscriptionsLIST.pending, (state) => {
                state.userSubscriptionsListingApiState = 'loading';
                state.userSubscriptionsListingVF = true;
            })
            .addCase(userSubscriptionsLIST.fulfilled, (state, action) => {
                state.userSubscriptionsListingApiState = 'succeeded';
                state.userSubscriptionsListingResponse = action.payload;
                state.userSubscriptionsListingVF = true;
                state.userSubscriptionsListingError = null;
            })
            .addCase(userSubscriptionsLIST.rejected, (state, action) => {
                state.userSubscriptionsListingApiState = 'failed';
                state.userSubscriptionsListingError = action?.payload;
                state.userSubscriptionsListingVF = true;
                state.userSubscriptionsListingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUserSubscriptionsListingVF
} = listUserSubscriptionsSlice.actions;

export default listUserSubscriptionsSlice.reducer;
