import React from "react";


const SubscriptionPlanSkeleton = () => {

    return (
        <div className="gap-x-6 py-3 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl text-sm leading-6">
            <div className="mb-4 space-y-3 py-2">
                <div className="h-4 w-24 bg-base-300/30 skeleton rounded" />
                <div className="h-4 w-full md:w-1/2 bg-base-300/30 skeleton rounded" />
            </div>
            <div className="border-t border-neutral">
                <div className="divide-y divide-neutral">
                    <div className="py-4 grid grid-cols-5 gap-y-1 gap-x-4">
                        <div className="h-4 w-20 bg-base-300/30 skeleton rounded col-span-full sm:col-span-2 my-2"/>
                        <div className="flex items-center gap-x-2">
                            <div className="h-4 w-20 bg-base-300/30 skeleton rounded my-2"/>
                            <div className="h-4 w-32 bg-base-300/30 skeleton rounded my-2"/>
                        </div>
                    </div>
                    <div className="py-6 grid grid-cols-5 sm:gap-x-4">
                        <div className="h-4 w-20 bg-base-300/30 skeleton rounded col-span-full sm:col-span-2 my-2"/>
                        <div className="h-4 w-20 bg-base-300/30 skeleton rounded my-2"/>
                    </div>
                    <div className="py-6 grid grid-cols-5 sm:gap-x-4">
                        <div className="h-4 w-20 bg-base-300/30 skeleton rounded col-span-full sm:col-span-2 my-2"/>
                        <div className="h-4 w-20 bg-base-300/30 skeleton rounded my-2"/>
                    </div>
                    <div className="py-6 grid grid-cols-5 gap-y-6">
                        <div className="h-4 w-20 bg-base-300/30 skeleton rounded col-span-full sm:col-span-2"/>
                        <div className="col-span-full sm:col-span-3 sm:col-start-3 space-y-6">
                            <div className="relative w-full items-center grid grid-cols-5 gap-2 my-2 pt-8">
                                <div className="h-4 w-20 bg-base-300/30 skeleton rounded col-span-full: col-span-2"/>
                                <div className="h-8 bg-base-300/30 skeleton rounded-md col-span-full md:col-span-3 w-full justify-self-end"/>
                            </div>
                            <div className="col-span-2 col-start-2 space-y-2">
                                <div className="flex items-center justify-between text-base-content/70 py-2">
                                    <div className="h-4 w-20 bg-base-300/30 skeleton rounded col-span-full: col-span-2"/>
                                    <div className="h-4 w-20 bg-base-300/30 skeleton rounded"/>
                                </div>
                                <div className="flex items-center justify-between font-medium py-2">
                                    <div className="h-4 w-20 bg-base-300/30 skeleton rounded col-span-full: col-span-2"/>
                                    <div className="h-4 w-20 bg-base-300/30 skeleton rounded"/>
                                </div>
                            </div>
                            <div className="flex justify-end col-span-full">
                                <div className="bg-base-300/30 skeleton rounded-md h-8 w-20" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SubscriptionPlanSkeleton;
