import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import PropTypes from "prop-types";
import clsx from "clsx";
import rehypeRaw from "rehype-raw";
import remarkFootnotes from "remark-footnotes";
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'

import MdTable from "./Components/MdTable";
import MdLinks from "./Components/MdLinks";
import MdImages from "./Components/MdImages";
import MdBold from "./Components/MdBold";
import CodeComponent from "./Components/MdCode/MdCodeBlock";
import "./customMarkdown.css";


const Markdown = ({
    text,
    className,
    contentClass,
    codeClassName,
    includeCodeHeader,
    includeHtml = false,
    codeIsCustomized,
    ...props
}) => {

    const components = {
        code: (props) => <CodeComponent
            codeClassName={codeClassName}
            includeCodeHeader={includeCodeHeader}
            codeIsCustomized={codeIsCustomized}
            {...props}
        />,
        table: MdTable,
        a: MdLinks,
        img: MdImages,
        strong: MdBold,
    };

    const transformMathDelimiters = (content) => {
        return content
            ?.replace(/\\\(/g, '$')
            ?.replace(/\\\)/g, '$')
            ?.replace(/\\\[/g, '$$')
            ?.replace(/\\\]/g, '$$');
    };

    const parseMarkdown = (content) => {
        return transformMathDelimiters(content);
    };

    const tableClasses = "prose-table:overflow-y-hidden prose-table:text-base prose-table:text-sm prose-th:px-3 prose-th:py-1 prose-th:bg-base-200 prose-th:border-neutral-focus/70 dark:prose-th:border-neutral-focus-dark/70 prose-td:px-3 prose-td:py-2 prose-th:prose-medium prose-td:font-normal prose-td:whitespace-normal prose-th:whitespace-normal prose-td:border-neutral-focus/70 dark:prose-td:border-neutral-focus-dark/70 prose-td:border-l prose-td:border-r-0 last:prose-td:border-r prose-td:border-t-0 prose-th:border-r-0 last:prose-th:border-r first:prose-td:border-b first:prose-td:border-t-0";

    const codeClasses = "prose-pre:overflow-x-auto prose-pre:overflow-y-hidden prose-pre:p-0 prose-pre:my-2 prose-pre:bg-transparent prose-code:p-0 prose-code:m-0 prose-pre:leading-loose";

    return (
        <article className={clsx(
            className,
            "text-sm font-normal leading-8 max-w-none",
            "prose text-base-content tracking-normal prose-strong:font-semibold",
            "prose-hr:my-4 prose-p:leading-8",
            "prose-h1:text-xl prose-h2:text-lg prose-h3:text-base prose-h4:text-sm",
            "prose-h1:mt-4 prose-h2:mt-3.5 prose-h3:mt-3 prose-h4:mt-2.5",
            "prose-h1:mb-2 prose-h2:mb-1.5 prose-h3:mb-1 prose-h4:mb-0.5",
            "prose-ul:my-0 prose-ol:my-0 prose-li:text-sm prose-li:font-normal prose-li:p-0 prose-li:mt-0.5 prose-li:mb-1",
            "prose-img:bg-transparent prose-img:m-0 prose-img:p-0 prose-img:rounded-md",
            "prose-blockquote:font-serif prose-blockquote:font-medium prose-blockquote:tracking-wide",
            codeClasses,
            tableClasses
        )}>
            <ReactMarkdown
                components={components}
                remarkPlugins={[remarkGfm, remarkFootnotes, remarkMath]}
                rehypePlugins={[rehypeRaw, rehypeKatex]}
                className={clsx(contentClass)}
                {...props}
            >
                {parseMarkdown(text)}
            </ReactMarkdown>
        </article>
    );
};

Markdown.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    contentClass: PropTypes.string,
    codeClassName: PropTypes.string,
    includeHtml: PropTypes.bool,
    includeCodeHeader: PropTypes.bool,
    codeIsCustomized: PropTypes.bool,
};

export default Markdown;
