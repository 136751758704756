import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const streamTaskGlidePOST = createAsyncThunk(
    'taskGlide/interaction/create',
    async ({ values, tgConversationId, tgInteractionId, onStreamData, sessionInfo, signal }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        const path = tgConversationId ? `${process.env.REACT_APP_TASKGLIDE_STREAM_PATH}${tgConversationId}/` : `${process.env.REACT_APP_TASKGLIDE_STREAM_PATH}`;

        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: path,
                    args: values,
                    accessCode: access,
                    isStream: true,
                    onStreamData,
                    headers: { 'X-User-Session': JSON.stringify(sessionInfo) },
                    signal: signal,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    taskGlideStreamVF: false,
    taskGlideStreamApiState: 'idle',
    taskGlideStreamResponse: null,
    taskGlideStreamError: null,
}

export const streamTaskGlideSlice = createSlice({
    name: 'taskGlideStreaming',
    initialState: INITIAL_STATE,
    reducers: {
        clearTaskGlideStreamingVF: (state) => {
            state.taskGlideStreamVF = false;
        },
        resetTaskGlideStreaming: (state) => {
            state.taskGlideStreamVF = false;
            state.taskGlideStreamApiState = 'idle';
            state.taskGlideStreamResponse = null;
            state.taskGlideStreamError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(streamTaskGlidePOST.pending, (state) => {
                state.taskGlideStreamApiState = 'loading';
                state.taskGlideStreamVF = true;
            })
            .addCase(streamTaskGlidePOST.fulfilled, (state, action) => {
                state.taskGlideStreamApiState = 'succeeded';
                state.taskGlideStreamResponse = action.payload;
                state.taskGlideStreamVF = true;
                state.taskGlideStreamError = null;
            })
            .addCase(streamTaskGlidePOST.rejected, (state, action) => {
                state.taskGlideStreamApiState = 'failed';
                state.taskGlideStreamError = action?.payload;
                state.taskGlideStreamVF = true;
                state.taskGlideStreamResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTaskGlideStreamingVF,
    resetTaskGlideStreaming
} = streamTaskGlideSlice.actions;

export default streamTaskGlideSlice.reducer;
