import React from "react";
import PropTypes from "prop-types";


const TransactionAddress = ({ transaction }) => {
    
    return (
        <div className="xl:flex xl:justify-between space-y-8 xl:space-x-4 xl:space-y-0 text-base-content">
            <div className="space-y-3" >
                <p className="font-semibold">From</p>
                <dd className="space-y-0.5">
                    <span className="font-medium">{transaction?.payer_info?.name}</span>
                    <div className="font-light">
                        <p>{transaction?.payer_info?.address?.line1}</p>
                        <p>{transaction?.payer_info?.address?.line2}</p>
                        <p>{transaction?.payer_info?.address?.city}</p>
                        <p>{transaction?.payer_info?.address?.state}, {transaction?.payer_info?.address?.country}, {transaction?.payer_info?.address?.postal_code}</p>
                    </div>
                </dd>
            </div>
            <div className="space-y-3" >
                <p className="font-semibold">To</p>
                <dd className="space-y-0.5">
                    <span className="font-medium">{transaction?.payee_info?.name}</span>
                    <p className="font-light" >{transaction?.payee_info?.address?.state}, {transaction?.payee_info?.address?.country}</p>
                </dd>
            </div>
        </div>
    )
};

TransactionAddress.propTypes = {
    transaction: PropTypes.object,
}

export default TransactionAddress;
