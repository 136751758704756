import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import clsx from "clsx";
import {ImSpinner3} from "react-icons/im";
import * as yup from "yup";

import routes from "../../../Routes/routes";
import {companyLogo} from "../../../Design/companyLogo";
import Alerts from "../../../Components/Alerts/Alerts";
import {useVerifyAccount} from "../../../Hooks/apiHooks/users/useVerifyAccount";
import {useAlert} from "../../../Hooks/frontendHooks/useAlert";


const initialValues = {
    username: "",
}

const validationSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
});

const VerifyAccount = () => {
    const dispatch = useDispatch();
    const {alert, alertKey} = useAlert();

    const {
        verifyAccount,
        accountVerificationSuccessVF,
        accountVerificationFailureVF,
        accountVerificationLoadingVF
    } = useVerifyAccount({vfExists: true});


    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        verifyAccount(values);
        setSubmitting(false);
        resetForm();
    };

    return (
        <>
            <div className="flex min-h-screen flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 bg-base-100">
                <div className="mx-auto w-full max-w-sm">
                    <Link to={routes.unAuth.home.href} className="outline:none focus:outline-none">
                        <span className="sr-only">Dovise</span>
                        <img
                            className="mx-auto h-10 w-auto transition-transform hover:scale-110 duration-500"
                            src={companyLogo}
                            alt="Your Company"
                        />
                    </Link>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-base-content">
                        Verify your account
                    </h2>
                </div>

                <div className="mt-10 mx-auto w-full max-w-sm">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting, isValid, values }) => (
                            <Form className="space-y-6">
                                <div>
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-base-content">
                                        Username
                                    </label>
                                    <div className="mt-2">
                                        <Field
                                            type="text"
                                            name="username"
                                            autoComplete="username"
                                            required
                                            className="input-field"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        disabled={!isValid || accountVerificationLoadingVF || isSubmitting || values.username === ""}
                                        className="relative primary-button w-full"
                                    >
                                        <ImSpinner3
                                            className={clsx(
                                                "absolute inset-0 m-auto animate-spin h-5 w-5",
                                                (accountVerificationLoadingVF || isSubmitting) ? "" : "hidden"
                                            )}
                                            aria-hidden="true"
                                        />
                                        <p className={(accountVerificationLoadingVF || isSubmitting) ? "invisible" : ""}>
                                            Send verification link
                                        </p>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                    <p className="mt-10 text-center text-sm text-base-content/70">
                        Already verified?{' '}
                        <Link
                            to={routes.unAuth.login.href}
                            onClick={() => dispatch({type: "RESET_USER_MANAGEMENT"})}
                            className="font-medium leading-6 text-primary hover:text-primary-focus ml-1 outline:none focus:outline-none">
                            Sign in
                        </Link>
                    </p>
                </div>
            </div>
            {(accountVerificationSuccessVF || accountVerificationFailureVF) &&
                <Alerts message={alert.message} type={alert.type} keyValue={alertKey}/>}
        </>
    )
};

export default VerifyAccount;
