import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import routes from "../../../Routes/routes";
import {clearRegistrationVF, registerPOST} from "../../../api/features/users/registrationSlice";
import {useNavigate} from "react-router-dom";


export const useRegister = (vfExists = false) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        registrationVF,
        registrationApiState,
        registrationError,
    } = useSelector(state => state.registrationReducer);

    const registrationSuccessVF = registrationApiState === 'succeeded' && registrationVF;
    const registrationFailureVF = registrationApiState === 'failed' && registrationVF;
    const registrationLoadingVF = registrationApiState === 'loading' && registrationVF;

    const register = (values) => dispatch(registerPOST(values));

    useEffect(() => {
        if (registrationSuccessVF) {
            navigate(routes.unAuth.login.href);
        }
        else if (vfExists && registrationFailureVF) {
            // TODO: FIX THE ISSUE OF ARRAYS INSTEAD OF STRINGS
            dispatch(setAlert({
                message: (registrationError?.email || registrationError?.username || registrationError?.password || registrationError?.password2) || "Please try again.",
                type: "error"
            }));
            dispatch(incrementAlertKey());

            const timer = setTimeout(() => {
                dispatch(clearRegistrationVF());
            }, 4000);

            return () => clearTimeout(timer);
        }
    }, [registrationApiState]);

    return {
        register,
        registrationApiState,
        registrationFailureVF,
        registrationSuccessVF,
        registrationLoadingVF,
        registrationVF
    };
};

