import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    currentTgConversation: null,
    currentTestTgConversation: null,
};

export const getCurrentTgConversationSlice = createSlice({
    name: 'currentTgConversationRetrieval',
    initialState: initialState,
    reducers: {
        setCurrentTgConversation: (state, action) => {
            state.currentTgConversation = action.payload;
        },
        setCurrentTestTgConversation: (state, action) => {
            state.currentTestTgConversation = action.payload;
        },
    },
});

export const {
    setCurrentTgConversation,
    setCurrentTestTgConversation,
} = getCurrentTgConversationSlice.actions;

export default getCurrentTgConversationSlice.reducer;
