import {a11yDark, a11yLight} from "react-syntax-highlighter/src/styles/hljs";
import {useSelector} from "react-redux";

export const useCustomHLJS = () => {
    const theme = useSelector(state => state.frontendReducer.theme);

    let darkStyle = {};
    let lightStyle = {};

    if (theme === 'dark') {
        darkStyle['hljs'] = {...darkStyle['hljs'], 'backgroundColor': 'transparent', 'background': 'transparent', color: '#a4abb8' };
        darkStyle['hljs-keyword'] = {...darkStyle['hljs-keyword'], 'color': '#6366f1'};
        darkStyle['hljs-comment'] = {...darkStyle['hljs-comment'], 'color': '#80868f'};
        darkStyle['hljs-tag'] = {...darkStyle['hljs-tag'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-name'] = {...darkStyle['hljs-name'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-regexp'] = {...darkStyle['hljs-regexp'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-link'] = {...darkStyle['hljs-link'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-built_in'] = {...darkStyle['hljs-built_in'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-literal'] = {...darkStyle['hljs-literal'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-type'] = {...darkStyle['hljs-type'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-params'] = {...darkStyle['hljs-params'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-title'] = {...darkStyle['hljs-title'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-string'] = {...darkStyle['hljs-string'], 'color': '#93bb76'};
        darkStyle['hljs-symbol'] = {...darkStyle['hljs-symbol'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-attr'] = {...darkStyle['hljs-attr'], 'color': '#5EA8E4FF'}
        darkStyle['hljs-number'] = {...darkStyle['hljs-number'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-selector-attr'] = {...darkStyle['hljs-selector-attr'], 'color': '#5EA8E4FF'};
        darkStyle['hljs-selector-pseudo'] = {...darkStyle['hljs-selector-pseudo'], 'color': '#5EA8E4FF'};

        return darkStyle;
    } else if (theme === 'light') {
        lightStyle['hljs'] = {...lightStyle['hljs'], 'backgroundColor': 'transparent', 'background': 'transparent', color: '#141414'};
        lightStyle['hljs-keyword'] = {...lightStyle['hljs-keyword'], 'color': '#6366f1'};
        lightStyle['hljs-comment'] = {...lightStyle['hljs-comment'], 'color': '#838383'};
        lightStyle['hljs-tag'] = {...lightStyle['hljs-tag'], 'color': '#113db7'};
        lightStyle['hljs-name'] = {...lightStyle['hljs-name'], 'color': '#113db7'};
        lightStyle['hljs-regexp'] = {...lightStyle['hljs-regexp'], 'color': '#113db7'};
        lightStyle['hljs-link'] = {...lightStyle['hljs-link'], 'color': '#113db7'};
        lightStyle['hljs-built_in'] = {...lightStyle['hljs-built_in'], 'color': '#113db7'};
        lightStyle['hljs-literal'] = {...lightStyle['hljs-literal'], 'color': '#113db7'};
        lightStyle['hljs-type'] = {...lightStyle['hljs-type'], 'color': '#113db7'};
        lightStyle['hljs-params'] = {...lightStyle['hljs-params'], 'color': '#113db7'};
        lightStyle['hljs-title'] = {...lightStyle['hljs-title'], 'color': '#113db7'};
        lightStyle['hljs-string'] = {...lightStyle['hljs-string'], 'color': '#228327'};
        lightStyle['hljs-symbol'] = {...lightStyle['hljs-symbol'], 'color': '#113db7'};
        lightStyle['hljs-attr'] = {...lightStyle['hljs-attr'], 'color': '#113db7'}
        lightStyle['hljs-number'] = {...lightStyle['hljs-number'], 'color': '#113db7'};
        lightStyle['hljs-selector-attr'] = {...lightStyle['hljs-selector-attr'], 'color': '#113db7'};
        lightStyle['hljs-selector-pseudo'] = {...lightStyle['hljs-selector-pseudo'], 'color': '#113db7'};

        return lightStyle;
    }
};
