import React from "react";
import {TbHistoryToggle} from "react-icons/tb";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {MdOutlineLibraryAdd} from "react-icons/md";

import routes from "../../../../../../../Routes/routes";
import {setCurrentTgConversation} from "../../../../../../../api/features/superIntelligence/taskGlide/frontend/getCurrentTgConversationSlice";


const TaskGlideInputAdditionalButtons = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <button
                type="button"
                onClick={() => dispatch(setCurrentTgConversation(null))}
                className="lg:hidden dropdown-item"
            >
                <MdOutlineLibraryAdd className="ml-2 h-4 w-4 shrink-0" aria-hidden="true"/>
                <p className="pr-2" >Start New</p>
            </button>
            <button
                type="button"
                onClick={() => navigate(`${routes.auth.taskGlide.home.href}?conversation-history=open`)}
                className="lg:hidden dropdown-item"
            >
                <TbHistoryToggle className="ml-2 h-4 w-4 shrink-0" aria-hidden="true"/>
                <p className="pr-2" >Conversations</p>
            </button>
        </>
    )
};

export default TaskGlideInputAdditionalButtons;
