import React from "react";
import {Link, useNavigate} from "react-router-dom";

import routes from "../../../Routes/routes";
import {companyLogo} from "../../../Design/companyLogo";


const AccountVerificationResponse = () => {
    const navigate = useNavigate();
    const path = window.location.pathname;
    const pathArray = path.split("/");
    const response = pathArray[pathArray.length - 1];

    const messages = {
        successful: {
            title: 'Email verified',
            message: 'Congratulations. You are almost there. You have successfully verified your account. You can now login.',
            action: 'Sign in',
            onClick: () => navigate(routes.unAuth.login.href)
        },
        invalid: {
            title: 'Failed to verify email',
            message: 'Your verification link is invalid. Please request a new one.',
            action: 'Retry verification',
            onClick: () => navigate(routes.unAuth.accountVerification.href)
        },
        expired: {
            title: 'Verification expired',
            message: 'Your account verification link has expired. You can request a new one now.',
            action: 'Retry verification',
            onClick: () => navigate(routes.unAuth.accountVerification.href)
        }
    };

    const { title, message, action, onClick } = messages[response] || messages.invalid;

    return (
        <div className="flex min-h-screen flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 bg-base-100">
            <div className="mx-auto w-full max-w-md">
                <Link to={routes.unAuth.home.href} className="outline:none focus:outline-none">
                    <span className="sr-only">Dovise</span>
                    <img
                        className="mx-auto h-10 w-auto transition-transform hover:scale-110 duration-500"
                        src={companyLogo}
                        alt="Your Company"
                    />
                </Link>
                <div className="text-center space-y-3">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-base-content">
                        {title}
                    </h2>
                    <p className="text-base text-base-content/70 max-w-lg">
                        {message}
                    </p>
                </div>
            </div>
            <button
                type="button"
                onClick={onClick}
                className="primary-button mt-10 mx-auto"
            >
                {action}
            </button>
        </div>
    )
};

export default AccountVerificationResponse;

