import React from "react";

import LandingHero from "./LandingHero/LandingHero";
import LogoClouds from "./LogoClouds";
import Feature2 from "./Features/Feature2/Feature2";
import Feature1 from "./Features/Feature1/Feature1";


const Landing = () => {

    return (
        <main className="isolate grid grid-cols-1 grid-flow-row gap-y-32" >
            <LandingHero />
            <LogoClouds />
            <Feature1 />
            <Feature2 />
        </main>
    );
};

Landing.propTypes = {

};

export default Landing;
