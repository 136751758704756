import React from "react";
import {FiChevronsUp} from "react-icons/fi";
import {LiaSpinnerSolid} from "react-icons/lia";
import PropTypes from "prop-types";


const MessageLoader = ({ onLoad, isLoading, disabled }) => {

    return (
        <div className="w-full flex items-center cursor-pointer z-50">
            <button
                type="button"
                className="relative w-fit mx-auto z-50 group/load-more"
                onClick={onLoad}
                disabled={isLoading || disabled}
            >
                {!isLoading ?
                    <FiChevronsUp className="size-4 text-base-content shrink-0"/> :
                    <LiaSpinnerSolid className="size-4 text-base-content/70 shrink-0 animate-spin"/>}
                {!isLoading && <div className="right-8 left-tooltips lg:group-hover/load-more:flex">Load more</div>}
            </button>
        </div>
    )
};

MessageLoader.propTypes = {
    onLoad: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default MessageLoader;
