import React, {useEffect, useState} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import clsx from "clsx";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";

import {findMonthIndexByDate, formatDateToISO, groupDatesByMonth} from "../../reusableFxns/dateTimeFxns";


const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
};

const DoubleCalendar = (
    { calendarDates, handleDateClick, singleDateIsLoading, selectedDate, handleTodayClick }
) => {
    const windowWidth = useWindowWidth();
    const isSingleView = windowWidth < 768;

    const groupedDates = groupDatesByMonth(calendarDates);
    const selectedDateMonthIndex = findMonthIndexByDate(groupedDates, selectedDate);

    const initialStartIndex = selectedDateMonthIndex >= 0 ? Math.floor(selectedDateMonthIndex / 2) * 2 : 0;
    const [startIndex, setStartIndex] = useState(initialStartIndex);

    useEffect(() => {
        if (selectedDate === formatDateToISO(new Date())) {
            const newStartIndex = selectedDateMonthIndex >= 0 ? Math.floor(selectedDateMonthIndex / 2) * 2 : 0;
            setStartIndex(newStartIndex);
        }
    }, [selectedDate, selectedDateMonthIndex]);

    const handlePrevious = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - (isSingleView ? 1 : 2));
        }
    };

    const handleNext = () => {
        if (startIndex < groupedDates.length - (isSingleView ? 1 : 2)) {
            setStartIndex(startIndex + (isSingleView ? 1 : 2));
        }
    };

    const handleToday = () => {
        handleTodayClick();
    };

    const displayedMonths = groupedDates.slice(startIndex, startIndex + (isSingleView ? 1 : 2));

    return (
        <div className="relative grid grid-cols-1 gap-x-20 md:grid-cols-2 space-y-4">
            <div className="flex items-center justify-between col-span-full">
                <button
                    type="button"
                    className="text-base-content/70 hover:text-base-content button-ring-focus"
                    onClick={handlePrevious}
                    disabled={startIndex === 0}
                >
                    <span className="sr-only">Previous month</span>
                    <ChevronLeftIcon className="size-5" aria-hidden="true"/>
                </button>
                <button
                    type="button"
                    className="standard-button"
                    onClick={handleToday}
                >
                    <span className="sr-only">Today</span>
                    Today
                </button>
                <button
                    type="button"
                    className="text-base-content/70 hover:text-base-content button-ring-focus"
                    onClick={handleNext}
                    disabled={startIndex >= groupedDates.length - (isSingleView ? 1 : 2)}
                >
                    <span className="sr-only">Next month</span>
                    <ChevronRightIcon className="size-5" aria-hidden="true"/>
                </button>
            </div>
            {displayedMonths?.map((month, monthIdx) => (
                <section
                    key={monthIdx}
                    className={clsx(
                        monthIdx === displayedMonths.length - 1 && 'grid-cols-1 md:grid-cols-2', 'text-center'
                    )}
                >
                    <h2 className="text-sm font-medium text-base-content">{month.name}</h2>
                    <div className="mt-4 grid grid-cols-7 text-xs leading-6 text-base-content/70">
                        <div>S</div>
                        <div>M</div>
                        <div>T</div>
                        <div>W</div>
                        <div>T</div>
                        <div>F</div>
                        <div>S</div>
                    </div>
                    <div className="isolate mt-2 grid grid-cols-7 divide-y divide-x divide-neutral rounded-lg overflow-hidden text-sm shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-1 ring-neutral-focus/70 dark:ring-neutral-focus-dark/70">
                        {month.days?.map((day, dayIdx) => (
                            <button
                                key={day.date || `${month.name}-${dayIdx}`}
                                type="button"
                                className={clsx(
                                    day.isCurrentMonth ?
                                        (day?.date === selectedDate ?
                                            'bg-base-300/20 hover:bg-base-300/20 text-base-content' :
                                            'bg-base-100 hover:bg-base-300/10 text-base-content') :
                                        'bg-base-200 hover:bg-base-200 text-base-content/70 opacity-75',
                                    'relative py-1.5 focus:z-10 button-ring-focus',
                                )}
                                onClick={() => handleDateClick(day.date)}
                            >
                                <time
                                    dateTime={day.date}
                                    className={clsx(
                                        day.isToday && 'font-semibold',
                                        day?.date === selectedDate && day.isToday && "bg-primary-focus dark:bg-primary-focus-dark font-semibold text-primary-content",
                                        day?.date !== selectedDate && day.isToday && "text-primary-focus dark:text-primary-focus-dark",
                                        'mx-auto size-6 flex items-center justify-center rounded-full',
                                    )}
                                >
                                    {(selectedDate === day.date && singleDateIsLoading && day.isCurrentMonth) ?
                                        <CgSpinner className="size-4 animate-spin shrink-0 mx-auto" /> :
                                        day.date?.split('-').pop().replace(/^0/, '')}
                                </time>
                            </button>
                        ))}
                    </div>
                </section>
            ))}
        </div>
    );
};

DoubleCalendar.propTypes = {
    calendarDates: PropTypes.array,
    handleDateClick: PropTypes.func,
    singleDateIsLoading: PropTypes.bool,
    selectedDate: PropTypes.string,
    handleTodayClick: PropTypes.func,
};

export default DoubleCalendar;
