import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearUserSubscriptionRetrievalVF, userSubscriptionGET} from "../../../api/features/payments/getUserSubscriptionSlice";
import {useAuthentication} from "../users/useAuthentication";


export const useGetUserSubscription = (
    { vfExists=false }
) => {
    const dispatch = useDispatch();
    const {userIsAuthenticated} = useAuthentication();

    // Payment methods
    const {
        userSubscriptionRetrievalVF,
        userSubscriptionRetrievalApiState,
        userSubscriptionRetrievalResponse,
        userSubscriptionRetrievalError,
    } = useSelector(state => state.getUserSubscriptionReducer);

    // API states with visual feedback
    const userSubscriptionRetrievalSuccessVF = userSubscriptionRetrievalApiState === 'succeeded' && userSubscriptionRetrievalVF;
    const userSubscriptionRetrievalFailureVF = userSubscriptionRetrievalApiState === 'failed' && userSubscriptionRetrievalVF;

    const getUserSubscription = (product_name) => userIsAuthenticated && dispatch(userSubscriptionGET(product_name));

    useEffect(() => {
        if (vfExists) {
            if (userSubscriptionRetrievalSuccessVF || userSubscriptionRetrievalFailureVF) {
                const timer = setTimeout(() => {
                    dispatch(clearUserSubscriptionRetrievalVF());
                }, 500);
                return () => clearTimeout(timer);
            }
        }
    }, [userSubscriptionRetrievalApiState]);

    return {
        getUserSubscription,
        userSubscriptionRetrievalVF,
        userSubscriptionRetrievalApiState,
        userSubscriptionRetrievalResponse,
        userSubscriptionRetrievalError,
        userSubscriptionRetrievalSuccessVF,
        userSubscriptionRetrievalFailureVF
    };
};
