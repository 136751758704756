import React from "react";


const SubscriptionSkeleton = () => {

    return (
        <ul className="overflow-y-auto scrollbar h-[calc(100dvh-222px)] sm:h-[calc(100dvh-178px)] bg-base-100 cursor-default">
            <li className="gap-x-6 py-5">
                <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="min-w-0 space-y-2 w-full">
                        <div className="flex items-center space-x-3">
                            <div className="h-4 w-24 bg-base-300/30 skeleton rounded"/>
                            <div className="h-4 w-24 bg-base-300/30 skeleton rounded"/>
                            <div className='rounded h-4 w-32 bg-base-300/30 skeleton'/>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-base-content/70">
                            <div className="hidden md:flex h-3.5 w-32 bg-base-300/30 skeleton rounded"/>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current hidden md:flex text-base-300/70">
                                <circle cx={1} cy={1} r={1}/>
                            </svg>
                            <div className="h-3.5 w-1/3 bg-base-300/30 skeleton rounded"/>
                        </div>
                    </div>
                    <div className="ml-4 rounded-md h-5 w-5 bg-base-300/30 skeleton"/>
                </div>
            </li>
            <li className="gap-x-6 py-5" >
                <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="min-w-0 space-y-2 w-full">
                        <div className="flex items-center space-x-3">
                            <div className="h-3.5 w-20 bg-base-300/30 skeleton rounded"/>
                            <div className='rounded h-4 w-20 bg-base-300/30 skeleton'/>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-base-content/70">
                            <div className="hidden md:flex h-3.5 w-28 bg-base-300/30 skeleton rounded"/>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current hidden md:flex text-base-300/70">
                                <circle cx={1} cy={1} r={1}/>
                            </svg>
                            <div className="h-3.5 w-2/3 bg-base-300/30 skeleton rounded"/>
                        </div>
                    </div>
                    <div className="ml-4 rounded-md h-5 w-5 bg-base-300/30 skeleton"/>
                </div>
            </li>
            <li className="gap-x-6 py-5">
                <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="min-w-0 space-y-2 w-full">
                        <div className="flex items-center space-x-3">
                            <div className="h-3.5 w-24 bg-base-300/30 skeleton rounded"/>
                            <div className='rounded h-4 w-20 bg-base-300/30 skeleton'/>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-base-content/70">
                            <div className="hidden md:flex h-3.5 w-32 bg-base-300/30 skeleton rounded"/>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current hidden md:flex text-base-300/70">
                                <circle cx={1} cy={1} r={1}/>
                            </svg>
                            <div className="h-3.5 w-1/2 bg-base-300/30 skeleton rounded"/>
                        </div>
                    </div>
                    <div className="ml-4 rounded-md h-5 w-5 bg-base-300/30 skeleton"/>
                </div>
            </li>
        </ul>
    )
};

export default SubscriptionSkeleton;
