import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {taskGlideSettingsPUT,clearTaskGlideSettingsUpdateVF, resetTaskGlideSettingsUpdate} from "../../../../../api/features/superIntelligence/taskGlide/taskGlide/updateTaskGlideSettingsSlice";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {useGetTaskGlideSettings} from "./useGetTaskGlideSettings";
import {useAuthentication} from "../../../users/useAuthentication";


export const useUpdateTaskGlideSettings = (
    {vfExists=false, refreshAndReset=false} = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // User Info
    const { getTaskGlideSettings } = useGetTaskGlideSettings();
    const {
        taskGlideSettingsUpdateVF,
        taskGlideSettingsUpdateApiState,
        taskGlideSettingsUpdateResponse,
        taskGlideSettingsUpdateError,
    } = useSelector(state => state.updateTaskGlideSettingsReducer);

    // API State with visual feedback
    const taskGlideSettingsUpdateSuccessVF = taskGlideSettingsUpdateApiState === 'succeeded' && taskGlideSettingsUpdateVF;
    const taskGlideSettingsUpdateFailureVF = taskGlideSettingsUpdateApiState === 'failed' && taskGlideSettingsUpdateVF;

    // Function to be called on form submission
    const updateTaskGlideSettings = (values) => {
        userIsAuthenticated && dispatch(taskGlideSettingsPUT(values));
    };

    useEffect(() => {
        if (vfExists) {
            if (taskGlideSettingsUpdateSuccessVF) {
                dispatch(clearTaskGlideSettingsUpdateVF());
            } else if (taskGlideSettingsUpdateFailureVF) {
                dispatch(setAlert({
                    message: 'Please try again.',
                    type: 'error'
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearTaskGlideSettingsUpdateVF());
                }, 1500);

                return () => clearTimeout(timer);
            }
        }
    }, [taskGlideSettingsUpdateApiState]);

    useEffect(() => {
        if (refreshAndReset && taskGlideSettingsUpdateApiState === 'succeeded') {
            if (!taskGlideSettingsUpdateVF) {
                getTaskGlideSettings();

                dispatch(resetTaskGlideSettingsUpdate());
            }
        }
    }, [taskGlideSettingsUpdateApiState, taskGlideSettingsUpdateVF]);

    return {
        updateTaskGlideSettings,
        taskGlideSettingsUpdateVF,
        taskGlideSettingsUpdateSuccessVF,
        taskGlideSettingsUpdateFailureVF,
        taskGlideSettingsUpdateError,
        taskGlideSettingsUpdateResponse,
        taskGlideSettingsUpdateApiState
    };
};
