import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const paymentMethodsLIST = createAsyncThunk(
    'payments/paymentMethod/list',
    async (params, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_LIST_STRIPE_PAYMENT_METHODS_PATH}${params}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    paymentMethodsListingVF: false,
    paymentMethodsListingApiState: 'idle',
    paymentMethodsListingResponse: null,
    paymentMethodsListingError: null,
};

export const listPaymentMethodsSlice = createSlice({
    name: 'paymentMethodsList',
    initialState: INITIAL_STATE,
    reducers: {
        clearPaymentMethodsListingVF: (state) => {
            state.paymentMethodsListingVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(paymentMethodsLIST.pending, (state) => {
                state.paymentMethodsListingApiState = 'loading';
                state.paymentMethodsListingVF = true;
            })
            .addCase(paymentMethodsLIST.fulfilled, (state, action) => {
                state.paymentMethodsListingApiState = 'succeeded';
                state.paymentMethodsListingResponse = action.payload;
                state.paymentMethodsListingVF = true;
                state.paymentMethodsListingError = null;
            })
            .addCase(paymentMethodsLIST.rejected, (state, action) => {
                state.paymentMethodsListingApiState = 'failed';
                state.paymentMethodsListingError = action?.payload;
                state.paymentMethodsListingVF = true;
                state.paymentMethodsListingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearPaymentMethodsListingVF
} = listPaymentMethodsSlice.actions;

export default listPaymentMethodsSlice.reducer;
