import React, {useEffect, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import {FiExternalLink} from "react-icons/fi";
import {MdAutoAwesome} from "react-icons/md";


const FollowUpExpandedModal = ({ index, text, onSubmit, isLoading }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalId = `followUp${index}`;

    return (
        <>
            <button
                type="button"
                onClick={() => {
                    const dialogElement = document.getElementById(modalId);
                    dialogElement.showModal();
                    setIsModalOpen(true);
                }}
                className="px-2 py-2.5 cursor-default group/follow-up relative button-ring-focus"
            >
                <FiExternalLink className="size-3.5 shrink-0"/>
                <div className="left-8 right-tooltips ml-1.5 group-hover/follow-up:sm:block" >Show</div>
            </button>
            <dialog
                id={modalId}
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={() => setIsModalOpen(false)}
            >
                <form method="dialog" className="modal-wrapper">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <MdAutoAwesome className="h-6 w-6 text-primary" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mx-4 sm:mt-2 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-base-content">
                                Suggested follow up
                            </h3>
                            <div className="mt-2 text-sm">
                                <p className="inline text-base-content/70" >
                                    {text}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-end gap-x-3">
                        <button
                            className="standard-button"
                            onClick={() => {
                                const dialogElement = document.getElementById(modalId);
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="primary-button"
                            disabled={isLoading}
                            onClick={onSubmit}
                        >
                            <p>Send</p>
                        </button>
                    </div>
                </form>
                <form method="dialog" className="modal-backdrop">
                    <button
                        tabIndex={-1}
                        className="cursor-default"
                        onClick={() => {
                            const dialogElement = document.getElementById(modalId);
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >
                        close
                    </button>
                </form>
            </dialog>
        </>
    )
};

FollowUpExpandedModal.propTypes = {
    index: PropTypes.number,
    text: PropTypes.string,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool
}

export default FollowUpExpandedModal;
