import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CardNumberElement} from "@stripe/react-stripe-js";


export const stripeCheckoutPOST = createAsyncThunk(
    'stripe/stripeCheckout/post',
    async ({stripe, elements, address, card_name}, thunkAPI) => {
        if (!stripe || !elements) {
            return thunkAPI.rejectWithValue("Stripe or elements not available.");
        }

        const cardNumberElement = elements.getElement(CardNumberElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: {
                address: {
                    line1: address?.line1,
                    line2: address?.line2 || null,
                    city: address?.city,
                    state: address?.state,
                    country: address?.country,
                    postal_code: address?.postal_code,
                },
                name: card_name,
            },
        });

        if (error) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return paymentMethod.id;
        }
    }
);


const getInitialState = () => ({
    stripeCheckoutVF: false,
    stripeCheckoutFxnState: 'idle',
    stripeCheckoutResponse: null,
    stripeCheckoutError: null,
});

export const getStripeCheckoutSlice = createSlice({
    name: 'stripe',
    initialState: getInitialState(),
    reducers: {
        clearStripeCheckoutVF: (state) => {
            state.stripeCheckoutVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(stripeCheckoutPOST.pending, (state) => {
                state.stripeCheckoutFxnState = 'loading';
                state.stripeCheckoutVF = true;
            })
            .addCase(stripeCheckoutPOST.fulfilled, (state, action) => {
                state.stripeCheckoutFxnState = 'succeeded';
                state.stripeCheckoutResponse = action.payload;
                state.stripeCheckoutVF = true;
                state.stripeCheckoutError = null;
            })
            .addCase(stripeCheckoutPOST.rejected, (state, action) => {
                state.stripeCheckoutFxnState = 'failed';
                state.stripeCheckoutError = action.payload;
                state.stripeCheckoutVF = true;
                state.stripeCheckoutResponse = null;
            });
    }
});

export const {
    clearStripeCheckoutVF
} = getStripeCheckoutSlice.actions;

export default getStripeCheckoutSlice.reducer;