import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {clearPasswordForgotVF, forgotPasswordPOST, resetPasswordForgot} from "../../../api/features/users/forgotPasswordSlice";


export const useForgotPassword = (
    {vfExists=false} = {}
) => {
    const dispatch = useDispatch();

    const {
        passwordForgotVF,
        passwordForgotApiState,
        passwordForgotResponse,
        passwordForgotError,
    } = useSelector(state => state.forgotPasswordReducer);

    // API State with visual feedback
    const passwordForgotSuccessVF = passwordForgotApiState === 'succeeded' && passwordForgotVF;
    const passwordForgotFailureVF = passwordForgotApiState === 'failed' && passwordForgotVF;
    const passwordForgotLoadingVF = passwordForgotApiState === 'loading' && passwordForgotVF;

    // Function to be called on form submission
    const forgotPassword = (values) => dispatch(forgotPasswordPOST(values));

    useEffect(() => {
        if (vfExists) {
            if (passwordForgotSuccessVF) {
                dispatch(setAlert({
                    message: passwordForgotResponse?.message,
                    type: "success"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearPasswordForgotVF());
                }, 4000);

                return () => clearTimeout(timer);
            }  else if (passwordForgotFailureVF) {
                dispatch(setAlert({
                    message: passwordForgotError?.message || "Something went wrong. Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetPasswordForgot());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [passwordForgotApiState]);

    return {
        forgotPassword,
        passwordForgotVF,
        passwordForgotApiState,
        passwordForgotResponse,
        passwordForgotError,
        passwordForgotSuccessVF,
        passwordForgotFailureVF,
        passwordForgotLoadingVF
    };
};
