import React, {Fragment, useEffect, useRef, useState} from "react";
import {Menu, Transition} from "@headlessui/react";
import clsx from "clsx";

import PropTypes from "prop-types";


const ButtonDropDown = (
    { useFullScreen = false, buttonText, children, buttonClassName, dropdownClassName, disabled }
) => {
    const [isNearBottom, setIsNearBottom] = useState(false);
    const buttonRef = useRef(null);

    const checkPosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            const distanceFromBottom = useFullScreen ? window.innerHeight - rect.bottom : rect.bottom;
            const distanceToTop = useFullScreen ? rect.top : window.innerHeight - rect.top;
            setIsNearBottom(distanceFromBottom < 450 || distanceToTop < 450);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', checkPosition);

        checkPosition();

        return () => {
            window.removeEventListener('scroll', checkPosition);
            window.removeEventListener('resize', checkPosition);
        };
    }, [useFullScreen]);

    return (
        <Menu as="div" className="relative w-fit">
            <Menu.Button
                ref={buttonRef}
                data-tip="date-picker"
                disabled={disabled}
                type="button"
                className={clsx(
                    "relative flex items-center justify-center bg-base-100 dark:bg-base-200 text-base-content ring-[0.75px] ring-base-content/20 dark:ring-base-content/20 px-2 py-1 hover:drop-shadow-sm shadow-sm shadow-gray-400/50 dark:shadow-black/50 outline-none text-sm min-w-max button-ring-focus disabled:pointer-events-none w-fit truncate disabled:opacity-50 sm:disabled:cursor-not-allowed",
                    buttonClassName
                )} >
                <span className="sr-only">Open date picker</span>
                <p>{buttonText}</p>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out"
                enterFrom="transform opacity-0 -translate-y-2"
                enterTo="transform opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="transform opacity-100 translate-y-0"
                leaveTo="transform opacity-0 -translate-y-2"
            >
                <Menu.Items
                    className={clsx(
                        "absolute mt-2 rounded-md focus:outline-none text-sm z-20 bg-base-100 shadow-[0px_0px_15px_rgba(0,0,0,0.15)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.85)] ring-[0.5px] ring-neutral-focus/70 dark:ring-neutral-focus-dark/70",
                        isNearBottom && "bottom-9",
                        dropdownClassName
                    )}
                >

                    {children}
                </Menu.Items>
            </Transition>
        </Menu>
    )
};

ButtonDropDown.propTypes = {
    useFullScreen: PropTypes.bool,
    buttonText: PropTypes.string,
    children: PropTypes.node,
    buttonClassName: PropTypes.string,
    dropdownClassName: PropTypes.string,
    disabled: PropTypes.bool,
}

export default ButtonDropDown;
