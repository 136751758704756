import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import clsx from "clsx";
import * as yup from "yup";
import {ImSpinner3} from "react-icons/im";

import routes from "../../../Routes/routes";
import {companyLogo} from "../../../Design/companyLogo";
import Alerts from "../../../Components/Alerts/Alerts";
import {useForgotPassword} from "../../../Hooks/apiHooks/users/useForgotPassword";
import {useAlert} from "../../../Hooks/frontendHooks/useAlert";


const initialValues = {
    email: "",
}

const validationSchema = yup.object().shape({
    email: yup.string()
        .required('Email is required')
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Email is not valid'),
});


const ForgotPassword = () => {
    const dispatch = useDispatch();
    const {alert, alertKey} = useAlert();

    const {
        forgotPassword,
        passwordForgotSuccessVF,
        passwordForgotFailureVF,
        passwordForgotLoadingVF,
    } = useForgotPassword({ vfExists: true })

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        forgotPassword(values)
        setSubmitting(false);
        resetForm();
    };

    return (
        <>
            <div className="flex min-h-screen flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 bg-base-100 space-y-10">
                <div className="mx-auto w-full max-w-sm space-y-6">
                    <Link to={routes.unAuth.home.href} className="outline:none focus:outline-none">
                        <span className="sr-only">Dovise</span>
                        <img
                            className="mx-auto h-10 w-auto transition-transform hover:scale-110 duration-500"
                            src={companyLogo}
                            alt="Your Company"
                        />
                    </Link>
                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-base-content">
                        Reset your password
                    </h2>
                </div>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {({isSubmitting, isValid, values}) => (
                        <Form className="mx-auto w-full max-w-sm space-y-6">
                            <div className="space-y-2">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-base-content">
                                    Email
                                </label>
                                <Field
                                    type="text"
                                    name="email"
                                    autoComplete="email"
                                    required
                                    className="input-field"
                                />
                            </div>
                            <button
                                type="submit"
                                disabled={!isValid || passwordForgotLoadingVF || isSubmitting || values.email === ""}
                                className="primary-button w-full"
                            >
                                <ImSpinner3
                                    className={clsx(
                                        "absolute inset-0 m-auto animate-spin h-5 w-5",
                                        (passwordForgotLoadingVF || isSubmitting) ? "" : "hidden"
                                    )}
                                    aria-hidden="true"
                                />
                                <p className={(passwordForgotLoadingVF || isSubmitting) ? "invisible" : ""}>
                                    Send reset link
                                </p>
                            </button>
                        </Form>
                    )}
                </Formik>

                <p className="mx-auto w-full max-w-sm text-center text-sm leading-6 space-x-1">
                    <p className="inline text-base-content/70">Remember your password?</p>
                    <Link
                        to={routes.unAuth.login.href}
                        onClick={() => dispatch({type: "RESET_USER_MANAGEMENT"})}
                        className="inline font-medium text-primary hover:text-primary-focus outline:none focus:outline-none">
                        Sign in
                    </Link>
                </p>
            </div>
            {(passwordForgotSuccessVF || passwordForgotFailureVF) &&
                <Alerts message={alert.message} type={alert.type} keyValue={alertKey}/>}
        </>
    )
};

export default ForgotPassword;
