import React, {forwardRef, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";
import {IoTrash} from "react-icons/io5";
import clsx from "clsx";

import {useDeleteSingleTgConversation} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteSingleTgConversation";


const SingleConvoDeletionModal = forwardRef(({ conversationItem, onDelete, disabledState, description }, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { tgSingleConversationDeleteVF, tgSingleConversationDeleteApiState } = useDeleteSingleTgConversation();

    const convoId = `singleConvoDeletion-${conversationItem?.conversation_uuid}`

    useEffect(() => {
        const dialogElement = document.getElementById(convoId);

        if ((tgSingleConversationDeleteApiState === 'succeeded' || tgSingleConversationDeleteApiState === 'failed') && !tgSingleConversationDeleteVF) {
            dialogElement?.close();
            setIsModalOpen(false);
        }
    }, [tgSingleConversationDeleteApiState, tgSingleConversationDeleteVF]);


    return (
        <>
            <button
                ref={ref}
                disabled={disabledState}
                className="dropdown-item"
                onClick={(e) => {
                    if (!disabledState) {
                        e.preventDefault();
                        const dialogElement = document.getElementById(convoId);
                        dialogElement.showModal();
                        setIsModalOpen(true);
                    }}}
            >
                <p>Delete</p>
                {disabledState && <CgSpinner className="animate-spin h-3 w-3 inline-block"/>}
            </button>
            <dialog
                id={convoId}
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={() => setIsModalOpen(false)}
            >
                <form
                    method="dialog"
                    className="modal-wrapper space-y-4"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <div className="sm:flex sm:items-start" >
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-error-content sm:mx-0 sm:h-10 sm:w-10">
                            <IoTrash className="h-6 w-6 text-error" aria-hidden="true"/>
                        </div>
                        <div className="mt-3 text-center sm:mx-4 sm:mt-2 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-base-content">
                                Delete All Conversations
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-base-content/70">
                                Are you sure you want to delete the conversation titling &quot;<span className="capitalize font-semibold text-base-content">{description}</span>&quot;? This action cannot be undone.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 flex items-center justify-end gap-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                const dialogElement = document.getElementById(convoId);
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                            className="empty-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            disabled={disabledState}
                            className="error-button"
                            onClick={onDelete}
                        >
                            <CgSpinner
                                className={clsx(
                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                    tgSingleConversationDeleteVF ? "" : "hidden"
                                )}
                                aria-hidden="true"
                            />
                            <p className={tgSingleConversationDeleteVF ? "invisible" : ""}>
                                Delete
                            </p>
                        </button>
                    </div>
                </form>
                <form method="dialog" className="modal-backdrop">
                    <button
                        className="cursor-default"
                        onClick={() => {
                            const dialogElement = document.getElementById(convoId);
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >close
                    </button>
                </form>
            </dialog>
        </>
    );
});

SingleConvoDeletionModal.displayName = 'SingleConvoDeletionModal';

SingleConvoDeletionModal.propTypes = {
    conversationItem: PropTypes.object,
    onDelete: PropTypes.func,
    disabledState: PropTypes.bool,
    description: PropTypes.string,
}

export default SingleConvoDeletionModal;
