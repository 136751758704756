import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    conversation: null,
    testConversation: null,
};

export const taskGlideUiActionsSlice = createSlice({
    name: 'taskGlideUiActions',
    initialState: initialState,
    reducers: {
        setConversation: (state, action) => {
            state.conversation = action.payload;
        },
        setTestConversation: (state, action) => {
            state.testConversation = action.payload;
        },
    },
});

export const {
    setConversation,
    setTestConversation,
} = taskGlideUiActionsSlice.actions;

export default taskGlideUiActionsSlice.reducer;
