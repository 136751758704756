import routes from "./routes";

export const userManagementRoutes = [
    routes.unAuth.login.href,
    routes.unAuth.register.href,
    routes.unAuth.resetPassword.href,
    routes.unAuth.forgotPassword.href,
    routes.unAuth.accountVerification.href,
    routes.unAuth.successfulAccountVerification.href,
    routes.unAuth.invalidAccountVerification.href,
    routes.unAuth.expiredAccountVerification.href,
];

export const registrationRoutes = [
    routes.auth.registration.find(form => form.name === "Personal Information").href,
    routes.auth.registration.find(form => form.name === "Preferences").href,
    routes.auth.registration.find(form => form.name === "Subscription").href,
];