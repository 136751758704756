import React from "react";
import PropTypes from "prop-types";

import config from "../../../../../config";


const SubscriptionPlanList = (
    {selectedPlans, setSelectedPlans, userSubscriptions}
) => {

    return (
        <div className="col-span-full pb-6">
            {userSubscriptions?.map((plan) => {
                return (
                    <div key={plan?.product_id} className="relative col-span-full flex w-full gap-x-4">
                        <div className="flex h-6 items-center">
                            <input
                                name={plan?.price_id}
                                type="checkbox"
                                disabled={plan?.price_id === config.taskGlidePriceId}
                                checked={selectedPlans.includes(plan?.price_id)}
                                onChange={() => {
                                    if (selectedPlans.includes(plan?.price_id)) {
                                        setSelectedPlans(selectedPlans.filter(id => id !== plan?.price_id));
                                    } else {
                                        setSelectedPlans([...selectedPlans, plan?.price_id]);
                                    }
                                }}
                                className="standard-checkbox"
                            />
                        </div>
                        <div className="text-sm leading-6">
                            <div className="flex items-center space-x-2">
                                <label htmlFor={plan?.price_id} className="font-medium text-base-content">
                                    {plan?.product_name}
                                </label>
                                <p>.</p>
                                <div className="flex items-center space-x-1 font-medium">
                                    <p>{plan?.amount}</p>
                                    <p className="uppercase">{plan?.currency}</p>
                                    <p className="font-light text-xs sm:text-2xs">/ {plan?.interval}</p>
                                </div>
                            </div>
                            <p className="text-base-content/70">{plan?.description}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
};

SubscriptionPlanList.propTypes = {
    selectedPlans: PropTypes.array,
    setSelectedPlans: PropTypes.func,
    userSubscriptions: PropTypes.array,
};

export default SubscriptionPlanList;
