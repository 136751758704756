import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';

import {ScrollToTop} from "./ScrollToTop";
import {routeComponents} from "../Routes/routeComponents";
import {useAlert} from "../Hooks/frontendHooks/useAlert";
import ThemeController from "../Design/ThemeController";


const App = () => {
    const index = routeComponents.findIndex(({path}) => path === '/');
    useAlert({ shouldReset: true });

    return (
        <>
            <ThemeController/>
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    {routeComponents.map(({path, component: Component, page}) => (
                        <Route exact path={path} element={<Component page={page} />} key={index} />
                    ))}
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;
