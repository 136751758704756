import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import TypewriterComponent from "typewriter-effect";
import {Link} from "react-router-dom";
import {RiArrowRightLine, RiArrowRightSLine} from "react-icons/ri";
import {useDispatch} from "react-redux";

import routes from "../../../../Routes/routes";
import LandingPhoneFrameDesign from "./LandingPhoneFrameDesign";


const LandingHero = () => {
    const dispatch = useDispatch();

    return (
        <div className="relative mt-32" >
            <div className="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8" >
                <div className="mx-auto max-w-6xl lg:mx-0 space-y-4" >
                    <div className="group relative flex items-center gap-x-4 rounded-full px-4 py-0.5 text-xs leading-6 text-base-content/70 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 hover:sm:ring-primary/30 bg-base-200 hover:sm:bg-primary/10 whitespace-nowrap w-fit" >
                        <span className="font-semibold text-primary">TaskGlide</span>
                        <span className="h-4 w-px bg-neutral-focus dark:bg-neutral-focus-dark group-hover:bg-primary/30" aria-hidden="true" />
                        <Link to={routes.unAuth.products.find(product => product.name === 'TaskGlide').href} className="flex items-center gap-x-1">
                            <span className="absolute inset-0" aria-hidden="true" />
                            Your day to day assistant
                            <ChevronRightIcon className="-mr-2 h-5 w-5" aria-hidden="true" />
                        </Link>
                    </div>
                    <div className="flex flex-col pt-2" >
                        <h1 className="font-bold tracking-tight leading-[1.1] text-base-content text-[min(9vw,60px)]">
                            Intelligent Assistants Supercharged for
                        </h1>
                        <span className="max-w-lg font-bold tracking-tight leading-[1.1] text-base-content text-[min(9vw,60px)]">
                            <TypewriterComponent
                                options={{
                                    autoStart: true,
                                    loop: true,
                                    delay: 100,
                                    deleteSpeed: 25,
                                    strings: ["Programming", "Research", "Ecommerce", "Documentation", "Web Search", "Communication", "Education"],
                                }} />
                        </span>
                    </div>
                    <p className="text-lg leading-8 text-base-content/70">
                        Effortlessly tackle any task with our versatile and comprehensive suite of intelligent assistants for documentation, programming, education, research, and beyond.
                    </p>
                    <div className="flex items-center gap-x-6 pt-4">
                        <Link
                            to={routes.unAuth.register.href}
                            onClick={() => dispatch({type: "RESET_USER_MANAGEMENT"})}
                            className="rounded-primary-button group"
                        >
                            <p>Join now</p>
                            <RiArrowRightSLine className='flex group-hover:sm:hidden size-4 shrink-0' aria-hidden="true" />
                            <RiArrowRightLine className='group-hover:sm:flex hidden size-4 shrink-0' aria-hidden="true" />
                        </Link>
                        <Link
                            to={routes.unAuth.contact.href}
                            className="rounded-empty-button group"
                        >
                            <p>Contact us</p>
                            <RiArrowRightSLine className='flex group-hover:sm:hidden size-4 shrink-0' aria-hidden="true" />
                            <RiArrowRightLine className='group-hover:sm:flex hidden size-4 shrink-0' aria-hidden="true" />
                        </Link>
                    </div>
                </div>
                <div className="scale-95 w-full my-12 lg:my-0" >
                    <LandingPhoneFrameDesign />
                </div>
            </div>
        </div>
    )
};

export default LandingHero;
