import { createSlice } from '@reduxjs/toolkit';


const INITIAL_STATE = {
    alert: {
        message: '',
        description: '',
        type: ''
    },
    alertKey: 0,
}

export const alertSlice = createSlice({
    name: 'alert',
    initialState: INITIAL_STATE,
    reducers: {
        setAlert: (state, action) => {
            state.alert = action.payload;
        },
        incrementAlertKey: (state) => {
            state.alertKey += 1;
        },
        resetAlert: (state) => {
            state.alert = INITIAL_STATE.alert;
            state.alertKey = INITIAL_STATE.alertKey;
        }
    },
});

export const { setAlert, incrementAlertKey, resetAlert } = alertSlice.actions;

export default alertSlice.reducer;
