import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearStripeCustomerRetrievalVF, stripeCustomerGET} from "../../../api/features/payments/getStripeCustomerSlice";
import {useAuthentication} from "../users/useAuthentication";


export const useGetStripeCustomer = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();
    const {userIsAuthenticated} = useAuthentication();
    const {
        stripeCustomerRetrievalApiState,
        stripeCustomerRetrievalVF,
        stripeCustomerRetrievalResponse,
        stripeCustomerRetrievalError,
    } = useSelector(state => state.getStripeAccountReducer);

    const stripeAccountRetrievalSuccessVF = stripeCustomerRetrievalApiState === 'succeeded' && stripeCustomerRetrievalVF;
    const stripeAccountRetrievalFailureVF = stripeCustomerRetrievalApiState === 'failed' && stripeCustomerRetrievalVF;

    const getStripeCustomer = () => userIsAuthenticated && dispatch(stripeCustomerGET());

    useEffect(() => {
        if (vfExists) {
            if (stripeAccountRetrievalSuccessVF || stripeAccountRetrievalFailureVF) {
                const timer = setTimeout(() => {
                    dispatch(clearStripeCustomerRetrievalVF());
                }, 2000);

                return () => clearTimeout(timer);
            }
        }
    }, [stripeCustomerRetrievalApiState]);

    return {
        getStripeCustomer,
        stripeCustomerRetrievalApiState,
        stripeCustomerRetrievalVF,
        stripeCustomerRetrievalResponse,
        stripeCustomerRetrievalError
    };
};
