import React from "react";
import {useLocation} from "react-router-dom";


const GlideToolStackedItemSkeleton = () => {
    return (
        <li className="col-span-1 rounded-lg bg-base-100 ring-[0.75px] ring-neutral-focus dark:ring-neutral-focus-dark p-4 space-y-3">
            <div className="flex w-full items-center justify-between space-x-5">
                <div className="flex-1 truncate space-y-2">
                    <div className="h-4 w-20 bg-base-300/30 skeleton rounded"/>
                    <div className="h-3.5 w-full bg-base-300/30 skeleton rounded"/>
                    <div className="h-3.5 w-1/2 bg-base-300/30 skeleton rounded"/>
                </div>
                <div className="rounded-full overflow-hidden h-10 w-10 bg-base-300/30 skeleton" />
            </div>
            <div className="flex items-center">
                <div className="flex items-center justify-end w-0 flex-1">
                    <div className="flex items-center justify-center space-x-4">
                        <div className="h-4 w-16 bg-base-300/30 skeleton rounded"/>
                        <div className="h-8 w-16 bg-base-300/30 skeleton rounded-md"/>
                    </div>
                </div>
            </div>
        </li>
    )
};


const GlideCreatorSetupSkeleton = () => {
    const location = useLocation();
    const path = location.pathname;
    const isNewSetup = path.includes('new/setup');

    return (
        <div className="h-full overflow-y-auto scrollbar">
            <div className="grid grid-cols-1 grid-flow-row gap-8 w-full" >
                <div className="border-b border-neutral" >
                    <div className="grid grid-cols-1 gap-x-20 gap-y-10 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                        <div className="col-span-full md:col-span-4 space-y-3">
                            <div className="bg-base-300/30 skeleton rounded h-5 w-20"/>
                            <div className="space-y-2.5">
                                <div className="bg-base-300/30 skeleton rounded h-4 w-full"/>
                                <div className="bg-base-300/30 skeleton rounded h-4 w-2/3"/>
                            </div>
                        </div>
                        <div className="relative md:col-start-5 md:col-span-5 lg:col-start-6 grid grid-cols-1 gap-y-5">
                            <div className="relative col-span-full flex w-full gap-x-4">
                                <div className="bg-base-300/30 skeleton rounded w-4 h-4"/>
                                <div className="space-y-3 w-full">
                                    <div className="bg-base-300/30 skeleton rounded-md h-4 w-32"/>
                                    <div className="bg-base-300/30 skeleton rounded-md h-4 w-full"/>
                                </div>
                            </div>
                            <div className="group relative col-span-full space-y-3 py-1">
                                <div className="bg-base-300/30 skeleton rounded w-28 h-4"/>
                                <div className="grid grid-rows-3 grid-flow-col gap-y-3 justify-items-center p-1 rounded-md">
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                    <div className="block h-9 w-9 rounded-full border-0 bg-base-300/30 skeleton"/>
                                </div>
                            </div>
                            <div className="pt-4 group relative col-span-full grid grid-cols-7 items-center">
                                <div className="bg-base-300/30 skeleton rounded w-28 h-4 col-span-2 xl:col-span-3"/>
                                <div className="block w-full rounded-md border-0 h-8 bg-base-300/30 skeleton col-span-5 xl:col-span-4"/>
                            </div>
                            {!isNewSetup && <div className="pt-4 group relative col-span-full grid grid-cols-7 items-center">
                                <div className="bg-base-300/30 skeleton rounded w-28 h-4 col-span-2 xl:col-span-3"/>
                                <div className="block w-full rounded-md border-0 h-8 bg-base-300/30 skeleton col-span-5 xl:col-span-4"/>
                            </div>}
                            <div className="pt-4 group relative col-span-full grid grid-cols-7 items-start">
                                <div className="bg-base-300/30 skeleton rounded w-28 h-4 col-span-2 xl:col-span-3"/>
                                <div className="block w-full rounded-md border-0 bg-base-300/30 skeleton h-[120px] col-span-5 xl:col-span-4"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-b border-neutral">
                    <div className="col-span-full grid grid-cols-1 md:grid-cols-9 gap-y-3 py-12 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                        <div className="col-span-full md:col-span-4 space-y-3">
                            <div className="bg-base-300/30 skeleton rounded h-5 w-24"/>
                            <div className="space-y-2.5">
                                <div className="bg-base-300/30 skeleton rounded h-4 w-full"/>
                                <div className="bg-base-300/30 skeleton rounded h-4 w-1/2"/>
                            </div>
                        </div>
                        <div className="relative md:col-start-6 lg:col-start-7 md:col-span-4">
                            <div className="col-span-full row-span-3 md:col-span-1 px-1 py-2 overflow-y-auto scrollbar">
                                <div className="relative flex items-center h-8">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                        <div className="bg-base-100 rounded-full w-5 h-5 p-2"/>
                                    </div>
                                    <div className="block w-full rounded-md border-0 py-5 bg-base-300/30 skeleton"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-full h-full">
                            <ul className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 py-6">
                                <GlideToolStackedItemSkeleton/>
                                <GlideToolStackedItemSkeleton/>
                                <GlideToolStackedItemSkeleton/>
                                <GlideToolStackedItemSkeleton/>
                                <GlideToolStackedItemSkeleton/>
                                <div className="flex items-center justify-center">
                                    <div className="rounded-full h-8 px-2 flex items-center space-x-2 ring-[0.75px] ring-neutral-focus dark:ring-neutral-focus-dark">
                                        <div className="h-4 w-4 bg-base-300/30 skeleton rounded-full"/>
                                        <div className="h-3.5 w-16 bg-base-300/30 skeleton rounded"/>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-span-full row-span-1 w-full pb-8 pt-0 px-4 sm:px-6 lg:px-8 h-fit place-self-end mx-auto max-w-5xl">
                    <div className="flex items-center justify-end gap-x-4">
                        <div className="bg-base-300/30 skeleton h-8 w-16 rounded" />
                        <div className="rounded bg-base-300/30 skeleton w-20 h-8 py-2" />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GlideCreatorSetupSkeleton;
