import React from 'react';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {CgSpinner} from "react-icons/cg";

import {useEmailSubscription} from "../../../Hooks/apiHooks/users/useEmailSubscription";


const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
});

const ComingSoon = () => {
    const {
        subscribeToEmail,
        emailSubscriptionVF
    } = useEmailSubscription({ vfExists: true });

    return (
        <div className="relative bg-base-100 h-dvh isolate flex items-center justify-center">
            <div className="mx-auto grid min-h-full place-items-center px-6 py-20 sm:py-40 lg:px-8">
                <div className="text-center">
                    <div className="space-y-2">
                        <p className="text-base font-medium text-primary">Join the waitlist</p>
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-base-content sm:text-5xl max-w-2xl">Coming Soon</h1>
                    </div>
                    <p className="mt-6 text-base leading-7 text-base-content/70 max-w-xl">
                        Anticipate the extraordinary. Secure your spot on the waitlist and be among the first to experience our upcoming innovation.
                    </p>
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            subscribeToEmail(values);
                            resetForm();
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting, errors, touched}) => (
                            <Form className="group relative z-10 gap-x-4 max-w-md mx-auto mt-10 flex items-center justify-center" >
                                <label htmlFor="email-address" className="absolute -top-2 left-2 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-medium hidden leading-none z-10 group-focus-within:text-secondary" >
                                    Email address
                                </label>
                                <Field
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    placeholder="Email address"
                                    className="input-field"
                                />
                                <button
                                    type="submit"
                                    disabled={isSubmitting || emailSubscriptionVF || (errors.email && touched.email)}
                                    className="primary-button"
                                >
                                    {emailSubscriptionVF ? <CgSpinner className="animate-spin size-5 inline-block" /> : "Subscribe"}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
