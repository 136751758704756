import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const calendarEventGET = createAsyncThunk(
    'memoMinder/calendar/event/get',
    async ({ date }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_MEMOMINDER_GET_CALENDAR_EVENT}${date}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    calendarEventRetrievalVF: false,
    calendarEventRetrievalApiState: 'idle',
    calendarEventRetrievalResponse: null,
    calendarEventRetrievalError: null,
};

export const getCalendarEventSlice = createSlice({
    name: 'calendarEventRetrieval',
    initialState: INITIAL_STATE,
    reducers: {
        clearCalendarEventRetrievalVF: (state) => {
            state.calendarEventRetrievalVF = false;
        },
        resetCalendarEventRetrieval: (state) => {
            state.calendarEventRetrievalVF = false;
            state.calendarEventRetrievalApiState = 'idle';
            state.calendarEventRetrievalResponse = null;
            state.calendarEventRetrievalError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(calendarEventGET.pending, (state) => {
                state.calendarEventRetrievalApiState = 'loading';
                state.calendarEventRetrievalVF = true;
            })
            .addCase(calendarEventGET.fulfilled, (state, action) => {
                state.calendarEventRetrievalApiState = 'succeeded';
                state.calendarEventRetrievalResponse = action?.payload;
                state.calendarEventRetrievalVF = true;
                state.calendarEventRetrievalError = null;
            })
            .addCase(calendarEventGET.rejected, (state, action) => {
                state.calendarEventRetrievalApiState = 'failed';
                state.calendarEventRetrievalError = action?.payload;
                state.calendarEventRetrievalVF = true;
                state.calendarEventRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearCalendarEventRetrievalVF,
    resetCalendarEventRetrieval,
} = getCalendarEventSlice.actions;

export default getCalendarEventSlice.reducer;
