import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {clearGlidesListingVF, resetGlidesListing, glidesGET} from "../../../../../api/features/superIntelligence/taskGlide/glides/listUserGlidesSlice";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";


export const useListUserGlides = ({ vfExists=false } = {}) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // MyGlides
    const {
        glidesListingVF,
        glidesListingApiState,
        glidesListingResponse,
        glidesListingError,
    } = useSelector(state => state.listGlidesReducer);

    // API states with visual feedback
    const glidesListingSuccessVF = glidesListingApiState === 'succeeded' && glidesListingVF;
    const glidesListingFailureVF = glidesListingApiState === 'failed' && glidesListingVF;

    const getGlides = () => userIsAuthenticated && dispatch(glidesGET());

    useEffect(() => {
        if (vfExists) {
            if (glidesListingSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearGlidesListingVF());
                }, 500);

                return () => clearTimeout(timer);
            } else if (glidesListingFailureVF) {
                dispatch(setAlert({
                    message: glidesListingResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetGlidesListing());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [glidesListingApiState, glidesListingVF]);

    return {
        getGlides,
        glidesListingVF,
        glidesListingApiState,
        glidesListingResponse,
        glidesListingError,
        glidesListingSuccessVF,
        glidesListingFailureVF
    };
};
