import React, {useEffect, useRef, useState} from 'react'
import {Combobox} from '@headlessui/react'
import PropTypes from "prop-types";
import clsx from "clsx";
import {useField} from "formik";
import {HiOutlineChevronUpDown} from "react-icons/hi2";


const SingleSelect = ({ name, list, styles, wrapperClass, placeholder, onValueChange, ...props }) => {
    const [field, meta, helpers] = useField(name);
    const [query, setQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState(() => list?.find(item => item?.id === field.value?.id) || '');
    const comboboxRef = useRef(null);
    const [isNearBottom, setIsNearBottom] = useState(false);

    useEffect(() => {
        if (field.value && (!selectedItem || field.value?.id !== selectedItem?.id)) {
            setSelectedItem(list?.find(item => item?.id === field.value?.id) || '');
        }
    }, [field.value, list]);

    const handleChange = (item) => {
        setSelectedItem(item);
        helpers.setValue(item);
        if (onValueChange) {
            onValueChange(item);
        }
        setQuery('');
    };

    const filteredList = query === '' ? list : list.filter(item => item.name.toLowerCase().includes(query.toLowerCase()));

    const checkPosition = () => {
        if (comboboxRef.current) {
            const rect = comboboxRef.current.getBoundingClientRect();
            const distanceFromBottom = window.innerHeight - rect.bottom;
            setIsNearBottom(distanceFromBottom < 239);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', checkPosition);

        checkPosition();

        return () => {
            window.removeEventListener('scroll', checkPosition);
            window.removeEventListener('resize', checkPosition);
        };
    }, []);

    return (
        <div className={clsx("relative group", wrapperClass)}>
            <Combobox
                ref={comboboxRef}
                value={selectedItem}
                onChange={handleChange}
                as="div"
                {...props}
            >
                <Combobox.Input
                    className={clsx("input-field", styles)}
                    onChange={event => setQuery(event.target.value)}
                    displayValue={(value) => value ? value.name : ''}
                    placeholder={placeholder}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <HiOutlineChevronUpDown className="h-5 w-5 text-base-300" aria-hidden="true"/>
                </Combobox.Button>
                {filteredList?.length > 0 && (
                    <Combobox.Options className={clsx("select-dropdown", isNearBottom && "bottom-9")} >
                        {filteredList?.map((item) => (
                            <Combobox.Option
                                key={item.id}
                                value={item}
                                className="relative select-none cursor-pointer"
                            >
                                {({ selected }) => (
                                    <div className={clsx("flex items-center space-x-2 py-1 pl-3 pr-9", selected ? "bg-base-200" : "hover:bg-base-200")}>
                                        <span className='block truncate'>{item.name}</span>
                                        <div>{item?.addOnValue}</div>
                                    </div>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </Combobox>
        </div>
    );
};

SingleSelect.propTypes = {
    name: PropTypes.string,
    list: PropTypes.array,
    placeholder: PropTypes.string,
    styles: PropTypes.string,
    wrapperClass: PropTypes.string,
    onValueChange: PropTypes.func
};

export default SingleSelect;
