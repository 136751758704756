import React from "react";
import PropTypes from "prop-types";

import {cardLogos} from "../../LinkedCards/LinkedCards";
import ExistingSubscriptionForm from "./ExistingSubscription/ExistingSubscriptionForm";
import NewSubscriptionForm from "./NewSubscription/NewSubscriptionForm";


export const calculateDiscountAmount = (discountType, discountAmount, planPrice) => {
    if (discountType === "percent_off") {
        return planPrice * (discountAmount / 100);
    } else if (discountType === "amount_off") {
        return discountAmount;
    }
    return 0;
};


const SubscriptionManagement = (
    { subscriptionStatus, subscriptionPlanHook, paymentMethods, userSubscription, subscriptionPlan }
) => {
    const cardBrand = 'unknown';
    const isSubscribed = subscriptionStatus === 'Subscribed';

    const paymentList = paymentMethods?.payment_methods?.map(method => ({
        id: method?.id,
        name: `**** ${method?.last4}`,
        is_default: method?.is_default,
        addOnValue: (
            <div className="flex items-center space-x-2">
                <p className="w-fit text-base-content/70 lg:block hidden">
                    ({method?.name})
                </p>
                <img
                    src={cardLogos[method?.brand]}
                    alt={cardBrand.toUpperCase()}
                    className="w-auto h-4 absolute right-2.5 top-2.5 shadow ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-sm"
                />
                {method?.is_default && <p className="secondary-badge">Default</p>}
            </div>)
    }));

    const initialPaymentMethod = paymentList?.find(method =>
        subscriptionStatus === 'Subscribed' ? method.id === userSubscription?.payment_method_id : method.is_default
    );

    const initialValues = {
        payment_method: initialPaymentMethod ? {
            id: initialPaymentMethod.id,
            name: `**** ${initialPaymentMethod.last4} (${initialPaymentMethod.name})`
        } : '',
    };

    return (
        <div className="py-6 grid grid-cols-5 gap-y-6">
            <div className="text-sm font-medium leading-6 col-span-full">Subscription management</div>
            <div className="col-span-full sm:col-span-3 sm:col-start-3 space-y-6">
                {isSubscribed ? <ExistingSubscriptionForm
                        userSubscription={userSubscription}
                        paymentList={paymentList}
                        subscriptionStatus={subscriptionStatus}
                        initialValues={initialValues}
                        subscriptionPlan={subscriptionPlan}
                    /> :
                    <NewSubscriptionForm
                        subscriptionPlanHook={subscriptionPlanHook}
                        subscriptionStatus={subscriptionStatus}
                        initialValues={initialValues}
                        paymentList={paymentList}
                    />}
            </div>
        </div>
    )
};

SubscriptionManagement.propTypes = {
    subscriptionStatus: PropTypes.string,
    subscriptionPlanHook: PropTypes.func,
    paymentMethods: PropTypes.object,
    userSubscription: PropTypes.object,
    subscriptionPlan: PropTypes.object
};

export default SubscriptionManagement;
