import React from "react";
import {CheckIcon} from "@heroicons/react/20/solid";

import BgCheckers from "../../../../Components/Bg Checkers/BgCheckers";
import ResearchMockFrame from "./ResearchMockFrame";


const ResearchSolution = () => {

    return (
        <div className="relative isolate py-16 sm:py-24">
            <BgCheckers lines="stroke-secondary/10" boxes="fill-secondary/10"/>
            <div className="space-y-10 mx-auto max-w-7xl px-6 md:px-8 py-16">
                <div className="flex flex-col justify-center mx-auto max-w-3xl text-start lg:text-center space-y-6">
                    <div className="space-y-2" >
                        <h2 className="text-base font-medium leading-6 text-secondary">Research Solutions</h2>
                        <h1 className="font-bold tracking-tight text-base-content text-[min(7.5vw,40px)] leading-tight">Research with Intelligent Synthesis</h1>
                    </div>
                    <p className="leading-8 mx-auto text-[min(5vw,18px)] text-base-content/70 text-lg">Harness the power of Super Intelligence to swiftly navigate, summarize, and interrogate vast bodies of research. Simplify your scholarly pursuits with our advanced research platform.</p>
                </div>
                <div className="flex items-center justify-center justify-self-center">
                    <ResearchMockFrame />
                </div>
            </div>
            <div className="space-y-16">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 mx-auto max-w-7xl px-6 md:px-8">
                    <div className="flex items-start space-x-2">
                        <CheckIcon className="size-6 text-secondary shrink-0" aria-hidden="true"/>
                        <div className="space-y-1">
                            <h3 className="font-medium">Instant Access to Research Papers</h3>
                            <p className="text-base-content/70">Gain immediate entry to an extensive repository of research papers across disciplines. Our platform eliminates the barriers to knowledge by providing quick access to the studies you need, when you need them.</p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-2">
                        <CheckIcon className="size-6 text-secondary shrink-0" aria-hidden="true"/>
                        <div className="space-y-1">
                            <h3 className="font-medium">Super Intelligent Summarization</h3>
                            <p className="text-base-content/70">Dive into new knowledge without the deep dive time commitment. Our Super Intelligence rapidly reads and comprehends articles, delivering concise summaries that capture the essence of each paper. Stay abreast of the latest findings with efficiency and ease.</p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-2">
                        <CheckIcon className="size-6 text-secondary shrink-0" aria-hidden="true"/>
                        <div className="space-y-1">
                            <h3 className="font-medium">Interactive Query System</h3>
                            <p className="text-base-content/70">Move beyond passive reading with an interactive query system that lets you probe further. Ask complex questions and receive answers with relevant citations, making it easier to expand your understanding or challenge the findings within seconds.</p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-2">
                        <CheckIcon className="size-6 text-secondary shrink-0" aria-hidden="true"/>
                        <div className="space-y-1">
                            <h3 className="font-medium">Seamless Integration of Resources</h3>
                            <p className="text-base-content/70">Connect disparate sources and papers within our platform to foster a more comprehensive research environment. By allowing these sources to interact, you receive a synthesized view, ensuring a richer, more connected understanding of the subject matter.</p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-2">
                        <CheckIcon className="size-6 text-secondary shrink-0" aria-hidden="true"/>
                        <div className="space-y-1">
                            <h3 className="font-medium">Efficient Research Paper Writing</h3>
                            <p className="text-base-content/70">Streamline the process of writing cited research papers. Our tool not only finds the sources for you but also helps in crafting a well-cited document, saving hours of searching and cross-referencing.</p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-2">
                        <CheckIcon className="size-6 text-secondary shrink-0" aria-hidden="true"/>
                        <div className="space-y-1">
                            <h3 className="font-medium">Data-Driven Insights</h3>
                            <p className="text-base-content/70">Utilize our platform’s data analysis to identify trends, gaps, and opportunities in the research landscape. Leverage these insights to direct your own research efforts or to find underexplored niches in the field.</p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-2">
                        <CheckIcon className="size-6 text-secondary shrink-0" aria-hidden="true"/>
                        <div className="space-y-1">
                            <h3 className="font-medium">Collaborative Research Features</h3>
                            <p className="text-base-content/70">Collaborate on papers with peers, leveraging shared insights and annotations. Our Super Intelligent assistant can act as a mediator to combine individual contributions into a cohesive whole.</p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-2">
                        <CheckIcon className="size-6 text-secondary shrink-0" aria-hidden="true"/>
                        <div className="space-y-1">
                            <h3 className="font-medium">Automated Citation Management</h3>
                            <p className="text-base-content/70">Forget the hassle of citation management. Our platform automatically formats and manages references for your papers, ensuring they meet the citation style of your choice.</p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-2">
                        <CheckIcon className="size-6 text-secondary shrink-0" aria-hidden="true"/>
                        <div className="space-y-1">
                            <h3 className="font-medium">Tailored Research Experience</h3>
                            <p className="text-base-content/70">Customize your research tools to suit your methods. Whether you prefer visual data representation or text-based outputs, our platform molds to your preferred style of data consumption.</p>
                        </div>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl px-6 md:px-8 space-y-2">
                    <h2 className="font-semibold text-2xl">Start Your Research Journey</h2>
                    <p className="text-base-content/70 text-lg">Embrace a smarter way to research with our Super Intelligent solutions. Leverage the collective intelligence of thousands of studies and experts in one seamless, integrated platform. Begin your journey towards faster, more efficient scholarly writing and analysis today.</p>
                </div>
            </div>
        </div>
    )
};

export default ResearchSolution;
