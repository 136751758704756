import React from "react";

import Markdown from "../../../Components/Markdown/Markdown";


const PrivacyPolicy = () => {

    return (
        <div className="bg-base-100 px-6 py-32 lg:px-8">
            <div className="mx-auto max-w-3xl text-base leading-7">
                <p className="text-base font-semibold leading-7 text-primary mb-2">policies</p>
                <h1 className="text-4xl leading-7 mt-4 mb-6 font-bold">Privacy Policy</h1>
                <Markdown
                    className="prose-h1:text-4xl prose-h1:pb-8 prose-p:text-base prose-p:font-light prose-p:pt-2 prose-p:leading-7 leading-7"
                    text={`Dovise, LLC ("Dovise", "we", "our", or "us") is committed to protecting your privacy. This Privacy Policy describes how we collect, use, and disclose personal information.

**1. Information We Collect**

- **Account Information:** When you create an account, we collect information such as your name, contact details, and payment information.
- **User Content:** We collect any content you provide to our Services.
- **Technical Information:** We collect information about your use of the Services, such as IP address, browser type, and usage data.

**2. How We Use Information**

- To provide and improve our Services.
- To communicate with you about our Services.
- To prevent fraud and ensure security.
- To comply with legal obligations.

**3. Sharing of Information**

- **Service Providers:** We share information with vendors to perform services on our behalf.
- **Business Transfers:** Information may be transferred in connection with a business transaction.
- **Legal Requirements:** We may disclose information to comply with the law or protect our rights.

**4. Your Rights**

- **Access and Correction:** You have the right to access and correct your personal information.
- **Deletion:** You can request the deletion of your personal information.
- **Opt-Out:** You can opt out of the use of your information for training our models.

**5. Security and Retention**: We implement security measures to protect your information. We retain information for as long as necessary to provide our Services or as required by law.

**6. International Transfers**: Your information may be transferred to and processed in the United States and other countries.

**7. Changes to this Policy**: We may update this Privacy Policy from time to time. We will notify you of significant changes by posting the new policy on our website.

**8. Contact Us**: If you have any questions, please contact us at contact@dovise.com.`}
                    includeHtml={true}
                />
            </div>
        </div>
    )
};

export default PrivacyPolicy;

