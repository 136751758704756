import React from "react";


const GlideCreatorTestSkeleton = () => {

    return (
        <div className="grid md:grid-cols-9 lg:grid-cols-11 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-sm w-full z-10 h-[calc(100dvh-163px)]">
            <div className="col-span-full lg:col-span-8 w-full">
                <div className="h-[calc(100dvh-265px)] overflow-y-auto scrollbar flex flex-col justify-center items-center space-y-5 px-4">
                    <div className="rounded-full p-2 bg-base-300/30 skeleton w-28 h-28 lg:w-24 lg:h-24"/>
                    <div className="max-w-2xl w-full space-y-3">
                        <div className="bg-base-300/30 skeleton w-32 h-3 rounded mx-auto"/>
                        <div className="bg-base-300/30 skeleton w-20 h-2.5 rounded mx-auto"/>
                    </div>
                    <div className="max-w-2xl w-full space-y-2">
                        <div className="bg-base-300/30 skeleton w-full h-4 rounded mx-auto"/>
                        <div className="bg-base-300/30 skeleton w-1/2 h-4 rounded mx-auto"/>
                    </div>
                </div>
                <div className="px-4 py-2">
                    <div className="rounded-xl w-full flex items-center justify-between bg-base-200 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 px-5 py-3.5">
                        <div className="h-6 w-6 rounded-full bg-base-300/30 skeleton"/>
                        <div className="h-6 w-6 rounded-full bg-base-300/30 skeleton"/>
                    </div>
                    <div className="py-3">
                        <div className="h-3 bg-base-300/30 rounded skeleton mx-auto w-2/3"/>
                    </div>
                </div>
            </div>
            <div className="md:col-span-3 flex flex-col justify-between items-start py-4 px-4 w-full border-l border-l-neutral-focus/40 dark:border-l-neutral-focus-dark/40">
                <div className="w-full space-y-1">
                    <div className="flex items-center py-3 px-5 space-x-3 bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 rounded-t-lg">
                        <div className="rounded-full w-4 h-4 bg-base-300/30 skeleton"/>
                        <div className="bg-base-300/30 skeleton rounded w-1/2 h-4"/>
                    </div>
                    <div className="flex items-center py-3 px-5 space-x-3 bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50">
                        <div className="rounded-full w-4 h-4 bg-base-300/30 skeleton"/>
                        <div className="flex-1 bg-base-300/30 skeleton rounded w-full h-4"/>
                    </div>
                    <div className="flex flex-col justify-start py-3 px-5 space-y-2 bg-base-200 ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50 rounded-b-lg">
                        <div className="flex items-center w-1/3 space-x-2">
                            <div className="rounded-full w-4 h-4 bg-base-300/30 skeleton"/>
                            <div className="flex-1 bg-base-300/30 skeleton rounded w-1/2 h-3.5"/>
                        </div>
                        <div className="flex items-center w-2/3 space-x-2">
                            <div className="rounded-full w-3.5 h-3.5 bg-base-300/30 skeleton"/>
                            <div className="flex-1 bg-base-300/30 skeleton rounded w-1/2 h-3.5"/>
                        </div>
                        <div className="flex items-center w-2/3 space-x-2">
                            <div className="rounded-full w-3.5 h-3.5 bg-base-300/30 skeleton"/>
                            <div className="flex-1 bg-base-300/30 skeleton rounded w-1/2 h-3.5"/>
                        </div>
                        <div className="flex items-center w-2/3 space-x-2">
                            <div className="rounded-full w-3.5 h-3.5 bg-base-300/30 skeleton"/>
                            <div className="flex-1 bg-base-300/30 skeleton rounded w-1/2 h-3.5"/>
                        </div>
                    </div>
                </div>
                <div className="w-full space-y-2">
                    <div className="flex items-center justify-center h-8 space-x-2 rounded-md py-2 px-3 bg-base-200 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 w-full">
                        <div className="w-32 h-4 bg-base-300/30 skeleton rounded"/>
                        <div className="rounded-full bg-base-300/30 skeleton w-4 h-4"/>
                    </div>
                    <div className="flex items-center justify-center h-8 space-x-2 rounded-md py-2 px-3 bg-base-200 ring-[0.5px] ring-base-content/20 dark:ring-base-content/20 w-full">
                        <div className="w-32 h-4 bg-base-300/30 skeleton rounded"/>
                        <div className="rounded-full bg-base-300/30 skeleton w-4 h-4"/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GlideCreatorTestSkeleton;
