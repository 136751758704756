import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";

import {footerNav} from "./footerNav";
import {companyLogo, loaderLogo} from "../../Design/companyLogo";
import routes from "../../Routes/routes";

const Footer = (props) => {
    const { className } = props;
    const navigate = useNavigate();

    return (
        <div className={clsx(className, "mx-auto px-6 lg:px-8 w-full max-w-7xl")}>
            <footer
                aria-labelledby="footer-heading"
                className="relative border-t border-neutral"
            >
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="xl:grid xl:grid-cols-3 xl:gap-8 py-14">
                    <div className="space-y-8">
                        <button
                            type="button"
                            className="group p-0 m-0"
                            onClick={() => navigate(routes.unAuth.home.href)}
                        >
                            <img
                                className={clsx("h-7", "group-hover:hidden block")}
                                src={loaderLogo}
                                alt="Dovise"
                            />
                            <img
                                className={clsx("h-7", "group-hover:block hidden")}
                                src={companyLogo}
                                alt="Dovise"
                            />
                        </button>
                        <p className="text-sm leading-6">
                            Empowering Progress Through Smart Solutions.
                        </p>
                        <div className="flex space-x-6">
                            {footerNav.social.map((item) => (
                                <Link key={item.name} to={item.href} target="_blank" className="text-sm leading-6 text-base-300/70 hover:text-primary" rel="noreferrer">
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon className="h-6 w-6" aria-hidden="true"/>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                                <h3 className="text-sm font-semibold leading-6 text-base-content">Solutions</h3>
                                <ul className="mt-6 space-y-4">
                                    {footerNav.solutions.map((item) => (
                                        <li key={item.name}>
                                            <Link to={item.href} className="text-sm leading-6 text-base-content/70 hover:text-base-content">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-sm font-semibold leading-6 text-base-content">Products</h3>
                                <ul className="mt-6 space-y-4">
                                    {footerNav.products.map((item) => (
                                        <li key={item.name}>
                                            <Link to={item.href} className="text-sm leading-6 text-base-content/70 hover:text-base-content">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold leading-6 text-base-content">Company</h3>
                                <ul className="mt-6 space-y-4">
                                    {footerNav.company.map((item) => (
                                        <li key={item.name}>
                                            <Link to={item.href} className="text-sm leading-6 text-base-content/70 hover:text-base-content">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-sm font-semibold leading-6 text-base-content">Legal</h3>
                                <ul className="mt-6 space-y-4">
                                    {footerNav.legal.map((item) => (
                                        <li key={item.name}>
                                            <Link to={item.href} className="text-sm leading-6 text-base-content/70 hover:text-base-content">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-t border-neutral py-8">
                    <p className="text-xs leading-5 text-base-content/70">Copyright &copy; 2023 Dovise All rights reserved.</p>
                </div>
            </footer>
        </div>
    )
};

Footer.propTypes = {
    className: PropTypes.string,
}

export default Footer;
