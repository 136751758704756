import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const userDefinedGlideGET = createAsyncThunk(
    'taskGlide/glides/user-defined/get',
    async ({ uuid }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_GET_USER_DEFINED_GLIDES_PATH}${uuid}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userDefinedGlideRetrievalVF: false,
    userDefinedGlideRetrievalApiState: 'idle',
    userDefinedGlideRetrievalResponse: null,
    userDefinedGlideRetrievalError: null,
};

export const getUserGlideDetailsSlice = createSlice({
    name: 'userDefinedGlideRetrieval',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserDefinedGlideRetrievalVF: (state) => {
            state.userDefinedGlideRetrievalVF = false;
        },
        resetUserDefinedGlideRetrieval: (state) => {
            state.userDefinedGlideRetrievalVF = false;
            state.userDefinedGlideRetrievalApiState = 'idle';
            state.userDefinedGlideRetrievalResponse = null;
            state.userDefinedGlideRetrievalError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userDefinedGlideGET.pending, (state) => {
                state.userDefinedGlideRetrievalApiState = 'loading';
                state.userDefinedGlideRetrievalVF = true;
            })
            .addCase(userDefinedGlideGET.fulfilled, (state, action) => {
                state.userDefinedGlideRetrievalApiState = 'succeeded';
                state.userDefinedGlideRetrievalResponse = action?.payload;
                state.userDefinedGlideRetrievalVF = true;
                state.userDefinedGlideRetrievalError = null;
            })
            .addCase(userDefinedGlideGET.rejected, (state, action) => {
                state.userDefinedGlideRetrievalApiState = 'failed';
                state.userDefinedGlideRetrievalError = action?.payload;
                state.userDefinedGlideRetrievalVF = true;
                state.userDefinedGlideRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUserDefinedGlideRetrievalVF,
    resetUserDefinedGlideRetrieval,
} = getUserGlideDetailsSlice.actions;

export default getUserGlideDetailsSlice.reducer;
