import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const userTransactionGET = createAsyncThunk(
    'payments/transactions/get',
    async (transaction_id, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TRANSACTIONS_PATH}${transaction_id}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userTransactionRetrievalVF: false,
    userTransactionRetrievalApiState: 'idle',
    userTransactionRetrievalResponse: null,
    userTransactionRetrievalError: null,
};

export const getUserTransactionSlice = createSlice({
    name: 'getUserTransaction',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserTransactionRetrievalVF: (state) => {
            state.userTransactionRetrievalVF = false;
        },
        resetUserTransactionRetrievalVF: (state) => {
            state.userTransactionRetrievalVF = false;
            state.userTransactionRetrievalApiState = 'idle';
            state.userTransactionRetrievalResponse = null;
            state.userTransactionRetrievalError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userTransactionGET.pending, (state) => {
                state.userTransactionRetrievalApiState = 'loading';
                state.userTransactionRetrievalVF = true;
            })
            .addCase(userTransactionGET.fulfilled, (state, action) => {
                state.userTransactionRetrievalApiState = 'succeeded';
                state.userTransactionRetrievalResponse = action.payload;
                state.userTransactionRetrievalVF = true;
                state.userTransactionRetrievalError = null;
            })
            .addCase(userTransactionGET.rejected, (state, action) => {
                state.userTransactionRetrievalApiState = 'failed';
                state.userTransactionRetrievalError = action?.payload;
                state.userTransactionRetrievalVF = true;
                state.userTransactionRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUserTransactionRetrievalVF,
    resetUserTransactionRetrievalVF
} = getUserTransactionSlice.actions;

export default getUserTransactionSlice.reducer;
