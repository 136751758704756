import React, {useState} from 'react';
import PropTypes from "prop-types";
import clsx from "clsx";


const MdBold = ({ children }) => {
    const text = children.toString();

    return (
        <strong className={clsx([
            text.startsWith("@") ? "font-medium text-primary-focus dark:text-primary-content cursor-default group static"
                : "font-normal",
        ])}
        >
            {children}
        </strong>
    );
};

MdBold.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MdBold;
