import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearUserGlideDefaultStateUpdateVF, resetUserGlideDefaultStateUpdate, userGlideDefaultStatePOST} from "../../../../../api/features/superIntelligence/taskGlide/glides/updateUserGlideDefaultStateSlice";
import {useListUserGlides} from "./useListUserGlides";


export const useUpdateUserGlideDefaultState = (
    { vfExists=false, autoUpdate=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // MyGlides
    const {
        userGlideDefaultStateUpdateVF,
        userGlideDefaultStateUpdateApiState,
        userGlideDefaultStateUpdateResponse,
        userGlideDefaultStateUpdateError,
    } = useSelector(state => state.updateUserGlideDefaultStateReducer);
    const { getGlides } = useListUserGlides();

    // API states with visual feedback
    const userGlideDefaultStateUpdateSuccessVF = userGlideDefaultStateUpdateApiState === 'succeeded' && userGlideDefaultStateUpdateVF;
    const userGlideDefaultStateUpdateFailureVF = userGlideDefaultStateUpdateApiState === 'failed' && userGlideDefaultStateUpdateVF;

    const updateUserGlideDefaultState = (user_glide_id) => userIsAuthenticated && dispatch(userGlideDefaultStatePOST({ user_glide_id }));

    useEffect(() => {
        if (vfExists) {
            if (userGlideDefaultStateUpdateSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearUserGlideDefaultStateUpdateVF());
                }, 500);

                return () => clearTimeout(timer);
            } else if (userGlideDefaultStateUpdateFailureVF) {
                dispatch(setAlert({
                    message: userGlideDefaultStateUpdateResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUserGlideDefaultStateUpdate());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [userGlideDefaultStateUpdateApiState, userGlideDefaultStateUpdateVF]);

    useEffect(() => {
        if (autoUpdate && userGlideDefaultStateUpdateApiState === 'succeeded' && !userGlideDefaultStateUpdateVF) {
            getGlides();
            dispatch(resetUserGlideDefaultStateUpdate());
        }
    }, [userGlideDefaultStateUpdateApiState, userGlideDefaultStateUpdateVF]);

    return {
        updateUserGlideDefaultState,
        userGlideDefaultStateUpdateVF,
        userGlideDefaultStateUpdateApiState,
        userGlideDefaultStateUpdateResponse,
        userGlideDefaultStateUpdateError,
        userGlideDefaultStateUpdateSuccessVF,
        userGlideDefaultStateUpdateFailureVF
    };
};
