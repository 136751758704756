import React from "react";

import {getGlideIcon} from "../../../Auth/Products/TaskGlide/Chat/ChatBox/Messages/glideInfo";
import Markdown from "../../../../Components/Markdown/Markdown";


const TaskGlideProductChatContent = () => {

    return (
        <div className="relative overflow-hidden mx-auto bg-base-100 h-[550px] pl-10 pr-6">
            <div className="flex flex-col w-full bg-base-100 z-20 py-4">
                <div className="h-full space-y-5 py-1">
                    <div className="flex flex-row space-x-2 group/user-message">
                        <div className="h-full">
                            <div className="h-6 w-6 rounded-full flex items-center justify-center text-base-100 text-2xs font-medium bg-gray-600 dark:bg-gray-400 shadow shadow-gray-400/70 dark:shadow-black">
                                JD
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <div className="flex flex-row items-end space-x-3 w-fit max-w-full pt-1.5 pb-0.5">
                                    <span className="leading-none font-medium text-sm">John</span>
                                    <span className="text-3xs text-base-content/70 whitespace-nowrap">12:00pm</span>
                                </div>
                            </div>
                            <div>
                                <Markdown
                                    contentClass="text-sm prose:text-sm prose-p:text-sm prose-h2:text-sm prose-li:text-sm prose-strong:text-sm"
                                    text="What are the latest news on Apple vision pro??"
                                    className="w-full min-w-full text-base-content"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row space-x-2 group/user-message">
                        <div className="h-full">
                            <div className="h-6 w-6 rounded-full flex items-center justify-center text-base-100 text-2xs font-medium bg-red-600 dark:bg-red-400 shadow shadow-gray-400/70 dark:shadow-black">
                                {getGlideIcon("NewsGlide")}
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <div className="flex flex-row items-end space-x-3 w-fit max-w-full pt-1.5 pb-0.5">
                                    <span className="leading-none font-medium text-sm">NewsGlide</span>
                                    <span className="text-3xs text-base-content/70 whitespace-nowrap">12:01pm</span>
                                </div>
                            </div>
                            <div>
                                <Markdown
                                    contentClass="text-sm prose:text-sm prose-p:text-sm prose-h2:text-sm prose-li:text-sm prose-strong:text-sm"
                                    text={"### Latest News on Apple Vision Pro:\n" +
                                        "\n" +
                                        "The latest news on Apple Vision Pro reveals some exciting developments and future plans for Apple's innovative product. Here are the key highlights from the recent news article by Digital Trends:\n" +
                                        "\n" +
                                        "1. **Next Big Thing After Vision Pro:**\n" +
                                        "   - Apple is already planning its next big innovation following the Vision Pro.\n" +
                                        "   - There are discussions within Apple's hardware engineering division about potential new devices.\n" +
                                        "   - The company is under pressure to introduce something that resonates with the market.\n" +
                                        "\n" +
                                        "2. **New Devices in Development:**\n" +
                                        "   - Apple is secretly working on a range of new products to capture customer attention.\n" +
                                        "   - These include smartglasses, a health and fitness ring, and enhanced AirPods with cameras and advanced sensors.\n" +
                                        "   - The aim is to revitalize sales and addre ●"}
                                    className="w-full min-w-full text-base-content"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TaskGlideProductChatContent;
