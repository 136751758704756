import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {calendarEventCREATE, clearCalendarEventCreateVF, resetCalendarEventCreate} from "../../../../../api/features/superIntelligence/memoMinder/calendar/createCalendarEventSlice";


export const useCreateCalendarEvent = (
    { vfExists=false, autoUpdate=false } = {}
) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();
    const {
        calendarEventCreateVF,
        calendarEventCreateApiState,
        calendarEventCreateResponse,
        calendarEventCreateError,
    } = useSelector(state => state.createCalendarEventReducer);

    const calendarEventCreateSuccessVF = calendarEventCreateApiState === 'succeeded' && calendarEventCreateVF;
    const calendarEventCreateFailureVF = calendarEventCreateApiState === 'failed' && calendarEventCreateVF;

    const createCalendarEvent = (values) => userIsAuthenticated && dispatch(calendarEventCREATE({ values }));

    useEffect(() => {
        if (vfExists) {
            if (calendarEventCreateSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearCalendarEventCreateVF());
                }, 500);

                return () => clearTimeout(timer);
            } else if (calendarEventCreateFailureVF) {
                dispatch(setAlert({
                    message: calendarEventCreateResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetCalendarEventCreate());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [calendarEventCreateApiState, calendarEventCreateVF]);

    useEffect(() => {
        if (autoUpdate && calendarEventCreateApiState === 'succeeded' && !calendarEventCreateVF) {

            dispatch(resetCalendarEventCreate());
        }
    }, [calendarEventCreateApiState, calendarEventCreateVF]);

    return {
        createCalendarEvent,
        calendarEventCreateVF,
        calendarEventCreateApiState,
        calendarEventCreateResponse,
        calendarEventCreateError,
        calendarEventCreateSuccessVF,
        calendarEventCreateFailureVF
    };
};
