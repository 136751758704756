import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const downloadDocumentGET = createAsyncThunk(
    'taskGlide/download-document/',
    async ({ document_uuid }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_DOWNLOAD_DOCUMENT_PATH}${document_uuid}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    documentDeletionVF: false,
    documentDeletionApiState: 'idle',
    documentDeletionResponse: null,
    documentDeletionError: null,
};

export const createUserGlideSlice = createSlice({
    name: 'documentDownload',
    initialState: INITIAL_STATE,
    reducers: {
        clearDocumentDownloadVF: (state) => {
            state.documentDeletionVF = false;
        },
        resetDocumentDownload: (state) => {
            state.documentDeletionVF = false;
            state.documentDeletionApiState = 'idle';
            state.documentDeletionResponse = null;
            state.documentDeletionError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(downloadDocumentGET.pending, (state) => {
                state.documentDeletionApiState = 'loading';
                state.documentDeletionVF = true;
            })
            .addCase(downloadDocumentGET.fulfilled, (state, action) => {
                state.documentDeletionApiState = 'succeeded';
                state.documentDeletionResponse = action?.payload;
                state.documentDeletionVF = true;
                state.documentDeletionError = null;
            })
            .addCase(downloadDocumentGET.rejected, (state, action) => {
                state.documentDeletionApiState = 'failed';
                state.documentDeletionError = action?.payload;
                state.documentDeletionVF = true;
                state.documentDeletionResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearDocumentDownloadVF, resetDocumentDownload
} = createUserGlideSlice.actions;

export default createUserGlideSlice.reducer;
