import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearUserInfoUpdateVF, resetUserInfoUpdate, userInfoUPDATE} from "../../../api/features/users/updateUserInfoSlice";
import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {useGetUserInfo} from "./useGetUserInfo";
import {useAuthentication} from "./useAuthentication";


export const useUpdateUserInfo = (
    {vfExists=false, refreshAndReset=true} = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // User Info
    const { getUserInfo } = useGetUserInfo();
    const {
        userInfoUpdateVF,
        userInfoUpdateApiState,
    } = useSelector(state => state.updateUserInfoReducer);

    // API State with visual feedback
    const userInfoUpdateSuccessVF = userInfoUpdateApiState === 'succeeded' && userInfoUpdateVF;
    const userInfoUpdateFailureVF = userInfoUpdateApiState === 'failed' && userInfoUpdateVF;

    // Function to be called on form submission
    const updateUserInfo = (values) => {
        const mappedValues = {
            first_name: values.firstName,
            last_name: values.lastName,
            occupation: values.occupation,
            date_of_birth: values.dateOfBirth,
            phone_number: values.phoneNumber,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            country: values.country,
            zip_code: values.zipCode,
        };

        userIsAuthenticated && dispatch(userInfoUPDATE(mappedValues));
    };

    useEffect(() => {
        if (vfExists && (userInfoUpdateSuccessVF || userInfoUpdateFailureVF)) {
            dispatch(setAlert({
                message: userInfoUpdateApiState === 'succeeded' ? 'User information updated successfully.' : 'Please try again.',
                type: userInfoUpdateApiState === 'succeeded' ? 'success' : 'error'
            }));
            dispatch(incrementAlertKey());

            const timer = setTimeout(() => {
                dispatch(clearUserInfoUpdateVF());
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [userInfoUpdateApiState]);

    useEffect(() => {
        if (refreshAndReset && userInfoUpdateApiState === 'succeeded') {
            if (!userInfoUpdateVF) {
                getUserInfo();
                dispatch(resetUserInfoUpdate());
            }
        }
    }, [userInfoUpdateApiState, userInfoUpdateVF]);

    return {
        updateUserInfo,
        userInfoUpdateVF,
        userInfoUpdateSuccessVF,
        userInfoUpdateFailureVF,
        userInfoUpdateApiState,
    };
};
