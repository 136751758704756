import React from 'react';
import {Link} from "react-router-dom";
import {AiFillTool} from "react-icons/ai";
import {HiChartBar} from "react-icons/hi";
import {HiUserGroup} from "react-icons/hi2";
import {MdCheckCircle} from "react-icons/md";
import {BsPersonFillCheck} from "react-icons/bs";
import {RiArrowRightLine, RiArrowRightSLine} from "react-icons/ri";

import TaskGlideProductBrowserFrame from "./TaskGlideProductBrowserFrame";
import TaskGlideProductPhoneFrame from "./TaskGlideProductPhoneFrame";
import BgCheckers from "../../../../Components/Bg Checkers/BgCheckers";
import routes from "../../../../Routes/routes";


const TaskGlideProduct = () => {

    return (
        <div className="relative isolate bg-base-100 px-6 pt-14 pb-24 overflow-visible lg:px-0">
            <BgCheckers/>
            <div
                className="absolute left-1/2 top-0 -z-10 -ml-24 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
                aria-hidden="true"
            >
                <div
                    className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-primary to-primary-focus dark:to-primary-focus-dark opacity-30"
                    style={{
                        clipPath: 'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)'}}
                />
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
                <div className="sticky top-0 col-start-2 row-span-2 row-start-1 overflow-hidden">
                    <div className="col-span-1 lg:flex hidden">
                        <div className="flex-none max-w-none">
                            <TaskGlideProductBrowserFrame />
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-7xl lg:gap-x-4 lg:px-8 flex flex-col justify-center py-16 lg:py-52">
                    <div className="lg:max-w-xl text-start space-y-4">
                        <p className="text-base font-medium leading-7 text-primary-focus dark:text-primary-focus-dark">TaskGlide</p>
                        <div className="space-y-4 mt-4">
                            <h1 className="font-bold leading-tight tracking-tight text-base-content text-[min(10vw,40px)]">Excel through tasks intelligently</h1>
                            <p className="text-[min(5vw,18px)] leading-8 text-base-content/70">
                                Your all-encompassing platform for seamless task management, autonomous productivity, and collaborative intelligence. TaskGlide is your gateway to a future where every task is a triumph.
                            </p>
                        </div>
                        <div className="flex items-center gap-x-6 pt-4">
                            <Link
                                to={routes.unAuth.register.href}
                                className="rounded-primary-button group"
                            >
                                <p>Register</p>
                                <RiArrowRightSLine className="flex group-hover:hidden size-4" aria-hidden="true"/>
                                <RiArrowRightLine className="group-hover:flex hidden size-4" aria-hidden="true"/>
                            </Link>
                            <Link
                                to={routes.unAuth.products.find(product => product.name === 'TaskGlide').children.find(child => child.name === 'Glides').href}
                                className="rounded-empty-button group"
                            >
                                Glides
                                <RiArrowRightSLine className="flex group-hover:hidden size-4 shrink-0" aria-hidden="true"/>
                                <RiArrowRightLine className="group-hover:flex hidden size-4 shrink-0" aria-hidden="true"/>
                            </Link>
                        </div>
                    </div>
                    <div className="block lg:hidden mt-10">
                        <TaskGlideProductPhoneFrame/>
                    </div>
                </div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="max-w-xl text-base leading-7 lg:max-w-lg text-base-content/70">
                            <p>Ultimate productivity powerhouse with Super Intelligent assistants that are custom-built for your world. These aren’t just pre-programmed bots; they’re dynamic, learning entities that adapt to your style and evolve with every task. With TaskGlide, you’re the architect of your digital workforce, creating Super Intelligent assistants that are perfectly aligned with your vision of efficiency.</p>
                            <ul className="mt-8 space-y-8 text-gray-600">
                                <li className="relative">
                                    <AiFillTool className="absolute top-1 left-1 size-5 flex-none text-primary-focus dark:text-primary-focus-dark" aria-hidden="true"/>
                                    <div className="pl-9">
                                        <strong className="font-semibold text-base-content whitespace-nowrap">
                                            Seamless Tool Integration
                                        </strong>
                                        <p className="text-base-content/70">Our platform is your playground for productivity. Integrate your trusted apps and tools directly into the TaskGlide ecosystem for a seamless workflow experience. From sophisticated development environments to advanced research databases, TaskGlide bridges the gap between your tools and your tasks, ensuring a cohesive and uninterrupted work stream.</p>
                                    </div>
                                </li>
                                <li className="relative">
                                    <BsPersonFillCheck className="absolute top-1 left-1 size-5 flex-none text-primary-focus dark:text-primary-focus-dark" aria-hidden="true"/>
                                    <div className="pl-9">
                                        <strong className="font-semibold text-base-content whitespace-nowrap">
                                            Diverse Domain Expertise
                                        </strong>
                                        <p className="text-base-content/70">
                                            Step into a realm where Super Intelligent expertise knows no bounds. TaskGlide offers specialized agents trained across various fields – finance, tech, education, and more – all ready to serve your multidisciplinary needs. These agents aren’t just versatile; they’re virtuosos in their respective fields, providing insights and support that are as informed as they are invaluable.
                                        </p>
                                    </div>
                                </li>
                                <li className="relative">
                                    <MdCheckCircle className="absolute top-1 left-1 size-5 flex-none text-primary-focus dark:text-primary-focus-dark" aria-hidden="true"/>
                                    <div className="pl-9">
                                        <strong className="font-semibold text-base-content whitespace-nowrap">
                                            Autonomous Task Management
                                        </strong>
                                        <p className="text-base-content/70">
                                            A new era of autonomy. TaskGlide’s Super Intelligent agents are equipped to take charge of your repetitive and time-consuming tasks. From automated documentation to self-driven data analysis, they handle the heavy lifting, enabling you to reclaim your time and focus on what truly matters – your creativity, strategic decisions, and professional growth.
                                        </p>
                                    </div>
                                </li>
                                <li className="relative">
                                    <HiUserGroup className="absolute top-1 left-1 size-5 flex-none text-primary-focus dark:text-primary-focus-dark" aria-hidden="true"/>
                                    <div className="pl-9">
                                        <strong className="font-semibold text-base-content whitespace-nowrap">
                                            Collaborative Intelligence
                                        </strong>
                                        <p className="text-base-content/70">
                                            With TaskGlide, collaboration takes on a new dimension. Our Super Intelligent agents are not just working alongside you; they are communicating with each other, combining their specialized knowledge to tackle complex projects with a level of depth and understanding previously unimaginable. This synergy of Super Intelligent minds ensures that every project benefits from a 360-degree perspective.
                                        </p>
                                    </div>
                                </li>
                                <li className="relative">
                                    <HiChartBar className="absolute top-1 left-1 size-5 flex-none text-primary-focus dark:text-primary-focus-dark" aria-hidden="true"/>
                                    <div className="pl-9">
                                        <strong className="font-semibold text-base-content whitespace-nowrap">
                                            Future-Ready Productivity
                                        </strong>
                                        <p className="text-base-content/70">
                                            TaskGlide does not just set the bar; it continually raises it. As the landscape of work changes, so does our platform, constantly updating with the latest Super Intelligent advancements and industry standards. With TaskGlide, you are not just equipped for today’s tasks; you are primed for tomorrow’s challenges.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <div className="space-y-6 mt-16">
                                <h2 className="text-2xl font-bold tracking-tight text-base-content">
                                    Call to Action
                                </h2>
                                <p>
                                    Transform the way you work, learn, and innovate. TaskGlide is more than a tool – it is a catalyst for unparalleled productivity. Begin your journey today and redefine what is possible. Glide with us into a future where your potential is unlimited, and every task is a triumph.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TaskGlideProduct;
