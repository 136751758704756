import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";
import {IoTrash, IoTrashBinOutline} from "react-icons/io5";
import clsx from "clsx";

import {useDeleteAllTgConversations} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteAllTgConversations";

const AllConversationDeletionModal = ({ onDelete, disabledState }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        tgAllConversationsDeleteApiState,
        tgAllConversationsDeleteVF,
        tgAllConversationsDeleteSuccessVF,
        tgAllConversationsDeleteFailureVF
    } = useDeleteAllTgConversations({vfExists: true, refreshAndReset: false});

    useEffect(() => {
        const dialogElement = document.getElementById('allConversationDeletionDesktop');

        if (tgAllConversationsDeleteSuccessVF || tgAllConversationsDeleteFailureVF) {
            dialogElement?.close();
            setIsModalOpen(false);
        }
    }, [tgAllConversationsDeleteApiState, tgAllConversationsDeleteVF, tgAllConversationsDeleteSuccessVF, tgAllConversationsDeleteFailureVF]);


    return (
        <>
            <button
                type="button"
                disabled={disabledState}
                className="flex w-1/3 mx-auto space-x-2 error-button"
                onClick={(e) => {
                    if (!disabledState) {
                        e.preventDefault();
                        const dialogElement = document.getElementById('allConversationDeletionDesktop');
                        dialogElement.showModal();
                        setIsModalOpen(true);
                    }
                }}
            >
                <IoTrashBinOutline className="h-auto w-4" aria-hidden="true" />
                <p>Delete All</p>
            </button>
            <dialog
                id="allConversationDeletionDesktop"
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={() => setIsModalOpen(false)}
            >
                <form method="dialog" className="modal-wrapper" onSubmit={(e) => e.preventDefault()} >
                    <div className="sm:flex sm:items-start" >
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-error-content sm:mx-0 sm:h-10 sm:w-10" >
                            <IoTrash className="h-6 w-6 text-error" aria-hidden="true"/>
                        </div>
                        <div className="mt-3 text-center sm:mx-4 sm:mt-2 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-base-content">
                                Delete All Conversations
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-base-content/70">
                                    Are you sure you want to delete all conversations? This action cannot be undone.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-end gap-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                const dialogElement = document.getElementById('allConversationDeletionDesktop');
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                            className="standard-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            disabled={disabledState}
                            className="error-button"
                            onClick={onDelete}
                        >
                            <CgSpinner
                                className={clsx(
                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                    tgAllConversationsDeleteVF ? "" : "hidden"
                                )}
                                aria-hidden="true"
                            />
                            <p className={tgAllConversationsDeleteVF ? "invisible" : ""}>
                                Delete
                            </p>
                        </button>
                    </div>
                </form>
                <form method="dialog" className="modal-backdrop">
                    <button
                        className="cursor-default"
                        onClick={() => {
                            const dialogElement = document.getElementById("allConversationDeletionDesktop");
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >close
                    </button>
                </form>
            </dialog>
        </>
    )
};

AllConversationDeletionModal.propTypes = {
    onDelete: PropTypes.func,
    disabledState: PropTypes.bool,
}

export default AllConversationDeletionModal;
