import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const feedbackPOST = createAsyncThunk(
    'users/feedback/post',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_POST_FEEDBACK_PATH}`,
                    args: values,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    feedbackCreateVF: false,
    feedbackCreateApiState: 'idle',
    feedbackCreateResponse: null,
    feedbackCreateError: null,
};

export const createFeedbackSlice = createSlice({
    name: 'feedbackCreation',
    initialState: INITIAL_STATE,
    reducers: {
        clearFeedbackCreationVF: (state) => {
            state.feedbackCreateVF = false;
        },
        resetFeedbackCreation: (state) => {
            state.feedbackCreateVF = false;
            state.feedbackCreateApiState = 'idle';
            state.feedbackCreateResponse = null;
            state.feedbackCreateError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(feedbackPOST.pending, (state) => {
                state.feedbackCreateApiState = 'loading';
                state.feedbackCreateVF = true;
            })
            .addCase(feedbackPOST.fulfilled, (state, action) => {
                state.feedbackCreateApiState = 'succeeded';
                state.feedbackCreateResponse = action.payload;
                state.feedbackCreateVF = true;
                state.feedbackCreateError = null;
            })
            .addCase(feedbackPOST.rejected, (state, action) => {
                state.feedbackCreateApiState = 'failed';
                state.feedbackCreateError = action?.payload;
                state.feedbackCreateVF = true;
                state.feedbackCreateResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET_USER_MANAGEMENT') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearFeedbackCreationVF,
    resetFeedbackCreation
} = createFeedbackSlice.actions;

export default createFeedbackSlice.reducer;
