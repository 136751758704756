import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";
import {IoIosPaperPlane} from "react-icons/io";
import clsx from "clsx";
import {Field, Form, Formik, useFormikContext} from "formik";
import {useSearchParams} from "react-router-dom";

import {useCreateFeedback} from "../../../../../Hooks/apiHooks/users/usePostFeedback";
import SingleSelect from "../../../../../Components/Selects/SingleSelect";
import {useListUserSubscriptions} from "../../../../../Hooks/apiHooks/payments/useListUserSubscriptions";


const RatingField = ({ name }) => {
    const { setFieldValue, values } = useFormikContext();

    const handleChange = (value) => {
        setFieldValue(name, value);
    };

    return (
        <div className="rating rating-sm">
            {[1, 2, 3, 4, 5].map((num) => (
                <Field
                    key={num}
                    type="radio"
                    name={name}
                    value={num}
                    checked={values[name] === num}
                    onChange={() => handleChange(num)}
                    className="mask mask-star-2 bg-yellow-400 dark:bg-yellow-500 text-yellow-400 dark:text-yellow-500"
                />
            ))}
        </div>
    );
};

RatingField.propTypes = {
    name: PropTypes.string
}


const FeedbackModal = ({ disabledState }) => {
    const initialFocusRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const isModalOpen = searchParams.get('feedback') === 'open';
    const isModalOpenParam = searchParams.get('feedback') === 'open';

    const { feedbackCreateVF, createFeedback, feedbackCreateApiState } = useCreateFeedback({ vfExists: true });
    const {
        listUserSubscriptions,
        userSubscriptionsListingResponse,
    } = useListUserSubscriptions({ vfExists: true });

    const [initialValues, setInitialValues] = useState({
        rating: 5,
        comment: 'hi',
        product: ''
    });

    useEffect(() => {
        const dialogElement = document.getElementById('navFeedbackModal');
        if (isModalOpenParam) {
            dialogElement?.showModal();
            initialFocusRef.current?.focus();
        } else {
            dialogElement?.close();
        }
    }, [isModalOpenParam]);


    useEffect(() => {
        const dialogElement = document.getElementById('navFeedbackModal');

        if ((feedbackCreateApiState === 'succeeded' || feedbackCreateApiState === 'failed') && !feedbackCreateVF) {
            dialogElement?.close();
            setSearchParams({ 'feedback': 'close' });
        }
    }, [feedbackCreateApiState, feedbackCreateVF, setSearchParams]);

    const handleOpenModal = (e) => {
        if (!disabledState) {
            e.preventDefault();
            const dialogElement = document.getElementById('navFeedbackModal');
            dialogElement.showModal();
            setSearchParams({ 'feedback': 'open' });
        }
    };

    const handleCloseModal = () => {
        const dialogElement = document.getElementById('navFeedbackModal');
        if (dialogElement) {
            dialogElement.close();
        }
        setSearchParams({ 'feedback': 'close' });
    };

    const handleFeedbackSubmit = (values, { setSubmitting }) => {
        const feedbackValues = {
            rating: values?.rating,
            product: values.product?.name,
            comment: values.comment,
        };

        createFeedback(feedbackValues);
        setSubmitting(false);
    };

    useEffect(() => {
        if (!userSubscriptionsListingResponse) {
            listUserSubscriptions();
        } else {
            const defaultProduct = userSubscriptionsListingResponse.find(prod => prod.product_name === 'TaskGlide');
            setInitialValues({
                rating: 5,
                comment: '',
                product: defaultProduct ? { id: defaultProduct.price_id, name: defaultProduct.product_name } : ''
            });
        }
    }, [userSubscriptionsListingResponse]);

    return (
        <>
            <button
                type="button"
                disabled={disabledState}
                className="standard-button space-x-1.5"
                onClick={handleOpenModal}
            >
                <IoIosPaperPlane className="h-4 sm:h-3.5 w-4 sm:w-3.5 shrink-0" aria-hidden="true"/>
                <p className="text-xs">Leave Feedback</p>
            </button>
            <dialog
                id="navFeedbackModal"
                className={clsx("modal-bg", { modal: isModalOpen })}
                onClose={handleCloseModal}
            >
                {initialValues.product && (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFeedbackSubmit}
                    >
                        {({ isSubmitting, setFieldValue }) => (
                            <Form className="modal-wrapper space-y-3 text-sm">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-2 sm:text-left">
                                        <h3 className="text-base font-semibold leading-6 text-base-content">
                                            Leave Feedback
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-base-content/70">
                                                What do you think of our products? We would love to hear your feedback! This will help us improve our products and services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <p className="font-medium">Rating</p>
                                    <RatingField name="rating"/>
                                </div>
                                <div className="relative space-y-1">
                                    <p className="font-medium">Product</p>
                                    <SingleSelect
                                        name="product"
                                        list={userSubscriptionsListingResponse?.map(subscription => ({id: subscription.price_id, name: subscription.product_name}))}
                                        disabled={userSubscriptionsListingResponse?.length === 1}
                                        placeholder="Product being reviewed"
                                    />
                                </div>
                                <div className="relative space-y-1">
                                    <p className="font-medium">Feedback</p>
                                    <Field
                                        as="textarea"
                                        name="comment"
                                        className="m-0 resize-none h-[calc(100dvh-600px)] overflow-y-auto appearance-none input-field"
                                        placeholder="Your feedback here..."
                                    />
                                </div>
                                {/*<div className="relative space-y-1">*/}
                                {/*    <p className="font-medium">Attachments</p>*/}
                                {/*    <label className="flex items-center justify-center space-x-2 w-full h-full rounded-md border border-dashed border-base-content/30 p-6 text-sm text-base-content/70 hover:text-base-content relative">*/}
                                {/*        <span className="sr-only">Upload a file</span>*/}
                                {/*        <input*/}
                                {/*            type="file"*/}
                                {/*            name="attachments"*/}
                                {/*            className="w-0 text-base-content/70 file:py-2 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-base-100/70 file:text-base-content/90 hover:sm:file:text-base-content hover:sm:file:bg-base-100 file:ring-1 file:ring-neutral-focus file:ring-inset focus:outline-0 file:active:scale-95 absolute hidden group-disabled:hover:sm:cursor-not-allowed file:cursor-pointer"*/}
                                {/*            onChange={(event) => {*/}
                                {/*                setFieldValue("attachments", event.currentTarget.files[0]);*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*        <BsPlusCircleDotted className="size-5 shrink-0"/>*/}
                                {/*        <p>Upload a file</p>*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                                <div className="flex items-center justify-end gap-x-3 pt-1">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const dialogElement = document.getElementById('navFeedbackModal');
                                            if (dialogElement) {
                                                dialogElement.close();
                                            }
                                        }}
                                        className="standard-button"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        ref={initialFocusRef}
                                        disabled={isSubmitting || disabledState}
                                        className="primary-button"
                                    >
                                        <CgSpinner
                                            className={clsx("absolute inset-0 m-auto animate-spin h-5 w-5", isSubmitting ? "" : "hidden")}
                                            aria-hidden="true"
                                        />
                                        <p className={isSubmitting ? "invisible" : ""}>
                                            Submit
                                        </p>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
                <form method="dialog" className="modal-backdrop">
                    <button
                        tabIndex={-1}
                        className="cursor-default focus-visible:outline-0 focus-visible:ring-0"
                        onClick={() => {
                            const dialogElement = document.getElementById('navFeedbackModal');
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >close
                    </button>
                </form>
            </dialog>
        </>
    );
};

FeedbackModal.propTypes = {
    disabledState: PropTypes.bool,
}

export default FeedbackModal;
