import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import * as yup from "yup";

import SettingsNav from "../SettingsNav";
import Nav from "../../Shell/Nav/Nav";
import {useGetUserPreferences} from "../../../../Hooks/apiHooks/users/useGetUserPreferences";
import {useUpdateUserPreferences} from "../../../../Hooks/apiHooks/users/useUpdateUserPreferences";
import SingleSelect from "../../../../Components/Selects/SingleSelect";
import PreferencesSettingsSkeleton from "./PreferencesSettingsSkeleton";
import {languageList} from "../../Registration/Forms/PreferencesForm/PreferencesForm";
import {useAlert} from "../../../../Hooks/frontendHooks/useAlert";


const preferenceValidationSchema = yup.object().shape({
    alerts: yup.boolean(),
    offers: yup.boolean(),
    updates: yup.boolean(),
    language: yup.object().shape({
        id: yup.number().required("Please choose a language"),
        name: yup.string().required("Please choose a language"),
    }),
});

const PreferenceSettings = () => {
    const [editingPreferences, setEditingPreferences] = useState(false);
    const { alert, alertKey } = useAlert();

    // User PreferenceSettings
    const {getUserPreferences, userPreferencesRetrievalApiState, userPreferencesRetrievalVF, userPreferencesRetrievalResponse} = useGetUserPreferences({vfExists: true});
    const {updateUserPreferences, userPreferencesUpdateApiState, userPreferencesUpdateVF} = useUpdateUserPreferences({vfExists: true, refreshAndReset: true});

    // Formik Initial Values
    const preferencesInitialValues = {
        alerts: userPreferencesRetrievalResponse?.alerts || false,
        offers: userPreferencesRetrievalResponse?.offers || false,
        updates: userPreferencesRetrievalResponse?.updates || false,
        language: languageList?.find(lang => lang.name === userPreferencesRetrievalResponse?.preferred_language) || { id: 0, name: '' },
    };

    const [preferencesPrevValues, setPreferencesPrevValues] = useState({});

    // Formik Submit
    const preferencesOnSubmit = (values, { setSubmitting }) => {
        updateUserPreferences(values);
        setSubmitting(false);
    };

    useEffect(() => {
        if (userPreferencesRetrievalApiState === 'idle') {
            getUserPreferences();
        }
    }, [userPreferencesRetrievalApiState]);

    return (
        <>
            <Nav/>
            <SettingsNav/>

            {/* AccountSettings forms */}
            {(userPreferencesRetrievalVF || userPreferencesUpdateVF) ? <PreferencesSettingsSkeleton />  : <main className="bg-base-100 overflow-y-auto h-[calc(100dvh-112px)] scrollbar">
                <div className="grid grid-cols-1 gap-x-20 gap-y-6 py-14 md:grid-cols-9 w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl" >
                    <div className="col-span-full md:col-span-4">
                        <h2 className="text-base font-semibold leading-7 text-base-content">Notifications and other preferences</h2>
                        <p className="mt-1 text-sm leading-6 text-base-content/70">
                            Choose which communications you&apos;d like to receive and how. The language you choose will be applied to the super-intelligent applications you use.
                        </p>
                    </div>

                    <Formik
                        initialValues={preferencesInitialValues}
                        onSubmit={preferencesOnSubmit}
                        validationSchema={preferenceValidationSchema}
                    >
                        {({errors, touched, isSubmitting, isValid, values, setFieldValue, handleBlur, validateForm, resetForm}) => {
                            const handlePreferencesEditToggle = () => {
                                setPreferencesPrevValues(values);
                                setEditingPreferences(!editingPreferences);
                            };

                            const handlePreferencesCancel = () => {
                                Object.keys(preferencesPrevValues).forEach(key => {
                                    setFieldValue(key, preferencesPrevValues[key]);
                                });
                                resetForm({}); // Clear errors and touched fields
                                setEditingPreferences(false);
                            };

                            return (
                                <Form className="relative md:col-start-5 md:col-span-5 lg:col-start-6">
                                    <div className="grid grid-cols-1 gap-y-5 sm:max-w-none sm:grid-cols-4">
                                        <fieldset className="col-span-full">
                                            <div className="mt-6 space-y-6">

                                                {/* Updates */}
                                                <div className="relative flex gap-x-4">
                                                    <div className="flex h-6 items-center">
                                                        <Field
                                                            name="alerts"
                                                            type="checkbox"
                                                            className="standard-checkbox"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="alerts" className="text-base-content">
                                                            Account Alerts
                                                        </label>
                                                        <p className="text-base-content/70">Get notified when we release a new feature or update.</p>
                                                    </div>
                                                </div>

                                                {/* Newsletters */}
                                                <div className="relative flex gap-x-4">
                                                    <div className="flex h-6 items-center">
                                                        <Field
                                                            name="offers"
                                                            type="checkbox"
                                                            className="standard-checkbox"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="offers" className="text-base-content">
                                                            Promotional Offers
                                                        </label>
                                                        <p className="text-base-content/70">Stay informed with our latest news and articles.</p>
                                                    </div>
                                                </div>

                                                {/* Promotional Offers */}
                                                <div className="relative flex gap-x-4">
                                                    <div className="flex h-6 items-center">
                                                        <Field
                                                            name="updates"
                                                            type="checkbox"
                                                            className="standard-checkbox"
                                                        />
                                                    </div>
                                                    <div className="text-sm leading-6">
                                                        <label htmlFor="updates" className="text-base-content">
                                                            Updates
                                                        </label>
                                                        <p className="text-base-content/70">Receive emails about special promotions and discounts.</p>
                                                    </div>
                                                </div>

                                                <div className="pt-4 group relative col-span-full grid grid-cols-7 items-center">
                                                    <label htmlFor="language" className="col-span-3 text-sm">
                                                        Preferred Language
                                                    </label>
                                                    <SingleSelect
                                                        name="language"
                                                        list={languageList}
                                                        wrapperClass="col-span-4"
                                                        placeholder="English"
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className="mt-6 flex items-center justify-end gap-x-3">
                                        <button
                                            type="submit"
                                            disabled={isSubmitting || !isValid || (!values.language || values.language.name === '') || userPreferencesUpdateVF}
                                            className="primary-button"
                                        >
                                            {userPreferencesUpdateVF ? "Saving..." : "Save"}
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </main>}
        </>
    );
};

export default PreferenceSettings;
