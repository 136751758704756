import React, {useState} from "react";
import {AnimatePresence} from "framer-motion";
import {Field, Form, Formik} from "formik";
import {CgSpinner} from "react-icons/cg";
import clsx from "clsx";
import {ImPriceTags} from "react-icons/im";
import {RxCross2} from "react-icons/rx";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";

import {resetPromoCodeValidation} from "../../../../../../api/features/payments/validatePromoCodeSlice";
import {useValidatePromoCode} from "../../../../../../Hooks/apiHooks/payments/useValidatePromoCode";
import SingleSelect from "../../../../../../Components/Selects/SingleSelect";
import {useCreateSubscription} from "../../../../../../Hooks/apiHooks/payments/useCreateSubscription";
import {calculateDiscountAmount} from "../SubscriptionManagement";
import NewSubscriptionModal from "./NewSubscriptionModal";
import Alerts from "../../../../../../Components/Alerts/Alerts";
import {useAlert} from "../../../../../../Hooks/frontendHooks/useAlert";


const NewSubscriptionForm = (
    { subscriptionPlanHook, subscriptionStatus, initialValues, paymentList }
) => {
    const dispatch = useDispatch();
    const [promotionCodes, setPromotionCodes] = useState({});
    const { alert, alertKey } = useAlert();

    const {
        validatePromoCode,
        promoCodeValidationVF,
        promoCodeValidationResponse,
        promoCodeValidationApiState
    } = useValidatePromoCode({ vfExists: true });
    const { createSubscription } = useCreateSubscription({ vfExists: true });
    const { subscriptionPlanRetrievalResponse } = subscriptionPlanHook({ vfExists: true });

    const handlePromoCodeChange = (event) => {
        const { id, value } = event.target;
        setPromotionCodes((prevCodes) => ({
            ...prevCodes,
            [id]: value
        }));
    };

    const resetPromoCode = () => {
        setPromotionCodes((prevCodes) => ({
            ...prevCodes,
            [subscriptionPlanRetrievalResponse?.price_id]: ''
        }));
    };

    const applyPromoCode = () => {
        validatePromoCode({ promotion_code: promotionCodes[subscriptionPlanRetrievalResponse?.price_id] });
        resetPromoCode();
    };

    const discountAmount = calculateDiscountAmount(
        promoCodeValidationResponse?.discount_type,
        promoCodeValidationResponse?.discount_amount,
        subscriptionPlanRetrievalResponse?.amount
    );

    const onNewSubscriptionSubmit = (values) => {
        createSubscription({
            price_ids: [subscriptionPlanRetrievalResponse?.price_id],
            payment_method_id: values?.payment_method?.id,
            promotion_code: promoCodeValidationResponse?.promotion_code
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onNewSubscriptionSubmit}
            // validationSchema={preferenceValidationSchema}
        >
            {({errors, touched, isSubmitting, isValid, values, setFieldValue, handleBlur, validateForm, resetForm}) => (
                <Form className="space-y-4" >
                    <div className="grid grid-cols-5 gap-2">
                        <p className="text-sm font-medium text-base-content/70 col-span-full sm:col-span-2">
                            Payment Method
                        </p>
                        <div className="flex items-center gap-x-4 col-span-full md:col-span-3">
                            <AnimatePresence>
                                <div
                                    className="relative w-full">
                                    <SingleSelect
                                        name="payment_method"
                                        list={paymentList}
                                        placeholder="Select"
                                        disabled={subscriptionStatus === 'Not available'}
                                    />
                                </div>
                            </AnimatePresence>
                        </div>
                    </div>
                    <div className="col-span-full">
                        <div className="flex items-center w-full space-x-6">
                            <div className="relative w-full items-start grid grid-cols-5 gap-2">
                                <p className="text-sm font-medium col-span-2 text-base-content/70">
                                    Promo Code
                                </p>
                                <div className="col-span-full md:col-span-3 space-y-4">
                                    <div className="flex items-center gap-x-4">
                                        <Field
                                            type="text"
                                            id={subscriptionPlanRetrievalResponse?.price_id}
                                            name="promotion_code"
                                            className="input-field"
                                            placeholder="DOVISE2024"
                                            value={promotionCodes[subscriptionPlanRetrievalResponse?.price_id] || ''}
                                            onChange={handlePromoCodeChange}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    event.preventDefault();
                                                    applyPromoCode();
                                                }
                                            }}
                                            disabled={subscriptionStatus === 'Not available'}
                                        />
                                        <button
                                            type="button"
                                            onClick={applyPromoCode}
                                            disabled={!promotionCodes[subscriptionPlanRetrievalResponse?.price_id]}
                                            className="neutral-button"
                                        >
                                            <CgSpinner
                                                className={clsx(
                                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                                    promoCodeValidationVF ? "" : "hidden"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <p className={promoCodeValidationVF ? "invisible" : ""}>
                                                Apply
                                            </p>
                                        </button>
                                    </div>
                                    {promoCodeValidationResponse && <div className="uppercase flex items-center space-x-1.5 bg-base-200 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-md shadow p-2 w-fit text-sm leading-none">
                                        <ImPriceTags className="w-4 h-4 text-base-content"/>
                                        <p>{promoCodeValidationResponse?.promotion_code}</p>
                                        <button
                                            type="button"
                                            onClick={() => dispatch(resetPromoCodeValidation())}
                                            className="flex items-center justify-center text-base-content relative group/remove-promo"
                                        >
                                            <RxCross2 className="w-4 h-4"/>
                                            <div className="right-tooltips left-8 group-hover/remove-promo:sm:block">Remove</div>
                                        </button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-2 col-start-2 space-y-2">
                        <div className="flex items-center justify-between text-base-content/70">
                            <p>Subtotal</p>
                            <div className="flex items-center space-x-0.5">
                                <p>${subscriptionPlanRetrievalResponse?.amount}</p>
                                <p>/month</p>
                            </div>
                        </div>
                        {promoCodeValidationResponse && (
                            <div className="flex items-center justify-between text-base-content/70">
                                <p>Discount</p>
                                <div className="flex items-center space-x-0.5">
                                <p>-${discountAmount}</p>
                                    <p>/month</p>
                                </div>
                            </div>
                        )}
                        <div className="flex items-center justify-between font-medium">
                            <p>Total</p>
                            <div className="flex items-center space-x-0.5">
                                <p>${subscriptionPlanRetrievalResponse?.amount - discountAmount}</p>
                                <p>/month</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end col-span-full">
                        <NewSubscriptionModal
                            disabledState={!isValid || isSubmitting || subscriptionStatus === 'Not available'}
                            product_name={subscriptionPlanRetrievalResponse?.product_name}
                            subscriptionCreationHook={useCreateSubscription}
                        />
                    </div>
                    {promoCodeValidationApiState === 'failed' && <Alerts
                        description={alert.description}
                        type={alert.type}
                        keyValue={alertKey} />}
                </Form>
            )}
        </Formik>
    );
};

NewSubscriptionForm.propTypes = {
    subscriptionPlanHook: PropTypes.func,
    subscriptionStatus: PropTypes.string,
    initialValues: PropTypes.object,
    paymentList: PropTypes.array,
};

export default NewSubscriptionForm;
