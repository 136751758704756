import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {CgSpinner} from "react-icons/cg";
import {Field, Form, Formik} from "formik";
import {useSearchParams} from "react-router-dom";
import PropTypes from "prop-types";

import {useCreateCalendarEvent} from "../../../../../Hooks/apiHooks/superIntelligence/memoMinder/calendar/useCreateCalendarEvent";
import DatePicker from "../../../../../Components/DatePicker/DatePicker";
import {useListCalendarDates} from "../../../../../Hooks/apiHooks/superIntelligence/memoMinder/calendar/useListCalendarDates";
import TimePicker from "../../../../../Components/TimePicker/TimePicker";
import {isDateDisabled, isTimeDisabled, roundTimeToNearest15Minutes, formatDateToISO, formatTime12Hour, formatTimeTo24HourFormat} from "../../../../../reusableFxns/dateTimeFxns";


const MemoMinderEventCreationModal = () => {
    const {
        createCalendarEvent,
        calendarEventCreateVF,
        calendarEventCreateApiState,
    } = useCreateCalendarEvent({ vfExists: true });
    const {
        listCalendarDates,
        calendarDatesListingResponse,
    } = useListCalendarDates();

    const now = roundTimeToNearest15Minutes(new Date());
    const today = formatDateToISO(new Date());
    const [allDay, setAllDay] = useState(false);

    const initialFocusRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const isModalOpen = searchParams.get('calendar-event-modal') === 'open';
    const isModalOpenParam = searchParams.get('calendar-event-modal') === 'open';

    useEffect(() => {
        const dialogElement = document.getElementById('eventCreationModal');
        if (isModalOpenParam) {
            dialogElement?.showModal();
            initialFocusRef.current?.focus();
            !calendarDatesListingResponse && listCalendarDates(null, 100);
        } else {
            dialogElement?.close();
        }
    }, [isModalOpenParam, calendarDatesListingResponse]);

    useEffect(() => {
        const dialogElement = document.getElementById('eventCreationModal');

        if ((calendarEventCreateApiState === 'succeeded' || calendarEventCreateApiState === 'failed') && !calendarEventCreateVF) {
            dialogElement?.close();
            setSearchParams({ 'calendar-event-modal': 'close' });
        }
    }, [calendarEventCreateApiState, calendarEventCreateVF, searchParams]);

    const handleOpenModal = (e) => {
        if (!calendarEventCreateVF) {
            e.preventDefault();
            const dialogElement = document.getElementById('eventCreationModal');
            dialogElement.showModal();
            setSearchParams({ 'calendar-event-modal': 'open' });
        }
    };

    const handleCloseModal = () => {
        const dialogElement = document.getElementById('eventCreationModal');
        if (dialogElement) {
            dialogElement.close();
        }
        setSearchParams({ 'calendar-event-modal': 'close' });
    };

    const handleEventCreation = (values, { setSubmitting }) => {
        const eventValues = {
            title: values?.title,
            description: values?.description,
            all_day: values?.allDay,
            start_date: values?.startDate,
            end_date: values?.endDate,
            start_time: formatTimeTo24HourFormat(values?.startTime),
            end_time: formatTimeTo24HourFormat(values?.endTime),
        };

        createCalendarEvent(eventValues);
        setSubmitting(false);
    };

    const MemoMinderEventForm = ({ values, setFieldValue, isSubmitting }) => {
        useEffect(() => {
            if (values.startDate && values.startTime) {
                const startDateTime = new Date(`${values.startDate} ${values.startTime}`);
                const endDateTime = new Date(`${values.endDate} ${values.endTime}`);
                if (endDateTime <= startDateTime) {
                    const newEndDateTime = new Date(startDateTime.getTime() + 15 * 60000);
                    setFieldValue('endDate', formatDateToISO(newEndDateTime));
                    setFieldValue('endTime', formatTime12Hour(newEndDateTime));
                }
            }
        }, [values.startDate, values.startTime, values.endDate, values.endTime, setFieldValue]);

        return (
            <Form className="modal-wrapper overflow-visible text-sm">
                <div className="space-y-2">
                    <Field
                        name="title"
                        className="empty-input-field placeholder:text-lg placeholder:font-bold"
                        placeholder="Title"
                    />
                    <Field
                        as="textarea"
                        name="description"
                        className="m-0 resize-none overflow-y-auto appearance-none max-h-[calc(100dvh-650px)] min-h-12 empty-input-field auto-hide-scrollbar"
                        placeholder="Description"
                    />
                    <div className="px-3 grid grid-cols-6 gap-y-3">
                        <p className="text-base-content/70 col-span-1">All day:</p>
                        <Field
                            name="allDay"
                            type="checkbox"
                            className="standard-checkbox col-span-5"
                            onChange={(e) => {
                                setAllDay(e.target.checked);
                                setFieldValue('allDay', e.target.checked);
                                if (e.target.checked) {
                                    setFieldValue('startTime', values.startTime);
                                    setFieldValue('endTime', values.startTime);
                                } else {
                                    setFieldValue('startTime', formatTime12Hour(now));
                                    setFieldValue('endTime', formatTime12Hour(new Date(now.getTime() + 15 * 60000)));
                                }
                            }}
                        />
                        <p className="text-base-content/70 col-span-1">Starts:</p>
                        <div className="flex items-center gap-x-3 col-span-5">
                            <DatePicker
                                calendarDates={calendarDatesListingResponse}
                                selectedDate={values.startDate}
                                setSelectedDate={(date) => setFieldValue('startDate', date)}
                                dateButtonClassName="rounded-md"
                                isDateDisabled={(date) => isDateDisabled(date, today)}
                            />
                            <TimePicker
                                selectedTime={values.startTime}
                                setSelectedTime={(time) => setFieldValue('startTime', time)}
                                timeButtonClassName="rounded-md min-w-20"
                                disabled={allDay}
                                isTimeDisabled={(time) => isTimeDisabled(time, values.startDate, formatTime12Hour(now), today)}
                            />
                        </div>
                        <p className="text-base-content/70 col-span-1">Ends:</p>
                        <div className="flex items-center gap-x-3 col-span-5">
                            <DatePicker
                                calendarDates={calendarDatesListingResponse}
                                selectedDate={values.endDate}
                                setSelectedDate={(date) => setFieldValue('endDate', date)}
                                dateButtonClassName="rounded-md"
                                isDateDisabled={(date) => isDateDisabled(date, values.startDate)}
                            />
                            <TimePicker
                                selectedTime={values.endTime}
                                setSelectedTime={(time) => setFieldValue('endTime', time)}
                                timeButtonClassName="rounded-md min-w-20"
                                disabled={allDay}
                                isTimeDisabled={(time) => isTimeDisabled(time, values.endDate, values.startTime, values.startDate)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-end gap-x-3 mt-3">
                    <button
                        type="button"
                        onClick={() => {
                            const dialogElement = document.getElementById('eventCreationModal');
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                        className="standard-button"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        ref={initialFocusRef}
                        disabled={isSubmitting || calendarEventCreateVF}
                        className="primary-button"
                    >
                        <CgSpinner
                            className={clsx("absolute inset-0 m-auto animate-spin h-5 w-5", isSubmitting ? "" : "hidden")}
                            aria-hidden="true"
                        />
                        <p className={isSubmitting ? "invisible" : ""}>
                            Add event
                        </p>
                    </button>
                </div>
            </Form>
        );
    };

    MemoMinderEventForm.propTypes = {
        values: PropTypes.object,
        setFieldValue: PropTypes.func,
        isSubmitting: PropTypes.bool
    }

    return (
        <>
            <button
                className="standard-button"
                onClick={handleOpenModal}
                type="button"
            >
                Add event
            </button>
            <dialog
                id="eventCreationModal"
                className={clsx("modal-bg", {modal: isModalOpen})}
                onClose={handleCloseModal}
            >
                <Formik
                    initialValues={{
                        title: '',
                        description: '',
                        allDay: false,
                        startDate: formatDateToISO(new Date()),
                        endDate: formatDateToISO(new Date()),
                        startTime: formatTime12Hour(now),
                        endTime: formatTime12Hour(new Date(now.getTime() + 15 * 60000)),
                    }}
                    onSubmit={handleEventCreation}
                    component={MemoMinderEventForm}
                >
                    <form method="dialog" className="modal-backdrop">
                        <button
                            tabIndex={-1}
                            className="cursor-default focus-visible:outline-0 focus-visible:ring-0"
                            onClick={() => {
                                const dialogElement = document.getElementById('eventCreationModal');
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                        >close
                        </button>
                    </form>
                </Formik>
                <form method="dialog" className="modal-backdrop">
                    <button
                        tabIndex={-1}
                        className="cursor-default focus-visible:outline-0 focus-visible:ring-0"
                        onClick={() => {
                            const dialogElement = document.getElementById('eventCreationModal');
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >close
                    </button>
                </form>
            </dialog>
        </>
    )
};

export default MemoMinderEventCreationModal;
