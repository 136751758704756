import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearParseDocumentsVF, documentsParsePOST, resetParseDocuments} from "../../../../../api/features/superIntelligence/taskGlide/taskGlide/parseDocumentsSlice";


export const useParseDocuments = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();
    const {
        parseDocumentsVF,
        parseDocumentsApiState,
        parseDocumentsResponse,
        parseDocumentsError,
    } = useSelector(state => state.parseDocumentsReducer);

    const parseDocumentsSuccessVF = parseDocumentsApiState === 'succeeded' && parseDocumentsVF;
    const parseDocumentsFailureVF = parseDocumentsApiState === 'failed' && parseDocumentsVF;

    const parseDocuments = (documents) => userIsAuthenticated && dispatch(documentsParsePOST({ documents }));

    useEffect(() => {
        if (vfExists) {
            if (parseDocumentsSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearParseDocumentsVF());
                }, 500);

                return () => clearTimeout(timer);
            } else if (parseDocumentsFailureVF) {
                dispatch(setAlert({
                    message: parseDocumentsResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetParseDocuments());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [parseDocumentsApiState, parseDocumentsVF]);

    return {
        parseDocuments,
        parseDocumentsVF,
        parseDocumentsApiState,
        parseDocumentsResponse,
        parseDocumentsError,
        parseDocumentsSuccessVF,
        parseDocumentsFailureVF
    };
};
