import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearUserInfoRetrievalVF, userInfoRETRIEVE} from "../../../api/features/users/getUserInfoSlice";
import {useAuthentication} from "./useAuthentication";


export const useGetUserInfo = ({vfExists=false} = {}) => {
    const dispatch = useDispatch();

    // Authentication
    const {userIsAuthenticated} = useAuthentication();

    // User info
    const {
        userInfoRetrievalApiState,
        userInfoRetrievalVF,
        userInfoRetrievalResponse,
        userInfoRetrievalError,
    } = useSelector(state => state.getUserInfoReducer);

    // API states with visual feedback
    const userInfoRetrievalSuccessVF = userInfoRetrievalApiState === 'succeeded' && userInfoRetrievalVF;

    const getUserInfo = () => userIsAuthenticated && dispatch(userInfoRETRIEVE());

    useEffect(() => {
        if (vfExists) {
            if (userInfoRetrievalSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearUserInfoRetrievalVF());
                }, 1000);
                return () => clearTimeout(timer);
            }
        }
    }, [userInfoRetrievalApiState, userInfoRetrievalVF]);

    return {
        getUserInfo,
        userInfoRetrievalApiState,
        userInfoRetrievalResponse,
        userInfoRetrievalError,
        userInfoRetrievalVF,
        userInfoRetrievalSuccessVF,
    };
};
