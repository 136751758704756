import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const taskGlideMessageDELETE = createAsyncThunk(
    'taskGlide/interaction/message/delete',
    async ({ interaction_id, is_user_message }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'DELETE',
                    path: `${process.env.REACT_APP_TASKGLIDE_DELETE_MESSAGE_PATH}${interaction_id}/${is_user_message}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    taskGlideMessageDeletionVF: false,
    taskGlideMessageDeletionApiState: 'idle',
    taskGlideMessageDeletionResponse: null,
    taskGlideMessageDeletionError: null,
};

export const deleteTaskGlideMessageSlice = createSlice({
    name: 'deleteTaskGlideMessage',
    initialState: INITIAL_STATE,
    reducers: {
        clearTaskGlideMessageDeletionVF: (state) => {
            state.taskGlideMessageDeletionVF = false;
        },
        resetTaskGlideMessageDeletionState: (state) => {
            state.taskGlideMessageDeletionVF = false;
            state.taskGlideMessageDeletionApiState = 'idle';
            state.taskGlideMessageDeletionResponse = null;
            state.taskGlideMessageDeletionError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(taskGlideMessageDELETE.pending, (state) => {
                state.taskGlideMessageDeletionApiState = 'loading';
                state.taskGlideMessageDeletionVF = true;
            })
            .addCase(taskGlideMessageDELETE.fulfilled, (state, action) => {
                state.taskGlideMessageDeletionApiState = 'succeeded';
                state.taskGlideMessageDeletionResponse = action.payload;
                state.taskGlideMessageDeletionVF = true;
                state.taskGlideMessageDeletionError = null;
            })
            .addCase(taskGlideMessageDELETE.rejected, (state, action) => {
                state.taskGlideMessageDeletionApiState = 'failed';
                state.taskGlideMessageDeletionError = action?.payload;
                state.taskGlideMessageDeletionVF = true;
                state.taskGlideMessageDeletionResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTaskGlideMessageDeletionVF,
    resetTaskGlideMessageDeletionState
} = deleteTaskGlideMessageSlice.actions;

export default deleteTaskGlideMessageSlice.reducer;
