import {Link, useLocation, useSearchParams} from "react-router-dom";
import {Dialog, Disclosure, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import clsx from "clsx";
import {ChevronRightIcon} from "@heroicons/react/20/solid";

import {sideBarItems} from "./sideBarItems";
import {companyLogo} from "../../../Design/companyLogo";

export const ShellMobileSideBar = () => {
    const location = useLocation();
    const path = location.pathname;
    const [searchParams, setSearchParams] = useSearchParams();

    const openParam = searchParams.get('menu');
    const isSidebarOpen = openParam === 'open';

    const handleMenuClick = () => {
        setSearchParams({ 'menu': isSidebarOpen ? 'close' : 'open' });
    };

    return (
        <Transition.Root show={isSidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50 lg:hidden" onClose={handleMenuClick}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-250"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-250"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-base-100 px-6 pb-2 ring-1 ring-base-content/10">
                                <div className="flex h-16 shrink-0 items-center justify-between">
                                    <img
                                        className="h-8 w-auto"
                                        src={companyLogo}
                                        alt="Your Company"
                                    />
                                    <button
                                        type="button"
                                        className="rounded-full p-2 -m-2 hover:bg-neutral-focus/70 text-base-content/70 ring-0 outline-none"
                                        onClick={handleMenuClick}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                <nav className="flex flex-1 flex-col">
                                    <ul className="flex flex-1 flex-col gap-y-7">
                                        <li>
                                            <ul className="-mx-2 space-y-1">
                                                {sideBarItems.map((item) => (
                                                    <li key={item.name} >
                                                        {!item.children ? (
                                                            <Link
                                                                to={item.href}
                                                                onClick={handleMenuClick}
                                                                className={clsx(
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-base-content'
                                                                )}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        ) : (
                                                            <Disclosure as="div" >
                                                                {({ open }) => (
                                                                    <>
                                                                        <Disclosure.Button
                                                                            className={clsx(
                                                                                path.includes('task-glide') ? 'text-primary-focus bg-base-100' : "hover:sm:bg-base-200",
                                                                                'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-base-content'
                                                                            )}
                                                                        >
                                                                            {item.name}
                                                                            <ChevronRightIcon
                                                                                className={clsx(
                                                                                    open ? 'rotate-90 text-base-content/70' : 'text-base-content',
                                                                                    'ml-auto h-5 w-5 shrink-0'
                                                                                )}
                                                                                aria-hidden="true"
                                                                            />
                                                                        </Disclosure.Button>
                                                                        <Disclosure.Panel as="ul" className="mt-0.5 px-2 space-y-1">
                                                                            {item.children.map((subItem) => (
                                                                                <li key={subItem.name}>
                                                                                    <Link
                                                                                        to={subItem.href}
                                                                                        className={clsx(
                                                                                            path === subItem.href ? 'bg-base-200' : 'hover:sm:bg-base-200',
                                                                                            'flex items-center w-full text-left rounded-md gap-x-3 py-2 pr-2 pl-6 font-medium text-sm leading-6 text-base-content'
                                                                                        )}
                                                                                    >
                                                                                        <subItem.IconComponent className={clsx(
                                                                                            "h-5 w-5 shrink-0",
                                                                                            path === subItem.href ? 'text-primary' : "text-neutral-focus"
                                                                                        )} aria-hidden="true" />
                                                                                        {subItem.name}
                                                                                    </Link>
                                                                                </li>
                                                                            ))}
                                                                        </Disclosure.Panel>
                                                                    </>
                                                                )}
                                                            </Disclosure>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
};

ShellMobileSideBar.propTypes = {};
