import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const allTgConversationsLIST = createAsyncThunk(
    'taskGlide/conversation/list',
    async (params, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_LIST_ALL_CONVOS_PATH}${params}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    tgConversationsListingVF: false,
    tgConversationsListingApiState: 'idle',
    tgConversationsListingResponse: null,
    tgConversationsListingError: null,
};

export const listAllTgConversationsSlice = createSlice({
    name: 'tgConversationsList',
    initialState: INITIAL_STATE,
    reducers: {
        clearAllTgConversationsListingVF: (state) => {
            state.tgConversationsListingVF = false;
        },
        resetAllTgConversationsListing: (state) => {
            state.tgConversationsListingVF = false;
            state.tgConversationsListingApiState = 'idle';
            state.tgConversationsListingResponse = null;
            state.tgConversationsListingError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(allTgConversationsLIST.pending, (state) => {
                state.tgConversationsListingApiState = 'loading';
                state.tgConversationsListingVF = true;
            })
            .addCase(allTgConversationsLIST.fulfilled, (state, action) => {
                state.tgConversationsListingApiState = 'succeeded';
                state.tgConversationsListingResponse = action.payload;
                state.tgConversationsListingVF = true;
                state.tgConversationsListingError = null;
            })
            .addCase(allTgConversationsLIST.rejected, (state, action) => {
                state.tgConversationsListingApiState = 'failed';
                state.tgConversationsListingError = action?.payload;
                state.tgConversationsListingVF = true;
                state.tgConversationsListingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearAllTgConversationsListingVF, resetAllTgConversationsListing
} = listAllTgConversationsSlice.actions;

export default listAllTgConversationsSlice.reducer;
