import React from "react";
import {Bars3Icon} from "@heroicons/react/24/outline";
import {HiOutlineDotsHorizontal, HiOutlineRefresh, HiReply} from "react-icons/hi";
import {LuFiles, LuPencil} from "react-icons/lu";
import {CgMenuGridO} from "react-icons/cg";
import {FaRegStopCircle} from "react-icons/fa";
import {PiBatteryChargingFill} from "react-icons/pi";
import {TbAntennaBars5} from "react-icons/tb";

import Markdown from "../../../../Components/Markdown/Markdown";
import PhoneFrame from "../../../../Components/Frames/PhoneFrame";
import {getGlideIcon} from "../../../Auth/Products/TaskGlide/Chat/ChatBox/Messages/glideInfo";


const LandingPhoneFrameDesign = () => {

    return (
        <PhoneFrame>
            <div className="grid grid-cols-3 content-center justify-items-stretch w-full text-2xs font-semibold text-base-content py-2.5 px-5 bg-base-100" >
                <p className="flex items-center justify-self-start" >10:32pm</p>
                <div className="py-2 w-24 bg-base-content rounded-xl justify-self-center" />
                <div className="flex items-center space-x-1 justify-self-end" >
                    <TbAntennaBars5 className="size-5"/>
                    <p className="font-semibold text-xs" >5G</p>
                    <PiBatteryChargingFill className="size-5"/>
                </div>
            </div>
            <div className="border-b border-b-neutral bg-base-100 items-center flex justify-between w-full shrink-0 gap-x-4 px-4 pt-1 pb-2.5 mx-auto max-w-7xl" >
                <div className="w-full">
                        <Bars3Icon className="size-6" aria-hidden="true"/>
                </div>
                <div className="size-7 flex items-center justify-center space-x-1 ring-base-content/30 border-0 text-xs rounded-full p-1 bg-base-content ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark text-base-100 shrink-0">JD</div>
            </div>
            <div className="overflow-hidden px-4 mx-auto bg-base-100 h-[520px]">
                <div className="flex flex-col w-full bg-base-100 z-20 py-4">
                    <div className="h-full space-y-3 py-1">
                        <div className="flex flex-row space-x-2 group/user-message">
                            <div className="h-full">
                                <div className="h-6 w-6 rounded-full flex items-center justify-center text-base-100 text-2xs font-medium bg-gray-600 dark:bg-gray-400">
                                    JD
                                </div>
                            </div>

                            <div className="w-full">
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-row items-end space-x-3 w-fit max-w-full py-0.5">
                                        <span className="leading-none font-medium text-sm">John</span>
                                        <span className="text-3xs text-base-content/70 whitespace-nowrap">12:00pm</span>
                                    </div>
                                    <div className="p-1 group/delete rounded-full bg-base-200/70 group/more-btn focus:outline-none">
                                        <HiOutlineDotsHorizontal className="w-4 h-4"/>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 grid-flow-row gap-y-0.5">
                                    <div>
                                        <Markdown
                                            text="how can i help the environment in nyc and help with decreasing polution?"
                                            className="w-full min-w-full text-base-content"/>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex items-center justify-start overflow-visible space-x-0.5 my-0.5 text-base-content">
                                        <div
                                            className="group/tg-res-copy-btn p-1.5 group/copy rounded-full"
                                        >
                                            <LuFiles className="w-3 h-auto"/>
                                        </div>
                                        <div className="p-1.5 group/reply rounded-full">
                                            <HiReply className="w-3 h-auto"/>
                                        </div>
                                        <div
                                            className="p-1.5 group/reply rounded-full"
                                        >
                                            <LuPencil className="w-3 h-auto"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row space-x-2 group/user-message">
                            <div className="h-full">
                                <div className="h-6 w-6 rounded-full flex items-center justify-center text-base-100 text-2xs font-medium bg-green-600 dark:bg-green-400">
                                    {getGlideIcon("EcoGlide")}
                                </div>
                            </div>

                            <div className="w-full">
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-row items-end space-x-3 w-fit max-w-full py-0.5">
                                        <span className="leading-none font-medium text-sm">EcoGlide</span>
                                        <span className="text-3xs text-base-content/70 whitespace-nowrap">12:01pm</span>
                                    </div>
                                    <div className="p-1 group/delete rounded-full bg-base-200/70 group/more-btn focus:outline-none">
                                        <HiOutlineDotsHorizontal className="w-4 h-4"/>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 grid-flow-row gap-y-0.5">
                                    <div>
                                        <Markdown
                                            contentClass="text-sm prose:text-sm prose-p:text-sm prose-h2:text-sm prose-li:text-sm prose-strong:text-sm leading-6 prose-p:leading-6"
                                            text={`Hey John! That's a fantastic question. Helping the environment and reducing pollution in a bustling city like New York can have a significant impact. Let's break it down step-by-step:
## 1. **Reduce, Reuse, Recycle**

### **Reduce**
- **Minimize Waste**: Be mindful of the waste you genera ●`}
                                            className="w-full min-w-full text-base-content"/>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex items-center justify-start overflow-visible space-x-0.5 my-0.5 text-base-content">
                                        <div
                                            className="group/tg-res-copy-btn p-1.5 group/copy rounded-full"
                                        >
                                            <LuFiles className="w-3 h-auto"/>
                                        </div>
                                        <div className="p-1.5 group/reply rounded-full">
                                            <HiReply className="w-3 h-auto"/>
                                        </div>
                                        <div
                                            className="p-1.5 group/reply rounded-full"
                                        >
                                            <HiOutlineRefresh className="w-3 h-auto"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-4 bg-base-100">
                <div className="flex-1 flex flex-row w-full z-20">
                    <div className="flex h-full flex-1 items-stretch md:flex-col">
                        <div className="rounded-xl w-full bg-base-200 ring-[0.5px] ring-base-content/20 h-12 flex items-center justify-between px-2">
                            <div className="h-fit group flex text-sm rounded-md p-1.5 bg-transparent text-base-content group/menu">
                                <CgMenuGridO className="h-6 w-6" aria-hidden="true"/>
                            </div>
                            <div className="my-0 flex items-center group/submit outline-none disabled:cursor-not-allowed rounded-md h-fit place-self-center bg-transparent p-1.5 disabled:opacity-50 text-base-content">
                                <FaRegStopCircle className="h-6 w-6"/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bottom Actions */}
                <p className="px-3 py-2 mx-0 text-2xs text-center text-base-300 break-all whitespace-nowrap line-clamp-1 overflow-hidden">
                    EcoGlide can make mistakes from time to time.
                </p>
            </div>
        </PhoneFrame>
    )
};

export default LandingPhoneFrameDesign;
