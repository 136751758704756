import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {clearTgSingleConversationDeleteVF, singleTgConversationDELETE} from "../../../../../api/features/superIntelligence/taskGlide/conversations/deleteSingleTgConversationSlice";
import {useListAllTgConversations} from "./useListAllTgConversations";
import {setCurrentTgConversation} from "../../../../../api/features/superIntelligence/taskGlide/frontend/getCurrentTgConversationSlice";
import {useGetCurrentTgConversation} from "../frontend/useGetCurrentTgConversation";


export const useDeleteSingleTgConversation = (
    {vfExists=false, autoUpdate=false} = {}
) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();
    const {
        tgSingleConversationDeleteVF,
        tgSingleConversationDeleteApiState,
        tgSingleConversationDeleteResponse,
        tgSingleConversationDeleteError,
    } = useSelector(state => state.deleteSingleTgConversationReducer);
    const { listAllTgConversations, tgConversationsListingResponse } = useListAllTgConversations();
    const { currentTgConversation } = useGetCurrentTgConversation();

    const [dataIsRefreshed, setDataIsRefreshed] = useState(false);
    const [conversationIdBeingDeleted, setConversationIdBeingDeleted] = useState(null);

    // API states with visual feedback
    const tgConversationDeleteSuccessVF = tgSingleConversationDeleteApiState === 'succeeded' && tgSingleConversationDeleteVF;
    const tgConversationDeleteFailureVF = tgSingleConversationDeleteApiState === 'succeeded' && tgSingleConversationDeleteVF;

    const deleteSingleTGConversation = (conversation_id) => {
        setDataIsRefreshed(false);
        setConversationIdBeingDeleted(conversation_id);
        userIsAuthenticated && dispatch(singleTgConversationDELETE({conversation_id}))
    };

    useEffect(() => {
        if (vfExists && tgConversationDeleteSuccessVF) {
            const timer = setTimeout(() => {
                dispatch(clearTgSingleConversationDeleteVF());
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [tgSingleConversationDeleteApiState, tgSingleConversationDeleteVF]);

    useEffect(() => {
        if (autoUpdate && tgSingleConversationDeleteApiState === 'succeeded' && !dataIsRefreshed) {
            setDataIsRefreshed(true);
            if (tgConversationsListingResponse?.count === 1 || conversationIdBeingDeleted === currentTgConversation?.id) {
                setConversationIdBeingDeleted(null);
                dispatch(setCurrentTgConversation(null));
            }
            listAllTgConversations();
        }
    }, [tgSingleConversationDeleteApiState, tgSingleConversationDeleteVF]);

    return {
        deleteSingleTGConversation,
        tgSingleConversationDeleteVF,
        tgSingleConversationDeleteApiState,
        tgSingleConversationDeleteResponse,
        tgSingleConversationDeleteError,
        tgConversationDeleteSuccessVF,
        tgConversationDeleteFailureVF
    };
};
