import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {calendarEventGET, clearCalendarEventRetrievalVF, resetCalendarEventRetrieval} from "../../../../../api/features/superIntelligence/memoMinder/calendar/getCalendarEventSlice";


export const useGetCalendarEvent = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();
    const {
        calendarEventRetrievalVF,
        calendarEventRetrievalApiState,
        calendarEventRetrievalResponse,
        calendarEventRetrievalError,
    } = useSelector(state => state.getCalendarEventReducer);

    const calendarEventRetrievalSuccessVF = calendarEventRetrievalApiState === 'succeeded' && calendarEventRetrievalVF;
    const calendarEventRetrievalFailureVF = calendarEventRetrievalApiState === 'failed' && calendarEventRetrievalVF;

    const getCalendarEvent = (date) => dispatch(calendarEventGET({ date }));

    useEffect(() => {
        if (vfExists) {
            if (calendarEventRetrievalSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearCalendarEventRetrievalVF());
                }, 100);

                return () => clearTimeout(timer);
            } else if (calendarEventRetrievalFailureVF) {
                dispatch(setAlert({
                    message: calendarEventRetrievalResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetCalendarEventRetrieval());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [calendarEventRetrievalApiState, calendarEventRetrievalVF]);

    return {
        getCalendarEvent,
        calendarEventRetrievalVF,
        calendarEventRetrievalApiState,
        calendarEventRetrievalResponse,
        calendarEventRetrievalError,
        calendarEventRetrievalSuccessVF,
        calendarEventRetrievalFailureVF,
    };
};
