import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../users/useAuthentication";
import {clearTransactionListingVF, transactionLIST} from "../../../api/features/payments/listTransactionSlice";


export const useListTransactions = (
    { vfExists=false }
) => {
    const dispatch = useDispatch();

    // Authentication
    const {userIsAuthenticated} = useAuthentication();

    // Payment methods
    const {
        transactionListingVF,
        transactionListingApiState,
        transactionListingResponse,
        transactionListingError,
    } = useSelector(state => state.listTransactionReducer);

    // API states with visual feedback
    const transactionListingSuccessVF = transactionListingApiState === 'succeeded' && transactionListingVF;

    const listTransactions = () => userIsAuthenticated && dispatch(transactionLIST());

    useEffect(() => {
        if (vfExists) {
            if (transactionListingSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearTransactionListingVF());
                }, 500);
                return () => clearTimeout(timer);
            }
        }
    }, [transactionListingApiState, transactionListingVF]);

    return {
        listTransactions,
        transactionListingVF,
        transactionListingApiState,
        transactionListingResponse,
        transactionListingError,
        transactionListingSuccessVF
    };
};
