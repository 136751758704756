import {useSelector} from "react-redux";


export const useGetTgInteractionStream = () => {
    const {
        tgStreamConversationUuid,
        tgStreamSelectedGlides,
        tgStreamInteractionId,
        tgStreamInteractionStatus,
        tgStreamInteractionMessages,
        // tgStreamUserMessage,
        // tgStreamGlideResponse,
        tgStreamSchema,
    } = useSelector(state => state.tgInteractionStreamReducer);

    return {
        tgStreamConversationUuid,
        tgStreamSelectedGlides,
        tgStreamInteractionId,
        tgStreamInteractionStatus,
        tgStreamInteractionMessages,
        // tgStreamUserMessage,
        // tgStreamGlideResponse,
        tgStreamSchema,
    }
};
