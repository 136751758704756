import React from "react";
import {Link, useLocation} from "react-router-dom";
import routes from "../../../Routes/routes";
import clsx from "clsx";

const SettingsNav = () => {
    const location = useLocation();

    const settingsNav = [
        { name: 'Account', href: routes.auth.settings.account.href },
        { name: 'Preferences', href: routes.auth.settings.preferences.href },
    ];

    const updatedSettingsNav = settingsNav.map(item => ({
        ...item,
        current: (
            item.href === location.pathname
        ),
    }));

    return (
        <header className="flex items-center sticky w-full top-14 h-14 bg-base-100 border-b-[0.75px] border-neutral z-[25]">
            <nav className="overflow-x-auto hide-scrollbar w-full mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                <ul className="flex min-w-full flex-none space-x-4 py-2 text-sm font-semibold leading-6">
                    {updatedSettingsNav.map((item) => (
                        <Link
                            key={item.name}
                            to={item.href}
                            className={clsx(item.current ? 'bg-base-200 text-base-300' : 'hover:bg-base-200', 'inline-flex items-center rounded-md py-1.5 px-3 text-sm font-medium whitespace-nowrap text-base-content')}
                        >
                            {item.name}
                        </Link>
                    ))}
                </ul>
            </nav>
        </header>
    )
};

export default SettingsNav;
