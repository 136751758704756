import {useDispatch, useSelector} from 'react-redux';

import {clearStripeCustomerCreationVF, resetStripeCustomerCreation, stripeCustomerCREATE} from "../../../api/features/payments/createStripeCustomerSlice";
import {useAuthentication} from "../users/useAuthentication";
import {useEffect} from "react";
import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";


export const useCreateStripeCustomer = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();

    const {
        stripeCustomerCreationApiState,
        stripeCustomerCreationVF,
        stripeCustomerCreationResponse,
        stripeCustomerCreationError,
    } = useSelector(state => state.createStripeAccountReducer);

    const stripeCustomerCreationSuccessVF = stripeCustomerCreationApiState === 'succeeded' && stripeCustomerCreationVF;
    const stripeCustomerCreationFailureVF = stripeCustomerCreationApiState === 'failed' && stripeCustomerCreationVF;

    const createStripeCustomer = () => userIsAuthenticated && dispatch(stripeCustomerCREATE());

    useEffect(() => {
        if (vfExists) {
            if (stripeCustomerCreationSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearStripeCustomerCreationVF());
                }, 100);

                return () => clearTimeout(timer);
            }  else if (stripeCustomerCreationFailureVF) {
                dispatch(setAlert({
                    message: stripeCustomerCreationError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                dispatch(resetStripeCustomerCreation());
            }
        }
    }, [stripeCustomerCreationApiState]);

    return {
        createStripeCustomer,
        stripeCustomerCreationApiState,
        stripeCustomerCreationVF,
        stripeCustomerCreationResponse,
        stripeCustomerCreationError,
        stripeCustomerCreationSuccessVF,
        stripeCustomerCreationFailureVF
    };
};
