import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {useCallBackend} from "../../calls/useCallBackend";


export const paymentMethodSetDefaultPOST = createAsyncThunk(
    'payments/paymentMethod/setDefault',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_SET_STRIPE_DEFAULT_PAYMENT_METHOD_PATH}`,
                    args: values,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    paymentMethodDefaultSettingVF: false,
    paymentMethodDefaultSettingApiState: 'idle',
    paymentMethodDefaultSettingResponse: null,
    paymentMethodDefaultSettingError: null,
}

export const setDefaultPaymentMethodSlice = createSlice({
    name: 'paymentMethodDefaultSetting',
    initialState: INITIAL_STATE,
    reducers: {
        clearPaymentMethodDefaultSettingVF: (state) => {
            state.paymentMethodDefaultSettingVF = false;
        },
        resetPaymentMethodDefaultSetting: (state) => {
            state.paymentMethodDefaultSettingVF = false;
            state.paymentMethodDefaultSettingApiState = 'idle';
            state.paymentMethodDefaultSettingResponse = null;
            state.paymentMethodDefaultSettingError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(paymentMethodSetDefaultPOST.pending, (state) => {
                state.paymentMethodDefaultSettingApiState = 'loading';
                state.paymentMethodDefaultSettingVF = true;
            })
            .addCase(paymentMethodSetDefaultPOST.fulfilled, (state, action) => {
                state.paymentMethodDefaultSettingApiState = 'succeeded';
                state.paymentMethodDefaultSettingResponse = action.payload;
                state.paymentMethodDefaultSettingVF = true;
                state.paymentMethodDefaultSettingError = null;
            })
            .addCase(paymentMethodSetDefaultPOST.rejected, (state, action) => {
                state.paymentMethodDefaultSettingApiState = 'failed';
                state.paymentMethodDefaultSettingError = action?.payload;
                state.paymentMethodDefaultSettingVF = true;
                state.paymentMethodDefaultSettingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearPaymentMethodDefaultSettingVF,
    resetPaymentMethodDefaultSetting
} = setDefaultPaymentMethodSlice.actions;

export default setDefaultPaymentMethodSlice.reducer;
