import {prepareHeaders} from "./prepareHeaders";
import {processResponse} from "./processResponse";
import {handleFetchError} from "./errorHandlers";
import {processStream} from "./processStream";
import config from "../../config";


export const useCallBackend = async (
    { method, path, args, headers: customHeaders, accessCode, mode = 'cors', isStream = false, onStreamData = null, signal = null },
    thunkAPI
) => {
    const endpoint = config.backendUrl;
    let url = `${endpoint}${path}`;
    let headers = await prepareHeaders(accessCode, customHeaders, thunkAPI);
    if (!headers) {return;}

    let body;
    if (args instanceof FormData) {
        body = args;
        delete headers['Content-Type'];
    } else {
        body = JSON.stringify(args);
        headers['Content-Type'] = 'application/json';
    }

    try {
        const response = await fetch(url, {
            method,
            headers,
            body,
            mode,
            signal
        });

        return isStream ? processStream(response, onStreamData, thunkAPI) : processResponse(response, thunkAPI);
    } catch (error) {
        return handleFetchError(error, thunkAPI);
    }
};
