import PropTypes from "prop-types";
import React from "react";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import SearchCommand from "../../Components/SearchCommand/SearchCommand";


export const UnAuthWrapper = ({ page:  Page }) => {

    return (
        <div className="bg-base-100 grid grid-cols-1 grid-flow-row overflow-x-hidden overflow-y-auto scrollbar h-dvh auto-scroll-container" >
            <Header/>
            <SearchCommand/>
            <Page/>
            <Footer/>
        </div>
    )
};

UnAuthWrapper.propTypes = {
    page: PropTypes.elementType.isRequired,
};