import React, {useEffect, useRef, useState} from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import clsx from "clsx";
import PropTypes from "prop-types";
import {ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {HiOutlineMagnifyingGlass} from "react-icons/hi2";
import {CgSpinner} from "react-icons/cg";

export const loadGoogleMapsScript = (callback) => {
    if (window.google) {
        callback();
        return;
    }

    window.handleScriptLoad = callback;

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=handleScriptLoad`;
    script.async = true;
    document.body.appendChild(script);
};

const AddressAutocompletion = (
    { relativeToScreen = false, onSelect, placeholder, defaultValue, onBlur, className, wrapperClass, ...props }
) => {
    const containerRef = useRef(null);

    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isNearBottom, setIsNearBottom] = useState(false);
    const [address, setAddress] = useState(defaultValue || "");
    const [dropdownVisible, setDropdownVisible] = useState(false);

    useEffect(() => {
        loadGoogleMapsScript(() => setIsScriptLoaded(true));
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.defaultPrevented) {
            // Prevent Enter key from submitting the form or causing other unwanted behavior
            event.preventDefault();
        }
    };

    const checkPosition = () => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();

            if (relativeToScreen) {
                // Calculate the distance from the bottom of the screen
                const distanceFromBottomOfScreen = window.innerHeight - rect.bottom;
                setIsNearBottom(distanceFromBottomOfScreen < 239);
            } else {
                // Your previous logic for parent relative positioning
                const parentRect = containerRef.current.parentElement.getBoundingClientRect();
                const distanceFromBottomOfParent = parentRect.bottom - rect.bottom;
                setIsNearBottom(distanceFromBottomOfParent < 239);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', checkPosition);

        checkPosition();

        return () => {
            window.removeEventListener('scroll', checkPosition);
            window.removeEventListener('resize', checkPosition);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = async value => {
        setAddress(value);

        // Get detailed address components
        const results = await geocodeByAddress(value);
        const addressComponents = results[0].address_components;

        // Extract the specific components into an object
        const extractedComponents = {
            address1: addressComponents.find(component => component.types.includes('street_number'))?.long_name + " " + addressComponents.find(component => component.types.includes('route'))?.long_name,
            address2: addressComponents.find(component => component.types.includes('subpremise'))?.long_name ? "#" + addressComponents.find(component => component.types.includes('subpremise'))?.long_name : "",
            city: addressComponents.find(component => component.types.includes('locality'))?.long_name,
            state: addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name,
            stateCode: addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.short_name,
            country: addressComponents.find(component => component.types.includes('country'))?.long_name,
            countryCode: addressComponents.find(component => component.types.includes('country'))?.short_name,
            zip_code: addressComponents.find(component => component.types.includes('postal_code'))?.long_name,
        };

        onSelect?.(extractedComponents);
    };

    return (
        <div className={clsx("group relative", wrapperClass)} ref={containerRef} >
            {isScriptLoaded ? (
                <PlacesAutocomplete
                    value={address}
                    onChange={(addressValue) => {
                        setAddress(addressValue);
                        setDropdownVisible(true);
                    }}
                    onSelect={handleSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                        <>
                            <input
                                className={clsx("input-field pr-8", className)}
                                {...getInputProps({ placeholder: placeholder })}
                                onKeyDown={handleKeyDown}
                                onBlur={onBlur}
                                {...props}
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <HiOutlineMagnifyingGlass className="h-4 w-4 text-base-300 shrink-0" aria-hidden="true" />
                            </div>
                            <div
                                className={clsx("select-dropdown",
                                    (!dropdownVisible || suggestions.length === 0) && "hidden",
                                    isNearBottom && "bottom-10"
                                )}>
                                <ul className="cursor-default" >
                                    {suggestions.map((suggestion, index) => {

                                        return (
                                            <li
                                                key={index}
                                                {...getSuggestionItemProps(suggestion)}
                                                className={clsx(
                                                    "relative select-none flex items-center space-x-2 py-1 pl-3 pr-9 cursor-pointer text-base-content/70 hover:sm:bg-base-200 text-start text-sm",
                                                    suggestion.active && "bg-base-100"
                                                )}
                                            >
                                                {suggestion.description}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </>
                    )}
                </PlacesAutocomplete>
            ) : (
                <div className="relative group" >
                    <input
                        disabled
                        className={clsx("input-field pr-10", className)}
                        value={"Loading..."}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <CgSpinner className="h-4 w-4 shrink-0 text-base-300 animate-spin" aria-hidden="true" />
                    </div>
                </div>
            )}
        </div>
    );
};

AddressAutocompletion.propTypes = {
    relativeToScreen: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    onBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    className: PropTypes.string,
    wrapperClass: PropTypes.string,
}

AddressAutocompletion.defaultProps = {
    placeholder: "",
    defaultValue: "",
}

export default AddressAutocompletion;