import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {useCallBackend} from "../../calls/useCallBackend";


export const paymentMethodCREATE = createAsyncThunk(
    'payments/paymentMethod/create',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_CREATE_STRIPE_PAYMENT_METHOD_PATH}`,
                    args: values,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    paymentCreationVF: false,
    paymentCreationApiState: 'idle',
    paymentCreationResponse: null,
    paymentCreationError: null,
}

export const createPaymentMethodSlice = createSlice({
        name: 'paymentMethodCreation',
        initialState: INITIAL_STATE,
        reducers: {
            clearPaymentCreationVF: (state) => {
                state.paymentCreationVF = false;
            },
            resetPaymentCreation: (state) => {
                state.paymentCreationVF = false;
                state.paymentCreationApiState = 'idle';
                state.paymentCreationResponse = null;
                state.paymentCreationError = null;
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(paymentMethodCREATE.pending, (state) => {
                    state.paymentCreationApiState = 'loading';
                    state.paymentCreationVF = true;
                })
                .addCase(paymentMethodCREATE.fulfilled, (state, action) => {
                    state.paymentCreationApiState = 'succeeded';
                    state.paymentCreationResponse = action.payload;
                    state.paymentCreationVF = true;
                    state.paymentCreationError = null;
                })
                .addCase(paymentMethodCREATE.rejected, (state, action) => {
                    state.paymentCreationApiState = 'failed';
                    state.paymentCreationError = action?.payload;
                    state.paymentCreationVF = true;
                    state.paymentCreationResponse = null;
                })
                .addDefaultCase((state, action) => {
                    if (action.type === 'RESET') {
                        return INITIAL_STATE;
                    }
                });
        }
    });


export const {
    clearPaymentCreationVF,
    resetPaymentCreation
} = createPaymentMethodSlice.actions;

export default createPaymentMethodSlice.reducer;
