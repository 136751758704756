import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {clearTaskGlideSettingsRetrievalVF, taskGlideSettingsGET} from "../../../../../api/features/superIntelligence/taskGlide/taskGlide/getTaskGlideSettingsSlice";
import {useAuthentication} from "../../../users/useAuthentication";


export const useGetTaskGlideSettings = ({vfExists=false} = {}) => {
    const dispatch = useDispatch();

    // Authentication
    const {userIsAuthenticated} = useAuthentication();

    // User info
    const {
        taskGlideSettingsRetrievalApiState,
        taskGlideSettingsRetrievalVF,
        taskGlideSettingsRetrievalResponse,
        taskGlideSettingsRetrievalError,
    } = useSelector(state => state.getTaskGlideSettingsReducer);

    // API states with visual feedback
    const taskGlideSettingsRetrievalSuccessVF = taskGlideSettingsRetrievalApiState === 'succeeded' && taskGlideSettingsRetrievalVF;
    const taskGlideSettingsRetrievalErrorVF = taskGlideSettingsRetrievalApiState === 'failed' && taskGlideSettingsRetrievalVF;

    const getTaskGlideSettings = () => userIsAuthenticated && dispatch(taskGlideSettingsGET());

    useEffect(() => {
        if (vfExists && taskGlideSettingsRetrievalSuccessVF) {
            const timer = setTimeout(() => {
                dispatch(clearTaskGlideSettingsRetrievalVF());
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [taskGlideSettingsRetrievalApiState]);

    return {
        getTaskGlideSettings,
        taskGlideSettingsRetrievalApiState,
        taskGlideSettingsRetrievalResponse,
        taskGlideSettingsRetrievalError,
        taskGlideSettingsRetrievalVF,
        taskGlideSettingsRetrievalSuccessVF,
        taskGlideSettingsRetrievalErrorVF
    };
};
