import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import {resetAlert} from "../../api/features/frontend/alertSlice";

export const useAlert = (shouldReset = false) => {
    const dispatch = useDispatch();
    const {
        alert,
        alertKey,
        message,
        description,
    } = useSelector((state) => state.alertReducer);

    useEffect(() => {
        if (shouldReset && alertKey > 0 && (message || description)) {
            const timer = setTimeout(() => {
                dispatch(resetAlert());
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [alertKey, message, description]);

    return {alert,  alertKey};
};
