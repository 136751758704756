import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearDocumentDownloadVF, downloadDocumentGET, resetDocumentDownload} from "../../../../../api/features/superIntelligence/taskGlide/conversations/downloadDocumentSlice";


export const useDownloadDocument = (
    {vfExists=false} = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Task Glide
    const {
        documentDeletionVF,
        documentDeletionApiState,
        documentDeletionResponse,
        documentDeletionError,
    } = useSelector(state => state.downloadDocumentReducer);

    // API states with visual feedback
    const documentDownloadSuccessVF = documentDeletionApiState === 'succeeded' && documentDeletionVF;
    const documentDownloadFailureVF = documentDeletionApiState === 'failed' && documentDeletionVF;

    const downloadDocument = async (doc) => {
        if (userIsAuthenticated) {
            const response = await dispatch(downloadDocumentGET({ document_uuid: doc?.document_uuid }));
            if (downloadDocumentGET.fulfilled.match(response)) {
                const preSignedUrl = response.payload.url;
                const link = document.createElement('a');
                link.href = preSignedUrl;
                link.setAttribute('download', doc?.name_tag);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        }
    };

    useEffect(() => {
        if (vfExists) {
            if (documentDownloadSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearDocumentDownloadVF());
                }, 250);

                return () => clearTimeout(timer);
            } else if (documentDownloadFailureVF) {
                dispatch(setAlert({
                    message: documentDeletionResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetDocumentDownload());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [documentDeletionApiState, documentDeletionVF]);

    return {
        downloadDocument,
        documentDeletionVF,
        documentDeletionApiState,
        documentDeletionResponse,
        documentDeletionError,
        documentDownloadSuccessVF,
        documentDownloadFailureVF
    };
};
