import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {MdOutlineOpenInNew} from "react-icons/md";
import { motion } from "framer-motion";
import clsx from "clsx";
import {FcGoogle} from "react-icons/fc";
import {SiWikipedia} from "react-icons/si";

import wikipediaIcon from "../../../../../../../Design/superIntelligence/taskGlide/wikipedia.svg";
import webSearchIcon from "../../../../../../../Design/superIntelligence/taskGlide/web-search.svg";


const TaskGlideBrowsingSources = ({ message }) => {
    const [imageFailed, setImageFailed] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const maxPagination = 3;
    const toolData = message.glide_response?.tools_used || [];
    const toolType = toolData[0]?.name_tag;

    const highLevelSourceTitle = toolType === 'Web Browser' ? toolData[0]?.tool_input?.query : toolData[0]?.tool_input?.page_to_search;
    const highLevelSourceHref = toolType === 'Web Browser' ?
        `https://www.google.com/search?q=${toolData[0]?.tool_input?.query}${toolData[0]?.tool_input?.search_type === 'news' && '&tbm=nws'}` :
        `https://en.wikipedia.org/w/index.php?search=${toolData[0]?.tool_input?.page_to_search?.split(' ').join('+')}&title=Special:Search&profile=advanced&fulltext=1&ns0=1`;
    const highLevelSourceIcon = toolType === 'Web Browser' ? <FcGoogle className="size-3.5 shrink-0" /> : <SiWikipedia className="size-3.5 shrink-0" />;

    useEffect(() => {
        const totalAnimationTime = (maxPagination) * 0.25 * 1000;
        const timeoutId = setTimeout(() => {
            setShowButton(true);
        }, totalAnimationTime);

        return () => clearTimeout(timeoutId);
    }, [message?.glide_response?.tools_used.length]);

    const displayedIcon = {
        "Wiki Browser": wikipediaIcon,
        "Web Browser": webSearchIcon,
    };

    const hasValidTool = () => {
        if (!message?.glide_response?.tools_used) {
            return false;
        }

        return toolData?.some(tool => {
            const isRelevantTool = (tool?.name_tag === "Wiki Browser" || tool?.name_tag === "Web Browser");
            return isRelevantTool && tool?.tool_output?.title;
        });
    };

    let counter = 0;

    if (!hasValidTool()) {
        return null;
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            key={message?.id}
            className="grid grid-cols-1 bg-base-200 px-3 py-2.5 mt-2 rounded-md text-xs ring-[0.5px] ring-neutral-focus/50 dark:ring-neutral-focus-dark/50"
        >
            <div className="space-x-1 mb-2">
                <h3 className="inline text-base-content">{toolData?.length > 1 ? "Sources for" : "Source for"}</h3>
                <div className="relative group/google-search inline">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className="inline font-medium text-primary hover:text-primary-focus dark:hover:text-primary-focus-dark"
                        href={highLevelSourceHref}
                    >
                        {highLevelSourceTitle}:
                    </a>
                    <a href={`https://www.google.com/search?q=${toolData[0]?.tool_input?.query}`} target="_blank" rel="noreferrer" className="hidden absolute bottom-6 left-0 lg:group-hover/google-search:flex items-center justify-center bg-base-content text-base-100 w-fit space-x-1 px-2 rounded-md no-underline z-30 font-medium"
                    >
                        <div className="w-4 flex justify-center items-center rounded-full h-fit overflow-hidden">
                            {highLevelSourceIcon}
                        </div>
                        <span className="whitespace-nowrap truncate max-w-2xs w-fit h-full py-1.5">{highLevelSourceHref}</span>
                        <div className="w-3 flex justify-center items-center h-fit overflow-hidden">
                            <MdOutlineOpenInNew className="w-3 h-auto"/>
                        </div>
                    </a>
                </div>
            </div>

            <ol className="flex flex-col text-start items-start space-y-2 text-secondary-focus">
                {toolData?.slice(0, showAll ? toolData?.length : maxPagination).map((tool, index) => {
                    if (!tool?.tool_output) {
                        return null;
                    }

                    counter += 1;
                    const src = (tool.tool_output?.icon && !imageFailed) ? tool.tool_output?.icon : displayedIcon[tool?.name_tag];
                    const isInitialItem = index < maxPagination;

                    const motionProps = isInitialItem ? {
                        initial: {opacity: 0, y: -10},
                        animate: {opacity: 1, y: 0},
                        exit: {opacity: 0, y: -10},
                        transition: {duration: 0.5, ease: "easeInOut", delay: index * 0.25},
                    } : {};

                    return (
                        (tool?.tool_output) &&
                        <motion.li
                            {...motionProps}
                            key={index}
                            className="inline"
                        >
                            {toolData?.length > 1 && <span className="inline">{counter}. </span>}
                            <div className="relative group/content-source inline">
                                <a
                                    className="text-secondary-focus hover:sm:text-secondary"
                                    href={tool?.tool_output?.href}
                                    target="_blank"
                                    rel="noreferrer"
                                >{tool?.tool_output?.title}</a>
                                <a href={tool?.tool_output?.href} target="_blank" rel="noreferrer" className="hidden absolute bottom-6 left-0 lg:group-hover/content-source:flex items-center justify-center bg-base-content text-base-100 w-fit space-x-1.5 px-2 rounded-md no-underline z-30 font-medium"
                                >
                                    <div className="w-4 flex justify-center items-center rounded-full h-fit overflow-hidden" >
                                        <img
                                            className="w-4 h-auto"
                                            src={src}
                                            alt={tool?.tool_output?.title}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = displayedIcon[tool?.name_tag];
                                                setImageFailed(true);
                                            }}
                                        />
                                    </div>
                                    <span className="whitespace-nowrap truncate max-w-2xs w-fit h-full py-1.5" >{tool?.tool_output?.href}</span>
                                    <div className="w-3 flex justify-center items-center h-fit overflow-hidden" >
                                        <MdOutlineOpenInNew className="w-3 h-auto" />
                                    </div>
                                </a>
                            </div>
                        </motion.li>
                    )}
                )}
                {toolData?.length > maxPagination && (
                    <button
                        onClick={() => setShowAll(!showAll)}
                        className={clsx("text-secondary-focus hover:sm:text-secondary mt-2 transition-all duration-500", showButton ? "opacity-100" : "opacity-0")}
                    >
                        {showAll ? 'Show Less' : `+${toolData?.length - maxPagination} More`}
                    </button>
                )}
            </ol>
        </motion.div>
    );
};

TaskGlideBrowsingSources.propTypes = {
    message: PropTypes.object,
};

export default TaskGlideBrowsingSources;
