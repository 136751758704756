import React, {useRef, useState} from 'react';
import {motion} from "framer-motion";
import clsx from "clsx";
import {BiDotsHorizontalRounded} from "react-icons/bi";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {TbPhoto} from "react-icons/tb";
import {LuPaperclip} from "react-icons/lu";

import {glideColorClasses, glideDefaultColor, getGlideIcon} from "../Chat/ChatBox/Messages/glideInfo";
import SingleConvoDeletionModal from "./SingleConvoDeletionModal";
import DropDown from "../../../../../Components/DropDown/DropDown";


const InteractionHistoryItem = (
    {
        selectedConversation,
        conversationItem,
        disabledState,
        handleConversationClick,
        handleDeleteSingleConversationClick,
        itemLoadingState,
        interactionHistoryRef
    }
) => {
    const convoDeletionModal = useRef(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const messages = conversationItem?.interactions?.messages;
    const glideResponse = messages?.find(item => item.type === 'glide_response')?.content;
    const userMessage = messages?.find(item => item.type === 'user_message')?.content;

    const glideIconAnimation = {
        initial: { opacity: 0, x: 10, scale: 0 },
        enter: { opacity: 1, x: 0, scale: 1 },
        exit: { opacity: 0, x: 10, scale: 0 },
    };

    const taskHistoryAnimation = {
        initial: { opacity: 0, y: -10 },
        enter: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 10 },
    };

    const items = [
        {
            label: conversationItem?.is_pinned ? "Unpin" : "Pin",
            icon: null,
            onClick:() => console.log("Pinned"),
        },
        {
            label: "View",
            icon: null,
            onClick: () => handleConversationClick(conversationItem?.conversation_uuid),
        },
        <SingleConvoDeletionModal
            key="delete"
            ref={convoDeletionModal}
            description={conversationItem?.description}
            disabledState={disabledState}
            conversationItem={conversationItem}
            onDelete={() => {
                handleDeleteSingleConversationClick(conversationItem?.conversation_uuid);
                setDropdownVisible(false);
            }}
        />
    ];

    return (
        <motion.li
            key={conversationItem?.conversation_uuid}
            variants={taskHistoryAnimation}
            layout
            initial="initial"
            animate="enter"
            exit="exit"
            className={clsx(
                "group flex justify-between relative px-4 py-0 w-full text-start disabled:cursor-not-allowed disabled:opacity-50 min-h-[104px] space-x-4",
                selectedConversation?.id === conversationItem?.id ?
                    "bg-base-200 disabled:hover:bg-base-200" : "hover:bg-base-200 disabled:hover:bg-base-100",
            )}
        >
            <button
                type="button"
                tabIndex={-1}
                className="flex flex-col justify-start py-3 flex-1 focus-visible:ring-0 focus-visible:outline-none"
                disabled={disabledState}
                onClick={() => handleConversationClick(conversationItem?.conversation_uuid)}
            >
                <div className="block focus:outline-none text-start w-full">
                    <span className="absolute inset-0" aria-hidden="true"/>
                    <p className="line-clamp-1 text-xs font-semibold text-base-content/70 truncate capitalize">
                        {glideResponse?.interaction_glide?.name}
                    </p>
                    <div className="flex items-center justify-between w-full space-x-4 mt-1">
                        <div className="flex items-center space-x-2" >
                            <p className="line-clamp-1 prose-p:leading-snug text-sm text-base-content text-start capitalize overflow-hidden break-all">
                                {conversationItem?.description || "Helping you with your task"}
                            </p>
                            {conversationItem?.has_documents && <LuPaperclip className="h-4 w-4 shrink-0 text-base-content/70"/>}
                            {conversationItem?.has_images && <TbPhoto className="h-4 w-4 shrink-0 text-base-content/70"/>}
                        </div>
                        <div className="flex">
                            {conversationItem?.used_glides?.slice(0, 4).map((glide, index) => (
                                <motion.li
                                    variants={glideIconAnimation}
                                    layout
                                    initial="initial"
                                    animate="enter"
                                    exit="exit"
                                    key={glide?.uuid}
                                    className={clsx(
                                        "flex items-center justify-center rounded-full h-6 w-6 sm:h-5 sm:w-5 font-medium p-1 text-2xs text-base-100 ring-1 ring-base-100 focus:ring-0 focus:outline-none", index > 0 ? "-ml-2" : "ml-0",
                                        glideColorClasses[glide?.color].bg || glideDefaultColor?.bg,
                                        selectedConversation?.id === conversationItem?.id ? "ring-base-100" : "ring-base-200"
                                    )}
                                >
                                    {getGlideIcon(glide?.name, "w-4 h-4 sm:w-3.5 sm:h-3.5 text-3xs")}
                                </motion.li>
                            ))}
                            {conversationItem?.used_glides?.length > 4 && (
                                <div className="flex items-center justify-center rounded-full h-6 w-6 sm:h-5 sm:w-5 font-medium p-1 text-2xs text-base-100 bg-base-content -ml-2 ring-1 ring-base-100">
                                    {conversationItem?.used_glides?.length > 9 ?
                                        <BiDotsHorizontalRounded className="h-5 w-5 sm:h-4 sm:w-4"/> :
                                        <span>+{conversationItem?.used_glides?.length - 4}</span>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <span
                    className="line-clamp-2 text-sm sm:text-xs text-base-content/70 overflow-hidden break-all mt-1 font-normal prose-p:leading-snug text-start"
                >
                    {glideResponse?.main_response || userMessage?.text_input || "..."}
                </span>
            </button>
            <DropDown
                dropDownClass="w-36"
                dropdownIcon={itemLoadingState ?
                    <CgSpinner className="w-4 h-4 shrink-0 animate-spin" /> :
                    <HiOutlineDotsVertical className="w-4 h-4 shrink-0" />}
                items={items}
                dropDownParent={interactionHistoryRef}
                dropdownVisible={dropdownVisible}
                setDropdownVisible={setDropdownVisible}
                additionalRefs={[convoDeletionModal]}
                className="group/more-btn button-ring-focus mt-3 inline-flex p-1 rounded-full bg-base-100 focus-visible:rounded-full" />
        </motion.li>
    )
};

InteractionHistoryItem.propTypes = {
    selectedConversation: PropTypes.object,
    conversationItem: PropTypes.object,
    disabledState: PropTypes.bool,
    handleConversationClick: PropTypes.func,
    handleDeleteSingleConversationClick: PropTypes.func,
    itemLoadingState: PropTypes.bool,
    interactionHistoryRef: PropTypes.object
};

export default InteractionHistoryItem;
