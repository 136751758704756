import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const userInfoRETRIEVE = createAsyncThunk(
    'users/userInfo/retrieve',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
            try {
                return await useCallBackend(
                    {
                        method: 'GET',
                        path: `${process.env.REACT_APP_USER_INFO_PATH}`,
                        accessCode: access,
                    },
                    thunkAPI
                );
            } catch (error) {
                return thunkAPI.rejectWithValue(error);
            }
    }
);


const INITIAL_STATE = {
    userInfoRetrievalApiState: "idle",
    userInfoRetrievalVF: false,
    userInfoRetrievalResponse: null,
    userInfoRetrievalError: null,
};

export const getUserInfoSlice = createSlice({
    name: 'userInfoRetrieval',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserInfoRetrievalVF: (state) => {
            state.userInfoRetrievalVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userInfoRETRIEVE.pending, (state) => {
                state.userInfoRetrievalApiState = 'loading';
                state.userInfoRetrievalVF = true;
            })
            .addCase(userInfoRETRIEVE.fulfilled, (state, action) => {
                state.userInfoRetrievalApiState = 'succeeded';
                state.userInfoRetrievalResponse = action.payload;
                state.userInfoRetrievalVF = true;
                state.userInfoRetrievalError = null;
            })
            .addCase(userInfoRETRIEVE.rejected, (state, action) => {
                state.userInfoRetrievalApiState = 'failed';
                state.userInfoRetrievalError = action?.payload;
                state.userInfoRetrievalVF = true;
                state.userInfoRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const { clearUserInfoRetrievalVF } = getUserInfoSlice.actions;

export default getUserInfoSlice.reducer;
