import React, {useEffect, useState} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {PiDotOutlineLight} from "react-icons/pi";
import clsx from "clsx";
import PropTypes from "prop-types";

import ButtonDropDown from "../ButtonDropDown/ButtonDropDown";
import {findMonthIndexByDate, formatDateToISO, formatDateToReadable, groupDatesByMonth} from "../../reusableFxns/dateTimeFxns";


const DatePicker = (
    { calendarDates, selectedDate, setSelectedDate, useFullScreen = false, dateButtonClassName, disabled, isDateDisabled }
) => {
    const groupedDates = groupDatesByMonth(calendarDates);
    const selectedDateMonthIndex = findMonthIndexByDate(groupedDates, selectedDate);

    const initialStartIndex = selectedDateMonthIndex >= 0 ? selectedDateMonthIndex : 0;
    const [startIndex, setStartIndex] = useState(initialStartIndex);

    const onDateClick = (date) => {
        setSelectedDate(date);
        const selectedDateMonthIndex = findMonthIndexByDate(groupedDates, date);
        if (selectedDateMonthIndex !== startIndex) {
            setStartIndex(selectedDateMonthIndex);
        }
    };

    useEffect(() => {
        const newStartIndex = selectedDateMonthIndex >= 0 ? selectedDateMonthIndex : 0;
        setStartIndex(newStartIndex);
    }, [selectedDate, selectedDateMonthIndex]);

    const handlePrevious = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
        }
    };

    const handleNext = () => {
        if (startIndex < groupedDates.length - 1) {
            setStartIndex(startIndex + 1);
        }
    };

    const handleToday = () => {
        onDateClick(formatDateToISO(new Date()));
    };

    const displayedMonth = groupedDates[startIndex];

    return (
        <ButtonDropDown
            buttonText={formatDateToReadable(selectedDate)}
            useFullScreen={useFullScreen}
            buttonClassName={dateButtonClassName}
            dropdownClassName="w-64"
            disabled={disabled}
        >
            <div className="px-4 py-2.5">
                <div className="flex items-center justify-between">
                    <h2 className="flex-auto text-sm font-semibold text-base-content">{displayedMonth?.name}</h2>
                    <div className="flex items-center justify-between">
                        <button
                            type="button"
                            className="flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                            onClick={handlePrevious}
                            disabled={startIndex === 0}
                        >
                            <span className="sr-only">Previous month</span>
                            <ChevronLeftIcon className="size-4" aria-hidden="true"/>
                        </button>
                        <button
                            type="button"
                            className="flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                            onClick={handleToday}
                        >
                            <span className="sr-only">Today</span>
                            <PiDotOutlineLight className="size-4" aria-hidden="true"/>
                        </button>
                        <button
                            type="button"
                            className="flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                            onClick={handleNext}
                            disabled={startIndex >= groupedDates.length - 1}
                        >
                            <span className="sr-only">Next month</span>
                            <ChevronRightIcon className="size-4" aria-hidden="true"/>
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-7 text-center text-xs leading-6 text-gray-500 mt-1">
                    <div>S</div>
                    <div>M</div>
                    <div>T</div>
                    <div>W</div>
                    <div>T</div>
                    <div>F</div>
                    <div>S</div>
                </div>
                <div className="mt-2 grid grid-cols-7 gap-2 text-xs">
                    {displayedMonth?.days?.map((day, _) => {
                        const dateDisabled = isDateDisabled(day.date);

                        return (
                            <div key={day.date} className='py-1' >
                                <button
                                    type="button"
                                    className={clsx(
                                        day?.date === selectedDate && day.isToday && "bg-primary-focus dark:bg-primary-focus-dark text-primary-content",
                                        day?.date === selectedDate && !day.isToday && "text-base-100 bg-base-content",
                                        day?.date !== selectedDate && day?.isCurrentMonth && "text-base-content bg-base-100",
                                        day?.date !== selectedDate && !day?.isCurrentMonth && "text-base-content/70 bg-base-100",
                                        day?.date !== selectedDate && day.isToday && "bg-base-100 text-primary-focus dark:text-primary-focus-dark",
                                        (day?.isToday || day?.date === selectedDate) && "font-semibold",
                                        day?.date !== selectedDate && "hover:sm:bg-base-300/10",
                                        'mx-auto flex size-5 items-center justify-center rounded-full disabled:cursor-not-allowed disabled:opacity-50',
                                    )}
                                    onClick={() => onDateClick(day.date)}
                                    disabled={dateDisabled}
                                >
                                    <time dateTime={day.date}>{day.date?.split('-').pop().replace(/^0/, '')}</time>
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>
        </ButtonDropDown>
    );
};

DatePicker.propTypes = {
    calendarDates: PropTypes.array,
    selectedDate: PropTypes.string,
    setSelectedDate: PropTypes.func,
    useFullScreen: PropTypes.bool,
    dateButtonClassName: PropTypes.string,
    disabled: PropTypes.bool,
    isDateDisabled: PropTypes.func,
}

export default DatePicker;
