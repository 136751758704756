import React, {Fragment, useEffect, useState} from 'react'
import {Combobox, Dialog, Transition} from "@headlessui/react";
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {useNavigate} from "react-router-dom";

import {routeComponents} from "../../Routes/routeComponents";


const SearchCommand = () => {
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const filteredPages = query === '' ? [] : routeComponents.filter((route) => {
        return route?.searchable && (
            route.name?.toLowerCase().includes(query.toLowerCase()) ||
            route.keywords?.some(keyword => keyword.toLowerCase().includes(query.toLowerCase()))
        );
    });

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
                event.preventDefault();
                setOpen(true);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleSelection = (route) => {
        navigate(route.path);
        setOpen(false);
    };

    return (
        <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
            <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-0"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50 transition-opacity backdrop-blur" />
                </Transition.Child>
                <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
                <div className="fixed top-1/2 inset-0 transform -translate-y-1/2 p-4">
                    <Dialog.Panel className="mx-auto max-w-xl rounded-xl bg-base-100 shadow-lg overflow-hidden">
                        <Combobox onChange={handleSelection}>
                            <div className="relative">
                                <MagnifyingGlassIcon className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-base-content" />
                                <Combobox.Input
                                    className="w-full border-0 bg-transparent pl-11 pr-4 py-3 text-base-content placeholder:text-base-content/50 focus:ring-0"
                                    placeholder="Search..."
                                    onChange={(event) => setQuery(event.target.value)}
                                />
                            </div>
                            {filteredPages.length > 0 && (
                                <Combobox.Options className="max-h-72 overflow-y-auto py-1 text-sm">
                                    {filteredPages.map((route) => (
                                        <Combobox.Option
                                            key={route.path}
                                            value={route}
                                            as={Fragment}
                                            onClick={() => handleSelection(route)}
                                        >
                                            {({ active }) => (
                                                <div
                                                    className={`cursor-default select-none px-4 py-2.5 ${active ? 'bg-primary text-primary-content' : 'text-base-content'} w-full text-start`}
                                                >
                                                    {route.name}
                                                </div>
                                            )}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            )}
                            {query !== '' && filteredPages.length === 0 && (
                                <p className="p-4 text-sm text-base-content/50">No results found</p>
                            )}
                        </Combobox>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default SearchCommand;
