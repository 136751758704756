import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const userSubscriptionGET = createAsyncThunk(
    'payments/user-subscription/get',
    async (product_name, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_USER_SUBSCRIPTION_GET_PATH}${product_name}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userSubscriptionRetrievalVF: false,
    userSubscriptionRetrievalApiState: 'idle',
    userSubscriptionRetrievalResponse: null,
    userSubscriptionRetrievalError: null,
};

export const getUserSubscriptionSlice = createSlice({
    name: 'getUserSubscription',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserSubscriptionRetrievalVF: (state) => {
            state.userSubscriptionRetrievalVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userSubscriptionGET.pending, (state) => {
                state.userSubscriptionRetrievalApiState = 'loading';
                state.userSubscriptionRetrievalVF = true;
            })
            .addCase(userSubscriptionGET.fulfilled, (state, action) => {
                state.userSubscriptionRetrievalApiState = 'succeeded';
                state.userSubscriptionRetrievalResponse = action.payload;
                state.userSubscriptionRetrievalVF = true;
                state.userSubscriptionRetrievalError = null;
            })
            .addCase(userSubscriptionGET.rejected, (state, action) => {
                state.userSubscriptionRetrievalApiState = 'failed';
                state.userSubscriptionRetrievalError = action?.payload;
                state.userSubscriptionRetrievalVF = true;
                state.userSubscriptionRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const { clearUserSubscriptionRetrievalVF } = getUserSubscriptionSlice.actions;

export default getUserSubscriptionSlice.reducer;
