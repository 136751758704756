import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {clearRegistrationStatusVF, registrationStatusRETRIEVE} from "../../../api/features/users/getRegistrationStatusSlice";
import routes from "../../../Routes/routes";
import {registrationRoutes, userManagementRoutes} from "../../../Routes/customRoutes";
import {useAuthentication} from "./useAuthentication";
import {useCreateSubscription} from "../payments/useCreateSubscription";


const registrationBasedNav = (navigate, response, subscriptionStatus) => {
    if (!response?.fully_registered && !registrationRoutes.includes(window.location.pathname)) {
        if (!response?.user_info_completed) {
            navigate(routes.auth.registration.find(form => form.name === "Personal Information").href);
        } else if (response?.user_info_completed && !response?.user_preferences_completed) {
            navigate(routes.auth.registration.find(form => form.name === "Preferences").href);
        } else if (response?.user_info_completed && response?.user_preferences_completed && (!response?.payment_methods_completed || !response?.subscription_completed)) {
            navigate(routes.auth.registration.find(form => form.name === "Subscription").href);
        }
    } else if (response?.fully_registered && (userManagementRoutes.includes(window.location.pathname) || registrationRoutes.includes(window.location.pathname))) {
        if (subscriptionStatus === 'succeeded') {
            navigate(routes.auth.feedbacks.find(route => route.label === 'Subscription Confirmation').href)
        } else {
            navigate(routes.auth.taskGlide.home.href)
        }
    }
};

export const useGetRegistrationStatus = ({vfExists = false} = {}) => {
    const path = window.location.pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        registrationStatusVF,
        registrationStatusApiState,
        registrationStatusResponse,
    } = useSelector(state => state.getRegistrationStatusReducer);
    const {userIsAuthenticated, authenticationApiState} = useAuthentication();
    const {subscriptionCreationApiState} = useCreateSubscription();

    const registrationStatusSuccessVF = registrationStatusApiState === 'succeeded' && registrationStatusVF;
    const registrationStatusFailureVF = registrationStatusApiState === 'failed' && registrationStatusVF;
    const registrationStatusLoadingVF = registrationStatusApiState === 'loading' && registrationStatusVF;

    const getRegistrationStatus = () => userIsAuthenticated && dispatch(registrationStatusRETRIEVE());

    // Visual feedback based on registration status API state
    useEffect(() => {
        if (vfExists && (registrationStatusFailureVF || registrationStatusSuccessVF)) {
            const timer = setTimeout(() => {
                dispatch(clearRegistrationStatusVF());
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [registrationStatusApiState]);

    useEffect(() => {
        if (userIsAuthenticated && registrationStatusApiState === 'succeeded') {
            registrationBasedNav(navigate, registrationStatusResponse, subscriptionCreationApiState);
        }
    }, [registrationStatusApiState, path, authenticationApiState, userIsAuthenticated, subscriptionCreationApiState]);

    return {
        getRegistrationStatus,
        registrationStatusVF,
        registrationStatusApiState,
        registrationStatusResponse,
        registrationStatusLoadingVF
    };
};
