import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {useAuthentication} from "../users/useAuthentication";
import {clearPromoCodeValidationVF, promoCodeValidationPOST, resetPromoCodeValidation} from "../../../api/features/payments/validatePromoCodeSlice";


export const useValidatePromoCode = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Promo Validation Method
    const {
        promoCodeValidationVF,
        promoCodeValidationApiState,
        promoCodeValidationResponse,
        promoCodeValidationError,
    } = useSelector(state => state.validatePromoCodeReducer);

    // API State with visual feedback
    const promoCodeValidationSuccessVF = promoCodeValidationApiState === 'succeeded' && promoCodeValidationVF;
    const promoCodeValidationFailureVF = promoCodeValidationApiState === 'failed' && promoCodeValidationVF;

    const validatePromoCode = (values) => userIsAuthenticated && dispatch(promoCodeValidationPOST(values));

    useEffect(() => {
        if (vfExists) {
            if (promoCodeValidationSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearPromoCodeValidationVF());
                }, 250);

                return () => clearTimeout(timer);
            } else if (promoCodeValidationFailureVF) {
                dispatch(clearPromoCodeValidationVF());

                dispatch(setAlert({
                    message: promoCodeValidationError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetPromoCodeValidation());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [promoCodeValidationApiState, promoCodeValidationVF]);

    return {
        validatePromoCode,
        promoCodeValidationVF,
        promoCodeValidationApiState,
        promoCodeValidationResponse,
        promoCodeValidationError,
        promoCodeValidationSuccessVF,
        promoCodeValidationFailureVF,
    };
};

