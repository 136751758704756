import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const userPreferencesRETRIEVE = createAsyncThunk(
    'users/userPreferences/retrieve',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_PREFERENCES_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userPreferencesRetrievalApiState: "idle",
    userPreferencesRetrievalVF: false,
    userPreferencesRetrievalResponse: null,
    userPreferencesRetrievalError: null,
};

export const getUserPreferencesSlice = createSlice({
    name: 'userPreferencesRetrieval',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserPreferencesRetrievalVF: (state) => {
            state.userPreferencesRetrievalVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userPreferencesRETRIEVE.pending, (state) => {
                state.userPreferencesRetrievalApiState = 'loading';
                state.userPreferencesRetrievalVF = true;
            })
            .addCase(userPreferencesRETRIEVE.fulfilled, (state, action) => {
                state.userPreferencesRetrievalApiState = 'succeeded';
                state.userPreferencesRetrievalResponse = action.payload;
                state.userPreferencesRetrievalVF = true;
                state.userPreferencesRetrievalError = null;
            })
            .addCase(userPreferencesRETRIEVE.rejected, (state, action) => {
                state.userPreferencesRetrievalApiState = 'failed';
                state.userPreferencesRetrievalError = action?.payload;
                state.userPreferencesRetrievalVF = true;
                state.userPreferencesRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const { clearUserPreferencesRetrievalVF } = getUserPreferencesSlice.actions;

export default getUserPreferencesSlice.reducer;
