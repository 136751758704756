import React from "react";
import clsx from "clsx";
import {useNavigate} from "react-router-dom";
import {IoCheckmarkSharp} from "react-icons/io5";
import {HiOutlineMagnifyingGlass} from "react-icons/hi2";

import routes from "../../../Routes/routes";


export const products = [
    {
        name: 'TaskGlide',
        id: 'task-glide',
        active: true,
        planHref: routes.unAuth.subscribe.find(plan => plan.name === 'TaskGlide').href,
        productHref: routes.unAuth.products.find(product => product.name === 'TaskGlide').href,
        audience: 'everyone',
        description: 'Curated Super Intelligent assistant learning and evolving with you.',
        features: [
            "Personalized responses based on user profile",
            "Efficient decision-making assistance",
            "Ideal for daily tasks and queries",
            "Learns and evolves with user interactions",
            "Tailored insights based on individual interests"
        ],
    },
    {
        name: 'MemoMinder',
        id: 'memo-minder',
        active: false,
        planHref: routes.unAuth.subscribe.find(plan => plan.name === 'MemoMinder').href,
        productHref: routes.unAuth.products.find(product => product.name === 'MemoMinder').href,
        audience: 'everyone',
        description: 'Your Super Intelligent organizer for to-do lists, reminders, and calendar management.',
        features: [
            "Effortless to-do list management",
            "Smart reminders based on your schedule",
            "Integrated calendar for seamless planning",
            "Personalized task prioritization",
            "Synchronization across all your devices"
        ],
    },
    {
        name: 'CodeCraft',
        id: 'code-craft',
        active: false,
        planHref: routes.unAuth.subscribe.find(plan => plan.name === 'CodeCraft').href,
        productHref: routes.unAuth.products.find(product => product.name === 'CodeCraft').href,
        audience: 'developers',
        description: 'Expert Super Intelligent companion for coding, debugging, optimization and more.',
        features: [
            "Adaptive support based on user's coding proficiency",
            "Assistance in writing, debugging, and optimizing code",
            "Python code compiling for data analysis",
            "Capable of establishing a large application's base",
            "Aids in technical practice for coding exams/interviews"
        ],
    },
    {
        name: 'Scholar',
        id: 'scholar',
        active: false,
        planHref: routes.unAuth.subscribe.find(plan => plan.name === 'Scholar').href,
        productHref: routes.unAuth.products.find(product => product.name === 'Scholar').href,
        audience: 'researchers',
        description: 'Streamlining research with Super Intelligent powered reading and summarization.',
        features: [
            "Instant summaries of numerous research papers",
            "In-depth responses to research-related queries",
            "Citations and sources provided for responses",
            "Ability to create new research papers",
            "Adapts to the user's field of interest"
        ],
    },
    {
        name: 'ProseMaster',
        id: 'prose-master',
        active: false,
        planHref: routes.unAuth.subscribe.find(plan => plan.name === 'ProseMaster').href,
        productHref: routes.unAuth.products.find(product => product.name === 'ProseMaster').href,
        audience: 'writers',
        description: 'Your Super Intelligent partner for precise, effective writing and any type of documentation.',
        features: [
            "Expert in various documentation formats",
            "Flawless grammar and style suggestions",
            "Customized results based on user preferences",
            "Continually improves to match user's writing topics",
            "Assists in creating a wide range of documents"
        ],
    },
    {
        name: 'SavantIQ',
        id: 'savant-iq',
        active: false,
        planHref: routes.unAuth.subscribe.find(plan => plan.name === 'SavantIQ').href,
        productHref: routes.unAuth.products.find(product => product.name === 'SavantIQ').href,
        audience: 'students',
        description: 'An intuitive Super Intelligent tutor for students at any study level.',
        features: [
            "Customized learning material based on user profile",
            "Adapts to the learning pace of the student",
            "Provides unique, personalized educational content",
            "Teaches a wide range of topics",
            "Caters to learners of all ages"
        ],
    },
    {
        name: 'DataDive',
        id: 'data-dive',
        active: false,
        planHref: routes.unAuth.subscribe.find(plan => plan.name === 'DataDive').href,
        productHref: routes.unAuth.products.find(product => product.name === 'DataDive').href,
        audience: 'data scientists',
        description: 'Super Intelligent powered analysis tool for deep data exploration.',
        features: [
            "Connects with large data for comprehensive data collection",
            "Python support for versatile data analysis",
            "Generates visualizations and charts for data interpretation",
            "Allows data export in various formats (CSV, PDF, etc.)",
            "Adaptive support based on user's data science proficiency"
        ],
    },
    {
        name: 'FiscalFlow',
        id: 'fiscal-flow',
        active: false,
        planHref: routes.unAuth.subscribe.find(plan => plan.name === 'FiscalFlow').href,
        productHref: routes.unAuth.products.find(product => product.name === 'FiscalFlow').href,
        audience: 'financial analysts',
        description: 'Your Super Intelligent assistant for real-time financial analysis.',
        features: [
            "Connects with financial and news for live data",
            "Understands all financial analysis and valuation metrics",
            "Aids in making informed decisions for asset/property purchases",
            "Generates financial visualizations and charts",
            "Allows data export in various formats (CSV, PDF, etc.)"
        ],
    },
];

const Store = () => {
    const navigate = useNavigate();
    const [query, setQuery] = React.useState('')

    const filteredProducts =
        query === ''
        ? products
        : products.filter((tier) => {
            const lowerCaseQuery = query.toLowerCase().replace(/\s+/g, '');
            return tier.name.toLowerCase().replace(/\s+/g, '').includes(lowerCaseQuery)
                || tier.description.toLowerCase().replace(/\s+/g, '').includes(lowerCaseQuery)
                || tier.audience.toLowerCase().replace(/\s+/g, '').includes(lowerCaseQuery)
                || tier.features.some(feature => feature.toLowerCase().replace(/\s+/g, '').includes(lowerCaseQuery)
            )});

    return (
        <main className="isolate" >
            <div className="relative isolate -z-10 bg-transparent py-20 sm:py-32" >
                <div className="absolute left-1/2 -z-10 -ml-24 transform-gpu blur-3xl lg:-ml-10 xl:ml-10" aria-hidden="true" >
                    <div
                        className="aspect-[700/1036] w-[50.0625rem] bg-gradient-to-tr from-secondary to-accent opacity-30"
                        style={{
                            clipPath:
                                'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                        }}
                    />
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="py-14 text-start sm:text-center" >
                        <div className="mx-auto max-w-4xl space-y-4">
                            <h2 className="text-base font-medium leading-7 text-accent">Store</h2>
                            <p className="text-4xl font-bold tracking-tight text-base-content sm:text-5xl">
                                Explore Our Product Range
                            </p>
                        </div>
                        <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-base-content/70 pb-10">
                            Delve into our diverse assortment of Super Intelligent powered products. Select the perfect tool tailored to enhance your productivity and fit your specific needs.
                        </p>
                        <div className="group relative isolate mx-auto w-full sm:w-80">
                            <label
                                htmlFor="name"
                                className="absolute -top-2 left-2 group-focus-within:inline-block bg-base-100 px-1 text-xs font-medium group-focus-within:text-accent-focus dark:group-focus-within:text-accent hidden z-10 leading-none tracking-tighter"
                            >
                                Product name / Feature / audience
                            </label>
                            <div className="relative group flex items-center">
                                <input
                                    type="text"
                                    name="search"
                                    id="search"
                                    autoComplete={"off"}
                                    placeholder={'Product name / Feature / audience'}
                                    className="block w-full appearance-none rounded-md border-0 bg-base-100 shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-[0.75px] ring-base-content/20 dark:ring-base-content/20 outline-none text-base-content text-sm sm:leading-6 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light disabled:pointer-events-none disabled:text-base-content/70 py-1 focus-visible:ring focus-visible:outline-none focus-visible:ring-accent/30 dark:focus-visible:ring-accent/30 focus-visible:rounded-md focus-visible:ring-offset-1 focus-visible:ring-offset-accent/10 focus:ring-0 font-light justify-center"
                                    value={query}
                                    onChange={(event) => setQuery(event.target.value)}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <HiOutlineMagnifyingGlass className="h-5 w-5 text-base-300/70 group-focus-within:text-accent" aria-hidden="true"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="isolate mx-auto mt-10 grid max-w-sm grid-cols-1 gap-8 md:max-w-3xl md:grid-cols-2 xl:max-w-6xl xl:grid-cols-3">
                        {filteredProducts.map((product) => (
                            <div
                                key={product.id}
                                className={clsx("ring-1 ring-neutral/70 bg-base-200 drop-shadow rounded-3xl p-8 space-y-6", !product?.active && "opacity-75")}
                            >
                                <div className="space-y-1">
                                    <p className="text-accent text-xs font-medium" >
                                        {product.audience}
                                    </p>
                                    <h3
                                        id={product.id}
                                        className="text-base-content text-2xl font-semibold leading-8">
                                        {product.name}
                                    </h3>
                                    <p className="text-sm leading-6 text-base-content/70">{product.description}</p>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => navigate(routes.unAuth.products.find(item => item.name === product.name).href)}
                                    disabled={!product?.active}
                                    className="accent-button"
                                >
                                    {product?.active ? "Learn More" : "Coming Soon"}
                                </button>
                                <ul className="space-y-3 text-sm leading-6 text-base-content/70">
                                    {product.features.map((feature) => (
                                        <li key={feature} className="flex gap-x-3">
                                            <IoCheckmarkSharp
                                                className="text-accent size-5 flex-none"
                                                aria-hidden="true"
                                            />
                                            <p>{feature}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </main>
    )
};

export default Store;
