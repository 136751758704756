import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const subscriptionPlansLIST = createAsyncThunk(
    'payments/subscription-plans/list',
    async (thunkAPI) => {
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_SUBSCRIPTION_PLANS_LIST_PATH}`,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    subscriptionPlansListingVF: false,
    subscriptionPlansListingApiState: 'idle',
    subscriptionPlansListingResponse: null,
    subscriptionPlansListingError: null,
};

export const listSubscriptionPlansSlice = createSlice({
    name: 'subscriptionPlansListing',
    initialState: INITIAL_STATE,
    reducers: {
        clearSubscriptionPlansListingVF: (state) => {
            state.subscriptionPlansListingVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(subscriptionPlansLIST.pending, (state) => {
                state.subscriptionPlansListingApiState = 'loading';
                state.subscriptionPlansListingVF = true;
            })
            .addCase(subscriptionPlansLIST.fulfilled, (state, action) => {
                state.subscriptionPlansListingApiState = 'succeeded';
                state.subscriptionPlansListingResponse = action.payload;
                state.subscriptionPlansListingVF = true;
                state.subscriptionPlansListingError = null;
            })
            .addCase(subscriptionPlansLIST.rejected, (state, action) => {
                state.subscriptionPlansListingApiState = 'failed';
                state.subscriptionPlansListingError = action?.payload;
                state.subscriptionPlansListingVF = true;
                state.subscriptionPlansListingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearSubscriptionPlansListingVF
} = listSubscriptionPlansSlice.actions;

export default listSubscriptionPlansSlice.reducer;
