import React, {useState} from "react";
import PropTypes from "prop-types";
import {BsClipboard, BsClipboardCheck, BsClipboardCheckFill, BsClipboardFill} from "react-icons/bs";
import clsx from "clsx";

const CodeHeader = ({ language, code }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    }

    return (
        <>
            <span className={clsx(
                "absolute top-3 left-3 font-sans cursor-default text-xs sm:text-2xs rounded px-3 tracking-wide font-medium", "bg-base-100 text-secondary-focus"
            )}>
                {language[1].charAt(0).toUpperCase() + language[1].slice(1)}
            </span>

            {/* Copy button */}
            <button
                className={"absolute top-2 right-2 cursor-pointer items-center gap-x-2 bg-base-100 p-1.5 rounded-full group/copy-btn"}
                onClick={handleCopy}
            >
                {copied ?
                    <div>
                        <BsClipboardCheck className="w-3.5 h-3.5 sm:w-3 sm:h-3 text-success-content group-hover/copy-btn:sm:hidden" />
                        <BsClipboardCheckFill className="w-3.5 h-3.5 sm:w-3 sm:h-3 text-success-content hidden group-hover/copy-btn:sm:flex" />
                    </div> :
                    <div>
                        <BsClipboard className="w-3.5 h-3.5 sm:w-3 sm:h-3 text-base-content group-hover/copy-btn:sm:hidden" />
                        <BsClipboardFill className="w-3.5 h-3.5 sm:w-3 sm:h-3 text-base-content hidden group-hover/copy-btn:sm:flex" />
                    </div>}
            </button>
        </>
    )
};

CodeHeader.propTypes = {
    language: PropTypes.array.isRequired,
    code: PropTypes.array.isRequired,
};

export default CodeHeader;
