import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const stripeCustomerGET = createAsyncThunk(
    'users/stripeCustomer/get',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_RETRIEVE_STRIPE_CUSTOMER_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    stripeCustomerRetrievalApiState: "idle",
    stripeCustomerRetrievalVF: false,
    stripeCustomerRetrievalResponse: null,
    stripeCustomerRetrievalError: null,
};

export const getStripeCustomerSlice = createSlice({
    name: 'getStripeAccount',
    initialState: INITIAL_STATE,
    reducers: {
        clearStripeCustomerRetrievalVF: (state) => {
            state.stripeCustomerRetrievalVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(stripeCustomerGET.pending, (state) => {
                state.stripeCustomerRetrievalApiState = 'loading';
                state.stripeCustomerRetrievalVF = true;
            })
            .addCase(stripeCustomerGET.fulfilled, (state, action) => {
                state.stripeCustomerRetrievalApiState = 'succeeded';
                state.stripeCustomerRetrievalResponse = action.payload;
                state.stripeCustomerRetrievalVF = true;
                state.stripeCustomerRetrievalError = null;
            })
            .addCase(stripeCustomerGET.rejected, (state, action) => {
                state.stripeCustomerRetrievalApiState = 'failed';
                state.stripeCustomerRetrievalError = action?.payload;
                state.stripeCustomerRetrievalVF = true;
                state.stripeCustomerRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const { clearStripeCustomerRetrievalVF } = getStripeCustomerSlice.actions;

export default getStripeCustomerSlice.reducer;
