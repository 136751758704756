import React from "react";

const PreferencesRegFormSkeleton = () => {

    return (
        <>
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 py-8 md:py-0 lg:grid-cols-9 w-full px-px" >
                <div className="col-span-full md:col-span-4 md:hidden">
                    <div className="h-4 w-36 bg-base-300/30 rounded-md" />
                    <div className="mt-4 space-y-3" >
                        <div className="bg-base-300/30 w-2/3 h-4 rounded-md" />
                    </div>
                </div>
                <div className="col-span-full py-px">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10">
                        {/* Updates */}
                        <div className="relative flex gap-x-4 w-full">
                            <div className="p-1 skeleton bg-base-300/30 rounded w-4 h-4"/>
                            <div className="space-y-3 w-full">
                                <div className="skeleton bg-base-300/30 rounded h-4 w-32"/>
                                <div className="skeleton bg-base-300/30 rounded h-4 w-full"/>
                                <div className="skeleton bg-base-300/30 rounded h-4 w-1/4"/>
                            </div>
                        </div>

                        {/* Promotional Offers */}
                        <div className="relative flex gap-x-4 w-full">
                            <div className="p-1 skeleton bg-base-300/30 rounded w-4 h-4"/>
                            <div className="space-y-3 w-full">
                                <div className="skeleton bg-base-300/30 rounded h-4 w-32"/>
                                <div className="skeleton bg-base-300/30 rounded h-4 w-full"/>
                                <div className="skeleton bg-base-300/30 rounded h-4 w-1/4"/>
                            </div>
                        </div>

                        {/* AccountSettings Alerts */}
                        <div className="relative flex gap-x-4 w-full">
                            <div className="p-1 skeleton bg-base-300/30 rounded w-4 h-4"/>
                            <div className="space-y-3 w-full">
                                <div className="skeleton bg-base-300/30 rounded h-4 w-32"/>
                                <div className="skeleton bg-base-300/30 rounded h-4 w-full"/>
                            </div>
                        </div>

                        <div className="col-span-full">
                            <div className="mt-3 bg-base-300/30 skeleton rounded-md h-8 w-full"/>
                        </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <div className="h-4 w-12 bg-base-300/30 skeleton rounded"/>
                        <div className="h-8 w-14 bg-base-300/30 skeleton rounded-md"/>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PreferencesRegFormSkeleton;


