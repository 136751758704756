import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import {generateTimeOptions} from "../../reusableFxns/dateTimeFxns";
import ButtonDropDown from "../ButtonDropDown/ButtonDropDown";


const TimePicker = (
    { selectedTime, setSelectedTime, useFullScreen = false, timeButtonClassName, disabled, isTimeDisabled }
) => {
    const times = generateTimeOptions();

    const onTimeClick = (time) => {
        setSelectedTime(time);
    };

    return (
        <ButtonDropDown
            buttonText={selectedTime}
            useFullScreen={useFullScreen}
            buttonClassName={timeButtonClassName}
            dropdownClassName="max-h-56 overflow-y-auto overflow-x-hidden scrollbar"
            disabled={disabled}
        >
            {times?.map(time => (
                <button
                    key={time}
                    type="button"
                    className={clsx(
                        "enabled:hover:bg-base-200 p-1 w-28 text-center cursor-pointer",
                        isTimeDisabled(time) && 'cursor-not-allowed opacity-50',
                        selectedTime === time && "bg-base-200"
                    )}
                    onClick={() => !isTimeDisabled(time) && onTimeClick(time)}
                    disabled={isTimeDisabled(time)}
                >
                    {time}
                </button>
            ))}
        </ButtonDropDown>
    );
};

TimePicker.propTypes = {
    selectedTime: PropTypes.string,
    setSelectedTime: PropTypes.func,
    useFullScreen: PropTypes.bool,
    timeButtonClassName: PropTypes.string,
    disabled: PropTypes.bool,
    isTimeDisabled: PropTypes.func,
}

export default TimePicker;
