import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import clsx from "clsx";

import routes from "../../../Routes/routes";
import darkBg from "../../../Design/backgrounds/darkGrayBlack.png";
import lightBg from "../../../Design/backgrounds/lightGrayWhite.png";
import PersonalInformationForm from "./Forms/UserInfoRegForm/PersonalInformationForm";
import PreferencesForm from "./Forms/PreferencesForm/PreferencesForm";
import MobileRegistrationSteps from "./Steps/MobileRegistrationSteps";
import RegistrationSteps from "./Steps/RegistrationSteps";
import Nav from "../Shell/Nav/Nav";
import SubscriptionForm from "./Forms/SubscriptionForm/SubscriptionForm";


const steps = [
    {
        name: 'Personal Information',
        description: 'Provide your personal information to curate your experience on the platform.',
        href: routes.auth.registration.find(form => form.name === 'Personal Information').href
    },
    {
        name: 'Preferences',
        description: 'Choose your preferred language and communication preferences.',
        href: routes.auth.registration.find(form => form.name === 'Preferences').href
    },
    {
        name: 'Subscription',
        description: 'Subscribe to your chosen products and services.',
        href: routes.auth.registration.find(form => form.name === 'Subscription').href
    },
];

const Registration = () => {
    const theme = useSelector(state => state.frontendReducer.theme);

    const path = window.location.pathname;
    const step = path.split('/').pop();

    const renderForm = () => {
        switch(step) {
            case 'personal-info':
                return <PersonalInformationForm />;
            case 'preferences':
                return <PreferencesForm />;
            case 'subscription':
                return <SubscriptionForm />;
            default:
                return null;
        }
    };

    return (
        <>
            <Nav/>
            <img className={clsx(["fixed top-0 w-full h-full z-0 object-cover", theme === 'dark' ? "hidden" : "hidden md:block"])} src={lightBg} alt="lightBg" />
            <img className={clsx(["fixed top-0 w-full h-full z-0 object-cover", theme === 'light' ? "hidden" : "hidden md:block"])} src={darkBg} alt="darkBg" />
            <div className="w-full h-dvh overflow-hidden" >
                <MobileRegistrationSteps steps={steps} />
                <div className="h-[calc(100dvh-141px)] sm:h-[calc(100dvh-182px)] md:h-[calc(100dvh-56px)] overflow-y-auto scrollbar w-full">
                    <div className="md:px-8 md:mx-auto md:max-w-7xl h-full">

                        {/* Left Section */}
                        <div className="grid grid-cols-full md:grid-cols-8 md:grid-flow-row md:col-span-4 h-fit md:flex">

                            <div className="py-12 px-4 sm:px-6 md:px-0 mx-auto max-w-7xl md:mx-0 md:max-w-none md:space-y-10 w-full z-[5] md:pr-8 hidden md:block">
                                <header className="min-w-0 space-y-4">
                                    <h2 className="text-2xl sm:text-3xl font-bold leading-7 text-base-content sm:truncate sm:tracking-tight">Join Dovise</h2>
                                    <div className="space-y-2 leading-6 text-sm text-base-content/70 hidden md:block" >
                                        <p>
                                            Registering with Dovise provides you with comprehensive access to our advanced intelligent technologies and exclusive toolset.
                                        </p>
                                        <p>
                                            Your account details and preferences can be updated post-registration via your personalized account settings.
                                        </p>
                                    </div>
                                </header>
                                <div className="py-4">
                                    <RegistrationSteps steps={steps}/>
                                </div>
                                <p className="leading-6 text-sm text-base-content/70 hidden md:block">
                                    Our intuitive platform offers personalized support, real-time collaboration, and powerful tools to enhance your productivity and creativity.
                                </p>
                                <footer className="hidden md:block w-full">
                                    <p className="mt-2 leading-6 text-sm text-base-content/70">By registering, you agree to our <Link to="/" className="text-primary">Terms of Service</Link> and <Link to="/" className="text-primary">Privacy Policy</Link>.</p>
                                    <p className="mt-2 leading-6 text-sm text-base-content/70">Having trouble? Visit our <Link to="/" className="text-primary">Support Center</Link> or contact us at <Link to="mailto:contact@dovise.com" className="text-primary">contact@dovise.com</Link>.</p>
                                    <p className="mt-2 leading-6 text-sm text-base-content/70">Don&apos;t worry, we save your progress as you go.</p>
                                </footer>
                            </div>

                            {/*Forms*/}
                            <div className=" z-[4] col-span-full md:col-span-4 py-4 md:py-10 px-4 sm:px-6 md:px-px flex-auto w-full self-center h-full flex items-center md:pl-8">
                                <div className="w-full mx-auto max-w-7xl md:max-w-sm">
                                    {renderForm()}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
};

export default Registration;