import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const emailSubscriptionPOST = createAsyncThunk(
    'users/email/subscribe',
    async (values, thunkAPI) => {
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_POST_SUBSCRIBE_EMAIL_PATH}`,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    emailSubscriptionVF: false,
    emailSubscriptionApiState: 'idle',
    emailSubscriptionResponse: null,
    emailSubscriptionError: null,
};

export const emailSubscriptionSlice = createSlice({
    name: 'emailSubscription',
    initialState: INITIAL_STATE,
    reducers: {
        clearEmailSubscriptionVF: (state) => {
            state.emailSubscriptionVF = false;
        },
        resetEmailSubscription: (state) => {
            state.emailSubscriptionVF = false;
            state.emailSubscriptionApiState = 'idle';
            state.emailSubscriptionResponse = null;
            state.emailSubscriptionError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(emailSubscriptionPOST.pending, (state) => {
                state.emailSubscriptionApiState = 'loading';
                state.emailSubscriptionVF = true;
            })
            .addCase(emailSubscriptionPOST.fulfilled, (state, action) => {
                state.emailSubscriptionApiState = 'succeeded';
                state.emailSubscriptionResponse = action.payload;
                state.emailSubscriptionVF = true;
                state.emailSubscriptionError = null;
            })
            .addCase(emailSubscriptionPOST.rejected, (state, action) => {
                state.emailSubscriptionApiState = 'failed';
                state.emailSubscriptionError = action?.payload;
                state.emailSubscriptionVF = true;
                state.emailSubscriptionResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET_USER_MANAGEMENT') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearEmailSubscriptionVF,
    resetEmailSubscription
} = emailSubscriptionSlice.actions;

export default emailSubscriptionSlice.reducer;
