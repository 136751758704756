import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {useListAllTgConversations} from "./useListAllTgConversations";
import {allTgConversationsDELETE, clearTgAllConversationsDeleteVF} from "../../../../../api/features/superIntelligence/taskGlide/conversations/deleteAllTgConversationsSlice";


export const useDeleteAllTgConversations = (
    {vfExists=false, autoUpdate=false} = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // Task Glide
    const {
        tgAllConversationsDeleteVF,
        tgAllConversationsDeleteApiState,
        tgAllConversationsDeleteResponse,
        tgAllConversationsDeleteError,
    } = useSelector(state => state.deleteAllTgConversationsReducer);
    const { listAllTgConversations } = useListAllTgConversations();

    const [dataIsRefreshed, setDataIsRefreshed] = useState(false);

    // API states with visual feedback
    const tgAllConversationsDeleteSuccessVF = tgAllConversationsDeleteApiState === 'succeeded' && tgAllConversationsDeleteVF;
    const tgAllConversationsDeleteFailureVF = tgAllConversationsDeleteApiState === 'failed' && tgAllConversationsDeleteVF;

    const deleteAllTGConversations = (conversation_id) => {
        setDataIsRefreshed(false);
        userIsAuthenticated && dispatch(allTgConversationsDELETE({conversation_id}))
    };

    useEffect(() => {
        if (vfExists && tgAllConversationsDeleteSuccessVF) {
            const timer = setTimeout(() => {
                dispatch(clearTgAllConversationsDeleteVF());
            }, 250);

            return () => clearTimeout(timer);
        }
    }, [tgAllConversationsDeleteApiState, tgAllConversationsDeleteVF]);

    useEffect(() => {
        if (autoUpdate && tgAllConversationsDeleteApiState === 'succeeded' && !dataIsRefreshed) {
            setDataIsRefreshed(true);
            listAllTgConversations();
        }
    }, [tgAllConversationsDeleteApiState, tgAllConversationsDeleteVF]);

    return {
        deleteAllTGConversations,
        tgAllConversationsDeleteApiState,
        tgAllConversationsDeleteResponse,
        tgAllConversationsDeleteError,
        tgAllConversationsDeleteSuccessVF,
        tgAllConversationsDeleteFailureVF,
        tgAllConversationsDeleteVF,
    };
};
