import React, {Fragment, useEffect, useRef, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Link, useNavigate} from "react-router-dom";
import clsx from "clsx";

import Nav from "../../../../Shell/Nav/Nav";
import routes from "../../../../../../Routes/routes";
import DeleteAllMyGlidesModal from "./DeleteAllMyGlidesModal";
import {useListUserGlides} from "../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useListUserGlides";
import {glideColorClasses, getGlideIcon} from "../../Chat/ChatBox/Messages/glideInfo";
import {useDeleteAllUserGlides} from "../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/glides/useDeleteAllUserGlides";
import MyGlideSkeleton from "./MyGlideSkeleton";
import MyGlideExtrasMenu from "./MyGlideExtrasMenu";
import {useGeneralUiActions} from "../../../../../../Hooks/frontendHooks/useGeneralUiActions";


const MyGlides = () => {
    const navigate = useNavigate();
    const myGlideMenuParentRef = useRef(null);
    const { firstRender, handleFirstRender } = useGeneralUiActions();
    const { getGlides, glidesListingResponse, glidesListingVF } = useListUserGlides({ vfExists: true });
    const { deleteAllUserDefinedGlides, userDefinedGlidesDeleteAllVF } = useDeleteAllUserGlides();
    const [defaultStateUpdated, setDefaultStateUpdated] = useState(false);

    const doviseCreatedGlides = glidesListingResponse?.every(item => item?.glide?.uuid === process.env.REACT_APP_TASKGLIDE_TG_UUID);
    const firstLoadingStates = (firstRender || glidesListingVF) && !defaultStateUpdated;

    useEffect(() => {
        getGlides();
        handleFirstRender(1000);
    }, []);

    return (
        <>
            <Nav/>
            <div className="py-3 bg-base-100 sticky top-0 right-0 left-0 lg:left-16 border-b border-neutral-focus/40 dark:border-neutral-focus-dark/40 z-[2]">
                <div className="md:flex md:items-center md:justify-between space-y-3 md:space-y-0 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="flex items-center space-x-3 py-2">
                        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:tracking-tight text-base-content">My Glides</h1>
                    </div>
                    <div className="flex gap-x-3">
                        {(firstLoadingStates && !glidesListingResponse) ?
                            <>
                                <div className="rounded-md bg-base-300/30 skeleton h-8 w-20 outline-none shadow shadow-gray-400/70 dark:shadow-black/50"/>
                                <div className="rounded-md bg-base-300/30 skeleton h-8 w-24 outline-none shadow shadow-gray-400/70 dark:shadow-black/50"/>
                            </> :
                            <>
                                <DeleteAllMyGlidesModal
                                    disabledState={userDefinedGlidesDeleteAllVF || doviseCreatedGlides}
                                    onDelete={deleteAllUserDefinedGlides}
                                />
                                <Link
                                    type="button"
                                    to={routes.auth.taskGlide.newGlideSetup.href}
                                    className="primary-button"
                                >
                                    Create new
                                </Link>
                            </>}
                    </div>
                </div>
            </div>
            {(firstLoadingStates && !glidesListingResponse) ?
                <MyGlideSkeleton /> :
                <div ref={myGlideMenuParentRef} className="relative flex flex-col w-full z-10 h-[calc(100dvh-214px)] md:h-[calc(100dvh-125px)] scrollbar overflow-y-auto">
                    <AnimatePresence>
                        {glidesListingResponse?.map((item, i) => {
                            const agent = item?.glide;

                            return (
                                <motion.div
                                    key={item.id}
                                    initial={{opacity: 0, y: -25}}
                                    animate={{opacity: 1, y: 0}}
                                    exit={{opacity: 0, y: 25}}
                                    transition={{duration: 0.5, delay: i * 0.1}}
                                    className="relative bg-base-100 hover:sm:bg-base-200/50"
                                >
                                    <div className="flex items-center justify-between space-x-3 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-sm w-full">
                                        <button
                                            type="button"
                                            onClick={() => navigate(`${agent?.uuid}/test`)}
                                            className="flex items-center space-x-3 text-start flex-1 py-4">
                                            <div className={clsx(
                                                "rounded-full p-2.5 text-base-100 leading-none",
                                                glideColorClasses[agent?.color]?.bg)}
                                            >
                                                {getGlideIcon(agent?.name, "w-7 h-7 sm:w-5 sm:h-5")}
                                            </div>
                                            <div className="space-y-1">
                                                <div className="flex items-center space-x-3">
                                                    <p className="font-semibold">{agent?.name}</p>
                                                    {item?.is_default && <div className="secondary-badge">Default</div>}
                                                </div>
                                                <p className="text-base-content/70 font-light">{agent?.description}</p>
                                            </div>
                                        </button>
                                        <div className="flex items-center space-x-3">
                                            <div className="flex flex-col items-end w-full space-y-1.5 text-end">
                                                <div className={agent?.is_public ? "success-badge" : "neutral-badge"} >
                                                    {agent?.is_public ? "Public" : "Private"}
                                                </div>
                                                <div className="inline-flex space-x-1 rounded-md px-1 text-xs sm:text-2xs w-fit">
                                                    <p className="text-base-content/70">by</p>
                                                    <p className={clsx(
                                                        "tracking-wide font-medium",
                                                        !agent?.user_created ? "text-primary" : "text-base-content")}
                                                    >
                                                        {!agent?.user_created ? "Dovise" : `@${agent?.created_by_username}`}
                                                    </p>
                                                </div>
                                            </div>
                                            <MyGlideExtrasMenu
                                                key={agent?.uuid}
                                                setDefaultStateUpdated={setDefaultStateUpdated}
                                                item={item}
                                                myGlideMenuParentRef={myGlideMenuParentRef}
                                            />
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        )}
                </AnimatePresence>
            </div>}
        </>
    )
};

export default MyGlides;
