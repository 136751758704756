import {a11yDark} from "react-syntax-highlighter/src/styles/hljs";

export const feature1Theme = () => {
    let myCustomStyle = {...a11yDark};

    myCustomStyle['hljs'] = {...myCustomStyle['hljs'], 'backgroundColor': 'rgb(26,30,40)', 'background': '#181A24'};
    myCustomStyle['hljs-keyword'] = {...myCustomStyle['hljs-keyword'], 'color': '#5B9BD5'};
    myCustomStyle['hljs-comment'] = {...myCustomStyle['hljs-comment'], 'color': '#949494'};
    myCustomStyle['hljs-tag'] = {...myCustomStyle['hljs-tag'], 'color': '#FFC75F'};
    myCustomStyle['hljs-name'] = {...myCustomStyle['hljs-name'], 'color': '#FFC75F'};
    myCustomStyle['hljs-regexp'] = {...myCustomStyle['hljs-regexp'], 'color': '#FFC75F'};
    myCustomStyle['hljs-link'] = {...myCustomStyle['hljs-link'], 'color': '#2aa198'};
    myCustomStyle['hljs-selector-id'] = {...myCustomStyle['hljs-selector-id'], 'color': '#9b703f'};
    myCustomStyle['hljs-selector-class'] = {...myCustomStyle['hljs-selector-class'], 'color': '#9b703f'};
    myCustomStyle['hljs-meta'] = {...myCustomStyle['hljs-meta'], 'color': '#b58900'};
    myCustomStyle['hljs-built_in'] = {...myCustomStyle['hljs-built_in'], 'color': '#FF6B6B'};
    myCustomStyle['hljs-literal'] = {...myCustomStyle['hljs-literal'], 'color': '#6c71c4'};
    myCustomStyle['hljs-type'] = {...myCustomStyle['hljs-type'], 'color': '#2aa198'};
    myCustomStyle['hljs-params'] = {...myCustomStyle['hljs-params'], 'color': '#FFFFFF'};
    myCustomStyle['hljs-title'] = {...myCustomStyle['hljs-title'], 'color': '#A763E8'};
    myCustomStyle['hljs-string'] = {...myCustomStyle['hljs-string'], 'color': '#73BA9B'};
    myCustomStyle['hljs-symbol'] = {...myCustomStyle['hljs-symbol'], 'color': '#2aa198'};
    myCustomStyle['hljs-section'] = {...myCustomStyle['hljs-section'], 'color': '#000'};
    myCustomStyle['hljs-selector-tag'] = {...myCustomStyle['hljs-selector-tag'], 'color': '#268bd2'};
    myCustomStyle['hljs-attr'] = {...myCustomStyle['hljs-attr'], 'color': '#A763E8'}
    myCustomStyle['hljs-number'] = {...myCustomStyle['hljs-number'], 'color': '#62D2E4'};
    myCustomStyle['hljs-emphasis'] = {...myCustomStyle['hljs-emphasis'], 'fontStyle': 'italic'};
    myCustomStyle['hljs-strong'] = {...myCustomStyle['hljs-strong'], 'fontWeight': 'bold'};
    myCustomStyle['hljs-formula'] = {...myCustomStyle['hljs-formula'], 'backgroundColor': '#000'};
    myCustomStyle['hljs-addition'] = {...myCustomStyle['hljs-addition'], 'backgroundColor': '#000'};
    myCustomStyle['hljs-deletion'] = {...myCustomStyle['hljs-deletion'], 'backgroundColor': '#000'};
    myCustomStyle['hljs-selector-attr'] = {...myCustomStyle['hljs-selector-attr'], 'color': '#62D2E4'};
    myCustomStyle['hljs-selector-pseudo'] = {...myCustomStyle['hljs-selector-pseudo'], 'color': '#62D2E4'};

    return myCustomStyle;
};
