import React from 'react';
import PropTypes from 'prop-types';

const MdTable = ({ children }) => {
    return (
        <div className="overflow-auto max-w-full">
            <table className="whitespace-nowrap mt-2 mb-4">{children}</table>
        </div>
    );
};

MdTable.defaultProps = {
    children: null,
};

MdTable.propTypes = {
    children: PropTypes.node,
};

export default MdTable;
