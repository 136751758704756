import {useEffect, useState} from "react";


export const useGeneralUiActions = () => {
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        setFirstRender(true);
    }, []);

    const handleFirstRender = (delay) => {
        const timer = setTimeout(() => {
            setFirstRender(false);
        }, delay);

        return () => clearTimeout(timer);
    };

    return {
        firstRender,
        handleFirstRender
    };
}