import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {clearGlidesListingVF, resetGlidesListing, glidesGET} from "../../../../../api/features/superIntelligence/taskGlide/glides/listUserGlidesSlice";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearUserDefinedGlideRetrievalVF, resetUserDefinedGlideRetrieval, userDefinedGlideGET} from "../../../../../api/features/superIntelligence/taskGlide/glides/getUserGlideDetailsSlice";


export const useGetUserGlideDetails = ({ vfExists=false } = {}) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // MyGlides
    const {
        userDefinedGlideRetrievalVF,
        userDefinedGlideRetrievalApiState,
        userDefinedGlideRetrievalResponse,
        userDefinedGlideRetrievalError,
    } = useSelector(state => state.getUserDefinedGlideReducer);

    // API states with visual feedback
    const userDefinedGlideRetrievalSuccessVF = userDefinedGlideRetrievalApiState === 'succeeded' && userDefinedGlideRetrievalVF;
    const userDefinedGlideRetrievalFailureVF = userDefinedGlideRetrievalApiState === 'failed' && userDefinedGlideRetrievalVF;

    const getUserDefinedGlide = (uuid) => userIsAuthenticated && dispatch(userDefinedGlideGET({ uuid }));

    useEffect(() => {
        if (vfExists) {
            if (userDefinedGlideRetrievalSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearUserDefinedGlideRetrievalVF());
                }, 500);

                return () => clearTimeout(timer);
            } else if (userDefinedGlideRetrievalFailureVF) {
                dispatch(setAlert({
                    message: userDefinedGlideRetrievalResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUserDefinedGlideRetrieval());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [userDefinedGlideRetrievalApiState, userDefinedGlideRetrievalVF]);

    return {
        getUserDefinedGlide,
        userDefinedGlideRetrievalVF,
        userDefinedGlideRetrievalApiState,
        userDefinedGlideRetrievalResponse,
        userDefinedGlideRetrievalError,
        userDefinedGlideRetrievalSuccessVF,
        userDefinedGlideRetrievalFailureVF
    };
};
