import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";

import {setCurrentTgConversation} from "../../../../../api/features/superIntelligence/taskGlide/frontend/getCurrentTgConversationSlice";
import {useGetSingleTgConversation} from "../conversations/useGetSingleTgConversation";


export const useGetCurrentTgConversation = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries(searchParams.entries());

    const {
        tgSingleConversationRetrievalVF,
        tgSingleConversationRetrievalApiState
    } = useGetSingleTgConversation();
    const {
        currentTgConversation,
        currentTestTgConversation,
    } = useSelector(state => state.getCurrentTgConversationReducer);

    const handleClearConversation = () => {
        dispatch(setCurrentTgConversation(null));
        setSearchParams({});
    };

    const handleClickNewConversation = (conversationUuid) => {
        if (currentTgConversation?.conversation_uuid !== conversationUuid) {
            setSearchParams({ ...currentParams, 'conversation_uuid': conversationUuid });
        }
    };

    useEffect(() => {
        if (tgSingleConversationRetrievalApiState === 'succeeded' && !tgSingleConversationRetrievalVF) {
            setSearchParams({ ...currentParams, 'conversation-history': 'close' });
        }
    }, [tgSingleConversationRetrievalVF]);

    return {
        currentTgConversation,
        currentTestTgConversation,
        handleClearConversation,
        handleClickNewConversation,
    }
};
