import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const resetPasswordPOST = createAsyncThunk(
    'users/account/reset-password',
    async (values, thunkAPI) => {
        const urlPath = window.location.pathname;
        const resetToken = urlPath.split('/').pop();

        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_RESET_PASSWORD_PATH}${resetToken}/`,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    passwordResetVF: false,
    passwordResetApiState: 'idle',
    passwordResetResponse: null,
    passwordResetError: null,
};

export const passwordResetSlice = createSlice({
    name: 'resetPassword',
    initialState: INITIAL_STATE,
    reducers: {
        clearPasswordResetVF: (state) => {
            state.passwordResetVF = false;
        },
        resetPasswordReset: (state) => {
            state.passwordResetVF = false;
            state.passwordResetApiState = 'idle';
            state.passwordResetResponse = null;
            state.passwordResetError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetPasswordPOST.pending, (state) => {
                state.passwordResetApiState = 'loading';
                state.passwordResetVF = true;
            })
            .addCase(resetPasswordPOST.fulfilled, (state, action) => {
                state.passwordResetApiState = 'succeeded';
                state.passwordResetResponse = action.payload;
                state.passwordResetVF = true;
                state.passwordResetError = null;
            })
            .addCase(resetPasswordPOST.rejected, (state, action) => {
                state.passwordResetApiState = 'failed';
                state.passwordResetError = action?.payload;
                state.passwordResetVF = true;
                state.passwordResetResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET' || action.type === 'RESET_USER_MANAGEMENT') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const { 
    resetPasswordReset,
    clearPasswordResetVF
} = passwordResetSlice.actions;

export default passwordResetSlice.reducer;
