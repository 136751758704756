async function performFetchAccess(endpoint) {
    const response = await fetch(`${endpoint}users/token/refresh/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: localStorage.getItem('refreshToken') }),
    });

    const data = await response.json();
    return { access: data.access, refresh: data.refresh };
}

let refreshPromise = null;

export function fetchAccess(endpoint) {
    if (!refreshPromise) {
        refreshPromise = performFetchAccess(endpoint).finally(() => {
            refreshPromise = null;
        });
    }

    return refreshPromise;
}