import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {useStreamTaskGlide} from "../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";


const DropDown = (
    { className, dropDownClass, dropdownIcon, items, dropDownParent, dropdownVisible, setDropdownVisible, additionalRefs }
) => {
    const dropdownRef = useRef(null);
    const [isNearBottom, setIsNearBottom] = useState(false);
    const { taskGlideStreamVF } = useStreamTaskGlide();

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !additionalRefs?.some(ref => ref.current && ref.current.contains(event.target))
        ) {
            setDropdownVisible(false);
        }
    };

    const toggleDropdown = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDropdownVisible(prev => !prev);
    };

    const checkPosition = () => {
        if (dropdownRef.current && dropDownParent?.current && !taskGlideStreamVF) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const chatBoxRect = dropDownParent.current.getBoundingClientRect();
            const distanceFromBottom = chatBoxRect.bottom - rect.bottom;
            setIsNearBottom(distanceFromBottom < 239);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('resize', checkPosition);
        if (dropDownParent?.current) {
            dropDownParent.current.addEventListener('scroll', checkPosition);
        }

        checkPosition();

        return () => {
                document.removeEventListener('click', handleClickOutside);
                window.removeEventListener('resize', checkPosition);
                if (dropDownParent?.current) {
                    dropDownParent.current.removeEventListener('scroll', checkPosition);
                }
            };
    }, [dropDownParent, additionalRefs, taskGlideStreamVF]);

    const filteredItems = items.filter(item => item !== null && item !== undefined);

    return  (
        <div className={clsx("relative group dropdown block h-fit", className, !isNearBottom ? "dropdown-bottom" : "dropdown-top")}>
            <div
                ref={dropdownRef}
                tabIndex={0}
                role="button"
                className="relative rounded-full text-base-content"
                onClick={toggleDropdown}
                onKeyDown={toggleDropdown}
                aria-expanded={dropdownVisible}
                aria-haspopup="true"
            >
                <span className="sr-only">Open options menu</span>
                {dropdownIcon}
            </div>
            <div
                className={clsx("button-dropdown", dropDownClass, !dropdownVisible && "hidden", !isNearBottom ? "mt-2" : "mb-2")}
                role="menu"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                tabIndex={0}
            >
                {filteredItems.map((item, index) => (
                    <div key={index}>
                        {React.isValidElement(item) ? (
                            React.cloneElement(item, { setDropdownVisible })
                        ) : (
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    item.onClick();
                                    setDropdownVisible(false);
                                }}
                                className="dropdown-item"
                            >
                                {item.icon && React.cloneElement(item.icon, { className: "size-3.5 shrink-0" })}
                                <p>{item.label}</p>
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

DropDown.propTypes = {
    className: PropTypes.string,
    dropDownClass: PropTypes.string,
    items: PropTypes.array,
    dropDownParent: PropTypes.object,
    setDropdownVisible: PropTypes.func,
    dropdownVisible: PropTypes.bool,
    additionalRefs: PropTypes.array,
    dropdownIcon: PropTypes.node
}

export default DropDown;
