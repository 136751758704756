import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {clearAuthenticationVF, loginPOST, unAuthenticate} from "../../../api/features/users/authenticationSlice";


export const useAuthentication = ({ vfExists = false } = {}) => {
    const dispatch = useDispatch();
    const {
        authenticationApiState,
        authenticationVF,
        userIsAuthenticated,
        authenticationResponse,
        authenticationError
    } = useSelector(state => state.authenticationReducer);

    const authenticationSuccessVF = authenticationApiState === 'succeeded' && authenticationVF;
    const authenticationFailureVF = authenticationApiState === 'failed' && authenticationVF;
    const authenticationLoadingVF = authenticationApiState === 'loading' && authenticationVF;

    const login = (values) => dispatch(loginPOST(values));
    const logout = () => dispatch(unAuthenticate());

    useEffect(() => {
        if (vfExists && authenticationSuccessVF) {
            const timer = setTimeout(() => {
                dispatch(clearAuthenticationVF());
            }, 2000);

            return () => clearTimeout(timer);
        } else if (vfExists && authenticationFailureVF) {
            dispatch(setAlert({
                message: authenticationError?.message || "Please try again.",
                type: "error"
            }));
            dispatch(incrementAlertKey());

            const timer = setTimeout(() => {
                dispatch(clearAuthenticationVF());
            }, 4000);

            return () => clearTimeout(timer);
        }
    }, [authenticationApiState, authenticationVF]);

    return {
        login,
        logout,
        authenticationApiState,
        authenticationVF,
        userIsAuthenticated,
        authenticationResponse,
        authenticationError,
        authenticationLoadingVF,
        authenticationFailureVF,
        authenticationSuccessVF
    };
};