import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {clearTgConversationSearchVF, tgConversationSearchGET} from "../../../../../api/features/superIntelligence/taskGlide/conversations/searchTgConversationsSlice";


export const useSearchTgConversations = (
    {vfExists=false} = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const {userIsAuthenticated} = useAuthentication();

    // Task Glide
    const {
        tgConversationSearchVF,
        tgConversationSearchApiState,
        tgConversationSearchResponse,
        tgConversationSearchError,
    } = useSelector(state => state.searchTgConversationsReducer);

    // API states with visual feedback
    const tgConversationSearchSuccessVF = tgConversationSearchApiState === 'succeeded' && tgConversationSearchVF;

    const searchTGConversations = (query) =>  userIsAuthenticated && dispatch(tgConversationSearchGET(query));

    useEffect(() => {
        if (vfExists && tgConversationSearchSuccessVF) {
            dispatch(clearTgConversationSearchVF());
        }
    }, [tgConversationSearchApiState, tgConversationSearchVF]);

    return {
        searchTGConversations,
        tgConversationSearchVF,
        tgConversationSearchApiState,
        tgConversationSearchResponse,
        tgConversationSearchError,
        tgConversationSearchSuccessVF
    };
};
