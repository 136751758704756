import React, {Fragment, useState, useMemo, useEffect} from "react";
import {EllipsisHorizontalIcon} from "@heroicons/react/20/solid";
import PropTypes from "prop-types";
import clsx from "clsx";
import {motion} from "framer-motion";

import BillingNav from "../BillingNav";
import CardDeletionModal from "./CardDeletionModal";
import {PaginationButton} from "../Invoices/Invoices";
import Nav from "../../Shell/Nav/Nav";
import CardAdditionModal from "./CardAdditionModal";
import {useListPaymentMethods} from "../../../../Hooks/apiHooks/payments/useListPaymentMethods";
import {useDeletePaymentMethod} from "../../../../Hooks/apiHooks/payments/useDeletePaymentMethod";
import LinkedCardsSkeleton from "./LinkedCardsSkeleton";
import {useCreatePaymentMethod} from "../../../../Hooks/apiHooks/payments/useCreatePaymentMethod";
import {useSetDefaultPaymentMethod} from "../../../../Hooks/apiHooks/payments/useSetDefaultPaymentMethod";
import Alerts from "../../../../Components/Alerts/Alerts";
import {useAlert} from "../../../../Hooks/frontendHooks/useAlert";
import Amex from "../../../../Design/PaymentIcons/cards/american-express.svg";
import Visa from "../../../../Design/PaymentIcons/cards/visa.svg";
import Discover from "../../../../Design/PaymentIcons/cards/discover.svg";
import Mastercard from "../../../../Design/PaymentIcons/cards/mastercard.svg";
import ApplePay from "../../../../Design/PaymentIcons/wallets/apple-pay.svg";
import GooglePay from "../../../../Design/PaymentIcons/wallets/google-pay.svg";
import CardGeneric from "../../../../Design/PaymentIcons/generic/card-generic.svg";
import dinersCard from "../../../../Design/PaymentIcons/cards/diners.svg";
import jcbCard from "../../../../Design/PaymentIcons/cards/jcb.svg";
import unionPayCard from "../../../../Design/PaymentIcons/cards/unionpay.svg";


export const cardLogos = {
    amex: Amex,
    visa: Visa,
    discover: Discover,
    mastercard: Mastercard,
    applepay: ApplePay,
    googlePay: GooglePay,
    cardGeneric: CardGeneric,
    diners: dinersCard,
    jcb: jcbCard,
    unionpay: unionPayCard,
};

const Card = (
    { index, card, newDefaultPM, setNewDefaultPM, setNewPMDeletion }
) => {
    // Payment Methods
    const { setDefaultPaymentMethod, paymentMethodDefaultSettingVF } = useSetDefaultPaymentMethod({ vfExists: true, refreshAndReset: false });
    const { deletePaymentMethod, paymentMethodDeletionFailureVF } = useDeletePaymentMethod({ vfExists: false, refreshAndReset: false });
    const { paymentMethodsListingVF } = useListPaymentMethods({ vfExists: false });

    // Alerts
    const { alert, alertKey } = useAlert();

    const handleDelete = (e) => {
        e.preventDefault();
        deletePaymentMethod(card.id);
        setNewPMDeletion(card.id);
    };

    const handleSetDefault = (e) => {
        e.stopPropagation();
        !card?.is_default && setDefaultPaymentMethod({payment_method_id: card?.id})
        setNewDefaultPM(card?.id);
    };

    const expirationDate = () => {
        const expMonth = String(card.exp_month).padStart(2, '0');
        const expYear = String(card.exp_year).slice(-2);
        return `${expMonth}/${expYear}`;
    };

    return (
        <>
            <motion.div
                layout
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-base-200 ring-1 ring-neutral rounded-sm shadow">
                <div className="px-6 py-5 flex items-start justify-between w-full">
                    <h4 className="sr-only">{card?.brand}</h4>
                    <div className="sm:flex sm:items-start">
                        <img
                            src={cardLogos[card?.brand]}
                            alt={card?.brand.toUpperCase()}
                            className="w-auto h-6 sm:flex-shrink-0 ring-1 ring-neutral/30 overflow-hidden rounded-sm"
                        />
                        <div className="mt-3 sm:ml-4 sm:mt-0">
                            <div className="text-sm font-medium text-base-content">Ending {card?.last4}</div>
                            <div className="mt-1.5 text-sm text-base-content/70 flex items-center">
                                <div>Expires on {expirationDate()}</div>
                                {newDefaultPM && (newDefaultPM === card?.id && (paymentMethodsListingVF || paymentMethodDefaultSettingVF)) &&
                                    <>
                                        <span className="hidden sm:mx-2 sm:inline bg-base-content/20 rounded-full w-1 h-1 animate-pulse duration-700" />
                                        <div className="bg-base-content/20 rounded w-12 h-4 py-0.5 mt-3 sm:mt-0 animate-pulse duration-700"/>
                                    </>
                                }
                                {!(newDefaultPM && (paymentMethodsListingVF || paymentMethodDefaultSettingVF)) && card?.is_default && (
                                    <>
                                        <span className="px-1" aria-hidden="true">&middot;</span>
                                        <span className="secondary-badge" >
                                            Default
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="dropdown dropdown-down text-base-300/70">
                        <div
                            tabIndex={0}
                            role="button"
                            className={clsx(
                                "block outline-none button-ring-focus",
                                newDefaultPM && newDefaultPM === card?.id && (paymentMethodsListingVF || paymentMethodDefaultSettingVF) ? "animate-pulse duration-700 text-base-content/70" : "text-base-content hover:text-base-content/70"
                            )}
                        >
                            <span className="sr-only">Open options</span>
                            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true"/>
                        </div>

                        <ul className="button-dropdown w-40 sm:w-32">
                            <li>
                                <CardDeletionModal card={card} onDelete={handleDelete} />
                            </li>
                            <li>
                                <button
                                    type="button"
                                    disabled={card?.is_default}
                                    onClick={handleSetDefault}
                                    className="flex flex-row w-full items-center space-x-2 px-3 py-1 text-sm leading-6 text-base-content hover:sm:bg-base-200 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:sm:bg-base-100"
                                >
                                    Set as default
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </motion.div>
            {paymentMethodDeletionFailureVF &&
                <Alerts
                    description={alert.description}
                    message={alert.message}
                    type={alert.type}
                    keyValue={alertKey}
                />}
        </>
    )
};

Card.propTypes = {
    index: PropTypes.number.isRequired,
    card: PropTypes.shape({
        id: PropTypes.string.isRequired,
        brand: PropTypes.string,
        last4: PropTypes.string.isRequired,
        exp_month: PropTypes.number.isRequired,
        exp_year: PropTypes.number.isRequired,
        is_default: PropTypes.bool,
    }).isRequired,
    newDefaultPM: PropTypes.string,
    setNewDefaultPM: PropTypes.func.isRequired,
    setNewPMDeletion: PropTypes.func.isRequired,
};

const LinkedCards = () => {
    // Payment Methods
    /* This is a hack to refresh the list of payment methods after default payment method is set, payment method is deleted, or payment method is created */
    // eslint-disable-next-line no-unused-vars
    const refreshPMPostSetDefault = useSetDefaultPaymentMethod({ vfExists: true, refreshAndReset: true });
    // eslint-disable-next-line no-unused-vars
    const refreshPMPostDeletion = useDeletePaymentMethod({ vfExists: true, refreshAndReset: true });
    // eslint-disable-next-line no-unused-vars
    const refreshPMPostCreation = useCreatePaymentMethod();
    const {
        listPaymentMethods,
        paymentMethodsListingResponse,
        paymentMethodsListingVF,
    } = useListPaymentMethods({ vfExists: true });

    // States
    const [newDefaultPM, setNewDefaultPM] = useState(null);
    const [newPMDeletion, setNewPMDeletion] = useState(null);
    const [newPMCreation, setNewPMCreation] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Setting card pagination
    const cards = paymentMethodsListingResponse?.payment_methods;
    const totalCards = paymentMethodsListingResponse?.total_count;
    const totalPages = Math.ceil(totalCards / itemsPerPage);
    const indexOfLastItem = useMemo(() => currentPage * itemsPerPage, [currentPage, totalPages]);
    const indexOfFirstItem = useMemo(() => indexOfLastItem - itemsPerPage, [indexOfLastItem, totalPages]);
    const fromCard = cards?.length > 0 ? indexOfFirstItem + 1 : 0;
    const toCard = Math.min(indexOfLastItem, paymentMethodsListingResponse?.total_count)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        listPaymentMethods(pageNumber, itemsPerPage);
    };

    // If list of payment is already fetched and succeeded, don't fetch it again
    useEffect(() => {
        if (!cards) {
            listPaymentMethods(1, itemsPerPage);
        }
    }, []);

    // If the payment methods need to be refreshed or the page changes, list the payment methods
    useEffect(() => {
        if (currentPage !== 1) {
            listPaymentMethods(currentPage, itemsPerPage);
        }
    }, [currentPage]);

    // If the cards are already set or the default payment method is set, a payment method is deleted, or a payment method is created, then do not show the skeleton loader
    if (!cards || (!newDefaultPM && !newPMDeletion && !newPMCreation && paymentMethodsListingVF)) {
        return (
            <>
                <Nav/>
                <BillingNav/>
                <LinkedCardsSkeleton/>
            </>
        )
    }
    return (
        <>
            <Nav/>
            <BillingNav />

            <div className="py-5 bg-base-100 sticky top-28 right-0 left-0 lg:left-16 border-b border-neutral z-10" >
                <div className="sm:flex sm:items-center sm:justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="min-w-0 flex-1 sm:flex-auto">
                        <h1 className="text-2xl font-bold leading-7 sm:truncate sm:tracking-tight text-base-content">
                            Linked Cards
                        </h1>
                    </div>
                </div>
            </div>
            <div className="scrollbar overflow-y-auto h-[calc(100dvh-238px)] sm:h-[calc(100dvh-236px)]">
                <ul className="grid md:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-4 hide-scrollbar mx-auto px-6 lg:px-9 max-w-5xl py-5">
                    {Array.isArray(cards) && cards.map((card, index) => (
                        <Card key={card?.id} card={card} index={index} newDefaultPM={newDefaultPM} setNewDefaultPM={setNewDefaultPM} setNewPMDeletion={setNewPMDeletion}/>
                    ))}
                    <CardAdditionModal setNewPMCreation={setNewPMCreation}/>
                </ul>
            </div>
            <nav
                className="border-t border-neutral-focus/40 dark:border-neutral-focus-dark/40 bg-base-100 py-3 z-[2]"
                aria-label="Pagination"
            >
                <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="hidden sm:block">
                        <p className="text-sm text-base-content/70">
                            Showing{" "}
                            <span className="font-medium">{fromCard}</span> to{" "}
                            <span className="font-medium">{toCard}</span> of{" "}
                            <span className="font-medium">{totalCards}</span> results
                        </p>
                    </div>
                    {/*TODO: UPDATE THE ONCLICK TO GET BACKEND METADATA FOR PAGINATION*/}
                    <div className="flex flex-1 justify-between sm:justify-end space-x-3">
                        <PaginationButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </PaginationButton>
                        <PaginationButton
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages || cards?.length === 0}
                        >
                            Next
                        </PaginationButton>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default LinkedCards;
