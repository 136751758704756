import {useEffect, useState} from "react";


export const useSessionInfo = () => {
    const now = new Date();
    const session_time = now.toLocaleTimeString();
    const session_date = now.toLocaleDateString();

    const [locationInfo, setLocationInfo] = useState({ city: '', state: '', country: '', zip: '' });

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                getAddress(position.coords.latitude, position.coords.longitude);
            });
        }
    };

    const getAddress = (latitude, longitude) => {
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setLocationInfo({
                    city: data.address.city || data.address.town || '',
                    state: data.address.state || '',
                    country: data.address.country || '',
                    zip: data.address.postcode || ''
                });
            })
            .catch(error => {
                console.error('Error fetching the address: ', error);
            });
    };

    useEffect(() => {
        getLocation();
    }, []);

    return {
        session_time: session_time,
        session_date: session_date,
        city: locationInfo?.city,
        state: locationInfo?.state,
        country: locationInfo?.country,
        zip: locationInfo?.zip,
    };
}
