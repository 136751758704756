import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {useAuthentication} from "../../../users/useAuthentication";
import {incrementAlertKey, setAlert} from "../../../../../api/features/frontend/alertSlice";
import {clearMessageTogglePinVF, resetMessageTogglePin, togglePinMessagePOST} from "../../../../../api/features/superIntelligence/taskGlide/conversations/togglePinMessageSlice";
import {useGetSingleTgConversation} from "./useGetSingleTgConversation";
import {useGetPinnedMessage} from "./useGetPinnedMessage";


export const useTogglePinMessage = (
    { vfExists=false, autoUpdate=false, conversationUUID } = {}
) => {
    const dispatch = useDispatch();
    const { userIsAuthenticated } = useAuthentication();
    const {
        togglePinMessageVF,
        togglePinMessageApiState,
        togglePinMessageResponse,
        togglePinMessageError,
    } = useSelector(state => state.togglePinMessageReducer);
    const { getSingleTGConversation } = useGetSingleTgConversation();
    const { getPinnedMessage } = useGetPinnedMessage();

    const messageTogglePinSuccessVF = togglePinMessageApiState === 'succeeded' && togglePinMessageVF;
    const messageTogglePinFailureVF = togglePinMessageApiState === 'failed' && togglePinMessageVF;

    const togglePinMessage = (interaction_id, is_user_message_str) => userIsAuthenticated && dispatch(togglePinMessagePOST({ interaction_id, is_user_message_str }));

    useEffect(() => {
        if (vfExists) {
            if (messageTogglePinSuccessVF) {
                const timer = setTimeout(() => {
                    dispatch(clearMessageTogglePinVF());
                }, 500);

                return () => clearTimeout(timer);
            } else if (messageTogglePinFailureVF) {
                dispatch(setAlert({
                    message: togglePinMessageResponse?.detail || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetMessageTogglePin());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [togglePinMessageApiState, togglePinMessageVF]);

    useEffect(() => {
        if (autoUpdate && togglePinMessageApiState === 'succeeded' && !togglePinMessageVF) {
            getSingleTGConversation(conversationUUID);
            getPinnedMessage(conversationUUID);
        }
    }, [togglePinMessageApiState, togglePinMessageVF]);

    return {
        togglePinMessage,
        togglePinMessageVF,
        togglePinMessageApiState,
        togglePinMessageResponse,
        togglePinMessageError,
        messageTogglePinSuccessVF,
        messageTogglePinFailureVF
    };
};
