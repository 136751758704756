import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {CheckIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";

const RegistrationSteps = ({ steps }) => {

    return (
        <nav aria-label="Progress" className="w-fit hidden md:block" >
            <ol className="overflow-hidden">
                {steps.map((step, stepIdx) => {
                    const isCurrentStep = step.href === location.pathname;
                    const isComplete = stepIdx < steps.findIndex(s => s.href === location.pathname);

                    let status;
                    if (isCurrentStep) {
                        status = 'current';
                    } else if (isComplete) {
                        status = 'complete';
                    } else {
                        status = 'incomplete';
                    }

                    return (
                    <li key={step.name} className={clsx(stepIdx !== steps.length - 1 ? 'pb-16' : '', 'relative')}>
                        {status === 'complete' ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (<div className="absolute left-2.5 top-3.5 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600  ease-in-out duration-700" aria-hidden="true" />) : null}
                                <Link to={step.href} className="group relative flex items-start">
                                    <span className="flex h-9 items-center">
                                        <span className="relative flex h-5 w-5 items-center justify-center rounded-full bg-primary group-hover:bg-primary-focus dark:group-hover:bg-primary-focus-dark ease-in-out duration-700">
                                            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium">{step.name}</span>
                                        <span className="text-xs text-gray-500">{step.description}</span>
                                    </span>
                                </Link>
                            </>
                        ) : status === 'current' ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div className="absolute left-2.5 top-3.5 -ml-px mt-0.5 h-full w-0.5 bg-base-300 ease-in-out duration-700" aria-hidden="true" />
                                ) : null}
                                <Link to={step.href} className="group relative flex items-start" aria-current="step">
                                    <span className="flex h-9 items-center" aria-hidden="true">
                                        <span className="relative flex h-5 w-5 items-center justify-center rounded-full border-2 border-primary bg-base-100">
                                            <span className="h-2.5 w-2.5 rounded-full bg-primary" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-primary">{step.name}</span>
                                        <span className="text-xs text-base-content/70">{step.description}</span>
                                    </span>
                                </Link>
                            </>
                        ) : (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div className="absolute left-2.5 top-3.5 -ml-px mt-0.5 h-full w-0.5 bg-base-300  ease-in-out duration-700" aria-hidden="true" />
                                ) : null}
                                <Link to={step.href} className="group relative flex items-start">
                                    <span className="flex h-5 items-center" aria-hidden="true">
                                        <span className="relative flex h-5 w-5 items-center justify-center rounded-full border-2 border-base-300 bg-base-200 group-hover:border-base-300/70">
                                            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-base-content/70">{step.name}</span>
                                        <span className="text-xs text-base-content/70">{step.description}</span>
                                    </span>
                                </Link>
                            </>
                        )}
                    </li>
                )})}
            </ol>
        </nav>
    )
};

RegistrationSteps.propTypes = {
    steps: PropTypes.array.isRequired,
};

export default RegistrationSteps;
