import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import {useAuthentication} from "../users/useAuthentication";
import {clearUnsubscribeVF, resetUnsubscribe, unsubscribePOST} from "../../../api/features/payments/unsubscribeSlice";
import {useGetRegistrationStatus} from "../users/useGetRegistrationStatus";
import {useListUserSubscriptions} from "./useListUserSubscriptions";


export const useUnsubscribe = (
    { vfExists=false, refreshAndReset=false } = {}
) => {
    const dispatch = useDispatch();

    // Authentication
    const { userIsAuthenticated } = useAuthentication();

    // API hooks
    const {
        unsubscribeVF,
        unsubscribeApiState,
        unsubscribeResponse,
        unsubscribeError,
    } = useSelector(state => state.unsubscribeReducer);
    const { getRegistrationStatus } = useGetRegistrationStatus();
    const { listUserSubscriptions } = useListUserSubscriptions();

    // API State with visual feedback
    const unsubscribeSuccessVF = unsubscribeApiState === 'succeeded' && unsubscribeVF;
    const unsubscribeFailureVF = unsubscribeApiState === 'failed' && unsubscribeVF;

    const unsubscribe = (price_id) => userIsAuthenticated && dispatch(unsubscribePOST(price_id));

    useEffect(() => {
        if (vfExists) {
            if (unsubscribeSuccessVF) {
                dispatch(setAlert({
                    message: unsubscribeResponse?.message,
                    type: 'info'
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUnsubscribe());
                }, 4000);

                return () => clearTimeout(timer);
            } else if (unsubscribeFailureVF) {
                dispatch(setAlert({
                    message: unsubscribeError?.message || "Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetUnsubscribe());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [unsubscribeVF, unsubscribeApiState]);

    useEffect(() => {
        if (refreshAndReset && unsubscribeApiState === 'succeeded') {
            getRegistrationStatus();
            listUserSubscriptions();
        }
    }, [unsubscribeVF, unsubscribeApiState]);

    return {
        unsubscribe,
        unsubscribeVF,
        unsubscribeSuccessVF,
        unsubscribeFailureVF,
        unsubscribeError,
        unsubscribeResponse,
        unsubscribeApiState
    };
};

