import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const transactionReceiptGET = createAsyncThunk(
    'payments/transactions/receipt',
    async (transaction_id, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_GET_RECEIPT_PATH}${transaction_id}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    transactionReceiptRetrievalVF: false,
    transactionReceiptRetrievalApiState: 'idle',
    transactionReceiptRetrievalResponse: null,
    transactionReceiptRetrievalError: null,
};

export const getTransactionReceiptSlice = createSlice({
    name: 'getTransactionReceipt',
    initialState: INITIAL_STATE,
    reducers: {
        clearTransactionReceiptRetrievalVF: (state) => {
            state.transactionReceiptRetrievalVF = false;
        },
        resetTransactionReceiptRetrieval: (state) => {
            state.transactionReceiptRetrievalVF = false;
            state.transactionReceiptRetrievalApiState = 'idle';
            state.transactionReceiptRetrievalResponse = null;
            state.transactionReceiptRetrievalError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(transactionReceiptGET.pending, (state) => {
                state.transactionReceiptRetrievalApiState = 'loading';
                state.transactionReceiptRetrievalVF = true;
            })
            .addCase(transactionReceiptGET.fulfilled, (state, action) => {
                state.transactionReceiptRetrievalApiState = 'succeeded';
                state.transactionReceiptRetrievalResponse = action.payload;
                state.transactionReceiptRetrievalVF = true;
                state.transactionReceiptRetrievalError = null;
            })
            .addCase(transactionReceiptGET.rejected, (state, action) => {
                state.transactionReceiptRetrievalApiState = 'failed';
                state.transactionReceiptRetrievalError = action?.payload;
                state.transactionReceiptRetrievalVF = true;
                state.transactionReceiptRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTransactionReceiptRetrievalVF,
    resetTransactionReceiptRetrieval
} = getTransactionReceiptSlice.actions;

export default getTransactionReceiptSlice.reducer;
