import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import clsx from "clsx";

import Nav from "../../../Shell/Nav/Nav";
import BillingNav from "../../BillingNav";
import routes from "../../../../../Routes/routes";
import {useGetSubscriptionPlan} from "../../../../../Hooks/apiHooks/payments/useGetSubscriptionPlan";
import {useListPaymentMethods} from "../../../../../Hooks/apiHooks/payments/useListPaymentMethods";
import SubscriptionManagement from "./SubscriptionManagement";
import {useGetUserSubscription} from "../../../../../Hooks/apiHooks/payments/useGetUserSubscription";
import {useListUserSubscriptions} from "../../../../../Hooks/apiHooks/payments/useListUserSubscriptions";
import {currentSubscriptionStatus, futureSubscriptionStatus, statuses} from "../Subscriptions";
import SubscriptionPlanSkeleton from "./SubscriptionPlanSkeleton";
import {useGeneralUiActions} from "../../../../../Hooks/frontendHooks/useGeneralUiActions";


const SubscriptionPlan = () => {
    const { product_name } = useParams();
    const {
        getSubscriptionPlan,
        subscriptionPlanRetrievalVF,
        subscriptionPlanRetrievalResponse,
    } = useGetSubscriptionPlan({ vfExists: true });
    const {
        getUserSubscription,
        userSubscriptionRetrievalVF,
        userSubscriptionRetrievalResponse,
    } = useGetUserSubscription({ vfExists: true });
    const {
        listPaymentMethods,
        paymentMethodsListingResponse,
        paymentMethodsListingVF,
    } = useListPaymentMethods({ vfExists: true });
    const {
        userSubscriptionsListingResponse,
        userSubscriptionsListingVF,
    } = useListUserSubscriptions({ vfExists: true });
    const { firstRender, handleFirstRender } = useGeneralUiActions();

    useEffect(() => {
        if (subscriptionPlanRetrievalResponse?.product_name !== product_name) {
            getSubscriptionPlan(product_name);
        }
    }, [product_name]);

    useEffect(() => {
        if (!paymentMethodsListingResponse) {
            listPaymentMethods();
        }
    }, []);

    useEffect(() => {
        handleFirstRender(1000);
    }, [product_name]);

    const isSubscribed = userSubscriptionsListingResponse?.some(sub => sub.price_id === subscriptionPlanRetrievalResponse?.price_id);
    const currentStatus = currentSubscriptionStatus(
        subscriptionPlanRetrievalResponse?.is_active,
        isSubscribed
    )

    useEffect(() => {
        if (currentStatus === 'Subscribed' && subscriptionPlanRetrievalResponse?.product_name === product_name) {
            getUserSubscription(product_name);
        }
    }, [product_name, subscriptionPlanRetrievalResponse]);

    const firstRenderLoading = firstRender && (paymentMethodsListingVF || userSubscriptionRetrievalVF);
    const subscribedLoading = currentStatus === 'Subscribed' && userSubscriptionRetrievalResponse?.product_name !== product_name;
    const loadingState = userSubscriptionsListingVF || subscriptionPlanRetrievalVF || subscriptionPlanRetrievalResponse?.product_name !== product_name || firstRenderLoading || subscribedLoading;

    return (
        <>
            <Nav/>
            <BillingNav/>

            <div className="bg-base-100 sticky top-28 right-0 left-0 py-4 border-b border-neutral z-10">
                <div className="sm:flex sm:items-center sm:justify-between px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl">
                    <div className="min-w-0 flex-1 sm:flex-auto">
                        <div className="text-sm breadcrumbs font-medium">
                            <ul>
                                <li>
                                    <Link
                                        to={routes.auth.billing.subscriptions.find(plan => plan?.label === 'Subscriptions')?.href}
                                        className="hover:no-underline"
                                    >
                                        {routes.auth.billing.subscriptions.find(plan => plan?.label === 'Subscriptions')?.label}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={routes.auth.billing.subscriptions.find(plan => plan?.label === 'TaskGlide')?.href}
                                        className="hover:no-underline text-primary"
                                    >
                                        {product_name}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx(
                "h-[calc(100dvh-182px)] sm:h-[calc(100dvh-182px)] bg-base-100 cursor-default",
                loadingState ? 'overflow-hidden' : 'overflow-y-auto scrollbar'
            )}>
                {loadingState ?
                <SubscriptionPlanSkeleton /> :
                <div className="gap-x-6 py-3 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl text-sm leading-6">
                    <div className="mb-4">
                        <h3 className="text-base font-semibold leading-7">
                            {subscriptionPlanRetrievalResponse?.product_name}
                        </h3>
                        <p className="mt-1 text-base-content/70">
                            {subscriptionPlanRetrievalResponse?.description}
                        </p>
                    </div>
                    <div className="border-t border-neutral">
                        <div className="divide-y divide-neutral">
                            <div className="py-6 grid grid-cols-5 gap-y-1 gap-x-4">
                                <div className="text-sm font-medium leading-6 col-span-full sm:col-span-2 h-fit">Status</div>
                                <div className="flex items-center gap-x-2" >
                                    <p className={statuses[currentStatus]}>
                                        {currentStatus}
                                    </p>
                                    {isSubscribed && <p
                                        className={clsx(
                                            statuses['Not subscribed'],
                                            'rounded whitespace-nowrap px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset hidden md:inline-flex'
                                        )}
                                    >
                                        {futureSubscriptionStatus(
                                            userSubscriptionRetrievalResponse?.current_period_end,
                                            userSubscriptionRetrievalResponse?.is_cancellation_scheduled
                                        )}
                                    </p>}
                                </div>
                            </div>
                            <div className="py-6 grid grid-cols-5 sm:gap-x-4">
                                <div className="text-sm font-medium leading-6 col-span-full sm:col-span-2">Built for</div>
                                <div className="mt-1 text-sm leading-6 sm:mt-0 col-span-full sm:col-span-3 capitalize">
                                    {subscriptionPlanRetrievalResponse?.users}
                                </div>
                            </div>
                            <div className="py-6 grid grid-cols-5 sm:gap-x-4">
                                <div className="text-sm font-medium leading-6 col-span-full sm:col-span-2">Billing cycle</div>
                                <div className="mt-1 text-sm leading-6 sm:mt-0 col-span-full sm:col-span-3">Monthly</div>
                            </div>
                            {currentSubscriptionStatus !== 'Not available' && <SubscriptionManagement
                                subscriptionStatus={currentStatus}
                                subscriptionPlanHook={useGetSubscriptionPlan}
                                paymentMethods={paymentMethodsListingResponse}
                                userSubscription={userSubscriptionRetrievalResponse}
                                subscriptionPlan={subscriptionPlanRetrievalResponse}
                            />}
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
};

export default SubscriptionPlan;
