import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik} from "formik";
import clsx from "clsx";
import * as yup from "yup";
import {CgSpinner} from "react-icons/cg";

import routes from "../../../Routes/routes";
import {companyLogo} from "../../../Design/companyLogo";
import Alerts from "../../../Components/Alerts/Alerts";
import {useResetPassword} from "../../../Hooks/apiHooks/users/useResetPassword";
import {useAlert} from "../../../Hooks/frontendHooks/useAlert";
import {ImSpinner3} from "react-icons/im";


const initialValues = {
    password: "",
    password2: "",
}

const validationSchema = yup.object().shape({
    password: yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/^(?=.*[a-z]).+$/, 'At least one lowercase letter')
        .matches(/^(?=.*[A-Z]).+$/, 'At least one uppercase letter')
        .matches(/^(?=.*\d).+$/, 'At least one number')
        .matches(/^(?=.*[@$!%*?&]).+$/, 'At least one special character (@$!%*?&)'),
    password2: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {alert, alertKey} = useAlert();

    const {
        resetPassword,
        passwordResetApiState,
        passwordResetFailureVF,
        passwordResetLoadingVF
    } = useResetPassword({ vfExists: true });

    useEffect(() => {
        if (passwordResetApiState === "succeeded") {
            navigate(routes.unAuth.login.href);
        }
    }, [passwordResetApiState]);

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        resetPassword(values);
        setSubmitting(false);
        resetForm();
    };

    return (
        <>
            <div className="flex min-h-screen flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 bg-base-100 space-y-10">
                <div className="mx-auto w-full max-w-sm space-y-6">
                    <Link to={routes.unAuth.home.href} className="outline:none focus:outline-none">
                        <span className="sr-only">Dovise</span>
                        <img
                            className="mx-auto h-10 w-auto transition-transform hover:scale-110 duration-500"
                            src={companyLogo}
                            alt="Your Company"
                        />
                    </Link>
                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-base-content">
                        Reset your password
                    </h2>
                </div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {({errors, touched, isSubmitting, isValid, values}) => (
                        <Form className="mx-auto w-full max-w-sm space-y-6">
                            <div className="space-y-2">
                                <div className="flex justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-base-content">
                                        Password
                                    </label>
                                    <p>
                                        {errors.password && touched.password ? (
                                            <span className="text-2xs text-error-content">{errors.password}</span>
                                        ) : null}
                                    </p>
                                </div>
                                <Field
                                    type="password"
                                    name="password"
                                    required
                                    className="input-field"
                                />
                            </div>
                            <div className="space-y-2">
                                <div className="flex justify-between">
                                    <label htmlFor="password2" className="block text-sm font-medium leading-6 text-base-content">
                                        Confirm password
                                    </label>
                                    <p>
                                        {errors.password2 && touched.password2 ?
                                            <span className="text-2xs text-error-content">{errors.password2}</span>
                                            : null}
                                    </p>
                                </div>
                                <Field
                                    type="password"
                                    name="password2"
                                    required
                                    className="input-field"
                                />
                            </div>
                            <button
                                type="submit"
                                disabled={!isValid || passwordResetLoadingVF || isSubmitting || values.password === '' || values.password2 === ''}
                                className="primary-button w-full"
                            >
                                <ImSpinner3
                                    className={clsx(
                                        "absolute inset-0 m-auto animate-spin h-5 w-5",
                                        (passwordResetLoadingVF || isSubmitting) ? "" : "hidden"
                                    )}
                                    aria-hidden="true"
                                />
                                <p className={(passwordResetLoadingVF || isSubmitting) ? "invisible" : ""}>
                                    Reset password
                                </p>
                            </button>
                        </Form>
                    )}
                </Formik>

                <p className="mt-10 text-center text-sm text-base-content/70">
                    Remember your password?{' '}
                    <Link
                        to={routes.unAuth.login.href}
                        onClick={() => dispatch({type: "RESET_USER_MANAGEMENT"})}
                        className="font-medium leading-6 text-primary hover:text-primary-focus ml-1">
                        Sign in
                    </Link>
                </p>
            </div>
            {passwordResetFailureVF &&
                <Alerts message={alert.message} type={alert.type} keyValue={alertKey}/>}
        </>
    )
};

export default ResetPassword;
