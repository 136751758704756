import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const taskGlideSettingsGET = createAsyncThunk(
    'taskGlide/settings/retrieve',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_SETTINGS_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    taskGlideSettingsRetrievalApiState: "idle",
    taskGlideSettingsRetrievalVF: false,
    taskGlideSettingsRetrievalResponse: null,
    taskGlideSettingsRetrievalError: null,
};

export const getTaskGlideSettingsSlice = createSlice({
    name: 'taskGlideSettingsRetrieval',
    initialState: INITIAL_STATE,
    reducers: {
        clearTaskGlideSettingsRetrievalVF: (state) => {
            state.taskGlideSettingsRetrievalVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(taskGlideSettingsGET.pending, (state) => {
                state.taskGlideSettingsRetrievalApiState = 'loading';
                state.taskGlideSettingsRetrievalVF = true;
            })
            .addCase(taskGlideSettingsGET.fulfilled, (state, action) => {
                state.taskGlideSettingsRetrievalApiState = 'succeeded';
                state.taskGlideSettingsRetrievalResponse = action.payload;
                state.taskGlideSettingsRetrievalVF = true;
                state.taskGlideSettingsRetrievalError = null;
            })
            .addCase(taskGlideSettingsGET.rejected, (state, action) => {
                state.taskGlideSettingsRetrievalApiState = 'failed';
                state.taskGlideSettingsRetrievalError = action?.payload;
                state.taskGlideSettingsRetrievalVF = true;
                state.taskGlideSettingsRetrievalResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const { clearTaskGlideSettingsRetrievalVF } = getTaskGlideSettingsSlice.actions;

export default getTaskGlideSettingsSlice.reducer;
