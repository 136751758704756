import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const glidesGET = createAsyncThunk(
    'taskGlide/glides/list',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TASKGLIDE_LIST_GLIDES_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    glidesListingVF: false,
    glidesListingApiState: 'idle',
    glidesListingResponse: null,
    glidesListingError: null,
};

export const listUserGlidesSlice = createSlice({
    name: 'glidesListing',
    initialState: INITIAL_STATE,
    reducers: {
        clearGlidesListingVF: (state) => {
            state.glidesListingVF = false;
        },
        resetGlidesListing: (state) => {
            state.glidesListingVF = false;
            state.glidesListingApiState = 'idle';
            state.glidesListingResponse = null;
            state.glidesListingError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(glidesGET.pending, (state) => {
                state.glidesListingApiState = 'loading';
                state.glidesListingVF = true;
            })
            .addCase(glidesGET.fulfilled, (state, action) => {
                state.glidesListingApiState = 'succeeded';
                state.glidesListingResponse = action?.payload;
                state.glidesListingVF = true;
                state.glidesListingError = null;
            })
            .addCase(glidesGET.rejected, (state, action) => {
                state.glidesListingApiState = 'failed';
                state.glidesListingError = action?.payload;
                state.glidesListingVF = true;
                state.glidesListingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearGlidesListingVF, resetGlidesListing
} = listUserGlidesSlice.actions;

export default listUserGlidesSlice.reducer;
