import React, {Fragment} from "react";
import {Popover, Transition} from "@headlessui/react";
import {Link, useNavigate} from "react-router-dom";
import clsx from "clsx";

import routes from "../../Routes/routes";
import {productBottomSection, products, solutions} from "./hedaerNavRoutes";


const HeaderPopOvers = () => {
    const navigate = useNavigate();

    return (
        <Popover.Group className="hidden lg:flex lg:gap-x-12" >
            <Popover>
                <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-base-content hover:text-base-content/70 focus:outline-none">
                    <span>Products</span>
                </Popover.Button>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50" />
                </Transition.Child>

                <Transition
                    as={Fragment}
                    enter="transition ease-out"
                    enterFrom="transform opacity-0 -translate-y-2"
                    enterTo="transform opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="transform opacity-100 translate-y-0"
                    leaveTo="transform opacity-0 -translate-y-2"
                >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        {({ close }) => (
                            <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-base-100 text-sm leading-6 shadow-lg ring-1 ring-neutral">
                                <div className="p-4">
                                    {products.map((item) => (
                                        <button
                                            key={item.name}
                                            onClick={() => {
                                                navigate(item.href)
                                                close()
                                            }}
                                            disabled={!item.active}
                                            className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-base-200 text-start"
                                        >
                                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-base-200 group-hover:bg-base-100">
                                                {item.icon("font-bold text-sm text-base-300 group-hover:text-primary text-center")}
                                            </div>
                                            <div>
                                                <div
                                                    className="flex items-center justify-between font-semibold text-base-content w-full"
                                                >
                                                    {item.name}
                                                    {!item.active && <div className="inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-medium capitalize text-secondary bg-secondary/10" >Coming Soon</div>}
                                                </div>
                                                <p className="mt-1 text-base-content/70">{item.description}</p>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                                <div className="grid grid-cols-2 divide-x divide-neutral border-t border-neutral">
                                    {productBottomSection.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            onClick={() => close()}
                                            className="group flex items-center justify-center gap-x-2.5 p-3 font-semibold text-base-content bg-base-200 hover:bg-neutral"
                                        >
                                            <item.icon className="h-5 w-5 flex-none text-base-300 group-hover:text-primary" aria-hidden="true" />
                                            <p>{item.name}</p>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Popover.Panel>
                </Transition>
            </Popover>
            <Popover>
                <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-base-content hover:text-base-content/70 focus:outline-none"  >
                    <span>Solutions</span>
                </Popover.Button>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50" />
                </Transition.Child>

                <Transition
                    as={Fragment}
                    enter="transition ease-out"
                    enterFrom="transform opacity-0 -translate-y-2"
                    enterTo="transform opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="transform opacity-100 translate-y-0"
                    leaveTo="transform opacity-0 -translate-y-2"
                >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        {({ close }) => (
                            <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-base-100 text-sm leading-6 shadow-lg ring-1 ring-neutral">
                                <div className="p-4">
                                    {solutions.map((item) => (
                                        <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-base-200">
                                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-base-200 group-hover:bg-base-100">
                                                <item.icon className="h-6 w-6 text-base-300 group-hover:text-primary" aria-hidden="true" />
                                            </div>
                                            <div>
                                                <Link
                                                    to={item.href}
                                                    onClick={() => close()}
                                                    className="font-semibold text-base-content"
                                                >
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </Link>
                                                <p className="mt-1 text-base-content/70">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="px-8 py-4 border-t border-neutral/70 bg-base-200">
                                    <div className="flex items-center gap-x-3">
                                        <h3 className="text-sm font-semibold leading-6 text-base-content">TaskGlide</h3>
                                        <p className="inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-medium capitalize text-secondary-focus bg-secondary/20" >Base plan</p>
                                    </div>
                                    <p className="mt-2 text-sm leading-6 text-base-content/70">
                                        Navigate daily decisions effortlessly, intelligently.
                                    </p>
                                </div>
                            </div>
                        )}
                    </Popover.Panel>
                </Transition>
            </Popover>
            <Link to={routes.unAuth.products.find(product => product.name === 'Pricing').href} className="text-sm font-semibold leading-6 text-base-content hover:text-base-content/70">
                Pricing
            </Link>
            <Link to={routes.unAuth.about.href} className="text-sm font-semibold leading-6 text-base-content hover:text-base-content/70">
                About us
            </Link>
        </Popover.Group>
    )
};

HeaderPopOvers.propTypes = {

};

export default HeaderPopOvers;
