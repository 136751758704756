import React from 'react';
import {RiArrowDownSLine} from "react-icons/ri";
import {CgMenuGridO} from "react-icons/cg";
import {FaRegStopCircle} from "react-icons/fa";

import BrowserFrame from "../../../../Components/Frames/BrowserFrame";
import {companyLogo} from "../../../../Design/companyLogo";
import TaskGlideProductChatContent from "./TaskGlideProductChatContent";


const TaskGlideProductBrowserFrame = () => {

    return (
        <BrowserFrame browserTab={"https://dovise.com/task-glide/chat"} browserColor={"bg-base-100"} >
            <div className="w-full h-full" >
                <div className="h-14 bg-base-100 items-center flex w-full z-20">
                    <div className="flex w-full items-center shrink-0 gap-x-4 mx-auto max-w-5xl px-10">
                        <div className="flex flex-none relative items-center px-0 gap-x-8">
                            <div className="flex flex-shrink-0 items-center">
                                <img className="h-8 w-auto" src={companyLogo} alt=""/>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex-none space-x-4 text-sm leading-6 ml-6 flex">
                                <div className="text-base-content inline-flex items-center justify-center rounded-md py-2 px-2 text-sm font-medium whitespace-nowrap space-x-1 hover:bg-base-200 hover:shadow-inner hover:ring-[0.5px] bg-base-200 shadow-inner ring-inset ring-[0.5px] ring-neutral-focus/70 dark:ring-neutral-focus-dark/70">
                                    <span className="pl-1.5">TaskGlide</span>
                                    <RiArrowDownSLine className="h-4 w-4" aria-hidden="true"/>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-none items-center shrink-0 divide-x divide-neutral-focus/50 dark:divide-neutral-focus-dark/50">
                            <div className="group/profile flex items-center outline-none pl-3">
                                <div className="h-7 w-7 flex items-center justify-center space-x-1 ring-base-content/30 border-0 text-xs rounded-full p-1 bg-base-content ring-[0.5px] ring-neutral-focus dark:ring-base-100 text-base-100 relative">
                                    JD
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden w-full h-full z-0">
                    <div className="col-span-6 flex flex-col w-full bg-base-100 border-r-[0.5px] border-neutral/70 z-20">
                        <div className="h-[545px] w-full mx-auto max-w-5xl">
                            <div className="relative flex-1">
                                <TaskGlideProductChatContent/>
                            </div>
                        </div>
                        <div className="pl-10 pr-6 bg-base-100 w-full mx-auto max-w-5xl">
                            <div className="flex-1 flex flex-row w-full z-20">
                                <div className="relative flex h-full flex-1 items-stretch md:flex-col">
                                    <div className="rounded-xl w-full bg-base-200 ring-[0.5px] ring-base-content/20 shadow-[0px_0px_5px_rgba(0,0,0,0.10)] dark:shadow-[0px_0px_10px_rgba(0,0,0,0.50)] h-12 flex items-center justify-between px-2">
                                        <div className="h-fit group flex text-sm rounded-md p-1.5 bg-transparent text-base-content group/menu">
                                            <CgMenuGridO className="size-6 shrink-0" aria-hidden="true"/>
                                        </div>
                                        <div className="my-0 flex items-center group/submit outline-none disabled:cursor-not-allowed rounded-md h-fit place-self-center bg-transparent p-1.5 disabled:opacity-50 text-base-content">
                                            <FaRegStopCircle className="size-6 shrink-0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Bottom Actions */}
                            <p className="px-3 py-2 mx-0 text-2xs text-center text-base-300 break-all whitespace-nowrap line-clamp-1 overflow-hidden">
                                EcoGlide can make mistakes from time to time.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </BrowserFrame>
    )
};

export default TaskGlideProductBrowserFrame;
