import React, {useState} from "react";
import clsx from "clsx";
import {AnimatePresence, motion} from "framer-motion";
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

import SingleSelect from "../../../../../Components/Selects/SingleSelect";
import {cardLogos} from "../../../Billing/LinkedCards/LinkedCards";
import AddressAutocompletion from "../../../../../Components/AddressAutocompletion/AddressAutocompletion";
import {useListPaymentMethods} from "../../../../../Hooks/apiHooks/payments/useListPaymentMethods";
import {handleBlur, handleCardNumberChange, handleFocus} from "./SubscriptionForm";
import {useSelector} from "react-redux";
import {Field} from "formik";


const SubscriptionPaymentMethodForm = (
    { showExisting, setShowExisting, showNew, setShowNew, cardBrand, setCardBrand, setFieldValue, paymentList }
) => {
    const theme = useSelector(state => state.frontendReducer.theme);

    const cardNumberElementRef = React.useRef(null);
    const cardExpiryElementRef = React.useRef(null);
    const cardCvcElementRef = React.useRef(null);

    // const [address, setAddress] = useState({
    //     line1: '',
    //     line2: '',
    //     city: '',
    //     state: '',
    //     country: '',
    //     postal_code: '',
    // });

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: theme === 'dark' ? '#e2e8f0' : '#1f2937',
                fontSize: "14px",
                "::placeholder": {
                    color: theme === 'dark' ? 'rgba(226,232,240,0.2)' : 'rgba(31,41,55,0.2)'
                },
            },
            invalid: {
                color: theme === 'dark' ? '#f87171' : '#dc2626',
                iconColor: theme === 'dark' ? '#7f1d1d' : '#fee2e2',
                ":-webkit-autofill": {
                    color: theme === 'dark' ? '#f87171' : '#dc2626',
                }
            },
        }
    };

    const {
        paymentMethodsListingResponse,
        paymentMethodsListingVF,
    } = useListPaymentMethods({ vfExists: true });

    const togglePaymentMethod = (method, setShowExisting, setShowNew) => {
        if (method === 'existing') {
            setShowExisting(true);
            setShowNew(false);
        } else {
            setShowExisting(false);
            setShowNew(true);
        }
    };

    return (
        <div className="w-full col-span-full gap-x-6 grid grid-cols-1 gap-y-8 py-8 text-sm">
            <div className="grid grid-cols-2 gap-x-4">
                <button
                    type="button"
                    className={clsx(showExisting ? "primary-button" : "standard-button")}
                    onClick={() => togglePaymentMethod('existing', setShowExisting, setShowNew)}
                    disabled={paymentMethodsListingResponse?.total_count === 0 || paymentMethodsListingVF}
                >
                    Existing payment
                </button>
                <button
                    type="button"
                    className={clsx(!showExisting ? "primary-button" : "standard-button")}
                    onClick={() => togglePaymentMethod('new', setShowExisting, setShowNew)}
                >
                    New payment
                </button>
            </div>

            {showExisting && <AnimatePresence>
                <motion.div
                    initial={{opacity: 0, y: 0}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 0}}
                    transition={{duration: 0.5, delay: 0.1}}
                    className="relative w-full">
                    <SingleSelect
                        list={paymentList}
                        name="selectedPaymentMethod"
                        onChange={(selectedItem) => {
                            setFieldValue('selectedPaymentMethod', selectedItem);
                        }}
                        placeholder="Select Payment Method"
                    />
                </motion.div>
            </AnimatePresence>}
            {showNew && <AnimatePresence>
                <motion.div
                    initial={{opacity: 0, y: 0}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 0}}
                    transition={{duration: 0.5, delay: 0.1}}
                    className="grid grid-cols-2 gap-x-6 gap-y-8"
                >
                    <div className="group relative col-span-full">
                        <label htmlFor="ccNumber" className="absolute -top-2 left-2.5 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-light text-base-content/70 z-10 leading-none">
                            Card Number
                        </label>
                        <div className="" ref={cardNumberElementRef}>
                            <CardNumberElement
                                onFocus={() => handleFocus(cardNumberElementRef)}
                                onBlur={() => handleBlur(cardNumberElementRef)}
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={(event) => handleCardNumberChange(event, setCardBrand)}
                                className="block w-full appearance-none rounded-md border-0 bg-base-100 shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-[0.75px] ring-base-content/20 dark:ring-base-content/20 outline-none text-base-content/70 text-sm sm:leading-6 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light disabled:cursor-not-allowed disabled:text-base-content/50 py-2 px-2 input-ring-focus font-light"
                            />
                            <img
                                src={cardBrand !== "unknown" ? cardLogos[cardBrand] : cardLogos["cardGeneric"]}
                                alt={cardBrand.toUpperCase()}
                                className="w-auto h-4 absolute right-2 top-2 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-sm shrink-0"
                            />
                        </div>
                    </div>
                    <div className="relative col-span-full md:col-span-1">
                        <p className="absolute -top-2 left-2.5 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-light text-base-content/70 z-10 leading-none">Expiry date</p>
                        <div className="col-span-1 flex items-center" ref={cardExpiryElementRef}>
                            <CardExpiryElement
                                onFocus={() => handleFocus(cardExpiryElementRef)}
                                onBlur={() => handleBlur(cardExpiryElementRef)}
                                options={CARD_ELEMENT_OPTIONS}
                                className="block w-full appearance-none rounded-md border-0 bg-base-100 shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-[0.75px] ring-base-content/20 dark:ring-base-content/20 outline-none text-base-content text-sm sm:leading-6 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light disabled:cursor-not-allowed disabled:text-base-content/70 py-2 px-2 input-ring-focus font-light"
                            />
                        </div>
                    </div>
                    <div className="relative col-span-full md:col-span-1">
                        <p className="absolute -top-2 left-2.5 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-light text-base-content/70 z-10 leading-none">CVC</p>
                        <div className="col-span-1 flex items-center" ref={cardCvcElementRef}>
                            <CardCvcElement
                                onFocus={() => handleFocus(cardCvcElementRef)}
                                onBlur={() => handleBlur(cardCvcElementRef)}
                                options={CARD_ELEMENT_OPTIONS}
                                className="block w-full appearance-none rounded-md border-0 bg-base-100 shadow-sm shadow-gray-400/30 dark:shadow-black/50 ring-[0.75px] ring-base-content/20 dark:ring-base-content/20 outline-none text-base-content text-sm sm:leading-6 focus:placeholder-transparent placeholder:text-base-content/30 placeholder:font-light disabled:cursor-not-allowed disabled:text-base-content/70 py-2 px-2 input-ring-focus font-light"
                            />
                        </div>
                    </div>
                    <div className="relative col-span-full group">
                        <p className="absolute -top-2 left-2.5 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-light text-base-content/70 z-10 leading-none">Cardholder name</p>
                        <Field
                            type="text"
                            name="card_name"
                            className="input-field"
                            placeholder="John Doe"
                        />
                    </div>
                    <div className="relative col-span-full group">
                        <p className="absolute -top-2 left-2.5 group-focus-within:inline-block bg-base-100 px-0.5 text-xs font-light text-base-content/70 z-10 leading-none">Billing address</p>
                        <AddressAutocompletion
                            placeholder={"123 Main St, New York, NY, USA, 10001"}
                            onSelect={values => {
                                setFieldValue('address', {
                                    line1: values?.address1,
                                    line2: values?.address2,
                                    city: values?.city,
                                    state: values?.state,
                                    country: values?.countryCode,
                                    postal_code: values?.zip_code,
                                });
                            }}
                        />
                    </div>
                </motion.div>
            </AnimatePresence>}
        </div>
    )
};

SubscriptionPaymentMethodForm.propTypes = {
    showExisting: PropTypes.bool,
    setShowExisting: PropTypes.func,
    showNew: PropTypes.bool,
    setShowNew: PropTypes.func,
    cardBrand: PropTypes.string,
    setCardBrand: PropTypes.func,
    setFieldValue: PropTypes.func,
    paymentList: PropTypes.array,
};

export default SubscriptionPaymentMethodForm;
