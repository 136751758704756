import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const transactionLIST = createAsyncThunk(
    'payments/transactions/list',
    async (thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'GET',
                    path: `${process.env.REACT_APP_TRANSACTIONS_PATH}`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    transactionListingVF: false,
    transactionListingApiState: 'idle',
    transactionListingResponse: null,
    transactionListingError: null,
};

export const listTransactionSlice = createSlice({
    name: 'transactionListing',
    initialState: INITIAL_STATE,
    reducers: {
        clearTransactionListingVF: (state) => {
            state.transactionListingVF = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(transactionLIST.pending, (state) => {
                state.transactionListingApiState = 'loading';
                state.transactionListingVF = true;
            })
            .addCase(transactionLIST.fulfilled, (state, action) => {
                state.transactionListingApiState = 'succeeded';
                state.transactionListingResponse = action.payload;
                state.transactionListingVF = true;
                state.transactionListingError = null;
            })
            .addCase(transactionLIST.rejected, (state, action) => {
                state.transactionListingApiState = 'failed';
                state.transactionListingError = action?.payload;
                state.transactionListingVF = true;
                state.transactionListingResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearTransactionListingVF
} = listTransactionSlice.actions;

export default listTransactionSlice.reducer;
