import React, {Fragment} from "react";
import {Popover, Transition} from "@headlessui/react";
import {Link, useLocation} from "react-router-dom";
import {RiArrowDownSLine} from "react-icons/ri";
import clsx from "clsx";

import {taskGlideItems} from "../../sideBarItems";


const TaskGlideNavPopOver = () => {
    const location = useLocation();
    const path = location.pathname;

    return (
        <Popover.Group className="relative hidden lg:flex lg:gap-x-12" >
            <Popover>
                <Popover.Button className={clsx(
                    "text-base-content inline-flex items-center justify-center rounded-md py-2 px-2 text-sm font-medium whitespace-nowrap space-x-1 hover:bg-base-200 hover:ring-[0.5px] hover:ring-inset hover:ring-neutral-focus/70 hover:dark:ring-neutral-focus-dark/70 button-ring-focus", path.startsWith('/task-glide') && "bg-base-200 text-base-content shadow-inner ring-inset ring-[0.5px] ring-neutral-focus/70 dark:ring-neutral-focus-dark/70"
                )} >
                    <span className="pl-1.5" >TaskGlide</span>
                    <RiArrowDownSLine className="h-4 w-4" aria-hidden="true" />
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out"
                    enterFrom="transform opacity-0 -translate-y-2"
                    enterTo="transform opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="transform opacity-100 translate-y-0"
                    leaveTo="transform opacity-0 -translate-y-2"
                >
                    <Popover.Panel className="absolute left-0 z-30 mt-4 flex-auto overflow-hidden rounded-xl bg-base-100 text-sm ring-[0.5px] ring-neutral-focus/70 dark:ring-neutral-focus-dark/70 shadow-[0px_0px_15px_rgba(0,0,0,0.15)] dark:shadow-[0px_0px_15px_rgba(0,0,0,0.85)] flex items-center justify-center p-3">
                        {({ close }) => (
                            <div className="flex flex-col space-y-2" >
                                {taskGlideItems.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        onClick={() => close()}
                                        className={clsx(
                                            "group relative flex gap-x-6 p-3 hover:bg-base-200 rounded-lg",
                                            path === item.href && 'bg-base-200'
                                        )}
                                    >
                                        <div className={clsx(
                                            "mt-1 flex h-10 w-10 flex-none items-center justify-center rounded-lg group-hover:bg-base-100",
                                            path === item.href ? 'bg-base-100' : "bg-base-200"
                                        )} >
                                            <item.icon className={clsx(
                                                "h-5 w-5 group-hover:text-primary",
                                                path === item.href ? 'text-primary' : "text-base-300"
                                            )} aria-hidden="true" />
                                        </div>
                                        <div className="space-y-0" >
                                            <p className="font-semibold text-base-content" >{item.name}</p>
                                            <p className="text-base-content/70 flex-1 whitespace-nowrap">{item.description}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </Popover.Panel>
                </Transition>
            </Popover>
        </Popover.Group>
    )
};

TaskGlideNavPopOver.propTypes = {

};

export default TaskGlideNavPopOver;
