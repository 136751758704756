import React, {Fragment} from "react";
import {Bars3Icon} from "@heroicons/react/24/outline";
import {Menu, Transition} from "@headlessui/react";
import {Link, useSearchParams} from "react-router-dom";
import clsx from "clsx";
import {HiOutlineUser} from "react-icons/hi2";

import routes from "../../../../Routes/routes";
import {userAvatar} from "../../Settings/Account/AccountSettings";
import {companyLogo} from "../../../../Design/companyLogo";
import {registrationRoutes} from "../../../../Routes/customRoutes";
import {useGetUserInfo} from "../../../../Hooks/apiHooks/users/useGetUserInfo";
import {useAuthentication} from "../../../../Hooks/apiHooks/users/useAuthentication";
import ThemeButtons from "../../../../Components/ThemeButtons/ThemeButtons";
import TaskGlideNavPopOver from "./PopOvers/TaskGlideNavPopOver";
import FeedbackModal from "./Feedback/FeedbackModal";
import MemoMinderNavPopOver from "./PopOvers/MemoMinderNavPopOver";


const Nav = () => {
    const path = window.location.pathname;
    const { userInfoRetrievalResponse } = useGetUserInfo();
    const { logout } = useAuthentication();

    const userNavigation = registrationRoutes.includes(path) ? [{ name: 'Support', href: `mailto:${process.env.REACT_APP_DOVISE_CONTACT_EMAIL}?subject=Support%20ticket%20(${userInfoRetrievalResponse?.username})%20-%20Unregistered` }] :[
        { name: 'Settings', href: routes.auth.settings.account.href },
        { name: 'Billing', href: routes.auth.billing.payment.href },
        { name: 'Support', href: `mailto:${process.env.REACT_APP_DOVISE_CONTACT_EMAIL}?subject=Support%20ticket%20(${userInfoRetrievalResponse?.username})` },
    ];

    const [searchParams, setSearchParams] = useSearchParams();
    const isSidebarOpen = searchParams.get('menu') === 'open';

    const handleMenuClick = () => {
        setSearchParams({ 'menu': isSidebarOpen ? 'close' : 'open' });
    };

    return (
        <div className="sticky top-0 h-14 border-b-[0.75px] border-neutral pt-px bg-base-100 items-center flex w-full z-40" >
            <div className={clsx(
                "flex w-full items-center shrink-0 gap-x-4 px-4 sm:px-6 lg:px-8 mx-auto max-w-5xl",
                registrationRoutes.includes(path) && "justify-between"
            )} >
                <div className={clsx(
                    "flex-none relative items-center px-2 lg:px-0 gap-x-8",
                    registrationRoutes.includes(path) ? "" : "hidden lg:flex"
                )}>
                    <div className="flex flex-shrink-0 items-center">
                        <span className="sr-only">Dovise</span>
                        <img className="h-8 w-auto" src={companyLogo} alt="" />
                    </div>
                </div>

                {!registrationRoutes.includes(path) && (
                    <div className="w-full" >
                        <button type="button" className="-ml-2.5 p-2.5 text-base-300 hover:sm:text-base-300/70 lg:hidden flex-none button-ring-focus" onClick={handleMenuClick}>
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        <div className="flex-none space-x-4 text-sm leading-6 hidden lg:ml-6 lg:flex">
                            <TaskGlideNavPopOver />
                            {/*<MemoMinderNavPopOver />*/}
                        </div>
                    </div>
                )}

                <div className="flex flex-none items-center shrink-0 divide-x divide-neutral-focus/50 dark:divide-neutral-focus-dark/50">
                    {!registrationRoutes.includes(path) && <div className="pr-3" >
                        <FeedbackModal />
                    </div>}
                    <Menu as="div" className="relative">
                        <Menu.Button
                            data-tip="Profile"
                            className="group/profile flex items-center outline-none ml-3 focus-visible:ring focus-visible:ring-info/40 focus-visible:outline-none dark:focus-visible:ring-info/40 focus-visible:rounded-full">
                            <span className="sr-only">Open user menu</span>
                            <div className="bottom-tooltips top-12 ml-1.5 group-hover/profile:sm:block">Profile</div>
                            {userAvatar === "" ? (
                                    userInfoRetrievalResponse?.first_name[0] ? <div className="h-7 w-7 flex items-center justify-center space-x-1 ring-base-content/30 border-0 text-xs rounded-full p-1 bg-base-content ring-[0.5px] ring-neutral-focus dark:ring-base-100 text-base-100 relative">
                                            {userInfoRetrievalResponse?.first_name[0] + userInfoRetrievalResponse?.last_name[0]}
                                        </div> :
                                        <div className="flex items-center justify-center focus:outline-none z-0 p-1 h-7 w-7 bg-base-300 rounded-full text-base-100">
                                            <HiOutlineUser className="h-5 w-5" aria-hidden="true"/>
                                        </div>)
                                : <img
                                    className="h-7 w-7 rounded-full"
                                    src={userAvatar}
                                    alt=""
                                />}
                        </Menu.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out"
                            enterFrom="transform opacity-0 -translate-y-2"
                            enterTo="transform opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="transform opacity-100 translate-y-0"
                            leaveTo="transform opacity-0 -translate-y-2"
                        >
                            <Menu.Items className="button-dropdown mt-2 divide-y divide-neutral/70 w-56 origin-top-right" >
                                <div className="flex items-center justify-between px-4 py-2.5">
                                    <div className="flex flex-col justify-between space-y-0.5">
                                        <p className="truncate text-sm font-medium">
                                            {(userInfoRetrievalResponse?.first_name !== "" && userInfoRetrievalResponse?.last_name !== "") ?
                                                (userInfoRetrievalResponse?.first_name + " " + userInfoRetrievalResponse?.last_name) :
                                                "New User"}
                                        </p>
                                        <p className="text-xs text-base-content/70 font-light">@{userInfoRetrievalResponse?.username}</p>
                                    </div>
                                    <ThemeButtons className="group/theme rounded-full text-base-content/70 hover:sm:text-base-content cursor-pointer outline-0"/>
                                </div>
                                <div className="py-1">
                                    {userNavigation.map((item) => (
                                        <Menu.Item key={item.name}>
                                            <Link
                                                to={item.href}
                                                className="text-base-content/70 hover:sm:text-base-content hover:sm:bg-base-200 px-4 py-2 text-sm w-full flex justify-start"
                                            >
                                                {item.name}
                                            </Link>
                                        </Menu.Item>
                                    ))}
                                </div>
                                <div>
                                    <Menu.Item>
                                        <button
                                            onClick={logout}
                                            className="text-base-content/70 hover:sm:text-base-content hover:sm:bg-base-200 px-4 py-2 text-sm w-full flex justify-start"
                                        >
                                            Sign out
                                        </button>
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    )
};

export default Nav;
