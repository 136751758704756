import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from "react";

import {incrementAlertKey, setAlert} from "../../../api/features/frontend/alertSlice";
import passwordResetReducer, {clearPasswordResetVF, resetPasswordPOST, resetPasswordReset} from "../../../api/features/users/passwordResetSlice";


export const useResetPassword = (
    { vfExists=false } = {}
) => {
    const dispatch = useDispatch();

    const {
        passwordResetVF,
        passwordResetApiState,
        passwordResetResponse,
        passwordResetError,
    } = useSelector(state => state.passwordResetReducer);

    // API State with visual feedback
    const passwordResetSuccessVF = passwordResetApiState === 'succeeded' && passwordResetVF;
    const passwordResetFailureVF = passwordResetApiState === 'failed' && passwordResetVF;
    const passwordResetLoadingVF = passwordResetApiState === 'loading' && passwordResetVF;

    // Function to be called on form submission
    const resetPassword = (values) => dispatch(resetPasswordPOST(values));

    useEffect(() => {
        if (vfExists) {
            if (passwordResetSuccessVF) {
                dispatch(setAlert({
                    message: passwordResetResponse?.message,
                    type: "success"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(clearPasswordResetVF());
                }, 4000);

                return () => clearTimeout(timer);
            }  else if (passwordResetFailureVF) {
                dispatch(setAlert({
                    message: passwordResetError?.message || "Something went wrong. Please try again.",
                    type: "error"
                }));
                dispatch(incrementAlertKey());

                const timer = setTimeout(() => {
                    dispatch(resetPasswordReset());
                }, 4000);

                return () => clearTimeout(timer);
            }
        }
    }, [passwordResetApiState]);

    return {
        resetPassword,
        passwordResetVF,
        passwordResetApiState,
        passwordResetResponse,
        passwordResetError,
        passwordResetSuccessVF,
        passwordResetFailureVF,
        passwordResetLoadingVF
    };
};
