import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const documentsParsePOST = createAsyncThunk(
    'taskGlide/parse-documents',
    async ({ documents }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_TASKGLIDE_PARSE_DOCUMENTS_PATH}`,
                    args: documents,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    parseDocumentsVF: false,
    parseDocumentsApiState: 'idle',
    parseDocumentsResponse: null,
    parseDocumentsError: null,
}

export const parseDocumentsSlice = createSlice({
    name: 'documentsParse',
    initialState: INITIAL_STATE,
    reducers: {
        clearParseDocumentsVF: (state) => {
            state.parseDocumentsVF = false;
        },
        resetParseDocuments: (state) => {
            state.parseDocumentsVF = false;
            state.parseDocumentsApiState = 'idle';
            state.parseDocumentsResponse = null;
            state.parseDocumentsError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(documentsParsePOST.pending, (state) => {
                state.parseDocumentsApiState = 'loading';
                state.parseDocumentsVF = true;
            })
            .addCase(documentsParsePOST.fulfilled, (state, action) => {
                state.parseDocumentsApiState = 'succeeded';
                state.parseDocumentsResponse = action.payload;
                state.parseDocumentsVF = true;
                state.parseDocumentsError = null;
            })
            .addCase(documentsParsePOST.rejected, (state, action) => {
                state.parseDocumentsApiState = 'failed';
                state.parseDocumentsError = action?.payload;
                state.parseDocumentsVF = true;
                state.parseDocumentsResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearParseDocumentsVF,
    resetParseDocuments
} = parseDocumentsSlice.actions;

export default parseDocumentsSlice.reducer;
