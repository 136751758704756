import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const userDefinedGlidePOST = createAsyncThunk(
    'taskGlide/glides/user-defined/create',
    async ({ values }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_TASKGLIDE_CREATE_USER_DEFINED_GLIDES_PATH}`,
                    accessCode: access,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userDefinedGlideCreateVF: false,
    userDefinedGlideCreateApiState: 'idle',
    userDefinedGlideCreateResponse: null,
    userDefinedGlideCreateError: null,
};

export const createUserGlideSlice = createSlice({
    name: 'userDefinedGlideCreate',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserDefinedGlideCreateVF: (state) => {
            state.userDefinedGlideCreateVF = false;
        },
        resetUserDefinedGlideCreate: (state) => {
            state.userDefinedGlideCreateVF = false;
            state.userDefinedGlideCreateApiState = 'idle';
            state.userDefinedGlideCreateResponse = null;
            state.userDefinedGlideCreateError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userDefinedGlidePOST.pending, (state) => {
                state.userDefinedGlideCreateApiState = 'loading';
                state.userDefinedGlideCreateVF = true;
            })
            .addCase(userDefinedGlidePOST.fulfilled, (state, action) => {
                state.userDefinedGlideCreateApiState = 'succeeded';
                state.userDefinedGlideCreateResponse = action?.payload;
                state.userDefinedGlideCreateVF = true;
                state.userDefinedGlideCreateError = null;
            })
            .addCase(userDefinedGlidePOST.rejected, (state, action) => {
                state.userDefinedGlideCreateApiState = 'failed';
                state.userDefinedGlideCreateError = action?.payload;
                state.userDefinedGlideCreateVF = true;
                state.userDefinedGlideCreateResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUserDefinedGlideCreateVF, resetUserDefinedGlideCreate
} = createUserGlideSlice.actions;

export default createUserGlideSlice.reducer;
