import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {useCallBackend} from "../../calls/useCallBackend";


export const undoCancellationPOST = createAsyncThunk(
    'payments/subscription/undo-cancellation',
    async (price_id, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_UNDO_CANCELLATION_PATH}${price_id}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    undoCancellationVF: false,
    undoCancellationApiState: 'idle',
    undoCancellationResponse: null,
    undoCancellationError: null,
}

export const undoCancellationSlice = createSlice({
    name: 'undoCancellation',
    initialState: INITIAL_STATE,
    reducers: {
        clearUndoCancellationVF: (state) => {
            state.undoCancellationVF = false;
        },
        resetUndoCancellation: (state) => {
            state.undoCancellationVF = false;
            state.undoCancellationApiState = 'idle';
            state.undoCancellationResponse = null;
            state.undoCancellationError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(undoCancellationPOST.pending, (state) => {
                state.undoCancellationApiState = 'loading';
                state.undoCancellationVF = true;
            })
            .addCase(undoCancellationPOST.fulfilled, (state, action) => {
                state.undoCancellationApiState = 'succeeded';
                state.undoCancellationResponse = action.payload;
                state.undoCancellationVF = true;
                state.undoCancellationError = null;
            })
            .addCase(undoCancellationPOST.rejected, (state, action) => {
                state.undoCancellationApiState = 'failed';
                state.undoCancellationError = action?.payload;
                state.undoCancellationVF = true;
                state.undoCancellationResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUndoCancellationVF,
    resetUndoCancellation
} = undoCancellationSlice.actions;

export default undoCancellationSlice.reducer;
