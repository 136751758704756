import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    theme: localStorage.getItem("theme") || "light",
    mobileAuthMenuOpen: false,
    pageIsLoading: false,
    dataIsLoading: false,
};

export const uiSlice = createSlice({
    name: 'frontendResponse',
    initialState: initialState,
    reducers: {
        toggleTheme: (state) => {
            state.theme = (state.theme === "light") ? "dark" : "light";
            localStorage.setItem('theme', state.theme);
        },
        toggleAuthMobileMenu: (state) => {
            state.mobileAuthMenuOpen = !state.mobileAuthMenuOpen;
        },
        pageLoading: (state) => {
            state.pageIsLoading = true;
        },
        pageLoaded: (state) => {
            state.pageIsLoading = false;
        },
        dataLoading: (state) => {
            state.dataIsLoading = true;
        },
        dataLoaded: (state) => {
            state.dataIsLoading = false;
        },
    },
    extraReducers: (builder) => {
        // Handle your async actions here
    }
});

export const {
    toggleTheme,
    toggleAuthMobileMenu,
    pageLoaded,
    dataLoaded,
} = uiSlice.actions;

export default uiSlice.reducer;
