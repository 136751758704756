import React, {useEffect, useRef, useState} from "react";
import {HiOutlineDotsHorizontal} from "react-icons/hi";
import PropTypes from "prop-types";
import {LuFiles, LuStar, LuStarOff} from "react-icons/lu";
import {useSearchParams} from "react-router-dom";

import MessageDeletionModal from "./MessageDeletionModal";
import {useTogglePinMessage} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useTogglePinMessage";
import DropDown from "../../../../../../../../Components/DropDown/DropDown";
import {GoCodescanCheckmark} from "react-icons/go";


const MessageActions = ({ message, scope, onDelete, chatBoxRef, pinningIsEnabled }) => {
    const [searchParams] = useSearchParams();
    const conversationUUID = searchParams.get('conversation_uuid');
    const {togglePinMessage} = useTogglePinMessage({vfExists: true, autoUpdate: false, conversationUUID});

    const modalRef = useRef(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [copied, setCopied] = useState(false);
    const isUserMessage = scope === "user";
    const messageIsStarred = message?.content?.is_pinned;

    const handleCopy = () => {
        const text = isUserMessage ? message?.text_input : message?.main_response;
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    useEffect(() => {
        if (copied) {
            setDropdownVisible(false);
        }
    }, [copied]);

    const items = [
        ...(pinningIsEnabled ?
            [{
                label: messageIsStarred ? "Unstar" : "Star",
                icon: messageIsStarred ? <LuStarOff /> : <LuStar />,
                onClick: () => togglePinMessage(message?.id, isUserMessage),
            }] : []
        ),
        {
            label: "Copy",
            icon: <LuFiles />,
            onClick: handleCopy,
        },
        {
            label: "Review",
            icon: <GoCodescanCheckmark />,
            onClick: null
        },
        // {
        //     label: "Reply",
        //     icon: <HiReply />,
        //     onClick: () => console.log("Reply clicked"),
        // },
        // {
        //     label: isUserMessage ? "Edit" : "Retry",
        //     icon: isUserMessage ? <LuPencil /> : <HiOutlineRefresh />,
        //     onClick: () => console.log(message?.scope === "task-glide" ? "Retry clicked" : "Edit clicked"),
        // },
        <MessageDeletionModal
            key="delete"
            scope={scope}
            message={message}
            ref={modalRef}
            onDelete={() => {
                onDelete();
                setDropdownVisible(false);
            }}
        />,
    ];

    return (
        <DropDown
            dropDownClass="w-36"
            dropdownIcon={<HiOutlineDotsHorizontal className="w-4 h-4 shrink-0 text-base-300" />}
            items={items}
            dropDownParent={chatBoxRef}
            setDropdownVisible={setDropdownVisible}
            dropdownVisible={dropdownVisible}
            additionalRefs={[modalRef]}
        />
    )
};

MessageActions.propTypes = {
    scope: PropTypes.oneOf(['user', 'task-glide']),
    message: PropTypes.object,
    onDelete: PropTypes.func,
    chatBoxRef: PropTypes.object,
    pinningIsEnabled: PropTypes.bool,
};

export default MessageActions;
