import React from "react";
import PropTypes from "prop-types";

import Markdown from "../../../../Components/Markdown/Markdown";


const bookText = `## Dawn of a Nation: The Story of the United States**  
*Volume 1: From Exploration to Revolution*

---

### Preface

The unfolding story of the United States is a saga of ambition, conflict, and the relentless pursuit of ideals. It is a narrative sculpted by the hands of countless individuals, from the indigenous peoples who first nurtured this land, through waves of settlers seeking freedom or fortune, to the visionaries who dreamed of a republic built on principles of liberty and justice. This volume, the first in a series dedicated to unraveling the intricate tapestry of American history, aims to bring to light the early chapters of this unique nation's journey.

As we delve into the age of exploration, the colonial era, and the revolutionary fervor that birthed a new country, we invite readers to consider not just the events and dates, but the human experiences that propelled these momentous times. This is a tale of dreams, of struggles, and of the indomitable spirit of a people determined to forge their own destiny.

---

### Chapter 1: The Age of Discovery

#### The World Awaits

Before the sails of Columbus's ships billowed in the Atlantic breeze, the world lay divided not just by oceans but by understanding. Europe, in the late 15th century, was a place of burgeoning curiosity, where the limitations of the known world chafed against a growing thirst for knowledge and wealth. This chapter explores the forces that propelled adventurers across treacherous seas, driven by the promise of discovery and the unyielding human urge to explore the unknown.

#### Collision of Worlds

Columbus's landing in the Bahamas was a pivotal moment, not only for the explorers who had ventured forth from Europe but also for the civilizations they encountered in the New World. This section delves into the initial encounters between Europeans and the indigenous peoples of the Americas, exploring the awe, misunderstandings, and eventual conflicts that arose from these first interactions. Through narratives of cooperation and confrontation, we glimpse the complexity of this epochal meeting of worlds.

---

### Chapter 2: Colonization and Conflict

#### A Tapestry of Settlements

The century following Columbus's voyage saw the European powers scramble to plant their flags over the New World. This chapter examines the establishment of colonies by the Spanish, English, French, and Dutch, each with its own ambitions, challenges, and legacies. From the harsh winters and communal spirit of Plymouth to the multicultural trade hub of New Amsterdam, we explore the varied experiences of settlers who laid the foundations of future states.

#### The Native American Experience

Integral to the story of colonization is the experience of the Native American tribes, whose lands and lives were irrevocably changed by the arrival of Europeans. This section offers insight into the diverse societies that existed pre-contact, their reactions to the settlers, and the subsequent impacts on their cultures, populations, and territories. Through narratives of resistance, adaptation, and survival, we honor the resilience of these communities in the face of profound transformation.`

const yamlDoc = `\`\`\`yaml
openapi: 3.0.0
info:
  title: WeatherNow API
  version: 1.0.0
  description: The WeatherNow API provides real-time weather information based on the user's location.

servers:
  - url: https://api.weathernow.com/v1
    description: Production server

paths:
  /current:
    get:
      summary: Get Current Weather
      operationId: getCurrentWeather
      description: Returns the current weather conditions for a specified location.
      parameters:
        - in: query
          name: latitude
          schema:
            type: string
          required: true
          description: The latitude of the location.
        - in: query
          name: longitude
          schema:
            type: string
          required: true
          description: The longitude of the location.
      responses:
        '200':
          description: A successful response containing the weather information for the provided location.
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/CurrentWeatherResponse'
        '401':
          description: Unauthorized. API key is missing or invalid.
        '404':
          description: Not Found. The requested location is not available.
      security:
        - ApiKeyAuth: []

  /forecast:
    get:
      summary: Get Weather Forecast
      operationId: getWeatherForecast
      description: Retrieves the weather forecast for the next 5 days for the specified location.
      parameters:
        - in: query
          name: latitude
          schema:
            type: string
          required: true
          description: The latitude of the location.
        - in: query
          name: longitude
          schema:
            type: string
          required: true
          description: The longitude of the location.
      responses:
        '200':
          description: A successful response containing the 5-day weather forecast for the provided location.
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/ForecastResponse'
        '401':
          description: Unauthorized. API key is missing or invalid.
        '404':
          description: Not Found. The requested forecast is not available.
      security:
        - ApiKeyAuth: []

components:
  securitySchemes:
    ApiKeyAuth:
      type: apiKey
      in: header
      name: Authorization

  schemas:
    CurrentWeatherResponse:
      type: object
      properties:
        location:
          type: string
        temperature:
          type: number
        humidity:
          type: number
        condition:
          type: string

    ForecastResponse:
      type: object
      properties:
        location:
          type: string
        forecast:
          type: array
          items:
            type: object
            properties:
              day:
                type: string
              temperature:
                type: number
              humidity:
                type: number
              condition:
                type: string
\`\`\``;

const generatedDoc = `# WeatherNow API Documentation

## Introduction

The WeatherNow API offers real-time weather information based on user location, providing current weather conditions and a 5-day weather forecast. Designed for developers, this API integrates seamlessly into applications requiring weather data.

## Base URL

The WeatherNow API is accessible at the following base URL: \`https://api.weathernow.com/v1\`. All endpoints described below are relative to this base URL.

## Authentication

WeatherNow API uses API keys for authentication. The API key must be included in the header of each request:

\`\`\`http
Authorization: ApiKey [YOUR_API_KEY]
\`\`\`

Ensure your API key is kept secure and not exposed publicly.

## Endpoints

### Get Current Weather

**GET** \`/current\`

Fetches the current weather conditions for a specified location.

#### Parameters

| Name       | In    | Type   | Required | Description                         |
|------------|-------|--------|----------|-------------------------------------|
| latitude   | query | string | true     | The latitude of the location.       |
| longitude  | query | string | true     | The longitude of the location.      |

#### Responses

- **200 OK**  
  Returns the current weather conditions for the provided location.

- **401 Unauthorized**  
  API key is missing or invalid.

- **404 Not Found**  
  The requested location is not available.

#### Sample Request

\`\`\`python
import requests

url = "https://api.weathernow.com/v1/current?latitude=40.712776&longitude=-74.005974"
headers = {"Authorization": "ApiKey [YOUR_API_KEY]"}

response = requests.get(url, headers=headers)
print(response.json())
\`\`\`

#### Sample Response

\`\`\`json
{
  "location": "New York, NY",
  "temperature": 21,
  "humidity": 65,
  "condition": "Sunny"
}
\`\`\`

### Get Weather Forecast

**GET** \`/forecast\`

Retrieves the weather forecast for the next 5 days for the specified location.

#### Parameters

| Name       | In    | Type   | Required | Description                         |
|------------|-------|--------|----------|-------------------------------------|
| latitude   | query | string | true     | The latitude of the location.       |
| longitude  | query | string | true     | The longitude of the location.      |

#### Responses

- **200 OK**  
  Returns the 5-day weather forecast for the provided location.

- **401 Unauthorized**  
  API key is missing or invalid.

- **404 Not Found**  
  The requested forecast is not available.

#### Sample Request

\`\`\`python
import requests

url = "https://api.weathernow.com/v1/forecast?latitude=40.712776&longitude=-74.005974"
headers = {"Authorization": "ApiKey [YOUR_API_KEY]"}

response = requests.get(url, headers=headers)
print(response.json())
\`\`\`

#### Sample Response

\`\`\`json
{
  "location": "New York, NY",
  "forecast": [
    {
      "day": "2024-03-19",
      "temperature": 18,
      "humidity": 60,
      "condition": "Partly Cloudy"
    },
    {
      "day": "2024-03-20",
      "temperature": 22,
      "humidity": 55,
      "condition": "Sunny"
    },
    // Additional days omitted for brevity
  ]
}
\`\`\``;


const DocumentationMockFrame = () => {

    return (
        <>
            <div className="bg-base-200 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-2xl p-3 w-[48rem] h-[550px] max-w-none shadow-[0px_0px_50px_rgba(0,0,0,0.20)] dark:shadow-[0px_0px_50px_rgba(0,0,0,0.75)] mx-auto lg:-ml-0 text-sm overflow-hidden absolute -top-8 left-0">
                <div className="h-full w-full overflow-hidden px-4 pb-4 bg-base-100 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-xl">
                    <Markdown
                        includeCodeHeader={false}
                        className="bg-transparent min-w-full text-base-content prose-h1:mt-1"
                        codeIsCustomized={true}
                        contentClass="text-xs prose-p:text-xs prose-h1:text-base prose-h2:text-sm prose-h3:text-sm leading-relaxed prose-p:leading-relaxed"
                        text={bookText}
                    />
                </div>
            </div>
            <div className="bg-base-200 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-2xl p-3 w-[48rem] h-[550px] max-w-none shadow-[0px_0px_50px_rgba(0,0,0,0.20)] dark:shadow-[0px_0px_50px_rgba(0,0,0,0.75)] mx-auto lg:-ml-0 text-sm overflow-hidden absolute top-0 left-32">
                <div className="h-full w-full overflow-hidden px-4 pb-4 bg-base-100 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-xl">
                    <Markdown
                        includeCodeHeader={false}
                        codeClassName="pt-0"
                        contentClass="text-xs prose-p:text-xs prose-h1:text-base prose-h2:text-sm prose-h3:text-sm leading-relaxed prose-p:leading-relaxed"
                        className="bg-transparent min-w-full text-base-content"
                        codeIsCustomized={true}
                        text={yamlDoc}
                    />
                </div>
            </div>
            <div className="bg-base-200 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-2xl p-3 w-[48rem] h-[550px] max-w-none shadow-[0px_0px_50px_rgba(0,0,0,0.20)] dark:shadow-[0px_0px_50px_rgba(0,0,0,0.75)] mx-auto lg:-ml-0 text-sm overflow-hidden absolute top-8 left-64">
                <div className="h-full w-full overflow-hidden px-4 pb-4 bg-base-100 ring-[0.5px] ring-neutral-focus dark:ring-neutral-focus-dark rounded-xl leading">
                    <Markdown
                        includeCodeHeader={false}
                        className="bg-transparent min-w-full text-base-content prose-h1:mt-1"
                        codeIsCustomized={true}
                        contentClass="text-xs prose-p:text-xs prose-h1:text-[17px] prose-h2:text-sm prose-h3:text-sm leading-relaxed prose-p:leading-relaxed"
                        text={generatedDoc}
                    />
                </div>
            </div>
        </>
    );
};

DocumentationMockFrame.propTypes = {
    isOriginal: PropTypes.bool
};

export default DocumentationMockFrame;
