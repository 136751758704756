import React from "react";
import {BsChevronCompactLeft} from "react-icons/bs";
import {PiLineVerticalLight} from "react-icons/pi";
import {useSearchParams} from "react-router-dom";

import {useListAllTgConversations} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useListAllTgConversations";
import {useGetSingleTgConversation} from "../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetSingleTgConversation";


export const toggleConversationHistorySideBar = (state, searchParams, setSearchParams) => {
    const currentParams = Object.fromEntries(searchParams.entries());
    setSearchParams({
        ...currentParams,
        'conversation-history': state,
    });
};


const ConversationHistoryButton = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { tgConversationsListingResponse } = useListAllTgConversations();
    const { tgSingleConversationRetrievalVF } = useGetSingleTgConversation();

    return (
        <button
            type="button"
            className="hidden lg:block absolute -right-2 top-1/2 inset-y-0 transform -translate-y-1/2 mb-10 text-base-content/30 hover:text-base-content/50 cursor-pointer z-50 group/conversation-history h-fit w-fit group/conversation-sidebar focus-visible:ring-0 focus-visible:outline-none"
            disabled={!tgConversationsListingResponse || tgSingleConversationRetrievalVF}
            onClick={() => toggleConversationHistorySideBar('open', searchParams, setSearchParams)}
        >
            <BsChevronCompactLeft className="w-auto h-16 shrink-0 hidden group-hover/conversation-sidebar:block"/>
            <PiLineVerticalLight className="w-auto h-16 shrink-0 group-hover/conversation-sidebar:hidden block"/>
            <div className="left-tooltips right-14 group-hover/conversation-history:lg:block">Conversations</div>
        </button>
    )
};

export default ConversationHistoryButton;
