import React, {forwardRef, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";
import {IoTrash} from "react-icons/io5";
import clsx from "clsx";
import {BsTrash3} from "react-icons/bs";

import {useDeleteTaskGlideMessage} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteTaskGlideMessage";
import {useListAllTgConversations} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useListAllTgConversations";
import {useGetSingleTgConversation} from "../../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetSingleTgConversation";


const MessageDeletionModal = forwardRef(({ scope, message, onDelete }, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalId = scope === 'user' ? `user-message-deletion-${message?.id}` : `agent-response-deletion-${message?.id}`;

    useEffect(() => {
        const dialogElement = document.getElementById(modalId);
        if (isModalOpen) {
            dialogElement?.showModal();
        } else {
            dialogElement?.close();
        }
    }, [isModalOpen, modalId]);

    const renderedMessage = scope === 'user' ? message?.content?.text_input : message?.content?.main_response;

    const {
        taskGlideMessageDeletionApiState,
        taskGlideMessageDeletionVF,
        taskGlideMessageDeleteSuccessVF,
        taskGlideMessageDeleteFailureVF,
    } = useDeleteTaskGlideMessage();

    const { tgConversationsListingVF } = useListAllTgConversations();
    const { tgSingleConversationRetrievalVF } = useGetSingleTgConversation();

    useEffect(() => {
        const dialogElement = document.getElementById(modalId);

        if (taskGlideMessageDeleteSuccessVF || taskGlideMessageDeleteFailureVF) {
            dialogElement?.close();
            setIsModalOpen(false);
        }
    }, [taskGlideMessageDeletionApiState, taskGlideMessageDeletionVF]);

    const disabledState = taskGlideMessageDeletionVF || tgConversationsListingVF || tgSingleConversationRetrievalVF;

    return (
        <>
            <button
                disabled={disabledState}
                className="dropdown-item"
                ref={ref}
                onClick={(e) => {
                    if (!disabledState) {
                        e.preventDefault();
                        const dialogElement = document.getElementById(modalId);
                        dialogElement.showModal();
                        setIsModalOpen(true);
                    }
                }}
            >
                <BsTrash3 className="size-3.5 shrink-0" />
                <p>Delete</p>
            </button>
            <dialog
                id={modalId}
                onClose={() => setIsModalOpen(false)}
                className={clsx("modal-bg", {modal: isModalOpen})}
            >
                <form
                    method="dialog"
                    className="modal-wrapper text-wrap"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-error-content sm:mx-0 sm:h-10 sm:w-10">
                            <IoTrash className="h-6 w-6 text-error" aria-hidden="true"/>
                        </div>
                        <div className="mt-3 text-center sm:mx-4 sm:mt-2 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-base-content">
                                {scope === "user" ? "Delete Message" : "Delete Response"}
                            </h3>
                            <div className="mt-2">
                                <div className="break-all text-sm text-base-content/70 space-y-4">
                                    <p>Are you sure you want to delete the following {scope === "user" ? "message" : "response"}? This action cannot be undone.</p>
                                    <p className="text-start border-l-4 border-base-content/30 pl-3 line-clamp-2">{renderedMessage}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-end gap-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                const dialogElement = document.getElementById(modalId);
                                if (dialogElement) {
                                    dialogElement.close();
                                }
                            }}
                            className="standard-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            disabled={disabledState}
                            className="error-button"
                            onClick={onDelete}
                        >
                            <CgSpinner
                                className={clsx(
                                    "absolute inset-0 m-auto animate-spin h-5 w-5",
                                    disabledState ? "" : "hidden"
                                )}
                                aria-hidden="true"
                            />
                            <p className={disabledState ? "invisible" : ""}>
                                Delete
                            </p>
                        </button>
                    </div>
                </form>
                <form method="dialog" className="modal-backdrop">
                    <button
                        className="cursor-default"
                        onClick={() => {
                            const dialogElement = document.getElementById(modalId);
                            if (dialogElement) {
                                dialogElement.close();
                            }
                        }}
                    >close
                    </button>
                </form>
            </dialog>
        </>
    );
});

MessageDeletionModal.displayName = 'MessageDeletionModal';

MessageDeletionModal.propTypes = {
    disabledState: PropTypes.bool,
    scope: PropTypes.string,
    message: PropTypes.object,
    onDelete: PropTypes.func,
}

export default MessageDeletionModal;
