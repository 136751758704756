import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {CgSpinner} from "react-icons/cg";
import clsx from "clsx";


const UserMessageImageInput = ({ imageUrl }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isValidImage, setIsValidImage] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const imgRef = useRef(null);

    useEffect(() => {
        const img = imgRef.current;
        const handleLoad = () => setIsValidImage(true);
        const handleError = () => setIsValidImage(false);

        img.addEventListener("load", handleLoad);
        img.addEventListener("error", handleError);

        return () => {
            img.removeEventListener("load", handleLoad);
            img.removeEventListener("error", handleError);
        };
    }, [imageUrl]);

    useEffect(() => {
        const dialogElement = document.getElementById(`modal-${imageUrl}`);
        if (isModalOpen) {
            dialogElement?.showModal();
        } else {
            dialogElement?.close();
        }
    }, [isModalOpen, imageUrl]);

    return (
        <>
            <button
                disabled={isValidImage && !imageLoaded}
                className="max-w-2xs h-fit w-full rounded-md self-end overflow-hidden disabled:cursor-default"
                onClick={() => setIsModalOpen(true)}
            >
                {(isValidImage && !imageLoaded) &&
                    <div className="flex justify-center items-center h-40 w-full bg-base-300/30 skeleton rounded-md" />}
                <img
                    ref={imgRef}
                    src={imageUrl}
                    onLoad={() => {
                        const timer = setTimeout(() => {
                            setImageLoaded(true);
                        }, 250);
                        return () => clearTimeout(timer);
                    }}
                    style={{display: (isValidImage && imageLoaded) ? 'block' : 'none'}}
                    alt="Uploaded"
                />
            </button>
            <dialog
                id={`modal-${imageUrl}`}
                onClose={() => setIsModalOpen(false)}
                className={clsx("relative modal-bg", {modal: isModalOpen})}
            >
                <form
                    method="dialog"
                    className="rounded-md overflow-hidden w-fit p-2 absolute"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <img
                        src={imageUrl}
                        alt="Opened Full-Screen"
                        className={clsx(
                            'overflow-hidden max-h-[36rem] rounded-md h-full w-full duration-300 transform',
                            isModalOpen ? 'scale-100 opacity-100' : 'scale-75 opacity-0'
                        )}
                    />
                </form>
                <form method="dialog" className="modal-backdrop">
                    <button
                        className="cursor-default"
                        onClick={() => setIsModalOpen(false)}
                    >close
                    </button>
                </form>
            </dialog>
        </>
    );
};

UserMessageImageInput.propTypes = {
    imageUrl: PropTypes.string,
};

export default UserMessageImageInput;
