import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import {userAvatar} from "../../../../../Settings/Account/AccountSettings";
import {glideColorClasses, getGlideIcon} from "../../../Chat/ChatBox/Messages/glideInfo";
import TaskGlideMessage from "../../../Chat/ChatBox/Messages/TaskGlideMessage";
import {useDeleteTaskGlideMessage} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useDeleteTaskGlideMessage";
import {useStreamTaskGlide} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/taskGlide/useStreamTaskGlide";
import {useGetTestConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/conversations/useGetTestConversation";
import MessageLoader from "../../../../../../../Components/MessageLoader/MessageLoader";
import {useGetCurrentTgConversation} from "../../../../../../../Hooks/apiHooks/superIntelligence/taskGlide/frontend/useGetCurrentTgConversation";


const GlideCreatorTestChatBox = (
    { glide, onImageLoad, handleInteractionPagination }
) => {
    const { deleteTaskGlideMessage } = useDeleteTaskGlideMessage({vfExists: true, autoUpdate: true});
    const { taskGlideStreamVF } = useStreamTaskGlide({ isTest: true });
    const { currentTestTgConversation } = useGetCurrentTgConversation();
    const { testConversationRetrievalVF } = useGetTestConversation({ vfExists: true });

    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        onImageLoad(imageLoaded);
    }, [imageLoaded, onImageLoad]);

    if (currentTestTgConversation?.interactions?.results?.length === 0 || !currentTestTgConversation) {
        return (
            <div className="relative flex flex-col justify-center h-full space-y-4">
                <div className={clsx(
                    "rounded-full p-2.5 text-base-100 leading-none font-medium w-fit mx-auto",
                    glideColorClasses[glide?.color]?.bg)}
                >
                    {React.cloneElement(getGlideIcon(glide?.name, "w-20 h-20 lg:w-16 lg:h-16 text-3xl p-2"), {
                        onLoad: () => setImageLoaded(true)
                    })}
                </div>
                <div className="mx-auto max-w-xl space-y-2">
                    <div className="flex flex-col items-center justify-center">
                        <h2 className="text-lg text-base-content text-center font-semibold">{glide?.name}</h2>
                        <div className="text-xs text-center inline py-1">
                            <p className="text-base-content/70 inline font-light" >By </p>
                            <p className="text-base-content inline font-light" >{glide?.created_by_username === "dovise" ? "Dovise" : `@${glide?.created_by_username}`}</p>
                        </div>
                    </div>
                    <p className="text-base text-center text-base-content/70 font-light">{glide?.description}</p>
                </div>
            </div>
        )
    }
    return (
        <div className="relative flex-1 px-6 sm:px-4 space-y-4 w-full py-4">
            {!!currentTestTgConversation?.interactions?.next && <MessageLoader onLoad={handleInteractionPagination} isLoading={testConversationRetrievalVF} disabled={taskGlideStreamVF} />}
            {currentTestTgConversation?.interactions?.results?.map((message) => {
                const isLastMessage = currentTestTgConversation?.interactions?.results?.[currentTestTgConversation?.interactions?.results?.length - 1]?.id === message?.id;

                return (
                    <TaskGlideMessage
                        key={message?.id || 0}
                        isLastMessage={isLastMessage}
                        message={message}
                        userAvatar={userAvatar}
                        onUserMessageDelete={() => deleteTaskGlideMessage(message?.id, true)}
                        onTaskGlideResponseDelete={() => deleteTaskGlideMessage(message?.id, false)}
                        pinningIsEnabled={false}
                    />
                )
            })}
        </div>
    );
};

GlideCreatorTestChatBox.propTypes = {
    glide: PropTypes.object,
    onImageLoad: PropTypes.func,
    handleInteractionPagination: PropTypes.func
};

export default GlideCreatorTestChatBox;
