import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { motion } from "framer-motion";


const GlideCaption = ({ glideState, glideName }) => {

    let content;
    switch (glideState) {
        case 'create_conversation':
        case 'retrieve_conversation':
        case 'create_interaction':
            content = "Thinking"; break;
        case 'create_schema':
        case 'get_responding_glide':
            content = "Evaluating"; break;
        case 'call_main_glide':
            content = "Responding"; break;
        case 'uploading_document':
        case 'uploaded_document':
            content = "Uploaded document"; break;
        case 'call_reviewer_glide':
            content = "Reviewing"; break;
        case 'call_glide_tool':
            content = `Using external tools`; break;
        default:
            content = glideName;
    }

    const notGlideName = content !== glideName;

    const shineVariants = {
        shine: {
            backgroundPosition: ['-200px', '200px'],
            transition: {
                backgroundPosition: {
                    duration: 2,
                    ease: 'easeInOut',
                    repeat: Infinity,
                },
            },
        },
    };

    return (
        <div
            className={clsx(
                "line-clamp-1 leading-none text-sm py-0.5 relative",
                notGlideName ? "font-normal" : "font-medium"
            )}
        >
            {content}
            {notGlideName && (
                <motion.div
                    className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-base-100 to-transparent"
                    style={{
                        backgroundSize: '200px 100%',
                        backgroundPosition: '-200px',
                    }}
                    variants={shineVariants}
                    animate="shine"
                />
            )}
        </div>
    );
};

GlideCaption.propTypes = {
    glideState: PropTypes.string,
    glideName: PropTypes.string,
}

export default GlideCaption;
