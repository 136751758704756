import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../../../calls/useCallBackend";


export const togglePinMessagePOST = createAsyncThunk(
    'taskGlide/conversation/message/toggle-pin',
    async ({ interaction_id, is_user_message_str }, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'POST',
                    path: `${process.env.REACT_APP_TASKGLIDE_TOGGLE_PIN_MESSAGE_PATH}${interaction_id}/${is_user_message_str}/`,
                    accessCode: access,
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    togglePinMessageVF: false,
    togglePinMessageApiState: 'idle',
    togglePinMessageResponse: null,
    togglePinMessageError: null,
}

export const searchTgConversationsSlice = createSlice({
    name: 'messageTogglePin',
    initialState: INITIAL_STATE,
    reducers: {
        clearMessageTogglePinVF: (state) => {
            state.togglePinMessageVF = false;
        },
        resetMessageTogglePin: (state) => {
            state.togglePinMessageVF = false;
            state.togglePinMessageApiState = 'idle';
            state.togglePinMessageResponse = null;
            state.togglePinMessageError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(togglePinMessagePOST.pending, (state) => {
                state.togglePinMessageApiState = 'loading';
                state.togglePinMessageVF = true;
            })
            .addCase(togglePinMessagePOST.fulfilled, (state, action) => {
                state.togglePinMessageApiState = 'succeeded';
                state.togglePinMessageResponse = action.payload;
                state.togglePinMessageVF = true;
                state.togglePinMessageError = null;
            })
            .addCase(togglePinMessagePOST.rejected, (state, action) => {
                state.togglePinMessageApiState = 'failed';
                state.togglePinMessageError = action?.payload;
                state.togglePinMessageVF = true;
                state.togglePinMessageResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearMessageTogglePinVF, resetMessageTogglePin
} = searchTgConversationsSlice.actions;

export default searchTgConversationsSlice.reducer;
