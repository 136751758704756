import React from "react";
import PropTypes from "prop-types";
import {RiUserLine} from "react-icons/ri";


const UserAvatar = ({ userInfo }) => {

    return (
        <div className="h-7 w-7 sm:h-6 sm:w-6 rounded-full flex items-center justify-center text-base-100 text-xs sm:text-2xs font-medium bg-gray-600 dark:bg-gray-400" >
             {<RiUserLine className="w-3.5 h-3.5" /> || userInfo?.first_name[0] + userInfo?.last_name[0]}
        </div>
    )
};

UserAvatar.propTypes = {
    userInfo: PropTypes.object.isRequired,
};

export default UserAvatar;
