import React from "react";


const Test = () => {

    return (
       <div className="p-96 h-screen w-screen border border-black" >

       </div>
    )
};

export default Test;
