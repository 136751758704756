import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {HiOutlineChatBubbleOvalLeft} from "react-icons/hi2";
import {motion} from "framer-motion";

import {glideColorClasses, getGlideIcon, getToolIcon} from "../../Chat/ChatBox/Messages/glideInfo";
import {Link} from "react-router-dom";
import {BiDotsHorizontalRounded} from "react-icons/bi";


export const formatUsageCount = (count) => {
    if (count < 1000) {return count.toString();}
    if (count < 1000000) {return (count / 1000).toFixed(1) + 'k';}
    if (count < 1000000000) {return (count / 1000000).toFixed(1) + 'M';}
    return (count / 1000000000).toFixed(1) + 'B';
};

const GlideItem = ({ glide, index }) => {
    const allTools = (glide?.default_tools?.concat(glide?.added_tools) || []).filter(Boolean);

    return (
        <motion.div
            initial={{opacity: 0, x: -25}}
            animate={{opacity: 1, x: 0}}
            exit={{opacity: 0, x: 25}}
            transition={{duration: 0.5, delay: index * 0.1}}
            key={glide?.uuid}
            className="grid grid-cols-1 grid-row-4 rounded-lg p-4 space-y-5 bg-base-100 spread-shadow ring-[0.75px] ring-neutral-focus/20 dark:ring-neutral-focus-dark/20"
        >
            <div className="row-span-3 flex w-full items-center justify-between space-x-5">
                <div className="flex-1 truncate">
                    <div className="flex items-center justify-start space-x-2.5 py-0.5" >
                        <h3 className="truncate font-semibold text-base-content text-base">{glide?.name}</h3>
                        {allTools.length > 0 && (
                            <div className="flex items-center" >
                                {allTools?.slice(0, 4).map((tool, i) => (
                                    <div
                                        key={tool?.uuid}
                                        className={clsx(
                                            "group/tool-icon rounded-full p-[3px] bg-base-100 ring-[0.5px] ring-base-content/20 text-base-content shadow-sm shadow-gray-400/50 dark:shadow-black/50 relative",
                                            i > 0 ? "-ml-1.5" : "ml-0",
                                        )}
                                    >
                                        {getToolIcon(tool?.name_tag, "w-5 h-5 sm:w-4 sm:h-4 p-0.5")}
                                        <div className="top-10 bottom-tooltips">{tool?.name_tag}</div>
                                    </div>))}
                                {allTools?.length > 4 && (
                                    <div className="rounded-full p-[3px] bg-base-100 ring-[0.5px] ring-base-content/20 text-base-content shadow-sm shadow-gray-400/50 dark:shadow-black/50 relative -ml-1.5 z-10 overflow-hidden">
                                        {allTools?.length > 9 ?
                                            <BiDotsHorizontalRounded className="w-5 h-5 sm:w-4 sm:h-4 p-0.5"/> :
                                            <p className="flex items-center justify-center text-2xs font-light text-center leading-none tracking-tighter w-5 h-5 sm:w-4 sm:h-4 p-0.5 cursor-default" >+{allTools?.length - 4}</p>
                                        }
                                    </div>
                                )}
                            </div>)}
                    </div>
                    <p className="mt-0.5 line-clamp-2 whitespace-pre-wrap text-sm text-base-content/70 font-light">{glide?.description}</p>
                    <div className="inline-flex space-x-1 rounded-md text-xs sm:text-2xs w-fit">
                        <p className="text-base-content/70">by</p>
                        <p className={clsx("font-medium tracking-wide", !glide?.user_created ? "text-primary-focus dark:text-primary-focus-dark" : "text-secondary-focus dark:text-secondary-focus-dark")}>
                            {!glide?.user_created ? "Dovise" : `@${glide?.created_by_username}`}
                        </p>
                        {glide?.usage_count > 0 &&
                            <>
                                <p>.</p>
                                <div className="flex items-center space-x-0.5 text-base-content/70 font-light">
                                    <HiOutlineChatBubbleOvalLeft className="w-3 h-3"/>
                                    <p>{formatUsageCount(glide?.usage_count)}</p>
                                </div>
                            </>}
                    </div>
                </div>
                <div className={clsx(
                    "rounded-full p-2.5 text-base-100 leading-none font-medium",
                    glideColorClasses[glide?.color]?.bg)}
                >
                    {getGlideIcon(glide?.name, "w-9 h-9 sm:w-8 sm:h-8 text-xl")}
                </div>
            </div>
            <div className="flex justify-end items-center gap-x-3 row-span-1 text-sm">
                <button className="standard-button">Learn More</button>
                <Link
                    to={`/task-glide/glides/${glide?.uuid}/test`}
                    className="primary-button"
                >
                    Test
                </Link>
            </div>
        </motion.div>
    )
};

GlideItem.propTypes = {
    glide: PropTypes.object,
    index: PropTypes.number
}

export default GlideItem;
