import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {useCallBackend} from "../../calls/useCallBackend";


export const userPreferencesUPDATE = createAsyncThunk(
    'users/userPreferences/update',
    async (values, thunkAPI) => {
        const access = localStorage.getItem('accessToken');
        try {
            return await useCallBackend(
                {
                    method: 'PUT',
                    path: `${process.env.REACT_APP_PREFERENCES_PATH}`,
                    accessCode: access,
                    args: values
                },
                thunkAPI
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const INITIAL_STATE = {
    userPreferencesUpdateVF: false,
    userPreferencesUpdateApiState: 'idle',
    userPreferencesUpdateResponse: null,
    userPreferencesUpdateError: null,
};

export const updateUserPreferencesSlice = createSlice({
    name: 'userPreferencesUpdate',
    initialState: INITIAL_STATE,
    reducers: {
        clearUserPreferencesUpdateVF: (state) => {
            state.userPreferencesUpdateVF = false;
        },
        resetUserPreferencesUpdate: (state) => {
            state.userPreferencesUpdateVF = false;
            state.userPreferencesUpdateApiState = 'idle';
            state.userPreferencesUpdateResponse = null;
            state.userPreferencesUpdateError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userPreferencesUPDATE.pending, (state) => {
                state.userPreferencesUpdateApiState = 'loading';
                state.userPreferencesUpdateVF = true;
            })
            .addCase(userPreferencesUPDATE.fulfilled, (state, action) => {
                state.userPreferencesUpdateApiState = 'succeeded';
                state.userPreferencesUpdateResponse = action?.payload;
                state.userPreferencesUpdateVF = true;
                state.userPreferencesUpdateError = null;
            })
            .addCase(userPreferencesUPDATE.rejected, (state, action) => {
                state.userPreferencesUpdateApiState = 'failed';
                state.userPreferencesUpdateError = action?.payload;
                state.userPreferencesUpdateVF = true;
                state.userPreferencesUpdateResponse = null;
            })
            .addDefaultCase((state, action) => {
                if (action.type === 'RESET') {
                    return INITIAL_STATE;
                }
            });
    }
});


export const {
    clearUserPreferencesUpdateVF,
    resetUserPreferencesUpdate
} = updateUserPreferencesSlice.actions;

export default updateUserPreferencesSlice.reducer;
