import React from "react";
import {motion} from "framer-motion";
import clsx from "clsx";
import {BsStars} from "react-icons/bs";
import {BiDotsHorizontalRounded} from "react-icons/bi";
import PropTypes from "prop-types";

import {glideColorClasses, glideDefaultColor, getGlideIcon, getUppercaseLetters} from "../../Pages/Auth/Products/TaskGlide/Chat/ChatBox/Messages/glideInfo";


const SelectedGlidesIcons = ({ selectedGlides, size }) => {
    const glideIconAnimation = {
        initial: { opacity: 0, x: 10, scale: 0 },
        enter: { opacity: 1, x: 0, scale: 1 },
        exit: { opacity: 0, x: 10, scale: 0 },
    };

    return (
        <>
            {selectedGlides?.length > 0 ?
                <div className="flex relative w-fit">
                    {selectedGlides?.slice(0, 4).map((glide, index) => {
                        return (
                            <motion.div
                                variants={glideIconAnimation}
                                initial="initial"
                                animate="enter"
                                exit="exit"
                                key={glide?.uuid}
                                className={clsx(
                                    "flex items-center justify-center rounded-full font-medium p-1 text-2xs text-base-100 shadow-sm shadow-gray-400/50 dark:shadow-black/50", index > 0 ? "-ml-1.5" : "ml-0", size,
                                    glideColorClasses[glide?.color].bg || glideDefaultColor?.bg,
                                )}
                            >
                                {getGlideIcon(glide?.name, "w-4 h-4") || getUppercaseLetters(glide?.name)}
                            </motion.div>
                        )
                    })}
                    {selectedGlides?.length > 4 && (
                        <div className={clsx(
                            "flex items-center justify-center rounded-full font-medium p-1 text-2xs text-base-100 bg-base-content -ml-2 ring-[0.5px] ring-base-content shadow-md", size,
                        )}>
                            {selectedGlides?.length > 9 ?
                                <BiDotsHorizontalRounded className="w-4 h-4"/> :
                                <span>+{selectedGlides?.length - 4}</span>
                            }
                        </div>
                    )}
                </div> :
                <div className={clsx(
                    "bg-base-300 rounded-full text-base-100 p-1 flex items-center justify-center ring-[0.5px] ring-base-300 shadow-md", size,
                )} >
                    <BsStars className="w-4 h-4"/>
                </div>}
            <span className="sr-only">Open Glides selection menu</span>
            {selectedGlides?.length > 1 ? null : <div className="left-tooltips right-10 group-hover/selected-glides:sm:block">Selected Glides</div>}

        </>
    )
};

SelectedGlidesIcons.propTypes = {
    selectedGlides: PropTypes.array,
    size: PropTypes.string,
};

SelectedGlidesIcons.defaultProps = {
    size: "w-7 h-7",
};

export default SelectedGlidesIcons;
